const apiVersionMap = {
  // config.default
'fetchContentful': 'v1b3',
// useAuthApi
refreshTrustedLogin: 'v1b3',
trustedLogin: 'v1b3',
logout: 'v1b3',
activateAccount: 'v1b3',
activateSession: 'v1b3',
signup: 'v1b3',
updateUserDetails: 'v1b3',
forgotPassword: 'v1b3',
resetPassword: 'v1b3',
changePassword: 'v1b3',
addDevice: 'v1b3',
deleteDevice: 'v1b3',
registerDevice: 'v1b3',
userDetails: 'v1b3',
time: 'v1b3',
smilPlayback:'v1b3',

// useDetailsApi
getMediaProgress: 'v1b3',
sendMediaProgress: 'v1b3',
getNextEpisode: 'v1b3',
getProducts: 'v1b3',
getOrderHistory: 'v1b3',
createOrder: 'v1b3',
commitOrder: 'v1b3',
abandonOrder: 'v1b3',
getOrderStatus: 'v1b3',
getProductsDetails: 'v1b3',
getEpisodeDetails: 'v1b3',
getPlaybackEpisode: 'v1b3',
getMediaInfo: 'v1b3',
getSeriesDetails: 'v1b3',
getMultipleMediaProgress: 'v1b3',
getRecentlyWatched: 'v1b3',
setRecentSearch: 'v1b3',
getRecentSearch: 'v1b3',
clearRecentSearch: 'v1b3',
applyCoupon: 'v1b3',
getMediaDetails:'v1b3',
checkAccess: 'v1b3',
restorePurchase:'v1b3',
cancelSubscription:'v1b3',
reactivateSubscription:'v1b3',
// useFavApi
getFavourites: 'v1b3',
deleteFavourites: 'v1b3',
addFavourites: 'v1b3',
isFavouritedItem: 'v1b3',

// useGeneralApi
getRailInfo: 'v1b3',
getSeasonDetails: 'v1b3',
getChannelInfo: 'v1b3',
getListItems: 'v1b3',
getRecommendations: 'v1b3',
getBestMatch: 'v1b3',
getSearchSuggestion: 'v1b3',
// profileApi
deleteProfile:'v1b3',
selectProfile:'v1b3',
updateProfile:'v1b3',
createProfile:'v1b3',
getAccountDetails:'v1b3',
updateAccountDetails:'v1b3'
}

export default apiVersionMap
