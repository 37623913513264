import React from "react";
import PropTypes from "prop-types";
/* --------------------------------------------- */
const Subscription = ({ color, ...rest }) => (
  <svg
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.4375 3.75H6.5625C6.045 3.75 5.625 3.33 5.625 2.8125C5.625 2.295 6.045 1.875 6.5625 1.875H23.4375C23.955 1.875 24.375 2.295 24.375 2.8125C24.375 3.33 23.955 3.75 23.4375 3.75ZM3.75 7.5H26.25C26.7675 7.5 27.1875 7.08 27.1875 6.5625C27.1875 6.045 26.7675 5.625 26.25 5.625H3.75C3.2325 5.625 2.8125 6.045 2.8125 6.5625C2.8125 7.08 3.2325 7.5 3.75 7.5ZM1.875 13.125C1.875 12.0909 2.71594 11.25 3.75 11.25H26.25C27.2841 11.25 28.125 12.0909 28.125 13.125V24.375C28.125 25.4091 27.2841 26.25 26.25 26.25H3.75C2.71594 26.25 1.875 25.4091 1.875 24.375V13.125ZM3.75 28.125H26.25C28.3181 28.125 30 26.4431 30 24.375V13.125C30 11.0569 28.3181 9.375 26.25 9.375H3.75C1.68187 9.375 0 11.0569 0 13.125V24.375C0 26.4431 1.68187 28.125 3.75 28.125ZM11.293 21.7651V15.737C11.293 15.0254 12.0543 14.5735 12.6796 14.9138L18.2183 17.9326C18.8708 18.2879 18.8699 19.2245 18.2165 19.5798L12.6787 22.5892C12.0533 22.9276 11.293 22.4757 11.293 21.7651Z"
      fill={color}
    />
  </svg>
);
/* --------------------------------------------- */
Subscription.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object,
};
/* --------------------------------------------- */
Subscription.defaultProps = {
  color: "transparent",
  style: {
    width: 25,
    height: 25,
  },
};
/* --------------------------------------------- */
export default Subscription;
/* --------------------------------------------- */
