/** Session storage keys */

export const RECENTLY_WATCHED = 'recently-watched'
export const FAVOURITES = 'favourites'
export const RECENT_SEARCHES = 'recent-searches'
export const STATE_UUID = 'state-uuid'
export const LANDING_PAGE_RENDERED = 'landing-page-rendered'
export const PRODUCTS = 'products'
export const EPISODES = 'episodes'
export const SUCCESS_DATA = 'success-data'
export const CONCURRENCY_TOKEN = 'concurrency-token'
export const IS_SHOWN_MAINTENANCE = 'is-shown-maintenance'

