import styled from "styled-components";

export const ProgressBar = styled.div`
  ${({ duration, progress }) => {
    const totalLength = duration;
    const currentProgress = progress;
    let widthInpercentage = Math.ceil((currentProgress / totalLength) * 100);
    if (widthInpercentage > 100) widthInpercentage = 100;
    if (widthInpercentage < 0) widthInpercentage = 0;
    return `
          &:after {
            width: ${widthInpercentage}%;
            background: #FFE300;
          }
        }
      `;
  }}
`;
