import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
// Button component for showing buttons.
const Button = ({ title, onClick, disabled }) => {
  return (
    <>
      <button className='button' {...{ onClick, disabled }}>{title}</button>
    </>
  )
}
/* --------------------------------------------- */
Button.propTypes = {
  /** Button title. */
  title: PropTypes.string,
  /** Button click function. */
  onClick: PropTypes.func
}
/* --------------------------------------------- */
Button.defaultProps = {
  title: '',
  onClick: () => { }
}
/* --------------------------------------------- */
export default Button
/* --------------------------------------------- */
