import React, { createContext, useReducer } from "react";

/** Miscs inital state */
const INITIAL_STATE = {
    isEnableBodyScroll: true
};

/** Miscs context */
const MiscsStateContext = createContext();
const MiscsDispatchContext = createContext();

/** Miscs reducer */
const MiscsReducer = (state, action) => {
    switch (action.type) {
        case "ENABLE_BODY_SCROLL":
            return { ...state, isEnableBodyScroll: true };
        case "DISABLE_BODY_SCROLL":
            return { ...state, isEnableBodyScroll: false };
        default:
            return state;
    }
};

/** Miscs provider */
const MiscsProvider = (props) => {
    const [state, dispatch] = useReducer(MiscsReducer, INITIAL_STATE);

    return (
        <MiscsStateContext.Provider value={state}>
            <MiscsDispatchContext.Provider value={dispatch}>
                {props.children}
            </MiscsDispatchContext.Provider>
        </MiscsStateContext.Provider>
    );
};

/** Custom hook for miscs state */
export const useMiscsState = () => {
    const context = React.useContext(MiscsStateContext);
    if (context === undefined) {
        throw new Error("useMiscsDispatch must be used within a MiscsProvider");
    }
    return context;
};

/** Custom hook for miscs dispatch */
export const useMiscsDispatch = () => {
    const context = React.useContext(MiscsDispatchContext);
    if (context === undefined) {
        throw new Error("useMiscsDispatch must be used within a MiscsProvider");
    }
    return context;
};

/** Custom hook for miscs state & dispatch */
export const useMiscs = () => {
    return [useMiscsState(), useMiscsDispatch()];
};

export default MiscsProvider;
