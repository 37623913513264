import React, { useContext, useEffect } from 'react'
import visitorInfo from 'visitor-info'
import { GlobalContext } from '../../context/global.context'
import { PlayerContext } from '../../context/player.context'
import { getBrowserName } from '../../utils/contentfulHandler'
import CloseIcon from '../icons/CloseIcon'

const { browser = {}, engine = {} } = visitorInfo()
const isSafari = getBrowserName((browser || {}).name, (engine || {}).name) === 'safari'

/**
 * It: Formats bits to bytes. 
 */
function formatBytes(bits) {
    const KB = bits / 8000
    return (KB).toFixed(2) + 'KB'
}

// PlayerStats component to display player stats
const PlayerStats = ({ mediaId, debugModeEnabled, showPlayerStats, theme }) => {
    const { userInfo } = useContext(GlobalContext)
    const {
        isShowStats = false,
        userAgent = '',
        playerVersion = '',
        playerState = '',
        startingQuality = '',
        startTime = new Date(),
        loadTime = new Date(),
        duration = 0,
        quality = '',
        drmProvider = '',
        drmLicenseUrl = '',
        releaseUrl = '',
        streamUrl = '',
        thumbnailUrl = '',
        estimatedBandwidth = 0,
        corruptedFrames = 0,
        decodedFrames = 0,
        droppedFrames = 0,
        videoHeight = 0,
        videoWidth = 0,
        bufferingTime = 0,
        bufferingHistory = [],
        streamHistory = [],
        trackHistory = [],
        allPlayerStats = [],
        videoId = '',
        originalVideoId = '',
        videoCodec = '',
        videoBandwidth = '',
        mimeType = '',
        frameRate = '',
        pixelAspectRatio = '',
        audioId = '',
        originalAudioId = '',
        audioCodec = '',
        audioSamplingRate = '',
        audioBandwidth = '',
        textTrackLanguage = '',
        textTrackLabel = '',
        originalTextId = '',
        dispatch
    } = useContext(PlayerContext)

    /**
     * It: Dispatches player stats.
     */
    useEffect(() => {
        let seconds = parseInt((loadTime.getTime() - startTime.getTime()) / 1000) || 0;
        seconds = seconds < 0 ? 0 : seconds
        const screenWidth = window.screen.width
        const screenHeight = window.screen.height
        let orientation = 'landscape'
        let videoResolution = ''
        let deviceResolution = ''

        if (window.screen.orientation && window.screen.orientation.type) {
            orientation = window.screen.orientation.type
        } else if (window.matchMedia) {
            var mql = window.matchMedia("(orientation: portrait)");
            if (mql.matches) orientation = 'portrait'
            else orientation = 'landscape'
        }

        if (videoWidth && videoHeight) {
            videoResolution = `${videoWidth}x${videoHeight}`
        }

        if (screenWidth && screenHeight) {
            deviceResolution = `${screenWidth}x${screenHeight}`
        }

        const displayStats = {
            deviceResolution,
            orientation,
            videoResolution
        }

        const frameRateInfo = {
            "Decoded Frames": decodedFrames
        }

        if (!isSafari) {
            frameRateInfo["Dropped Frames"] = droppedFrames
            frameRateInfo["Corrupted Frames"] = corruptedFrames
            frameRateInfo["Quality Selection"] = quality
        }

        let stats = [
            { label: "User Agent", value: userAgent },
            { label: "Player Version", value: playerVersion },
            { label: "Player State", value: playerState },
            { label: "Time to Start", value: `${seconds}s` },
            { label: "User ID", value: userInfo.userId },
            { label: "Content ID", value: mediaId },
            { label: "Duration", value: `${duration}s` },
            { label: "DRM Provider", value: drmProvider },
            { label: "DRM License URL", value: drmLicenseUrl },
            { label: "Release URL", value: releaseUrl },
            { label: "Stream URL", value: streamUrl },
            { label: "Filmstrip", value: thumbnailUrl },
            { label: "Framerate", value: JSON.stringify(frameRateInfo) },
            { label: "Buffer Time", value: `${parseInt(bufferingTime || 0)}s` },
            { label: "Buffering History", value: JSON.stringify(bufferingHistory) },
            { label: "Display Stats", value: JSON.stringify(displayStats) },
        ].filter(Boolean)

        if (!isSafari) {
            const videoTrack = {
                videoId,
                originalVideoId,
                videoCodec,
                mimeType,
                frameRate,
                pixelAspectRatio,
                videoBandwidth
            }

            const audioTrack = {
                audioId,
                originalAudioId,
                audioCodec,
                audioSamplingRate,
                audioBandwidth
            }
            const textTrack = {
                language: textTrackLanguage,
                label: textTrackLabel,
                originalTextId
            }

            stats = [
                ...stats,
                { label: "Starting Quality", value: startingQuality },
                { label: "Quality Selection", value: quality },
                { label: "Current Estimated Bandwidth", value: `${formatBytes(estimatedBandwidth)}/s` },
                { label: "Current Video", value: JSON.stringify(videoTrack) },
                { label: "Current Audio", value: JSON.stringify(audioTrack) },
                { label: "Current Text", value: JSON.stringify(textTrack) },
                { label: "Stream History", value: JSON.stringify(streamHistory) },
                { label: "Track History", value: JSON.stringify(trackHistory) }
            ]
        }

        dispatch({ type: "ALL_PLAYER_STATS", value: stats })
    }, [
        userAgent,
        playerVersion,
        playerState,
        startingQuality,
        startTime,
        loadTime,
        duration,
        quality,
        drmProvider,
        drmLicenseUrl,
        releaseUrl,
        streamUrl,
        thumbnailUrl,
        estimatedBandwidth,
        corruptedFrames,
        decodedFrames,
        droppedFrames,
        videoHeight,
        videoWidth,
        bufferingTime,
        bufferingHistory,
        streamHistory,
        trackHistory,
        mediaId,
        userInfo.userId,
        videoId,
        originalVideoId,
        videoCodec,
        mimeType,
        frameRate,
        pixelAspectRatio,
        videoBandwidth,
        audioId,
        originalAudioId,
        audioCodec,
        audioSamplingRate,
        audioBandwidth,
        textTrackLanguage,
        textTrackLabel,
        originalTextId,
        dispatch
    ])

    if (!debugModeEnabled) return null

    return (
        <div className={`player__state-pop__up   ${isShowStats ? "player__state-pop__up---open" : ""}`}>
            <div className="fixed-header">
                <div className='player__state-pop__up-head'>
                    <div className='title-wrapper'>
                        <p> Player Stats </p>
                    </div>
                    <div className='player-audio__close'
                        onClick={showPlayerStats}
                    >
                        <CloseIcon color={theme && theme.header && theme.header.accent.primary} style={{ width: 19, height: 19 }} />
                    </div>
                </div>
            </div>
            <div className='player__state-pop__up-wrapper'>
                <div>
                    <div className="player-state-wrapper">
                        <ul>
                            {
                                allPlayerStats.map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <div className="label">{item.label}</div>
                                            <div className="value">{item.value}</div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PlayerStats