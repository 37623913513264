import React, { useEffect } from 'react'
import useFirebaseTrace from '../../../hooks/useFirebaseTrace'

/**
 * A simple helper component to track static page load performance.
 */
const StaticPagePerformanceAnalyser = (props) => {
    const firebaseTrace = useFirebaseTrace()

    useEffect(() => {
        window.staticPageTrace = firebaseTrace('static_page_load')
    }, [firebaseTrace])

    return <>{props.children}</>
}

export default StaticPagePerformanceAnalyser