import { getNew } from '../utils/contentfulHandler'
import getTheme from './getTheme'
import getBaseConfiguration from './getBaseConfiguration'
import getPage from './getPage'
import getNavigation from './getNavigation'
import getFeatures from './getFeatures'

// Helper function to form contentful tree
const getApplication = (contentful) => {
  const themeLocal = getTheme(contentful)
  const pageLocal = getPage(contentful)
  const baseConfigurationLocal = getBaseConfiguration(contentful)
  const navigationLocal = getNavigation(contentful)
  const featuresLocal = getFeatures(contentful)
  const applicationLocal = contentful.application
  return applicationLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      baseConfiguration = [], pages = [], navigation = {}, features = [], defaultPageTheme = [],
      defaultComponentTheme = [], defaultPopupTheme = []
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      baseConfiguration: getNew(baseConfiguration, baseConfigurationLocal),
      pages: getNew(pages, pageLocal),
      navigation: getNew(navigation, navigationLocal),
      features: getNew(features, featuresLocal),
      defaultPageTheme: getNew(defaultPageTheme, themeLocal),
      defaultComponentTheme: getNew(defaultComponentTheme, themeLocal),
      defaultPopupTheme: getNew(defaultPopupTheme, themeLocal)
    }
  })
}

export default getApplication
