import {useCallback, useEffect, useRef} from 'react';
import { getDescriptionOfPlayerError, getErrorTitle } from '../components/player/MuxErrorHandler';

const usePlaybackRetry = ({playbackError, getMuxMetaData, recoverableErrorRetryCount, appMessages, shakaInstanceRef, onRecoverableErrorRetryCount, tokenExpiryRetryCount, dispatch, shakaConfigRef, onObjectDestructionError, onPlaybackDRMError, isSafari, onPlaybackManifestFailed, onPlaybackGenericError, onCrash, onHDCPError, onRecoverablePlayerError}) => {
    
    const isHDCPErrorThrownRef = useRef(false)
    const shakaConfig = shakaConfigRef.current;

    // to get recoverable retry count from contentful for the streaming
    const getRecoverableErrorRetryCount = useCallback((file) => {
        let errorRetryCount = null;
        const streamConfig = shakaConfig[file];
        if(streamConfig && streamConfig?.recoverableErrorRetryParams) {
            errorRetryCount = streamConfig?.recoverableErrorRetryParams?.maxAttempts;
        } else {
            errorRetryCount = null;
        }
        return errorRetryCount;
    },[shakaConfig])

    // to get expiry retry count from contentful for the streaming
    const getExpiryRetryCount = useCallback((file) => {
        let expiryRetryCount = null;
        const streamConfig = shakaConfig[file];
        if(streamConfig && streamConfig?.expiryRetryParams) {
            expiryRetryCount = streamConfig?.expiryRetryParams?.maxAttempts;
        } else {
            expiryRetryCount = null;
        }
        return expiryRetryCount;
    },[shakaConfig])
    
    //send error log to mux
    const sendMuxLog = useCallback(() => {
        if (shakaInstanceRef?.current?.mux && playbackError?.code && playbackError?.data) {
            const title = getErrorTitle(playbackError, appMessages)
            const description = getDescriptionOfPlayerError(playbackError, appMessages)
            shakaInstanceRef.current.mux.emit('error', {
                player_error_code: playbackError?.code || title, //added custom mux title and description
                player_error_message: description, //added custom mux title and description
                ...getMuxMetaData()
            });
        }
    },[appMessages, getMuxMetaData, playbackError, shakaInstanceRef])

    //retry the playback with current timestamp
    const retryPlayback = useCallback((showError, errorLog, retryCount)=> {
        if(retryCount) {
            if(recoverableErrorRetryCount > retryCount) {
                showError(errorLog);
                sendMuxLog();
                dispatch({ type: "RECOVERY_ERROR_RETRY_COUNT", value: 1}) 
                dispatch({ type: "RETRY_PLAYBACK", value: false })     
    
            } else {
                dispatch({ type: "RECOVERY_ERROR_RETRY_COUNT", value: recoverableErrorRetryCount + 1}) 
                dispatch({ type: "RETRY_PLAYBACK", value: true }) 
            }
        } else {
            showError(errorLog);
            sendMuxLog();
        }
    },[dispatch, recoverableErrorRetryCount, sendMuxLog])

    //retry smil playback
    const retrySmilPlayback = useCallback((showError, errorLog, retryCount) => {
        if(tokenExpiryRetryCount > retryCount) {
            showError(errorLog)
            sendMuxLog();
            dispatch({ type: "TOKEN_EXPIRY_COUNT", value: 1}) // to reset count
            dispatch({ type: "RETRY_SMIL_PLAYBACK", value: false }) // to retry smil playback
        } else {
            dispatch({ type: "TOKEN_EXPIRY_COUNT", value: tokenExpiryRetryCount + 1}) // to fix drm token expiry
            dispatch({ type: "RETRY_SMIL_PLAYBACK", value: true }) // to fix drm token expiry
        }
    },[dispatch, sendMuxLog, tokenExpiryRetryCount])
    
    // playback error from shaka player handling
    useEffect(()=>{
        if(!playbackError) return
        const { severity = null, code = null, category = null, data = null } = playbackError || {}
        const errorLog = { shakaErrorLog: JSON.stringify(playbackError) }
        console.error(playbackError)

        if (severity === 2) { // CRITICAL error
            if (code === 4012) {
                sendMuxLog();
                if (!isHDCPErrorThrownRef.current) {
                    onHDCPError(errorLog)
                    isHDCPErrorThrownRef.current = true
                } else {
                    onRecoverablePlayerError(errorLog)
                }
            } else if (category === 1) { // NETWORK related errors
                onCrash({}, errorLog)
            } else if (category === 2) { // TEXT related errors
                sendMuxLog();
                onPlaybackGenericError(errorLog)
            } else if (category === 3) { // MEDIA related errors
                const retryCount = getRecoverableErrorRetryCount("manifest");
                retryPlayback(onPlaybackGenericError, errorLog, retryCount)
            } else if (category === 4) { // MANIFEST related errors
                if(Array.isArray(data) && data?.[1] === 403) {
                    const retryCount = getExpiryRetryCount("manifest");
                    if(retryCount) {
                        retrySmilPlayback(onPlaybackManifestFailed, errorLog, retryCount)
                     } else {
                        onPlaybackManifestFailed(errorLog)
                        sendMuxLog();
                     }
                } else {
                    sendMuxLog();
                    onPlaybackManifestFailed(errorLog)
                }
            } else if (category === 5) { // STREAMING related errors
                const retryCount = getRecoverableErrorRetryCount("streaming");
                retryPlayback(onPlaybackGenericError, errorLog, retryCount)
            } else if (category === 6) { // DRM related errors
                if (isSafari && code === 6006) { // 6006 error is a recoverable error in safari. https://github.com/google/shaka-player/issues/2214
                    onRecoverablePlayerError(errorLog)
                    sendMuxLog();
                } else if(code === 6007) {
                    const retryCount = getExpiryRetryCount("drm");
                    if(retryCount) {
                       retrySmilPlayback(onPlaybackDRMError, errorLog, retryCount)
                    } else {
                        onPlaybackDRMError(errorLog)
                        sendMuxLog();
                    }
                } else {
                    onPlaybackDRMError(errorLog)
                    sendMuxLog();
                }
            } else if (category === 7) { // PLAYER related errors
                sendMuxLog();
                if (code === 7003) {
                    onObjectDestructionError(errorLog);
                } else {
                    onPlaybackGenericError(errorLog)
                }
            } else if (category === 8) { // CAST related errors
                sendMuxLog();
                onPlaybackGenericError(errorLog)
            } else if (category === 9) { // STORAGE related errors
                sendMuxLog();
                onPlaybackGenericError(errorLog)
            } else if (category === 10) { // ADS related errors
                sendMuxLog();
                onPlaybackGenericError(errorLog)
            } else {
                sendMuxLog();
                onPlaybackGenericError(errorLog)
            }
        } else {
            if(category === 1) {
                onCrash({}, errorLog)
                return
            }
            const retryCount = getRecoverableErrorRetryCount("streaming");
            retryPlayback(onPlaybackGenericError, errorLog, retryCount)
        }

    },[dispatch, getExpiryRetryCount, getRecoverableErrorRetryCount, isHDCPErrorThrownRef, isSafari, onCrash, onHDCPError, onObjectDestructionError, onPlaybackDRMError, onPlaybackGenericError, onPlaybackManifestFailed, onRecoverablePlayerError, playbackError, retryPlayback, retrySmilPlayback, sendMuxLog, shakaConfigRef])

    return null;
}

export default usePlaybackRetry;