import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const InfoIcon = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 422.686 422.686' {...rest}>
    <g>
      <g>
        <path d='M211.343,422.686C94.804,422.686,0,327.882,0,211.343C0,94.812,94.812,0,211.343,0    s211.343,94.812,211.343,211.343C422.686,327.882,327.882,422.686,211.343,422.686z M211.343,16.257    c-107.574,0-195.086,87.52-195.086,195.086s87.52,195.086,195.086,195.086s195.086-87.52,195.086-195.086    S318.908,16.257,211.343,16.257z' data-original='#010002' className='active-path' data-old_color='#010002' fill={color} />
      </g>
      <g>
        <g>
          <path d='M231.9,104.647c0.366,11.323-7.934,20.37-21.134,20.37c-11.689,0-19.996-9.055-19.996-20.37     c0-11.689,8.681-20.744,20.744-20.744C223.975,83.903,231.9,92.958,231.9,104.647z M194.931,338.531V155.955h33.189v182.576     C228.12,338.531,194.931,338.531,194.931,338.531z' data-original='#010002' className='active-path' data-old_color='#010002' fill={color} />
        </g>
      </g>
    </g>
  </svg>
)
/* --------------------------------------------- */
InfoIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
InfoIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 22,
    height: 22
  }
}
/* --------------------------------------------- */
export default InfoIcon
/* --------------------------------------------- */
