import {
  APP_CONFIG, MAINTENANCE_CONFIG, CONTENTFUL_APPLICATOIN_RES,
  CONTENTFUL_LOCALES_RES,
  ALLOWED_LOCALES, APP_MESSAGES, SELECTED_LOCALE, APPSTORE_CONFIG
} from '../constants/actionTypes'
import inialState from './initalState'
/* --------------------------------------------- */
/** App config reducer */
const appConfigReducers = (state = inialState, action) => {
  switch (action.type) {
    case CONTENTFUL_APPLICATOIN_RES:
      return {
        ...state,
        contentfulApplicationRes: action.value,
        isAppConfig: true
      }
    case CONTENTFUL_LOCALES_RES:
      return {
        ...state,
        contentfulLocalesRes: action.value,
        isAppConfig: true
      }
    case APPSTORE_CONFIG:
      return {
        ...state,
        appStoreConfig: action.value
      }
    case APP_CONFIG:
      return {
        ...state,
        appConfig: action.value
      }
    case MAINTENANCE_CONFIG:
      return {
        ...state,
        maintenanceConfig: action.value
      }
    case ALLOWED_LOCALES:
      return {
        ...state,
        allowedLocales: action.value
      }
    case APP_MESSAGES:
      return {
        ...state,
        appMessages: action.value,
        isAppMessagesReady: true
      }
    case SELECTED_LOCALE:
      return {
        ...state,
        selectedLocale: action.value
      }
    default: return state
  }
}
/* --------------------------------------------- */
export default appConfigReducers
/* --------------------------------------------- */
