import React, { useState, useCallback, useRef, useEffect, useImperativeHandle } from "react";
import VolMuteIcon from "../icons/VolMuteIcon";
import VolumeIcon from "../icons/VolumeIcon";
import {
  getMetaDataPlayerMuteState,
  setMetaDataPlayerMuteState,
} from "../../utils/localStorageService.js";
const timeoutInMiliseconds = 5000;
const MuteUnmute = React.forwardRef(({playerRef, isPlayerLoaded}, ref) => {
  const [isMute, setIsMute] = useState(getMetaDataPlayerMuteState());
  const timeoutId = useRef(null);
  const [isShowControls, updateIsShowControls] = useState(true);
  const isFirstRender = useRef(true);
  const handleMuteBtn = () => {
    if (!isMute) {
      playerRef.current.onMuteClick();
    } else {
      playerRef.current.onUnmuteClick();
    }
    setIsMute(!isMute);
    setMetaDataPlayerMuteState(!isMute);
  };
  const handleShowControls = useCallback((bShow) => {
    updateIsShowControls(bShow);
  }, []);
  const doInactive = useCallback(() => {
    handleShowControls(false);
  }, [handleShowControls]);
  const resetTimer = useCallback(() => {
    if (timeoutId.current) {
      window.clearTimeout(timeoutId.current);
    }
    timeoutId.current = window.setTimeout(doInactive, timeoutInMiliseconds);
    if (!isShowControls) {
      handleShowControls(true);
    }
  }, [handleShowControls, doInactive, isShowControls]);
  useImperativeHandle(ref, () => ({
    resetTimer
  }));
  useEffect(() => {
    if (isPlayerLoaded && isFirstRender.current) {
      isFirstRender.current = false
      resetTimer();
    }
  }, [resetTimer, isPlayerLoaded]);
  /* --------------------------------------------- */

  if (!isShowControls) {
    return <></>;
  }

  return (
    <>
      {isMute ? (
        <div onClick={handleMuteBtn} className="volume-btn-wrapper">
          <div className="control-icons-wrapper">
            <VolMuteIcon color={"#ffd200"} style={{ width: 20, height: 20 }} />
          </div>
        </div>
      ) : (
        <div onClick={handleMuteBtn} className="volume-btn-wrapper">
          <div className="control-icons-wrapper">
            <VolumeIcon
              className="control-icons-filled"
              color={"#ffd200"}
              style={{ width: 20, height: 20 }}
            />
          </div>
        </div>
      )}
    </>
  );
})
export default MuteUnmute;
