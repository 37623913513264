import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import useErrorUtils from "../utils/useErrorUtils";
import { useHistory } from "react-router-dom";
import useAnalytics from "../containers/useAnalytics";
const usePlaybackError = ({ currentTime, durationTime, type, pidRef, resetError, detailsSectionData, errorObj, onPrimaryBtnClick, smilData, errorConfigurationRef, playerStartTime, contentTitle, sendLog }) => {
    const { sendAnalytics } = useAnalytics()
    const playerFeature = useSelector(store => store.featuresReducers.playerFeature)
    const { showError } = useErrorUtils()
    const history = useHistory()

    // on smil playback erro sending analytics and error logs to loggly
    const onSmilPlaybackError = useCallback(({errorInfo, errorType}) => {
        const duration = parseInt(durationTime?.current)
        const progress = parseInt(currentTime?.current)
        const pid = pidRef?.current
        const config = errorConfigurationRef?.current.filter((config) => {
            return config.type === errorType
        })[0] || {}
        sendAnalytics({
            type: '_content.playback',
            action: 'error',
            attr: {
                start_time: playerStartTime,
                content_offer_id: detailsSectionData?.contentOfferId,
                content_stream_id: pid,
                content_title: contentTitle,
                content_type: type,
                progress,
                content_duration: duration,
                playback_mode: (playerFeature.portraitPlayerEnabled ? 'portrait' : 'fullscreen'),
                error_code: config.code,
                error_message: config.name
            }
        })
        sendLog(errorType, 'Player', errorInfo)
    }, [durationTime, currentTime, pidRef, errorConfigurationRef, sendAnalytics, playerStartTime, detailsSectionData, contentTitle, type, playerFeature, sendLog])

    //On smil playback error show error popup
    const _onSmilPlaybackError = (errorInfo, type, isCustomAction) => {
        showError({
            type: type,
            value: {
                onPrimaryBtnClick: () => {resetError() ; isCustomAction ? onPrimaryBtnClick(): history.push('/')},
                isPlayerError: false,
            },
            errorInfo
        })
        onSmilPlaybackError({errorInfo, errorType: type})
    }
    
    //catching smil playback errors
    useEffect(() => {
        if (!smilData || !errorObj) return
        let parsedError = JSON.parse(smilData)
        var el = document.createElement('html');
        el.innerHTML = parsedError?.responseBody

        /* --------------------------------------------- */
        const isExceptionParam = el.querySelector('param[name=\'isException\']')
        const exceptionParam = el.querySelector('param[name=\'exception\']')
        const responseCodeParam = el.querySelector('param[name=\'responseCode\']')
        /* --------------------------------------------- */
        const isException = isExceptionParam && isExceptionParam.getAttribute('value')
        const exception = exceptionParam && exceptionParam.getAttribute('value')
        const responseCode = responseCodeParam && responseCodeParam.getAttribute('value')


        let xmlStr = null
        try {
            const serializer = new window.XMLSerializer();
            xmlStr = serializer.serializeToString(el);
        } catch (e) { }

        /* --------------------------------------------- */
        if (isException === 'true') {
            if (exception === 'ConcurrencyLimitViolation') _onSmilPlaybackError({ smilData: xmlStr }, 'PlaybackConcurrency') 
            else if (exception === 'GeoLocationBlocked') _onSmilPlaybackError({ smilData: xmlStr }, 'PlaybackGeoRestricted') // to handle geo block exception
            else if (exception === 'CONTENT_GEO_BLOCK') _onSmilPlaybackError({ smilData: xmlStr }, 'InStreamGeoRestricted') // to handle content block exception
            else if (exception === 'AnonymousProxyBlocked') _onSmilPlaybackError({ smilData: xmlStr }, 'PlayerVpnBlocked')
            else if (exception === 'LicenseNotGranted' || responseCode === '401') _onSmilPlaybackError({ smilData: xmlStr }, 'PlaybackEntitlement', true) 
            else if (exception === 'UnknownFormat') _onSmilPlaybackError({ smilData: xmlStr }, 'PlaybackFormat') 
            else if (exception === 'NoAssetTypeFormatMatches') _onSmilPlaybackError({ smilData: xmlStr }, 'PlaybackFormat') 
            else if (responseCode === 'EntitlementValidationError') _onSmilPlaybackError({ smilData: xmlStr }, 'PlaybackEntitlement', true) 
            else if (exception === 'EntitlementValidationError') _onSmilPlaybackError({ smilData: xmlStr }, 'PlaybackEntitlement', true) 
            else if (exception === 'PlaybackDeviceUnsupported') _onSmilPlaybackError({ smilData: xmlStr }, 'PlaybackDeviceUnsupported') 
            else _onSmilPlaybackError({ smilData: xmlStr }, 'UnknownSmilError')
            return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [smilData])


    return;
}

export default usePlaybackError;