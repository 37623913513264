import { SUPPORT_CONFIG } from '../constants/actionTypes'
import { filter } from '../utils/contentfulHandler'
import { getThemeData, mergeThemes } from '../utils/contentfulThemeMerger'
import utils from '../utils/utils'

/** SuppportConfig store */
export const fetchSupportConfig = (support, popupThemeData, options) => {
  return (dispatch) => {
    if (utils.isNotNull(support, 0)) {
      const supportConfig = { ...support[0] }
      if (utils.isNotNull(supportConfig, 'errorConfiguration')) {
        const errorConfig = filter(supportConfig.errorConfiguration, '', options)
        let config = errorConfig.map((config) => {
          if (utils.isNotNull(config)) {
            return config
          }
          return null
        }).filter(Boolean)

        const mwErrorConfig = {};

        config = config.map((config = {}) => {
          const conf = { ...config }
          let faqItem = []
          if (utils.isNotNull(conf, 'faqItem')) {
            faqItem = filter(conf.faqItem, '', options)
            faqItem = faqItem.map((faq) => {
              let item = {}
              if (utils.isNotNull(faq, 'sysId')) {
                item.id = faq.sysId
              }
              if (utils.isNotNull(faq)) {
                item = { ...item, ...faq }
              }
              return item
            })
          }
          conf.faqItem = faqItem
          let themeData = popupThemeData
          if (utils.isNotNull(conf, 'theme', 0)) {
            themeData = getThemeData(conf.theme[0], options)
            themeData = mergeThemes(popupThemeData, themeData)
          }
          conf.theme = themeData
          if(config?.mwErrorCode && config?.mwErrorCode?.length) {
            config.mwErrorCode.forEach(element => {
              if(element)
              mwErrorConfig[element] = conf;
            });
          }
          return conf
        })
        supportConfig.errorConfiguration = config
        supportConfig.mwErrorConfiguration = mwErrorConfig
      }
      dispatch({
        type: SUPPORT_CONFIG,
        value: supportConfig
      })
    }
    return Promise.resolve()
  }
}
