import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  // Suspense,
  // lazy,
  useLayoutEffect
} from 'react'
import { GlobalContext } from '../../../context/global.context'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Spinner from '../../Spinner'
import Button from '../../form/Button'
import useAuthApi from '../../../apis/useAuthApi'
import {
  // clearLocalStorage,
  getAppLanguage,
  setAppLanguage,
  setIsRenderedFromSignup
} from '../../../utils/localStorageService'
import { routeMap } from '../../../route'
import useErrorUtils from '../../../utils/useErrorUtils'
import TextBox from '../../form/TextBox'
import _utils from '../../../utils/utils'
// import InfoPopUp from "../../InfoPopUp/InfoPopUp";

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {
    htmlElRef.current && htmlElRef.current.focus()
  }

  return [htmlElRef, setFocus]
}

const VerifyAccount = ({ history }) => {
  const { dispatch } = useContext(GlobalContext)
  const { appConfig, appMessages } = useSelector((store) => store.appConfigReducers)
  const { revenueConfig = {} } = useSelector((store) => store.featuresReducers)
  const { revenueMode = [] } = revenueConfig
  const isSvodModeAvailable = revenueMode.find((item) => item === 'svod')
  const enableOTPInput = false;
  const { userManagementConfig = {}, userManagementThemes = {} } = useSelector(
    (store) => store.userManagement
  )
  const [inputTheme, updateInputTheme] = useState({})

  const otpInput = useRef(null)
  const otpRef = useRef(null)

  // const { profileManagementConfig = {} } = useSelector(
  //   (store) => store.profileManagement
  // )

  const { registrationMethods = [], authConfig: userAuthConfig = [] } =
    userManagementConfig

  const [otpCounter, setOtpCounter] = useState(20)
  const [isLoading, updateIsLoading] = useState(true)
  const [showWarning, setShowWarning] = useState(false)
  const [enableSubmit, setEnableSubmit] = useState(false)
  const [pin, setPin] = useState(0)
  const [valueOne, setvalueOne] = useState('')
  const [valueTwo, setvalueTwo] = useState('')
  const [valueThree, setvalueThree] = useState('')
  const [valueFour, setvalueFour] = useState('')
  const [valueFive, setvalueFive] = useState('')
  const [valueSix, setvalueSix] = useState('')
  const [theme, updateTheme] = useState({})

  const [inputOneRef, setInputOneFocus] = useFocus()
  const [inputTwoRef, setInputTwoFocus] = useFocus()
  const [inputThreeRef, setInputThreeFocus] = useFocus()
  const [inputFourRef, setInputFourFocus] = useFocus()
  const [inputFiveRef, setInputFiveFocus] = useFocus()
  const [inputSixRef, setInputSixFocus] = useFocus()
  const [loading, setLoading] = useState(false)
  // const [showInfoPopip, setShowInfoPopUp] = useState(false)

  const [baseUrl, updateBaseUrl] = useState(null)
  const [authConfig, setAuthConfig] = useState({})
  const { showError } = useErrorUtils()

  const { verifyUser, signup } = useAuthApi()

  const location = useLocation()

  useEffect(() => {
    if (!appConfig.baseUrl) return
    updateBaseUrl(appConfig.baseUrl)
  }, [appConfig.baseUrl])

  useEffect(() => {
    // clearLocalStorage();
    const authConfig = userAuthConfig.filter(
      (config) =>
        config.identityProvider === 'cognito' ||
        config.identityProvider === 'mpx'
    )
    setAuthConfig(authConfig[0] || {})
  }, [userAuthConfig])

  useEffect(() => {
    let isMounted = true
    const timer = setTimeout(() => {
      if (!isMounted) return
      updateIsLoading(false)
      dispatch({ type: 'SHOW_SPLASH', value: false })
    }, 500)

    // clearing the timer and making sure that state will not changed when component is unmounting
    return () => {
      isMounted = false
      clearTimeout(timer)
    }
  }, [dispatch])

  const fwdToHome = () => {
    if (history?.action === 'POP') {
      history.push('/')
    }
  }
  useEffect(fwdToHome, [history])

  useEffect(() => {
    if (
      valueOne === '' &&
      valueTwo === '' &&
      valueThree === '' &&
      valueFour === '' &&
      valueFive === '' &&
      valueSix === ''
    ) {
      setInputOneFocus()
      setShowWarning(false)
    }
  }, [
    setInputOneFocus,
    valueFour,
    valueOne,
    valueThree,
    valueTwo,
    valueFive,
    valueSix
  ])

  const permittedEntries = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
  useLayoutEffect(() => {
    if (permittedEntries.indexOf(valueOne) === -1) {
      setShowWarning(true)
      setvalueOne('')
    } else {
      setInputTwoFocus()
      if (showWarning) setShowWarning(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueOne])

  useLayoutEffect(() => {
    if (permittedEntries.indexOf(valueTwo) === -1) {
      setShowWarning(true)
      setvalueTwo('')
    } else {
      setInputThreeFocus()
      if (showWarning) setShowWarning(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueTwo])

  useLayoutEffect(() => {
    if (permittedEntries.indexOf(valueThree) === -1) {
      setShowWarning(true)
      setvalueThree('')
    } else {
      setInputFourFocus()
      if (showWarning) setShowWarning(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueThree])

  useLayoutEffect(() => {
    if (permittedEntries.indexOf(valueFour) === -1) {
      setShowWarning(true)
      setvalueFour('')
    } else {
      setInputFiveFocus()
      if (showWarning) setShowWarning(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFour])

  useLayoutEffect(() => {
    if (permittedEntries.indexOf(valueFive) === -1) {
      setShowWarning(true)
      setvalueFive('')
    } else {
      setInputSixFocus()
      if (showWarning) setShowWarning(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueFive])

  useLayoutEffect(() => {
    if (permittedEntries.indexOf(valueSix) === -1) {
      setShowWarning(true)
      setvalueSix('')
    } else {
      if (showWarning) setShowWarning(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueSix])

  useEffect(() => {
    if (
      valueOne !== '' &&
      valueTwo !== '' &&
      valueThree !== '' &&
      valueFour !== '' &&
      valueFive !== '' &&
      valueSix !== ''
    ) {
      setPin(
        `${valueOne}${valueTwo}${valueThree}${valueFour}${valueFive}${valueSix}`
      )
      setEnableSubmit(true)
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    valueOne,
    valueTwo,
    valueThree,
    valueFour,
    valueFive,
    valueSix,
    permittedEntries
  ])

  useEffect(() => {
    if (_utils.isNotNull(theme, 'compositeStyle', 'inputBox')) {
      updateInputTheme(theme.compositeStyle.inputBox || {})
    }
  }, [theme])

  useEffect(() => {
    updateTheme(userManagementThemes.registrationTheme || {})
    // dispatch({ type: 'SHOW_HEADER_BACK_BTN' })
  }, [userManagementThemes.registrationTheme, dispatch])

  React.useEffect(() => {
    const timer =
      otpCounter > 0 &&
      setInterval(() => {
        setOtpCounter(otpCounter - 1)
      }, 1000)
    return () => clearInterval(timer)
  }, [otpCounter])

  const triggerSuccessFunction = () => {
    setLoading(true)
    // const isMultiProfileEnabled =
    //   Object.keys(profileManagementConfig).length > 0
    // const showSelectionOnStart = profileManagementConfig.showSelectionOnStart
    verifyUser(baseUrl, {
      email: location?.state?.email,
      password: location?.state?.password,
      mobile: location?.state?.mobile,
      countryCode: location?.state?.countryCode,
      countryName: location?.state?.countryName,
      firstName: location?.state?.firstName,
      lastName: location?.state?.lastName,
      operator: location?.state?.operator,
      confirmationCode: pin,
      identityProvider: authConfig.identityProvider,
      logoutExtendPeriod: authConfig.logoutExtendPeriod,
      accessTokenRefreshInterval: authConfig.accessTokenRefreshInterval
    }).then((res) => {
      const locale =
        getAppLanguage() ||
        window.navigator.userLanguage ||
        window.navigator.language ||
        'en-US'
      setAppLanguage(locale)
      dispatch({ type: 'IS_APP_LOADED', value: false })
      dispatch({ type: 'AUTHENTICATED' })
      dispatch({ type: 'SHOW_SPINNER', value: false })
      setIsRenderedFromSignup(true)
      const searchParamObj = new URLSearchParams(location.search)
      const paymentId = searchParamObj.get('paymentId')
      if (isSvodModeAvailable && paymentId) {
        setLoading(false)
        dispatch({ type: 'IS_DIRECTED_TO_PAYMENT', value: true })
        setIsRenderedFromSignup(true)
        history.push(routeMap.payment.replace(':storeProductId', paymentId))
      } else if (isSvodModeAvailable) {
        setTimeout(()=>{
          history.push(routeMap.subscription)
          setLoading(false)
        },1000)
      } 
      // else if (isMultiProfileEnabled) {
      //   dispatch({
      //     type: 'SET_SHOW_MULTI_PROFILE_LIST',
      //     value: showSelectionOnStart
      //   })
      // } 
      else {
        setLoading(false)
        history.push('/')
      }
    }).catch((err) => {
      setvalueOne('')
      setvalueTwo('')
      setvalueThree('')
      setvalueFour('')
      setvalueFive('')
      setvalueSix('')
      setLoading(false)
      if (err?.errorCode === 1302) {
        // setShowInfoPopUp(true)
        showError({ type: 'EmailVerificationFailed', onPrimaryBtnClick: () =>{console.log("primm"); history.push(routeMap.signup)}, })
      } else {
        showError({ type: 'SignupFailed' })
      }
    })
  }

  const onDeleteValueOne = (event) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      event.preventDefault()
      setvalueOne('')
    } else if (valueOne !== '' && valueTwo === '') {
      event.preventDefault()
      setvalueTwo(event.key)
      setInputTwoFocus()
    }
  }

  const onDeleteValueTwo = (event) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      event.preventDefault()
      setvalueTwo('')
      setInputOneFocus()
    } else if (valueTwo !== '' && valueThree === '') {
      event.preventDefault()
      setvalueThree(event.key)
      setInputThreeFocus()
    }
  }

  const onDeleteValueThree = (event) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      event.preventDefault()
      setvalueThree('')
      setInputTwoFocus()
    } else if (valueThree !== '' && valueFour === '') {
      event.preventDefault()
      setvalueFour(event.key)
      setInputFourFocus()
    }
  }

  const onDeleteValueFour = (event) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      event.preventDefault()
      setvalueFour('')
      setInputThreeFocus()
    } else if (valueFour !== '' && valueFive === '') {
      event.preventDefault()
      setvalueFive(event.key)
      setInputFiveFocus()
    }
  }

  const onDeleteValueFive = (event) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      event.preventDefault()
      setvalueFive('')
      setInputFourFocus()
    } else if (valueFive !== '' && valueSix === '') {
      event.preventDefault()
      setvalueSix(event.key)
      setInputSixFocus()
    }
  }

  const onDeleteValueSix = (event) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      event.preventDefault()
      setvalueSix('')
      setInputFiveFocus()
    }
  }

  const onEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (enableSubmit) triggerSuccessFunction()
    }
  }

  const onPastePin = (e) => {
    e.preventDefault()
    const pasteTextArray = e.clipboardData.getData('Text').split('')
    if (pasteTextArray[0]) {
      setvalueOne(pasteTextArray[0])
    }
    if (pasteTextArray[1]) {
      setvalueTwo(pasteTextArray[1])
    }
    if (pasteTextArray[2]) {
      setvalueThree(pasteTextArray[2])
    }
    if (pasteTextArray[3]) {
      setvalueFour(pasteTextArray[3])
    }
    if (pasteTextArray[4]) {
      setvalueFive(pasteTextArray[4])
    }
    if (pasteTextArray[5]) {
      setvalueSix(pasteTextArray[5])
    }
  }

  const resendCode = () => {
    signup(baseUrl, {
      registrationMethods,
      email: location?.state?.email,
      password: location?.state?.password,
      countryCode: location?.state?.countryCode,
      countryName: location?.state?.countryName,
      firstName: location?.state?.firstName,
      lastName: location?.state?.lastName,
      mobile: location?.state?.mobile,
      identityProvider: authConfig.identityProvider,
      logoutExtendPeriod: authConfig.logoutExtendPeriod,
      accessTokenRefreshInterval: authConfig.accessTokenRefreshInterval
    }).then(() => {
      setOtpCounter(20)
    })
  }

  const otpHandler = (otp) => {
    setPin(otp);
    if(otp?.length > 3 ) {
      setEnableSubmit(true)
      otpInput.current.classList.add('validation-Error')
    } else {
      setEnableSubmit(false)
    }
  }

  return (
    <>
      <BodyWrapper {...{ theme }} className='catalogContainer verify-account-page-wrapper'>
        {(isLoading || loading) && <Spinner size={60} IsLoading />}
        <div className='form-page signup-wrapper'>
        <div className='form-page'>
          {enableOTPInput? 
                    <VerifyAccountWrapper {...{ theme }}>
                    <p className='step-1-text'>{appMessages.label_verify_account_title}</p>
                    <p className='step-1-description' style={{ maxWidth: '520px' }}>
                      {appMessages.label_verify_account_description}
                    </p>
                    <div className='form' style={{ maxWidth: '520px' }}>
                      <div className='otp-pin-input'>
                        <input
                          id='one'
                          ref={inputOneRef}
                          value={valueOne ? '•' : ''}
                          onChange={(e) => {
                            setvalueOne(e.target.value)
                          }}
                          onKeyDown={(e) => {
                            onDeleteValueOne(e)
                          }}
                          onPaste={onPastePin}
                          onKeyPress={onEnterKeyPress}
                          type='text'
                          maxlength='1'
                        />
                        <input
                          id='two'
                          ref={inputTwoRef}
                          value={valueTwo ? '•' : ''}
                          onChange={(e) => setvalueTwo(e.target.value)}
                          onKeyDown={(e) => {
                            onDeleteValueTwo(e)
                          }}
                          onKeyPress={onEnterKeyPress}
                          type='text'
                          maxlength='1'
                        />
                        <input
                          id='three'
                          ref={inputThreeRef}
                          value={valueThree ? '•' : ''}
                          onChange={(e) => setvalueThree(e.target.value)}
                          onKeyDown={(e) => {
                            onDeleteValueThree(e)
                          }}
                          onKeyPress={onEnterKeyPress}
                          type='text'
                          maxlength='1'
                        />
                        <input
                          id='four'
                          ref={inputFourRef}
                          value={valueFour ? '•' : ''}
                          onChange={(e) => setvalueFour(e.target.value)}
                          onKeyDown={(e) => {
                            onDeleteValueFour(e)
                          }}
                          onKeyPress={onEnterKeyPress}
                          type='text'
                          maxlength='1'
                        />
                        <input
                          id='five'
                          ref={inputFiveRef}
                          value={valueFive ? '•' : ''}
                          onChange={(e) => setvalueFive(e.target.value)}
                          onKeyDown={(e) => {
                            onDeleteValueFive(e)
                          }}
                          onKeyPress={onEnterKeyPress}
                          type='text'
                          maxlength='1'
                        />
                        <input
                          id='six'
                          ref={inputSixRef}
                          value={valueSix ? '•' : ''}
                          onChange={(e) => setvalueSix(e.target.value)}
                          onKeyDown={(e) => {
                            onDeleteValueSix(e)
                          }}
                          onKeyPress={onEnterKeyPress}
                          type='text'
                          maxlength='1'
                        />
                      </div>
                      <ButtonWrapper {...{ theme, enableSubmit }}>
                        <div
                          className={`form-btn-wrapper ${
                            enableSubmit ? 'sumbit-bnt-active' : ''
                          }`}
                        >
                          <Button
                            {...{ title: appMessages.label_account_verify_confirm }}
                            onClick={
                              enableSubmit ? () => triggerSuccessFunction() : () => {}
                            }
                            //   onClick={handleLoginClick}
                          />
                        </div>
                      </ButtonWrapper>
                    </div>
                    <div className='receive-text'>
                      <p>
                        {appMessages.label_account_verify_waiting_desc}
                      </p>
                      <p>{appMessages.label_verify_account_code_not_received_desc}</p>
                      {otpCounter !== 0 ? (
                        <p className='resend-code-counter'>
                          {appMessages.label_verify_account_resend_desc}{' '}
                          <span> {otpCounter && _utils.secondsToTime(otpCounter).s}</span>s
                        </p>
                      ) : (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <p className='resend-code' onClick={resendCode}>{appMessages.label_account_verify_resend_code_desc}</p>
                      )}
                    </div>
                  </VerifyAccountWrapper>
                  :
                  <VerifyAccountWrapper {...{ theme }}>
                    <p className='step-1-text'>{appMessages.label_verify_account_title}</p>
                    <p className='step-1-description' style={{ maxWidth: '520px' }}>
                      {appMessages.label_verify_account_description}
                    </p>
                    <div className='form otp-txt'>

                    <div className='otp-txt-input' ref={otpInput}>
                      <TextBox
                        {...{
                          ref: otpRef,
                          placeholder: appMessages.label_enter_otp,
                          validationText:
                            appMessages.label_otp_placeholder,
                          theme: inputTheme,
                          type: 'text',
                          name: 'otp',
                          isOtp: true,
                          handler: otpHandler,
                          onKeyPress: onEnterKeyPress
                        }}
                      />
                    </div>
                    
                    <ButtonWrapper {...{ theme, enableSubmit }}>
                        <div
                          className={`form-btn-wrapper ${
                            enableSubmit ? 'sumbit-bnt-active' : ''
                          }`}
                        >
                          <Button
                            {...{ title: appMessages.label_account_verify_confirm }}
                            onClick={
                              enableSubmit ? () => triggerSuccessFunction() : () => {}
                            }
                            //   onClick={handleLoginClick}
                          />
                        </div>
                    </ButtonWrapper>
                    </div>
                    <div className='receive-text'>
                      <p>
                        {appMessages.label_account_verify_waiting_desc}
                      </p>
                      <p>{appMessages.label_verify_account_code_not_received_desc}</p>
                      {otpCounter !== 0 ? (
                        <p className='resend-code-counter'>
                          {appMessages.label_verify_account_resend_desc}{' '}
                          <span> {otpCounter && _utils.secondsToTime(otpCounter).s}</span>s
                        </p>
                      ) : (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <p className='resend-code' onClick={resendCode}>{appMessages.label_account_verify_resend_code_desc}</p>
                      )}
                    </div>
                  </VerifyAccountWrapper>
          }

        </div>
        </div>
      </BodyWrapper>
      {/* {showInfoPopip && (
         <InfoPopUp
         title={"Test"}
         desc={"Test"}
         positiveText={"Test"}
         onPositiveBtnClick={()=> setShowInfoPopUp(false)}
       />
      )} */}

    </>
  )
}

export default VerifyAccount

const BodyWrapper = styled.div`

`

const VerifyAccountWrapper = styled.div`
  .step-1-text {
    width: 336px;
    height: 21px;
    font-weight: 700;
    font-size: 24px;
    line-height: 21px;
    color:  ${({ theme: { body } }) => body && body.text.primary};;
  }
  .step-1-description {
    height: 46px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color:  ${({ theme: { body } }) => body && body.text.secondary};;
  }
  // .otp-pin-input {
  //   display: flex;
  //   flex-direction: row;
  //   -webkit-box-pack: center;
  //   justify-content: space-between;
  //   margin-top: 40px;

  //   input {
  //     width: 71px;
  //     height: 68px;
  //     border-radius: 5px;
  //     border: 1px solid  ${({ theme: { body } }) => body && body.text.secondary};;
  //     background-color:  ${({ theme: { body } }) => body && body.background.secondary};;
  //     color: ${({ theme: { body } }) => body && body.text.secondary};;
  //     font-size: 30px;
  //     text-align: center;
  //     outline: none;
      
  //     @media (max-width: 480px) {
  //       width: 45px;
  //       height: 45px;
  //     }
  //   }
  // }
  .otp-txt {
    @media (max-width: 480px) {
           padding-top: 15px;
          }
      }
  }
  .otp-txt-input {
    input {
      letter-spacing: 16px;
    }
  }
  .receive-text {
    max-width: 548px;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color:  ${({ theme: { body } }) => body && body.text.primary};;
    }
    .resend-code-counter {
      color:  ${({ theme: { body } }) => body && body.background.tertiary};;
      text-decoration-line: underline;
    }
    .resend-code {
      display: flex;
      cursor: pointer;
      justify-content: center;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      text-decoration-line: underline;
      color:  ${({ theme: { body } }) => body && body.text.tertiary};;
      &:hover {
        color: ${({ theme: { body } }) => body && body.text.secondary};
      }
    }
  }
`

const ButtonWrapper = styled.div`
  margin-top: 42px;
  .form-btn-wrapper {
    display: flex;
    justify-content: center;
  }
  button {
    text-transform: uppercase;
    cursor: not-allowed;
    background: ${({ theme: { compositeStyle } }) =>
      compositeStyle &&
      compositeStyle.primaryButton &&
      compositeStyle.primaryButton.disabled &&
      compositeStyle.primaryButton.disabled.background};
    color: ${({ theme: { compositeStyle } }) =>
      compositeStyle &&
      compositeStyle.primaryButton &&
      compositeStyle.primaryButton.disabled &&
      compositeStyle.primaryButton.disabled.text};
    border:${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.disabled.stroke};
    width: 25%;
  }

  .sumbit-bnt-active button {
    cursor: pointer;
    background: ${({ theme: { compositeStyle } }) =>
      compositeStyle &&
      compositeStyle.primaryButton &&
      compositeStyle.primaryButton.normal &&
      compositeStyle.primaryButton.normal.background};
    color: ${({ theme: { compositeStyle } }) =>
      compositeStyle &&
      compositeStyle.primaryButton &&
      compositeStyle.primaryButton.normal &&
      compositeStyle.primaryButton.normal.text};
    border:${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.normal.stroke};
      &:hover {
        background-color:${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.focussed.background};
        color:${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.focussed.text};
        border:${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.focussed.stroke};
      }
  }
`
