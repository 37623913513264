// Helper function to form contentful tree
const getFilter = (contentful) => {
  const filterLocal = contentful.filter
  return filterLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    return { sysId: id, contentTypeId, ...fields }
  })
}

export default getFilter
