import React from 'react'
import { useMiscsState } from '../../context/miscs.context'
import { RemoveScrollBar } from 'react-remove-scroll-bar'

// BodyScroll component used to handle enabling/disabling of body scroll.
const BodyScroll = () => {
    const { isEnableBodyScroll } = useMiscsState()

    if (!isEnableBodyScroll) return <RemoveScrollBar />
    return null
}

export default BodyScroll