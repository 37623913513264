import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getTheme from './getTheme'
import getPageComponent from './getPageComponent'
import getPage from './getPage'

// Helper function to form contentful tree
const getFeatureCatalog = (contentful) => {
  const filterLocal = getFilter(contentful)
  const themeLocal = getTheme(contentful)
  const pageComponentLocal = getPageComponent(contentful)
  const pageLocal = getPage(contentful)
  const featureCatalogLocal = contentful.featureCatalog
  return featureCatalogLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      filter = {}, theme = [], episodeListingComponent = [], metadataLinkPage = [],
      peopleLinkPage = [], metaTheme = [], relatedMediaComponent = []
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      theme: getNew(theme, themeLocal),
      episodeListingComponent: getNew(episodeListingComponent, pageComponentLocal),
      metadataLinkPage: getNew(metadataLinkPage, pageLocal),
      peopleLinkPage: getNew(peopleLinkPage, pageLocal),
      metaTheme: getNew(metaTheme, themeLocal),
      relatedMediaComponent: getNew(relatedMediaComponent, pageComponentLocal)
    }
  })
}

export default getFeatureCatalog
