import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getLocalization from './getLocalization'
import getMaintenanceConfiguration from './getMaintenanceConfiguration'
import getAppStoreConfiguration from './getAppStoreConfiguration'

// Helper function to form contentful tree
const getBaseConfiguration = (contentful) => {
  const filterLocal = getFilter(contentful)
  const localizationLocal = getLocalization(contentful)
  const maintenanceConfigurationLocal = getMaintenanceConfiguration(contentful)
  const appStoreConfigurationLocal = getAppStoreConfiguration(contentful)
  const baseConfigurationLocal = contentful.baseConfiguration
  return baseConfigurationLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { filter = {}, localizationConfig = [], maintenanceConfig = [], appStoreConfig = [] } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      localizationConfig: getNew(localizationConfig, localizationLocal),
      maintenanceConfig: getNew(maintenanceConfig, maintenanceConfigurationLocal),
      appStoreConfig: getNew(appStoreConfig, appStoreConfigurationLocal)
    }
  })
}

export default getBaseConfiguration
