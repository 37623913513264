import getFeatureAnalytics from './getFeatureAnalytics'
import getFeatureCatalog from './getFeatureCatalog'
import getFeaturePlayer from './getFeaturePlayer'
import getFeatureRevenue from './getFeatureRevenue'
import getFeatureSearch from './getFeatureSearch'
import getFeatureSupport from './getFeatureSupport'
import getFeatureUserManagement from './getFeatureUserManagement'
import getFeatureProfileManagement from './getFeatureProfileManagement'
import getFeatureIntro from './getFeatureIntro'
import getFeatureAd from "./getFeatureAd"

// Helper function to form contentful tree
const getFeatures = (contentful) => {
  const featureAnalyticsLocal = getFeatureAnalytics(contentful)
  const featureCatalogLocal = getFeatureCatalog(contentful)
  const featurePlayerLocal = getFeaturePlayer(contentful)
  const featureRevenueLocal = getFeatureRevenue(contentful)
  const featureSearchLocal = getFeatureSearch(contentful)
  const featureSupportLocal = getFeatureSupport(contentful)
  const featureUserManagementLocal = getFeatureUserManagement(contentful)
  const featureProfileManagementLocal = getFeatureProfileManagement(contentful)
  const featureIntroLocal = getFeatureIntro(contentful)
  const featureAd = getFeatureAd(contentful)
  const applicationLocal = contentful.application

  // console.log(featureIntroLocal)

  let featuresLocal = applicationLocal.map(({ fields = {} }) => {
    const { features = [] } = fields
    return features
  })
  featuresLocal = [].concat(...featuresLocal)

  const findMatch = (arr, id) => {
    let selected = null
    arr.map((props) => {
      const { sysId = '' } = props
      if (id === sysId) selected = { ...props }
      return null
    })
    return selected
  }

  return featuresLocal.map(({ sys = {} }) => {
    const { id = '' } = sys
    let selected = null
    if (!selected) selected = findMatch(featureAnalyticsLocal, id)
    if (!selected) selected = findMatch(featureCatalogLocal, id)
    if (!selected) selected = findMatch(featurePlayerLocal, id)
    if (!selected) selected = findMatch(featureRevenueLocal, id)
    if (!selected) selected = findMatch(featureSearchLocal, id)
    if (!selected) selected = findMatch(featureSupportLocal, id)
    if (!selected) selected = findMatch(featureUserManagementLocal, id)
    if (!selected) selected = findMatch(featureProfileManagementLocal, id)
    if (!selected) selected = findMatch(featureIntroLocal, id)
    if (!selected) selected = findMatch(featureAd, id)

    return selected
  }).filter(Boolean)
}

export default getFeatures
