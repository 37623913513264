import { useCallback, useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import useAuthApi from "../../apis/useAuthApi"
import { GlobalContext } from "../../context/global.context";
import useErrorUtils from "../../utils/useErrorUtils";

const TimeModificationHandler = (props) => {
    const { getServerTime } = useAuthApi();
    const { showError, hideError } = useErrorUtils();
    const [showGeoLocError, setShowGeoLocError] = useState(false);
    const [showVPNError, setShowVPNError] = useState(false);

    const { appConfig: { baseUrl, allowedTimeDifference } } = useSelector(store => store.appConfigReducers)
    const { isShowSplash, dispatch } = useContext(GlobalContext)
    const pathname = window?.location?.pathname;
    // console.log(pathname, "pathname");
    const checkForTimeModifications = useCallback(() => {
        baseUrl && getServerTime(baseUrl).then(currentServerTime => {
            setShowGeoLocError(false);
            setShowVPNError(false);
            dispatch({ type: 'IS_GEO_BLOCKED', value: false })
            dispatch({ type: 'IS_VPN_BLOCKED', value: false })

            const timezoneOffset = new Date().getTimezoneOffset() * 60000 * -1;
            const initialDeviceTime = new Date().getTime() + timezoneOffset;
            const initialServerTime = new Date(currentServerTime.timestamp).getTime();
            const deltaTime = initialDeviceTime - initialServerTime;
            let allowedTimeDifferenceMSec = allowedTimeDifference * 60000;  
            if (deltaTime > allowedTimeDifferenceMSec) {
                showError({ type: 'TimeDifferenceExceeded' })
            }
        }).catch(error=>{
            error = error || {}
            const responseBody = error.responseBody || {}
            if(responseBody?.isGeoBlocked) {
                dispatch({ type: 'IS_GEO_BLOCKED', value: true })
                setShowGeoLocError(true);
            }
            if(responseBody?.isVPNBlocked) {
                dispatch({ type: 'IS_VPN_BLOCKED', value: true })
                setShowVPNError(true);
            }
        })
    }, [baseUrl, getServerTime, dispatch, allowedTimeDifference, showError])

    useEffect(()=>{
        if(showGeoLocError) {
            setTimeout(()=>{
                showError({ type: 'GeoLocationBlocked' })
            },10)
        }
        //  else {
        //     hideError({ type: 'GeoLocationBlocked' })
        // }
    },[showError, showGeoLocError, isShowSplash, pathname, hideError])

    useEffect(()=>{
        if(showVPNError) {
            setTimeout(()=>{
                showError({ type: 'AppVPNRestricted' })
            },10)

        } 
        // else {
        //     hideError({ type: 'AppVPNRestricted' })
        // }
    },[showError, showVPNError, isShowSplash, pathname, hideError])

    useEffect(() => {
        if (baseUrl)
            checkForTimeModifications()
    }, [baseUrl, checkForTimeModifications, pathname])


    useEffect(() => {
        document.addEventListener("visibilitychange", function () {
            // Browser tab is visible
            if (!document.hidden) {
                checkForTimeModifications()
            } 
        });
    }, [checkForTimeModifications])

    return null
}

export default TimeModificationHandler;