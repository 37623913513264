import React from "react";
import PropTypes from "prop-types";
/* --------------------------------------------- */
const InfoIconFilled = ({ color, ...rest }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clip-path="url(#clip0_356_64)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.54 0C12.4641 6.36041e-07 9.45724 0.912245 6.89984 2.62134C4.34244 4.33044 2.34936 6.75961 1.17271 9.60158C-0.00393522 12.4436 -0.311293 15.5707 0.289519 18.5873C0.89033 21.604 2.37232 24.3747 4.54802 26.549C6.72373 28.7234 9.49541 30.2036 12.5125 30.8024C15.5295 31.4013 18.6564 31.0919 21.4977 29.9135C24.3389 28.735 26.7668 26.7403 28.4742 24.1818C30.1817 21.6233 31.092 18.6159 31.09 15.54C31.0847 11.4184 29.4444 7.46733 26.529 4.55388C23.6137 1.64042 19.6616 0.00264542 15.54 0V0ZM16.59 23C16.5644 23.3441 16.404 23.6642 16.1437 23.8907C15.8835 24.1173 15.5443 24.232 15.2 24.21C14.8567 24.2319 14.5187 24.1169 14.26 23.8901C14.0013 23.6633 13.8431 23.3432 13.82 23V14.28C13.8431 13.9368 14.0013 13.6167 14.26 13.3899C14.5187 13.1631 14.8567 13.0481 15.2 13.07C15.5443 13.048 15.8835 13.1627 16.1437 13.3893C16.404 13.6158 16.5644 13.9359 16.59 14.28V23ZM16.59 10.36C16.5605 10.707 16.4018 11.0303 16.1453 11.2659C15.8888 11.5015 15.5533 11.6322 15.205 11.6322C14.8567 11.6322 14.5212 11.5015 14.2647 11.2659C14.0082 11.0303 13.8495 10.707 13.82 10.36V8.9C13.8495 8.55299 14.0082 8.22971 14.2647 7.99411C14.5212 7.75852 14.8567 7.62779 15.205 7.62779C15.5533 7.62779 15.8888 7.75852 16.1453 7.99411C16.4018 8.22971 16.5605 8.55299 16.59 8.9V10.36Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_356_64">
        <rect width="31.09" height="31.09" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
/* --------------------------------------------- */
InfoIconFilled.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object,
};
/* --------------------------------------------- */
InfoIconFilled.defaultProps = {
  color: "transparent",
  style: {
    width: 22,
    height: 22,
  },
};
/* --------------------------------------------- */
export default InfoIconFilled;
/* --------------------------------------------- */
