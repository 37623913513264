

import styled from 'styled-components'
import utils from '../../utils/utils'

// Styled components used in ConfirmPopUp component.
export const PopUpContent = styled.div`
  background: ${({ theme: { body } }) => body && body.background.primary};
  }
`
/* --------------------------------------------- */
export const ButtonWrapper = styled.div`
  text-transform: uppercase;
  & .popup-btn__positive {
    background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.normal.background};
    color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.normal.text};
    border: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'stroke') ? `1px solid ${compositeStyle.primaryButton.normal.stroke}`: ''};
    border-radius: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'primaryButton', 'edgeRadius') && compositeStyle.primaryButton.edgeRadius * 16}px;
    box-shadow: ${({ theme: { compositeStyle } }) => (utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'shadow') && compositeStyle.primaryButton.normal.shadow )? '0 6px 8px 2px rgba(0, 0, 0, 0.48)' : ''};
    &:hover {
      background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.focussed.background};
      color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.focussed.text};
      border: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'primaryButton', 'focussed', 'stroke') ? `1px solid ${compositeStyle.primaryButton.focussed.stroke}`: ''};
    }
  }

  & .popup-btn__negative {
    background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.secondaryButton.normal.background};
    color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.secondaryButton.normal.text};
    border: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'normal', 'stroke') ? `1px solid ${compositeStyle.secondaryButton.normal.stroke}`: ''};
    border-radius: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'edgeRadius') && compositeStyle.secondaryButton.edgeRadius * 16}px;
    box-shadow: ${({ theme: { compositeStyle } }) => (utils.isNotNull(compositeStyle, 'secondaryButton', 'normal', 'shadow') && compositeStyle.secondaryButton.normal.shadow )? '0 6px 8px 2px rgba(0, 0, 0, 0.48)' : ''};
    &:hover {
      background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.secondaryButton.focussed.background};
      color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.secondaryButton.focussed.text};
      border: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'focussed', 'stroke') ? `1px solid ${compositeStyle.secondaryButton.focussed.stroke}`: ''};
    }
  }
`
/* --------------------------------------------- */
export const HeaderTextPrimaryH2 = styled.h2`
  color: ${({ theme: { header } }) => header && header.text.primary};
`
/* --------------------------------------------- */
export const HeaderTextSecondaryP = styled.p`
    color: ${({ theme: { body } }) => body && body.text.primary};
`

