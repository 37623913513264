import {
  CATALOG_CONFIG
} from '../constants/actionTypes'
import inialState from './initalState'
/* --------------------------------------------- */
/** Catalog config reducer */
const catalog = (state = inialState, action) => {
  switch (action.type) {
    case CATALOG_CONFIG:
      return {
        ...state,
        catalogConfig: action.value
      }
    default: return state
  }
}
/* --------------------------------------------- */
export default catalog
/* --------------------------------------------- */
