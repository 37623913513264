import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const PlayOutlineIconFilled = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox="0 0 64 64" {...rest}>
    <g id="Layer_2_1_" style={{ display: 'none' }}>
      <rect x="-148" y="-148" style={{ display: 'inline' }} width="360" height="360" />
    </g>
    <polygon fill={color} points="17.1,1.7 49.7,32.9 17.1,63 " />
  </svg>
)
/* --------------------------------------------- */
PlayOutlineIconFilled.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
PlayOutlineIconFilled.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default PlayOutlineIconFilled
/* --------------------------------------------- */
