import React, { useContext } from 'react'
import styled from "styled-components";
import utils from "../../../utils/utils";
import { Context } from "./context";
/* ---------------------------------------------------------------------------------------------------- */
export const Wrapper = (props) => {
  const { errorToast } = useContext(Context)
  const { theme } = errorToast
  /* -------------------------------------------------------------------------------------------------- */
  return <StyledWrapper theme={theme}>{props.children}</StyledWrapper>
};
/* ---------------------------------------------------------------------------------------------------- */
export const StyledWrapper = styled.div`
  .toast.success-toast {
    border-color: ${({ theme }) => {
      if (utils.isNotNull(theme, "compositeStyle", "toastTheme", "accent", "secondary")) {
        return `${theme.compositeStyle.toastTheme.accent.secondary}`;
      } else {
        return "transparent";
      }
    }}
  }
  .toast {
    border-color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "toastTheme", "accent", "primary")) {
      return `${theme.compositeStyle.toastTheme.accent.primary}`;
    } else {
      return "transparent";
    }
  }};
    border-radius: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "toastTheme", "curvedEdges")) {
      if (theme.compositeStyle.toastTheme.curvedEdges) return "5px"
      else return "0px"
    } else {
      return "0px";
    }
  }};
  background-color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "toastTheme", "background", "primary")) {
      return theme.compositeStyle.toastTheme.background.primary;
    } else {
      return "transparent";
    }
  }};
  }

  .desc {
    color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "toastTheme", "text", "primary")) {
      return theme.compositeStyle.toastTheme.text.primary;
    } else {
      return "transparent";
    }
  }};
  }

  .toast___action {
    color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "toastTheme", "text", "secondary")) {
      return theme.compositeStyle.toastTheme.text.secondary;
    } else {
      return "transparent";
    }
  }};
  }
  .toast .toast___action.success {
    color: ${({ theme }) => {
      if (utils.isNotNull(theme, "compositeStyle", "toastTheme", "accent", "secondary")) {
        return `${theme.compositeStyle.toastTheme.accent.secondary}`;
      } else {
        return "transparent";
      }
    }}
  } 
`;
/* ---------------------------------------------------------------------------------------------------- */
