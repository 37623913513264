import { getNewObj } from '../utils/contentfulHandler'
import getColor from './getColor'
import getColorGradient from './getColorGradient'
import getGraphics from './getGraphics'

// Helper function to form contentful tree
const getButtonColor = (contentful) => {
  const colorLocal = getColor(contentful)
  const colorGradientLocal = getColorGradient(contentful)
  const graphicsLocal = getGraphics(contentful)
  const buttonColorLocal = contentful.buttonColor
  return buttonColorLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { background = {}, text = {}, stroke = {}, image = {}, buttonGraphics = {} } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      background: getNewObj(background, [colorLocal, colorGradientLocal]),
      text: getNewObj(text, [colorLocal, colorGradientLocal]),
      stroke: getNewObj(stroke, [colorLocal, colorGradientLocal]),
      image: getNewObj(image, [colorLocal, colorGradientLocal]),
      buttonGraphics: getNewObj(buttonGraphics, [graphicsLocal])
    }
  })
}

export default getButtonColor
