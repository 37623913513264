import React, { useState, useEffect, useRef, useCallback, forwardRef, useContext, useImperativeHandle } from 'react';
import visitorInfo from 'visitor-info'
import PropTypes from 'prop-types'
import * as m3u8Parser from 'm3u8-parser'
import { useSelector } from 'react-redux'
import _ from '../../utils/lodash.custom'
/* --------------------------------------------- */
import { app, version, isLocalHost } from '../../config'
/* --------------------------------------------- */
import useGeneralApi from '../../apis/useGeneralApi'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
import { PlayerContext } from '../../context/player.context'
import { getAppLanguageWithoutRegion, getSelectedPlayerQuality, getSelectedSubtitleTrack } from '../../utils/localStorageService'
// import { getFormattedTokenInBase64 } from '../../utils/player.utils'
import { getBrowserName } from '../../utils/contentfulHandler'
import useLoggly from '../../containers/useLoggly';
import usePlaybackRetry from '../../hooks/usePlaybackRetry';
import { getDescriptionOfPlayerError, getErrorTitle } from './MuxErrorHandler';
// import { isMobile } from 'react-device-detect';
// import { useEffectDebugger } from '../../utils/debugger'
/* --------------------------------------------- */
const { browser = {}, engine = {} } = visitorInfo()
const localLang = getAppLanguageWithoutRegion()
/* --------------------------------------------- */
const isIE = getBrowserName((browser || {}).name, (engine || {}).name) === 'ie'
const isSafari = getBrowserName((browser || {}).name, (engine || {}).name) === 'safari'
const isEdgeLegacy = getBrowserName((browser || {}).name, (engine || {}).name) === 'edge'
/* --------------------------------------------- */
// ShakaPlayer component to handle shaka events and playback
const ShakaPlayer = forwardRef(({
    url, pid, title, mediaId, playbackAssetFormat, isEncrypted, uuid,
    onLoad, onBuffer, onBufferComplete, onPlay, onPause, onCurrentTimeChange, onComplete,
    onCrash, onLargeGap, onUnload, fairplayCertificate, isMuted,
    qualityMappingMode = 'height', qualityMapping = [], abrMode = 'absolute', debugModeEnabled = true, isShakaPlayerMount = false, currentProgram
}, ref) => {
    const { appMessages } = useSelector(store => store.appConfigReducers)
    const videoTagRef = useRef(null)
    const shakaInstanceRef = useRef(null)
    const isAutoQualityRef = useRef(false)
    const [playbackError, setPlaybackError] = useState(null)
    const { supportConfig = {} } = useSelector(store => store.support)
    // const subtitleTextRef = useRef('')
    const isSubtitleOff = useRef(true)
    const playStateRef = useRef(null)
    const selectedQualityRef = useRef('')
    const titleRef = useRef(title)
    /* --------------------------------------------- */
    const [availableSafariQualities, setAvailableSafariQualities] = useState([])
    const [availableQualities, setAvailableQualities] = useState([])
    const [selectedQuality, setSelectedQuality] = useState('')
    const [isBrowserSupported, setBrowserSupported] = useState(false)
    /* --------------------------------------------- */
    const { getData } = useGeneralApi()
    /* --------------------------------------------- */
    const { analyticsIntegrations = [] } = useSelector(store => store.analytics)
    /* --------------------------------------------- */
    const { userInfo, userToken, selectedProfile } = useContext(GlobalContext)
    /* --------------------------------------------- */
    const {
        shakaConfig = {}, onHDCPError, onStart, dispatch,
        abrStartingProfile = [], currentPlayerMediaData = {}, releaseUrl,
        startPos = 0, videoHeight = 0, videoWidth = 0, frameRate, videoBandwidth,
        onPlaybackDRMError, onPlaybackGenericError, onPlaybackManifestFailed, onRecoverablePlayerError, onBrowserUnsupported, onObjectDestructionError,
        drmToken, retryPlayback, tokenExpiryRetryCount, recoverableErrorRetryCount
    } = useContext(PlayerContext)
    /* --------------------------------------------- */
    const isPlaybackStartedRef = useRef(false)
    const qualityMappingRef = useRef(qualityMapping)
    const qualityMappingModeRef = useRef(qualityMappingMode)
    const abrStartingProfileRef = useRef(abrStartingProfile)
    const videoHeightRef = useRef(videoHeight)
    const videoWidthRef = useRef(videoWidth)
    const frameRateRef = useRef(frameRate)
    const videoBandwidthRef = useRef(videoBandwidth)
    const abrModeRef = useRef(abrMode)
    const labelAutoRef = useRef(appMessages.label_player_auto)
    const startPosRef = useRef(startPos)
    const userTokenRef = useRef(userToken)
    const userIdRef = useRef((userInfo && userInfo.userId) || {})
    const profileIdRef = useRef((selectedProfile && selectedProfile.profileId) || '')
    const errorConfigurationRef = useRef(supportConfig.errorConfiguration || [])
    const analyticsIntegrationsRef = useRef(analyticsIntegrations || [])
    const shakaConfigRef = useRef(shakaConfig || {})
    const shaka = window.shaka
    const { log } = useLoggly()

    /* --------------------------------------------- */
    useEffect(() => {
        qualityMappingRef.current = qualityMapping
    }, [qualityMapping])
    /* --------------------------------------------- */
    useEffect(() => {
        titleRef.current = title
    }, [title])
    /* --------------------------------------------- */
    useEffect(() => {
        qualityMappingModeRef.current = qualityMappingMode
        videoHeightRef.current = videoHeight
        videoWidthRef.current = videoWidth
        frameRateRef.current = frameRate
        videoBandwidthRef.current = videoBandwidth
    }, [qualityMappingMode, videoHeight, videoWidth, frameRate, videoBandwidth])
    /* --------------------------------------------- */
    useEffect(() => {
        abrStartingProfileRef.current = abrStartingProfile
    }, [abrStartingProfile])
    /* --------------------------------------------- */
    useEffect(() => {
        abrModeRef.current = abrMode
    }, [abrMode])
    /* --------------------------------------------- */
    useEffect(() => {
        labelAutoRef.current = appMessages.label_player_auto
    }, [appMessages.label_player_auto])
    /* --------------------------------------------- */
    useEffect(() => {
        startPosRef.current = startPos
    }, [startPos])
    /* --------------------------------------------- */
    useEffect(() => {
        userTokenRef.current = userToken
    }, [userToken])
    /* --------------------------------------------- */
    useEffect(() => {
        userIdRef.current = userInfo.userId
    }, [userInfo.userId])
    /* --------------------------------------------- */
    useEffect(() => {
        if(selectedProfile?.profileId){
            profileIdRef.current = selectedProfile.profileId
        }
    }, [selectedProfile])
    /* --------------------------------------------- */
    useEffect(() => {
        errorConfigurationRef.current = supportConfig.errorConfiguration || []
    }, [supportConfig.errorConfiguration])
    /* --------------------------------------------- */
    useEffect(() => {
        analyticsIntegrationsRef.current = analyticsIntegrations || []
    }, [analyticsIntegrations])
    /* --------------------------------------------- */
    useEffect(() => {
        shakaConfigRef.current = shakaConfig || {}
    }, [shakaConfig])
    /* --------------------------------------------- */
    useEffect(() => { selectedQualityRef.current = selectedQuality || '' }, [selectedQuality])
    /* --------------------------------------------- */
    /**
     * It: playback auto state.
     */
    const onAutoChange = useCallback((isAuto) => {
        isAutoQualityRef.current = isAuto || false
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Calls player play event.
     */
    const play = useCallback(() => {
        try{
            if (videoTagRef?.current?.play && typeof videoTagRef?.current?.play == 'function' ) videoTagRef.current.play() // sometimes play is undefined so checking play type is function and calling the function
        } catch(e) {
            log({
                type: 'Error', attr: {
                    module: 'Player',
                    debug_message: `From play event - Error while playing the video - ${title} - media id is ${mediaId}`,
                }
            })
        }
    }, [log, mediaId, title])
    /* --------------------------------------------- */
    /**
     * It: Calls player pause event.
     */
    const pause = useCallback(() => {
        try{    
            if (videoTagRef?.current?.pause) videoTagRef.current.pause()
        } catch {
            log({
                type: 'Error', attr: {
                    module: 'Player',
                    debug_message: `From pause event - Error while pausing the video - ${title} - mediaId is ${mediaId}`,
                }
            })
        }
    }, [log, mediaId, title])
    /* --------------------------------------------- */
    /**
     * It: Calls player mute event.
     */
    const mute = useCallback(async() => {
        videoTagRef.current.muted = true
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Calls player unmute event.
     */
    const unmute = useCallback(async() => {
        videoTagRef.current.muted = false
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Calls player pip enable event.
     */
    const pipStart = useCallback(async() => {
        try {
            if (!document.pictureInPictureElement) {
              // If you were fullscreen, leave fullscreen first.
              if (document.fullscreenElement) {
                document.exitFullscreen();
              }
              await videoTagRef?.current?.requestPictureInPicture();
            } else {
              await document.exitPictureInPicture();
            }
          } catch (error) {}
    },[])
    /* --------------------------------------------- */
    /**
     * It: Calls player pip exit event.
     */
     const pipExit = useCallback(async() => {
        try {
            if (document.pictureInPictureElement) {
              await document.exitPictureInPicture();
            }
          } catch (error) {}
    },[])
    /* --------------------------------------------- */
    /**
     * It: Gets current time.
     */
    const getCurrentTime = useCallback(() => {
        if (videoTagRef.current.currentTime) return videoTagRef.current.currentTime
        else return 0
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Sets current time.
     */
    const setCurrentTime = useCallback((currentTime = 0) => {
        videoTagRef.current.currentTime = currentTime
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Gets duration.
     */
    const getDuration = useCallback(() => {
        return videoTagRef.current.duration || 0
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Gets video Ele.
     */
    const getVideoEle = useCallback(()=>{
        return videoTagRef.current
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Gets player quality height.
     */
    const getUniquePlayerQualityHeight = useCallback(() => {
        let qualities = []
        if (isSafari) qualities = availableSafariQualities
        else qualities = shakaInstanceRef.current.getVariantTracks()
        /* --------------------------------------------- */
        let allQualities = []
        qualities.map((track) => {
            const { height } = track
            if (allQualities.indexOf(height) > -1) return null
            if (height) allQualities.push(height)
            return null
        })
        return allQualities.filter(Boolean)
    }, [availableSafariQualities])
    /* --------------------------------------------- */
    /**
     * It: Gets player quality bitrate.
     */
    const getUniquePlayerQualityBitrate = useCallback(() => {
        let qualities = []
        if (isSafari) qualities = availableSafariQualities
        else qualities = shakaInstanceRef.current.getVariantTracks()
        /* --------------------------------------------- */
        let allQualities = []
        qualities.map((track) => {
            const { videoBandwidth } = track
            if (allQualities.indexOf(videoBandwidth) > -1) return null
            if (videoBandwidth) allQualities.push(videoBandwidth)
            return null
        })
        return allQualities.filter(Boolean)
    }, [availableSafariQualities])
    /* --------------------------------------------- */
    /**
     * It: Gets all quality height.
     */
    const getAllQualityHeight = useCallback(() => {
        let qualities = getUniquePlayerQualityHeight()
        qualities = qualities
            .sort(function (a, b) { return a - b })
            .map(height => `${height}p`)
        return [appMessages.label_player_auto, ...qualities]
    }, [getUniquePlayerQualityHeight, appMessages.label_player_auto])
    /* --------------------------------------------- */
    /**
     * It: Gets quality height mapping.
     */
    const getQualityHeightMapping = useCallback(() => {
        let qualities = getUniquePlayerQualityHeight()
        return qualityMapping
            .filter(({ key = '' }) => qualities.filter(height => Number(key) === height).length > 0)
    }, [getUniquePlayerQualityHeight, qualityMapping])
    /* --------------------------------------------- */
    /**
     * It: Gets quality bitrate mapping.
     */
    const getQualityBitrateMapping = useCallback(() => {
        let qualities = getUniquePlayerQualityBitrate()
        return qualityMapping
            .filter(({ key = '' }) => qualities.filter(videoBandwidth => Number(key) === videoBandwidth).length > 0)
    }, [getUniquePlayerQualityBitrate, qualityMapping])
    /* --------------------------------------------- */
    /**
     * It: Gets quality height range mapping.
     */
    const getQualityHeightRangeMapping = useCallback(() => {
        let qualities = getUniquePlayerQualityHeight()
        return qualityMapping
            .filter(({ key = '' }) => {
                if (key.indexOf("-") === -1) return null
                const [start, end] = key.split("-")
                return qualities.filter(height => {
                    return Number(start) >= height && Number(end) <= height
                }).length > 0
            })
    }, [getUniquePlayerQualityHeight, qualityMapping])
    /* --------------------------------------------- */
    /**
     * It: Gets quality bitrate range mapping.
     */
    const getQualityBitrateRangeMapping = useCallback(() => {
        let qualities = getUniquePlayerQualityBitrate()
        return qualityMapping
            .filter(({ key = '' }) => {
                if (key.indexOf("-") === -1) return null
                const [start, end] = key.split("-")
                return qualities.filter(videoBandwidth => {
                    return Number(start) >= videoBandwidth && Number(end) <= videoBandwidth
                }).length > 0
            })
    }, [getUniquePlayerQualityBitrate, qualityMapping])
    /* --------------------------------------------- */
    /**
     * It: Gets quality height bitrate index mapping.
     */
    const getQualityHeightBitrateIndexMapping = useCallback(() => {
        let qualities = getUniquePlayerQualityHeight()
        qualities = qualities.sort(function (a, b) { return b - a })
        const maxHeight = qualities[0]
        let selectedQualities = qualityMapping
            .filter(({ key = '' }) => {
                if (key.indexOf("[") === -1) return null
                const [start] = key.split("[")
                return Number(start) === maxHeight
            })
        let allQualities = []
        if (isSafari) allQualities = availableSafariQualities
        else allQualities = shakaInstanceRef.current.getVariantTracks()
        return selectedQualities.slice(0, allQualities.length)
    }, [getUniquePlayerQualityHeight, qualityMapping, availableSafariQualities])
    /* --------------------------------------------- */
    /**
     * It: Gets all quality height mapping.
     */
    const getAllQualityHeightMapping = useCallback(() => {
        let qualities = getQualityHeightMapping()
        qualities = qualities.map(({ value = '' }) => value)
        return [appMessages.label_player_auto, ...qualities]
    }, [getQualityHeightMapping, appMessages.label_player_auto])
    /* --------------------------------------------- */
    /**
     * It: Gets quality bitrate mapping.
     */
    const getAllQualityBitrateMapping = useCallback(() => {
        let qualities = getQualityBitrateMapping()
        qualities = qualities.map(({ value = '' }) => value)
        return [appMessages.label_player_auto, ...qualities]
    }, [getQualityBitrateMapping, appMessages.label_player_auto])
    /* --------------------------------------------- */
    /**
     * It: Gets quality height range mapping.
     */
    const getAllQualityHeightRangeMapping = useCallback(() => {
        let qualities = getQualityHeightRangeMapping()
        qualities = qualities.map(({ value = '' }) => value)
        return [appMessages.label_player_auto, ...qualities]
    }, [getQualityHeightRangeMapping, appMessages.label_player_auto])
    /* --------------------------------------------- */
    /**
     * It: Gets all quality bitrate range mapping.
     */
    const getAllQualityBitrateRangeMapping = useCallback(() => {
        let qualities = getQualityBitrateRangeMapping()
        qualities = qualities.map(({ value = '' }) => value)
        return [appMessages.label_player_auto, ...qualities]
    }, [getQualityBitrateRangeMapping, appMessages.label_player_auto])
    /* --------------------------------------------- */
    /**
     * It: Gets all quality height bitrate index mapping.
     */
    const getAllQualityHeightBitrateIndexMapping = useCallback(() => {
        let qualities = getQualityHeightBitrateIndexMapping()
        qualities = qualities.map(({ value = '' }) => value)
        return [appMessages.label_player_auto, ...qualities]
    }, [getQualityHeightBitrateIndexMapping, appMessages.label_player_auto])
    /* --------------------------------------------- */
    /**
     * It: Gets all qualities.
     */
    const getAllQualities = useCallback(() => {
        if (availableQualities.length > 0) {
            return availableQualities
        } else {
            let qualities = []
            if (qualityMappingMode === 'height') qualities = getAllQualityHeight()
            else if (qualityMappingMode === 'heightMap') qualities = getAllQualityHeightMapping()
            else if (qualityMappingMode === 'bitrateMap') qualities = getAllQualityBitrateMapping()
            else if (qualityMappingMode === 'heightRange') qualities = getAllQualityHeightRangeMapping()
            else if (qualityMappingMode === 'bitrateRange') qualities = getAllQualityBitrateRangeMapping()
            else if (qualityMappingMode === 'heightBitrateIndex') qualities = getAllQualityHeightBitrateIndexMapping()
            setAvailableQualities(qualities)
            return qualities
        }
    }, [
        availableQualities, qualityMappingMode,
        getAllQualityHeight, getAllQualityHeightMapping,
        getAllQualityBitrateMapping, getAllQualityHeightRangeMapping,
        getAllQualityBitrateRangeMapping, getAllQualityHeightBitrateIndexMapping
    ])
    /* --------------------------------------------- */
    /**
     * It: Gets current quality height.
     */
    const getCurrentQualityHeight = useCallback(() => {
        const selectedQuality = shakaInstanceRef.current.getVariantTracks().filter(({ active }) => active)
        if (isAutoQualityRef.current || selectedQuality.length <= 0) return 'Auto'
        else return `${selectedQuality[0].height}p`
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Gets current quality height mapping.
     */
    const getCurrentQualityHeightMapping = useCallback(() => {
        let qualities = getQualityHeightMapping()
        const selectedQuality = shakaInstanceRef.current.getVariantTracks().filter(({ active }) => active)
        let selectedHeight = null
        if (isAutoQualityRef.current || selectedQuality.length <= 0) selectedHeight = null
        else selectedHeight = selectedQuality[0].height
        qualities = qualities.filter(({ key }) => {
            return Number(key) === selectedHeight
        })
        if (isAutoQualityRef.current || qualities.length <= 0) return 'Auto'
        else return qualities[0].value
    }, [getQualityHeightMapping])
    /* --------------------------------------------- */
    /**
     * It: Gets current quality bitrate mapping.
     */
    const getCurrentQualityBitrateMapping = useCallback(() => {
        let qualities = getQualityBitrateMapping()
        const selectedQuality = shakaInstanceRef.current.getVariantTracks().filter(({ active }) => active)
        let selectedBitrate = null
        if (isAutoQualityRef.current || selectedQuality.length <= 0) selectedBitrate = null
        else selectedBitrate = selectedQuality[0].videoBandwidth
        qualities = qualities.filter(({ key }) => {
            return Number(key) === selectedBitrate
        })
        if (isAutoQualityRef.current || qualities.length <= 0) return 'Auto'
        else return qualities[0].value
    }, [getQualityBitrateMapping])
    /* --------------------------------------------- */
    /**
     * It: Gets current quality height range mapping.
     */
    const getCurrentQualityHeightRangeMapping = useCallback(() => {
        let qualities = getQualityHeightRangeMapping()
        const selectedQuality = shakaInstanceRef.current.getVariantTracks().filter(({ active }) => active)
        let selectedHeight = null
        if (isAutoQualityRef.current || selectedQuality.length <= 0) selectedHeight = null
        else selectedHeight = selectedQuality[0].height
        qualities = qualities.filter(({ key = '' }) => {
            if (key.indexOf("-") === -1) return null
            const [start, end] = key.split("-")
            return Number(start) >= selectedHeight && Number(end) <= selectedHeight
        })
        if (isAutoQualityRef.current || qualities.length <= 0) return 'Auto'
        else return qualities[0].value
    }, [getQualityHeightRangeMapping])
    /* --------------------------------------------- */
    /**
     * It: Gets current quality bitrate range mapping.
     */
    const getCurrentQualityBitrateRangeMapping = useCallback(() => {
        let qualities = getQualityBitrateRangeMapping()
        const selectedQuality = shakaInstanceRef.current.getVariantTracks().filter(({ active }) => active)
        let selectedBitrate = null
        if (isAutoQualityRef.current || selectedQuality.length <= 0) selectedBitrate = null
        else selectedBitrate = selectedQuality[0].videoBandwidth
        qualities = qualities.filter(({ key = '' }) => {
            if (key.indexOf("-") === -1) return null
            const [start, end] = key.split("-")
            return Number(start) >= selectedBitrate && Number(end) <= selectedBitrate
        })
        if (isAutoQualityRef.current || qualities.length <= 0) return 'Auto'
        else return qualities[0].value
    }, [getQualityBitrateRangeMapping])
    /* --------------------------------------------- */
    /**
     * It: Gets current quality height bitrate index mapping.
     */
    const getCurrentQualityHeightBitrateIndexMapping = useCallback(() => {
        let qualities = getQualityHeightBitrateIndexMapping()
        let allQualities = shakaInstanceRef.current.getVariantTracks()
        allQualities = allQualities.sort(function (a, b) { return b.videoBandwidth - a.videoBandwidth })
        const selectedQuality = allQualities.filter(({ active }) => active)
        if (isAutoQualityRef.current || selectedQuality.length <= 0) qualities = []
        else {
            const selectedTrackIndex = allQualities.map((track, i) => {
                if (track.videoBandwidth === selectedQuality[0].videoBandwidth) return i + ''
                else return null
            }).filter(Boolean)
            if (selectedTrackIndex.length > 0) {
                qualities = qualities.filter(({ key = '' }) => {
                    const textInSquareBrackets = key.match(/\[(.*?)\]/) || [];
                    return textInSquareBrackets[1] === selectedTrackIndex[0]
                })
            } else {
                qualities = []
            }
        }
        if (isAutoQualityRef.current || qualities.length <= 0) return 'Auto'
        else return qualities[0].value
    }, [getQualityHeightBitrateIndexMapping])
    /* --------------------------------------------- */
    /**
     * It: Gets current quality.
     */
    const getCurrentQuality = useCallback(() => {
        const labelAuto = labelAutoRef.current || ''
        const selectedQuality = selectedQualityRef.current || labelAuto
        if (selectedQuality) return selectedQuality
        else {
            if (qualityMappingMode === 'height') return getCurrentQualityHeight()
            else if (qualityMappingMode === 'heightMap') return getCurrentQualityHeightMapping()
            else if (qualityMappingMode === 'bitrateMap') return getCurrentQualityBitrateMapping()
            else if (qualityMappingMode === 'heightRange') return getCurrentQualityHeightRangeMapping()
            else if (qualityMappingMode === 'bitrateRange') return getCurrentQualityBitrateRangeMapping()
            else if (qualityMappingMode === 'heightBitrateIndex') return getCurrentQualityHeightBitrateIndexMapping()
        }
    }, [
        qualityMappingMode,
        getCurrentQualityHeight, getCurrentQualityHeightMapping,
        getCurrentQualityBitrateMapping, getCurrentQualityHeightRangeMapping,
        getCurrentQualityBitrateRangeMapping, getCurrentQualityHeightBitrateIndexMapping
    ])
    /* --------------------------------------------- */
    /**
     * It: Sets current quality.
     */
    const setCurrentQuality = useCallback((quality = '') => {
        const player = shakaInstanceRef.current || {}
        const tracks = player.getVariantTracks() || []
        const abrMode = abrModeRef.current || ''
        const qualityMapping = qualityMappingRef.current || []
        const abrStartingProfile = abrStartingProfileRef.current || ''
        const previousQuality = selectedQualityRef.current || ''
        setCurrentQuality_1({
            player, tracks, qualityMappingMode, quality, qualityMapping,
            abrStartingProfile, abrMode, onAutoChange, setSelectedQuality, dispatch,
            previousQuality
        })
    }, [qualityMappingMode, dispatch, onAutoChange])
    /* --------------------------------------------- */
    /**
     * It: Gets all subtitles.
     */
    const getAllSubtitles = useCallback(() => {
        let allSubtitles = []
        shakaInstanceRef.current.getTextTracks().map(({ label, language, kind }) => {
            if (kind === 'subtitles' || kind === 'subtitle') {
                for (let i = 0; i < allSubtitles.length; i++) {
                    if (allSubtitles[i].language === language) return null
                }
                allSubtitles.push({ label, language })
            }
            return null
        })
        if (allSubtitles.length === 0) return []
        return [...allSubtitles, { label: appMessages.label_player_off, language: 'none' }]
    }, [appMessages.label_player_off])
    /* --------------------------------------------- */
    /**
     * It: Gets current subtitle.
     */
    const getCurrentSubtitle = useCallback(() => {
        const selectedSubtitle = shakaInstanceRef.current.getTextTracks().filter(({ active }) => active)
        if (isSubtitleOff.current || selectedSubtitle.length <= 0) return { label: appMessages.label_player_off, language: 'none' }
        else return { label: selectedSubtitle[0].label, language: selectedSubtitle[0].language }
    }, [appMessages.label_player_off])
    /* --------------------------------------------- */
    /**
     * It: Sets current subtitle.
     */
    const setCurrentSubtitle = useCallback((subtitleLang) => {
        if (!subtitleLang || subtitleLang === 'none') shakaInstanceRef.current.setTextTrackVisibility(false)
        else shakaInstanceRef.current.setTextTrackVisibility(true)
        /* --------------------------------------------- */
        const selectedSubtitle = shakaInstanceRef.current.getTextTracks().filter((track) => (subtitleLang === track.language))
        if (selectedSubtitle.length > 0) shakaInstanceRef.current.selectTextTrack(selectedSubtitle[0])
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Calls on complete playabck.
     */
    const _onComplete = useCallback(() => {
        pause() // In IE on complete play state is not changed automatically.
        onComplete()
    }, [pause, onComplete])
    /* --------------------------------------------- */
    /**
     * It: Returns mux attributes.
     */
    const getMuxMetaData = useCallback((props) => {
        const { selectedSubTitleTrack, selectedPlayerQuality } = props || {}
        return {
            viewer_user_id: userIdRef.current || '',
            page_type: uuid,
            player_is_fullscreen: true,
            experiment_name: profileIdRef.current || '',
            sub_property_id: version.buildType.toLowerCase(),
            player_name: `${app.name} - ShakaPlayer`,
            player_version: version.number,
            player_init_time: window.muxPlayerInitTime,
            debug: isLocalHost,
            video_id: mediaId,
            video_title: titleRef.current || '',
            video_series: currentPlayerMediaData.seriesTitle ? currentPlayerMediaData.seriesTitle : '',
            video_variant_name: selectedSubTitleTrack === "none" ? '' : selectedSubTitleTrack,
            video_variant_id: pid,
            video_language_code: localLang,
            video_content_type: currentPlayerMediaData.isTrailer ? 'trailer' : currentPlayerMediaData.type,
            video_duration: currentPlayerMediaData.duration,
            video_stream_type: (currentPlayerMediaData.type === "liveEvent" || currentPlayerMediaData.type === "liveevent") ? 'live' : 'vod', //need to change once startover and catchup are available
            video_producer: `${videoWidthRef.current}x${videoHeightRef.current}, ${videoBandwidthRef.current} @ ${frameRateRef.current} `,
            video_encoding_variant: playbackAssetFormat,
            video_cdn: releaseUrl,
            video_source_url: url,
            custom_1: currentProgram?.title ? currentProgram?.title : titleRef.current ? titleRef.current : '',
            // custom_2: `${videoWidthRef.current}x${videoHeightRef.current}, ${videoBandwidthRef.current} @ ${frameRateRef.current ? Math.ceil(frameRateRef.current): 0}fps `
            custom_2: selectedPlayerQuality || '',
            custom_5: version.number, //app version
        }
    }, [currentPlayerMediaData.duration, currentPlayerMediaData.isTrailer, currentPlayerMediaData.seriesTitle, currentPlayerMediaData.type, currentProgram, mediaId, pid, playbackAssetFormat, releaseUrl, url, uuid])
    /* --------------------------------------------- */
    useImperativeHandle(ref, () => {
        return {
            play, pause, mute, unmute, getCurrentTime, setCurrentTime, getDuration,
            getAllQualities, getCurrentQuality, setCurrentQuality,
            getAllSubtitles, getCurrentSubtitle, setCurrentSubtitle, getVideoEle, pipStart, pipExit
        }
    });
    /* --------------------------------------------- */
    /**
     * It: Sets default subtitles as inactive
     */
    useEffect(() => {
      const selectedSubTitleTrack = getSelectedSubtitleTrack()
       if(shakaInstanceRef.current && isSafari && selectedSubTitleTrack.language === 'none') {
         setTimeout(() => {
           setCurrentSubtitle('none')
         }, 3000)
       }
    }, [isShakaPlayerMount, setCurrentSubtitle])
    /* --------------------------------------------- */
    /**
     * It: Stores mux player init time.
     */
    useEffect(() => {
        window.muxPlayerInitTime = Date.now();
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Sets browser supported state.
     */
    useEffect(() => {
        // const shaka = window.shaka
        if(!shaka) return
        /* --------------------------------------------- */
        shaka.polyfill.installAll()
        /* --------------------------------------------- */
        let isBrowserSupported = shaka.Player.isBrowserSupported()
        if (['IE', 'IEMobile'].indexOf(browser.name) > -1) isBrowserSupported = false
        /* --------------------------------------------- */
        setBrowserSupported(isBrowserSupported)
        /* --------------------------------------------- */
        if (!isBrowserSupported) {
            const errorInfo = { browser: browser.name }
            if (onBrowserUnsupported) onBrowserUnsupported(errorInfo)
        }
    }, [onBrowserUnsupported, shaka])
    /* --------------------------------------------- */
    /**
     * It: It fetches manifest in safari.
     */
    useEffect(() => {
        let isMounted = true
        if (!isBrowserSupported) return
        if (!url) return
        if (!isSafari) return
        /* --------------------------------------------- */
        getData(url).then((manifest) => {
            if (!manifest) return
            /* --------------------------------------------- */
            const parser = new m3u8Parser.Parser();
            parser.push(manifest);
            parser.end();
            /* --------------------------------------------- */
            const parsedManifest = parser.manifest || {};
            const { playlists = [] } = parsedManifest
            const qualities = playlists.map(({ attributes = {} }) => {
                const { RESOLUTION = {}, BANDWIDTH = null } = attributes
                return {
                    height: RESOLUTION.height || null,
                    videoBandwidth: BANDWIDTH
                }
            })
            if (!isMounted) return
            setAvailableSafariQualities(qualities)
        }).catch(() => { })

        return () => {
          isMounted =  false;
        }
    }, [url, getData, isBrowserSupported])

    /* --------------------------------------------- */
    /**
     * It: Loads player.
     * It: Handles playback events.
     */
    const playerEffect = () => {
        let isMounted = true
        if (!isBrowserSupported) return
        // if (!url || (isSafari && isEncrypted && !fairplayCertificate)) return
        /* --------------------------------------------- */
        const shaka = window.shaka
        // const token = userTokenRef.current || ''
        const player = videoTagRef.current
        /* --------------------------------------------- */
        player.mute = true;
        // let keySystem;
        // const certificate = fairplayCertificate;
        // const releasePid = pid
        // const serverProcessSPCPath = 'https://drm-fairplay-licensing.axprod.net/AcquireLicense'
        /* --------------------------------------------- */
        // function stringToArray(string) {
        //     const buffer = new ArrayBuffer(string.length * 2); // 2 bytes for each char
        //     const array = new Uint16Array(buffer);
        //     for (let i = 0, strLen = string.length; i < strLen; i++) {
        //         array[i] = string.charCodeAt(i);
        //     }
        //     return array;
        // }

        // function arrayToString(array) {
        //     const uint16array = new Uint16Array(array.buffer);
        //     return String.fromCharCode.apply(null, uint16array);
        // }

        // function base64DecodeUint8Array(input) {
        //     const raw = window.atob(input);
        //     const rawLength = raw.length;
        //     const array = new Uint8Array(new ArrayBuffer(rawLength));

        //     for (let i = 0; i < rawLength; i++)
        //         array[i] = raw.charCodeAt(i);

        //     return array;
        // }

        // function base64EncodeUint8Array(input) {
        //     const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        //     let output = "";
        //     let chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        //     let i = 0;

        //     while (i < input.length) {
        //         chr1 = input[i++];
        //         chr2 = i < input.length ? input[i++] : Number.NaN; // Not sure if the index
        //         chr3 = i < input.length ? input[i++] : Number.NaN; // checks are needed here

        //         enc1 = chr1 >> 2;
        //         enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        //         enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        //         enc4 = chr3 & 63;

        //         if (isNaN(chr2)) {
        //             enc3 = enc4 = 64;
        //         } else if (isNaN(chr3)) {
        //             enc4 = 64;
        //         }
        //         output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
        //             keyStr.charAt(enc3) + keyStr.charAt(enc4);
        //     }
        //     return output;
        // }

        // function waitForEvent(name, action, target) {
        //     target.addEventListener(name, function () {
        //         action(arguments[0]);
        //     }, false);
        // }

        // function extractHostname(url) {
        //     let hostname;
        //     //find & remove protocol (http, ftp, etc.) and get hostname

        //     if (url.indexOf("//") > -1) {
        //         hostname = url.split('/')[2];
        //     }
        //     else {
        //         hostname = url.split('/')[0];
        //     }

        //     //find & remove port number
        //     hostname = hostname.split(':')[0];
        //     //find & remove "?"
        //     hostname = hostname.split('?')[0];

        //     return hostname;
        // }

        // function extractContentId(initData) {
        //     let contentId = arrayToString(initData);
        //     contentId = extractHostname(contentId)
        //     contentId = releasePid
        //     return contentId;
        // }

        // function concatInitDataIdAndCertificate(initData, id, cert) {
        //     if (typeof id == "string")
        //         id = stringToArray(id);
        //     // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
        //     let offset = 0;
        //     const buffer = new ArrayBuffer(initData.byteLength + 4 + id.byteLength + 4 + cert.byteLength);
        //     const dataView = new DataView(buffer);

        //     const initDataArray = new Uint8Array(buffer, offset, initData.byteLength);
        //     initDataArray.set(initData);
        //     offset += initData.byteLength;

        //     dataView.setUint32(offset, id.byteLength, true);
        //     offset += 4;

        //     const idArray = new Uint16Array(buffer, offset, id.length);
        //     idArray.set(id);
        //     offset += idArray.byteLength;

        //     dataView.setUint32(offset, cert.byteLength, true);
        //     offset += 4;

        //     const certArray = new Uint8Array(buffer, offset, cert.byteLength);
        //     certArray.set(cert);

        //     return new Uint8Array(buffer, 0, buffer.byteLength);
        // }

        // function selectKeySystem() {
        //     if (window.WebKitMediaKeys.isTypeSupported("com.apple.fps.1_0", "video/mp4")) {
        //         keySystem = "com.apple.fps.1_0";
        //     }
        //     else {
        //         throw new Error("Key System not supported");
        //     }
        // }

        // function onneedkey(event) {
        //   console.log("onneedkey")
        //     const video = event.target;
        //     let initData = event.initData;
        //     const contentId = extractContentId(initData);
        //     initData = concatInitDataIdAndCertificate(initData, contentId, "https://drm.csport.tv/fairplay.cer");

        //     if (!video.webkitKeys) {
        //         selectKeySystem();
        //         video.webkitSetMediaKeys(new window.WebKitMediaKeys(keySystem));
        //     }

        //     if (!video.webkitKeys)
        //         throw new Error("Could not create MediaKeys");

        //     const keySession = video.webkitKeys.createSession("video/mp4", initData);
        //     if (!keySession)
        //         throw new Error("Could not create key session");

        //     keySession.contentId = contentId;
        //     waitForEvent('webkitkeymessage', licenseRequestReady, keySession);
        //     waitForEvent('webkitkeyadded', onkeyadded, keySession);
        //     waitForEvent('webkitkeyerror', onkeyerror, keySession);
        // }

        // function licenseRequestReady(event) {
        //     const session = event.target;
        //     const message = event.message;
        //     const request = new XMLHttpRequest();
        //     request.responseType = 'text';
        //     request.session = session;
        //     request.addEventListener('load', licenseRequestLoaded, false);
        //     request.addEventListener('error', licenseRequestFailed, false);
        //     request.open('POST', 'https://drm-fairplay-licensing.axprod.net/AcquireLicense', true);
        //     request.setRequestHeader("Content-type", "application/json;charset=UTF-8");
        //     // request.setRequestHeader('Authorization', getFormattedTokenInBase64(token));
        //     request.setRequestHeader('X-AxDRM-Message',drmToken);
        //     console.log("Content ID is: " + session.contentId);

        //     let params = {
        //         getFairplayLicense: {
        //             spcMessage: base64EncodeUint8Array(message),
        //             releasePid: session.contentId
        //         }
        //     }
        //     params = JSON.stringify(params)
        //     request.send(params)
        // }

        // function licenseRequestLoaded(event) {
        //     const request = event.target;
        //     const session = request.session;
        //     if (request.response) {
        //         let keyText = JSON.parse(request.response)
        //         if (keyText) {
        //             const getFairplayLicenseResponse = keyText.getFairplayLicenseResponse
        //             if (getFairplayLicenseResponse) {
        //                 keyText = getFairplayLicenseResponse.ckcResponse
        //                 if (keyText) {
        //                     const key = base64DecodeUint8Array(keyText);
        //                     session.update(key);
        //                 }
        //             }
        //         }
        //     }
        // }

        // function licenseRequestFailed(event) {
        //     console.error('The license request failed.');
        // }

        // function onkeyerror(event) {
        //     console.error('A decryption key error was encountered');
        // }

        // function onkeyadded(event) {
        //     console.log('Decryption key was added to session.');
        // }
        /* --------------------------------------------- */
        /**
         * It: Intializes shaka player
         */
        const shakaInstance = new shaka.Player(player);
        window.shaka = shaka
        window.shakaInstance = shakaInstance
        shakaInstanceRef.current = shakaInstance
        let config = {}
        if (pid && isEncrypted) {
            if (isSafari) {
                config = {
                    drm: {
                        servers: {
                            'com.apple.fps.1_0': 'https://drm-fairplay-licensing.axprod.net/AcquireLicense'
                        },
                        advanced: {
                            'com.apple.fps.1_0': {
                                serverCertificateUri: "https://d1nbsad5sxy4x0.cloudfront.net/fairplay.cer"
                            }
                        }
                    }
                }
            } else if (isEdgeLegacy || isIE) {
                // const account = getMpxAccountURL() || ''
                config = {
                    drm: {
                        servers: {
                            'com.microsoft.playready': `https://drm-playready-licensing.axprod.net/AcquireLicense`
                        }
                    }
                }
            } else {
                config = {
                    drm: {
                        servers: {
                            'com.widevine.alpha': `https://drm-widevine-licensing.axprod.net/AcquireLicense`
                        }
                    }
                }
            }
        }
        // config.streaming = {}
        // config.manifest = {}
        // config.streaming.lowLatencyMode =  true
        const mergedShakaConfig = _.mergeWith({}, config, shakaConfigRef.current)
        window.shakaConfig = mergedShakaConfig
        if(mergedShakaConfig?.liveOffset) delete mergedShakaConfig.liveOffset
        /* --------------------------------------------- */
        /**
         * It: Intercepts network request and add token in header.
         */
        // if (!isSafari) {
            let _token;
            if (drmToken) {
                _token = drmToken
            }
            shakaInstance.getNetworkingEngine().registerRequestFilter(function (type, request) {
                // Only add headers to license requests:
                if(request && request.uris && !request.uris.includes("https://d1nbsad5sxy4x0.cloudfront.net/fairplay.cer")) {
                    request.allowCrossSiteCredentials = true;
                }
                if (type === shaka.net.NetworkingEngine.RequestType.LICENSE) {
                    request.allowCrossSiteCredentials = false;                
                    const { keySystem = '' } = shakaInstance.drmInfo() || {}
                    if (keySystem !== 'com.microsoft.playready') {
                        // This is the specific header name and value the server wants:
                        request.headers['X-AxDRM-Message'] = _token   
                    }
                }
                // if (type === shaka.net.NetworkingEngine.RequestType.MANIFEST || type === shaka.net.NetworkingEngine.RequestType.MANIFEST) {
                //     request.allowCrossSiteCredentials = false;  
                //     request.withCredentials = true;                             
                // }
            });
        // }
        shakaInstance.configure(mergedShakaConfig)
        /* --------------------------------------------- */
        let MuxConfig = analyticsIntegrationsRef.current.filter((config) => {
            return config.analyticsService === 'Mux'
        })
        /* --------------------------------------------- */
        if (MuxConfig[0]) MuxConfig = JSON.parse(MuxConfig[0].analyticsConfiguration)
        else MuxConfig = ''
        /* --------------------------------------------- */
        let selectedSubTitleTrack = getSelectedSubtitleTrack()
        if (!selectedSubTitleTrack) selectedSubTitleTrack = appMessages.subtitle_none
        else selectedSubTitleTrack = selectedSubTitleTrack.language
        /* --------------------------------------------- */
        let selectedPlayerQuality = getSelectedPlayerQuality() || ''
        if (!selectedPlayerQuality) selectedPlayerQuality = appMessages.label_player_auto
        /* --------------------------------------------- */
        let shakaPlayerMux = null

        const muxErrorTranslator = error => {
            if (error.player_error_code === 6006 || !error?.player_error_code || !error?.player_error_message) {
                return false;
            }
            return ({
                player_error_code: error.player_error_code,
                player_error_message: error.player_error_message
            })
        }

        if (window.initShakaPlayerMux && typeof window.initShakaPlayerMux === 'function') {
            shakaPlayerMux = window.initShakaPlayerMux(shakaInstance, {
                debug: isLocalHost,
                automaticErrorTracking: false,
                errorTranslator: (error, playerError) => muxErrorTranslator(error, playerError),
                data: {
                    env_key: MuxConfig.environmentKey,
                    disableCookies: false,
                    respectDoNotTrack: false,
                    // other metadata
                    ...getMuxMetaData({ selectedSubTitleTrack, selectedPlayerQuality })
                }
            })
        }
        /* --------------------------------------------- */
        const _onLoad = () => {
            shakaInstance.setTextTrackVisibility(true)
            shakaInstance.setTextTrackVisibility(false)
            // if (shakaInstance.mux && typeof shakaInstance.mux === 'function') {
            //     shakaInstance.mux.emit('programchange', {
            //         ...getMuxMetaData({ selectedSubTitleTrack, selectedPlayerQuality })
            //     })
            // }
            onLoad()
        }
        /* --------------------------------------------- */
        const _onPlay = () => {
            playStateRef.current = 'playing'
            dispatch({ type: "PLAYER_STATE", value: 'playing' })
            onPlay()
        }
        /* --------------------------------------------- */
        const _onPause = () => {
            playStateRef.current = 'paused'
            dispatch({ type: "PLAYER_STATE", value: 'paused' })
            onPause()
        }
        /* --------------------------------------------- */
        const _onCrash = (data, errorInfo) => {
            const config = errorConfigurationRef.current.filter((config) => {
                return config.type === 'PlaybackGeneric'
            })[0] || {}
            if (shakaInstance.mux && typeof shakaInstance.mux === 'function' && config?.code && errorInfo ) {
                const title = getErrorTitle(errorInfo, appMessages)
                const description = getDescriptionOfPlayerError(errorInfo, appMessages)
                // shakaPlayerMux.loadErrorHandler(error);
                //adding custom title and description
                console.log("inside crash", title, description)
                shakaPlayerMux.emit('error', {
                    player_error_code: errorInfo?.code || config?.code,
                    player_error_message: description,
                ...getMuxMetaData()
                });
            }
            onCrash(data, errorInfo)
        }
        /* --------------------------------------------- */
        const _onCurrentTimeChange = (e) => {
            if (!isPlaybackStartedRef.current) {
                dispatch({ type: "LOAD_TIME", value: new Date() })
                isPlaybackStartedRef.current = true
            }
            dispatch({type: "BUFFERING_INFO", value: shakaInstance.getBufferedInfo()})
            onCurrentTimeChange()
        }
        /* --------------------------------------------- */
        const onBuffering = ({ buffering }) => {
            const {
                stateHistory = []
            } = shakaInstance.getStats()
            const bufferingHistory = stateHistory.map((history) => {
                const { state } = history || {}
                if (state === 'buffering') {
                    history.timestamp = parseInt(history.timestamp || 0)
                    return history
                } else {
                    return null
                }
            }).filter(Boolean)

            dispatch({ type: "BUFFERING_HISTORY", value: bufferingHistory })

            if (buffering) {
                dispatch({ type: "PLAYER_STATE", value: 'buffering' })
                onBuffer()
            } else {
                dispatch({ type: "PLAYER_STATE", value: playStateRef.current })
                onBufferComplete()
            }
        }
        const onStateChange = (props, second) => {
            // console.log(props, second, 'stateChange')
        }
        const onRateChange = (props) => {
            // console.log(props, 'ratechange')
        }
        const onVariantChange = () => {
            const selectedTracks = shakaInstance.getVariantTracks().filter(({ active }) => active)
            const {
                frameRate = 0,
                pixelAspectRatio = '',
                mimeType = '',
                videoId = '',
                originalVideoId = '',
                videoCodec = '',
                videoBandwidth = '',
                originalAudioId = '',
                audioId = '',
                audioCodec = '',
                audioSamplingRate = '',
                audioBandwidth = '',
            } = selectedTracks[0] || {}

            dispatch({ type: "FRAME_RATE", value: frameRate })
            dispatch({ type: "PIXEL_ASPECT_RATIO", value: pixelAspectRatio })
            dispatch({ type: "MIME_TYPE", value: mimeType })
            dispatch({ type: "VIDEO_ID", value: videoId })
            dispatch({ type: "ORIGINAL_VIDEO_ID", value: originalVideoId })
            dispatch({ type: "VIDEO_CODEC", value: videoCodec })
            dispatch({ type: "VIDEO_BANDWIDTH", value: videoBandwidth })
            dispatch({ type: "AUDIO_ID", value: audioId })
            dispatch({ type: "AUDIO_CODEC", value: audioCodec })
            dispatch({ type: "ORIGINAL_AUDIO_ID", value: originalAudioId })
            dispatch({ type: "AUDIO_SAMPLING_RATE", value: audioSamplingRate })
            dispatch({ type: "AUDIO_BANDWIDTH", value: audioBandwidth })
        }
        /**
         * It: Hanldes player error.
         */
        const onError = (error = {}) => {
            const { detail = {} } = error || {}
            setPlaybackError(detail || {});
        }
        /**
         * It: Hanldes text change.
         */
        const onTextChange = () => {
            const isVisible = shakaInstance.isTextTrackVisible()
            const selectedTracks = isVisible ? shakaInstance.getTextTracks().filter(({ active }) => active) : []
            const {
                language = '',
                label = '',
                originalTextId = ''
            } = selectedTracks[0] || {}
            dispatch({ type: "TEXT_TRACK_LANGUAGE", value: language })
            dispatch({ type: "TEXT_TRACK_LABEL", value: label })
            dispatch({ type: "ORIGINAL_TEXT_ID", value: originalTextId })
        }
        /**
         * It: Hanldes manifest parse.
         */
        const onManifestParse = () => {
            const isOnLoad = true
            const player = shakaInstance
            const manifest = player.getManifest() || {}
            const labelAuto = labelAutoRef.current || ''
            const tracks = manifest.variants || []
            const qualityMapping = qualityMappingRef.current || []
            const qualityMappingMode = qualityMappingModeRef.current || ''
            const quality = getSelectedPlayerQuality() || labelAuto || ''
            const abrStartingProfile = abrStartingProfileRef.current || []
            const abrMode = abrModeRef.current || ''
            const previousQuality = selectedQualityRef.current || ''
            setCurrentQuality_1({
                isOnLoad, player, tracks, qualityMapping, qualityMappingMode,
                quality, abrStartingProfile, abrMode, onAutoChange, setSelectedQuality, dispatch,
                previousQuality
            })
        }
        /**
         * It: Fired when the playhead enters a large gap
         */
        const onLargeGapEvent = (currentTime, gapSize) => {
          onLargeGap(currentTime, gapSize)
        }
        /* --------------------------------------------- */
        var vid = document.getElementsByTagName("video");
        vid[0].onwaiting = function() {
            onBuffering({buffering: true});
        };
        vid[0].onplaying = function() {
            onBuffering({buffering: false});
        };
        shakaInstance.addEventListener('buffering', onBuffering)
        shakaInstance.addEventListener('onstatechange', onStateChange)
        shakaInstance.addEventListener('ratechange', onRateChange)
        shakaInstance.addEventListener('trackschanged', onVariantChange)
        shakaInstance.addEventListener('variantchanged', onVariantChange)
        shakaInstance.addEventListener('error', onError)
        shakaInstance.addEventListener('textchanged', onTextChange)
        shakaInstance.addEventListener('texttrackvisibility', onTextChange)
        shakaInstance.addEventListener('adaptation', onVariantChange)
        shakaInstance.addEventListener('manifestparsed', onManifestParse)
        shakaInstance.addEventListener('largegap', onLargeGapEvent)
        /* --------------------------------------------- */
        player.addEventListener('loadeddata', _onLoad)
        player.addEventListener('play', _onPlay)
        player.addEventListener('pause', _onPause)
        player.addEventListener('timeupdate', _onCurrentTimeChange)
        player.addEventListener('ended', _onComplete)
        // player.addEventListener('webkitneedkey', onneedkey);
        /* --------------------------------------------- */
        if (isSafari) player.src = url
        /* --------------------------------------------- */
        if (onStart) onStart()
        /* --------------------------------------------- */
        dispatch({ type: "STREAM_URL", value: url })
        /* --------------------------------------------- */
        /**
         * It: Loads playback.
         */
        const startFrom = (currentPlayerMediaData.type === "liveEvent" || currentPlayerMediaData.type === "liveevent") ? 
        shakaConfig?.liveOffset ? shakaConfig.liveOffset : -1 
        : startPosRef.current;
        shakaInstance.load(url, startFrom || 0)
            .then((res) => {
                // if(isMobile)
                // videoTagRef.current.requestFullscreen().catch(err => {
                //     console.log(err)
                // });
                if (!isMounted) return
                const duration = parseInt(getDuration()) || 0
                const { keySystem = '', licenseServerUri = '' } = shakaInstance.drmInfo() || {}
                if (debugModeEnabled) {
                    dispatch({ type: "DURATION", value: duration })
                    dispatch({ type: "DRM_PROVIDER", value: keySystem })
                    dispatch({ type: "DRM_LICENSE_URL", value: licenseServerUri })
                }
            })
            .catch((error = {}) => {
                if (!isMounted) return
                let errorLog = {}
                if (error.code) {
                    errorLog = { shakaErrorLog: JSON.stringify(error) }
                } else {
                    errorLog = error
                }
                setPlaybackError(error);
                if (shakaPlayerMux && error?.code && error?.data) {
                    const title = getErrorTitle(error, appMessages)
                    const description = getDescriptionOfPlayerError(error, appMessages)
                    // shakaPlayerMux.loadErrorHandler(error);
                    console.log(title)
                    shakaPlayerMux.emit('error', {
                        player_error_code: error?.code,
                        player_error_message: description,
                        ...getMuxMetaData()
                    });
                }
                if (error?.code !== 7000) {
                    _onCrash({ errorCode: error?.code || null }, errorLog)
                }
            });
        /* --------------------------------------------- */
        // shakaInstance.configure('drm.initDataTransform', (initData) => {
        //     if (isSafari) {
        //         let contentId = releasePid;
        //         const cert = shakaInstance.drmInfo().serverCertificate;
        //         return shaka.util.FairPlayUtils.initDataTransform(initData, contentId, cert)
        //     }
        //     else {
        //         return initData
        //     }
        // });
        // /* --------------------------------------------- */
        // shakaInstance.getNetworkingEngine().registerRequestFilter((type, request) => {
        //     if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
        //         return;
        //     }
        //     if (!isSafari) return
        //     const originalPayload = new Uint8Array(request.body);
        //     let params = {
        //         getFairplayLicense: {
        //             spcMessage: base64EncodeUint8Array(new Uint8Array(originalPayload)),
        //             releasePid
        //         }
        //     }
        //     params = JSON.stringify(params)
        //     request.headers['Content-Type'] = "application/json;charset=UTF-8";
        //     request.body = shaka.util.StringUtils.toUTF8(params);
        // })
        // /* --------------------------------------------- */
        // shakaInstance.getNetworkingEngine().registerResponseFilter((type, response) => {
        //     if (type !== shaka.net.NetworkingEngine.RequestType.LICENSE) {
        //         return;
        //     }
        //     if (!isSafari) return
        //     let responseText = shaka.util.StringUtils.fromUTF8(response.data)
        //     responseText = JSON.parse(responseText)
        //     responseText = responseText.getFairplayLicenseResponse.ckcResponse
        //     response.data = shaka.util.Uint8ArrayUtils.fromBase64(responseText).buffer
        // });
        /* --------------------------------------------- */
        return () => {
            isMounted = false
            shakaInstance.removeEventListener('buffering', onBuffering)
            shakaInstance.removeEventListener('onstatechange', onStateChange)
            shakaInstance.removeEventListener('ratechange', onRateChange)
            shakaInstance.removeEventListener('trackschanged', onVariantChange)
            shakaInstance.removeEventListener('variantchanged', onVariantChange)
            shakaInstance.removeEventListener('error', onError)
            shakaInstance.removeEventListener('textchanged', onTextChange)
            shakaInstance.removeEventListener('texttrackvisibility', onTextChange)
            shakaInstance.removeEventListener('adaptation', onVariantChange)
            shakaInstance.removeEventListener('manifestparsed', onManifestParse)
            shakaInstance.removeEventListener('largegap', onLargeGapEvent)
            /* --------------------------------------------- */
            player.removeEventListener('loadeddata', _onLoad)
            player.removeEventListener('play', _onPlay)
            player.removeEventListener('pause', _onPause)
            player.removeEventListener('timeupdate', _onCurrentTimeChange)
            player.removeEventListener('ended', _onComplete)
            // player.removeEventListener('webkitneedkey', onneedkey)
            /* --------------------------------------------- */
            shakaInstance.destroy()
            if (shakaInstance.mux && typeof shakaInstance.mux?.destroy === 'function') {
                shakaInstance.mux.destroy()
            }
            /* --------------------------------------------- */
            delete window.shakaConfig
            delete window.shakaInstance
            /* --------------------------------------------- */
            onUnload()
        }
    }

    useEffect(playerEffect, [url, isBrowserSupported, drmToken, retryPlayback, fairplayCertificate]);

    usePlaybackRetry({playbackError, appMessages, shakaConfigRef, tokenExpiryRetryCount, recoverableErrorRetryCount, shakaInstanceRef, getMuxMetaData, onObjectDestructionError, onPlaybackDRMError, isSafari, onPlaybackManifestFailed, onPlaybackGenericError, onCrash, onHDCPError, onRecoverablePlayerError});
    /* --------------------------------------------- */
    /**
     * It: Updates player stats values.
     */
    useEffect(() => {
        if (!isBrowserSupported) return
        let statsTimer = null
        if (debugModeEnabled) {
            statsTimer = setInterval(() => {
                const shakaInstance = shakaInstanceRef.current
                if (!shakaInstance) return
                const {
                    corruptedFrames = 0,
                    decodedFrames = 0,
                    droppedFrames = 0,
                    height = 0,
                    width = 0,
                    bufferingTime = 0,
                    switchHistory = [],
                    estimatedBandwidth = 0
                } = shakaInstance.getStats()
                const tracks = shakaInstance.getVariantTracks()
                const videoWidth = width || videoTagRef.current.videoWidth || 0
                const videoHeight = height || videoTagRef.current.videoHeight || 0
                const trackHistory = []

                const streamHistory = switchHistory.map((history) => {
                    const _history = { ...history }
                    _history.timestamp = parseInt(_history.timestamp || 0)
                    const selectedTrack = tracks.filter((track) => {
                        return track.id === _history.id && track.type === _history.type
                    })[0]
                    if (selectedTrack && selectedTrack.width && selectedTrack.height) {
                        if (selectedTrack.type === 'variant') {
                            trackHistory.push({
                                timestamp: _history.timestamp || '',
                                width: selectedTrack.width || '',
                                height: selectedTrack.height || '',
                                bitrate: _history.bandwidth || ''
                            })
                            _history.videoResolution = `${selectedTrack.width}x${selectedTrack.height}`
                        }
                    }
                    return _history
                })

                dispatch({ type: "BUFFERING_TIME", value: bufferingTime })
                dispatch({ type: "STREAM_HISTORY", value: streamHistory })
                dispatch({ type: "CORRUPTED_FRAMES", value: corruptedFrames })
                dispatch({ type: "DECODED_FRAMES", value: decodedFrames })
                dispatch({ type: "DROPPED_FRAMES", value: droppedFrames })
                dispatch({ type: "VIDEO_WIDTH", value: videoWidth })
                dispatch({ type: "VIDEO_HEIGHT", value: videoHeight })
                dispatch({ type: "ESTIMATED_BANDWIDTH", value: estimatedBandwidth })
                dispatch({ type: "TRACK_HISTORY", value: trackHistory })
            }, 1000)
        }
        return () => {
            clearInterval(statsTimer)
        }
    }, [debugModeEnabled, dispatch, isBrowserSupported])
    /* --------------------------------------------- */
    return (
        <>
            <video autoPlay ref={videoTagRef} muted={isMuted? true: false}/>
        </>
    );
})
/* --------------------------------------------- */
ShakaPlayer.propTypes = {
    /* Url for playback. */
    url: PropTypes.string,
    /* This callback gets triggered when player is ready for playback.  */
    onLoad: PropTypes.func,
    /* This callback gets triggered when player buffers.  */
    onBuffer: PropTypes.func,
    /* This callback gets triggered when player completes buffering.  */
    onBufferComplete: PropTypes.func,
    /* This callback gets triggered when playback plays.  */
    onPlay: PropTypes.func,
    /* This callback gets triggered when playback pauses.  */
    onPause: PropTypes.func,
    /* This callback gets triggered on every seek.  */
    onCurrentTimeChange: PropTypes.func,
    /* This callback gets triggered when playback completes.  */
    onComplete: PropTypes.func,
    /* This callback gets triggered when player crashes.  */
    onCrash: PropTypes.func,
    /* This callback gets triggered when playhead enters a large gap.  */
    onLargeGap: PropTypes.func,
    /* This callback gets triggered when subtitle text changes.  */
    // onSubtitleTextChange: PropTypes.func,
    /* This callback gets triggered when player is destroyed.  */
    onUnload: PropTypes.func
}
/* --------------------------------------------- */
ShakaPlayer.defaultProps = {
    url: null,
    onLoad: () => { },
    onBuffer: () => { },
    onBufferComplete: () => { },
    onPlay: () => { },
    onPause: () => { },
    onCurrentTimeChange: () => { },
    onComplete: () => { },
    onCrash: () => { },
    onLargeGap: () => { },
    // onSubtitleTextChange: () => { },
    onUnload: () => { }
}
/* --------------------------------------------- */
export default ShakaPlayer

const getSortedTracks = (params) => {
    const isOnLoad = params.isOnLoad || false
    let tracks = params.tracks || []
    tracks = tracks || []

    tracks = tracks.sort(function (a, b) {
        let aHeight = null
        let bHeight = null
        let aBandwidth = null
        let bBandwidth = null

        if (isOnLoad) {
            if (!a) a = {}
            if (!b) b = {}
            if (!a.video) a.video = {}
            if (!b.video) b.video = {}
            aHeight = a.video.height
            bHeight = b.video.height
            aBandwidth = a.video.bandwidth
            bBandwidth = b.video.bandwidth
        } else {
            aHeight = a.height
            bHeight = b.height
            aBandwidth = a.bandwidth
            bBandwidth = b.bandwidth
        }

        if (aHeight < bHeight) return 1;
        if (aHeight > bHeight) return -1;

        if (aBandwidth < bBandwidth) return 1;
        if (aBandwidth > bBandwidth) return -1;

        return 0;
    });

    return tracks;
}

const setCurrentQuality_1 = (params) => {
    const isOnLoad = params.isOnLoad || false
    const qualityMappingMode = params.qualityMappingMode || '' // 'height'
    const tracks = params.tracks || []
    const player = params.player || {}
    const quality = params.quality || ''
    const abrMode = params.abrMode || ''
    const qualityMapping = params.qualityMapping || []
    const abrStartingProfile = params.abrStartingProfile || ''
    const onAutoChange = params.onAutoChange || (() => { })
    const previousQuality = params.previousQuality || ''
    const setSelectedQuality = params.setSelectedQuality || (() => { })
    const dispatch = params.dispatch || (() => { })

    if (previousQuality === quality) return

    setSelectedQuality(quality)
    dispatch({ type: "QUALITY", value: quality })

    const props = { isOnLoad, tracks, player, quality, abrMode, qualityMapping, abrStartingProfile, onAutoChange }
    if (qualityMappingMode === 'height') setCurrentQualityHeight(props)
    else if (qualityMappingMode === 'heightMap') setCurrentQualityHeightMap(props)
    else if (qualityMappingMode === 'bitrateMap') setCurrentQualityBitrateMap(props)
    else if (qualityMappingMode === 'heightRange') setCurrentQualityHeightRangeMapping(props)
    else if (qualityMappingMode === 'bitrateRange') setCurrentQualityBitrateRangeMapping(props)
    else if (qualityMappingMode === 'heightBitrateIndex') setCurrentQualityHeightBitrateIndexMapping(props)
}

const setCurrentQualityHeightBitrateIndexMapping = (params) => {
    const isOnLoad = params.isOnLoad || false
    const player = params.player || {}
    const quality = params.quality || ''
    const abrMode = params.abrMode || ''
    const abrStartingProfile = params.abrStartingProfile || ''
    const onAutoChange = params.onAutoChange || (() => { })
    let qualityMapping = params.qualityMapping || []
    let tracks = params.tracks || []
    tracks = getSortedTracks({ isOnLoad, tracks });
    qualityMapping = getQualityHeightBitrateIndexMapping({ isOnLoad, tracks, qualityMapping })
    qualityMapping = qualityMapping.filter(({ value }) => {
        return quality === value
    })
    let selectedHeightBitrateIndex = ''
    if (qualityMapping.length > 0) {
        selectedHeightBitrateIndex = qualityMapping[0].key
    }
    let selectedQuality = null
    const textInSquareBrackets = selectedHeightBitrateIndex.match(/\[(.*?)\]/) || []; // 2
    const index = textInSquareBrackets[1]
    if (index !== null || index !== undefined) {
        selectedQuality = tracks[index]
    }
    const track = selectedQuality
    if (selectedQuality) {
        onAutoChange(false)
        if (abrMode === 'capping') {
            let height = track.height
            if (isOnLoad && track.video) {
                height = track.video.height
            }
            player.configure("abr.enabled", true)
            player.configure("abr.restrictions.maxHeight", height)
            player.configure("abr.restrictions.maxBandwidth", track.bandwidth)

            if (isOnLoad) {
                const defaultBandwidthEstimate = calcDefaultBandwdithEstimate({
                    player,
                    bandwidth: track.bandwidth
                })
                player.configure("abr.defaultBandwidthEstimate", defaultBandwidthEstimate);
            } else {
                player.selectVariantTrack(track, true)
            }
        } else {
            player.configure("abr.enabled", false)

            if (isOnLoad) {
                setStartBandwidth({ isOnLoad, tracks, player, abrStartingProfile });
            } else {
                player.selectVariantTrack(track, true)
            }
        }
    }
    else {
        onAutoChange(true)
        player.configure("abr.enabled", true)
        player.configure("abr.restrictions.maxHeight", Infinity)
        player.configure("abr.restrictions.maxBandwidth", Infinity)

        if (isOnLoad) {
            setStartBandwidth({ isOnLoad, tracks, player, abrStartingProfile });
        }
    }
}

const setCurrentQualityBitrateRangeMapping = (params) => {
    const isOnLoad = params.isOnLoad || false
    const player = params.player || {}
    const quality = params.quality || ''
    const abrMode = params.abrMode || ''
    const abrStartingProfile = params.abrStartingProfile || ''
    const onAutoChange = params.onAutoChange || (() => { })
    let qualityMapping = params.qualityMapping || []
    let tracks = params.tracks || []
    tracks = getSortedTracks({ isOnLoad, tracks });
    qualityMapping = getQualityBitrateRangeMapping({ isOnLoad, tracks, qualityMapping })
    qualityMapping = qualityMapping.filter(({ value }) => {
        return quality === value
    })
    let selectedBitrateRange = ''
    if (qualityMapping.length > 0) {
        selectedBitrateRange = qualityMapping[0].key
    }
    const [start, end] = selectedBitrateRange.split("-")
    const selectedQuality = tracks.filter((track) => {
        let bandwidth = track.bandwidth
        return Number(start) >= bandwidth && Number(end) <= bandwidth
    })
    if (selectedQuality.length > 0) {
        onAutoChange(false)
        if (abrMode === 'capping') {
            const [start, end] = selectedBitrateRange.split("-")
            player.configure("abr.enabled", true)
            player.configure("abr.restrictions.minBandwidth", Number(end))
            player.configure("abr.restrictions.maxBandwidth", Number(start))

            if (isOnLoad) {
                player.configure("abr.defaultBandwidthEstimate", selectedQuality[0].bandwidth)
            }
        } else {
            player.configure("abr.enabled", false)

            if (isOnLoad) {
                setStartBandwidth({ isOnLoad, tracks, player, abrStartingProfile });
            } else {
                player.selectVariantTrack(selectedQuality[0], true)
            }
        }
    }
    else {
        onAutoChange(true)
        player.configure("abr.enabled", true)
        player.configure("abr.restrictions.minBandwidth", 0)
        player.configure("abr.restrictions.maxBandwidth", Infinity)

        if (isOnLoad) {
            setStartBandwidth({ isOnLoad, tracks, player, abrStartingProfile });
        }
    }
}

const setCurrentQualityHeightRangeMapping = (params) => {
    const isOnLoad = params.isOnLoad || false
    const player = params.player || {}
    const quality = params.quality || ''
    const abrMode = params.abrMode || ''
    const abrStartingProfile = params.abrStartingProfile || ''
    const onAutoChange = params.onAutoChange || (() => { })
    let qualityMapping = params.qualityMapping || []
    let tracks = params.tracks || []
    tracks = getSortedTracks({ isOnLoad, tracks });
    qualityMapping = getQualityHeightRangeMapping({ isOnLoad, tracks, qualityMapping })
    qualityMapping = qualityMapping.filter(({ value }) => {
        return quality === value
    })
    let selectedHeightRange = ''
    if (qualityMapping.length > 0) {
        selectedHeightRange = qualityMapping[0].key
    }
    const [start, end] = selectedHeightRange.split("-")
    const selectedQuality = tracks.filter((track) => {
        let height = track.height
        if (isOnLoad && track.video) {
            height = track.video.height
        }
        return Number(start) >= height && Number(end) <= height
    })
    if (selectedQuality.length > 0) {
        onAutoChange(false)
        if (abrMode === 'capping') {
            const [start, end] = selectedHeightRange.split("-")
            player.configure("abr.enabled", true)
            player.configure("abr.restrictions.minHeight", Number(end))
            player.configure("abr.restrictions.maxHeight", Number(start))

            if (isOnLoad) {
                player.configure("abr.defaultBandwidthEstimate", selectedQuality[0].bandwidth)
            }
        } else {
            player.configure("abr.enabled", false)

            if (isOnLoad) {
                setStartBandwidth({ isOnLoad, tracks, player, abrStartingProfile });
            } else {
                player.selectVariantTrack(selectedQuality[0], true)
            }
        }
    }
    else {
        onAutoChange(true)
        player.configure("abr.enabled", true)
        player.configure("abr.restrictions.minHeight", 0)
        player.configure("abr.restrictions.maxHeight", Infinity)

        if (isOnLoad) {
            setStartBandwidth({ isOnLoad, tracks, player, abrStartingProfile });
        }
    }
}

const setCurrentQualityBitrateMap = (params) => {
    const isOnLoad = params.isOnLoad || false
    const player = params.player || {}
    const quality = params.quality || ''
    const abrMode = params.abrMode || ''
    const abrStartingProfile = params.abrStartingProfile || ''
    const onAutoChange = params.onAutoChange || (() => { })
    let qualityMapping = params.qualityMapping || []
    let tracks = params.tracks || []
    tracks = getSortedTracks({ isOnLoad, tracks });
    qualityMapping = getQualityBitrateMapping({ isOnLoad, tracks, qualityMapping })
    qualityMapping = qualityMapping.filter(({ value }) => {
        return quality === value
    })
    let selectedBitrate = null
    if (qualityMapping.length > 0) {
        selectedBitrate = Number(qualityMapping[0].key)
    }
    const selectedQuality = tracks.filter((track) => {
        let bandwidth = track.bandwidth
        return selectedBitrate === bandwidth
    })
    if (selectedQuality.length > 0) {
        onAutoChange(false)
        if (abrMode === 'capping') {
            player.configure("abr.enabled", true)
            player.configure("abr.restrictions.maxBandwidth", selectedQuality[0].bandwidth)

            if (isOnLoad) {
                player.configure("abr.defaultBandwidthEstimate", selectedQuality[0].bandwidth)
            }
        } else {
            player.configure("abr.enabled", false)

            if (isOnLoad) {
                setStartBandwidth({ isOnLoad, tracks, player, abrStartingProfile });
            } else {
                player.selectVariantTrack(selectedQuality[0], true)
            }
        }
    }
    else {
        onAutoChange(true)
        player.configure("abr.enabled", true)
        player.configure("abr.restrictions.maxBandwidth", Infinity)

        if (isOnLoad) {
            setStartBandwidth({ isOnLoad, tracks, player, abrStartingProfile });
        }
    }
}

const setCurrentQualityHeightMap = (params) => {
    const isOnLoad = params.isOnLoad || false
    const player = params.player || {}
    const quality = params.quality || ''
    const abrMode = params.abrMode || ''
    const abrStartingProfile = params.abrStartingProfile || ''
    const onAutoChange = params.onAutoChange || (() => { })
    let qualityMapping = params.qualityMapping || []
    let tracks = params.tracks || []
    tracks = getSortedTracks({ isOnLoad, tracks });
    qualityMapping = getQualityHeightMapping({ isOnLoad, tracks, qualityMapping })
    qualityMapping = qualityMapping.filter(({ value }) => {
        return quality === value
    })
    let selectedHeight = null
    if (qualityMapping.length > 0) {
        selectedHeight = Number(qualityMapping[0].key)
    }
    const selectedQuality = tracks.filter((track) => {
        let height = track.height
        if (isOnLoad && track.video) {
            height = track.video.height
        }
        return selectedHeight === height
    })
    const track = selectedQuality[0]
    if (selectedQuality.length > 0) {
        onAutoChange(false)
        if (abrMode === 'capping') {
            let height = track.height
            if (isOnLoad && track.video) {
                height = track.video.height
            }
            player.configure("abr.enabled", true)
            player.configure("abr.restrictions.maxHeight", height)

            if (isOnLoad) {
                player.configure("abr.defaultBandwidthEstimate", track.bandwidth)
            }
        } else {
            player.configure("abr.enabled", false)

            if (isOnLoad) {
                setStartBandwidth({ isOnLoad, tracks, player, abrStartingProfile });
            } else {
                player.selectVariantTrack(track, true)
            }
        }
    }
    else {
        onAutoChange(true)
        player.configure("abr.enabled", true)
        player.configure("abr.restrictions.maxHeight", Infinity)

        if (isOnLoad) {
            setStartBandwidth({ isOnLoad, tracks, player, abrStartingProfile });
        }
    }
}

const setCurrentQualityHeight = (params) => {
    const isOnLoad = params.isOnLoad || false
    const player = params.player || {}
    const quality = params.quality || ''
    const abrMode = params.abrMode || ''
    const abrStartingProfile = params.abrStartingProfile || ''
    const onAutoChange = params.onAutoChange || (() => { })
    let tracks = params.tracks || []

    const selectedQuality = tracks.filter((track) => {
        let height = track.height
        if (isOnLoad && track.video) {
            height = track.video.height
        }
        return quality === `${height}p`
    })
    if (selectedQuality.length > 0) {
        onAutoChange(false)
        const track = selectedQuality[0]
        if (abrMode === 'capping') {
            let height = track.height
            if (isOnLoad && track.video) {
                height = track.video.height
            }
            player.configure("abr.enabled", true)
            player.configure("abr.restrictions.maxHeight", height)

            if (isOnLoad) {
                player.configure("abr.defaultBandwidthEstimate", track.bandwidth)
            }
        } else {
            player.configure("abr.enabled", false)

            if (isOnLoad) {
                setStartBandwidth({ isOnLoad, tracks, player, abrStartingProfile });
            } else {
                player.selectVariantTrack(track, true)
            }
        }
    } else {
        onAutoChange(true)
        player.configure("abr.enabled", true)
        player.configure("abr.restrictions.maxHeight", Infinity)

        if (isOnLoad) {
            setStartBandwidth({ isOnLoad, tracks, player, abrStartingProfile });
        }
    }
}

const getQualityHeightBitrateIndexMapping = (params) => {
    const isOnLoad = params.isOnLoad || false
    let tracks = params.tracks || []
    let qualityMapping = params.qualityMapping || []
    tracks = getSortedTracks({ isOnLoad, tracks })
    const maxHeight = getMaxHeight({ isOnLoad, tracks })
    let filteredQualityMapping = filterQualityMappingByMaxHeight({ maxHeight, qualityMapping })
    return filteredQualityMapping.slice(0, tracks.length)
}

const getQualityBitrateRangeMapping = (params) => {
    const isOnLoad = params.isOnLoad || false
    let tracks = params.tracks || []
    let qualityMapping = params.qualityMapping || []
    tracks = getSortedTracks({ isOnLoad, tracks })
    let filteredQualityMapping = filterQualityMappingByBitrateRange({ tracks, qualityMapping })
    return filteredQualityMapping
}

const getQualityHeightRangeMapping = (params) => {
    const isOnLoad = params.isOnLoad || false
    let tracks = params.tracks || []
    let qualityMapping = params.qualityMapping || []
    tracks = getSortedTracks({ isOnLoad, tracks })
    let filteredQualityMapping = filterQualityMappingByHeightRange({ tracks, qualityMapping })
    return filteredQualityMapping
}

const getQualityHeightMapping = (params) => {
    const isOnLoad = params.isOnLoad || false
    let tracks = params.tracks || []
    let qualityMapping = params.qualityMapping || []
    tracks = getSortedTracks({ isOnLoad, tracks })
    let filteredQualityMapping = filterQualityMappingByHeight({ tracks, qualityMapping })
    return filteredQualityMapping
}

const getQualityBitrateMapping = (params) => {
    const isOnLoad = params.isOnLoad || false
    let tracks = params.tracks || []
    let qualityMapping = params.qualityMapping || []
    tracks = getSortedTracks({ isOnLoad, tracks })
    let filteredQualityMapping = filterQualityMappingByBitrate({ tracks, qualityMapping })
    return filteredQualityMapping
}

const getMaxHeight = (params) => {
    const isOnLoad = params.isOnLoad || false
    let maxHeight = 0
    let tracks = params.tracks || []
    tracks = getSortedTracks({ isOnLoad, tracks })
    const firstTrack = tracks[0] || {}
    if (isOnLoad) {
        const video = firstTrack.video || {}
        maxHeight = video.height;
    } else {
        maxHeight = firstTrack.height;
    }
    return maxHeight || 0
}

const filterQualityMappingByMaxHeight = (params) => {
    const maxHeight = params.maxHeight || 0
    const qualityMapping = params.qualityMapping || []
    return qualityMapping
        .filter(({ key = '' }) => {
            if (key.indexOf("[") === -1) return null
            const [start] = key.split("[")
            return Number(start) === maxHeight
        })
}

const filterQualityMappingByBitrateRange = (params) => {
    let tracks = params.tracks || []
    const qualityMapping = params.qualityMapping || []
    return qualityMapping
        .filter(({ key = '' }) => {
            if (key.indexOf("-") === -1) return null
            const [start, end] = key.split("-")
            return tracks.filter(track => {
                track = track || {}
                const bandwidth = track.bandwidth || 0
                return Number(start) >= bandwidth && Number(end) <= bandwidth
            }).length > 0
        })
}

const filterQualityMappingByHeightRange = (params) => {
    let tracks = params.tracks || []
    const qualityMapping = params.qualityMapping || []
    return qualityMapping
        .filter(({ key = '' }) => {
            if (key.indexOf("-") === -1) return null
            const [start, end] = key.split("-")
            return tracks.filter(track => {
                track = track || {}
                const height = track.height || 0
                return Number(start) >= height && Number(end) <= height
            }).length > 0
        })
}

const filterQualityMappingByBitrate = (params) => {
    let tracks = params.tracks || []
    const qualityMapping = params.qualityMapping || []
    return qualityMapping
        .filter(({ key = '' }) => {
            return tracks.filter(track => {
                track = track || {}
                const bandwidth = track.bandwidth || 0
                return Number(key) === bandwidth
            }).length > 0
        })
}

const filterQualityMappingByHeight = (params) => {
    let tracks = params.tracks || []
    const qualityMapping = params.qualityMapping || []
    return qualityMapping
        .filter(({ key = '' }) => {
            return tracks.filter(track => {
                track = track || {}
                const height = track.height || 0
                return Number(key) === height
            }).length > 0
        })
}

function setStartBandwidth(params) {
    const isOnLoad = params.isOnLoad || false
    let tracks = params.tracks || []
    const player = params.player || {}
    const abrStartingProfile = params.abrStartingProfile || []

    const maxHeight = getMaxHeight({ isOnLoad, tracks });
    const mockQualityMapping = abrStartingProfile.map((value) => {
        return { key: value }
    })
    let selectedQualities = filterQualityMappingByMaxHeight({ maxHeight, qualityMapping: mockQualityMapping })
    let selectedHeightBitrateIndex = '';
    if (selectedQualities.length > 0) {
        selectedHeightBitrateIndex = selectedQualities[0].key;
    }
    const textInSquareBrackets = selectedHeightBitrateIndex.match(/\[(.*?)\]/) || [];
    const index = textInSquareBrackets[1]
    if (index !== null || index !== undefined) {
        let selectedTrack = null
        if (index > 1) selectedTrack = tracks[index]
        else selectedTrack = tracks[0]
        const defaultBandwidthEstimate = calcDefaultBandwdithEstimate({
            player,
            bandwidth: selectedTrack.bandwidth
        })
        player.configure("abr.defaultBandwidthEstimate", defaultBandwidthEstimate);
    }
}

const calcDefaultBandwdithEstimate = (params) => {
    const bandwidth = params.bandwidth || 0
    const player = params.player || {}
    const playerConfig = player.getConfiguration() || {}
    const abrBandwidthUpgradeTarget = (playerConfig.abr && playerConfig.abr.bandwidthUpgradeTarget) || 0
    return parseInt(bandwidth / abrBandwidthUpgradeTarget)
}
