import React, {
  useRef,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import PlayerProvider from "../../context/player.context";
import Player from "./../player/Player";
import { GlobalContext } from "../../context/global.context";
import PlayIcon from "../../components/icons/PlayIcon";
import PlayIconFilled from "../../components/icons/PlayIconFilled";
import FavOutlineIcon from "../../components/icons/FavOutlineIcon";
import FavMarkedIcon from "../../components/icons/FavMarkedIcon";
import InfoIcon from "../../components/icons/InfoIcon";
import InfoIconFilled from "../../components/icons/InfoIconFilled";
import { routeMap } from "../../route";
import useFavApi from "../../apis/useFavApi";
import useDetailsApi from "../../apis/useDetailsApi.js";
import useAnalytics from "../../containers/useAnalytics";
import useErrorUtils from "../../utils/useErrorUtils";
import {
  splitLanguage,
  getMetaDataPlayerMuteState,
} from "../../utils/localStorageService";
import utils from "../../utils/utils";
import MuteUnmute from "./MuteUnmute.jsx";
import { ProgressBar } from "./style.jsx";
import "./style.scss";

const MetaDataPreview = () => {
  const playerRef = useRef(null);
  const isTrailerRef = useRef(false);
  const isHeroRail = useRef(false);
  const [playerUrl, setPlayerUrl] = useState(null);
  const [currentTrailer, updateCurrentTrailer] = useState(null);
  const [isPlayerLoaded, setPlayerLoaded] = useState(false);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [width, setWidth] = useState("450px");
  const [height, setHeight] = useState("450px");
  const [isFavourite, updateIsFavourite] = useState(false);
  const [displayDuration, updateDuration] = useState(null);
  const [bgImage, setBgImage] = useState(null);
  const [autoPlayStarted, setAutoPlayStarted] = useState(false);
  const [showDescription, setShowDescription] = useState(true)
  const { addFavourites, deleteFavourites, isFavouritedItemLocal } =
    useFavApi();
  const { checkAccess } = useDetailsApi();
  const { sendAnalytics } = useAnalytics();
  const { showError, hideAllErrors } = useErrorUtils();

  const releaseUrlRef = useRef(null);
  const controlRef = useRef(null);
  const { appMessages } = useSelector((store) => store.appConfigReducers);
  const appConfig = useSelector((store) => store.appConfigReducers.appConfig);

  const playerFeature = useSelector(
    (store) => store.featuresReducers.playerFeature
  );

  const {
    dispatch: globalDispatch,
    metaDataPreview,
    isAuthenticated,
    appLanguage,
    isMobileUser
  } = useContext(GlobalContext);
  
  const history = useHistory();

  const handleTrailerPlayClick = useCallback(() => {
    isTrailerRef.current = true;
    setPlayerUrl(currentTrailer);
    releaseUrlRef.current = currentTrailer;

    globalDispatch({
      type: "TRAILER_DETAILS",
      value: {
        isTrailer: true,
        trailerUrl: currentTrailer,
      },
    });
  }, [globalDispatch, currentTrailer]);

  useEffect(() => {
    if (playerRef.current && playerFeature.fullScreenConfig.previewAssetFormat) {
      playerRef.current.setPlaybackAssetFormat(
        playerFeature.fullScreenConfig.previewAssetFormat
      );
      playerRef.current.autoPlay(true);
    }
  }, [playerUrl, playerFeature]);
  useEffect(() => {
    if (isPlayerLoaded && playerRef.current) {
      playerRef.current.autoPlay(true);
    }
  }, [isPlayerLoaded]);

  useEffect(() => {
    if (!metaDataPreview?.details?.uid) {
      return;
    }
    const isFav = isFavouritedItemLocal(metaDataPreview.details.uid);
    updateIsFavourite(isFav);
  }, [isFavouritedItemLocal, metaDataPreview]);
  useEffect(() => {
    if (metaDataPreview?.trailers && metaDataPreview.trailers.length > 0) {
      updateCurrentTrailer(metaDataPreview.trailers[0].streams);
      handleTrailerPlayClick();
      setShowDescription(false);
    } else {
      updateCurrentTrailer(null);
      setShowDescription(true)
    }
  }, [metaDataPreview, handleTrailerPlayClick]);
  useEffect(() => {
    if (!metaDataPreview?.details?.displayDuration) {
      return;
    }
    const displayDurationFormatted = utils.millisToMinutesAndSeconds(
      metaDataPreview.details.displayDuration,
      {
        hr: appMessages.label_details_h,
        min: appMessages.label_details_min,
        sec: appMessages.label_details_sec,
      }
    );
    updateDuration(displayDurationFormatted);
  }, [updateDuration, appMessages, metaDataPreview]);

  useEffect(() => {
    const aspectRatio = 16 / 9; // fix aspect ratio for video container
    if (metaDataPreview?.offset) {
      let top, left, width, height;

      if (metaDataPreview.contentType === "home_heroComponent" || metaDataPreview.contentType === "Hero_rail_component") {
        isHeroRail.current = true;
        top = 80;
        left = "15%";
        width = window.innerWidth * 0.7;
        height = width / aspectRatio;
      } else {
        isHeroRail.current = false;
        width = 460; // const width as per design

        // calculated left of hover container
        left = metaDataPreview.offset.left + window.scrollX;
        left = left - (width - metaDataPreview.offset.width) / 2;
        // far left case
        left = left > 40 ? left : 40;

        // // far right case
        let farRight = window.innerWidth - 40 - width;
        left = left > farRight ? farRight : left;
        // // calculated top as per height of rail
        height = width / aspectRatio + 150;
        top = metaDataPreview.offset.top + window.scrollY;
        top = top - (height - metaDataPreview.offset.height) / 2;
      }
      const imagesArr = metaDataPreview?.details?.images || [];
      let bgImage = utils.getCloserMatchImage(
        width,
        isHeroRail.current ? height : height - 150,
        imagesArr
      );
      if (!bgImage & (imagesArr.length > 0)) bgImage = imagesArr[0];
      /* --------------------------------------------- */
      bgImage = bgImage ? bgImage.url : "";
      bgImage = window.escape(bgImage);
      bgImage = bgImage.replace(/https%3A/g, "https:");
      bgImage = bgImage.replace(/http%3A/g, "http:");
      setBgImage(bgImage);
      setLeft(left);
      setTop(top);
      setWidth(width);
      setHeight(height);
    }
  }, [metaDataPreview]);

  const getReleaseUrl = useCallback((releaseUrl) => {
    return releaseUrlRef.current;
  }, []);
  /* --------------------------------------------- */
  const setReleaseUrl = useCallback((releaseUrl) => {
    releaseUrlRef.current = releaseUrl;
  }, []);
  const handleMouseOut = useCallback(() => {
    globalDispatch({ type: "METADATA_PREVIEW", value: null });
    setBgImage(null);
    setPlayerLoaded(false);
    setAutoPlayStarted(false);
    setPlayerUrl(null);
    updateCurrentTrailer(null);
  }, [globalDispatch]);
  useEffect(() => {
      return history.listen((location) => { 
        if(metaDataPreview){
          handleMouseOut()
        }
      }) 
   },[history, handleMouseOut, metaDataPreview]) 
  /* --------------------------------------------- */
  /**
   * It: Trims leading zeros from given time string.
   */
  const trimLeadingZeroesFromTime = (inputTime) => {
    if (inputTime) {
      let arr = inputTime.split(" ");
      arr = arr.map((e) => {
        for (let i = 0; i < e.length; i++) {
          if (e[i] !== "0") {
            return e.slice(i);
          }
        }
        return "0";
      });
      return arr.join(" ");
    }
  };
  const checkIfCanAccess = useCallback(
    (callback) => {
      const baseUrl = appConfig.baseUrl;
      if (!baseUrl) {
        return;
      }
      const mediaId = metaDataPreview.details.uid;
      const purchaseMode = metaDataPreview.details.purchaseMode;
      const previewAssetFormat =
        playerFeature &&
        playerFeature.fullScreenConfig &&
        playerFeature.fullScreenConfig.previewAssetFormat;
      const url = metaDataPreview.details.streams;
      if (purchaseMode === "free") {
        if (callback) callback();
        return;
      }
      checkAccess(baseUrl, mediaId, url, previewAssetFormat)
        .then(() => {
          if (callback) callback();
        })
        .catch(() => {
          handleMouseOut();
          history.push(routeMap.subscription);
          //showError({ type: 'PurchaseRequired', value: { onPrimaryBtnClick: ()=>{} } })
        });
    },
    [
      checkAccess,
      appConfig.baseUrl,
      metaDataPreview,
      playerFeature,
      handleMouseOut,
      history,
    ]
  );
  const handlePlayClick = useCallback(() => {
    if (!isAuthenticated) {
      handleMouseOut();
      history.push(routeMap.login);
      return;
    }
    if (!metaDataPreview?.details) {
      return;
    }
    if (isMobileUser) {
      const {
        title,
        releaseYear,
        parentalControl,
        isCcAvailable,
        isHdAvailable,
        duration,
      } = metaDataPreview?.details || {}; 
      const getAppDetails = {
        title,
        images:metaDataPreview?.details?.images,
        releaseYear,
        parentalControl,
        isCcAvailable,
        isHdAvailable,
        duration
      }
      globalDispatch({
        type: 'GETAPP_POPUP_DETAILS',
        value: {
          isShowPopup: true,
          details: getAppDetails
        }
      })
      handleMouseOut();

      return
    }
    const linkUri = `${routeMap.player}?mediaId=${metaDataPreview.details.uid}&progress=${metaDataPreview.details.progress}&duration=${metaDataPreview.details.duration}`;
    handleMouseOut();
    checkIfCanAccess(() => {
      history.push(linkUri, { details: { ...metaDataPreview.details } });
    });
  }, [
    metaDataPreview,
    history,
    handleMouseOut,
    isAuthenticated,
    checkIfCanAccess,
    globalDispatch,
    isMobileUser
  ]);

  const handleInfoIconClick = () => {
    let DetailsPagelinkUri = "";
    const type = metaDataPreview.details.type;
    const uid = metaDataPreview.details.uid;
    const seriesUid = metaDataPreview.details.seriesUid;
    let _pageName = metaDataPreview.pageName;
    const seasonNumber = metaDataPreview.details.seasonNumber;
    const typeMap = {
      match: appMessages.media_type_match,
      movie: appMessages.media_type_movie,
      series: appMessages.media_type_series,
      liveevent: appMessages.media_type_liveevent,
      liveEvent: appMessages.media_type_liveEvent,
      preview: appMessages.media_type_preview,
    };
    if (!_pageName) _pageName = typeMap[type] || "";

    if (type) {
      const noWhiteSpaceTitle = (title || "")
        .replace(/\s/g, "-")
        .replace(/[$&+,/:;=?@"<>'#%{}|\\^~[\]]/g, "");
      switch (type) {
        case "movie":
          DetailsPagelinkUri = routeMap.details
            .replace(":pageName", _pageName)
            .replace(":type", appMessages.media_type_movie_id)
            .replace(":mediaId", `${uid}_${noWhiteSpaceTitle}`);
          break;
        case "series":
          DetailsPagelinkUri = routeMap.details
            .replace(":pageName", _pageName)
            .replace(":type", appMessages.media_type_series_id)
            .replace(":mediaId", `${uid}_${noWhiteSpaceTitle}`);
          break;
        case "episode":
          if (seriesUid !== "undefined") {
            DetailsPagelinkUri = routeMap.details
              .replace(":pageName", _pageName)
              .replace(":type", appMessages.media_type_series_id)
              .replace(":mediaId", `${seriesUid}_${noWhiteSpaceTitle}`);
            if (seasonNumber)
              metaDataPreview.details.seasonNumber = seasonNumber;
          } else DetailsPagelinkUri = "/";
          break;
        default:
          break;
      }
    }
    handleMouseOut();
    history.push(DetailsPagelinkUri, { details: metaDataPreview.details });
  };
  /**
   * It: Naviagtes to favourites route.
   */

  const goToFavourites = useCallback(() => {
    history.push(routeMap.favourites);
  }, [history]);
  /* --------------------------------------------- */
  const showControl = ()=>{
    if(controlRef.current?.resetTimer)
      controlRef.current.resetTimer()
  }
  /**
   * It: Sends Add/remove favourite api call.
   * It: Dispalys toast.
   * It: Sends favourites analytics.
   * It: Toggles favourite icon.
   */
  const favOnClickHandler = useCallback(() => {
    let isMounted = true;
    const baseUrl = appConfig.baseUrl;
    if (!baseUrl) {
      return;
    }
    const { type, uid, contentOfferId, contentGuid, recommendation_id, title } =
      metaDataPreview.details;

    if (isFavourite) {
      updateIsFavourite(false);
      showError({
        type: "RemoveFavouriteSuccess",
        value: { onPrimaryBtnClick: goToFavourites },
      });
      isAuthenticated &&
        deleteFavourites(baseUrl, uid)
          .then(() => {
            if (!isMounted) return;
            sendAnalytics({
              type: "_content.favourite",
              action: "remove",
              attr: {
                content_offer_id: contentOfferId,
                content_id: contentGuid,
                item_id: contentGuid,
                content_title: title,
                content_type: type,
                asset_id: uid,
                recommendation_id: recommendation_id || "",
              },
            });
          })
          .catch(() => {
            if (!isMounted) return;
            updateIsFavourite(true);
            hideAllErrors({ type: "RemoveFavouriteSuccess" });
            showError({ type: "GenericErrorRemoveFavourite" });
          });
    } else {
      updateIsFavourite(true);
      showError({
        type: "AddFavouriteSuccess",
        value: { onPrimaryBtnClick: goToFavourites },
      });
      const params = {
        language: splitLanguage(appLanguage),
      };
      isAuthenticated &&
        addFavourites(baseUrl, uid, params, metaDataPreview.details)
          .then(() => {
            if (!isMounted) return;
            sendAnalytics({
              type: "_content.favourite",
              action: "add",
              attr: {
                content_offer_id: contentOfferId,
                content_id: contentGuid,
                item_id: contentGuid,
                content_title: title,
                content_type: type,
                asset_id: uid,
                recommendation_id: recommendation_id || "",
              },
            });
          })
          .catch(() => {
            if (!isMounted) return;
            updateIsFavourite(false);
            hideAllErrors({ type: "AddFavouriteSuccess" });
            showError({ type: "GenericAddFavourite" });
          });
    }
    /* --------------------------------------------- */
    return () => {
      isMounted = false;
    };
  }, [
    addFavourites,
    deleteFavourites,
    appLanguage,
    isAuthenticated,
    isFavourite,
    sendAnalytics,
    goToFavourites,
    hideAllErrors,
    showError,
    metaDataPreview,
    appConfig,
  ]);

  if (!metaDataPreview) {
    return null;
  }

  function renderMuteUnmute() {
    if (!playerRef?.current || !autoPlayStarted) {
      return;
    }
    return (
      <>
        <MuteUnmute playerRef={playerRef} isPlayerLoaded ref={controlRef} />
      </>
    );
  }
  const {
    title,
    releaseYear,
    parentalControl,
    isCcAvailable,
    isHdAvailable,
    description,
    progress,
    duration,
  } = metaDataPreview?.details || {};
  const recentlyWatchedTimeRemaining =
    metaDataPreview?.recentlyWatchedTimeRemaining;
  return (
    <div
      className={
        isHeroRail.current
          ? "meta-data-preview-overlay hero-rail"
          : progress?"meta-data-preview has-progress scale-animation":"meta-data-preview-overlay scale-animation"
      }
      style={
        isHeroRail.current
          ? {}
          : { left: left, top: top, width: width, height: height }
      }
    >
      <div
        className={
          isHeroRail.current
            ? "meta-data-preview hero-rail scale-animation"
            : "meta-data-preview"
        }
        onMouseLeave={handleMouseOut}
        style={
          !isHeroRail.current
            ? {}
            : { left: left, top: top, width: width, height: height }
        }
      >
        <div
          className="meta-data-preview__player-container"
          id="player-wrapper"
        >
          {playerUrl && (
            <div
              className={autoPlayStarted ? "fade-in playing" : "not-playing"}
              onMouseMove={showControl}
              onMouseDown={showControl}
              onTouchMove={showControl}
            >
              <PlayerProvider
                setReleaseUrl={setReleaseUrl}
                getReleaseUrl={getReleaseUrl}
              >
                <Player
                  isPortraitPlayerEnabled={true}
                  setPlayerLoaded={setPlayerLoaded}
                  isPlayerLoaded={isPlayerLoaded}
                  isMuted={getMetaDataPlayerMuteState()}
                  onComplete={() => {
                    setAutoPlayStarted(false);
                    setShowDescription(true)
                  }}
                  onCurrentTimeChange={(currentTime) => {
                    if (currentTime > 100) {
                      setAutoPlayStarted(true);
                    }
                  }}   
                  onPlay={() => {
                    if (playerRef?.current) {
                      if (getMetaDataPlayerMuteState()) {
                        playerRef.current.onMuteClick();
                      } else {
                        playerRef.current.onUnmuteClick();
                      }
                    }
                  }}
                  ref={playerRef}
                  {...{
                    smilUrl: playerUrl,
                  }}
                  isTrailer= {true}
                />
              </PlayerProvider>
            </div>
          )}
          <div className={showDescription?"meta-data-preview__description-container has-description":"meta-data-preview__description-container"}>
            <img
              src={bgImage}
              alt="poster"
              className="meta-data-preview__image"
            />
            {showDescription  && <p className="meta-data-preview__description">{description}</p>}
          </div>
        </div>

        <div className="meta-data-preview__details">
          {renderMuteUnmute()}
          <div className="meta-data-preview__icons">
            <div
              onClick={() => handlePlayClick()}
              className="meta-data-preview__icon"
            >
              <PlayIcon
                color="#ffd200"
                style={{ width: 30, height: 30 }}
                className="play-icon"
              />
              <PlayIconFilled
                color="#ffd200"
                className="play-icon-filled"
                style={{ width: 30, height: 30 }}
              />
            </div>
            {isAuthenticated ? (
              <div
                onClick={favOnClickHandler}
                className="meta-data-preview__icon"
              >
                <FavMarkedIcon
                  color={"#ffd200"}
                  style={{ width: 30, height: 30 }}
                  className={
                    isFavourite ? "fav-icon-filled is-fav" : "fav-icon-filled"
                  }
                />
                <FavOutlineIcon
                  className={isFavourite ? "fav-icon is-fav" : "fav-icon"}
                  color={"#ffd200"}
                  style={{ width: 30, height: 30 }}
                />
              </div>
            ) : null}
            <div
              onClick={() => handleInfoIconClick()}
              className="meta-data-preview__icon"
            >
              <InfoIcon
                color="#ffd200"
                style={{ width: 30, height: 30 }}
                className="info-icon"
              />
              <InfoIconFilled
                color="#ffd200"
                className="info-icon-filled"
                style={{ width: 30, height: 30 }}
              />
            </div>
          </div>
          <div className="meta-data-preview__title">{title}</div>
          <div className="meda-data-preview__bottom-Info">
            <div>
              {displayDuration && <p className="duration">{displayDuration}</p>}
              <ul className="meta-data-preview__tags">
                {releaseYear && (
                  <li>
                    <span>{releaseYear}</span>
                  </li>
                )}
                {parentalControl &&
                  parentalControl[0] &&
                  parentalControl[0].rating && (
                    <li>
                      <span>{parentalControl[0].rating}</span>
                    </li>
                  )}
                {isCcAvailable && (
                  <li>
                    <span>{appMessages.label_details_cc}</span>
                  </li>
                )}
                {isHdAvailable && (
                  <li>
                    <span>{appMessages.label_details_hd}</span>
                  </li>
                )}
              </ul>
            </div>
            {recentlyWatchedTimeRemaining && (
              <p className="remaining-time">
                {trimLeadingZeroesFromTime(recentlyWatchedTimeRemaining.trim())}
              </p>
            )}
          </div>
        </div>
        {progress && (
          <ProgressBar
            {...{ duration, progress }}
            className="rail-item-info___progress"
          />
        )}
      </div>
    </div>
  );
};
export default MetaDataPreview;
