import { useContext, useCallback, useRef } from 'react'
/* --------------------------------------------- */
import { GlobalContext } from '../context/global.context'
/* --------------------------------------------- */
import { getUserId, removeLocalStorageData } from '../utils/localStorageService'
import { removeSessionStorageData } from '../utils/sessionStorageService'
/* --------------------------------------------- */
import useAnalytics from './useAnalytics'
import useLoggly from './useLoggly'
/* --------------------------------------------- */
import useErrorUtils from '../utils/useErrorUtils'
import _utils from '../utils/utils'
import { useHistory } from 'react-router-dom'
/* --------------------------------------------- */
// Custom hook to handle api errors.
const useErrorHandler = () => {
  const { dispatch, userInfo } = useContext(GlobalContext)
  const { sendAnalytics } = useAnalytics()
  const { log } = useLoggly()
  const { showError, hideAllErrors } = useErrorUtils()
  const history = useHistory()
  const userIdRef = useRef(null)

  /* --------------------------------------------- */
  const userInfoRef = useRef(userInfo)
  /* --------------------------------------------- */
  const handleError = useCallback((error = {}, other = {}, resErrorInfo = {}, errorConfiguration = []) => {
    const {
      errorCode = -1, errorStatus = '', errorMessage = '', apiUrl = '', custom = {},
      requestBody = '', responseBody = '', responseId = '', code = '', errorType = '', retry_count
    } = error
    const { isHideErrorScreens = false, isInitialTrustedLogin = false } = custom

    userIdRef.current = getUserId();
    console.log(userIdRef.current, "user")
    const { setAuthState = () => { }, subscriptionUri = '' } = other
    /* --------------------------------------------- */
    const errorInfo = {
      error_message: errorMessage,
      request_url: apiUrl,
      mw_error_code: errorCode,
      response_code: errorStatus,
      request_body: requestBody,
      request_id: responseId,
      response_body: responseBody,
      retry_count: retry_count,
      ...resErrorInfo
    }
    // User details that is to be sent to analytics during user logout 
    const uInfo = {
      user_id: userInfoRef.current.userId || '',
      user_email_hash: userInfoRef.current.emailHash || '',
      user_type: userInfoRef.current.customerType || '',
      user_source: userInfoRef.current.source || ''
    }
    /* --------------------------------------------- */
    if (!isHideErrorScreens && (errorCode === 7001 || errorCode === 4010 || ([1354, 1305, 1207, 1211, 1205, 1201].indexOf(errorCode) > -1))) { //to force logout user if refresh trusted login fails
      if (errorCode === 4010 && isInitialTrustedLogin) {
        const onPrimaryBtnClick = () => {
          dispatch({ type: 'FORCE_LOGOUT' })
        }
        sendAnalytics({ type: '_user.logout', attr: uInfo })
        hideAllErrors()
        setAuthState('LOGIN_FAILURE')
        showError({ type: 'UserLoginFailed', value: { onPrimaryBtnClick }, errorMessage, errorInfo })
        dispatch({ type: 'HIDE_LOGIN_POPUP' })
      } else {
        const onPrimaryBtnClick = () => {        
          dispatch({ type: 'SESSION_ERROR', value: false })
          history.push('/login')
          hideAllErrors()
        }
        sendAnalytics({ type: '_user.logout', attr: uInfo })
        hideAllErrors()
        dispatch({ type: 'SESSION_ERROR', value: true })
        dispatch({ type: 'UNAUTHENTICATED' })
        setAuthState('LOGIN_FAILURE')
        showError({ type: 'LoginSessionExpire', value: { onPrimaryBtnClick }, errorMessage, errorInfo, userId: userIdRef.current }) // passing userid to error log
        dispatch({ type: 'HIDE_LOGIN_POPUP' })
        dispatch({ type: 'USER_INFO', value: {} })
        removeSessionStorageData()
        removeLocalStorageData()
        // dispatch({ type: 'FORCE_LOGOUT' })
      }
      return
    } else if (!isHideErrorScreens && errorCode === 7002) {
      const onPrimaryBtnClick = () => {
        dispatch({ type: 'FORCE_LOGOUT' })
      }
      const onSecondaryBtnClick = () => {
        window.close()
      }
      sendAnalytics({ type: '_user.logout', attr: uInfo })
      dispatch({ type: 'UNAUTHENTICATED' })
      setAuthState('LOGIN_FAILURE')
      showError({ type: 'LoginUnsupportedUserType', value: { onPrimaryBtnClick, onSecondaryBtnClick }, errorMessage, errorInfo })
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
    } else if (!isHideErrorScreens && errorCode === 7003) {
      sendAnalytics({ type: '_user.logout', attr: uInfo })
      dispatch({ type: 'UNAUTHENTICATED' })
      setAuthState('LOGIN_FAILURE')
      showError({ type: 'UserAccountCreationFailed', errorMessage, errorInfo })
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
    } else if (!isHideErrorScreens && errorCode === 7004) {
      sendAnalytics({ type: '_user.logout', attr: uInfo })
      dispatch({ type: 'UNAUTHENTICATED' })
      setAuthState('LOGIN_FAILURE')
      showError({ type: 'UserProfileSavingFailed', errorMessage, errorInfo })
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
    } else if (!isHideErrorScreens && errorCode === 7005) {
      const onPrimaryBtnClick = () => {
        window.location.href = subscriptionUri
      }
      const onSecondaryBtnClick = () => {
        dispatch({ type: 'FORCE_LOGOUT' })
      }
      sendAnalytics({ type: '_user.logout', attr: uInfo })
      dispatch({ type: 'UNAUTHENTICATED' })
      setAuthState('LOGIN_FAILURE')
      showError({ type: 'LoginUnsupportedSubscriptionType', value: { onPrimaryBtnClick, onSecondaryBtnClick }, errorMessage, errorInfo })
      dispatch({ type: 'HIDE_LOGIN_POPUP' })
    }else if(!isHideErrorScreens && (errorCode === 1304 || errorCode === 1319)) {
      let configErrorType = errorCode === 1304 ? 'UserLoginFailed': 'UserDoesnotExist';
      let config = errorConfiguration?.filter((config) => {
        return config.type === configErrorType
      })[0] || {}
      log({
        type: config?.loggingLevel ? _utils.capitalizeFirstLetter(config?.loggingLevel) : 'Info', // Log type from corresponding error config from contentful
        attr: {
          module: 'Authentication',
          error_code:  config.code,        
          error_type: config?.type || responseBody?.message,
          mw_error_code: errorCode || (responseBody && JSON.stringify(responseBody)),
          error_shown: true,
          pageUrl:window.location.href,
          retry_count: retry_count,
          login_error_info: {
            request_header: (requestBody && JSON.stringify(requestBody)),
            request_url : apiUrl,
            response_code : responseBody?.errorCode,
            response_message : responseBody?.message || (responseBody && JSON.stringify(responseBody)),
          }
        }
      })
    } else if(!isHideErrorScreens && (['CRO_LYNK_4',7001,1003, 'BX-1001', 'BX-1002', 'BX-1003','BX-1004', 'BX-1005', 'BX-1006'].indexOf(errorCode) > -1)) {
      return
    } else if(requestBody?.paymentMethod) {
      return 
    } else if(responseBody?.error === 'ConcurrencyLimitViolation' || responseBody?.error === 'CONTENT_GEO_BLOCK') {
      return
    } else if(errorCode === 'network_error') { // to handle network errors and send error to loggly
      let config = errorConfiguration?.filter((config) => {
        return config.type === "AppNoNetwork"
      })[0] || {}
      log({
        type: config?.loggingLevel ? _utils.capitalizeFirstLetter(config?.loggingLevel) : 'Info', 
        attr: {
          module: 'General',
          error_code: config.code,
          error_type: 'AppNoNetwork',
          error_shown: false,
          pageUrl:window.location.href,
          retry_count: retry_count,
          api_error_info: {
            request_url : apiUrl,
            error_location: window.location.href
          }
        }
      })
      return
    } else if (apiUrl && !([1354, 1305, 1207, 1211, 1205, 1201].indexOf(errorCode) > -1)) {
      if(_utils.isValidUrl(apiUrl)) { //if concurrency error block from sending it as apierror
        const url = new URL(apiUrl);
        let isConcurrency = false
        try {
          isConcurrency = url?.searchParams?.get('_sequenceToken') ? true : false;
        } catch {}
        if(isConcurrency) {
          return
        }
    }
      const el = document.createElement( 'html' );
      el.innerHTML = responseBody
      /* --------------------------------------------- */
      const exceptionParam = el?.querySelector('param[name=\'exception\']')
      /* --------------------------------------------- */
      const exception = exceptionParam && exceptionParam.getAttribute('value')
      if(exception || error?.donotSendApiError)
      return
      log({
        type: [1303, 1302, 1312, 1322, 1318, 1311, 9029, 1310, 1119, 1300, 'VC_2', 1213].indexOf(errorCode) > -1  ? 'Info' : 'Error', // 1213, 1303, 1302, 1312, 9029, 1310, 1119, 1300, 'VC_2' these error codes needs to be send as Info logs. This is to remove after we handle the middleware errorcode is added to contentful error config
        attr: {
          module: 'General',
          error_code: code || 'A-4001',
          error_type: errorType || 'APIError',
          mw_error_code: errorCode || (responseBody && JSON.stringify(responseBody)),
          error_shown: false,
          pageUrl:window.location.href,
          retry_count: retry_count,
          api_error_info: {
            request_header: requestBody && JSON.stringify(requestBody),
            request_url : apiUrl,
            response_code : errorInfo?.response_code,
            response_message : responseBody?.message ? JSON.stringify(responseBody?.message) : (responseBody && JSON.stringify(responseBody)),
            error_location: window.location.href
          }
        }
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendAnalytics, hideAllErrors, showError, dispatch, log])
  /* --------------------------------------------- */
  return {
    handleError
  }
}
/* --------------------------------------------- */
export default useErrorHandler