import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getFaqItem from './getFaqItem'
import getTheme from './getTheme'
import getErrorConfiguration from './getErrorConfiguration'

// Helper function to form contentful tree
const getFeatureSupport = (contentful) => {
  const filterLocal = getFilter(contentful)
  const faqItemLocal = getFaqItem(contentful)
  const themeLocal = getTheme(contentful)
  const errorConfigurationLocal = getErrorConfiguration(contentful)
  const featureSupportLocal = contentful.featureSupport
  return featureSupportLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { filter = {}, faqData = [], errorConfiguration = [], theme = [] } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      faqData: getNew(faqData, faqItemLocal),
      errorConfiguration: getNew(errorConfiguration, errorConfigurationLocal),
      theme: getNew(theme, themeLocal)
    }
  })
}

export default getFeatureSupport
