/** Returns formated base 64 string */
export const getFormattedTokenInBase64 = (param1, param2, param3) => {
  if (!param1 || !param2) return ''
  // const account = 'http://access.auth.theplatform.com/data/Account/2707572913'
  return `Basic ${window.btoa(unescape(encodeURIComponent(`${param1}:${param2}:${param3}`)))}`
}

export const getFormattedTokenInBase64ForEmail = (param1) => {
  // console.log("params1");
  if (!param1) return ''
  // const account = 'http://access.auth.theplatform.com/data/Account/2707572913'
  return `Basic ${window.btoa(unescape(encodeURIComponent(`${param1}`)))}`
}

// Encoding func
export const getFormattedTokenInBase64ForContactDetails = (param1) => {
  // console.log("params1");
  if (!param1) return ''
  // const account = 'http://access.auth.theplatform.com/data/Account/2707572913'
  return `${window.btoa(unescape(encodeURIComponent(`${param1}`)))}`
}

// Decoding func
export const getDecodedBase64ForContactDetails = (param1) => {
  // console.log("params1");
  if (!param1) return ''
  return `${window.atob(`${param1}`)}`
}
