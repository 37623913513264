import React, { useState, useEffect, useCallback, useRef, useContext } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
import { routeMap } from '../../route'
/* --------------------------------------------- */
import utils from '../../utils/utils'
/* --------------------------------------------- */
import useLoggly from '../../containers/useLoggly'
import useLogout from '../../containers/useLogout'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
import { useGlobalNewDispatch } from '../../context/global.new.context'
import { useMiscsDispatch } from '../../context/miscs.context'
/* --------------------------------------------- */
import useAuthApi from '../../apis/useAuthApi'
/* --------------------------------------------- */
import ConfirmPopUp from '.././ConfirmPopUp'
/* --------------------------------------------- */
import useFirebaseTrace from '../../hooks/useFirebaseTrace'
/* --------------------------------------------- */
import '../../styles/components/Menu.scss'
import {
  SidebarMenu, SidebarMenuListWrapper, SidebarMenuList,
  SidebarMenuLink, SidebarMenuLinkExternal, SecondaryMenuItem,
} from './style'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
// import Avatar from '../../components/MultiProfileManagement/Avatar'
/* --------------------------------------------- */
// Menu component for displaying menu and events handled
const Menu = React.memo(({
  clickOutsideHandler,
  maxVisisbleMenuItems,
  isOpenMenu: isOpenMenuProp
}) => {
  const [isOpenMenu, setOpenMenu] = useState(false)
  const [baseUrl, setBaseUrl] = useState(null)
  const [sidebarNameTag, setSidebarNameTag] = useState(null)
  const [isConfirmPopup, updateIsConfirmPopup] = useState(false)
  const [theme, setTheme] = useState({})
  const [imageURL, setImageURL] = useState('')
  const [isShowLogin, setShowLogin] = useState(false)
  const [isShowLoginOptions, setShowLoginOptions] = useState(false)
  const sidebarMenuContainerRef = useRef(null)
  /* --------------------------------------------- */
  const { userInfo, isAuthenticated, uiDirection, dispatch } = useContext(GlobalContext)
  const globalNewDispatch = useGlobalNewDispatch()
  const miscsDispatch = useMiscsDispatch()

  /* --------------------------------------------- */
  const location = useLocation()
  const history = useHistory()
  /* --------------------------------------------- */
  const firebaseTrace = useFirebaseTrace()
  /* --------------------------------------------- */
  const { getUserDetails } = useAuthApi()
  // const history = useHistory()
  /* --------------------------------------------- */
  const { log } = useLoggly()
  const logout = useLogout()
  /* --------------------------------------------- */
  const { userManagementConfig = {} } = useSelector(store => store.userManagement)
  const { appConfig, appMessages } = useSelector(store => store.appConfigReducers)
  const { primaryMenuConfig, secondaryMenuConfig } = useSelector(store => store.navigation)
  const { profileManagementConfig = {} } = useSelector(store => store.profileManagement)
  /* --------------------------------------------- */
  const { loginMethods = [] } = userManagementConfig
  /* --------------------------------------------- */
  /**
   * It: Disables body scroll on side menu open.
   */
  useEffect(() => {
    if (isOpenMenu) miscsDispatch({ type: 'DISABLE_BODY_SCROLL' })
    else miscsDispatch({ type: 'ENABLE_BODY_SCROLL' })
  }, [isOpenMenu, miscsDispatch])
  /* --------------------------------------------- */
  /**
   * It: First name and last name avatart.
   */
  useEffect(() => {
    if (baseUrl && isAuthenticated && userInfo) {
      const { firstName, lastName } = userInfo
      let first = null
      let second = null
      if (firstName) first = firstName.charAt(0)
      if (lastName) second = lastName.charAt(0)
      if (first && second) setSidebarNameTag(`${first}${second}`)
    }
  }, [baseUrl, isAuthenticated, getUserDetails, userInfo])
  /* --------------------------------------------- */
  /**
   * It: Closes side menu.
   */
  const handleClickOutside = useCallback((event) => {
    const sidebarMenuContainer = sidebarMenuContainerRef.current
    if (sidebarMenuContainer && sidebarMenuContainer.contains(event.target)) return
    if (isOpenMenu) {
      clickOutsideHandler(false)
    }
  }, [isOpenMenu, clickOutsideHandler])
  /* --------------------------------------------- */
  /**
   * It: Closes side menu.
   */
  const handleLinkClick = useCallback((title, url) => {
    setOpenMenu(false)
    clickOutsideHandler(false)
    //sending info log to loggly if user clicks on sidebar menu item
    log({
      type: 'Info',
      attr: {
        module: 'General',
        debug_message: `Navigated to ${title}`,
      }
    })
    // setTimeout(() => {     // removed to resolve this error : Cannot read properties of undefined (reading 'pathname')
    //   history.push(url)
    // }, 1500)
  }, [log, clickOutsideHandler
    //  history
    ])
  /* --------------------------------------------- */
  /**
   * It: Closes side menu.
   * It: Shows login popup.
   * It: Redirects to login page.
   */
  const handleLoginClick = () => {
    clickOutsideHandler(false)
    if ((loginMethods || []).indexOf('vdt') > -1) {
      dispatch({ type: 'IS_POPUP_IN_VIEWPORT', value: true })
      disableBodyScroll(document.body, { reserveScrollBarGap: true })
      dispatch({ type: 'SHOW_LOGIN_POPUP' })
      dispatch({
        type: 'LOGIN_POPUP_DETAILS',
        value: {
          isShowCloseIcon: true,
          onCloseClick: () => {
            dispatch({ type: 'IS_POPUP_IN_VIEWPORT', value: false })
            enableBodyScroll(document.body)
            dispatch({ type: 'HIDE_LOGIN_POPUP' })
          }
        }
      })
    }
    else {
      dispatch({ type: 'SET_PREVIOUS_URL', value: window?.location?.pathname})
      history.push(routeMap.login)
    }
  }
  /* --------------------------------------------- */
  /**
   * It: Opens logout confirm popup.
   * It: Closes side menu.
   */
  const handleSignOut = () => {
    updateIsConfirmPopup(true)
    dispatch({ type: "HIDE_MENU_SIDEBAR" })
    setOpenMenu(false)
    clickOutsideHandler(false)
  }
  /* --------------------------------------------- */
  /**
   * It: Closes confirm popup.
   */
  const handlePositiveBtn = useCallback(() => {
    updateIsConfirmPopup(false)
  }, [])
  /* --------------------------------------------- */
  /**
   * It: Logouts out the user.
   */
  const handleLogoutClick = useCallback(() => {
    updateIsConfirmPopup(false)
    dispatch({ type: 'USER_INFO', value: {} })
    dispatch({ type: 'UNAUTHENTICATED' })

    // window.localStorage.clear()
    // localStorage.setItem('selected-profile', {})
    logout()
  }, [dispatch, logout])
  /* --------------------------------------------- */
  /**
   * It: Sets mousedown event listener.
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])
  /* --------------------------------------------- */
  /**
   * It: Scrolls to top on every side menu open.
   */
  useEffect(() => {
    if (isOpenMenu) {
      if (utils.isNotNull(sidebarMenuContainerRef.current, 'scrollTop')) {
        sidebarMenuContainerRef.current.scrollTop = 0
      }
    }
  }, [isOpenMenu])
  /* --------------------------------------------- */
  /**
   * It: Sets base url.
   */
  useEffect(() => {
    setBaseUrl(appConfig.baseUrl)
  }, [appConfig.baseUrl])
  /* --------------------------------------------- */
  /**
   * It: Sets side menu open state
   */
  useEffect(() => {
    setOpenMenu(isOpenMenuProp)
  }, [isOpenMenuProp])
  /* --------------------------------------------- */
  /**
   * It: Sets theme.
   */
  useEffect(() => {
    const theme = primaryMenuConfig.theme || {}
    setTheme(theme)
  }, [primaryMenuConfig.theme])
  /* --------------------------------------------- */
  /**
   * It: Sets settings title.
   */
  useEffect(() => {
    if (!secondaryMenuConfig.menuItems) return
    /* --------------------------------------------- */
    secondaryMenuConfig.menuItems.map((config) => {
      const { title, identifierExt } = config
      if (identifierExt === 'appsetting') {
        globalNewDispatch({ type: 'SETTINGS_TITLE', value: title })
      }
      if (identifierExt === 'appsetting' || true) {
        globalNewDispatch({ type: 'CHANGE_LANGUAGE_TITLE', value: "Change Language" })
      }
      return null
    })
  }, [secondaryMenuConfig.menuItems, globalNewDispatch])
  /* --------------------------------------------- */
  /**
   * It: Show/hide login option in side menu.
   */
  useEffect(() => {
    if (loginMethods.length === 0) setShowLoginOptions(false)
    else setShowLoginOptions(loginMethods.indexOf('cognito') > -1)
  }, [loginMethods])
  /* --------------------------------------------- */
  /**
   * It: Opens setting popup.
   */
  const handleSettingsClick = () => {
    let trace = firebaseTrace('static_page_load', {
      pageTitle: 'settings'
    })
    globalNewDispatch({ type: 'OPEN_SETTINGS_POPUP' })
    trace.stop()
    clickOutsideHandler(false)
  }

  const handleLanguageClick = () => {
    let trace = firebaseTrace('static_page_load', {
      pageTitle: 'change_language'
    })
    globalNewDispatch({ type: 'OPEN_LANGUAGE_POPUP' })
    trace.stop()
    clickOutsideHandler(false)
  }

  /* --------------------------------------------- */
  /**
   * It: Opens profiles popup.
   */
   const handleProfileClick = () => {
    let trace = firebaseTrace('static_page_load', {
      pageTitle: 'profiles'
    })
    const isMultiProfileEnabled = Object.keys(profileManagementConfig).length > 0
    if(isMultiProfileEnabled) {
      dispatch({ type: 'SET_SHOW_MULTI_PROFILE_LIST', value: true })
    }
    trace.stop()
    clickOutsideHandler(false)
  }
  /* --------------------------------------------- */
  /**
   * It: Shows login popup when unauthenticated.
   */
  useEffect(() => {
    setShowLogin(!isAuthenticated)
  }, [isAuthenticated])
  /* --------------------------------------------- */
  /**
   * It: Sets image in a state for showing in sie menu.
   */
  useEffect(() => {
    let imageURL = ''
    const { graphics } = theme
    graphics && graphics.map(({ id, images }) => {
      if (id !== 'app_menu_logo') return null
      imageURL = utils.prependProtocol(images[0].url)
      return null
    })
    setImageURL(imageURL)
  }, [theme])
  /* --------------------------------------------- */
  return (
    <>
      <div className={`sidebar ${isOpenMenu ? 'sidebar--open' : ''} `} >
        <div className='sidebar__overlay' />
        <SidebarMenu theme={theme} className='sidebar__menu' ref={sidebarMenuContainerRef}>
          {isShowLoginOptions && imageURL &&
            <div className='sidebar__menu-header'>
              <img src={imageURL} alt='' />
            </div>}
          {/* {isShowLoginOptions &&
            <div className='sidebar__btn-set'>
              {!isShowLogin ? <p onClick={handleSignOut} className='sign-up'>{appMessages.logout_popup_ok}</p> : <NavLink to={routeMap.signup} onClick={staticBtnClickHandle} className='sign-up'>{appMessages.label_log_in_sign_up}</NavLink>}
              {isShowLogin ? <p className='login'>or <NavLink onClick={staticBtnClickHandle} to={routeMap.login}>{appMessages.label_forgot_password_log_in}</NavLink></p> : null}
            </div>} */}
          <SidebarMenuListWrapper {...{ imageURL }} className='sidebar__menu-links'>
            <SidebarMenuList maxVisisbleMenuItems={maxVisisbleMenuItems}>
              <>
              {/* {isAuthenticated && selectedProfile &&  
              <li onClick={() => handleProfileClick()}>
                  <div className="sidebar__profile-item">
                     <Avatar image={selectedProfile && selectedProfile.avatar ? selectedProfile.avatar : ''} showBorder={true} height='30px' />
                      <span>{selectedProfile && selectedProfile.name ? selectedProfile.name : ''}</span>
                  </div>
              </li>} */}
                {primaryMenuConfig.menuItems && primaryMenuConfig.menuItems.map(({ title, type, showSeparatorBefore, identifierExt, url, buttonStyle, isHome }, index) => {
                  if (isShowLogin && url === routeMap.myAccount) return null
                  if (identifierExt === 'myaccount') url = routeMap.myAccount
                  if (identifierExt === 'tv-guide') url = routeMap.tvGuide
                  if(identifierExt==='home'){
                    url='/'
                  }
                  let isActive = false
                  if (
                    decodeURIComponent(location?.pathname) === url ||
                    (location?.pathname === '/' && isHome)
                  ) {
                    isActive = true
                  }
                  let _return = null
                  if (url && type === 'external') {
                    _return = (
                      <li key={index}>
                        <SidebarMenuLinkExternal
                          menuButtonStyle={buttonStyle}
                          dir={uiDirection}
                          showSeparatorBefore={`${showSeparatorBefore}`}
                          href={url}
                          target='_blank'
                        > {title}
                        </SidebarMenuLinkExternal>
                      </li>
                    )
                  } else if (url && type === 'webview') {
                    _return = (
                      <li key={index}>
                        <SidebarMenuLinkExternal
                          dir={uiDirection}
                          menuButtonStyle={buttonStyle}
                          showSeparatorBefore={`${showSeparatorBefore}`}
                          href={url}
                        > {title}
                        </SidebarMenuLinkExternal>
                      </li>
                    )
                  } else {
                    _return = (
                      <li key={index}>
                        <SidebarMenuLink
                          dir={uiDirection}
                          menuButtonStyle={buttonStyle}
                          showSeparatorBefore={`${showSeparatorBefore}`}
                          className={(isActive) ? 'active' : ''}
                          onClick={() => handleLinkClick(title, url)}
                          to={url}
                        > {title}
                          {url === routeMap.myAccount && sidebarNameTag && <div className='sidebar-account-nametag'>{sidebarNameTag}</div>}
                        </SidebarMenuLink>
                      </li>
                    )
                  }
                  return _return
                })}
              </>
              <>
                {secondaryMenuConfig.menuItems && secondaryMenuConfig.menuItems.map((config, i) => {
                  const { id, title, url, buttonStyle, type } = config
                  if (url && type === 'external') {
                    return (
                      <li key={i}>
                        <SidebarMenuLinkExternal
                          className='mobile-only'
                          menuButtonStyle={buttonStyle}
                          dir={uiDirection}
                          href={url}
                          target='_blank'
                        > {title}
                        </SidebarMenuLinkExternal>
                      </li>
                    )
                  } else if (url && type === 'webview') {
                    return (
                      <li key={i}>
                        <SidebarMenuLinkExternal
                          className='mobile-only'
                          menuButtonStyle={buttonStyle}
                          dir={uiDirection}
                          href={url}
                        > {title}
                        </SidebarMenuLinkExternal>
                      </li>
                    )
                  } else {
                    if (id === 'login') {
                      if (isAuthenticated) return null
                      else {
                        return (
                          <li key={i}>
                            <SecondaryMenuItem className='mobile-only' menuButtonStyle={buttonStyle} onClick={handleLoginClick} dir={uiDirection}>
                              {title}
                            </SecondaryMenuItem>
                          </li>
                        )
                      }
                    } else if (id === 'logout') {
                      if (!isAuthenticated) return null
                      else {
                        return (
                          <li key={i}>
                            <SecondaryMenuItem className='mobile-only' menuButtonStyle={buttonStyle} onClick={handleSignOut} dir={uiDirection}>
                              {title}
                            </SecondaryMenuItem>
                          </li>
                        )
                      }
                    } else if (id === 'menu_settings') {
                      return (
                        <li key={i}>
                          <SecondaryMenuItem className='mobile-only' menuButtonStyle={buttonStyle} onClick={handleSettingsClick} dir={uiDirection}>
                            {title}
                          </SecondaryMenuItem>
                        </li>
                      )
                    }
                    else if (id && id.trim() === 'menu_language') {
                      return (
                        <li key={i}>
                          <SecondaryMenuItem className='mobile-only' menuButtonStyle={buttonStyle} onClick={handleLanguageClick} dir={uiDirection}>
                            {title}
                          </SecondaryMenuItem>
                        </li>
                      )
                    }
                    else if (id === 'profiles' && isAuthenticated) {
                      return (
                        <li key={i}>
                          <SecondaryMenuItem className='mobile-only' menuButtonStyle={buttonStyle} onClick={handleProfileClick} dir={uiDirection}>
                            {title}
                          </SecondaryMenuItem>
                        </li>
                      )
                    }else if (id === 'myaccount' && isAuthenticated) {
                      return (
                        <li key={i}>
                          <SecondaryMenuItem
                          className={`mobile-only ${location?.pathname === '/my-account' ? 'active' : ''}`}
                          menuButtonStyle={buttonStyle} 
                          onClick={() => {
                            clickOutsideHandler(false)
                            history.push(id === 'myaccount' ? '/my-account' : url)
                          }} 
                            dir={uiDirection}>
                            {title}
                          </SecondaryMenuItem>
                        </li>
                      )
                    } else {
                      return null
                    }
                  }
                })}
              </>
            </SidebarMenuList>
          </SidebarMenuListWrapper>
        </SidebarMenu>
      </div>
      {isConfirmPopup && (
        <ConfirmPopUp
          onPositiveBtnClick={handlePositiveBtn}
          {...{
            title: appMessages.logout_popup_title,
            desc: appMessages.logout_popup_description,
            negtiveText: appMessages.label_logout_cancel,
            positiveText: appMessages.logout_popup_ok
          }}
          onNegativeBtnClick={handleLogoutClick}
        />
      )}
    </>
  )
})


/* --------------------------------------------- */
Menu.propTypes = {
  items: PropTypes.array,
  isOpenMenu: PropTypes.bool,
  maxVisisbleMenuItems: PropTypes.number
}
/* --------------------------------------------- */
Menu.defaultProps = {
  items: [],
  isOpenMenu: false
}
/* --------------------------------------------- */
export default Menu

