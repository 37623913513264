import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getUserAuthConfiguration from './getUserAuthConfiguration'
import getLabel from './getLabel'
import getTheme from './getTheme'

// Helper function to form contentful tree
const getFeatureUserManagement = (contentful) => {
  const filterLocal = getFilter(contentful)
  const userAuthConfigurationLocal = getUserAuthConfiguration(contentful)
  const labelLocal = getLabel(contentful)
  const themeLocal = getTheme(contentful)
  const featureUserManagementLocal = contentful.featureUserManagementLocal
  return featureUserManagementLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      filter = {},
      authConfigurations = [],
      genderList = [],
      loginTheme = [],
      profileTheme = [],
      registrationTheme = [],
      resetPasswordTheme = [],
      themeProfileList = [],
      themeProfileDetails = [],
      themeProfilePopup = []
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      authConfigurations: getNew(authConfigurations, userAuthConfigurationLocal),
      genderList: getNew(genderList, labelLocal),
      loginTheme: getNew(loginTheme, themeLocal),
      profileTheme: getNew(profileTheme, themeLocal),
      registrationTheme: getNew(registrationTheme, themeLocal),
      resetPasswordTheme: getNew(resetPasswordTheme, themeLocal),
      themeProfileList: getNew(themeProfileList, themeLocal),
      themeProfileDetails: getNew(themeProfileDetails, themeLocal),
      themeProfilePopup: getNew(themeProfilePopup, themeLocal)
    }
  })
}

export default getFeatureUserManagement
