import React, { createContext, useReducer } from 'react'
/* --------------------------------------------- */
/** Navigation initial state */
const initialState = {
  isSideMenuOpen: false,
  dispatch: () => { }
}
/* --------------------------------------------- */
/** Navigation context */
export const NavigationStateContext = createContext(initialState)
export const NavigationDispatchContext = createContext(initialState)
/* --------------------------------------------- */
/** Navigation reducer */
const navigationReducer = (state, action) => {
  switch (action.type) {
    case 'SHOW_MENU_SIDEBAR':
      return { ...state, isSideMenuOpen: true }
    case 'HIDE_MENU_SIDEBAR':
      return { ...state, isSideMenuOpen: false }
    default:
      return state
  }
}
/* --------------------------------------------- */
/** Navigation provider */
const NavigationProvider = (props) => {
  const [state, dispatch] = useReducer(navigationReducer, initialState)
  /* --------------------------------------------- */
  return (
    <NavigationStateContext.Provider value={state}>
      <NavigationDispatchContext.Provider value={dispatch}>
        {props.children}
      </NavigationDispatchContext.Provider>
    </NavigationStateContext.Provider>
  )
}
/* --------------------------------------------- */
export default NavigationProvider
