// Helper function to form contentful tree
const getLabel = (contentful) => {
  const labelLocal = contentful.label
  return labelLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    return { sysId: id, contentTypeId, ...fields }
  })
}

export default getLabel
