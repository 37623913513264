import { useCallback, useRef, useContext } from 'react'
import axios from 'axios'
import * as rax from 'retry-axios'
/* --------------------------------------------- */
import { getUserAuthInfo, getAppBaseUrl } from '../utils/localStorageService'
import utils from '../utils/utils'
/* --------------------------------------------- */
import useErrorHandler from '../containers/useErrorHandler'
/* --------------------------------------------- */
import VERSION from '../constants/apiVersionMap'
/* --------------------------------------------- */
import { GlobalContext } from '../context/global.context'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
window.axios = axios
/* --------------------------------------------- */
rax.attach()
/* --------------------------------------------- */
function refreshToken(url, body, dispatch) {
  const refreshingCall = axios.post(url, body , {withCredentials: true}).then((res) => {
      // store.commit('auth/setToken', token)
      // store.commit('auth/setRefreshingState', false);
      dispatch({ type: 'USER_TOKEN', value: res?.idToken || '' })
      // store.commit('auth/setRefreshingCall', undefined);
      return Promise.resolve(res?.data);
  });

  // store.commit('auth/setRefreshingCall', refreshingCall);
  return refreshingCall;
}

//to check online or offline fetching time api
const checkOnlineStatus = async (baseUrl) => {
  try {
    const online = await fetch(`${baseUrl}${VERSION.time}/time`);
    return online.status >= 200 && online.status < 300; // either true or false
  } catch (err) {
    return false; // definitely offline
  }
};

const useGeneralApi = () => {
  const { handleError } = useErrorHandler()
  const { sessionId, dispatch } = useContext(GlobalContext)
  const { supportConfig } = useSelector(store => store.support)
  const sessionIdRef = useRef(sessionId)
  const { appConfig } = useSelector(store => store.appConfigReducers)
  // const { refreshTrustedLogin } = useAuthApi()

  /* --------------------------------------------- */
  const fetchApi = useCallback((config = {}, body = {}, headers = {}, other = {}) => {
    if ('Authorization' in headers && !headers.Authorization) {
      return Promise.reject(new Error(`Token Missing. URL: ${other.url}`))
    }
    headers = headers || {}
    other = other || {}
    config.__retryCount = 0; //initializing retry count to 0 because of that we were getting wrong retry counts
    if (sessionIdRef.current && (headers.Authorization || other.trustedLogin) && !other.isDoNotSetSessionId) {
      headers.session_id = sessionIdRef.current
    }
    /* --------------------------------------------- */
    if (Object.keys(body).length > 0) config.data = body
    if (Object.keys(headers).length > 0) config.headers = headers
    if (headers.responseType) {
      config.responseType = headers.responseType
      if (config.headers) delete config.headers.responseType
    }
    try {
      // TO check whether the URL is valid or not
      if (utils.isValidUrl(config?.url) && new URL(config?.url)?.pathname?.split('/')?.pop() !== 'trustedLogin') {
        config.raxConfig = {
          retry: 3, // Retry 3 times on requests that return a response (500, etc) before giving up.  Defaults to 3.
          httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'POST'],
          statusCodesToRetry: [[100, 199], [401, 401], [429, 429], [500, 599]]
        }
      }
    } catch {}

    /* --------------------------------------------- */
    axios.interceptors.response.use(response => response, (err) => {
      const status = err.response ? err.response.status : null
        if (status === 403) {
          const config = {...err.config} //prevously it set as var variable changing to const
          const baseUrl = getAppBaseUrl(); //prevously it set as var variable changing to const
          const currentAPIBaseUrl = new URL(config?.url).origin; //current API base URL
          if (baseUrl === currentAPIBaseUrl || baseUrl?.indexOf(currentAPIBaseUrl) > -1) { //checking if URL string matches
          const url = `${new URL(config.url).origin}/${VERSION.refreshTrustedLogin}/accounts/trustedLogin`
          let authInfoLocal = getUserAuthInfo()
          const { identityProvider, authInfo } = authInfoLocal
          if(identityProvider)
          return refreshToken(url, {
            forceRefresh: true,
            platform: 'web',
            identityProvider,
            authMode: 'refreshToken',
            personas: false,
            authInfo
          }, dispatch).then(res => {
            const token = res.idToken || ''
            dispatch({ type: 'USER_TOKEN', value: token || '' })
            err.config.headers['Authorization'] = 'Bearer ' + token;
            // err.config.baseURL = undefined;
            return axios.request(err.config);
        })
        // Would be nice to catch an error here, which would work if the interceptor is omitted
        .catch(error => Promise.reject(error)
        );
        }
      }
      // Set the variable for keeping track of the retry count
      config.__retryCount = config.__retryCount || 0
      config.__retryCount += 1
      return Promise.reject(err)
    }
    )
    return axios(config).then(response => {
      // Enable below lines to reproduce 4010 error
      // if (config.url === 'https://api-videotron-qa.create.diagnal.com/accounts/trustedLogin') {
      //   return Promise.reject({
      //     response: {
      //       data: {
      //         errorCode: 4010
      //       }
      //     }
      //   })
      // }
      return response.data
    }).catch(async(error) => {
      error = error || {}
      if (error.response) {
        const { data = {}, status = '', statusText = '', headers = {}, config = {} } = error.response || {}
        const { message = {}, errorCode = null } = data || {}
        const apiError = new Error(message)
        apiError.errorMessage = statusText
        apiError.errorCode = errorCode
        apiError.errorStatus = status
        apiError.apiUrl = config.url
        apiError.custom = other
        apiError.requestBody = config.data ? utils.parseJSON(config.data) : ''
        apiError.responseBody = data
        apiError.responseId = headers['x-amzn-requestid']
        apiError.code = other.isContentfulApi ? 'A-6001' : 'A-4001'
        apiError.errorType = other.isContentfulApi ? 'ServerError' : 'APIError'
        apiError.retry_count = config.__retryCount || 1
        handleError(apiError, {}, error, supportConfig?.errorConfiguration) // sending error configuration to error handler page
        return Promise.reject(apiError)
      } 
      else {
        const isOnline = await checkOnlineStatus(appConfig?.baseUrl); // to check whether online or offline
        //to handle network errors
        const networkError = new Error("Network Error")
        if(!isOnline) {
          networkError.errorCode = "network_error"
        } else {
          networkError.donotSendApiError = true;
        }
        // if its a network error we will not get response. Added api url and request body in the error object to send error info to loggly
        try{
          networkError.errorMessage = JSON.stringify(error)
          networkError.apiUrl = config?.url
          networkError.requestBody = config.data ? utils.parseJSON(config.data) : ''
          networkError.retry_count = config.__retryCount || 1

        } catch {}
        handleError(networkError, {}, error, supportConfig?.errorConfiguration) // sending error configuration to error handler page
        return Promise.reject(networkError)
      }
    })
  }, [appConfig, dispatch, handleError, supportConfig])
  /* --------------------------------------------- */
  const getData = useCallback((url, body = {}, headers = {}, config = {}, other = {}) => {
    return fetchApi({ method: 'GET', url, ...config }, body, headers, other)
  }, [fetchApi])
  /* --------------------------------------------- */
  const postData = useCallback((url, body = {}, headers = {}, config = {}, other = {}) => {
    return fetchApi({ method: 'POST', url, ...config }, body, headers, other)
  }, [fetchApi])
  /* --------------------------------------------- */
  const putData = useCallback((url, body = {}, headers = {}, config = {}, other = {}) => {
    return fetchApi({ method: 'PUT', url, ...config }, body, headers, other)
  }, [fetchApi])
  /* --------------------------------------------- */
  const deleteData = useCallback((url, body = {}, headers = {}, config = {}, other = {}) => {
    return fetchApi({ method: 'DELETE', url, ...config }, body, headers, other)
  }, [fetchApi])
  /* --------------------------------------------- */
  const getRailInfo = useCallback((baseUrl, dataUrl, params) => {
    dataUrl = dataUrl.split('://')
    const slug = dataUrl[1]
    const url = `${baseUrl}${VERSION.getRailInfo}/content/filters/${slug}?${params}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  const getSeasonDetails = useCallback((baseUrl, seriesId, { language }) => {
    const url = `${baseUrl}${VERSION.getSeasonDetails}/content/items?type=season&filterBy=series&filter=${seriesId}&language=${language}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  const getSessionState = useCallback(() => {
    const authInfoLocal = getUserAuthInfo() || {}
    const now = Date.now()
    const nextRefreshTime = authInfoLocal.nextRefreshTime || null
    const identityProvider = authInfoLocal.identityProvider || ''
    if (!nextRefreshTime && (!identityProvider || identityProvider !== 'mpx')) return 'INVALID_SESSION'
    if (nextRefreshTime && (now > nextRefreshTime)) return 'SESSION_EXPIRED'
    return 'VALID_SESSION'
  }, [])
  /* --------------------------------------------- */
  const getChannelInfo = useCallback((baseUrl, channelID, query) => {
    const url = `${baseUrl}${VERSION.getChannelInfo}/content/channels?id=${channelID}&${query}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  const getListItems = useCallback((baseUrl, query) => {
    const url = `${baseUrl}${VERSION.getListItems}/content/items?${query}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  const getRecommendations = useCallback((baseUrl, query) => {
    const url = `${baseUrl}${VERSION.getRecommendations}/content/recommendations?${query}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  const getBestMatch = useCallback((baseUrl, { query, maxResultCount, searchMode, ratio, language, size, page, source, searchResultsFilter }) => {
    let url = `${baseUrl}${VERSION.getBestMatch}/search?language=${language}`
    if (query) url += `&query=${query}`
    if (maxResultCount) url += `&maxResultCount=${maxResultCount}`
    if (searchMode) url += `&searchMode=${searchMode}`
    if (ratio) url += `&ratio=${ratio}`
    if (size) url += `&size=${size}`
    if (page) url += `&page=${page}`
    if (source) url += `&source=${source}`
    if (searchResultsFilter) url += `&${searchResultsFilter}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  const getSearchSuggestion = useCallback((baseUrl, { query, maxResultCount, ratio, language, profileId, searchSuggestionsFilter }) => {
    let url = `${baseUrl}${VERSION.getSearchSuggestion}/searchSuggestions?language=${language}`
    if (query) url += `&query=${query}`
    if (ratio) url += `&ratio=${ratio}`
    if (maxResultCount) url += `&maxResultCount=${maxResultCount}`
    if (profileId) url += `&profileId=${profileId}`
    if (searchSuggestionsFilter) url += `&${searchSuggestionsFilter}`
    return getData(url)
  }, [getData])
  /* --------------------------------------------- */
  return {
    getData,
    putData,
    postData,
    deleteData,
    getRailInfo,
    getSeasonDetails,
    getChannelInfo,
    getListItems,
    getRecommendations,
    getBestMatch,
    getSearchSuggestion,
    getSessionState
  }
}
/* --------------------------------------------- */
export default useGeneralApi
