import { getNewObj } from '../utils/contentfulHandler'
import getColor from './getColor'

// Helper function to form contentful tree
const getColorGradient = (contentful) => {
  const colorLocal = getColor(contentful)
  const colorGradientLocal = contentful.colorGradient
  return colorGradientLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { startColor = {}, centerColor = {}, endColor = {} } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      startColor: getNewObj(startColor, [colorLocal]),
      centerColor: getNewObj(centerColor, [colorLocal]),
      endColor: getNewObj(endColor, [colorLocal])
    }
  })
}

export default getColorGradient
