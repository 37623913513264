import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getPage from './getPage'
import getButtonStyle from './getButtonStyle'

// Helper function to form contentful tree
const getMenuItem = (contentful, ignorePage = false) => {
  const filterLocal = getFilter(contentful)
  const pageLocal = (!ignorePage) ? getPage(contentful) : []
  const buttonStyleLocal = getButtonStyle(contentful)
  const menuItemLocal = contentful.menuItem
  return menuItemLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      filter = {}, page = [], buttonStyle = {}
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      page: (!ignorePage) ? getNew(page, pageLocal) : [],
      buttonStyle: getNewObj(buttonStyle, [buttonStyleLocal])
    }
  })
}

export default getMenuItem
