import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
import useAnalytics from '../../containers/useAnalytics'
import useErrorHandler from '../../containers/useErrorHandler'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
/* --------------------------------------------- */
import { openAndListenNewWin } from '../pages/LoginPopup/LoginPopup.helper'
/* --------------------------------------------- */
import utils from '../../utils/utils'
import useErrorUtils from '../../utils/useErrorUtils'
import { removeLocalStorageData, setTVLoginRedirect, getTVLoginRedirect } from '../../utils/localStorageService'
import { removeSessionStorageData } from '../../utils/sessionStorageService'
/* --------------------------------------------- */
import googleLogo from '../../imgs/GoogleLogo.svg'
/* --------------------------------------------- */
import useAuthApi from '../../apis/useAuthApi'
/* --------------------------------------------- */
import { routeMap } from '../../route'
import Right_Arrow from '../../imgs/Right_Arrow.png'
// GoogleLogin component for social login.
const GoogleLogin = ({ method = "signin" }) => {
    const { appConfig, appMessages = {} } = useSelector(store => store.appConfigReducers)
    const { userManagementConfig = {} } = useSelector(store => store.userManagement)
    /* --------------------------------------------- */
    const { authConfig: userAuthConfig = [] } = userManagementConfig
    /* --------------------------------------------- */
    const { trustedLogin } = useAuthApi()
    const { showError, hideError, hideAllErrors } = useErrorUtils()
    /* --------------------------------------------- */
    const [authState, setAuthState] = useState('')
    const [authConfig, setAuthConfig] = useState({})
    /* --------------------------------------------- */
    const { dispatch, previousURL } = useContext(GlobalContext)
    /* --------------------------------------------- */
    const history = useHistory()
    /* --------------------------------------------- */
    const { sendAnalytics } = useAnalytics()
    const { handleError } = useErrorHandler()
    /* --------------------------------------------- */
    const { profileManagementConfig = {} } = useSelector(store => store.profileManagement)
    /**
     * @param {string} url 
     * When: Should be called on external login success.
     * It: Calls trutedLogin and update state based on success/failure response.
     */
    const saveToken = (url) => {
        // console.log({url})
        // console.log('Location changed. New URL:' + url)
        const { code } = utils.parse(url)
        // console.log(code, 'code')
        if (!code) {
            setAuthState('LOGIN_FAILURE')
            showError({ type: 'UserLoginFailed' })
            return
        }
        let redirectUri = authConfig.redirectUri
        if (window.location.hostname === 'localhost') redirectUri = `${window.location.origin}/callback`
        setAuthState('LOGIN_PROGRESS')
        trustedLogin(appConfig.baseUrl, {
            code,
            identityProvider: authConfig.identityProvider,
            logoutExtendPeriod: authConfig.logoutExtendPeriod,
            accessTokenRefreshInterval: authConfig.accessTokenRefreshInterval,
            redirectUri,
            personas: true,
        }).then(() => {
            setAuthState('LOGIN_SUCCESS')
            const isMultiProfileEnabled = Object.keys(profileManagementConfig).length > 0
            const showSelectionOnStart = profileManagementConfig.showSelectionOnStart
            if (isMultiProfileEnabled) {
                dispatch({ type: 'SET_SHOW_MULTI_PROFILE_LIST', value: showSelectionOnStart })
            }
        }).catch((error) => {
            setAuthState('LOGIN_FAILURE')
            handleError(error, {
                setAuthState,
                subscriptionUri: authConfig.subscriptionUri
            })
        })
    }
    /* --------------------------------------------- */
    /**
     * When: Should be called on google signup.
     * It: Opens login window and handles success/failure state of login.
     */
    const handleSignupGoogleClick = () => {
        if (!authConfig.identityProvider) return
        /* --------------------------------------------- */
        const url = `${window.location.origin}/auth/${authConfig.identityProvider}/login`
        openAndListenNewWin(url, {
            onProgress: () => {
                hideAllErrors()
                setAuthState('LOGIN_PROGRESS')
            },
            onBlock: () => {
                const onPrimaryBtnClick = () => {
                    setAuthState('')
                    hideError({ type: 'LoginPopupError' })
                    dispatch({ type: 'SHOW_LOGIN_POPUP' })
                    dispatch({ type: 'USER_INFO', value: {} })
                    removeLocalStorageData()
                    removeSessionStorageData()
                }
                setAuthState('LOGIN_FAILURE')
                hideAllErrors()
                dispatch({ type: 'HIDE_LOGIN_POPUP' })
                showError({ type: 'LoginPopupError', value: { onPrimaryBtnClick } })
            },
            onFailure: () => {
                setAuthState('LOGIN_FAILURE')
                hideAllErrors()
            },
            onSuccess: url => {
                hideAllErrors()
                saveToken(url)
            },
        }, {
            width: 600,
            height: 540
        })
    }
    /* --------------------------------------------- */
    /**
     * It: Shows/hides spinner based on authState
     */
    useEffect(() => {
        if (authState === 'LOGIN_PROGRESS') {
            dispatch({ type: 'SHOW_SPINNER', value: true })
        } else if (authState !== '') {
            dispatch({ type: 'SHOW_SPINNER', value: false })
        }
    }, [authState, dispatch])
    /* --------------------------------------------- */
    /**
     * It: Sends login analytics log.
     * It: Updates authentication state.
     * It: Redirects to home page.
     */
    useEffect(() => {
        if (authState === 'LOGIN_SUCCESS') {
            dispatch({ type: 'IS_APP_LOADED', value: false })
            dispatch({ type: 'AUTHENTICATED' })
            if(getTVLoginRedirect()){
                history.push(routeMap.tvLogin)
                setTVLoginRedirect(false)
            }else if(previousURL){
                history.push(previousURL)
                dispatch({ type: 'SET_PREVIOUS_URL', value: null})
            }else{
                history.push('/')
            }
            // console.log('Authenticated.')
        }
    }, [authState, dispatch, sendAnalytics, history, previousURL])
    /* --------------------------------------------- */
    /**
     * It: Simply logs authentication failure.
     */
    useEffect(() => {
        if (authState === 'LOGIN_FAILURE') {
            // dispatch({ type: 'UNAUTHENTICATED' })
            // console.log('Authentication Failure.')
        }
    }, [authState, dispatch])
    /* --------------------------------------------- */
    /**
     * It: Filters google authConfig and sets them in a state.
     */
    useEffect(() => {
        const authConfig = userAuthConfig.filter((config) => config.identityProvider === 'google')
        if (authConfig.length > 0) {
            setAuthConfig(authConfig[0])
        }
    }, [userAuthConfig])
    /* --------------------------------------------- */
    return (
        <>
            <div className='social-login-button' onClick={handleSignupGoogleClick}>
                <img src={googleLogo} alt="" />
                <p>
                    {method === 'signin' && appMessages.label_log_in_sign_in_with_google}
                    {method === 'signup' && appMessages.label_sign_up_sign_up_with_google}
                </p>
                <img src={Right_Arrow} alt="" className="right-arrow" />
            </div>
        </>
    )
}
/* --------------------------------------------- */
export default GoogleLogin