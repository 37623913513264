import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const SubtitleIconFilled = ({ color, ...rest }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
<path fill-rule="evenodd" clip-rule="evenodd" d="M26.25 2.8125H3.75C1.68187 2.8125 0 4.49437 0 6.5625V23.4375C0 25.5056 1.68187 27.1875 3.75 27.1875H26.25C28.3181 27.1875 30 25.5056 30 23.4375V6.5625C30 4.49437 28.3181 2.8125 26.25 2.8125ZM23.4375 14.0625H13.125C12.6075 14.0625 12.1875 14.4816 12.1875 15C12.1875 15.5184 12.6075 15.9375 13.125 15.9375H23.4375C23.955 15.9375 24.375 15.5184 24.375 15C24.375 14.4816 23.955 14.0625 23.4375 14.0625ZM9.375 15.9375H6.5625C6.045 15.9375 5.625 15.5184 5.625 15C5.625 14.4816 6.045 14.0625 6.5625 14.0625H9.375C9.8925 14.0625 10.3125 14.4816 10.3125 15C10.3125 15.5184 9.8925 15.9375 9.375 15.9375ZM16.875 19.6875H6.5625C6.045 19.6875 5.625 20.1066 5.625 20.625C5.625 21.1434 6.045 21.5625 6.5625 21.5625H16.875C17.3925 21.5625 17.8125 21.1434 17.8125 20.625C17.8125 20.1066 17.3925 19.6875 16.875 19.6875ZM20.625 19.6875H23.4375C23.955 19.6875 24.375 20.1066 24.375 20.625C24.375 21.1434 23.955 21.5625 23.4375 21.5625H20.625C20.1075 21.5625 19.6875 21.1434 19.6875 20.625C19.6875 20.1066 20.1075 19.6875 20.625 19.6875Z" fill={color}/>
</svg>
)
/* --------------------------------------------- */
SubtitleIconFilled.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
SubtitleIconFilled.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default SubtitleIconFilled
/* --------------------------------------------- */
