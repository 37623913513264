import React, { useEffect, useState, useContext, lazy, Suspense, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, connect } from 'react-redux'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
import { getAppLanguageWithoutRegion, setAppLanguage } from '../../utils/localStorageService'
import { mergeThemes, getThemeData } from '../../utils/contentfulThemeMerger'
import { isNoHeaderPage } from '../../utils/contentfulHandler'
import utils from '../../utils/utils'
/* --------------------------------------------- */
import { routeMap } from '../../route'
/* --------------------------------------------- */
import SelectBox from '.././SelectBox'
import Popup from '.././Popup'
import StaticPagePerformanceAnalyser from '../Helper/StaticPagePerformanceAnalyser'
/* --------------------------------------------- */
import fb from '../../imgs/fb.png'
import insta from '../../imgs/insta.png'
import twitter from '../../imgs/twitter.png'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
import { FooterWrapper } from './style'
/* --------------------------------------------- */
const Faq = lazy(() => import('../pages/Faq'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const TermsOfUse = lazy(() => import('../pages/TermsOfUse'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
/* --------------------------------------------- */
// Footer component which handles all footer related events
const Footer = ({
  language, menuItems, copyrightTitle,
  appMessages, allowedLocales, selectedLocale, footerMenuConfig, specialList, appStoreConfig
}) => {
  const history = useHistory()
  const location = useLocation()

  /* --------------------------------------------- */
  const [languageSelectBox, updateLanguageSelectBox] = useState(language)
  const [copyrightText, updateCopyrightText] = useState(copyrightTitle)
  const [titleToShowInPopup, updateTitleToShowInPopup] = useState()
  const [isShowSelectBox, toggleIsShowSelectBox] = useState(false)
  const [footerLinks, updateFooterLinks] = useState(menuItems)
  const [selectboxTheme, updateSelectboxTheme] = useState({})
  const [showPopup, togglePopup] = useState(false)
  const [theme, updateTheme] = useState({})
  const [popupToShow, setPopupToShow] = useState('')
  const [isShowHeader, setShowHeader] = useState(false)
  /* --------------------------------------------- */
  const bIsTvLoginPage = useRef(false)
  /* --------------------------------------------- */
  const { appTheme } = useSelector(store => store.themeConfigReducers)
  const { supportInformation } = useSelector(store => store.featuresReducers)
  /* --------------------------------------------- */
  const { appLanguage, uiDirection, dispatch: globalDispatch } = useContext(GlobalContext)
  /* --------------------------------------------- */
  const {
    faqData = [], faqLink = '',
    tosData = {}, tosLink = '',
    privacyPolicyData = {}, privacyPolicyLink = ''
  } = supportInformation
  /* --------------------------------------------- */
  const appStoreBadge_fr = "https://cdn.create.diagnal.com/videotron/images/apple-app-store-fr-v2.png"
  const playStoreBadge_fr = "https://cdn.create.diagnal.com/videotron/images/android-google-play-fr-v2.png"
  const appStoreBadge_en = "https://static.csport.tv/images/appstore_footer.png"
  const playStoreBadge_en = "https://static.csport.tv/images/googleplay_footer.png"
  let appStoreBadge = ''
  let playStoreBadge = ''
  const lang = getAppLanguageWithoutRegion()
  if (lang === 'fr') {
    appStoreBadge = appStoreBadge_fr
    playStoreBadge = playStoreBadge_fr
  }
  else if (lang === 'en') {
    appStoreBadge = appStoreBadge_en
    playStoreBadge = playStoreBadge_en
  }
  else {
    appStoreBadge = appStoreBadge_en
    playStoreBadge = playStoreBadge_en
  }
  /* --------------------------------------------- */
  useEffect(() => {
      bIsTvLoginPage.current=utils.startsWith(decodeURIComponent(location?.pathname).toLowerCase(), routeMap.tvLogin.toLowerCase())
    },
    [location]
  )
  /* --------------------------------------------- */
  /**
   * It: Sets a state to show/hide footer
   */
  useEffect(() => {
    const unlisten = history.listen((location) => {
      const isNoHeader = isNoHeaderPage(decodeURIComponent(location?.pathname))
      setShowHeader(!isNoHeader)
    })

    const isNoHeader = isNoHeaderPage()
    setShowHeader(!isNoHeader)

    return () => {
      unlisten()
    }
  }, [history])
  /* --------------------------------------------- */
  /**
   * It: Updates theme
   */
  useEffect(() => {
    updateTheme(appTheme)
  }, [appTheme])
  /* --------------------------------------------- */
  /**
   * It: Updates select box theme.
   * It: Stores component links.
   * It: Updates copy right text.
   */
  useEffect(() => {
    specialList.map(({ title, id, theme: selectboxTheme }) => {
      if (id === 'selector_language') {
        updateLanguageSelectBox(prevState => ({
          ...prevState, title
        }))
        let selectboxThemeData = getThemeData(selectboxTheme[0], {})
        selectboxThemeData = mergeThemes(theme, selectboxThemeData)
        updateSelectboxTheme(selectboxThemeData)
        toggleIsShowSelectBox(true)
      }
      return null
    })
    const links = []
    footerMenuConfig.map(({ title, identifierExt }) => {
      let component = null
      switch (identifierExt) {
        case 'contact_page': component = 'con'; break
        case 'faq_page': component = 'faq'; break
        case 'tos_page': component = 'tos'; break
        case 'pp_page': component = 'pp'; break
        default: break
      }
      links.push({ title, component })
      return null
    })
    updateFooterLinks(links)
    updateCopyrightText(appMessages.footer_copyright)
  }, [appMessages, footerMenuConfig, specialList, theme])

  /**
   * It: Selects language in select box.
   */
  useEffect(() => {
    updateLanguageSelectBox(prevState => ({
      ...prevState,
      items: allowedLocales.map((locale) => {
        return {
          key: locale.code,
          value: locale.name
        }
      })
    }))
  }, [allowedLocales])

  /**
   * When: Should be called on popup close.
   * It: Closes popup.
   */
  const closeHandler = () => {
    togglePopup(false)
  }

  /**
   * It: Selects language select box.
   */
  useEffect(() => {
    updateLanguageSelectBox(prevState => ({
      ...prevState,
      defaultOption: appLanguage
    }))
  }, [appLanguage])

  /**
   * When: Should be called select box change.
   * It: Sets language globally.
   */
  const onChangeHandler = (data) => {
    globalDispatch({ type: 'SET_APP_LANGUAGE', value: data.key })
    if(utils.checkRTLLanguage(data.key)){
      document.getElementsByTagName('body')[0].setAttribute("dir", "rtl")
      globalDispatch({ type: 'SET_UI_DIRECTION', value: 'rtl'})
    }else {
      globalDispatch({ type: 'SET_UI_DIRECTION', value: 'ltr'})
      document.getElementsByTagName('body')[0].setAttribute("dir", "ltr")
    }
    setAppLanguage(data.key)
  }

  /**
   * When: Should be called on footer link click.
   * It: Shows popup.
   * It: Updates popup title.
   */
  const footerLinkHandler = ({ title, component }) => {
    setPopupToShow(component)
    updateTitleToShowInPopup(title)
    togglePopup(true)
  }

  /**
   * When: Should be called to create footer links.
   * It: Creates footer link.
   */
  const footerLinkMap = (param, i) => {
    const { title, component } = param
    if (component === 'faq' && faqData.length === 0) {
      if (faqLink) {
        return (
          <span key={i} onClick={() => window.open(faqLink, '_blank')}>{title}</span>
        )
      } else {
        return null
      }
    } else if (component === 'tos' && Object.keys(tosData).length === 0) {
      if (tosLink) {
        return (
          <span key={i} onClick={() => window.open(tosLink, '_blank')}>{title}</span>
        )
      } else {
        return null
      }
    } else if (component === 'pp' && Object.keys(privacyPolicyData).length === 0) {
      if (privacyPolicyLink) {
        return (
          <span key={i} onClick={() => window.open(privacyPolicyLink, '_blank')}>{title}</span>
        )
      } else {
        return null
      }
    } else {
      return (
        <span key={i} onClick={() => footerLinkHandler({ title, component })}>{title}</span>
      )
    }
  }
  /* -------------------------------------------- */
  if (!isShowHeader) return null
  /* -------------------------------------------- */
  if(bIsTvLoginPage?.current){
    return null
  }

  return (
    <>
      <FooterWrapper {...{ theme }} className='footer'>
        <div className='row flexWrapCont main-container'>
          <div className='column' style={{ marginBottom: 20 }}>
            <div id='footerLeftArea' className='footer-menu flexWrapCont footer-left'>
              {isShowSelectBox &&
                <div className='selectbox-footer'>
                  <SelectBox {...{ ...languageSelectBox, theme: selectboxTheme, onChange: (data) => onChangeHandler(data) }} />
                </div>}
              <div className='column'>
                <div
                  id='pageLinks' className='row flexWrapCont'
                  style={{ padding: 0 }}
                >
                  <div className='footer-link-col'>
                    {footerLinks.slice(0, 3).map(footerLinkMap)}
                  </div>
                  <div className='footer-link-col'>
                    {footerLinks.slice(3).map(footerLinkMap)}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id='appStoreIconsAndCopyright' className='column' dir={uiDirection}>
            <div className='footer__icons' dir={uiDirection}>
              <div className='footer-menu social-icons'>
                {appStoreConfig.instagramUrl && (
                  <div className='column'>
                    <a href={appStoreConfig && `${appStoreConfig.instagramUrl}`} target='_blank' rel='noopener noreferrer'>
                      <img src={insta} alt='insta' />
                    </a>
                  </div>
                )}
                {appStoreConfig.facebookUrl && (
                  <div className='column'>
                    <a href={appStoreConfig && `${appStoreConfig.facebookUrl}`} target='_blank' rel='noopener noreferrer'>
                      <img src={fb} alt='fb' />
                    </a>
                  </div>
                )}
                {appStoreConfig.twitterUrl && (
                  <div className='column'>
                    <a href={appStoreConfig && `${appStoreConfig.twitterUrl}`} target='_blank' rel='noopener noreferrer'>
                      <img src={twitter} alt='twitter' />
                    </a>
                  </div>
                )}
              </div>
              <div className='footer-menu appstoreIcons'>
                <div className='column'>
                  <a href={appStoreConfig && `${appStoreConfig.iosAppStoreUrl}`} className={`${!appStoreConfig?.iosAppStoreUrl ? 'disable-click': ""}`} target="_blank" rel='noopener noreferrer'>
                    <img src={appStoreBadge} alt='appstore-badge' />
                  </a>
                </div>
                <div className='column'>
                  <a href={appStoreConfig && `${appStoreConfig.androidAppStoreUrl}`} className={`${!appStoreConfig?.androidAppStoreUrl ? 'disable-click': ""}`} target="_blank" rel='noopener noreferrer'>
                    <img src={playStoreBadge} alt='google-play-badge' />
                  </a>
                </div>
              </div>
            </div>
            <div className='copyright'>
              {copyrightText}
            </div>
          </div>
        </div>
      </FooterWrapper>
      {showPopup && <div className='footer-popup-open'>
        <Popup title={titleToShowInPopup} closeHandler={closeHandler} >
          <Suspense fallback={<></>}>
            {popupToShow === 'faq' && <StaticPagePerformanceAnalyser><Faq /></StaticPagePerformanceAnalyser>}
            {popupToShow === 'tos' && <StaticPagePerformanceAnalyser><TermsOfUse /></StaticPagePerformanceAnalyser>}
            {popupToShow === 'pp' && <StaticPagePerformanceAnalyser><PrivacyPolicy /></StaticPagePerformanceAnalyser>}
            {popupToShow === 'con' && <StaticPagePerformanceAnalyser><ContactUs /></StaticPagePerformanceAnalyser>}
          </Suspense>
        </Popup>
      </div>}
    </>
  )
}



/* --------------------------------------------- */
Footer.propTypes = {
  /** List of languages to show in footer selectbox. */
  language: PropTypes.object,
  /** Footer links. */
  menuItems: PropTypes.array,
  /** Footer copyright title. */
  copyrightTitle: PropTypes.string,
  /** appConfigApi function to update selected language in redux store. */
  appConfigApi: PropTypes.func,
  /** Footer theme from contentful stored in redux store. */
  theme: PropTypes.object,
  /** App messages from contentful stored in redux store. */
  appMessages: PropTypes.object,
  /** App messages from contentful stored in redux store. */
  allowedLocales: PropTypes.array,
  /** Selected Language stored in redux store. */
  selectedLocale: PropTypes.string,
  /** Footer links. */
  footerMenuConfig: PropTypes.array,
  /** Footer components from contentful stored in redux store. */
  specialList: PropTypes.array,
  /** Footer's play store & apple store links. */
  appStoreConfig: PropTypes.object
}
/* --------------------------------------------- */
Footer.defaultProps = {
  language: {},
  menuItems: [],
  copyrightTitle: '',
  appConfigApi: () => { },
  theme: {},
  appMessages: {},
  allowedLocales: [],
  selectedLocale: 'en',
  footerMenuConfig: [],
  specialList: [],
  appStoreConfig: {}
}
/* --------------------------------------------- */
const mapStateToProps = state => {
  return {
    appMessages: state.appConfigReducers.appMessages,
    allowedLocales: state.appConfigReducers.allowedLocales,
    selectedLocale: state.appConfigReducers.selectedLocale,
    footerMenuConfig: state.navigation.footerMenuConfig,
    specialList: state.themeConfigReducers.specialList,
    appStoreConfig: state.appConfigReducers.appStoreConfig
  }
}
/* --------------------------------------------- */
export default connect(mapStateToProps, null)(Footer)
  /* --------------------------------------------- */
