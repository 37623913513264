import React, { useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
/* ------------------------------------------------------------------------------------------------ */
import { ErrorContext } from '../../context/error.context'
import { GlobalContext } from '../../context/global.context'
/* ------------------------------------------------------------------------------------------------ */
import ErrorPage from '../errors/ErrorPage'
import ErrorPopup from '../errors/ErrorPopup'
import ErrorFullscreen from '../errors/ErrorFullscreen'
import ErrorToast from '../errors/ErrorToast'
import ErrorSnackbar from '../errors/ErrorSnackbar'
/* ---------------------------------------------------------------------------------------------------- */
// Wrapper component for error components.
const ErrorContainer = () => {
    const {
        errorPage,
        errorPopup,
        errorFullscreen,
        errorToast,
        errorSnackbar,
        dispatch: errorDispatch
    } = useContext(ErrorContext)
    /* ------------------------------------------------------------------------------------------------ */
    const { isSessionError } = useContext(GlobalContext)
    /* ------------------------------------------------------------------------------------------------ */
    const isSessionErrorRef = useRef(isSessionError)
    /* ------------------------------------------------------------------------------------------------ */
    const history = useHistory()
    /* ------------------------------------------------------------------------------------------------ */
    useEffect(() => { isSessionErrorRef.current = isSessionError }, [isSessionError])
    /* ------------------------------------------------------------------------------------------------ */
    /** It: Clears all errors on route change except session error. */
    useEffect(() => {
        const unlisten = history && history.listen(() => {
            if (!isSessionErrorRef.current) {
                errorDispatch({ type: 'ERROR_PAGE', value: {} })
                errorDispatch({ type: 'ERROR_POPUP', value: {} })
                errorDispatch({ type: 'ERROR_FULLSCREEN', value: {} })
                errorDispatch({ type: 'ERROR_TOAST', value: {} })
                errorDispatch({ type: 'ERROR_SNACKBAR', value: {} })
            }
        })
        return () => {
            unlisten && unlisten()
        }
    }, [history, errorDispatch])
    /* ------------------------------------------------------------------------------------------------ */
    return (
        <>
            {isShowError(errorPage) && <ErrorPage />}
            {isShowError(errorPopup) && <ErrorPopup />}
            {isShowError(errorFullscreen) && <ErrorFullscreen />}
            {isShowError(errorToast) && <ErrorToast />}
            {isShowError(errorSnackbar) && <ErrorSnackbar />}
        </>
    )
}
/* ------------------------------------------------------------------------------------------------ */
export const isShowError = (errorData) => {
    return Object.keys(errorData).length > 0 && (!errorData.isPlayerError || errorData.isPortraitPlayer)
}
/* ------------------------------------------------------------------------------------------------ */
export default ErrorContainer