import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const PauseIcon = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path 
    fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M0.625 29.0625C0.625 29.5809 1.045 30 1.5625 30C2.08 30 2.5 29.5809 2.5 29.0625V0.9375C2.5 0.419062 2.08 0 1.5625 0C1.045 0 0.625 0.419062 0.625 0.9375V29.0625ZM16.6967 29.0625C16.6967 29.5809 17.1167 30 17.6342 30C18.1517 30 18.5717 29.5809 18.5717 29.0625V0.9375C18.5717 0.419062 18.1517 0 17.6342 0C17.1167 0 16.6967 0.419062 16.6967 0.9375V29.0625Z"
    />
  </svg>
)
/* --------------------------------------------- */
PauseIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
PauseIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default PauseIcon
/* --------------------------------------------- */
