import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const DropdownIcon = ({ color, ...rest }) => (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <path fillRule="evenodd" clipRule="evenodd" d="M0.9375 8C0.9375 11.6187 3.88125 14.5625 7.5 14.5625C11.1187 14.5625 14.0625 11.6187 14.0625 8C14.0625 4.38125 11.1187 1.4375 7.5 1.4375C3.88125 1.4375 0.9375 4.38125 0.9375 8ZM0 8C0 3.86469 3.36469 0.5 7.5 0.5C11.6353 0.5 15 3.86469 15 8C15 12.1353 11.6353 15.5 7.5 15.5C3.36469 15.5 0 12.1353 0 8ZM7.5 9.21219L9.98109 6.73109C10.1644 6.54781 10.4606 6.54781 10.6439 6.73109C10.8272 6.91437 10.8272 7.21062 10.6439 7.39391L7.83141 10.2064C7.74 10.2978 7.62 10.3438 7.5 10.3438C7.38 10.3438 7.26 10.2978 7.16859 10.2064L4.35609 7.39391C4.17281 7.21062 4.17281 6.91437 4.35609 6.73109C4.53937 6.54781 4.83562 6.54781 5.01891 6.73109L7.5 9.21219Z" fill="white"/>
    </svg>

)
/* --------------------------------------------- */
DropdownIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
DropdownIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default DropdownIcon
/* --------------------------------------------- */
