import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { AppWrapper } from './style'
import utils from '../../utils/utils'
import { routeMap } from '../../route'

// AppBG component used to handle dynmamic background color for the app.
const AppBG = (props) => {
    const [isNoHeaderPage, setNoHeaderPage] = useState(checkNoHeaderPage())
    const [isTvGuidePage, setIsTVGuidePage] = useState(checkTVGuidePage())
    const { appTheme } = useSelector(store => store.themeConfigReducers)
    const history = useHistory()
    const appWrapperRef = useRef(null)

    // Returns app-wrapper height
    const getParentHeight = useCallback(() => {
        const containerEl = appWrapperRef.current
        if (!containerEl) return 0

        return containerEl.offsetHeight
    }, [])

    // Returns parent element of app-wrapper
    const getGrandParent = useCallback(() => {
        const containerEl = appWrapperRef.current
        if (!containerEl) return null

        return containerEl.parentElement
    }, [])

    // Sets boolean indicating current page has header or not.
    useEffect(() => {
        const unlisten = history.listen((location) => {
            // console.log(location)
            setIsTVGuidePage(decodeURIComponent(checkTVGuidePage(location?.pathname)))
            setNoHeaderPage(
                checkNoHeaderPage(decodeURIComponent(location?.pathname))
            )
        })
        return () => {
            unlisten()
        }
    }, [history])

    return (
        <AppWrapper ref={appWrapperRef} {...{ theme: appTheme, isNoHeaderPage, isTvGuidePage }} className='app-wrapper'>
            {typeof props.children === 'function' ? props.children({ getParentHeight, getGrandParent }) : props.children}
        </AppWrapper>
    )
}

// Returns boolean. Checks given path has header.
const checkNoHeaderPage = (pathname) => {
    pathname = decodeURIComponent(pathname || window.location?.pathname || '')
    const noHeaderPaths = [routeMap.player]
    return noHeaderPaths.filter((path) => {
        return utils.startsWith(decodeURIComponent(pathname), path)
    }).length > 0
}

const checkTVGuidePage = (pathname)=>{
    pathname = decodeURIComponent(pathname || window.location?.pathname || '')
    return pathname === routeMap.tvGuide

}

export default AppBG