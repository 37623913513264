import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const FastRewindIconFilled = ({ color, ...rest }) => (
    <svg viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} >
        <path 
        fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M11.5282 0.288C11.8092 0.09 12.1193 0 12.4213 0C13.2303 0 13.9832 0.641 13.9832 1.56V16.904C13.9832 17.824 13.2303 18.465 12.4213 18.465C12.1193 18.465 11.8092 18.375 11.5282 18.177L0.65925 10.505C-0.21975 9.884 -0.21975 8.58 0.65925 7.96L11.5282 0.288ZM27.5448 0.288C27.8258 0.09 28.1358 0 28.4379 0C29.2468 0 29.9998 0.641 29.9998 1.56V16.904C29.9998 17.824 29.2468 18.465 28.4379 18.465C28.1358 18.465 27.8258 18.375 27.5448 18.177L16.6758 10.505C15.7968 9.884 15.7968 8.58 16.6758 7.96L27.5448 0.288Z" 
        />
    </svg>  
)
/* --------------------------------------------- */
FastRewindIconFilled.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
FastRewindIconFilled.defaultProps = {
  color: 'transparent',
  style: {
    width: 25, 
    height: 25
  }
}
/* --------------------------------------------- */
export default FastRewindIconFilled
/* --------------------------------------------- */
