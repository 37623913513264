import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getTheme from './getTheme'
import getMenuItem from './getMenuItem'

// Helper function to form contentful tree
const getNavigation = (contentful, ignorePage = false) => {
  const filterLocal = getFilter(contentful)
  const themeLocal = getTheme(contentful)
  const menuItemLocal = getMenuItem(contentful, ignorePage)
  const navigationLocal = contentful.navigation
  return navigationLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      menuItems = [], filter = {}, overflowItem = {}, theme = [], overflowTheme = []
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      menuItems: getNew(menuItems, menuItemLocal),
      filter: getNewObj(filter, [filterLocal]),
      overflowItem: getNewObj(overflowItem, [menuItemLocal]),
      theme: getNew(theme, themeLocal),
      overflowTheme: getNew(overflowTheme, themeLocal)
    }
  })
}

export default getNavigation
