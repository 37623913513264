// Helper function to form contentful tree
const getColor = (contentful) => {
  const colorLocal = contentful.color || []
  return colorLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    return { sysId: id, contentTypeId, ...fields }
  })
}

export default getColor
