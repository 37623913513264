import React from 'react'
import SelectBox from "./SelectBox";

// SelectBox wrapper component.
const Wrapper = (props) => {
    return (
        <>
            <SelectBox {...props} />
        </>
    )
}
export default Wrapper