import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
/* ---------------------------------------------------------------------------------------------------- */
/** Custom hook consists of custom errors that are not available in contentful */
/* ---------------------------------------------------------------------------------------------------- */
const useCustomErrorConfigs = () => {
  const { appMessages } = useSelector(store => store.appConfigReducers)
  const { appTheme } = useSelector(store => store.themeConfigReducers)
  const { catalogConfig = {} } = useSelector(store => store.catalog)
  const { userManagementThemes = {} } = useSelector(store => store.userManagement)
  /* ------------------------------------------------------------------------------------------------ */
  const appMessagesRef = useRef(appMessages)
  const themeRef = useRef(catalogConfig.theme)
  const appThemeRef = useRef(appTheme)
  const loginThemeRef = useRef(userManagementThemes)
  /* ------------------------------------------------------------------------------------------------ */
  useEffect(() => {
    appMessagesRef.current = appMessages
  }, [appMessages])
  /* ------------------------------------------------------------------------------------------------ */
  useEffect(() => {
    themeRef.current = catalogConfig.theme
  }, [catalogConfig.theme])
  /* ------------------------------------------------------------------------------------------------ */
  useEffect(() => {
    appThemeRef.current = appTheme
  }, [appTheme])
  /* ------------------------------------------------------------------------------------------------ */
  useEffect(() => {
    loginThemeRef.current = userManagementThemes.loginTheme
  }, [userManagementThemes])
  /* ------------------------------------------------------------------------------------------------ */
  const getErrors = useCallback(() => {
    const appMessages = appMessagesRef.current
    const theme = themeRef.current
    const loginTheme = loginThemeRef.current
    const errorConfigs = [{
      type: 'AddFavouriteSuccess',
      title: appMessages.label_details_add_favourite,
      uiType: 'toast',
      primaryActionType: 'custom',
      primaryActionLabel: appMessages.label_details_go,
      theme  
    }, {
      type: 'RemoveFavouriteSuccess',
      title: appMessages.label_error_removed_from_favourites,
      uiType: 'toast',
      primaryActionType: 'custom',
      primaryActionLabel: appMessages.label_details_go,
      theme  
    }, {
      type: 'CopyToClipboardSuccess',
      title: appMessages.label_details_url_copied,
      uiType: 'toast',
      theme
    },
    {
      type: 'LoginFailed',
      title: appMessages.error_something_wrong ? appMessages?.error_something_wrong : 'Something went wrong',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme,
      loginTheme
    },
    {
      type: 'LoginFailedWrongCredentials',
      title: appMessages.label_incorrect_username_or_password ? appMessages?.label_incorrect_username_or_password : 'Incorrect username or password',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme,
      loginTheme
    },
    {
      type: 'LoginFailedEmailNotExists',
      title: appMessages.label_provided_email_not_exists ? appMessages?.label_provided_email_not_exists : 'Provided email does not exist',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme,
      loginTheme
    },
    {
      type: 'failedToGetUserDetails',
      title: appMessages.label_failed_to_get_details ? appMessages?.label_failed_to_get_details : 'Error while fetching user details',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme
    },
    {
      type: 'changePasswordFailedCheckPassword',
      title: appMessages.label_incorrect_password ? appMessages?.label_incorrect_password : 'Incorrect password',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme
    },
    {
      type: 'changePasswordFailed',
      title: appMessages.label_error_on_password_update ? appMessages?.label_error_on_password_update : 'Error while updating password',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme
    },
    {
      type: 'SignupFailedUserExist',
      title: appMessages.label_signup_failed_user_exist ? appMessages?.label_signup_failed_user_exist : 'An account with the given email already exists',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme,
      loginTheme
    },
    {
      type: 'ForgetPasswordFailed',
      title: appMessages.error_something_wrong ? appMessages?.error_something_wrong : 'Something went wrong',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme,
      loginTheme
    },
    {
      type: 'ForgetPasswordEmailNotExist',
      title: appMessages.UserDoesnotExist ? appMessages?.UserDoesnotExist : 'An account with the given email does not exist',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme,
      loginTheme
    },
    {
      type: 'ForgetPasswordFieldsRequired',
      title: appMessages.forgot_password_fields_required ? appMessages?.forgot_password_fields_required : 'Password fields do not match',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme,
      loginTheme
    },
    {
      type: 'ForgetPasswordFieldsNotMatching',
      title: appMessages.forgot_password_fields_not_matching ? appMessages?.forgot_password_fields_not_matching : 'Both passwords fields need to be the same',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme,
      loginTheme
    },
    {
      type: 'SignupFailed',
      title: appMessages.error_something_wrong ? appMessages?.error_something_wrong : 'Something went wrong',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme,
      loginTheme
    },
    {
      type: 'profileUpdateFailed',
      title: appMessages.label_error_on_profile_update ? appMessages?.label_error_on_profile_update : 'Error while updating user details',
      uiType: 'snackbar',
      primaryActionType: 'cancel',
      primaryActionLabel: appMessages.button_close ? appMessages?.button_close : 'Close',
      theme
    },
    {
      type: 'incorrectPIN',
      title: appMessages.label_enter_correct_pin ? appMessages?.label_enter_correct_pin : 'Please Enter the correct PIN',
      uiType: 'toast',
      theme
    },
    {
      type: 'pinNotMatching',
      title: appMessages.label_enter_same_pin_as_earlier ? appMessages?.label_enter_same_pin_as_earlier : 'Please Enter the same PIN you entered earlier',
      uiType: 'toast',
      theme
    },
    {
      type: 'newProfileCreated',
      title: appMessages.label_new_profile_created ? appMessages?.label_new_profile_created : "'New profile created",
      uiType: 'toast',
      theme
    },
    {
      type: 'profileDeleted',
      title: appMessages.label_profile_deleted ? appMessages?.label_profile_deleted : 'Profile deleted',
      uiType: 'toast',
      theme
    },
    {
      type: 'errorDeletingProfile',
      title: appMessages.label_error_on_profile_delete ? appMessages?.label_error_on_profile_delete : 'Error while deleting profile',
      uiType: 'toast',
      theme
    },
    {
      type: 'profileUpdated',
      title: appMessages.label_profile_updated ? appMessages?.label_profile_updated : 'Profile updated',
      uiType: 'toast',
      theme
    },
    {
      type: 'errorUpdatingProfile',
      title: appMessages.label_error_on_profile_updating ? appMessages?.label_error_on_profile_updating : 'Error in updating profile',
      uiType: 'toast',
      theme
    },
    {
      type: 'errorCreatingProfile',
      title: appMessages.label_error_on_profile_creating ? appMessages?.label_error_on_profile_creating : 'Error in Creating Profile. Please Retry',
      uiType: 'toast',
      theme
    },
    {
      type: 'tosNotAccepted',
      title: appMessages.label_accept_terms_of_use_privacy_policy ? appMessages?.label_accept_terms_of_use_privacy_policy : 'Please accept Terms of Use & Privacy Policy',
      uiType: 'toast',
      theme
    },
    {
      type: 'ServerError',
      title: 'Error',
      description: 'A server error has occurred. Please refresh the page again after sometime.', //updated message as same as contentful
      uiType: 'fullscreen',
      code: 'A-6001',
      theme: appThemeRef.current,
    },
    {
      type: 'changePasswordSuccess',
      title: appMessages.label_password_updated_description,
      uiType: 'toast',
      theme
    },
    {
      type: 'detailsNotForKids',
      title: appMessages.label_details_not_for_kids_toast ? appMessages?.label_details_not_for_kids_toast : 'Not available for Kids',
      uiType: 'toast',
      theme
    },
    {
      type: 'detailsNotAvailable',
      title: appMessages.label_details_not_available ? appMessages?.label_details_not_available : 'Not available for this Profile',
      uiType: 'toast',
      theme
    },
    {
      type: 'promocodeApplied',
      title: appMessages.label_promo_code_applied ? appMessages?.label_promo_code_applied : 'Promo Code Applied',
      uiType: 'toast',
      theme,
      successType: true, // Since its a success message
    }
    ]
    /* -------------------------------------------------------------------------------------------- */
    return errorConfigs
  }, [])
  /* ------------------------------------------------------------------------------------------------ */
  return { getErrors }
}
/* ---------------------------------------------------------------------------------------------------- */
export default useCustomErrorConfigs
/* ---------------------------------------------------------------------------------------------------- */
