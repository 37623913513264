import { USER_AUTH_INFO, APP_LANGUAGE, SELECTED_PLAYER_QUALITY, AUTO_PLAY_NEXT_EPISODE, SELECTED_SUBTITLE_TRACK, SELECTED_PROFILE, GRAPHICS, REDIRECT_TO_TVLOGIN, IS_RENDERED_FROM_SIGNUP, IS_APP_FIRST_OPEN, USER_TOKEN, CONTENTFULL_TREE, IS_METADATA_TRAILER_MUTED, SIGN_UP_USER_EMAIL, IS_RENDERED_FROM_RAIL, APP_BASE_URL, USER_ID  } from './localStorageKeys'
import ls from 'localstorage-slim';

/** Utility functions to read & write in local storage */

const localStorage = window.store.localStorage;
var selectedLang = '';

export const getUserAuthInfo = () => {
  try {
    const data = JSON.parse(localStorage.getItem(USER_AUTH_INFO))
    const lscheck = data.nextRefreshTime; //To remove 
    if(lscheck)
    return data || {};
    else
    return ls.get(USER_AUTH_INFO, { decrypt: true }) || {};
  } catch (err){
    return ls.get(USER_AUTH_INFO, { decrypt: true }) || {};
  }
}

export const setUserAuthInfo = (authInfo = null, options = {}) => {
  const { merge = false } = options
  if (merge) {
    const oldInfo = getUserAuthInfo()
    authInfo = { ...oldInfo, ...authInfo }
  }
  try {
    ls.set(USER_AUTH_INFO, authInfo || {}, { encrypt: true });
  } catch(err) {
    authInfo = JSON.stringify(authInfo || {})
    localStorage.setItem(USER_AUTH_INFO, authInfo) //To remove 
}
  return true
}

export const getAppLanguage = () => {
  return (localStorage && localStorage.getItem && localStorage.getItem(APP_LANGUAGE)) || selectedLang || ''
}

export const splitLanguage = (appLanguageLocal) => {
  return appLanguageLocal.split('-')[0] || ''
}

export const getAppLanguageWithoutRegion = () => {
  const appLanguageLocal = getAppLanguage()
  return splitLanguage(appLanguageLocal)
}

export const setAppLanguage = (appLanguage = '') => {
  localStorage.setItem(APP_LANGUAGE, appLanguage)
  selectedLang = localStorage.getItem(APP_LANGUAGE);
  return true
}

export const getSelectedPlayerQuality = () => {
  return localStorage.getItem(SELECTED_PLAYER_QUALITY) || ''
}

export const setSelectedPlayerQuality = (selectedPlayerQuality = '') => {
  localStorage.setItem(SELECTED_PLAYER_QUALITY, selectedPlayerQuality)
  return true
}

export const removeSelectedPlayerQuality = () => {
  localStorage.removeItem(SELECTED_PLAYER_QUALITY)
  return true
}

export const getAutoPlayNextEpisode = () => {
  return localStorage.getItem(AUTO_PLAY_NEXT_EPISODE) || ''
}

export const setAutoPlayNextEpisode = (autoPlayNextEpisode = '') => {
  localStorage.setItem(AUTO_PLAY_NEXT_EPISODE, autoPlayNextEpisode)
  return true
}

export const removeAutoPlayNextEpisode = () => {
  localStorage.removeItem(AUTO_PLAY_NEXT_EPISODE)
  return true
}

export const getSelectedSubtitleTrack = () => {
  return JSON.parse(localStorage.getItem(SELECTED_SUBTITLE_TRACK)) || { language: 'none' }
}

export const setSelectedSubtitleTrack = (selectedSubtitleTrack = {}) => {
  localStorage.setItem(SELECTED_SUBTITLE_TRACK, JSON.stringify(selectedSubtitleTrack))
  return true
}

export const removeSelectedSubtitleTrack = () => {
  localStorage.removeItem(SELECTED_SUBTITLE_TRACK)
  return true
}

export const setSelectedProfile = (selectedProfile = {}) => {
  localStorage.setItem(SELECTED_PROFILE, JSON.stringify(selectedProfile))
  return true
}
export const getSelectedProfile = () => {
  return JSON.parse(localStorage.getItem(SELECTED_PROFILE))
}

export const removeSelectedProfile = () => {
  localStorage.removeItem(SELECTED_PROFILE)
  return true
}

export const getGraphics = () => {
  return JSON.parse(localStorage.getItem(GRAPHICS))
}

export const setGraphics = (graphics) => {
  localStorage.setItem(GRAPHICS, JSON.stringify(graphics))
  return true
}
export const getTVLoginRedirect = () => {
  return JSON.parse(localStorage.getItem(REDIRECT_TO_TVLOGIN))
}

export const setTVLoginRedirect = (isRdirectToTvLogin) => {
  localStorage.setItem(REDIRECT_TO_TVLOGIN, isRdirectToTvLogin)
  return true
}

export const setIsRenderedFromSignup = (isRendered) => {
  localStorage.setItem(IS_RENDERED_FROM_SIGNUP, isRendered)
  return true
}

export const getIsRenderedFromSignup = () => {
  return JSON.parse(localStorage.getItem(IS_RENDERED_FROM_SIGNUP)) || ''
}

export const removeIsRenderedFromSignup = () => {
  localStorage.removeItem(IS_RENDERED_FROM_SIGNUP)
  return true
}

export const setIsRenderedFromRail = (isRendered) => {
  localStorage.setItem(IS_RENDERED_FROM_RAIL, isRendered)
  return true
}

export const getIsRenderedFromRail = () => {
  return JSON.parse(localStorage.getItem(IS_RENDERED_FROM_RAIL)) || ''
}


// isFirstOpen will set to true when user opens the application for the first time .

export const setIsFirstOpenEventTriggered = (isFirstOpen) => {
  localStorage.setItem(IS_APP_FIRST_OPEN, !!isFirstOpen)
  return true
}

export const getIsFirstOpenEventTriggered = () => {
  return !!JSON.parse(localStorage.getItem(IS_APP_FIRST_OPEN))
}

export const setUserToken = (token) => {
  try {
    ls.set(USER_TOKEN, token, { encrypt: true })
  } catch {
    localStorage.setItem(USER_TOKEN, token)
  }
  return true
}

export const getUserToken = () => {
  try {
    return ls.get(USER_TOKEN, { decrypt: true })
  } catch {
    return localStorage.getItem(USER_TOKEN);
  }
}

export const getContentfullData = () => {
  return JSON.parse(localStorage.getItem(CONTENTFULL_TREE))
}

export const setContentfullData = (data) => {
  localStorage.setItem(CONTENTFULL_TREE, data)
  return true
}
export const getMetaDataPlayerMuteState = () => {
  const isMute = JSON.parse(localStorage.getItem(IS_METADATA_TRAILER_MUTED))
  return  isMute === null? true: isMute
}

export const setMetaDataPlayerMuteState = (bMuted) => {
  localStorage.setItem(IS_METADATA_TRAILER_MUTED, bMuted)
  return true
}
export const removeIsFirstOpenEvent = () => {
  localStorage.removeItem(IS_APP_FIRST_OPEN)
}

 
export const removeGraphics = () => {
  localStorage.removeItem(GRAPHICS)
  return true
}
export const setEmail = (email) => {
  localStorage.setItem(SIGN_UP_USER_EMAIL, JSON.stringify(email))
}

export const setUserId = (userId) => {
  localStorage.setItem(USER_ID, JSON.stringify(userId))
}

export const getUserId = () => {
  // console.log(
  //   "localStorage.getItem(SIGN_UP_USER_EMAIL)", localStorage.getItem(SIGN_UP_USER_EMAIL)
  // );
  return JSON.parse(localStorage.getItem(USER_ID)) || ''
}

export const getEmail = () => {
  // console.log(
  //   "localStorage.getItem(SIGN_UP_USER_EMAIL)", localStorage.getItem(SIGN_UP_USER_EMAIL)
  // );
  return JSON.parse(localStorage.getItem(SIGN_UP_USER_EMAIL)) || ''
}

export const clearLocalStorage = () => {
  var appLang = getAppLanguage();
  window.localStorage.clear();
  setAppLanguage(appLang);
}

export const setAppBaseUrl = (url) => {
  localStorage.setItem(APP_BASE_URL, url)
}

export const getAppBaseUrl = () => {
  return localStorage.getItem("app-base_url")
}

export const removeAppBaseUrl = () => {
  localStorage.removeItem(IS_APP_FIRST_OPEN)
}

export const removeLocalStorageData = () => {
  setUserAuthInfo({})
  removeSelectedPlayerQuality()
  removeAutoPlayNextEpisode()
  removeSelectedSubtitleTrack()
  removeSelectedProfile()
  removeIsRenderedFromSignup()
  removeIsFirstOpenEvent()
  removeAppBaseUrl()
  return true
}
