import React, { createContext, useReducer } from 'react'
/* --------------------------------------------- */
import useAnalytics from '../containers/useAnalytics'
/* --------------------------------------------- */
import { getAppLanguage, getSelectedProfile, setUserToken } from '../utils/localStorageService'
import utils from '../utils/utils'
/* --------------------------------------------- */
/** Global initial state */
const sessionState = utils.isSessionValid()
const isSessionValidB = sessionState === 'VALID_SESSION'
const isRefreshTokenNeeded = sessionState === 'INVALID_SESSION'
const urlParams = new URLSearchParams(window.location.search);
const isPreview = urlParams.get('preview');  // used for contentfull preview feature

const initialState = {
  allRailsLoaded: false,
  isShowSplash: true,
  isInitialAppLoad: true,
  isAppLoaded: false,
  isRefreshTokenEnded: false,
  isGeoBlocked: false,
  isVpnBlocked: false,
  isRefreshTokenNeeded: isRefreshTokenNeeded,
  isRecentlyWatchedCallEnded: false,
  userInfo: { profileType: 'normal' }, // This is done to fetch the menu items based on filter for annonymous users.
  userToken: '',
  appLanguage: getAppLanguage() || 'en-US',
  uiDirection: 'ltr',
  isShared: false,
  castData: {},
  isAutoplay: true,
  isGetAppPopupOpen: false,
  isAuthenticated: null, // autheticated: TRUE / annonymous: FALSE
  isSessionError: false,
  isShowLoginPopup: false,
  loginPopupDetails: {},
  isShowLogoutPopup: false,
  showHeaderBackBtn: false,
  isMobileUser: false,
  isShowInstallBanner: true,
  isCastConnectedStatus: false,
  retryPaymentDetails: {
    isRetry: false,
    details: {}
  },
  pageTitle: '',
  getAppDetails: {
    isShowPopup: false,
    details: {}
  },
  trailerDetails: {
    isTrailer: false,
    trailerUrl: ''
  },
  isShowSpinner: false,
  dispatch: () => { },
  logger: {
    push: () => { }
  },
  searchText: '',
  searchTextCountryCode: '',
  isForceLogout: false,
  kidsPinProp: {},
  showMultiProfileList: false,
  showProfileDetails: { flag: false, profile: {} },
  isThisCREATE: false,
  selectedProfile: isSessionValidB ? getSelectedProfile() || null: null,
  refreshCallState: 'IDLE',
  favouritesCallState: 'IDLE',
  recentlyWatchedRailTimestamp: Date.now(),
  initiateLogin: false,
  hasUserNavigatedFromLoginPage: false, // to show featureintro pop up
  isUserLoginRefresh: false,
  popupDetails: {
    inViewport: false,
    useMargin: false
  },
  previousURL: null,
  isRenderedLandingPage: false,
  previousUrlFromSignUp: null,
  isDirectedToPaymentPage: false,
  isContentfulPreview: Boolean(isPreview),
  metaDataPreview: null,
  metaDataTrailerMuted: true,
  paymentProviders: [],
  vidSmilUrl: '',
  isNavigatedFromRails: false
}

/* --------------------------------------------- */
/** Global context */
export const GlobalContext = createContext(initialState)
/* --------------------------------------------- */
/** Global reducer */
const globalReducer = (state, action) => {
  switch (action.type) {
    case 'SELECTED_PROFILE':
      return { ...state, selectedProfile: action.value }
    case 'SET_IS_THIS_CREATE':
      return { ...state, isThisCREATE: action.value }
    case 'SET_SHOW_PROFILE_DETAILS':
      return { ...state, showProfileDetails: action.value }
    case 'SET_SHOW_MULTI_PROFILE_LIST':
      return { ...state, showMultiProfileList: action.value }
    case 'IS_SHOW_SPLASH':
      return { ...state, isShowSplash: action.value }
    case 'IS_GEO_BLOCKED':
      return { ...state, isGeoBlocked: action.value }
    case 'IS_VPN_BLOCKED':
      return { ...state, isVpnBlocked: action.value }  
    case 'INITIAL_APP_LOAD':
      return { ...state, isInitialAppLoad: action.value }
    case 'ALL_RAILS_LOADED':
      return { ...state, allRailsLoaded: action.value }
    case 'IS_APP_LOADED':
      return { ...state, isAppLoaded: action.value }
    case 'IS_REFRESH_TOKEN_ENDED':
      return { ...state, isRefreshTokenEnded: action.value, isRefreshTokenNeeded: !action.value }
    case 'IS_RECENTLY_WATCHED_ENDED':
      return { ...state, isRecentlyWatchedCallEnded: action.value }
    case 'REFRESH_RECENTLY_WATCHED_RAIL':
      return { ...state, recentlyWatchedRailTimestamp: Date.now() }
    case 'USER_INFO':
      return { ...state, userInfo: action.value }
    case 'PAYMENT_RETRY_DETAILS':
      return { ...state, retryPaymentDetails: action.value }
    case 'USER_TOKEN':
      setUserToken(action.value)
      return { ...state, userToken: action.value }
    case 'UPDATE_USER_INFO':
      return { ...state, userInfo: { ...state.userInfo, ...action.value } }
    case 'IS_SHARED':
      return { ...state, isShared: action.value }
    case 'SHOW_SPINNER':
      return { ...state, isShowSpinner: action.value }
    case 'AUTHENTICATED':
      return { ...state, isAuthenticated: true }
    case 'UNAUTHENTICATED':
      return { ...state, isAuthenticated: false }
    case 'SESSION_ERROR':
      return { ...state, isSessionError: action.value }
    case 'GETAPP_POPUP_DETAILS':
      return { ...state, getAppDetails: action.value }
    case 'TRAILER_DETAILS':
      return { ...state, trailerDetails: action.value }
    case 'MOBILE_USER':
      return { ...state, isMobileUser: true }
    case 'NONMOBILE_USER':
      return { ...state, isMobileUser: false }
    case 'SHOW_LOGIN_POPUP':
      return { ...state, isShowLoginPopup: true }
    case 'HIDE_LOGIN_POPUP':
      return { ...state, isShowLoginPopup: false }
    case 'LOGIN_POPUP_DETAILS':
      return { ...state, loginPopupDetails: action.value }
    case 'SHOW_LOGOUT_POPUP':
      return { ...state, isShowLogoutPopup: true }
    case 'HIDE_LOGOUT_POPUP':
      return { ...state, isShowLogoutPopup: false }
    case 'HIDE_INSTALL_BANNER':
      return { ...state, isShowInstallBanner: false }
    case 'SHOW_HEADER_BACK_BTN':
      return { ...state, showHeaderBackBtn: true }
    case 'HIDE_HEADER_BACK_BTN':
      return { ...state, showHeaderBackBtn: false }
    case 'PAGE_TITLE':
      return { ...state, pageTitle: action.value }
    case 'AUTOPLAY_FALSE':
      return { ...state, isAutoplay: false }
    case 'AUTOPLAY_TRUE':
      return { ...state, isAutoplay: true }
    case 'CAST_CONNECTED':
      return { ...state, isCastConnectedStatus: true }
    case 'CAST_DISCONNECTED':
      return { ...state, isCastConnectedStatus: false }
    case 'CAST_DATA':
      return { ...state, castData: action.data }
    case 'SEARCH_TEXT':
      return { ...state, searchText: action.value }
    case 'SEARCH_TEXT_COUNTRY_CODE':
      return { ...state, searchTextCountryCode: action.value }  
    case 'SET_APP_LANGUAGE':
      return { ...state, appLanguage: action.value }
    case 'SET_UI_DIRECTION':
      return { ...state, uiDirection: action.value }  
    case 'FORCE_LOGOUT':
      return { ...state, isForceLogout: true }
    case 'KIDS_PIN_PROP_SET':
      return { ...state, kidsPinProp: action.kidsPinProp }
    case 'REFRESH_CALL_STATE':
      return { ...state, refreshCallState: action.value }
    case 'FAVOURITES_CALL_STATE':
      return { ...state, favouritesCallState: action.value }
    case 'SHOW_SPLASH':
      return { ...state, isShowSplash: action.value }
    case 'INITIATE_LOGIN':
      return { ...state, initiateLogin: action.value }
    case 'IS_POPUP_IN_VIEWPORT':
      return { ...state, isPopupInViewport: action.value }
    case 'USER_NAVIGATION_FROM_LOGIN':
      return { ...state, hasUserNavigatedFromLoginPage: action.value }
    case 'USER_LOGIN_REFRESH':
      return { ...state, isUserLoginRefresh: action.value }
    case 'POPUP_DETAILS':
      return { ...state, popupDetails: action.value }
    case 'IS_RAIL_ITEMS_LOADING':
      return { ...state, isRailItemsLoading: action.value }
    case 'SET_PREVIOUS_URL':
      return { ...state, previousURL: action.value }
    case 'RENDERED_LANDING':
      return { ...state, isRenderedLandingPage: action.value }
    case 'PREVIOUS_URL_FROM_SIGNUP':
      return { ...state, previousUrlFromSignUp: action.value }
    case 'METADATA_PREVIEW':
      return { ...state, metaDataPreview: action.value }
    case 'IS_DIRECTED_TO_PAYMENT':
      return { ...state, isDirectedToPaymentPage: action.value }
    case 'IS_METADATA_TRAILER_MUTED':
      return {...state, metaDataTrailerMuted:action.value}
    case 'SELECTED_COUNTRY':
      return { ...state, selectedCountry: action.value }
    case 'SELECTED_OPERATOR':
      return { ...state, selectedCableTVOperator: action.value } 
    case 'SELECTED_PARTNER':
      return {...state, selectedPartner: action.value}    
    case 'CONTENTFUL_DATA':
        return { ...state, contentfulData: action.value }
    case 'PAYMENT_PROVIDERS':
      return { ...state, paymentProviders: action.value } 
    case 'SET_SMIL_URL': 
      return { ...state, vidSmilUrl: action.value}
    case 'SET_IS_FROM_RAILS':
      return { ...state, isNavigatedFromRails: action.value}
    default:
      return state
  }
}
/* --------------------------------------------- */
/** Global provider */
const GlobalProvider = (props) => {
  const [state, dispatch] = useReducer(globalReducer, initialState)
  const { sendAnalytics } = useAnalytics()
  let { logger, sessionId } = props
  /* --------------------------------------------- */
  if (!logger) logger = initialState.logger
  /* --------------------------------------------- */
  return (
    <GlobalContext.Provider value={{
      ...state,
      dispatch,
      sendAnalytics,
      logger,
      sessionId
    }}
    >
      {props.children}
    </GlobalContext.Provider>
  )
}
/* --------------------------------------------- */
export default React.memo(GlobalProvider)
