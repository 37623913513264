
import styled from 'styled-components'
/* --------------------------------------------- */
// Styled components for Popup component.
export const PopupHeader = styled.div`
  background-color:${({ theme: { body } }) => body && body.background.primary};

  .heading {
    color:${({ theme: { body } }) => body && body.text.secondary};
  }
`

export const PopupContent = styled.div`
  background-color:${({ theme: { body } }) => body && body.background.primary};
`