import styled from 'styled-components'
import { device, size } from '../../constants/breakpoints'
import { ASPECT_RATIO } from '../../constants/details';
/* --------------------------------------------- */
import utils from '../../utils/utils'
/* --------------------------------------------- */
// Styled component for PlayerPlaceholder component
export const ImgContainer = styled.div`
${({ images, thisIsChannel, onImageLoad, showTwoByThreeImage }) => {
    if (!images) return
    return Object.keys(device).map((resolution) => {
      let width = 50
      if (resolution.indexOf('mobile') > -1 || resolution === 'tablet') width = 100
      let height = 0;
      const widthInPX = (width / 100) * size[resolution];
      if(showTwoByThreeImage && size[resolution]>960) {
        height = widthInPX * (1 / ASPECT_RATIO.TWO_BY_THREE)
      } else {
        height = widthInPX * (1 / ASPECT_RATIO.SIXTEEN_BY_NINE)
      }
      let img = utils.getCloserMatchImage(widthInPX, height, images)
      img = img ? img.url : ''
      img = window.escape(img)
      img = img.replace(/https%3A/g, 'https:')
      img = img.replace(/http%3A/g, 'http:')
      var image = new Image();
      image.onload = () => {
        if(onImageLoad && typeof onImageLoad ==="function") onImageLoad()
      }

      image.src=img;
      return `
      @media ${device[resolution]} {
          background-image: url("${img}");
          background-repeat: ${ thisIsChannel ? 'no-repeat' : ''};
          background-size: ${ thisIsChannel ? '50%!important' : ''};
      }
      `
    })
  }}
`
