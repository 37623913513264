import { getNew } from '../utils/contentfulHandler'
import getImage from './getImage'

// Helper function to form contentful tree
const getGraphics = (contentful) => {
  const imageLocal = getImage(contentful)
  const graphicsLocal = contentful.graphics
  return graphicsLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { images = [] } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      images: getNew(images, imageLocal)
    }
  })
}

export default getGraphics
