import { getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getAsset from './getAsset'

// Helper function to form contentful tree
const getImage = (contentful) => {
  const filterLocal = getFilter(contentful)
  const assetLocal = getAsset(contentful)
  const imageLocal = contentful.image
  return imageLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { filter = {}, media = {} } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      media: getNewObj(media, [assetLocal])
    }
  })
}

export default getImage
