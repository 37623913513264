import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const WarningIcon = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' {...rest}>
    <circle id='Ellipse_335' fill='none' stroke={color} strokeWidth='3' strokeLinecap='round' strokeLinejoin='round' cx='32.1' cy='32.1' r='29.4' />
    <circle fill={color} stroke={color} strokeWidth='3' strokeMiterlimit='10' cx='32.1' cy='51' r='2.5' />
    <rect x='30' y='11' fill={color} width='4' height='31' />
  </svg>
)
/* --------------------------------------------- */
WarningIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
WarningIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 22,
    height: 22
  }
}
/* --------------------------------------------- */
export default WarningIcon
/* --------------------------------------------- */
