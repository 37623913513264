import React, { useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
import _utils from '../../utils/utils'
import '../../styles/components/SelectBox.scss'
import { SelectBoxInput, DropdownItem } from './style'
import DropdownIconNew from '../icons/DropdownIcon.js'

// Select box component with events handling.
const openDropDownInvisibly = (global) => {
  const { dropdownHolder } = global
  dropdownHolder.classList.add('invisible')
  var _isOpenDropDownAbove = isOpenDropDownAbove(global)
  if (_isOpenDropDownAbove) openDropDownAbove(global)
  else openDropDownBelow(global)
}

const isOpenDropDownAbove = (global) => {
  const { selectBox, dropDownHeight } = global
  var selectBoxBounding = selectBox.getBoundingClientRect()
  var selectBoxFullHeight = selectBoxBounding.height + dropDownHeight
  return (window.innerHeight < selectBoxBounding.top + selectBoxFullHeight)
    ? isDropDownFitAbove(global) : false
}

const isDropDownFitAbove = (global) => {
  const { dropDownHeight } = global
  var selectBoxTop = global.selectBox.getBoundingClientRect().top
  return dropDownHeight < selectBoxTop
}

const calcDropDownHeight = (global) => {
  const { dropdown } = global
  var dropdownTop = _utils.removePx(_utils.getCSSValue(dropdown, 'top'))
  var dropdownBottom = _utils.removePx(_utils.getCSSValue(dropdown, 'bottom'))
  dropdownTop = (dropdownTop < 0) ? 0 : dropdownTop
  dropdownBottom = (dropdownBottom < 0) ? 0 : dropdownBottom
  return dropdownTop + dropdown.offsetHeight + dropdownBottom
}

const closeInvisibleDropDown = (global) => {
  const { dropdownHolder } = global
  dropdownHolder.classList.remove('invisible')
  closeDropDown(global)
}

const onInputClick = (global) => {
  const { dropdownHolder } = global
  const isExpanded = dropdownHolder.getAttribute('isExpanded')
  if (isExpanded === 'true') closeDropDown(global)
  else openDropDownAboveOrBelow(global)
}

const closeDropDown = (global) => {
  const { dropdownHolder } = global
  dropdownHolder.classList.remove('show')
  dropdownHolder.classList.add('hide')
  dropdownHolder.setAttribute('isExpanded', false)
}

const openDropDownAboveOrBelow = (global) => {
  const { selectBox, dropDownHeight } = global
  var selectBoxBounding = selectBox.getBoundingClientRect()
  var spaceAbove = selectBoxBounding.top
  var spaceBelow = window.innerHeight - selectBoxBounding.bottom
  if (dropDownHeight > spaceBelow && spaceAbove > spaceBelow) openDropDownAboveAndSetHeight(global)
  else openDropDownBelowAndSetHeight(global)
}

const openDropDownAboveAndSetHeight = (global) => {
  openDropDownAbove(global)
  setAboveDropDownMaxHeight(global)
}

const setAboveDropDownMaxHeight = (global) => {
  const { selectBox, dropdown } = global
  var selectBoxBounding = selectBox.getBoundingClientRect()
  var spaceAbove = selectBoxBounding.top
  var dropdownBottom = _utils.removePx(_utils.getCSSValue(dropdown, 'bottom'))
  setDropDownMaxHeight(global, spaceAbove - dropdownBottom)
}

const setDropDownMaxHeight = (global, availableSpace) => {
  const { dropdown, dropDownHeight } = global
  if (dropDownHeight < availableSpace) availableSpace = dropDownHeight
  dropdown.style.maxHeight = availableSpace + 'px'
}

const openDropDownAbove = (global) => {
  const { selectBox, dropdownHolder } = global
  dropdownHolder.classList.remove('below')
  dropdownHolder.classList.add('above')
  selectBox.insertBefore && selectBox.insertBefore(dropdownHolder, selectBox.childNodes[0])
  openDropDown(global)
}

const openDropDownBelowAndSetHeight = (global) => {
  openDropDownBelow(global)
  setBelowDropDownMaxHeight(global)
}

const openDropDownBelow = (global) => {
  const { selectBox, dropdownHolder } = global
  dropdownHolder.classList.remove('above')
  dropdownHolder.classList.add('below')
  selectBox.appendChild(dropdownHolder)
  openDropDown(global)
}

const openDropDown = (global) => {
  const { dropdownHolder } = global
  dropdownHolder.classList.remove('hide')
  dropdownHolder.classList.add('show')
  dropdownHolder.setAttribute('isExpanded', true)
}

const setBelowDropDownMaxHeight = (global) => {
  const { selectBox, dropdown } = global
  var selectBoxBounding = selectBox.getBoundingClientRect()
  var spaceBelow = window.innerHeight - selectBoxBounding.bottom
  var dropdownTop = _utils.removePx(_utils.getCSSValue(dropdown, 'top'))
  setDropDownMaxHeight(global, spaceBelow - dropdownTop)
}

const closeDropDownOnOutsideClick = (global, e) => {
  const { selectBox } = global
  var isClickedInsideSelectBox = _utils.isChildElement(e.target, selectBox)
  !isClickedInsideSelectBox && closeDropDown(global)
}

const selectOptionByIndex = (global, index) => {
  const { input, dropdownHolder, textArea, items } = global
  if (!items[index]) return
  input.setAttribute('selectedKey', items[index].key)
  var matchingEl = dropdownHolder.querySelector("[data-key='" + items[index].key + "']")
  matchingEl && matchingEl.setAttribute('active', true)
  textArea.innerHTML = items[index].value
  return items[index]
}

const selectOptionByKey = (global, key) => {
  const { items } = global
  for (var i = 0, index = -1; i < items.length; i++) {
    if (items[i].key === key) index = i
  }
  return selectOptionByIndex(global, index)
}

const removeSelectedOption = (global) => {
  const { input, dropdownHolder, textArea } = global
  var selectedKey = input.getAttribute('selectedKey')
  var matchingEl = dropdownHolder.querySelector("[data-key='" + selectedKey + "']")
  matchingEl && matchingEl.removeAttribute('active')
  textArea.innerHTML = ''
}

const removeActiveOptions = (global) => {
  const { dropdown } = global
  for (let i = 0; i < dropdown.children.length; i++) {
    dropdown.children[i].removeAttribute('active')
  }
}

const onDropDownItemClick = (global, index) => {
  const { onChange } = global
  removeSelectedOption(global)
  var selectedOption = selectOptionByIndex(global, index)
  onChange && onChange({
    index: index,
    key: selectedOption.key,
    value: selectedOption.value
  })
  closeDropDown(global)
}
/* --------------------------------------------- */
const SelectBox = ({ title, items, onChange, defaultOption, theme }) => {
  const selectBoxRef = useRef(null)
  const inputRef = useRef(null)
  const textAreaRef = useRef(null)
  const dropdownHolderRef = useRef(null)
  const dropdownRef = useRef(null)
  const globalRef = useRef({})

  /**
   * It: Initializes drop down.
   * It: Adds click event listener to select box.
   */
  useLayoutEffect(() => {
    const global = {}
    global.selectBox = selectBoxRef.current
    global.input = inputRef.current
    global.textArea = textAreaRef.current
    global.dropdownHolder = dropdownHolderRef.current
    global.dropdown = dropdownRef.current
    global.items = items
    global.onChange = onChange

    openDropDownInvisibly(global)
    global.dropDownHeight = calcDropDownHeight(global)
    closeInvisibleDropDown(global)

    removeActiveOptions(global)
    if (defaultOption === '') selectOptionByIndex(global, 0)
    else selectOptionByKey(global, defaultOption)

    globalRef.current = global
    document.addEventListener('click', closeDropDownOnOutsideClick.bind(null, global))
    return () => {
      document.removeEventListener('click', closeDropDownOnOutsideClick.bind(null, global))
    }
  }, [defaultOption, items, onChange])

  // useEffect(() => {
  //   if (option) {
  //     const global = globalRef.current
  //     const selectedOption = selectOptionByKey(global, option)
  //     if (selectedOption && onChange) {
  //       onChange({
  //         key: selectedOption.key,
  //         value: selectedOption.value
  //       })
  //     }
  //   }
  // }, [option, onChange])
  const _onInputClick=()=>{
    onInputClick(globalRef.current)
  }
  const _onDropDownItemClick=(i)=>{
    onDropDownItemClick( globalRef.current, i)
  }
  return (
    <div className='selectBox'>
      <div>{title}</div>
      <div ref={selectBoxRef} className='selectContainer'>
        <SelectBoxInput {...{ theme }} ref={inputRef} onClick={()=>_onInputClick()} id='input' className='selectBoxInput'>
          <div ref={textAreaRef} id='textArea' />
          <div id='dropdownIconHolder' className='selectBoxInput'>
            {items.length > 0 && <DropdownIconNew style={{ width: 15, height: 16 }} />}
          </div>
        </SelectBoxInput>
        <div ref={dropdownHolderRef} id='dropdownHolder' className='hide'>
          <div ref={dropdownRef} id='dropdown'>
            {items.map(({ key, value }, i) => {
              return (
                <DropdownItem
                  key={key} {...{
                    'data-key': key,
                    onClick: ()=>_onDropDownItemClick(i),
                    theme
                  }}
                >{value}
                </DropdownItem>)
            })}
          </div>
        </div>
      </div>
    </div>
  )
}


/* --------------------------------------------- */
SelectBox.propTypes = {
  /** title to be shown above the selectbox */
  title: PropTypes.string,
  /** selectbox items */
  items: PropTypes.array,
  /** onChange handler */
  onChange: PropTypes.func,
  /** default item to be selected */
  defaultOption: PropTypes.any,
  /** item to be selected */
  option: PropTypes.any,
  /** Theme from contentful */
  theme: PropTypes.object
}
/* --------------------------------------------- */
SelectBox.defaultProps = {
  title: '',
  items: [],
  onChange: () => { },
  defaultOption: '',
  theme: {}
}
/* --------------------------------------------- */
export default SelectBox
  /* --------------------------------------------- */
