import React, { useEffect, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
/* --------------------------------------------- */
import { version } from '../../config'
/* --------------------------------------------- */
import VERSION from '../../constants/apiVersionMap'
/* --------------------------------------------- */
import { useGlobalNew } from '../../context/global.new.context'
import { GlobalContext } from '../../context/global.context'
import { useMiscsDispatch } from '../../context/miscs.context'
/* --------------------------------------------- */
import useLoggly from '../../containers/useLoggly'
/* --------------------------------------------- */
import { setAutoPlayNextEpisode } from '../../utils/localStorageService'
/* --------------------------------------------- */
import CloseIcon from '../icons/CloseIcon'
import ToggleSwitch from '../ToggleSwitch'
/* --------------------------------------------- */
// SettingsPopup component for displaying settings popup and handles events
const SettingsPopup = () => {
    const [state, dispatch] = useGlobalNew()
    const miscsDispatch = useMiscsDispatch()
    /* --------------------------------------------- */
    const { isAutoplay, dispatch: globalDispatch } = useContext(GlobalContext)
    /* --------------------------------------------- */
    const { appMessages } = useSelector(store => store.appConfigReducers)
    const { popupTheme } = useSelector(store => store.themeConfigReducers)
    const { secondaryMenuConfig = {} } = useSelector(store => store.navigation)
    const [ispopupClosed, updatePopupClosed] = useState(false)
    const { menuItems = [] } = secondaryMenuConfig

    let settingPageTheme;
    const  settingPage = menuItems?.filter(menuItem => menuItem.id === "menu_settings")
    if (settingPage && settingPage.length > 0) {
        settingPageTheme = settingPage[0].theme[0]
    }
    /* --------------------------------------------- */
    const { log } = useLoggly()
    /* --------------------------------------------- */
    /**
     * It: Closes settings poopup
     */
    const handleCloseClick = () => {
        dispatch({ type: 'CLOSE_SETTINGS_POPUP' })
        updatePopupClosed(true);
    }
    /* --------------------------------------------- */
    /**
     * It: Toggles auto play.
     * It: Updates auto play state globally.
     * It: Sends loggly logs.
     */
    const handleAutoPlayChange = (e) => {
        if (e.target.checked) {
            globalDispatch({ type: 'AUTOPLAY_TRUE' })
            setAutoPlayNextEpisode(true)
            // sending debug log to loggly if user clicks on autoplay button
            log({
                type: 'Debug',
                attr: {
                    module: 'Settings',
                    debug_message: 'Autoplay on'
                }
            })
        }
        else {
            globalDispatch({ type: 'AUTOPLAY_FALSE' })
            setAutoPlayNextEpisode(false)
            // sending debug log to loggly if user clicks on autoplay button
            log({
                type: 'Debug',
                attr: {
                    module: 'Settings',
                    debug_message: 'Autoplay off'
                }
            })
        }
    }
    /* --------------------------------------------- */
    /**
     * It: Disables body scroll on opening settings popup.
     */
    useEffect(() => {
        if(ispopupClosed) miscsDispatch({ type: 'ENABLE_BODY_SCROLL' })
        if (state.isOpenSettingsPopup) miscsDispatch({ type: 'DISABLE_BODY_SCROLL' })
        else miscsDispatch({ type: 'ENABLE_BODY_SCROLL' })
        return () => {
            miscsDispatch({ type: 'ENABLE_BODY_SCROLL' })
        }
    }, [state.isOpenSettingsPopup, miscsDispatch, ispopupClosed])
    /* --------------------------------------------- */
    return (
        <div className={`details-page__pop-up settings-popUp ${state.isOpenSettingsPopup ? 'details-page__pop-up---open' : ''}`}>
            <SettingsWrapper {...{ theme: popupTheme }} className='details-page__pop-up-wrapper'>

                <div className='details-page__pop-up-head' >
                    <PopUpHeader theme={{...settingPageTheme}}>{state.settingsTitle || ''}</PopUpHeader>
                    <div className='player-audio__close' onClick={handleCloseClick}>
                        <CloseIcon color={popupTheme && popupTheme.header && popupTheme.header.accent && popupTheme.header.accent.primary} style={{ width: 15, height: 15 }} />
                    </div>
                </div>
                <div className='settings-popUp__content' >
                    <div className='action-single'>
                        <SettingBodyWrapper  theme={{...settingPageTheme}}>{appMessages.label_settings_autoplay_next_episode}</SettingBodyWrapper>
                        <ToggleSwitch checked={isAutoplay} onChange={handleAutoPlayChange} theme={popupTheme} />
                    </div>
                </div>
                <div className='app-ver-code'>
                    {appMessages.label_settings_version? appMessages?.label_settings_version: VERSION.VERSION_TEXT} {version.name}
                </div>
            </SettingsWrapper>
        </div>
    )
}
/* --------------------------------------------- */
export const SettingsWrapper = styled.div`
  background: ${({ theme: { body } }) => body && body?.background?.primary};
`
export const PopUpHeader = styled.p`
  color: ${({ theme: { header } }) => header && header?.text?.primary};
`
export const SettingBodyWrapper = styled.p`
color: ${({ theme: { body } }) =>  body && body?.text?.secondary?.code};
`
/* --------------------------------------------- */
export default SettingsPopup