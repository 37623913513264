import React, { useContext } from 'react'
import styled from "styled-components";
import utils from "../../../utils/utils";
import { Context } from "./context";
import WarningIconOrginal from '../../icons/WarningIcon'
import ButtonWrapper from '../../Button/index'
import FavOutlineIcon from '../../icons/FavOutlineIcon';
import SearchIcon from '../../icons/SearchIcon';
import SubscriptionIconOriginal from '../../icons/Subscription'
import { routeMap } from '../../../route'
/* ---------------------------------------------------------------------------------------------------- */
export const Wrapper = (props) => {
  const { errorPage } = useContext(Context)
  const { theme } = errorPage
  /* -------------------------------------------------------------------------------------------------- */
  return <StyledWrapper theme={theme}>{props.children}</StyledWrapper>
};
/* ---------------------------------------------------------------------------------------------------- */
export const PrimaryButtonWrapper = (props) => {
  const { errorPage } = useContext(Context)
  const { theme } = errorPage

  /* -------------------------------------------------------------------------------------------------- */
  return <ButtonWrapper
    {...{
      theme: theme && theme.compositeStyle && theme.compositeStyle.primaryButton,
      text: props.text,
      onClick: props.ButtonOnClick,
    }}
  />
};
/* ---------------------------------------------------------------------------------------------------- */
export const SecondaryButtonWrapper = (props) => {
  const { errorPage } = useContext(Context)
  const { theme } = errorPage
  /* -------------------------------------------------------------------------------------------------- */
  return <ButtonWrapper
    {...{
      theme: theme && theme.compositeStyle && theme.compositeStyle.secondaryButton,
      text: props.text,
      size: props.buttonSize,
      onClick: props.ButtonOnClick,
    }}
  />
};
/* ---------------------------------------------------------------------------------------------------- */
export const SubscriptionIcon = (props) => {
  const { errorPage } = useContext(Context)
  const { theme } = errorPage
  /* -------------------------------------------------------------------------------------------------- */
  let color = "transparent"
  /* -------------------------------------------------------------------------------------------------- */
  if (utils.isNotNull(theme, "body", "accent", "primary")) color = theme.body.accent.primary;
  /* -------------------------------------------------------------------------------------------------- */
  return <SubscriptionIconOriginal color={color} style={{ width: 50, height: 50, marginBottom: 25 }} {...props} />
};
/* ---------------------------------------------------------------------------------------------------- */
export const WarningIcon = (props) => {
  const { errorPage } = useContext(Context)
  const { theme } = errorPage
  /* -------------------------------------------------------------------------------------------------- */
  let color = "transparent"
  /* -------------------------------------------------------------------------------------------------- */
  if (utils.isNotNull(theme, "body", "accent", "primary")) color = theme.body.accent.primary;
  /* -------------------------------------------------------------------------------------------------- */
  return <WarningIconOrginal color={color} style={{ width: 50, height: 50, marginBottom: 25 }} {...props} />
};
/* ---------------------------------------------------------------------------------------------------- */
export const FavouritesIcon = (props) => {
  const { errorPage } = useContext(Context)
  const { theme } = errorPage
  /* -------------------------------------------------------------------------------------------------- */
  let color = "transparent"
  /* -------------------------------------------------------------------------------------------------- */
  if (utils.isNotNull(theme, "body", "accent", "primary")) color = theme.body.accent.primary;
  /* -------------------------------------------------------------------------------------------------- */
  return <FavOutlineIcon color={color} style={{ width: 50, height: 50, marginBottom: 25 }} {...props} />
};
/* ---------------------------------------------------------------------------------------------------- */
export const SearchIconOutline = (props) => {
  const { errorPage } = useContext(Context)
  const { theme } = errorPage
  /* -------------------------------------------------------------------------------------------------- */
  let color = "transparent"
  /* -------------------------------------------------------------------------------------------------- */
  if (utils.isNotNull(theme, "body", "accent", "primary")) color = theme.body.accent.primary;
  /* -------------------------------------------------------------------------------------------------- */
  return <SearchIcon color={color} style={{ width: 50, height: 50, marginBottom: 25 }} {...props} />
};
/* ---------------------------------------------------------------------------------------------------- */
export const StyledWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  height: ${() => routeMap.player ? '100%' : 'calc(100vh - 65px)'};
  
  .error-page-wrapper {
    background: ${({ theme }) => {
    if (utils.isNotNull(theme, "body", "background", "secondary")) {
      return theme.body.background.secondary;
    } else {
      return "transparent";
    }
  }};
  }

  .--hide {
    display: none
  }

  h2 {
    color: ${({ theme }) => {
    if (utils.isNotNull(theme, "header", "text", "primary")) {
      return theme.header.text.primary;
    } else {
      return "transparent";
    }
  }};
  }

  p {
    color: ${({ theme }) => {
    if (utils.isNotNull(theme, "body", "text", "primary")) {
      return theme.body.text.primary;
    } else {
      return "transparent";
    }
  }};
  }

  .error-code {
    color: ${({ theme }) => {
    if (utils.isNotNull(theme, "body", "text", "secondary")) {
      return theme.body.text.secondary;
    } else {
      return "transparent";
    }
  }};
  }

  .error-session-id {
    color: ${({ theme }) => {
      if (utils.isNotNull(theme, "body", "text", "secondary")) {
        return theme.body.text.secondary;
      } else {
        return "white"; //return white incase if no theme values available
      }
    }};
    font-size: 12px;
  }

    .popup-btn__positive {
      background-color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "primaryButton", "normal", "background")) {
      return theme.compositeStyle.primaryButton.normal.background;
    } else {
      return "transparent";
    }
  }};
      color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "primaryButton", "normal", "text")) {
      return theme.compositeStyle.primaryButton.normal.text;
    } else {
      return "transparent";
    }
  }};
    }

    .popup-btn__negative {
      background-color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "secondaryButton", "normal", "background")) {
      return theme.compositeStyle.secondaryButton.normal.background;
    } else {
      return "transparent";
    }
  }};
  color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "secondaryButton", "normal", "text")) {
      return theme.compositeStyle.secondaryButton.normal.text;
    } else {
      return "transparent";
    }
  }};
  border: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "secondaryButton", "normal", "stroke")) {
      return `1px solid ${theme.compositeStyle.secondaryButton.normal.stroke}`;
    } else {
      return "transparent";
    }
  }};
    }


    .error-fullscreen__btn{
      .popup-btn-subtitle{
        color: ${({ theme }) => {
    if (utils.isNotNull(theme, "body", "text", "secondary")) {
      return theme.body.text.secondary;
    } else {
      return "transparent";
    }
  }};
      }
    }



    .error-fullscreen__faq{

    .popup-btn-subtitle.faq-description{
      color: ${({ theme }) => {
    if (utils.isNotNull(theme, "body", "text", "tertiary")) {
      return theme.body.text.tertiary;
    } else {
      return "transparent";
    }
  }};
    }

    .popup-btn.popup-btn__negative{

      background-color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "tertiaryButton", "normal", "background")) {
      return theme.compositeStyle.tertiaryButton.normal.background;
    } else {
      return "transparent";
    }
  }};

      color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "tertiaryButton", "normal", "text")) {
      return theme.compositeStyle.tertiaryButton.normal.text;
    } else {
      return "transparent";
    }
  }};
      border: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "tertiaryButton", "normal", "stroke")) {
      return `1px solid ${theme.compositeStyle.tertiaryButton.normal.stroke}`;
    } else {
      return "transparent";
    }
  }};
    }
  }


`;
/* ---------------------------------------------------------------------------------------------------- */
