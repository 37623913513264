import React, { useState, useRef, useCallback, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
import Spinner from '.././Spinner'
/* --------------------------------------------- */
import { routeMap
  // routeVarMap 
} from '../../route'
/* --------------------------------------------- */
import useLoggly from '../../containers/useLoggly'
import useAnalytics from '../../containers/useAnalytics'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
import { ErrorContext } from '../../context/error.context'
/* --------------------------------------------- */
import useGeneralApi from '../../apis/useGeneralApi'//
import useDetailsApi from '../../apis/useDetailsApi'
/* --------------------------------------------- */
import useFirebaseTrace from '../../hooks/useFirebaseTrace'
/* --------------------------------------------- */
import SearchIcon from '.././icons/SearchIcon'//
import GoBackArrowIcon from '.././icons/GoBackArrowIcon'//
import utils from '../../utils/utils'//
import { splitLanguage } from '../../utils/localStorageService'//
import { getProductsLocal, getRecentSearchesLocal, setRecentSearchesLocal } from '../../utils/sessionStorageService'
import { StyleWrapper, SearchInputOuter, ListTitle, ClearAll, ListItemTitle, ListItemSubtitle, MoreResults, FloatingLabel, SearchBarSuggestions } from './style'
import VERSION from '../../constants/apiVersionMap'
import usePlayerApi from '../../apis/usePlayerApi'
import useErrorUtils from '../../utils/useErrorUtils'
import usePlaybackError from '../../hooks/usePlaybackError'
import _utils from '../../utils/utils'
import { isIOS, isMobile } from 'react-device-detect'

/* --------------------------------------------- */
// SearchBar component for showing search bar and handling search events.
const SearchBar = () => {
  const [isShowMore, toggleIsShowMore] = useState(false)
  const [isFocus, toggleIsFocus] = useState(false)
  const [suggestions, updateSuggestions] = useState([])
  const [noMatch, toggleNoMatch] = useState(false)
  const [searchBarTheme, setSearchBarTheme] = useState({})
  const [isResponsiveSearchOpen, setIsResponsiveSearchOpen] = useState(false)
  const [noRecentSearch, toggleNoRecentSearch] = useState(false)
  const [searchIconColor, setSearchIconColor] = useState('transparent')
  const [searchHistoryTheme, setSearchHistoryTheme] = useState({})
  const [searchSuggestionTheme, setSearchSuggestionTheme] = useState({})
  const [sysId, setSysId] = useState('')
  const [recentSearches, setRecentSearches] = useState([])
  const [isSearchField, updateIsValidField] = useState(false)
  const [identityProvider, setIdentityProvider] = useState('')
  const [smilData, updateSmilData] = useState(null)
  const [detailsSectionData, setDetailSectionData] = useState(null)
  /* --------------------------------------------- */
  const { isAuthenticated, searchText, dispatch, appLanguage, selectedProfile, sessionId, userInfo, isMobileUser } = useContext(GlobalContext)
  const playerFeature = useSelector(store => store.featuresReducers.playerFeature)
  const { errorPage } = useContext(ErrorContext)
  /* --------------------------------------------- */
  const { sendAnalytics } = useAnalytics()
  const { getSearchSuggestion } = useGeneralApi()
  const { setRecentSearch, getRecentSearch, clearRecentSearch } = useDetailsApi()
  /* --------------------------------------------- */
  const { specialMenuConfig } = useSelector(store => store.navigation)
  const { appConfig, appMessages } = useSelector(store => store.appConfigReducers)
  const { searchConfig, searchComponents } = useSelector(store => store.search)
  const { userManagementConfig = {} } = useSelector(store => store.userManagement)
  const { authConfig: userAuthConfig = [] } = userManagementConfig
  const { supportConfig } = useSelector(store => store.support)
  if (!supportConfig.errorConfiguration) supportConfig.errorConfiguration = []
  const errorConfigurationRef = useRef(supportConfig.errorConfiguration)
  const [errorObj, setError] = useState(null);

  /* --------------------------------------------- */
  const history = useHistory()
  const searchTimer = useRef(null)
  const appMessagesRef = useRef(appMessages)
  /* --------------------------------------------- */
  const { log } = useLoggly()
  /* --------------------------------------------- */
  const firebaseTrace = useFirebaseTrace()
  /* --------------------------------------------- */
  const searchBar = useRef(null)
  const searchInput = useRef(null)
  const _uuid = useRef(sessionId)
  const { getParsedSMIL } = usePlayerApi()
  const { showError } = useErrorUtils()
  const releaseUrlRef = useRef('')

  /* --------------------------------------------- */
  /**
   * It: Handles outside click.
   * It: Removes focus.
   */
  const handleClickOutside = useCallback((event) => {
    if (!(searchBar.current && searchBar.current.contains(event.target))) {
      toggleIsFocus(false)
    }
  }, [])
  useEffect(() => {
    if (!userAuthConfig) return
    const authConfig = userAuthConfig[0] || {}
    const identityProvider = authConfig.identityProvider || ''
    setIdentityProvider(identityProvider)
  }, [userAuthConfig])
  /* --------------------------------------------- */
  /**
   * It: Handles search input change.
   * It: Sets search text in state.
   */
  const searchInputChangeHandler = useCallback((e) => {
    searchInput.current.focus()
    updateIsValidField(true)
    if (!e.target.value) {
      updateIsValidField(false)
    }
    const value = e ? e.target.value : e
    if (value.length > searchConfig.maximumSearchQueryLength) return
    /* --------------------------------------------- */
    dispatch({ type: 'SEARCH_TEXT', value })
    dispatch({ type: 'SEARCH_TEXT_COUNTRY_CODE', value })
  }, [
    searchConfig.maximumSearchQueryLength, dispatch
  ])
  /* --------------------------------------------- */
  /**
   * It: Sets recent search from local on focus.
   */
  useEffect(() => {
    appMessagesRef.current = appMessages
  }, [appMessages])
  /* --------------------------------------------- */
  useEffect(() => {
    if (isFocus) {
      const recentSearchesLocal = getRecentSearchesLocal() || []
      setRecentSearches(recentSearchesLocal)
    }
  }, [isFocus])
  /* --------------------------------------------- */
  /**
   * It: Updates theme.
   */
  useEffect(() => {
    if (Object.keys(searchComponents).length === 0) return
    const { theme: historyTheme = {} } = searchComponents['component_search_history'] || {}
    const { theme: suggestionTheme = {} } = searchComponents['component_search_suggestion'] || {}
    setSearchHistoryTheme(historyTheme)
    setSearchSuggestionTheme(suggestionTheme)
  }, [searchComponents])
  /* --------------------------------------------- */
  /**
   * It: Updaets sysId in a state.
   */
  useEffect(() => {
    const searchPage = searchConfig.searchPage || []
    const { sysId = '' } = searchPage[0] || {}
    setSysId(sysId)
  }, [searchConfig.searchPage])
  /* --------------------------------------------- */
  /**
   * It: Sets search bar theme.
   */
  useEffect(() => {
    const searchBarItem = specialMenuConfig.filter(({ id }) => {
      return (id === 'search_bar')
    })
    if (utils.isNotNull(searchBarItem, 0, 'buttonStyle')) {
      setSearchBarTheme(searchBarItem[0].buttonStyle)
    }
  }, [
    specialMenuConfig
  ])
  /* --------------------------------------------- */
  /**
   * It: Sets mouse down event listerner.
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [
    handleClickOutside
  ])
  /* --------------------------------------------- */
  /**
   * It: Fetches recent search from MW when authenticated
   * It: Sends performance trace.
   * It: Toggles recent search dropdown.
   */
  useEffect(() => {
    let isMounted = true
    let trace = null
    if (isAuthenticated) {
      if (isFocus && searchText.length < searchConfig.minimumSearchQueryLength && searchConfig.enableSearchHistory && appConfig.baseUrl) {
        trace = firebaseTrace('recent_search_load')
        let profileId = selectedProfile ? selectedProfile.profileId : userInfo?.profiles?.[0] ? userInfo?.profiles?.[0]?.profileId : ''
        getRecentSearch(appConfig.baseUrl, searchConfig.maxSearchHistoryCount, profileId)
          .then((res) => {
            if (!isMounted) return
            let { searchList } = res
            searchList = [...new Set(searchList)]
            searchList = searchList.slice(0, searchConfig.maxSearchHistoryCount)
            if(searchList?.length)
            setRecentSearchesLocal(searchList)
            if (searchList.length === 0) toggleNoRecentSearch(true)
            else toggleNoRecentSearch(false)
            trace.addAttrs({ itemCount: (searchList || []).length + '' })
            trace.stop()
            trace = null
          })
          .catch(() => {
            if (!isMounted) return
            toggleNoRecentSearch(true)
            trace.stop()
            trace = null
          })
      }
    } else {
      toggleNoRecentSearch(true)
    }
    return () => {
      isMounted = false
      if (trace) trace.stop()
    }
  }, [isFocus,
    searchText,
    isAuthenticated,
    userInfo,
    getRecentSearch,
    setRecentSearches,
    appConfig.baseUrl,
    searchConfig.enableSearchHistory,
    searchConfig.maxSearchHistoryCount,
    searchConfig.minimumSearchQueryLength,
    selectedProfile,
    firebaseTrace
  ])
  /* --------------------------------------------- */
  /**
   * It: Fetches search suggetino from MW.
   * It: Sends performance trace.
   */
  useEffect(() => {
    updateSuggestions([])
    toggleNoMatch(false)
    let isMounted = true
    let trace = null
    if (searchTimer.current) {
      clearTimeout(searchTimer.current)
    }
    searchTimer.current = setTimeout(() => {
      if (
        isFocus &&
        searchText &&
        appConfig.baseUrl &&
        searchText.trim().length >= searchConfig.minimumSearchQueryLength
        // !/^\s/.test(searchText)
      ) {
       
        /* --------------------------------------------- */
        log({
          type: 'Info',
          attr: {
            debug_message: `Searched ${searchText}`,
            module: 'Search'
          }
        })
        /* --------------------------------------------- */
        toggleIsShowMore(false)
        /* --------------------------------------------- */
        trace = firebaseTrace('search_suggestions_load', {
          searchTerm: searchText
        })
        /* --------------------------------------------- */
        let queryParams = {
            query: window.encodeURIComponent(searchText),
            maxResultCount: searchConfig.maxSearchSuggestionCount,
            ratio: searchConfig.searchSuggestionTypeRatio,
            language: splitLanguage(appLanguage),
            profileId: selectedProfile ? selectedProfile.profileId : ''
        }
        if(searchConfig.searchSuggestionsFilter != null){
          queryParams.searchSuggestionsFilter = encodeURIComponent(searchConfig.searchSuggestionsFilter);
        }
        getSearchSuggestion(
          appConfig.baseUrl, queryParams
        ).then((res) => {
          if (!isMounted) return
          const content = res.content || []
          const newArray = content.slice(0, searchConfig.maxSearchSuggestionCount)
          updateSuggestions(newArray)
          /* --------------------------------------------- */
          if (content.length > 0) toggleIsShowMore(true)
          /* --------------------------------------------- */
          if (content.length === 0) toggleNoMatch(true)
          else toggleNoMatch(false)
          trace.addAttrs({ resultCount: (newArray || []).length + '' });
          trace.stop()
          trace = null
        }).catch(() => {
          updateSuggestions([])
          toggleNoMatch(false)
          trace.stop()
          trace = null
        })
      } else {
        updateSuggestions([])
      }
    }, 1300)
    return () => {
      isMounted = false
      clearTimeout(searchTimer.current)
      if (trace) trace.stop()
    }
  }, [appLanguage,
    isFocus,
    searchText,
    log,
    appConfig.baseUrl,
    getSearchSuggestion,
    searchConfig.minimumSearchQueryLength,
    searchConfig.maxSearchSuggestionCount,
    searchConfig.searchSuggestionTypeRatio,
    searchConfig.searchSuggestionsFilter,
    selectedProfile,
    firebaseTrace
  ])
  /* --------------------------------------------- */
  /**
   * It: Sets search icon color.
   */
  useEffect(() => {
    let seachIconColor = 'transparent'
    if ((isFocus || searchText) && utils.isNotNull(searchBarTheme, 'selected', 'image')) {
      seachIconColor = searchBarTheme.selected.image
    } else if (utils.isNotNull(searchBarTheme, 'normal', 'image')) {
      seachIconColor = searchBarTheme.normal.image
    }
    setSearchIconColor(seachIconColor)
  }, [
    isFocus,
    searchText,
    searchBarTheme
  ])
  /* --------------------------------------------- */
  /**
   * It: Sets focus.
   */
  const onFocusHandle = useCallback(() => {
    toggleIsFocus(true)
  }, [])

  const onPrimaryBtnClick = () => {
    history.push(routeMap.subscription);
  }
  /* --------------------------------------------- */
  /**
   * It: Removes search text.
   * It: Removes focus.
   */
  const handleSearchBackBtnClick = useCallback(() => {
    if (/^\s/.test(searchText)) return
    updateSuggestions([])
    dispatch({ type: 'SEARCH_TEXT', value: '' })
    dispatch({ type: 'SEARCH_TEXT_COUNTRY_CODE', value: '' })
    toggleIsFocus(false)
    setIsResponsiveSearchOpen(false)
  }, [dispatch, searchText])
  /* --------------------------------------------- */
  const onBlurHandle = useCallback((event) => {
    if (searchBar.current && !searchBar.current.contains(event.target)) toggleIsFocus(false)
  }, [])

  // sending smil error log based on the error type coming from smil api response
  const sendLog = useCallback((errorType, module, errorInfo) => {
    const config = errorConfigurationRef.current.filter((config) => {
        return config.type === errorType
    })[0] || {}
    const releaseUrl = releaseUrlRef?.current;
    log({
        type: config?.loggingLevel ? _utils.capitalizeFirstLetter(config?.loggingLevel) : 'Error',
        attr: {
            error_type: errorType,
            error_code: config.code,
            module: module,
            retry_count: errorInfo?.retry_count || 1,
            mw_error_code: errorInfo?.errorCode,
            error_shown: true,
            pageUrl: window.location.href,
            playback: {
                player_event: 'Start Over',
                source_url: releaseUrl,
                content_title: detailsSectionData?.contentTitle,
                content_id: detailsSectionData?.contentGuid,
                media_type: (detailsSectionData?.type === "liveEvent" || detailsSectionData?.type === "liveevent") ? 'live' : 'vod',
                message: config.name,
                ...(detailsSectionData?.mediaGuid && { stream_id: detailsSectionData?.mediaGuid }),
                shaka_version: window?.shaka?.Player?.version || 'v3.3.9'
            },
            error_info: {
                request_url: errorObj?.apiUrl || errorInfo?.apiUrl,
                response_code: errorObj?.errorStatus || errorInfo?.errorStatus,
                response_message: errorObj?.responseBody && JSON?.stringify(errorObj?.responseBody),
                error_location: 'Home'
            }
        }
    })
  }, [errorConfigurationRef, releaseUrlRef, log, detailsSectionData, errorObj])

  const resetError = () => {
    setError(null)
  }

  usePlaybackError({ type: detailsSectionData?.type, detailsSectionData, releaseUrlRef, resetError, onPrimaryBtnClick, errorObj, smilUrl:releaseUrlRef.current, smilData, errorConfigurationRef, contentTitle: detailsSectionData?.title, sendLog })

  /* --------------------------------------------- */
  /**
   * It: Passes search text to search page.
   * It: Redirects to search page.
   */
  const handleSearchIconClick = useCallback(() => {
    if (searchText.trim().length < searchConfig.minimumSearchQueryLength ) return; // || /^\s/.test(searchText)
    const isSearchErrorShown = (errorPage || {}).type === 'GenericNoSearch'
    const query = `${routeMap.listing}?search=${window.encodeURIComponent(searchText)}&sysId=${sysId}`
    handleSearchBackBtnClick()
    toggleIsFocus(false)
    if (searchText) {
      if (isSearchErrorShown) {
        history.replace(query)
      } else {
        history.push(query)
      }
    }
  }, [
    sysId,
    history,
    errorPage,
    searchText,
    handleSearchBackBtnClick,
    searchConfig.minimumSearchQueryLength
  ])
  /* --------------------------------------------- */
  /**
   * It: On enter key press it passes search text to search page.
   * It: Redirects to search page.
   */
  const handleKeyPress = useCallback((e) => {
    searchInput.current.focus()
    if (e.key === 'Enter') {
      e.preventDefault();
      if (searchText.trim().length < searchConfig.minimumSearchQueryLength ) return //|| /^\s/.test(searchText)
      searchInput.current.blur()
      handleSearchIconClick()
    }
  }, [handleSearchIconClick, searchText, searchConfig.minimumSearchQueryLength])
  /* --------------------------------------------- */
  /**
   * It: Passes search text to search page.
   * It: Redirects to search page.
   */
  const handleRecentSearchClick = useCallback((searchText) => {
    const query = `${routeMap.listing}?search=${window.encodeURIComponent(searchText)}&sysId=${sysId}&recentSearch=true`
    const isSearchErrorShown = (errorPage || {}).type === 'GenericNoSearch'
    handleSearchBackBtnClick()
    searchInput.current.blur()
    if (searchText) {
      if (isSearchErrorShown) {
        history.replace(query)
      } else {
        history.push(query)
      }
    }
  }, [
    sysId,
    history,
    errorPage,
    handleSearchBackBtnClick
  ])
  /* --------------------------------------------- */
  const resSearchClickhandle = () => {
    setIsResponsiveSearchOpen(true)
    searchInput.current.focus()
  }
 
const authenticate = useCallback((callback = null) => {
  if (!isAuthenticated) {
    dispatch({ type: 'SHOW_LOGIN_POPUP' })
    if (identityProvider === "mpx" || identityProvider === "cognito") {
      dispatch({
        type: 'LOGIN_POPUP_DETAILS',
        value: {
          isShowCloseIcon: true,
          onSignupClick: () => {
            dispatch({ type: 'HIDE_LOGIN_POPUP' })
            history.push(routeMap.signup)
          },
          onLoginClick: () => {
            dispatch({ type: 'HIDE_LOGIN_POPUP' })
            history.push(routeMap.login)
          },
          onCloseClick: () => {
            dispatch({ type: 'HIDE_LOGIN_POPUP' })
          }
        }
      })
    } else {
      dispatch({
        type: 'LOGIN_POPUP_DETAILS',
        value: {
          isShowCloseIcon: true,
          onCloseClick: () => {
            dispatch({ type: 'HIDE_LOGIN_POPUP' })
          }
        }
      })
    }
  } else {
    dispatch({ type: 'HIDE_LOGIN_POPUP' })
    dispatch({
      type: 'LOGIN_POPUP_DETAILS', value: {}
    })
    if (callback) callback()
  }
}, [dispatch, isAuthenticated, history, identityProvider])
  const handleSearchSuggestionClick = useCallback((title, type, uid, searchMode = 'title', details) => {
      if(!isAuthenticated) {
        authenticate()
      } else  if(isMobileUser) {
        const getAppDetails = {
          title,
          images: details?.images,
          releaseYear : details?.releaseYear,
          parentalControl: details?.parentalControl,
          isCcAvailable: details?.isCcAvailable,
          isHdAvailable: details?.isHdAvailable,
          type: details?.type,
        }
        dispatch({
          type: 'GETAPP_POPUP_DETAILS',
          value: {
            isShowPopup: true,
            details: getAppDetails
          }
        })
      }else {
          if (!appConfig.baseUrl) return
          sendAnalytics({
              type: 'search',
              attr: { search_term: searchText }
              })
    /* --------------------------------------------- */
    // const appMessages = appMessagesRef.current || {}
    /* --------------------------------------------- */
    // const typeMap = {
    //   match: appMessages.media_type_match,
    //   movie: appMessages.media_type_movie,
    //   series: appMessages.media_type_series,
    //   liveevent: appMessages.media_type_liveevent,
    //   liveEvent: appMessages.media_type_liveEvent,
    //   preview: appMessages.media_type_preview
    // }
    /* --------------------------------------------- */
    // const typeMapId = {
    //   match: appMessages.media_type_match_id,
    //   movie: appMessages.media_type_movie_id,
    //   series: appMessages.media_type_series_id,
    //   liveevent: appMessages.media_type_liveevent_id,
    //   liveEvent: appMessages.media_type_liveEvent_id,
    //   preview: appMessages.media_type_preview_id
    // }
    /* --------------------------------------------- */
    const profileId = selectedProfile ? selectedProfile.profileId : ''
    setRecentSearch(appConfig.baseUrl, title, profileId).catch(() => { })
    /* --------------------------------------------- */
    handleSearchBackBtnClick()
    if( details?.broadcastStartTime && (details?.broadcastStartTime > new Date().getTime())) return 

    /* --------------------------------------------- */
    if (searchMode === 'title') {
      setDetailSectionData(details);
      const linkUri = `${routeMap.player}?mediaId=${uid}`
      const uuid = _uuid.current
      let _smilUrl = linkUri.replace(/^http:\/\//i, `${window.location.protocol}//`)
      let products = getProductsLocal()
      const productId = products?.purchasedProducts?.[0]?.id || '';
      const parsedURL = utils.parseUrl(_smilUrl);
      const queryParams = utils.parse(parsedURL.search) || {};
      const mediaId = queryParams?.mediaId
        queryParams.format = 'SMIL';
        queryParams.formats = playerFeature?.fullScreenConfig?.playbackAssetFormat;
        queryParams.tracking = true;
        queryParams.clientId = uuid;
        queryParams.productId = productId;
        queryParams.userId = userInfo?.userId || '';
        queryParams.platform = 'web';
        const neededQueryParam =  {...queryParams};
        delete neededQueryParam.mediaId
        const queryString = utils.stringify(neededQueryParam)
        _smilUrl = `${appConfig.baseUrl}${ VERSION.smilPlayback}/playback/${mediaId}?${queryString}`
        releaseUrlRef.current = _smilUrl;
        // setReleaseUrl(_smilUrl)

        dispatch({ type: "SHOW_SPINNER", value: true })
        dispatch({ type: 'SET_SMIL_URL', value: _smilUrl})
        getParsedSMIL(_smilUrl).then((res) => {
          if(res) {
            dispatch({ type: "SHOW_SPINNER", value: false })
            dispatch({ type: 'SET_IS_FROM_RAILS', value: true})
              history.push(linkUri, { // sending smildata to player page as react router state
                details: details,
                selectedSmil: res?.getElementsByTagName('smil')?.[0]?.innerHTML,
                _smilUrl: _smilUrl,
              })
            // updateSmilData(res)
          } else {
            showError({ type: "UnknownSmilError", value: {
              onPrimaryBtnClick: () => history.push('/'),
              isPlayerError: false,
            }, });
            sendLog('UnknownSmilError', 'Player', {apiUrl: _smilUrl, errorStatus: 200})
          }
      }).catch((error = {}) => {
        dispatch({ type: 'SET_SMIL_URL', value: ''})
        dispatch({ type: "SHOW_SPINNER", value: false })
        setError(error);
        const el = document.createElement( 'html' );
        el.innerHTML = error?.responseBody
        /* --------------------------------------------- */
        const exceptionParam = el?.querySelector('param[name=\'exception\']')
        /* --------------------------------------------- */
        const exception = exceptionParam && exceptionParam.getAttribute('value')
        if(error?.errorCode) {
          if(error?.errorCode === "network_error") {
            showError({ type: "AppNoNetwork", value: {
                onPrimaryBtnClick: () => history.push('/'),
              isPlayerError: false,
            }, });
          }
        } else if(!exception) {
          showError({ type: "UnknownSmilError", value: {
            onPrimaryBtnClick: () => history.push('/'),
            isPlayerError: false,
          }, });
          sendLog('UnknownSmilError', 'Player', error)
          }
          let stringifyError = JSON.stringify(error)   
          updateSmilData(stringifyError)
        })
      // history.push(`${routeMap.player}?mediaId=${uid}`, { details })
      // if (type === routeVarMap.type.channel) {
      //   history.push(`${routeMap.player}?channelId=${uid}`)
      // } else if (type === routeVarMap.type.tournament) {
      //   history.push(`${routeMap.listing}?filterBy=tournament&filter=${uid}&sysId=${sysId}`)
      // } else {
      //   const noWhiteSpaceTitle = (title || '').replace(/\s/g, '-').replace(/[$&+,/:;=?@"<>'#%{}|\\^~[\]]/g, '')
      //   history.push(routeMap.details.replace(':pageName', typeMap[type]).replace(':type', typeMapId[type]).replace(':mediaId', `${uid}_${noWhiteSpaceTitle}`))
      // }
    } else {
      const query = `${routeMap.listing}?search=${window.encodeURIComponent(title)}&mode=${searchMode.toLowerCase()}&sysId=${sysId}`
      const isSearchErrorShown = (errorPage || {}).type === 'GenericNoSearch'
      if (isSearchErrorShown) {
        history.replace(query)
      } else {
        history.push(query)
      }
    }
   } 
  }, [appConfig.baseUrl, showError, selectedProfile,authenticate, isAuthenticated, sendLog, isMobileUser,setRecentSearch, handleSearchBackBtnClick, history, sysId, errorPage, sendAnalytics, searchText, dispatch, getParsedSMIL, playerFeature.fullScreenConfig.playbackAssetFormat, userInfo.userId])
  /* --------------------------------------------- */
  /**
   * It: Clears recent search from local.
   * It: Displays no recent search text.
   * It: Calls clear recent search api.
   */
  const handleClearAllClick = useCallback(() => {
    setRecentSearches([])
    setRecentSearchesLocal([])
    toggleNoRecentSearch(true)
    let profileId = selectedProfile ? selectedProfile.profileId : ''
    clearRecentSearch(appConfig.baseUrl, profileId).catch(() => { })
    /* --------------------------------------------- */
    // sending info log to loggly if recent search is cleared
    log({
      type: 'Info',
      attr: {
        module: 'Search',
        debug_message: `Recent search cleared`,
      }
    })
    /* --------------------------------------------- */
    searchInput.current.focus()
  }, [
    appConfig.baseUrl,
    setRecentSearches,
    clearRecentSearch,
    selectedProfile,
    log
  ])
  /* --------------------------------------------- */
  /**
   * It: Shows title for recent search & search suggetion.
   */
  const showTitle = useCallback(() => {
    if (searchText.trim().length < searchConfig.minimumSearchQueryLength) { //  && !/^\s/.test(searchText)
      if (recentSearches && recentSearches.length > 0) {
        return (
          <div className='suggestion-title'>
            <ListTitle className='search-heading' theme={searchHistoryTheme}>{appMessages.search_recent_search}</ListTitle>
            <ClearAll className='search-heading clear-all' theme={searchHistoryTheme} onClick={handleClearAllClick}>{appMessages.search_clear_all}</ClearAll>
          </div>
        )
      } else if (!recentSearches?.length || noRecentSearch) {
        return (
          <ListTitle theme={searchSuggestionTheme} className='search-heading'>{appMessages.search_no_recent_search}</ListTitle>
        )
      } else {
        return (
          <div className='spinner-main-wrapper'>
            <Spinner size={25} />
          </div>
        )
      }
    } else {
      if (suggestions.length > 0) {
        return (
          <ListTitle theme={searchSuggestionTheme} className='search-heading'>{appMessages.search_suggestion}</ListTitle>
        )
      } else if (noMatch) {
        return (
          <ListTitle theme={searchSuggestionTheme} className='search-heading'>{appMessages.search_no_results}</ListTitle>
        )
      } else if (searchText.trim().length >= searchConfig.minimumSearchQueryLength) { //  && !/^\s/.test(searchText)
        return (
          <div className='spinner-main-wrapper'>
            <Spinner size={25} />
          </div>
        )
      }
    }
  }, [
    noMatch,
    noRecentSearch,
    searchText,
    suggestions.length,
    searchHistoryTheme,
    handleClearAllClick,
    searchSuggestionTheme,
    recentSearches,
    appMessages.search_clear_all,
    appMessages.search_no_results,
    appMessages.search_suggestion,
    appMessages.search_recent_search,
    appMessages.search_no_recent_search,
    searchConfig.minimumSearchQueryLength
  ])
  /* --------------------------------------------- */
  /**
   * It: Shows recent search & search suggestion items.
   */
  const showItems = useCallback(() => {
    if (searchText.trim().length < searchConfig.minimumSearchQueryLength) { //  && !/^\s/.test(searchText)
      if (recentSearches && recentSearches.length > 0) {
        return (
          <ul>
            {
              recentSearches.map((searchText, i) => (
                <li key={i}>
                  <div onClick={() => handleRecentSearchClick(searchText)}>
                    <ListItemTitle theme={searchHistoryTheme} className='title'>{searchText}</ListItemTitle>
                  </div>
                </li>
              )).slice(0, searchConfig.maxSearchHistoryCount)
            }
          </ul>
        )
      }
    } else {
      if (suggestions.length > 0) {
        return (
          <ul>
            {
              suggestions.map((suggestion, i) => {
                let typeText = ''
                if (suggestion.searchMode === 'title') {
                  typeText = appMessages[`label_type_${suggestion.type}`]
                } else {
                  if (suggestion.searchMode === 'genre') typeText = appMessages.label_search_genre
                  else if (suggestion.searchMode === 'cast') typeText = appMessages.label_search_cast
                  else if (suggestion.searchMode === 'director') typeText = appMessages.label_search_director
                }
                return (
                  <li key={i}>
                    <div onClick={() => handleSearchSuggestionClick(suggestion.title, suggestion.type, suggestion.uid, suggestion.searchMode, suggestion)}>
                      {appConfig.type === 'Sports' && suggestion.subTitle && <ListItemSubtitle theme={searchSuggestionTheme}>{suggestion.subTitle}</ListItemSubtitle>}
                      <ListItemTitle theme={searchSuggestionTheme} className='title'>{suggestion.title}</ListItemTitle>
                      {
                        appConfig.type === 'Entertainment' && (
                          <div className='suggestion-list-item-bottom'>
                            {suggestion.searchMode === 'title'
                              ? (
                                <>
                                  {suggestion.type && <ListItemSubtitle theme={searchSuggestionTheme}>{typeText}</ListItemSubtitle>}
                                  {suggestion.genre && suggestion.genre.length > 0 && <ListItemSubtitle theme={searchSuggestionTheme}>{suggestion.genre[0]}</ListItemSubtitle>}
                                </>)
                              : suggestion.searchMode && <ListItemSubtitle theme={searchSuggestionTheme}>{typeText}</ListItemSubtitle>}
                          </div>
                        )
                      }
                    </div>
                  </li>
                )
              }).slice(0, searchConfig.maxSearchSuggestionCount)
            }
          </ul>
        )
      }
    }
  }, [
    suggestions,
    recentSearches,
    appConfig.type,
    searchText,
    searchHistoryTheme,
    searchSuggestionTheme,
    handleRecentSearchClick,
    handleSearchSuggestionClick,
    searchConfig.maxSearchHistoryCount,
    searchConfig.minimumSearchQueryLength,
    searchConfig.maxSearchSuggestionCount,
    appMessages
  ])
  /* --------------------------------------------- */
  /**
   * It: Passes search text to search page.
   * It: Redirects to search page.
   */
  const handleMoreClick = useCallback(() => {
    handleSearchIconClick()
  }, [
    handleSearchIconClick
  ])
  /* --------------------------------------------- */
  return (
    <StyleWrapper theme={searchBarTheme} isResponsiveSearchOpen={isResponsiveSearchOpen} isFocus={isFocus || searchText} className={`search-bar ${isResponsiveSearchOpen ? 'responsiveSearchOpen' : ''}`} ref={searchBar}>
      <SearchInputOuter theme={searchBarTheme} isFocus={isFocus || searchText} className={`search-bar__input ${(isFocus || searchText) ? 'search-bar__input---focus' : ''}`}>
        <form autoComplete='off' className='search-box-form'>
        <FloatingLabel theme={searchBarTheme} labelFocused className={`floting-label ${isSearchField ? 'label-focused' : ''}`}>{appMessages.label_search_hint}</FloatingLabel>
        <input
          ref={searchInput}
          theme={searchBarTheme}
          className='textbox'
          placeholder={!isFocus ? appMessages.label_search_hint : ""}
          value={searchText}
          onKeyPress={handleKeyPress}
          onChange={searchInputChangeHandler}
          autoComplete='off'
          onFocus={onFocusHandle}
          onBlur={onBlurHandle}
          spellCheck='false'
        />
        </form>
        <SearchIcon
          color={searchIconColor}
          onClick={handleSearchIconClick}
        />
      </SearchInputOuter>
      <div className='searchbar-res'>
        <div className='back-btn' onClick={handleSearchBackBtnClick}><GoBackArrowIcon color='black' style={{ width: 16, height: 16 }} /></div>
        <SearchIcon
          color={searchIconColor}
          onClick={resSearchClickhandle}
          style={{ width: 18, height: 18 }}
        />
      </div>
      { // && !/^\s/.test(searchText)
        isFocus  && ((searchText.trim().length < searchConfig.minimumSearchQueryLength && searchConfig.enableSearchHistory) || (searchText.length >= searchConfig.minimumSearchQueryLength && searchConfig.enableSearchSuggestions)) && (
          <SearchBarSuggestions isMobile={isMobile} isIos={isIOS} className='search-bar__suggestion'>
            <div className='suggestion-list'>
              {showTitle()}
              {showItems()}
              {isShowMore && searchText.length && suggestions.length ? <MoreResults theme={searchSuggestionTheme} onClick={handleMoreClick} className='show-more-label'>{appMessages.search_more_results}</MoreResults> : null}
            </div>
          </SearchBarSuggestions>
        )
      }
      {isFocus && <div className='search-bar__suggestion search-bar__suggestion-responsive' />}
    </StyleWrapper>
  )
}
/* --------------------------------------------- */
export default SearchBar
/* --------------------------------------------- */