/*eslint no-extend-native: [0]*/ // Added this line to disable warning for 'repeat' polyfill
/** Ployfill imports */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import '@webcomponents/webcomponentsjs';
import './utils/storagePolyfill'
/* --------------------------------------------- */
/** Slick css imports */
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/* --------------------------------------------- */
/** React & Redux imports */
import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
/* --------------------------------------------- */
/** RootReducer imports */
import RootReducer from './reducers/rootReducer'
/* --------------------------------------------- */
/** ServiceWorker imports */
import * as serviceWorker from './serviceWorker'
/* --------------------------------------------- */
/** App imports */
import App from './App'
/* --------------------------------------------- */
/** composedPath polyfill */
if (!Event.prototype.composedPath) {
  Event.prototype.composedPath = function () {
    if (this.path) {
      return this.path;
    }
    var target = this.target;
    this.path = [];
    while (target.parentNode !== null) {
      this.path.push(target);
      target = target.parentNode;
    }
    this.path.push(document, window);
    return this.path;
  }
}
/* --------------------------------------------- */
/** matches polyfill */
if (!Element.prototype.matches) {
  Element.prototype.matches =
    Element.prototype.matchesSelector ||
    Element.prototype.mozMatchesSelector ||
    Element.prototype.msMatchesSelector ||
    Element.prototype.oMatchesSelector ||
    Element.prototype.webkitMatchesSelector ||
    function (s) {
      var matches = (this.document || this.ownerDocument).querySelectorAll(s);
      var i = matches ? matches.length : 0;
      while (--i >= 0 && matches.item(i) !== this) { }
      return i > -1;
    };
}
/* --------------------------------------------- */
/** repeat polyfill */
if (!String.prototype.repeat) {
  String.prototype.repeat = function (count) {
    if (this == null)
      throw new TypeError('can\'t convert ' + this + ' to object');

    var str = '' + this;
    // To convert string to integer.
    count = +count;
    // Check NaN
    if (isNaN(count))
      count = 0;

    if (count < 0)
      throw new RangeError('repeat count must be non-negative');

    if (count === Infinity)
      throw new RangeError('repeat count must be less than infinity');

    count = Math.floor(count);
    if (str.length === 0 || count === 0)
      return '';

    // Ensuring count is a 31-bit integer allows us to heavily optimize the
    // main part. But anyway, most current (August 2014) browsers can't handle
    // strings 1 << 28 chars or longer, so:
    if (str.length * count >= 1 << 28)
      throw new RangeError('repeat count must not overflow maximum string size');

    var maxCount = str.length * count;
    count = Math.floor(Math.log(count) / Math.log(2));
    while (count) {
      str += str;
      count--;
    }
    str += str.substring(0, maxCount - str.length);
    return str;
  }
}
/* --------------------------------------------- */
/** Redux store creation */
const store = createStore(RootReducer, applyMiddleware(thunk))
/* --------------------------------------------- */
/** Redux App */
const reduxApp = (
  <Provider store={store}>
    <App />
  </Provider>
)
/* --------------------------------------------- */
/** Rendering App */
ReactDOM.render(reduxApp, document.getElementById('root'))
/* --------------------------------------------- */
/** Unregistering serviceworker */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
/* --------------------------------------------- */
