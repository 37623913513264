import { getNew, getNewObj } from '../utils/contentfulHandler'
import getPageComponent from './getPageComponent'
import getFilter from './getFilter'
import getTheme from './getTheme'
import getNavigation from './getNavigation'

// Helper function to form contentful tree
const getPage = (contentful, isCurated = false) => { // flag handled to resolve looping
  const pageComponentLocal = getPageComponent(contentful, isCurated)
  const filterLocal = getFilter(contentful)
  const themeLocal = getTheme(contentful)
  const navigationLocal = getNavigation(contentful, true)
  const pageLocal = contentful.page
  return pageLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      components = [], filter = {}, theme = [], popupTheme = [], navigation = []
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      components: getNew(components, pageComponentLocal),
      filter: getNewObj(filter, [filterLocal]),
      theme: getNew(theme, themeLocal),
      popupTheme: getNew(popupTheme, themeLocal),
      navigation: getNew(navigation, navigationLocal)
    }
  })
}

export default getPage
