export const size = {
  mobileS: 480,
  mobileL: 1024,
  laptop: 1440,
  laptopL: 1920,
  desktop: 4000
}
/* ---------------------------------------------------------------------------------------------------- */
export const device = {
  mobileS: '(max-width: 480px)',
  mobileL: '(min-width: 481px) and (max-width: 1024px)',
  laptop: '(min-width: 1025px) and (max-width: 1440px)',
  laptopL: '(min-width: 1441px) and (max-width: 1920px)',
  desktop: '(min-width: 1921px)'
}
/* ---------------------------------------------------------------------------------------------------- */

export const railItemWidth = {
  portrait: {
    small: {
      mobileS: 100 / 3.10,
      mobileL: 100 / 7.10,
      laptop: 100 / 8.10,
      laptopL: 100 / 8.10,
      desktop: 100 / 8.10
    },
    medium: {
      mobileS: 100 / 2.10,
      mobileL: 100 / 6.10,
      laptop: 100 / 7.10,
      laptopL: 100 / 7.10,
      desktop: 100 / 7.10
    },
    large: {
      mobileS: 100 / 1.10,
      mobileL: 100 / 5.10,
      laptop: 100 / 6.10,
      laptopL: 100 / 6.10,
      desktop: 100 / 6.10
    }
  },
  landscape: {
    small: {
      mobileS: 100 / 3.10,
      mobileL: 100 / 5.10,
      laptop: 100 / 5.10,
      laptopL: 100 / 5.10,
      desktop: 100 / 5.10
    },
    medium: {
      mobileS: 100 / 1.6,
      mobileL: 100 / 4.10,
      laptop: 100 / 4.10,
      laptopL: 100 / 4.10,
      desktop: 100 / 4.10
    },
    large: {
      mobileS: 100 / 1.10,
      mobileL: 100 / 3.10,
      laptop: 100 / 3.10,
      laptopL: 100 / 3.10,
      desktop: 100 / 3.10
    }
  },
  fill_width: {
    fill_width: {
      mobileS: 100 / 1,
      mobileL: 100 / 1,
      laptop: 100 / 1,
      laptopL: 100 / 1,
      desktop: 100 / 1
    }
  }
}
/* ---------------------------------------------------------------------------------------------------- */
export const railGridItemWidth = {
  portrait: {
    small: {
      mobileS: 100 / 3,
      mobileL: 100 / 7,
      laptop: 100 / 8,
      laptopL: 100 / 8,
      desktop: 100 / 8
    },
    medium: {
      mobileS: 100 / 2,
      mobileL: 100 / 6,
      laptop: 100 / 7,
      laptopL: 100 / 7,
      desktop: 100 / 7
    },
    large: {
      mobileS: 100 / 1,
      mobileL: 100 / 5,
      laptop: 100 / 6,
      laptopL: 100 / 6,
      desktop: 100 / 6
    }
  },
  landscape: {
    small: {
      mobileS: 100 / 3,
      mobileL: 100 / 4,
      laptop: 100 / 5,
      laptopL: 100 / 5,
      desktop: 100 / 5
    },
    medium: {
      mobileS: 100 / 2,
      mobileL: 100 / 3,
      laptop: 100 / 4,
      laptopL: 100 / 4,
      desktop: 100 / 4
    },
    large: {
      mobileS: 100 / 1,
      mobileL: 100 / 2,
      laptop: 100 / 3,
      laptopL: 100 / 3,
      desktop: 100 / 3
    }
  },
  fill_width: {
    fill_width: {
      mobileS: 100 / 1,
      mobileL: 100 / 1,
      laptop: 100 / 1,
      laptopL: 100 / 1,
      desktop: 100 / 1
    }
  }
}
/* ---------------------------------------------------------------------------------------------------- */
export const railItemLineHeight = {
  mobileS: 100 / 5,
  mobileL: 100 / 5,
  laptop: 100 / 5,
  laptopL: 100 / 5,
  desktop: 100 / 5
}
/* ---------------------------------------------------------------------------------------------------- */
export const railItemBorderRadius = {
  small: 10.10,
  medium: 10.10,
  large: 10.10,
  fill_width: 10.10
}
/* ---------------------------------------------------------------------------------------------------- */
export const centerModePadding = {
  portrait: {
    mobileS: 0,
    mobileL: 0,
    laptop: 0,
    laptopL: 1,
    desktop: 1
  },
  landscape: {
    mobileS: 0,
    mobileL: 0,
    laptop: 0,
    laptopL: 1,
    desktop: 1
  },
  fill_width: {
    mobileS: 0,
    mobileL: 0,
    laptop: 150,
    laptopL: 150,
    desktop: 150
  }
}
/* ---------------------------------------------------------------------------------------------------- */
export const railItemMarginRight = {
  portrait: {
    medium: {
      mobileS: 8,
      mobileL: 8,
      laptop: 15,
      laptopL: 15,
      desktop: 15
    }
  }
}

railItemMarginRight.landscape = { ...railItemMarginRight.portrait }
railItemMarginRight.fill_width = { fill_width: { ...railItemMarginRight.landscape.medium, mobileS: 0, mobileL: 0 } }

railItemMarginRight.portrait.small = { ...railItemMarginRight.portrait.medium }
railItemMarginRight.portrait.large = { ...railItemMarginRight.portrait.medium }

railItemMarginRight.landscape.small = { ...railItemMarginRight.landscape.medium }
railItemMarginRight.landscape.large = { ...railItemMarginRight.landscape.medium }
/* ---------------------------------------------------------------------------------------------------- */
