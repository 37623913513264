import styled from 'styled-components'
import utils from '../../utils/utils'

// Styles components for GetAppPopup component.
/* ---------------------------------------------  */
export const ImgContainer = styled.div`
  ${({ images }) => {
    if (!images) return
    const img = utils.getCloserMatchImage(640, 360, images)
    return `
            background-image: url("${img && img.url}");
        `
  }}
`
/* --------------------------------------------- */
export const GetAppPopupContainer = styled.div`
  .two-action-popup-wrapper__content {
    background: ${({ theme: { body } }) => body && body.background.primary};
  }

  h2 {
    color: ${({ theme: { header } }) => header && header.text.primary};
  }

  .data h2 {
    color: ${({ theme: { header } }) => header && header.text.secondary};
  }

  p {
    color: ${({ theme: { body } }) => body && body.text.secondary};
  }

`
/* --------------------------------------------- */
export const DetailsPageBadges = styled.ul`
  & p, & span{
    color: ${({ metaTheme: { header } }) => header && header.text.tertiary};
  }

  & li {
    border: solid 2px ${({ metaTheme: { header } }) => header && header.text.tertiary};
    color: ${({ metaTheme: { header } }) => header && header.text.tertiary};
  }

  & .filled {
    background: ${({ metaTheme: { header } }) => header && header.text.tertiary};
    color: ${({ metaTheme: { header } }) => header && header.text.tertiary};
  }
`
/* --------------------------------------------- */