import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
/* --------------------------------------------- */
import playImg from '../../imgs/ic_details_play.png'
/* --------------------------------------------- */
import { PlayerContext } from '../../context/player.context'
import { GlobalContext } from '../../context/global.context'
import { ImgContainer } from './style'
/* --------------------------------------------- */
// PlayerPlaceholder component for showing player placeholder
const PlayerPlaceholder = ({ thisIsChannel,
  playBtnClickHandler, images, isShowPlayBtn, showTwoByThreeImage

}) => {
  const [ isImageLoading, setIsImageLoading ] = React.useState(true)
  const { onPlayClick = null } = useContext(PlayerContext)
  const { isThisCREATE } = useContext(GlobalContext)
  /**
   * It: Handles play click.
   */
  const playBtnClickHandlerFun = () => {
    if (onPlayClick) {
      onPlayClick()
    } else {
      playBtnClickHandler(true)
    }
  }
  const onImageLoad = () =>{
    setIsImageLoading(false);
  }
  /* --------------------------------------------- */
  return (
    <>
      <ImgContainer {...{ images, thisIsChannel, isThisCREATE, onImageLoad, showTwoByThreeImage }} className='img-holder__img-container'>
        {isShowPlayBtn && (
          <div className='No-player-screen' onClick={playBtnClickHandlerFun}>
            <img src={playImg} alt='' />
          </div>
        )}
      </ImgContainer>
      {isImageLoading ?<SkeletonTheme color='#727272' highlightColor='#969696'>
             <Skeleton height="100%" width='100%'duration={2} className="image-loader-skeleton"/>
         </SkeletonTheme>:null}
    </>
  )
}


/* --------------------------------------------- */
PlayerPlaceholder.propTypes = {
  playBtnClickHandler: PropTypes.func,
  images: PropTypes.array,
  isShowPlayBtn: PropTypes.bool
}
/* --------------------------------------------- */
PlayerPlaceholder.defaultProps = {
  playBtnClickHandler: () => { },
  images: [],
  isShowPlayBtn: false
}
/* --------------------------------------------- */
export default PlayerPlaceholder
