import { REVENUE_CONFIG } from '../constants/actionTypes'
import { mergeThemes, getThemeData } from '../utils/contentfulThemeMerger'
import { filter } from '../utils/contentfulHandler'
import utils from '../utils/utils'

/** RevenueConfig store */
export const fetchRevenueConfig = (revenue = [], options, themeData = {}) => {
  const { appThemeData, componentThemeData } = themeData || {}
  return (dispatch) => {

    let revenueMode = []
    let productListingPage = {}
    let productDetailsPage = []
    let purchaseHistoryPage = []
    let checkoutPage = []
    let allowedPaymentMethods = []

    revenue = filter(revenue, '', options) || []

    if (utils.isNotNull(revenue, 0)) {
      const revenueConfig = revenue[0]

      if (utils.isNotNull(revenueConfig, 'revenueMode')) {
        revenueMode = revenueConfig.revenueMode
      }

      if (utils.isNotNull(revenueConfig, 'productListingPage')) {
        productListingPage = parsePage(revenueConfig.productListingPage, options, appThemeData, componentThemeData)
      }

      if (utils.isNotNull(revenueConfig, 'productDetailsPage')) {
        productDetailsPage = parsePage(revenueConfig.productDetailsPage, options, appThemeData, componentThemeData)
      }

      if (utils.isNotNull(revenueConfig, 'purchaseHistoryPage')) {
        purchaseHistoryPage = parsePage(revenueConfig.purchaseHistoryPage, options, appThemeData, componentThemeData)
      }

      if (utils.isNotNull(revenueConfig, 'checkoutPage')) {
        checkoutPage = parsePage(revenueConfig.checkoutPage, options, appThemeData, componentThemeData)
      }

      if (utils.isNotNull(revenueConfig, 'allowedPaymentMethods')) {
        allowedPaymentMethods = revenueConfig.allowedPaymentMethods
      }
    }

    dispatch({
      type: REVENUE_CONFIG,
      value: {
        revenueMode,
        productListingPage,
        productDetailsPage,
        purchaseHistoryPage,
        checkoutPage,
        allowedPaymentMethods
      }
    })

    return Promise.resolve()
  }
}


function parsePage(pageArr, options, appThemeData, componentThemeData) {
  pageArr = pageArr || []
  options = options || {}
  appThemeData = appThemeData || {}
  componentThemeData = componentThemeData || {}

  let page = filter(pageArr, '', options)[0] || {}

  let theme = appThemeData
  let components = {}

  if (utils.isNotNull(page, 'theme')) {
    theme = filter(page.theme, '', options)[0] || []
    theme = getThemeData(theme, options)
    theme = mergeThemes(appThemeData, theme)
  }

  if (utils.isNotNull(page, 'components')) {
    components = filter(page.components, '', options)[0] || {}

    let theme = componentThemeData
    let componentStyle = {}

    if (utils.isNotNull(components, 'theme')) {
      theme = filter(components.theme, '', options)[0] || {}
      theme = getThemeData(theme, options)
      theme = mergeThemes(componentThemeData, theme)
    }

    if (utils.isNotNull(components, 'componentStyle')) {
      componentStyle = filter(components.componentStyle, '', options)[0] || {}
    }

    components = {
      ...components,
      theme,
      componentStyle
    }
  }

  page = {
    ...page,
    theme,
    components
  }
  return page
}
