import React, { useContext } from 'react'
import { GlobalContext } from '../../../context/global.context'
import { ErrorContext } from "../../../context/error.context";
/* ---------------------------------------------------------------------------------------------------- */
export const Context = ErrorContext
/* ---------------------------------------------------------------------------------------------------- */
// ErrorPage component's context.
const Provider = (props) => {
  const contextProps = useContext(Context)
  const globalContextProps = useContext(GlobalContext)
  const errorDispatch = contextProps.dispatch
  const globalDispatch = globalContextProps.dispatch
  /* -------------------------------------------------------------------------------------------------- */
  return (
    <Context.Provider value={{ ...contextProps, ...globalContextProps, ...props.value, errorDispatch, globalDispatch }}>
      {props.children}
    </Context.Provider>
  )
}
/* ---------------------------------------------------------------------------------------------------- */
export default Provider
/* ---------------------------------------------------------------------------------------------------- */
