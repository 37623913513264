import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getFaqItem from './getFaqItem'
import getTheme from './getTheme'

// Helper function to form contentful tree
const getErrorConfiguration = (contentful) => {
  const filterLocal = getFilter(contentful)
  const faqItemLocal = getFaqItem(contentful)
  const themeLocal = getTheme(contentful)
  const errorConfigurationLocal = contentful.errorConfiguration
  return errorConfigurationLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { filter = {}, faqItem = [], theme = [] } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      faqItem: getNew(faqItem, faqItemLocal),
      theme: getNew(theme, themeLocal)
    }
  })
}

export default getErrorConfiguration
