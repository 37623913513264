import { PLAYER_FEATURE, SUPPORT_INFORMATION, CATALOG_FEATURE, REVENUE_FEATURE, REVENUE_CONFIG, INTRO_CONFIG, INTRO_POP_UP_VISIBILITY, ADVERTISEMENT_FEATURE } from '../constants/actionTypes'
import inialState from './initalState'
/* --------------------------------------------- */
/** Features config reducer */
const featuresReducers = (state = inialState, action) => {
  switch (action.type) {
    case PLAYER_FEATURE:
      return {
        ...state,
        playerFeature: action.value,
        isFeatureConfigRecieved: true
      }
    case SUPPORT_INFORMATION:
      return {
        ...state,
        supportInformation: action.value
      }
    case CATALOG_FEATURE:
      return {
        ...state,
        catalogFeature: action.value
      }
    case REVENUE_FEATURE:
      return {
        ...state,
        revenueFeature: action.value
      }
    case REVENUE_CONFIG:
      return {
        ...state,
        [REVENUE_CONFIG]: action.value
      }
    case INTRO_CONFIG:
      return {
        ...state,
        [INTRO_CONFIG]: action.value
      }
    case INTRO_POP_UP_VISIBILITY:
      return {
        ...state,
        introConfig: { ...state.introConfig, showIntroPopUp: action.value }
      }
    case ADVERTISEMENT_FEATURE:
      return {
        ...state,
        advertisementFeature: action.value
      }
    default: return state
  }
}
/* --------------------------------------------- */
export default featuresReducers
/* --------------------------------------------- */
