import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { version } from '../../../config'
import ErrorFullscreen from './ErrorFullscreen'
import Provider from './context'
import { Wrapper } from './style'
import _utils from '../../../utils/utils'
/* ---------------------------------------------------------------------------------------------------- */
// Wrapper component for ErrorFullscreen component.
const ErrorFullscreenWrapper = () => {
    const { appMessages } = useSelector(store => store.appConfigReducers)
    const fullscreenPopupRef = useRef(null)
    useEffect(()=>{
        let contentEl = fullscreenPopupRef.current;
        _utils.disableScroll(contentEl, { reserveScrollBarGap: true });
        return ()=> {
            _utils.enableScroll(contentEl);
        }
    },[])
    return (
        <Provider value={{ appMessages, version }}>
            <Wrapper ref={fullscreenPopupRef}>
                <ErrorFullscreen />
            </Wrapper>
        </Provider>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
export default ErrorFullscreenWrapper
/* ---------------------------------------------------------------------------------------------------- */
