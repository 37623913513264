import { useCallback, useEffect, useRef, useContext } from 'react'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
import useGeneralApi from './useGeneralApi'
import useAnalytics from '../containers/useAnalytics'
import useErrorHandler from '../containers/useErrorHandler'
import useLoggly from '../containers/useLoggly'
import { GlobalContext } from '../context/global.context'
import { getUserAuthInfo, setUserAuthInfo, setSelectedProfile, setEmail, getEmail } from '../utils/localStorageService'
import { getFormattedTokenInBase64 } from '../utils/APIUtilFunctions'
/* --------------------------------------------- */
import VERSION from '../constants/apiVersionMap'
/* --------------------------------------------- */
const useAuthApi = () => {
  const aLocalRef = useRef()
  const isSharedRef = useRef(false)
  const authConfigRef = useRef(null)
  const { sendAnalytics } = useAnalytics()
  const { handleError } = useErrorHandler()
  const { log } = useLoggly()
  const { isAuthenticated, isShared, userToken, userInfo, dispatch } = useContext(GlobalContext)
  /* --------------------------------------------- */
  const { appConfig } = useSelector(store => store.appConfigReducers)
  const { userManagementConfig = {} } = useSelector(store => store.userManagement)
  const { authConfig: userAuthConfig = [] } = userManagementConfig || {}
  /* --------------------------------------------- */
  useEffect(() => {
    const authConfig = userAuthConfig.filter((config) => config.identityProvider === 'vdt')
    if (authConfig.length > 0) {
      authConfigRef.current = authConfig[0]
    } else if (authConfig.length === 0) {
      // use the first config if none is set
      authConfigRef.current = userAuthConfig[0]
    }
  }, [
    userAuthConfig
  ])
  /* --------------------------------------------- */
  const userInfoRef = useRef(userInfo)
  const userTokenRef = useRef(userToken)
  const isAuthenticatedRef = useRef(isAuthenticated)
  /* --------------------------------------------- */
  const {
    getData,
    putData,
    postData,
    deleteData
  } = useGeneralApi()
  /* --------------------------------------------- */
  useEffect(() => {
    const authInfoLocal = getUserAuthInfo()
    aLocalRef.current = { ...authInfoLocal, ...userInfo }
  }, [userInfo])
  /* --------------------------------------------- */
  useEffect(() => {
    isSharedRef.current = isShared
  }, [isShared])
  /* --------------------------------------------- */
  useEffect(() => {
    userInfoRef.current = userInfo
  }, [userInfo])
  /* --------------------------------------------- */
  useEffect(() => {
    userTokenRef.current = userToken
  }, [userToken])
  /* --------------------------------------------- */
  useEffect(() => {
    isAuthenticatedRef.current = isAuthenticated
  }, [isAuthenticated])
  /* --------------------------------------------- */

  const getUserDetails = useCallback((baseUrl) => {
    const headers = {}
    // const Authorization = getFormattedTokenInBase64(userInfoRef?.current?.userId, userTokenRef?.current)
    const Authorization = `Bearer ${userTokenRef.current}`
    if (Authorization) headers.Authorization = Authorization
    const url = `${baseUrl}${VERSION.userDetails}/accounts`
    return getData(url, {}, headers)
      .then((res = {}) => {
        const { subscription = {} } = res
        const { subscriptionType = null } = subscription
        return {
          ...res,
          ...subscription,
          subscriptionType: subscriptionType ? subscriptionType.toLowerCase() : null
        }
      })
  }, [getData])
  /* --------------------------------------------- */
  const updateAccountAPI = useCallback((baseUrl, body, headersParams) => {
    const url = `${baseUrl}${VERSION.updateAccountDetails}/accounts`
    const headers = {}
    const Authorization = `Bearer ${userTokenRef.current}`
    if (Authorization) headers.Authorization = Authorization
    return putData(url, body, headers)
  }, [putData])
  /* --------------------------------------------- */
  const getAccountDetails = useCallback((baseUrl, headersParams) => {
    const url = `${baseUrl}${VERSION.getAccountDetails}/accounts`
    const headers = {}
    const Authorization = `Bearer ${userTokenRef.current}`
    if (Authorization) headers.Authorization = Authorization
    return getData(url, {}, headers)
  }, [getData])
  /* --------------------------------------------- */

  const handlePostLogin = useCallback((res, accessTokenRefreshInterval, logoutExtendPeriod, identityProvider) => {
    return new Promise((resolve) => {
      const { token, userId, userName, profiles, consumerToken, authInfo, name, fullname, idToken } = res || {}
      dispatch({ type: 'USER_TOKEN', value: idToken })
      if (res?.personaToken) { window.store.localStorage.setItem('selected-profile', JSON.stringify(profiles?.[0])) }
      let { duration } = res || {}
      duration = duration * 1000
      const now = Date.now()
      const loginSuccessTime = now

      const refreshInterval = Math.min(duration, accessTokenRefreshInterval)
      let nextRefreshTime = now + refreshInterval
      //removed logoutExtendPeriod value setting as next refresh interval
      resolve({
        ...res,
        token,
        userId,
        userName,
        duration,
        loginSuccessTime,
        nextRefreshTime,
        identityProvider,
        logoutExtendPeriod,
        refreshInterval,
        consumerToken,
        profiles,
        authInfo,
        name,
        fullname
      })
    })
  }, [dispatch])

  const setAuthInfo = useCallback((authInfo, baseUrl, identityProvider) => {
    const token = authInfo.idToken || authInfo.personaToken
    const profiles = authInfo.profiles || []
    const userid = authInfo.userId || ''
    // Start engage user tracking
    // adding try catch on engage user tracking
    try{
      if (window.ENGAGE) {
        window.ENGAGE.identify({
          userId: userid,
          name: authInfo.fullname,
          email: authInfo.name,
          status: 'registered'
        })
      }
    } catch(err){
      log({
        type: 'Info', attr: { //sending info log if engage tracking has any issue
            module: 'General',
            debug_message: `Error while starting engage tracking: ${err? err: ''}`,
        }
      })
    }

    setUserAuthInfo({
      duration: authInfo.duration || 0,
      loginSuccessTime: authInfo.loginSuccessTime || 0,
      nextRefreshTime: authInfo.nextRefreshTime || 0,
      identityProvider: authInfo.identityProvider || '',
      logoutExtendPeriod: authInfo.logoutExtendPeriod || 0,
      refreshInterval: authInfo.refreshInterval || 0,
      authInfo: authInfo.authInfo || '',
      userId: authInfo.userId,
      idToken: token,
    })
    dispatch({ type: 'USER_TOKEN', value: token })
    const uDetails = userDetails(authInfo)
    dispatch({ type: 'USER_INFO', value: uDetails })
    let biEventData = uDetails
    if (profiles?.length) {
      // const firstProfile = profiles.filter(({ isAdmin }) => isAdmin)[0]
      // const profileId = firstProfile.profileId || ''
      // return selectProfileAPI(baseUrl, { platform: 'web' }, { profileId, userId: userid })
      //   .then((res) => {
      //     res = res || {}
      // const token = res.token || ''
      // dispatch({ type: 'USER_TOKEN', value: token || '' })

      //     const profile = (res.profile || [])[0] || {}
      //     const profileType = profile.type === 'Kid' ? 'kid' : 'normal'
      //     // setSelectedProfile(profile)
      //     dispatch({ type: 'SELECTED_PROFILE', value: profile })

      return getAccountDetails(baseUrl, { userId: userid, token })
        .then((res) => {
          const uDetails = userDetails(authInfo)
          const userData = { ...userInfo, ...uDetails, ...res, token, identityProvider, authInfo }
          dispatch({ type: 'USER_TOKEN', value: authInfo.idToken })
          dispatch({ type: 'USER_INFO', value: userData })
          setUserAuthInfo({
            ...userData,
            duration: authInfo.duration || 0,
            loginSuccessTime: authInfo.loginSuccessTime || 0,
            nextRefreshTime: authInfo.nextRefreshTime || 0,
            identityProvider: authInfo.identityProvider || '',
            logoutExtendPeriod: authInfo.logoutExtendPeriod || 0,
            refreshInterval: authInfo.refreshInterval || 0,
            authInfo: authInfo.authInfo || '',
            userId: authInfo.userId
          })
          biEventData = userData
          sendAnalytics({ type: '_user.login', attr: biEventData })
        })
        // })
    }
  }, [dispatch, getAccountDetails, userInfo, sendAnalytics, log])
  /* --------------------------------------------- */
  /* --------------------------------------------- */

  const deleteProfileAPI = useCallback((baseUrl, body, urlParams) => {
    const url = `${baseUrl}${VERSION.deleteProfile}/accounts/profiles/profile/${urlParams.profileId}?userId=${urlParams.userId}`
    const headers = {}
    const Authorization = `Bearer ${userTokenRef.current}`
    if (Authorization) headers.Authorization = Authorization
    return deleteData(url, {}, headers)
  }, [deleteData])
  /* --------------------------------------------- */
  const selectProfileAPI = useCallback((baseUrl, body, urlParams) => {
    const { profileId, userId } = urlParams
    const url = `${baseUrl}${VERSION.selectProfile}/accounts/profiles/profile/${profileId}?userId=${userId}`
    return postData(url, body, {}, {
      // withCredentials: true
    })
  }, [postData])
  /* --------------------------------------------- */
  const updateProfileAPI = useCallback((baseUrl, body, params) => {
    const url = `${baseUrl}${VERSION.updateProfile}/accounts/profile/${params.profileId}?userId=${params.userId}`
    const headers = {}
    const Authorization = `Bearer ${userTokenRef.current}`
    if (Authorization) headers.Authorization = Authorization
    return putData(url, body, headers)
  }, [putData])
  /* --------------------------------------------- */
  /* --------------------------------------------- */
  const refreshTrustedLogin = useCallback((forceRefresh, userId) => {
    const baseUrl = appConfig.baseUrl
    const authConfig = authConfigRef.current || {}
    const accessTokenRefreshInterval = (authConfig.accessTokenRefreshInterval || 3600) * 1000
    let authInfoLocal = getUserAuthInfo()
    const { loginSuccessTime, identityProvider, logoutExtendPeriod, refreshInterval, duration, authInfo } = authInfoLocal
    if (!baseUrl || !identityProvider) return Promise.reject() //retrun if no identityprovider or baseurl
    authInfoLocal = { loginSuccessTime, identityProvider, logoutExtendPeriod, refreshInterval, duration }
    const now = Date.now()
    let nextRefreshTime = now + refreshInterval
    const logoutExtendPeriodTime = loginSuccessTime + (authConfig.logoutExtendPeriod * 1000) // handle scenario when logoutextentPeriod is modified in local storage
    const url = `${baseUrl}${VERSION.refreshTrustedLogin}/accounts/trustedLogin`
    setUserAuthInfo({ identityProvider }, { merge: true })
    return postData(url, {
      forceRefresh,
      platform: 'web',
      identityProvider,
      authMode: 'refreshToken',
      personas: false,
      authInfo
    }, {}, {
      withCredentials: true
    }, {
      isHideErrorScreens: true,
      trustedLogin: true,
      userId: userId,
    }).then((res) => {
      const {
        token = '',
        userId = '',
        userName = '',
        profiles = [],
        consumerToken = '',
        idToken = ''
      } = res
      // console.log
      let { duration } = res || {}
      duration = duration * 1000
      const now = Date.now()
      const loginSuccessTime = now
      const refreshInterval = Math.min(duration, accessTokenRefreshInterval)
      let nextRefreshTime = now + refreshInterval
      // const apiError = new Error('Testing refreshTrustedLogin api failure')
      // apiError.responseBody = { token }
      // throw apiError
      return {
        ...res,
        ...authInfoLocal,
        token,
        userId,
        userName,
        duration,
        loginSuccessTime,
        nextRefreshTime,
        refreshInterval,
        consumerToken,
        profiles,
        idToken,
      }
    }).catch((error) => {
      error = error || {}
      const responseBody = error.responseBody || {}
      const token = responseBody.token || userTokenRef.current
      const subscription = responseBody.subscription || {}
      const userId = responseBody.userId || ''
      if (now > logoutExtendPeriodTime || !token) {
        dispatch({ type: 'USER_INFO', value: {} })
        let errorMessage = ''
        if (now > logoutExtendPeriodTime) {
          errorMessage = 'Time Exceeded.'
        } else if (!token) {
          errorMessage = 'Login: Token Missing.'
        }
        const timeExceededError = new Error(errorMessage)
        timeExceededError.errorCode = 7001
        timeExceededError.errorMessage = errorMessage
        timeExceededError.apiUrl = url
        timeExceededError.errorStatus = error.errorStatus || ''
        timeExceededError.requestBody = error.requestBody || ''
        timeExceededError.responseId = error.responseId || ''
        timeExceededError.responseBody = error.responseBody || ''
        handleError(timeExceededError, {}, {
          error,
          isTokenPresent: !!token,
          authInfoLocal: { ...authInfoLocal, nextRefreshTime }
        })
        return Promise.reject(timeExceededError)
      } else {
        return { ...authInfoLocal, nextRefreshTime, token, subscription, userId }
      }
    }).then((validAuthInfo) => {
      const validToken = validAuthInfo.idToken || ''
      // const userId = authInfo.userId || ''
      // const profiles = authInfo.profiles || []
      const userAuthInfo = {
        ...validAuthInfo
      }
      // if (profiles) {
      //   let localSelectedProfile = getSelectedProfile()
      //   if (localSelectedProfile) {
      //     localSelectedProfile = profiles.filter(({ profileId }) => localSelectedProfile.profileId === profileId)
      //     localSelectedProfile = localSelectedProfile[0] ? localSelectedProfile[0] : null
      //   }
      //   const firstProfile = localSelectedProfile || profiles.filter(({ isAdmin }) => isAdmin)[0]
      //   const profileId = firstProfile.profileId || ''
      //   return selectProfileAPI(baseUrl, { platform: 'web' }, { profileId, userId })
      //     .then((res) => {
      //       res = res || {}
      //       const token = res.idToken || ''
      //       dispatch({ type: 'USER_TOKEN', value: token || '' })

      //       const profile = (res.profile || [])[0] || {}
      //       const profileType = profile.type === 'Kid' ? 'kid' : 'normal'
      //       setSelectedProfile(profile)
      //       dispatch({ type: 'SELECTED_PROFILE', value: profile })

      //       return getAccountDetails(baseUrl, { userId, token })
      //         .then((res) => {
      //           const uDetails = userDetails(authInfo)
      //           const userData = { ...userInfo, ...uDetails, ...res, token, profileType, identityProvider }
      //           dispatch({ type: 'USER_TOKEN', value: token })
      //           dispatch({ type: 'USER_INFO', value: userData })
      //           sendAnalytics({ type: '_user.login', attr: userData })
      //           setUserAuthInfo({ ...userData, ...userAuthInfo })
      //         })
      //     })
      // }
      setUserAuthInfo(userAuthInfo)
      dispatch({ type: 'USER_TOKEN', value: validToken })
      const uDetails = userDetails(validAuthInfo)
      dispatch({ type: 'USER_INFO', value: uDetails })
      sendAnalytics({ type: '_user.login', attr: uDetails })
      log({ type: 'Debug', attr: { module: 'Authentication', debug_message: 'Refresh Login Success' } }) //sending info log on refresh trusted login success
      return uDetails
    })
  }, [dispatch, log, postData, handleError, appConfig.baseUrl, sendAnalytics])
  /* --------------------------------------------- */
  const verifySession = useCallback((baseUrl, callback, options = {}) => {
    const now = Date.now()
    const isShared = isSharedRef.current
    const userInfo = userInfoRef.current
    // const loginSuccessTime = userInfo.loginSuccessTime
    // const logoutExtendPeriod = userInfo.logoutExtendPeriod
    const nextRefreshTime = userInfo.nextRefreshTime
    // const authInfo = userInfo.authInfo || ''
    const isAuthenticated = isAuthenticatedRef.current
    // const Authorization = userTokenRef.current
    if (!isAuthenticated) {
      return callback()
    }
    // const isExceededLogoutExtendPeriod = now > (loginSuccessTime + logoutExtendPeriod)
    const isExceededNextRefreshTime = now > nextRefreshTime
    // if (!isShared &&
    //   (userInfo.identityProvider !== 'mpx' || !authInfo) &&
    //   (isExceededLogoutExtendPeriod || (userInfo.identityProvider !== 'mpx' && !Authorization)) &&
    //   !options.isSkipSessionError) {
    //   const tokenMissingError = new Error('Token Missing.')
    //   tokenMissingError.errorCode = 7001
    //   handleError(tokenMissingError, {}, {
    //     baseUrl,
    //     isShared,
    //     loginSuccessTime,
    //     logoutExtendPeriod,
    //     errorOn: 'verifySession',
    //     isExceededLogoutExtendPeriod,
    //     identityProvider: userInfo.identityProvider,
    //     isSkipSessionError: options.isSkipSessionError,
    //     Authorization: !Authorization,
    //     authInfo: !authInfo,
    //     url: options.url
    //   })
    //   return Promise.reject(tokenMissingError)
    // }

    if (isShared || !isExceededNextRefreshTime) {
    return callback()
    } else {
      return refreshTrustedLogin(true).then(() => callback())
    }
  }, [refreshTrustedLogin])
  /* --------------------------------------------- */
  const trustedLogin = useCallback((baseUrl, { code, identityProvider, logoutExtendPeriod, accessTokenRefreshInterval, redirectUri }, headers = {}) => {
    logoutExtendPeriod = logoutExtendPeriod * 1000
    accessTokenRefreshInterval = accessTokenRefreshInterval * 1000
    const tokenDuration = (logoutExtendPeriod + accessTokenRefreshInterval) / 1000
    const url = `${baseUrl}${VERSION.trustedLogin}/accounts/trustedLogin`
    let params = {}
    if (identityProvider === 'cognito') {
      params = {
        platform: 'web',
        authMode: 'authCode',
        identityProvider: 'cognito',
        personas: false
      }
    } else {
      params = {
        code,
        tokenDuration,
        platform: 'web',
        identityProvider,
        authMode: 'authCode',
        redirectUrl: redirectUri,
        personas: false
      }
    }
    setUserAuthInfo({ identityProvider })
    return postData(url, params, headers, {
      withCredentials: true
    }, {
      isInitialTrustedLogin: true,
      trustedLogin: true
    }).then((res) => {
      handlePostLogin(res, accessTokenRefreshInterval, logoutExtendPeriod, identityProvider)
        .then((authInfo) => {
          setAuthInfo(authInfo, baseUrl, identityProvider)
        })
    })
  }, [
    // dispatch,
    postData,
    // sendAnalytics,
    // selectProfileAPI,
    // getAccountDetails,
    // userInfo,
    handlePostLogin,
    setAuthInfo
  ])

  const tvDeviceLogin = useCallback((baseUrl, { code }, headers = {}) => {
    if (!baseUrl) return Promise.reject()
    const url = `${baseUrl}${VERSION.trustedLogin}/accounts/trustedLogin`
    let params = {}
    const authInfoLocal = getUserAuthInfo()
    const identityProvider = authInfoLocal.identityProvider
    if (identityProvider === 'cognito') {
      params = {
        forceRefresh: true,
        platform: 'web',
        authMode: 'deviceCode',
        identityProvider: 'cognito',
        code: authInfoLocal.userId,
        user_code: code,
        personas: false
      }
    } else {
      params = {
        forceRefresh: true,
        platform: 'web',
        identityProvider,
        authMode: 'deviceCode',
        code: authInfoLocal.userId,
        user_code: code,
        personas: false
      }
    }

    return postData(url, params, headers,
      { withCredentials: true }, {})
  }, [
    postData
  ])

  const createProfileAPI = useCallback((baseUrl, body, headerParams) => {
    const url = `${baseUrl}${VERSION.createProfile}/accounts/profile`
    const headers = {}
    const Authorization = getFormattedTokenInBase64(headerParams.profileId, headerParams.token)
    if (Authorization) headers.Authorization = Authorization
    return postData(url, body, headers)
  }, [postData])
  /* --------------------------------------------- */
  const mpxLogin = useCallback((baseUrl, { userName, password, identityProvider, authInfo }) => {
    const url = `${baseUrl}v1b2/accounts/login`
    const headers = {}
    const Authorization = getFormattedTokenInBase64(userName, password)
    if (Authorization) headers.Authorization = Authorization

    setUserAuthInfo({ identityProvider })

    return postData(url, {}, headers).then((res = {}) => {
      const now = Date.now() / 1000
      let userInfo = {
        loginSuccessTime: now,
        identityProvider,
        ...res
      }
      const profiles = userInfo.profiles || []
      const userId = userInfo.userId || ''

      const firstProfile = profiles.filter(({ isAdmin }) => isAdmin)[0]
      const profileId = firstProfile.profileId || ''

      return selectProfileAPI(baseUrl, { platform: 'web' }, { profileId, userId })
        .then((res) => {
          res = res || {}
          const token = res.idToken || ''
          dispatch({ type: 'USER_TOKEN', value: token || '' })

          const profile = (res.profile || [])[0] || {}
          const profileType = profile.type === 'Kid' ? 'kid' : 'normal'
          setSelectedProfile(profile)
          dispatch({ type: 'SELECTED_PROFILE', value: profile })

          return getAccountDetails(baseUrl, { userId, token })
            .then((res) => {
              userInfo = { ...userInfo, ...res, token, profileType, identityProvider }
              dispatch({ type: 'USER_INFO', value: userInfo })
              setUserAuthInfo(userInfo)
            })
        })
    })
  }, [dispatch, postData, getAccountDetails, selectProfileAPI])
  /* --------------------------------------------- */
  const login = useCallback((baseUrl, { userName, password, identityProvider, accessTokenRefreshInterval, logoutExtendPeriod, authInfo }) => {
    if (identityProvider === 'cognito') {
      return trustedLogin(baseUrl, {
        authMode: 'authCode',
        identityProvider,
        accessTokenRefreshInterval,
        logoutExtendPeriod
      }, {
        Authorization: getFormattedTokenInBase64(userName, password)
      })
    } else if (identityProvider === 'mpx') {
      return mpxLogin(baseUrl, { userName, password, identityProvider, authInfo })
    }
    return Promise.reject(new Error('Unknown identityProvider'))
  }, [
    trustedLogin, mpxLogin
  ])
  /* --------------------------------------------- */
  const logout = useCallback((baseUrl) => {
    const url = `${baseUrl}${VERSION.logout}/accounts/logout`
    const Authorization = userTokenRef.current
    if (!Authorization) {
      const sessionError = new Error('Session Token Missing')
      return Promise.reject(sessionError)
    }
    sendAnalytics({
      type: '_user.logout',
      attr: {
        user_id: userInfo.userId,
        user_email_hash: userInfo.emailHash,
        user_type: userInfo.customerType,
        user_source: userInfo.source
      }
    })
    return postData(url, {}, { Authorization }, {
      // withCredentials: true
    })
      .then(() => {
        dispatch({ type: 'USER_INFO', value: {} })
        dispatch({ type: 'UNAUTHENTICATED' })
      })
  }, [
    dispatch,
    postData,
    sendAnalytics,
    userInfo
  ])
  /* --------------------------------------------- */
  const activateAccount = useCallback((baseUrl, { email }) => {
    const url = `${baseUrl}${VERSION.activateAccount}/accounts/email/token`
    return postData(url, { token_type: 'registration', login: email })
  }, [postData])
  /* --------------------------------------------- */
  const activateSession = useCallback((baseUrl, { token }) => {
    const url = `${baseUrl}${VERSION.activateSession}/accounts/activate`
    return postData(url, { token, start_session: true })
  }, [postData])
  /* --------------------------------------------- */
  const signup = useCallback((baseUrl, { email, password, mobile, countryCode, countryName, enablePromotions, acceptedTos, firstName, lastName, identityProvider, operator, accessTokenRefreshInterval, logoutExtendPeriod }) => {
    let url = `${baseUrl}${VERSION.signup}/accounts`
    if (identityProvider === 'mpx' || identityProvider === 'cognito') url = `${baseUrl}${VERSION.signup}/accounts/signUp`
    const body = {
      identityProvider: identityProvider,
      firstName,
      lastName,
      platform: 'web',
      profilePin: 'null',
      personas: false,
      countryCode,
      countryName,
      enablePromotions,
      acceptedTos,
      operator,
      details: {
        gender: 'male',
        enablePushNotifications: 'true',
        enablePromotions: 'true',
        acceptedTos: 'true',
        preferredLanguage: 'en',
        email: email,
        avatar: 'https://static.create.diagnal.com/images/mp-avatar/avatar1.png'
      },
      profile: {
        name: 'Default User',
        type: 'adult',
        avatar: 'https://static.create.diagnal.com/images/mp-avatar/avatar1.png',
        enablePushNotifications: 'true',
        preferredLanguage: 'en',
        requireExitPin: ''
      }
    }
    dispatch({ type: 'USER_INFO', value: {} })
    dispatch({ type: 'USER_TOKEN', value: null })
    window.store.localStorage.clear()
    return postData(url, body, {
      Authorization: getFormattedTokenInBase64(email, password, mobile)
    }).then((authInfo) => {
      setEmail(email)
      // const now = Date.now() / 1000
      // return login(baseUrl, {
      //   userName: email,
      //   password,
      //   mobile,
      //   identityProvider,
      //   accessTokenRefreshInterval,
      //   logoutExtendPeriod
      // })

      // const now = Date.now() / 1000
      //   const token = authInfo.token || ''
      // setUserAuthInfo({
      //   loginSuccessTime: now,
      //   identityProvider: identityProvider
      // })
      // const userInfoForContext = {
      //   "userId": authInfo.userId,
      //   "userName": authInfo.userName,
      //   "token": authInfo.token,
      //   "authInfo": authInfo.authInfo,
      //   "details": authInfo.details,
      //   "profile": authInfo.profile
      // }
      // dispatch({ type: 'USER_TOKEN', value: token })
      // dispatch({ type: 'USER_INFO', value: userInfoForContext })
    })
  }, [dispatch, postData])

  const verifyUser = useCallback((baseUrl, { password, mobile, countryCode, operator, countryName, firstName, lastName, confirmationCode, identityProvider, accessTokenRefreshInterval, logoutExtendPeriod }) => {
    let url = `${baseUrl}${VERSION.signup}/accounts`
    if (identityProvider === 'mpx' || identityProvider === 'cognito') url = `${baseUrl}${VERSION.signup}/accounts/verifyUser`
    const email = getEmail()
    const body = {
      countryCode,
      countryName,
      firstName,
      lastName,
      operator,
      confirmationCode: confirmationCode,
      identityProvider: identityProvider,
      personas: false,
      platform: 'web'
    }

    return postData(url, body, {
      Authorization: getFormattedTokenInBase64(email, password, mobile),
      withCredentials: true
    }, { withCredentials: true }).then((res) => {
      dispatch({ type: 'USER_TOKEN', value: res?.idToken })

      handlePostLogin(res, accessTokenRefreshInterval, logoutExtendPeriod, identityProvider)
        .then((authInfo) => {
          setAuthInfo(authInfo, baseUrl, identityProvider)
        })
    })
  }, [dispatch, postData, handlePostLogin, setAuthInfo])

  /* --------------------------------------------- */
  const updateUserDetails = useCallback((baseUrl, { firstName, lastName, dob, gender, phoneNumber, countryCode, email, operator }) => {
    const url = `${baseUrl}${VERSION.updateUserDetails}/accounts`
    return verifySession(baseUrl, () => {
      const headers = {}
      const Authorization = `Bearer ${userTokenRef.current}`
      if (Authorization) headers.Authorization = Authorization
      return putData(
        url,
        { firstName: firstName, lastName: lastName, phoneNumber, countryCode, operator, email, details: { dob: dob, gender } },
        headers
      )
    }, { url })
  }, [
    putData,
    verifySession
  ])
  /* --------------------------------------------- */

  const forgotPassword = useCallback((baseUrl, { email, identityProvider }) => {
    const url = `${baseUrl}${VERSION.forgotPassword}/accounts/forgotPassword`
    return postData(url, { userName: email, identityProvider })
  }, [postData])
  /* --------------------------------------------- */
  // call resetPassword api
  const resetPassword = useCallback((baseUrl, { newPassword, confirmationCode, userName }) => {
    const url = `${baseUrl}${VERSION.resetPassword}/accounts/resetPassword`
    return postData(url, { identityProvider: 'cognito', confirmationCode, platform: 'web' }, { //avoided sending password as request body and moved that to encrypted Autherization
      Authorization: getFormattedTokenInBase64(userName, newPassword)
    })
  }, [postData])
  /* --------------------------------------------- */
  // call change password api
  const changePassword = useCallback((baseUrl, { identityProvider, userName, oldPassword, newPassword }) => {
    const url = `${baseUrl}${VERSION.changePassword}/accounts/password`
    const headers = {}
    const Authorization = `Bearer ${userTokenRef.current}`
    if (Authorization) headers.Authorization = Authorization
    if (identityProvider === 'mpx') return putData(url, { userName: userName, platform: 'web', Authorization: getFormattedTokenInBase64(userName, oldPassword, newPassword)}, headers)// avoided sending password as request body and moved that to encrypted Autherization
    return putData(url, { identityProvider: 'cognito', Authorization: getFormattedTokenInBase64(userName, oldPassword, newPassword), platform: 'web' }, headers) //avoided sending password as request body and moved that to encrypted Autherization
  }, [putData])
  /* --------------------------------------------- */
  const addDevice = useCallback((baseUrl) => {
    const url = `${baseUrl}${VERSION.addDevice}/accounts/devices`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return getData(url, {}, { Authorization })
    }, { url })
  }, [
    getData,
    verifySession
  ])
  /* --------------------------------------------- */
  const deleteDevice = useCallback((baseUrl, { deviceId }) => {
    const url = `${baseUrl}${VERSION.deleteDevice}/accounts/devices/${deviceId}`
    return verifySession(baseUrl, () => {
      const Authorization = userTokenRef.current
      return deleteData(url, {}, { Authorization })
    }, { url })
  }, [
    deleteData,
    verifySession
  ])
  /* --------------------------------------------- */
  const getServerTime = useCallback((baseUrl) => {
    const url = `${baseUrl}${VERSION.time}/time`
    return getData(url, {})
  }, [getData])
  /* --------------------------------------------- */
  return {
    login,
    trustedLogin,
    refreshTrustedLogin,
    logout,
    signup,
    verifyUser,
    activateAccount,
    activateSession,
    verifySession,
    getUserDetails,
    updateUserDetails,
    forgotPassword,
    resetPassword,
    changePassword,
    addDevice,
    deleteDevice,
    selectProfileAPI,
    createProfileAPI,
    deleteProfileAPI,
    updateProfileAPI,
    updateAccountAPI,
    getAccountDetails,
    getServerTime,
    tvDeviceLogin
  }
}
/* --------------------------------------------- */
const userDetails = (res) => {
  const { subscription = {} } = res || {}
  const { subscriptionType = null } = subscription
  return {
    ...res,
    ...subscription,
    subscriptionType: subscriptionType ? subscriptionType.toLowerCase() : null
  }
}
/* --------------------------------------------- */
export default useAuthApi
