import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import utils from '../../utils/utils'
/* --------------------------------------------- */
// Styled components for Sidebar component
export const SidebarMenu = styled.div`
  background: ${({ theme: { body } }) => body && body.background.primary};
  .sign-up{
    color: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'tertiaryButton', 'normal', 'text') ? compositeStyle.tertiaryButton.normal.text : ''};
    background: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'tertiaryButton', 'normal', 'background') ? compositeStyle.tertiaryButton.normal.background : ''};
    box-shadow: ${({ theme: { compositeStyle } }) => (utils.isNotNull(compositeStyle, 'tertiaryButton', 'normal', 'shadow') && compositeStyle.tertiaryButton.normal.shadow )? '0 6px 8px 2px rgba(0, 0, 0, 0.48)' : ''};
    border-radius: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'tertiaryButton', 'edgeRadius') && compositeStyle.tertiaryButton.edgeRadius * 100}px;
    border: ${({ theme: { compositeStyle } }) => utils.isNotNull(compositeStyle, 'tertiaryButton', 'normal', 'stroke') ? `1px solid ${compositeStyle.tertiaryButton.normal.stroke}`: ''};
  }
`
/* --------------------------------------------- */
export const SidebarMenuListWrapper = styled.div`
  height: ${({ imageURL }) => imageURL.length ? 'auto' : '100%'};
`
/* --------------------------------------------- */
export const PopUpHeader = styled.p`
  color: ${({ theme: { header } }) => header && header.text.primary};
`
/* --------------------------------------------- */
export const SidebarMenuList = styled.ul`
${({ maxVisisbleMenuItems }) => {

    if (maxVisisbleMenuItems === 1) return
    let i = 1
    let test = ''
    while (i <= maxVisisbleMenuItems && maxVisisbleMenuItems > 1) {
      test += `
      li:nth-child(${i}){
        display:none;
      }

      @media only screen and (max-width: 1024px) {
        li:nth-child(${i}){
          display:block;
        }
      }
    `
      i++
    }
    return test
  }}
`
/* --------------------------------------------- */
export const SidebarMenuStyle = css`
  color: ${({ menuButtonStyle: { normal } }) => normal && normal.text};
  background: ${({ menuButtonStyle: { normal } }) =>
    normal && normal.background};
  padding-left: ${({
    menuButtonStyle: { normal, selected, focussed },
    dir,
  }) => {
    if (
      dir === "ltr" &&
      (utils.isNotNull(normal, "graphics", "images", 0, "url") ||
        utils.isNotNull(selected, "graphics", "images", 0, "url") ||
        utils.isNotNull(focussed, "graphics", "images", 0, "url"))
    ) {
      return "95px!important";
    } else {
      return "0px";
    }
  }};
  padding-right: ${({
    menuButtonStyle: { normal, selected, focussed },
    dir,
  }) => {
    if (
      dir === "rtl" &&
      (utils.isNotNull(normal, "graphics", "images", 0, "url") ||
        utils.isNotNull(selected, "graphics", "images", 0, "url") ||
        utils.isNotNull(focussed, "graphics", "images", 0, "url"))
    ) {
      return "95px!important";
    } else {
      return "0px";
    }
  }};
  border-radius: ${({ menuButtonStyle: { edgeRadius } }) =>
    edgeRadius && `0 ${edgeRadius * 20}px ${edgeRadius * 20}px 0`};
  border: ${({ menuButtonStyle: { normal } }) =>
    normal && `solid 1px ${normal.stroke}`};
  border-top: ${({ showSeparatorBefore }) =>
    showSeparatorBefore && "soild 1px white"};

  .sidebar-account-nametag {
    background: ${({ menuButtonStyle: { selected } }) =>
      selected && selected.background};
    color: ${({ menuButtonStyle: { selected } }) => selected && selected.text};
  }

  &:before {
    ${({ menuButtonStyle: { normal }, dir }) => {
      if (utils.isNotNull(normal, "graphics", "images", 0, "url")) {
        return `
          background-image: url('${normal.graphics.images[0].url}');
          left: ${(dir === "ltr") ? "50px !important" : "0px"}
          right: ${(dir === "rtl") ? "50px !important" : "0px"}
        `;
      }
    }}
  }

  &:hover {
    color: ${({ menuButtonStyle: { focussed } }) => focussed && focussed.text};
    background: ${({ menuButtonStyle: { focussed } }) =>
      focussed && focussed.background};

    .sidebar-account-nametag {
      color: ${({ menuButtonStyle: { normal } }) => normal && normal.text};
      background: rgba(31, 31, 31, 1);
    }
  }

  &:hover:before {
    ${({ menuButtonStyle: { focussed } }) => {
      if (utils.isNotNull(focussed, "graphics", "images", 0, "url")) {
        return `
          background-image: url('${focussed.graphics.images[0].url}');
        `;
      }
    }}
  }

  &.active {
    color: ${({ menuButtonStyle: { selected } }) => selected && selected.text};
    background: ${({ menuButtonStyle: { selected } }) =>
      selected && selected.background};

    .sidebar-account-nametag {
      color: ${({ menuButtonStyle: { normal } }) => normal && normal.text};
      background: rgba(31, 31, 31, 1);
    }
  }

  &.active::after {
    background: ${({ menuButtonStyle: { selected } }) =>
      selected && selected.stroke};
  }

  &.active::before {
    ${({ menuButtonStyle: { selected } }) => {
      if (utils.isNotNull(selected, "graphics", "images", 0, "url")) {
        return `
          background-image: url('${selected.graphics.images[0].url}');
        `;
      }
    }}
  }
`;
/* --------------------------------------------- */
export const SidebarMenuLinkExternal = styled(({ menuButtonStyle, showSeparatorBefore, dir,  ...props }) => <a {...props}>{props.children}</a>)`${SidebarMenuStyle}`
export const SidebarMenuLink = styled(({ menuButtonStyle, showSeparatorBefore, dir, ...props }) => <Link {...props}>{props.children}</Link>)`${SidebarMenuStyle}`
export const SecondaryMenuItem = styled(({ menuButtonStyle, showSeparatorBefore, dir, ...props }) => <div {...props}>{props.children}</div>)`${SidebarMenuStyle}`
/* --------------------------------------------- */