import { getNewObj } from '../utils/contentfulHandler'
import getButtonColor from './getButtonColor'

// Helper function to form contentful tree
const getButtonStyle = (contentful) => {
  const buttonColorLocal = getButtonColor(contentful)
  const buttonStyleLocal = contentful.buttonStyle
  return buttonStyleLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      normal = {}, focussed = {}, selectedFocussed = {}, pressed = {}, selected = {}, disabled = {}
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId: contentTypeId,
      normal: getNewObj(normal, [buttonColorLocal]),
      focussed: getNewObj(focussed, [buttonColorLocal]),
      selectedFocussed: getNewObj(selectedFocussed, [buttonColorLocal]),
      pressed: getNewObj(pressed, [buttonColorLocal]),
      selected: getNewObj(selected, [buttonColorLocal]),
      disabled: getNewObj(disabled, [buttonColorLocal])
    }
  })
}

export default getButtonStyle
