import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const SearchIcon = ({ color, ...rest }) => (
  <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.875 12.4691C1.875 6.62749 6.62719 1.87531 12.4697 1.87531C18.3112 1.87531 23.0634 6.62749 23.0634 12.4691C23.0634 18.3106 18.3112 23.0637 12.4697 23.0637C6.62719 23.0637 1.875 18.3106 1.875 12.4691ZM29.7253 28.4L21.9141 20.5878C23.7938 18.4044 24.9384 15.5703 24.9384 12.4691C24.9384 5.59343 19.3453 0.000305176 12.4697 0.000305176C5.59406 0.000305176 0 5.59343 0 12.4691C0 19.3447 5.59406 24.9387 12.4697 24.9387C15.57 24.9387 18.4041 23.7941 20.5875 21.9134L28.3997 29.7256C28.5825 29.9084 28.8225 30.0003 29.0625 30.0003C29.3025 30.0003 29.5425 29.9084 29.7253 29.7256C30.0919 29.3591 30.0919 28.7656 29.7253 28.4Z" fill={color}/>
  </svg>
)
/* --------------------------------------------- */
SearchIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
SearchIcon.defaultProps = {
  color: 'transparent'
}
/* --------------------------------------------- */
export default SearchIcon
/* --------------------------------------------- */
