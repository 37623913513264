import React from 'react'
import GetAppMobilePopup from "./GetAppMobilePopup";

// Wrapper component for GetAppMobilePopup component.
const Wrapper = (props) => {
    return (
        <>
            <GetAppMobilePopup {...props} />
        </>
    )
}
export default Wrapper