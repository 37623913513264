import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const PauseIcon = ({ color, ...rest }) => (
  <svg viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
  <path 
  fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M3.56266 0C1.62958 0 0.0625 1.56708 0.0625 3.50017V26.8105C0.0625 28.7436 1.62958 30.3107 3.56266 30.3107C5.49575 30.3107 7.06283 28.7436 7.06283 26.8105V3.50016C7.06283 1.56708 5.49575 0 3.56266 0ZM19.6343 0C17.7012 0 16.1342 1.56708 16.1342 3.50017V26.8105C16.1342 28.7436 17.7012 30.3107 19.6343 30.3107C21.5674 30.3107 23.1345 28.7436 23.1345 26.8105V3.50016C23.1345 1.56708 21.5674 0 19.6343 0Z" 
  />
  </svg>
)
/* --------------------------------------------- */
PauseIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
PauseIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default PauseIcon
/* --------------------------------------------- */
