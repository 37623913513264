import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const PlayIconFilled = ({ color, ...rest }) => (
  <svg viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path 
    fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M1.75 30.0001C1.59438 30.0001 1.43969 29.9617 1.29906 29.8848C0.999062 29.7198 0.8125 29.4048 0.8125 29.0626V0.937595C0.8125 0.595408 0.999062 0.280408 1.29906 0.116345C1.59906 -0.0486546 1.96469 -0.0374046 2.25344 0.146345L24.3512 14.2088C24.6213 14.3813 24.7853 14.6795 24.7853 15.0001C24.7853 15.3207 24.6213 15.6188 24.3512 15.7913L2.25344 29.8538C2.10063 29.9513 1.92531 30.0001 1.75 30.0001Z" 
    />
  </svg>
)
/* --------------------------------------------- */
PlayIconFilled.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
PlayIconFilled.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default PlayIconFilled
/* --------------------------------------------- */
