import React from 'react'
import Footer from "./Footer";

// Wrapper component of Footer component.
const Wrapper = (props) => {
    return (
        <>
            <Footer {...props} />
        </>
    )
}
export default Wrapper