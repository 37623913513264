import React, { useState, forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { useSelector } from 'react-redux'
import withRouterInnerRef from '../../components/hoc/withRouterInnerRef'
import utils from '../../utils/utils'
import styled from 'styled-components'
/* --------------------------------------------- */
import CloseIcon from '../icons/CloseIcon'
import PlayIcon from '../icons/PlayIcon'
import PlayIconFilled from '../icons/PlayOutlineIconFilled'
/* --------------------------------------------- */
// BinchWatch component for showing binch watch
const BinchWatch = forwardRef(({
  messages, history, nextBtnHandle, bingeCountdownDuration, nextEpisode
}, ref) => {
  const [currentTime, updateCurrentTime] = useState(0)
  const [duration, updateDuration] = useState(0)
  const [difference, updatedifference] = useState(0)
  const [forceClose, updateForceClose] = useState(false)
  const [isStarted, updateIsStarted] = useState(false)
  /* --------------------------------------------- */
  const { appMessages } = useSelector(store => store.appConfigReducers)
  /* --------------------------------------------- */
  const binchRef = useRef(null)
  /* --------------------------------------------- */
  /**
   * It: Sets remaining time in a state.
   */
  useEffect(() => {
    if (binchRef && binchRef.current) {
      const diff = duration - currentTime
      const diffInSec = Math.abs(diff / 1000)
      updatedifference(Math.round(diffInSec))
      if (duration && currentTime) {
        if (diff <= (bingeCountdownDuration * 1000)) {
          if (diffInSec === 0) {
            updateIsStarted(true)
            if (nextEpisode && nextEpisode.uid) {
              setTimeout(() => {
                if (nextBtnHandle) nextBtnHandle()
              }, 2000)
            }
          }
          if (!forceClose) binchRef.current.classList.add('binch-container---open')
        } else {
          binchRef.current.classList.remove('binch-container---open')
        }
      }
    }
  }, [duration, currentTime, forceClose, history, nextEpisode, nextBtnHandle, bingeCountdownDuration])
  /* --------------------------------------------- */
  /**
   * When: On close button click.
   * It: Closes popup.
   */
  const onCloseBtnHandler = () => {
    updateForceClose(true)
    if (binchRef && binchRef.current) {
      binchRef.current.classList.remove('binch-container---open')
    }
  }
  /* --------------------------------------------- */
  useImperativeHandle(ref, () => {
    return {
      updateCurrentTime: updateCurrentTime,
      updateDuration: updateDuration
    }
  })
  /* --------------------------------------------- */

  return (<>
    {nextEpisode && Object.keys(nextEpisode).length ? <div className='binch-container' ref={binchRef}>
      <div className='binch__header'>
        <p>{messages.label_binge_up_next}</p>
        <div onClick={onCloseBtnHandler}>
          <CloseIcon color='#ffd200' style={{ width: 15, height: 15 }} />
        </div>
      </div>
      <ImgContainer {...{ images: nextEpisode ? nextEpisode.images : [] }} className='binch__img' />
      <div className='binch__content'>
        <div className='binch-content__text'>
          <p>{messages.label_details_episode} {nextEpisode ? nextEpisode.episodeNumber : ''}</p>
          <h3>{nextEpisode ? nextEpisode.title : ''}</h3>
        </div>
        <div className='binch-content__icon' onClick={nextBtnHandle}>
          <PlayIcon color='#ffd200' style={{ width: 28, height: 32 }} className="binch_player-icon" />
          <PlayIconFilled color='#ffd200' style={{ width: 28, height: 32 }} className="binch_playerfill-icon" />
        </div>
      </div>
      <p className='binch__timer'>
        {(!isStarted && difference) ? `${appMessages.label_binge_starting_in} ${difference} ${appMessages.label_binge_seconds}` : `${appMessages.binge_starting_now}`}
      </p>
    </div> : null}
  </>
  )
})

/* --------------------------------------------- */
const ImgContainer = styled.div`
  ${({ images }) => {
    const height = 250 * (9 / 16)
    let bimgSrc = utils.getCloserMatchImage(320, 180, images)
    const url = bimgSrc ? bimgSrc.url : ''
    return `
            background-image: url("${url}");
            height: ${height}px;
            background-size: cover;
            background-position: center;
      `
  }}
`
/* --------------------------------------------- */
/* --------------------------------------------- */
export default withRouterInnerRef(BinchWatch)
