import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getTheme from './getTheme'
import getCuratedDataEntry from './getCuratedDataEntry'
import getPage from './getPage'


// Helper function to form contentful tree
const getFeatureIntro = (contentful) => {
  const filterLocal = getFilter(contentful)
  const curatedDataEntryLocal = getCuratedDataEntry(contentful)
  // console.log("curatedDataEntryLocal:", curatedDataEntryLocal)
  const themeLocal = getTheme(contentful)
  const featureIntroLocal = contentful.featureIntro
  const pageLocal = getPage(contentful)

  // console.log({ contentful, filterLocal, featureIntroLocal })
  return featureIntroLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      filter = {}, introContent = [], theme = [],landingPage = [],
    } = fields
   
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      introContent: getNew(introContent, curatedDataEntryLocal),
      landingPage : getNew(landingPage, pageLocal),
      theme: getNew(theme, themeLocal),
    }
  })
}

export default getFeatureIntro
