import React from "react";
/* ---------------------------------------------------------------------------------------------------- */
// RadioButton components for displaying toggle switch.
const RadioButton = ({ checked = false, id, value, title, onChange }) => {
  return (
    <>
      <input type="radio" id={id} value={value} {...{ checked, onChange }} />
      <label for={id}>{title}</label>
    </>
  );
};

/* ---------------------------------------------------------------------------------------------------- */
export default RadioButton;
/* ---------------------------------------------------------------------------------------------------- */
