import React from 'react'
//---Importing Components---
import {
    IndivudualCountryListStyled
  } from './style'

// ------Imports to be modified ------------

const IndividualCountryCode = ({ name = null, isThisCountryCodeSelected = null, code = null, flag = null, operator = null, theme }) => {
    return (
        <IndivudualCountryListStyled isThisCountryCodeSelected={isThisCountryCodeSelected} theme={theme}>
            <div className="indivudual-countrylist-wrapper">
                {   flag && <div className="indivudual-profile-wrapper__avatar">
                        <img alt='' src={flag} />
                    </div>
                }
                {   name && <div className="indivudual-country-details" >
                        <div className="indivudual-country__name">{name}</div>
                    </div>
                }    
                {   code && <div className="indivudual-country__code">
                        <span>{code}</span>
                    </div>
                }
                {   operator && <div className="indivudual-country__operator">
                        <span>{operator}</span>
                    </div>
                }      
            </div>
        </IndivudualCountryListStyled>
    )
}


export default IndividualCountryCode