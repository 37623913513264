import React from 'react'
import { useState, useEffect, useContext, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { GlobalContext } from '../../context/global.context'
import useContentful from '../../contentful/useContentful'
import { appConfig as appConfigAction } from '../../actions/appConfig'
import { setAppLanguage, getContentfullData, setContentfullData } from '../../utils/localStorageService'
import useErrorUtils from '../../utils/useErrorUtils'
import utils from '../../utils/utils'
import Spinner from '../Spinner'

// ContentfulLoader components responsible for fetching contentful data and storing them in redux store.
const ContentfulLoader = () => {
    const appDataLS = useRef(getContentfullData())
    const { isAppLoaded, isAuthenticated, userInfo, dispatch: globalDispatch, selectedProfile, appLanguage, isContentfulPreview } = useContext(GlobalContext)
    const [appData, setAppData] = useState(null)
    const [isApiFailed, setApiFailed] = useState(false)
    const { getContentful } = useContentful()
    const { showError } = useErrorUtils()
    const dispatch = useDispatch()
    const userInfoRef = useRef(userInfo)
    const selectedProfileRef = useRef(null)
    const appLanguageRef = useRef(null)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        userInfoRef.current = userInfo
    }, [userInfo])

    /**
     * It: Updates app language to the global state.
     * It: Fetches contentful data and set them as appData state.
     */
    useEffect(() => {
        getContentful(isContentfulPreview).then((res = {}) => {
            // console.log(res, "res")
            const { locale = '' } = res || {}
            globalDispatch({ type: 'SET_APP_LANGUAGE', value: locale || 'en-US' })
            if(utils.checkRTLLanguage(locale)){
                globalDispatch({ type: 'SET_UI_DIRECTION', value: 'rtl'})
                document.getElementsByTagName('body')[0].setAttribute("dir", "rtl")
              }
            setAppLanguage(locale)
            setAppData(res || {})
        }).catch((error) => {
            error = error || {}
            setAppData({})
            setApiFailed(true)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalDispatch, isContentfulPreview])


    /**
     * It: Updates app based on the selected Profile.
     * It: Fetches contentful data and set them as appData state.
     */
    useEffect(() => {
        if(!selectedProfile){
            return
        }
        if(!selectedProfileRef.current && selectedProfile){
            selectedProfileRef.current = selectedProfile
            return
        }
        setLoading(true)
        getContentful(isContentfulPreview).then((res = {}) => {
            setAppData(res || {})
            setLoading(false)
        }).catch((error) => {
            error = error || {}
            setAppData({})
            setApiFailed(true)
            setLoading(false)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isContentfulPreview, selectedProfile])
        /**
     * It: Updates app language to the global state on profile change.
     * It: Fetches contentful data and set them as appData state.
     */
    useEffect(() => {
        if(!appLanguage){
            return
        }
        if(!appLanguageRef.current && appLanguage){
            appLanguageRef.current = appLanguage
            return
        }
        setLoading(true)
        getContentful(isContentfulPreview).then((res = {}) => {
            setAppData(res || {})
            setLoading(false)
        }).catch((error) => {
            error = error || {}
            setAppData({})
            setApiFailed(true)
            setLoading(false)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appLanguage, isContentfulPreview])
    /**
     * It: uses the contentfull Data from LS if available.
     * It: Updates isAppLoaded state.
     */
    useEffect(()=>{
       let isMounted = true
        if (appDataLS.current) {
            const userInfo = userInfoRef.current || {}
            let subscriptionType = 'anonymous'
            if (isAuthenticated) subscriptionType = userInfo.subscriptionType || 'registered'
            dispatch(appConfigAction(appDataLS.current, { ...userInfo, subscriptionType }))
                .catch((error) => {
                    console.log('CONTENTFUL DATA PARSE ERROR', error)
                }).then(() => {
                    if (!isMounted) return
                    globalDispatch({ type: 'IS_APP_LOADED', value: true })
                })
        }
        return () => {
            isMounted = false
        }

    }, [
        appDataLS,
        dispatch,
        isAuthenticated,
        globalDispatch
        ])


    /**
     * It: Stores appData in redux stores.
     * It: Updates isAppLoaded state.
     */
    useEffect(() => {
        let isMounted = true
        if (appData) {
            const userInfo = userInfoRef.current || {}
            let subscriptionType = 'anonymous'
            if (isAuthenticated) subscriptionType = userInfo.subscriptionType || 'registered'
            // store contentfull tree in LS
            setContentfullData(JSON.stringify(appData))
            dispatch(appConfigAction(appData, { ...userInfo, subscriptionType }))
                .catch((error) => {
                    console.log('CONTENTFUL DATA PARSE ERROR', error)
                }).then(() => {
                    if (!isMounted) return
                    globalDispatch({ type: 'IS_APP_LOADED', value: true })
                })
        }
        return () => {
            isMounted = false
        }
    }, [
        dispatch,
        appData,
        isAuthenticated,
        globalDispatch
    ])

    /**
     * It: Displays contentful error.
     */
    useEffect(() => {
        if (isAppLoaded && isApiFailed) {
            showError({ type: 'ServerError' })
        }
    }, [isAppLoaded, isApiFailed, showError])
    return <Spinner IsLoading={isLoading} size={60} />
}

export default ContentfulLoader