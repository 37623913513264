import React from 'react'
import GetAppPopup from "./GetAppPopup";

// Wrapper component for GetAppPopup component.
const Wrapper = (props) => {
    return (
        <>
            <GetAppPopup {...props} />
        </>
    )
}
export default Wrapper