import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const SettingsIconFilled = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' width='36' height='36' {...rest}>
    <g id='Layer_2_1_' style={{ display: 'none' }}>
      <rect x='-100' y='-100' style={{ display: 'inline' }} width='360' height='360' />
    </g>
    <path
      fill={color} d='M158.77,70.89c0.03-1.53-0.56-2.14-1.92-2.61c-4.38-1.52-8.75-3.09-13.03-4.85c-1.03-0.42-2.07-1.51-2.52-2.55
   c-1.84-4.24-3.51-8.56-5.1-12.9c-0.31-0.83-0.3-2.02,0.06-2.82c2.09-4.63,4.39-9.17,6.47-13.8c0.34-0.76,0.38-2.21-0.1-2.71
   c-4.03-4.18-8.21-8.21-12.44-12.39c-4.98,2.38-10,4.85-15.1,7.16c-0.88,0.4-2.18,0.42-3.1,0.09c-4.35-1.59-8.66-3.29-12.91-5.12
   C98.03,17.93,96.87,17,96.46,16c-1.77-4.37-3.35-8.83-4.85-13.3C91.14,1.29,90.48,0.79,89,0.82C83.89,0.9,78.78,0.77,73.68,0.93
   c-0.82,0.02-2.02,0.92-2.34,1.7c-1.81,4.45-3.33,9.02-5.13,13.48c-0.38,0.94-1.43,1.85-2.39,2.27c-4.16,1.82-8.38,3.53-12.64,5.09
   c-1.07,0.39-2.58,0.43-3.6-0.02c-4.49-1.98-8.9-4.15-13.29-6.34c-1.35-0.68-2.23-0.62-3.35,0.55c-3.46,3.64-7.02,7.18-10.64,10.65
   c-1.02,0.98-1.07,1.77-0.5,2.95c2.2,4.49,4.26,9.04,6.5,13.5c0.67,1.34,0.56,2.28-0.11,3.6c-2.09,4.14-3.9,8.42-5.96,12.58
   c-0.47,0.95-1.36,2.01-2.31,2.37C13.44,65,8.91,66.64,4.3,68c-2.22,0.65-2.8,1.75-2.73,3.93c0.16,4.66,0.1,9.34,0.02,14
   c-0.03,1.55,0.4,2.31,1.97,2.8c4.76,1.48,9.49,3.07,14.14,4.86c1.07,0.41,2.06,1.66,2.59,2.77c1.97,4.09,3.63,8.32,5.64,12.39
   c0.7,1.42,0.78,2.5,0.11,3.88c-2.38,4.88-4.66,9.8-7.04,14.85c4.17,4.15,8.25,8.29,12.46,12.28c0.51,0.49,1.92,0.54,2.65,0.22
   c4.56-2.03,9.01-4.3,13.58-6.3c0.94-0.41,2.36-0.31,3.35,0.09c4.45,1.82,8.85,3.78,13.2,5.83c0.81,0.38,1.62,1.29,1.93,2.13
   c1.77,4.75,3.45,9.54,5.03,14.36c0.46,1.41,1.11,1.95,2.61,1.92c5.11-0.08,10.22-0.08,15.32,0c1.4,0.02,2-0.49,2.42-1.78
   c1.55-4.74,3.1-9.48,4.9-14.13c0.42-1.09,1.64-2.13,2.75-2.65c4.1-1.95,8.28-3.76,12.49-5.44c1.05-0.42,2.54-0.57,3.52-0.15
   c5.12,2.23,10.13,4.68,15.23,7.08c4.09-4.1,8.03-8.13,12.06-12.07c0.99-0.96,1.1-1.72,0.49-2.96c-2.22-4.47-4.39-8.97-6.4-13.54
   c-0.42-0.96-0.4-2.37-0.05-3.38c1.44-4.21,3.22-8.32,4.6-12.55c0.53-1.6,1.29-2.47,2.83-3.01c4.3-1.5,8.53-3.18,12.83-4.66
   c1.35-0.46,2-1.01,1.97-2.56C158.68,81.1,158.67,75.99,158.77,70.89z M81.46,109.94c-17.22,0.05-31.54-14.05-31.59-31.09
   c-0.04-17.41,14.05-31.8,31.2-31.86c17.55-0.06,31.74,14.02,31.75,31.47C112.83,95.95,98.94,109.89,81.46,109.94z'
    />
  </svg>

)
/* --------------------------------------------- */
SettingsIconFilled.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
SettingsIconFilled.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default SettingsIconFilled
/* --------------------------------------------- */
