import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const FavMarkedIcon = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox="0 0 20 19" {...rest}>
    <g id="Details" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="2b3-Details---Favorite" transform="translate(-335.000000, -232.000000)" fill={color} stroke={color}>
            <path d="M344.998597,250.2202 L346.113696,249.202001 C351.009997,244.751595 352.559252,243.154446 353.654329,241.119655 C354.221355,240.066048 354.5,239.055487 354.5,238.014 C354.5,235.201916 352.30363,233 349.5,233 C347.934752,233 346.394703,233.725595 345.38105,234.918729 L345,235.36725 L344.61895,234.918729 C343.605297,233.725595 342.065248,233 340.5,233 C337.69637,233 335.5,235.201916 335.5,238.014 C335.5,239.050673 335.775988,240.056664 336.337559,241.105218 C337.429239,243.143579 338.969215,244.734067 343.885611,249.212371 L344.998597,250.2202 Z" id="Stroke-1"></path>
        </g>
    </g>
  </svg>
)
/* --------------------------------------------- */
FavMarkedIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
FavMarkedIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default FavMarkedIcon
/* --------------------------------------------- */
