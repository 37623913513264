import { getNew, getNewObj } from '../utils/contentfulHandler'
import getComponentStyle from './getComponentStyle'
import getComponentData from './getComponentData'
import getFilter from './getFilter'
import getTheme from './getTheme'

// Helper function to form contentful tree
const getPageComponent = (contentful, isCurated) => {
  const componentStyleLocal = getComponentStyle(contentful)
  const componentDataLocal = getComponentData(contentful, isCurated)
  const filterLocal = getFilter(contentful)
  const themeLocal = getTheme(contentful)
  const pageComponentLocal = contentful.pageComponent
  return pageComponentLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      filter = {}, contents = [], componentStyle = [], theme = [], overflowListingTheme = []
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      contents: getNew(contents, componentDataLocal),
      componentStyle: getNew(componentStyle, componentStyleLocal),
      theme: getNew(theme, themeLocal),
      overflowListingTheme: getNew(overflowListingTheme, themeLocal)
    }
  })
}

export default getPageComponent
