import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
import utils from '../../utils/utils'
/* --------------------------------------------- */
import EyeIcon from '../icons/EyeIcon'
import HideEye from '../icons/HideEye'
/* --------------------------------------------- */

// TextBox component for showing text box with events.
const TextBox = forwardRef(({
  placeholder, theme, type, handler, name, isShowEye, confirmPasswordField, initialValue, onKeyPress, disabled,
  validationText, isLastName, isCoupon, updatePlaceholderTextCallback, inValidFormItem, isMissmatch, skipValidation, onAfterFocus, isPhone, isDate, isOtp
},ref) => {
  useImperativeHandle(ref, () => ({
    getForm() {
      return formRef.current;
    },
    getInput() {
      return inputRef.current;
    },
    getInputFocus() {
      return inputRef.current.focus();
    }
  }));
  const [inputValue, updateInputValue] = useState(initialValue)
  const [placeholderText, updatePlaceholderText] = useState(placeholder)
  const [isValidField, updateIsValidField] = useState(true)
  const [isValidFormItem, updateIsValidFormItem] = useState(true)
  const [isShowPassword, updateIsShowPassword] = useState(false)
  const [fieldType, updateFieldType] = useState(type)
  const [isFocused, updateIsFocused] = useState(false)

  /* --------------------------------------------- */
  const { appMessages } = useSelector(store => store.appConfigReducers)
  const { userManagementConfig = {} } = useSelector(store => store.userManagement)
  /* --------------------------------------------- */
  const formRef = useRef(null);
  const inputRef = useRef(null);
  /* --------------------------------------------- */
  /**
   * It: Sets initalValue
   */
  useEffect(() => {
    updateInputValue(initialValue)
  }, [initialValue])
  /* --------------------------------------------- */
  /**
   * It: UPdates placeholder text
   */
  useEffect(() => {
    updatePlaceholderText(placeholder)
  }, [placeholder])
  /* --------------------------------------------- */
  /**
   * It: Updates form valid or not state
   */
  useEffect(() => {
    if (inValidFormItem) updateIsValidFormItem(false)
    else updateIsValidFormItem(true)
  }, [inValidFormItem])
  /* --------------------------------------------- */
  /**
   * It: UPdates placeholder text
   */
  useEffect(() => {
    updatePlaceholderTextCallback(placeholderText)
  }, [updatePlaceholderTextCallback, placeholderText])
  /* --------------------------------------------- */
  /**
   * When: To validate name.
   * It: Updates placeholder text
   * It: Updates valid form state
   */
  const nameValidation = (value, validationRegex, minChars, maxChars, minError, maxError, placeholder) => {
    if (!value) {
      updatePlaceholderText(placeholder)
      updateIsValidFormItem(false)
      handler(null)
      return false
    }
    const pattern = new RegExp(validationRegex)
    if (pattern.test(value)) {
      if (value.length < minChars) {
        updatePlaceholderText(`${minError} ${minChars}`)
        updateIsValidFormItem(false)
        handler(null)
      } else if (value.length > maxChars) {
        updatePlaceholderText(`${maxError} ${maxChars}`)
        updateIsValidFormItem(false)
        handler(null)
      } else {
        updatePlaceholderText(placeholder)
        updateIsValidFormItem(true)
        handler(value)
      }
    } else {
      updatePlaceholderText(validationText)
      updateIsValidFormItem(false)
      handler(null)
    }
  }

  const phoneValidation = (value) => {
    if (!value) {
      updatePlaceholderText(placeholder)
      updateIsValidFormItem(false)
      handler(null)
      return false
    }
    updateInputValue(value)
    var phoneNum = value?.replace(/[^\d]/g, '');
    if(phoneNum.length > 6 && phoneNum.length < 16) { 
      updatePlaceholderText(placeholder)
      updateIsValidFormItem(true)
      handler(value)
    } else {
      updateIsValidFormItem(false)
      updatePlaceholderText(appMessages.label_account_info_mobile_number_invalid)
      handler(null)
      return false
    }
  }

  const emailValidation = (value, validationRegex, validationTextEmail, placeholder) => {

    if (!value) {
      updatePlaceholderText(placeholder)
      updateIsValidFormItem(false)
      handler(null)
      return false
    }

    const pattern = new RegExp(validationRegex)
    if (pattern.test(value)) {
      updatePlaceholderText(placeholder)
      updateIsValidFormItem(true)
      updateIsValidField(true) // make valid field if correct email format

      handler(value)
    }else {
      updatePlaceholderText(validationTextEmail)
      updateIsValidFormItem(false)
      handler(null)
      updateIsValidField(false) // make invalid field if wrong email format

      return false
    }
  }

  const dobValidation = (value, validationRegex, validationTextDob, placeholder) => {
    if (!value) {
      updatePlaceholderText(placeholder)
      updateIsValidFormItem(false)
      handler(null)
      return false
    }

    const pattern = new RegExp(validationRegex)

    if (pattern.test(value)) {
      var dateCheck;
      var dateFrom = userManagementConfig.dobPickerMinimumDate.split("-").reverse().join("/");
      var dateTo = userManagementConfig.dobPickerMaxDate.split("-").reverse().join("/");
      if(value?.length === 10 ) {
        dateCheck = value;
        
        var d1 = dateFrom.split("/");
        var d2 = dateTo.split("/");
        var c = dateCheck.split("/");

        var from = d1[1] + '/' + d1[0] + '/' + d1[2];  
        var to   = d2[1] + '/' + d2[0] + '/' + d2[2];
        var check = c[1] + '/' + c[0] + '/' + c[2];

        var fDate,lDate,cDate;
        fDate = Date.parse(from);
        lDate = Date.parse(to);
        cDate = Date.parse(check);

          if((cDate < lDate && cDate > fDate)) {
            updatePlaceholderText(placeholder)
            updateIsValidFormItem(true)
            handler(value)
          } else if(cDate >= lDate) {
            updatePlaceholderText(appMessages?.label_max_dob || placeholder)
          }else if(cDate <= fDate) {
            updatePlaceholderText(appMessages?.label_min_dob || placeholder)
          }
      }
      
    }else {
      updatePlaceholderText(validationTextDob)
      updateIsValidFormItem(false)
      handler(null)
    }
  }

  const couponValidation = (value) => {
    updateIsValidFormItem(true)
    updateIsValidField(true)
    if (!value) {
      updatePlaceholderText(validationText)
      return false
    }
    updateInputValue(value)
    handler(value)
  }

  //validation function for access code
  const accessCodeValidation = (value) => {
    let val = value?.split(" ")?.join("") || value;
    const pattern = new RegExp(userManagementConfig?.accessCodeValidationRegex) //getting validation regex from usermanagement feature
    if (val?.length && pattern.test(val)) {
      if(val.length >= userManagementConfig.accessCodeMinLength && val.length <= userManagementConfig.accessCodeMaxLength) { //min and max length validation
        updatePlaceholderText(placeholder)
        updateIsValidFormItem(true)
        updateIsValidField(true)
        handler(value)
      } else if(val.length < userManagementConfig.accessCodeMinLength) {
        updatePlaceholderText(appMessages?.label_min_access_code || placeholder)
        updateIsValidFormItem(false)
        updateIsValidField(false)
        handler(null)
      } else if(val.length > userManagementConfig.accessCodeMaxLength) {
        updatePlaceholderText(appMessages?.label_max_access_code || placeholder)
        updateIsValidFormItem(false)
        updateIsValidField(false)
        handler(null)
      }
    } else {
      updatePlaceholderText(appMessages?.label_enter_valid_access_code)
      updateIsValidFormItem(false)
      updateIsValidField(false)
      handler(null)
    }
  }

  //lynk id validation 
  const lynkIdValidation = (value) => {
    let val = value?.split(" ")?.join("") || value;
    updateInputValue(val)
    if(val?.length) {
      updateIsValidFormItem(true)
      updateIsValidField(true)
      updatePlaceholderText(appMessages.label_lynk)
      handler(val)
    } else {
      updatePlaceholderText(validationText)
      updateIsValidFormItem(false)
      updateIsValidField(false)
      handler(null)
      return false
    }
  }
  /* --------------------------------------------- */
  /**
   * When: To validate form.
   * It: Validates form
   */

  const inputOnChangeHandler = (e) => {

    if (isPhone && isNaN(e.target.value)) {
      return
    }

    updateIsValidField(false)
    if(type === "dob" && e.target.value.length >= 11) {
      return
    }
    updateInputValue(e.target.value)
    if (!e.target.value) {
      updateIsValidField(true)
      handler(e.target.value)
    }
    if(skipValidation){
      if(e.target.value){
        updatePlaceholderText(name)
      }else{
        updatePlaceholderText(placeholder)
      }
      handler(e.target.value)

      return;
    }
    if (type === 'email' && e.target.value.length !== 0) {
      emailValidation(e.target.value,  userManagementConfig.emailValidationRegex, appMessages.label_sign_up_error_enter_valid_email, appMessages.label_sign_up_email)
      // var x = e.target.value
      // var atpos = x.indexOf('@')
      // var dotpos = x.lastIndexOf('.')
      // if (atpos < 1 || dotpos < atpos + 2 || dotpos + 2 >= x.length) {
      //   updatePlaceholderText(appMessages.label_sign_up_error_enter_valid_email)
      //   updateIsValidFormItem(false)
      //   handler(null)
      // } else {
      //   updatePlaceholderText(appMessages.label_sign_up_email)
      //   updateIsValidFormItem(true)
      //   handler(e.target.value)
      // }
    } else if (type === 'email' && e.target.value.length === 0) {
         updatePlaceholderText(appMessages.label_sign_up_email)
        updateIsValidFormItem(true)
        handler(e.target.value)
    } else if (type === 'password' && e.target.value.length !== 0) {
      const pattern = new RegExp(userManagementConfig.passwordRegex)
      if (pattern.test(e.target.value)) {
        if (e.target.value.length < userManagementConfig.minPasswordChars) {
          updatePlaceholderText(`${appMessages.label_sign_up_error_minimum_password_character} ${userManagementConfig.minPasswordChars}`)
          updateIsValidFormItem(false)
          handler(null)
        } else if (e.target.value.length > userManagementConfig.maxPasswordChars) {
          updatePlaceholderText(`${appMessages.label_sign_up_error_maximum_password_character} ${userManagementConfig.maxPasswordChars}`)
          updateIsValidFormItem(false)
          handler(null)
        } else {
          updatePlaceholderText(placeholder)
          updateIsValidFormItem(true)
          handler(e.target.value)
        }
      } else {
        updatePlaceholderText(appMessages.label_sign_up_enter_valid_password)
        updateIsValidFormItem(false)
        handler(null)
      }
    } else if (type === 'password' && e.target.value.length === 0) {

      updatePlaceholderText(appMessages.label_sign_up_password)
        updateIsValidFormItem(true)
        handler(e.target.value)
    } else if (type === 'text') {
      if (isLastName) {
        nameValidation(e.target.value, userManagementConfig.lastNameValidationRegex,
          userManagementConfig.lastNameMinChars, userManagementConfig.lastNameMaxChars,
          appMessages.label_sign_up_error_minimum_last_name_character,
          appMessages.label_sign_up_error_maximum_last_name_character, appMessages.label_account_info_last_name)
      }else if(isPhone){
        phoneValidation(e.target.value)
      } else if (isCoupon) {
        couponValidation(e.target.value)
      } else if(name === 'accessCode') {
        accessCodeValidation(e.target.value)
      } else if (isDate) {
        if (e.target.value.length === 0) {
          updatePlaceholderText(appMessages.label_account_info_date_of_birth)
            updateIsValidFormItem(false)
            handler(e.target.value)
        }else {
          updatePlaceholderText(appMessages.label_account_info_date_of_birth)
            updateIsValidFormItem(true)
            handler(e.target.value)
        }
      
      } else if (isOtp) {
        if(e.target?.value?.length > 3) {
          updatePlaceholderText(appMessages.label_enter_otp)
          updateIsValidFormItem(true)
          updateIsValidField(true)
          handler(e.target.value)
        } else {
          updatePlaceholderText(appMessages.label_otp_placeholder)
          updateIsValidFormItem(false)
          updateIsValidField(false)
          handler(e.target.value)
        }
      } else {
        nameValidation(e.target.value, userManagementConfig.firstNameValidationRegex,
          userManagementConfig.firstNameMinChars, userManagementConfig.firstNameMaxChars,
          appMessages.label_sign_up_error_minimum_first_name_character,
          appMessages.label_sign_up_error_maximum_first_name_character, appMessages.label_account_info_first_name)
      }
    } else if (type === 'dob' && e.target.value.length !== 0) {
      dobValidation(e.target.value,  userManagementConfig?.dobRegex, appMessages.label_account_info_date_of_birth, appMessages.label_account_info_date_of_birth)
    } else if (type === 'dob' && e.target.value.length === 0) {
      updatePlaceholderText(appMessages.label_account_info_date_of_birth)
      updateIsValidFormItem(true)
      handler(e.target.value)
    } else if (type === 'lynkid') {
      lynkIdValidation(e.target.value);
    } else {
      updatePlaceholderText(placeholder)
    }

    if (confirmPasswordField && type === 'password' && e.target.value.length === 0) {
      updatePlaceholderText(appMessages.label_sign_up_confirm_password)
    }
  }
  /* --------------------------------------------- */
  /**
   * When: To handle eye icon click.
   * It: Show/hide password.
   */
  const eyeOnClickHandler = () => {
    updateIsShowPassword(!isShowPassword)
    if (!isShowPassword) {
      updateFieldType('text')
    } else updateFieldType('password')
  }
  const onFocus = () => {
    updateIsFocused(true)
    onAfterFocus && onAfterFocus()
  }
  const onBlur = () => {
    updateIsFocused(false)
  }

  /* --------------------------------------------- */
  return (
    <FormItem ref={formRef} {...{ theme, Placeholder, isValidField }} className={`form__item ${isValidFormItem && !isMissmatch ? `${inputValue ? 'form__item---valid' : ''}` : 'form__item---invalid'}`}>
      <input ref={inputRef} tabIndex={1} value={inputValue} onChange={inputOnChangeHandler} type={fieldType} name={name} placeholder={isDate && isFocused ? 'DD/MM/YYYY': ' '} autoComplete='new-password' onFocus={onFocus} onBlur={onBlur} disabled={disabled} onKeyPress={onKeyPress}/>
      {(!inputValue?.length && !isFocused) && <p className='empty-validation-text'>{validationText}</p>}
      <Placeholder {...{ theme }} labelFocused className={`floting-label ${isValidField ? 'label-focused' : ''}`}>{placeholderText}</Placeholder>
      {isShowEye &&
        <div className='toggle-password-view' onClick={eyeOnClickHandler}>
          {!isShowPassword ? <EyeIcon color='white' /> : <HideEye color='white' />}
        </div>}
    </FormItem>
  )
})
/* --------------------------------------------- */
const FormItem = styled.div`
  ${({ theme, isValidField }) => {
    if (!isValidField) {
      return `
        & {
          border: 1px solid ${utils.isNotNull(theme, 'accent', 'error') ? theme.accent.error : 'transparent'} !important;
        }
      `
    }
  }}

  & {
    background: ${({ theme }) => utils.isNotNull(theme, 'background', 'secondary') ? theme.background.primary : "transparent"};
    color: ${({ theme }) => utils.isNotNull(theme, 'text', 'secondary') ? theme.text.secondary : "transparent"};
  }

  .empty-validation-text {
    color: ${({ theme }) => utils.isNotNull(theme, 'text', 'primary') ? theme.text.primary : "transparent"};
  }

  & .floting-label{
    font-style: italic;
    font-weight: 500;
  }

  &.form__item---valid{
    border: 1px solid transparent;
  }

  &.form__item---invalid{
    border: 1px solid ${({ theme }) => utils.isNotNull(theme, 'accent', 'error') ? theme.accent.error : "transparent"} !important;
  }

  & input {
    color: ${({ theme }) => utils.isNotNull(theme, 'text', 'primary') ? theme.text.primary : "transparent"};
  }
   
  // & input[type='password']{
  //   font-size: 16px;
  //   font-family: Verdana;
  //   font-weight: 900;
  //   letter-spacing: 0.125em;
  // }

  .validation-Error &:not(.form__item---invalid):not(.form__item---valid) input:not(:focus) ~ .floting-label {
    background: ${({ theme }) => utils.isNotNull(theme, 'background', 'secondary') ? theme.background.primary : "transparent"};
    color: ${({ theme }) => utils.isNotNull(theme, 'text', 'secondary') ? theme.text.secondary : "transparent"};
    font-style: normal;
    font-weight: 500;
  }

  &:not(.form__item---invalid):not(.form__item---valid) input:focus ~ .floting-label,
  &.form__item---valid .floting-label,
  & input:focus ~ .floting-label {
    color: ${({ theme }) => utils.isNotNull(theme, 'text', 'tertiary') ? theme.text.tertiary : "transparent"} !important;
    font-style: normal !important;
    white-space: nowrap;
  }

  .floting-label {
    color: ${({ theme }) => utils.isNotNull(theme, 'text', 'error') ? theme.text.error : "transparent"};
    font-style: normal;
  }

  &.form__item---invalid .floting-label,
  &.form__item---invalid input:focus ~ .floting-label {
    color: ${({ theme }) => utils.isNotNull(theme, 'accent', 'error') ? theme.accent.error : "transparent"} !important;
  }
`
const Placeholder = styled.p`
  color: ${({ theme: { body } }) => body && body.accent.primary};
`
/* --------------------------------------------- */
TextBox.defaultProps = {
  placeholder: ''
}
/* --------------------------------------------- */
TextBox.propTypes = {
  /** Textbox placeholder. */
  placeholder: PropTypes.string,
  /** Initial value of textbox. */
  initialValue: PropTypes.string,
  /** Textbox onChange function. */
  onChange: PropTypes.func,
  /** Textbox onPlaceholderText update function. */
  updatePlaceholderTextCallback: PropTypes.func
}
/* --------------------------------------------- */
TextBox.defaultProps = {
  placeholder: '',
  initialValue: '',
  updatePlaceholderTextCallback: () => { }
}
/* --------------------------------------------- */
export default TextBox
/* --------------------------------------------- */
