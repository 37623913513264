import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const PipExit = ({ color, ...rest }) => (
  <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path d="M30.9983 13.4612V5.4472C30.9983 4.64811 30.3502 4 29.5511 4H2.4472C1.64811 4 1 4.64811 1 5.4472V25.0362C1 25.8353 1.64811 26.4834 2.4472 26.4834H15.8017" stroke={color} stroke-width="1.8" stroke-miterlimit="10" stroke-linecap="round" />
    <path d="M30 18.1172H21.4121C20.8598 18.1172 20.4121 18.5649 20.4121 19.1172V25.9404C20.4121 26.4927 20.8598 26.9404 21.4121 26.9404H30C30.5522 26.9404 31 26.4927 31 25.9404V19.1172C31 18.5649 30.5522 18.1172 30 18.1172Z" stroke={color} fill={color} stroke-width="1.8" stroke-miterlimit="10" />
  </svg>
)
/* --------------------------------------------- */
PipExit.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
PipExit.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default PipExit
/* --------------------------------------------- */
