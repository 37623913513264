import styled from 'styled-components'

// Styled component for GetAppMobilePopup component.
export const GetAppPopupContainer = styled.div`
  background: ${({ theme: { body } }) => body && body.background.primary};

  h2 {
    color: ${({ theme: { header } }) => header && header.text.primary};
  }

  .data h2 {
    color: ${({ theme: { header } }) => header && header.text.secondary};
  }

  p {
    color: ${({ theme: { body } }) => body && body.text.secondary};
  }

  .dowload-Btn {
    color: ${({ theme: { header } }) => header && header.text.primary};
  }

`