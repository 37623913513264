import React, { useEffect, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
/* --------------------------------------------- */
import { useGlobalNew } from '../../context/global.new.context'
import { GlobalContext } from '../../context/global.context'
/* --------------------------------------------- */
import './index.scss'
import CountryPopup from './CountryPopup'
/* --------------------------------------------- */

// SettingsPopup component for displaying settings popup and handles events
/* --------------------------------------------- */
const CountrycodePopup = () => {
    const [state] = useGlobalNew()
    /* --------------------------------------------- */
    /* --------------------------------------------- */
    const { appMessages } = useSelector(store => store.appConfigReducers)
    /* --------------------------------------------- */
    const { selectedCountry } = useContext(GlobalContext)
    const [selectedCountryObj, updateCountrySelected] = useState()
    /* --------------------------------------------- */
    /* --------------------------------------------- */
    /**
     * It: Closes phone no configuration poopup
     */
    useEffect(() => {
        if (selectedCountry && Object.keys(selectedCountry).length !== 0) {
            updateCountrySelected(selectedCountry)
        }
    }, [selectedCountry, selectedCountryObj])

    const title = state.isOpenCountryCodePopup ? appMessages.label_select_country_desc : state.isOpenCableOperatorPopup ? appMessages.label_select_operator : state.isOpenPartnerPopup ? appMessages.label_select_partner : ""; // show title based on the popup

    return (
        <div className={`details-page__pop-up country-code-popup ${state.isOpenCountryCodePopup || state.isOpenCableOperatorPopup || state.isOpenPartnerPopup ? 'details-page__pop-up---open' : ''}`}>
            <CountryPopup {...{ title: title, isSearchEnabled: state.isOpenCountryCodePopup ? true : false, selectedCountryObj: !state.isOpenCountryCodePopup ? selectedCountryObj : null }} />
        </div>
    )
}
/* --------------------------------------------- */
/* --------------------------------------------- */
export default CountrycodePopup