import React from 'react';
import { withRouter } from 'react-router-dom';
/* --------------------------------------------- */
// HOC coomponent which allows to access child component state from parent.
const withRouterInnerRef = (WrappedComponent) => {

    class InnerComponentWithRef extends React.Component {
        render() {
            const { forwardRef, ...rest } = this.props;
            return <WrappedComponent {...rest} ref={forwardRef} />;
        }
    }

    const ComponentWithRef = withRouter(InnerComponentWithRef, { withRef: true });

    return React.forwardRef((props, ref) => {
        return <ComponentWithRef {...props} forwardRef={ref} />;
    });
}
/* --------------------------------------------- */
export default withRouterInnerRef