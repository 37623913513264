import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
import AppleLogin from './AppleLogin/AppleLogin'
import FacebookLogin from './FacebookLogin/FacebookLogin'
import GoogleLogin from './GoogleLogin/GoogleLogin'
/* --------------------------------------------- */
// Container component having social login components bundled in a single component.
const SocialLogin = ({ method = 'signin' }) => {
  const { userManagementConfig = {} } = useSelector(store => store.userManagement)
  /* --------------------------------------------- */
  const { registrationMethods = [] } = userManagementConfig
  /* --------------------------------------------- */
  const [isShowFBButton, showFBButton] = useState(false)
  const [isShowAppleButton, showAppleButton] = useState(false)
  const [isShowGoogleButton, showGoogleButton] = useState(false)
  /* --------------------------------------------- */
  /**
   * It: Shows relevant social login buttons.
   */
  useEffect(() => {
    showAppleButton(registrationMethods.indexOf('apple') > -1)
    showFBButton(registrationMethods.indexOf('facebook') > -1)
    showGoogleButton(registrationMethods.indexOf('google') > -1)
  }, [registrationMethods])
  /* --------------------------------------------- */
  return (
    <>
      <div className='social-login-wrapper'>
        {isShowAppleButton && <AppleLogin method={method} />}
        {isShowFBButton && <FacebookLogin method={method} />}
        {isShowGoogleButton && <GoogleLogin method={method} />}
      </div>
    </>
  )
}
/* --------------------------------------------- */
export default SocialLogin
