import { ANALYTICS_INTEGRATIONS } from '../constants/actionTypes'
import utils from '../utils/utils'
import { filter } from '../utils/contentfulHandler'

/** Analytics store */
export const fetchAnalyticsConfig = (features = [], options) => {
  return dispatch => {
    features = filter(features, '', options)

    let featureAnalytics = features.map(item => {
      if (item.contentTypeId === 'featureAnalytics') return item
      return null
    }).filter(feature => feature !== null)

    featureAnalytics = filter(featureAnalytics, '', options)

    if (utils.isNotNull(featureAnalytics, 0, 'analyticsIntegrations')) {
      const analyticsIntegrations = filter(featureAnalytics[0].analyticsIntegrations, '', options)
      const analytics = analyticsIntegrations.map((item) => {
        return item
      })
      dispatch({
        type: ANALYTICS_INTEGRATIONS,
        value: analytics
      })
    }
    return Promise.resolve()
  }
}
