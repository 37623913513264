import React from 'react'
import ErrorToast from './ErrorToast'
import Provider from './context'
import { Wrapper } from './style'
/* ---------------------------------------------------------------------------------------------------- */
// Wrapper component for ErrorToast component.
const ErrorToastWrapper = () => {
    return (
        <Provider>
            <Wrapper>
                <ErrorToast />
            </Wrapper>
        </Provider>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
export default ErrorToastWrapper
/* ---------------------------------------------------------------------------------------------------- */
