import React from 'react'
import Popup from "./Popup";

//Wrapper component of Popup component.
const Wrapper = (props) => {
    return (
        <>
            <Popup {...props} />
        </>
    )
}
export default Wrapper