import { getNew, getNewObj } from '../utils/contentfulHandler'
import getThemeSection from './getThemeSection'
import getCompositeStyle from './getCompositeStyle'
import getFilter from './getFilter'
import getGraphics from './getGraphics'

// Helper function to form contentful tree
const getTheme = (contentful) => {
  const themeSectionLocal = getThemeSection(contentful)
  const compositeStyleLocal = getCompositeStyle(contentful)
  const filterLocal = getFilter(contentful)
  const graphicsLocal = getGraphics(contentful)
  const themeLocal = contentful.theme
  return themeLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { header = {}, body = {}, footer = {}, compositeStyle = {}, filter = {}, graphics = [] } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      header: getNewObj(header, [themeSectionLocal]),
      body: getNewObj(body, [themeSectionLocal]),
      footer: getNewObj(footer, [themeSectionLocal]),
      compositeStyle: getNewObj(compositeStyle, [compositeStyleLocal]),
      filter: getNewObj(filter, [filterLocal]),
      graphics: getNew(graphics, graphicsLocal)
    }
  })
}

export default getTheme
