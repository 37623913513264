import React, { useContext } from 'react'
import { ErrorContext } from "../../../context/error.context";
/* ---------------------------------------------------------------------------------------------------- */
export const Context = ErrorContext
/* ---------------------------------------------------------------------------------------------------- */
// ErrorSnackbar component's context.
const Provider = (props) => {
  const contextProps = useContext(Context)
  /* -------------------------------------------------------------------------------------------------- */
  return (
    <Context.Provider value={{ ...contextProps , ...props.value }}>
      {props.children}
    </Context.Provider>
  )
}
/* ---------------------------------------------------------------------------------------------------- */
export default Provider
/* ---------------------------------------------------------------------------------------------------- */
