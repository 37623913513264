import { mergeThemes, getThemeData } from '../utils/contentfulThemeMerger'
import { CATALOG_CONFIG } from '../constants/actionTypes'
import { filter, hydratePageComponent } from '../utils/contentfulHandler'
import utils from '../utils/utils'

/** CatalogConfig store */
export const fetchCatalogConfig = (catalog, themeData, options) => {
  const { appThemeData, componentThemeData } = themeData
  return (dispatch) => {
    catalog = filter(catalog, '', options)
    let catalogTheme = {}
    if (utils.isNotNull(catalog, 0)) {
      const catalogConfig = catalog[0]
      if (utils.isNotNull(catalogConfig, 'theme')) {
        const theme = filter(catalogConfig.theme, '', options)
        if (utils.isNotNull(theme, 0)) {
          catalogTheme = getThemeData(theme[0], options)
          catalogTheme = mergeThemes(appThemeData, catalogTheme)
        } else if (appThemeData) catalogTheme = appThemeData
      } else if (appThemeData) catalogTheme = appThemeData

      let episodeConfig = {}
      let episodeComponentStyle = []
      let episodeComponentTheme = {}
      let episodeOverflowTheme = {}

      if (utils.isNotNull(catalogConfig, 'episodeListingComponent')) {
        const components = filter(catalogConfig.episodeListingComponent, '', options)
        if (utils.isNotNull(components, 0)) {
          const component = components[0]
          const {
            config, componentStyles, componentTheme, overflowTheme
          } = hydratePageComponent(component, options, {
            defaultPageTheme: catalogTheme,
            defaultComponentTheme: componentThemeData
          })
          episodeConfig = config
          episodeComponentStyle = componentStyles
          episodeComponentTheme = componentTheme
          episodeOverflowTheme = overflowTheme
        }
      }
      let relatedComponent = {}
      let rConfig = {}
      if (utils.isNotNull(catalogConfig, 'relatedMediaComponent')) {
        const components = filter(catalogConfig.relatedMediaComponent, '', options)
        relatedComponent = components[0] || {}
        if (utils.isNotNull(relatedComponent, 'contents')) {
          const contents = filter(relatedComponent.contents, '', options)
          if (utils.isNotNull(contents, 0)) {
            rConfig = contents[0]
          }
        }
      }
      let productListingComponent = {}
      if (utils.isNotNull(catalogConfig, 'productListingComponent')) {
        const components = filter(catalogConfig.productListingComponent, '', options)
        productListingComponent = components[0] || {}
      }

      dispatch({
        type: CATALOG_CONFIG,
        value: {
          rConfig: rConfig,
          theme: catalogTheme,
          catalogTheme,
          catalogComponentThemeData: componentThemeData,
          episodeConfig,
          episodeComponentStyle,
          episodeComponentTheme,
          episodeOverflowTheme,
          relatedComponent,
          productListingComponent
        }
      })
    }
    return Promise.resolve()
  }
}
