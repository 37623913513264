import React, { createContext, useReducer } from 'react'
/* ---------------------------------------------------------------------------------------------------- */
/** Error initial state */
const initialState = {
  errorPage: {},
  errorPopup: {},
  errorFullscreen: {},

  errorToast: {},
  errorSnackbar: {},
  dispatch: () => { }
}
/* ---------------------------------------------------------------------------------------------------- */
/** Error context */
export const ErrorContext = createContext(initialState)
/* ---------------------------------------------------------------------------------------------------- */
/** Error reducer */
const ErrorReducer = (state, action) => {
  switch (action.type) {
    case 'ERROR_PAGE':
      return { ...state, errorPage: action.value }
    case 'ERROR_POPUP':
      return { ...state, errorPopup: action.value }
    case 'ERROR_FULLSCREEN':
      return { ...state, errorFullscreen: action.value }
    case 'ERROR_TOAST':
      return { ...state, errorToast: action.value }
    case 'ERROR_SNACKBAR':
      return { ...state, errorSnackbar: action.value }
    default:
      return state
  }
}
/* ---------------------------------------------------------------------------------------------------- */
/** Error provider */
const ErrorProvider = (props) => {
  const [state, dispatch] = useReducer(ErrorReducer, initialState)
  /* ------------------------------------------------------------------------------------------------ */
  return (
    <ErrorContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </ErrorContext.Provider>
  )
}
/* ---------------------------------------------------------------------------------------------------- */
export default ErrorProvider
/* ---------------------------------------------------------------------------------------------------- */
