export const contentfulConfig = {}
contentfulConfig.dev = {
  url: 'https://d2nadp1th936k8.cloudfront.net/v1b3/config',
  space: 'x9lr1mpsvy7i',
  accessToken: 'Yi9DHxzFwrPkO-wOsIRXm7GE9CsIzJ_vrItvAyAjqHE',
  environment: 'verticast_dev'
}
// contentfulConfig.qa = contentfulConfig.dev
contentfulConfig.qa = {
  url: 'https://d283j0u548tdgr.cloudfront.net/v1b3/config',
  space: 'wsbhs7osj38w',
  accessToken: 'mEYNTxuMIgCryaFImP4Atig4NXUm0fUNVWXoHjLrnII',
  environment: 'master'
}
contentfulConfig.staging = {
  url: 'https://api-staging.csport.tv/v1b3/config',
  space: 'wsbhs7osj38w',
  accessToken: 'mEYNTxuMIgCryaFImP4Atig4NXUm0fUNVWXoHjLrnII',
  environment: 'master'
}
contentfulConfig.preprod = {
  url: 'https://d2rtlczwkhvom2.cloudfront.net/config',
  space: 'wsbhs7osj38w',
  accessToken: 'mEYNTxuMIgCryaFImP4Atig4NXUm0fUNVWXoHjLrnII',
  appId: '7kOKcw9FlPrcCzy7mkdxyc',
  environment: 'master'
}
// contentfulConfig.prod = contentfulConfig.preprod
contentfulConfig.prod = {
  url: 'https://api.csport.tv/v1b3/config',
  space: 'wsbhs7osj38w',
  accessToken: 'mEYNTxuMIgCryaFImP4Atig4NXUm0fUNVWXoHjLrnII',
  appId: '7kOKcw9FlPrcCzy7mkdxyc',
  environment: 'master'
}
/* --------------------------------------------- */
export const firebaseConfig = {}
firebaseConfig.dev = {
  apiKey: "AIzaSyDYrAMthkIv3SxU4fvwYxbFx0uhoez72PE",
  authDomain: "verticast-2291d.firebaseapp.com",
  // databaseURL: "https://create-566be.firebaseio.com",
  projectId: "verticast-2291d",
  storageBucket: "verticast-2291d.appspot.com",
  messagingSenderId: "853429625657",
  appId: "1:853429625657:web:8e812c9d38928a5e94bee8",
  measurementId: "G-4WPTD9S29S"
};
firebaseConfig.qa = {
  apiKey: "AIzaSyDYrAMthkIv3SxU4fvwYxbFx0uhoez72PE",
  authDomain: "verticast-2291d.firebaseapp.com",
  // databaseURL: "https://create-566be.firebaseio.com",
  projectId: "verticast-2291d",
  storageBucket: "verticast-2291d.appspot.com",
  messagingSenderId: "853429625657",
  appId: "1:853429625657:web:8e812c9d38928a5e94bee8",
  measurementId: "G-4WPTD9S29S"
}
firebaseConfig.staging = {
  apiKey: "AIzaSyBFbjCcevXff1-RWPFSnxjhh09mi-uFxhc",
  authDomain: "verticast-1be4f.firebaseapp.com",
  projectId: "verticast-1be4f",
  storageBucket: "verticast-1be4f.appspot.com",
  messagingSenderId: "82336180683",
  appId: "1:82336180683:web:041af8fec930aa5ccfee4e",
  measurementId: "G-MS0HYGCDN6"
}
firebaseConfig.prod = {
  apiKey: "AIzaSyAlV4l8FF9630Rmk4gSqkEo08olyYCYLdQ",
  authDomain: "verticast-prod.firebaseapp.com",
  projectId: "verticast-prod",
  storageBucket: "verticast-prod.appspot.com",
  messagingSenderId: "873511701107",
  appId: "1:873511701107:web:c0b4bbf3e984e97f9bb806",
  measurementId: "G-E81S2JJ74B"
}
firebaseConfig.preprod = firebaseConfig.prod

/* --------------------------------------------- */
export const bugsnagConfig = {}
bugsnagConfig.dev = {
  id: 'befb82d6d799c424a4bb50891cb394c3'
}
bugsnagConfig.qa = bugsnagConfig.dev
bugsnagConfig.staging = {
  id: '6b79a4cee3facdc3a46aa02743cc06e3'
}
bugsnagConfig.preprod = bugsnagConfig.staging
bugsnagConfig.prod = {
  id: '12e8b80900cf7477274333babba5eaef'
}
/* --------------------------------------------- */
export const logglyConfig = {}
logglyConfig.dev = {
  id: 'a36cd2f8-de84-44d8-8625-3dd573de8020'
}
logglyConfig.qa = logglyConfig.dev
logglyConfig.staging = {
  id: '1fef9a49-1d04-4cf6-b7be-9c8ab3d47e98'
}
logglyConfig.preprod = logglyConfig.staging
logglyConfig.prod = logglyConfig.preprod
/* --------------------------------------------- */
export const chromecastConfig = {}
chromecastConfig.dev = {
  id: '74F1BE46'
}
chromecastConfig.qa = {
  id: '37C1CDF9'
}
chromecastConfig.staging = {
  id: '2F27EC59'
}
chromecastConfig.prod = {
  id: 'E85208B1'
}
/* --------------------------------------------- */
