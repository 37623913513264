import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getImage from './getImage'
import getGraphics from './getGraphics'
import getLabel from './getLabel'
import getPage from './getPage'

// Helper function to form contentful tree
const getCuratedDataEntry = (contentful) => {
  const filterLocal = getFilter(contentful)
  const imageLocal = getImage(contentful)
  const graphicsLocal = getGraphics(contentful)
  const labelLocal = getLabel(contentful)
  const pageLocal = getPage(contentful, true) // to get the page filter
  const curatedDataEntryLocal = contentful.curatedDataEntry
  return curatedDataEntryLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { filter = {}, images = [], graphics = [],listData = [], page = [] } = fields

    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      images: getNew(images, imageLocal),
      graphics: getNew(graphics, graphicsLocal),
      listData: getNew(listData, labelLocal),
      page: getNew(page, pageLocal)
    }
  })
}

export default getCuratedDataEntry
