import { useContext, useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { ErrorContext } from '../context/error.context'
import useLoggly from '../containers/useLoggly'
import useCustomErrorConfigs from '../utils/useCustomErrorConfigs'
import _utils from './utils'
/* ---------------------------------------------------------------------------------------------------- */
/** Custom hook to dispatch error events to show/hide error screens */
const useErrorUtils = () => {
  const errorConfigurationRef = useRef([])
  const mwErrorConfigurationRef = useRef([])
  const { dispatch } = useContext(ErrorContext)
  const { supportConfig } = useSelector(store => store.support)
  const { getErrors } = useCustomErrorConfigs()
  const { log } = useLoggly()
  /* -------------------------------------------------------------------------------------------------- */
  useEffect(() => {
    errorConfigurationRef.current = supportConfig.errorConfiguration || []
    mwErrorConfigurationRef.current = supportConfig.mwErrorConfiguration || {}
  }, [supportConfig])
  /* -------------------------------------------------------------------------------------------------- */
  const showError = useCallback(({ type = '', mwErrorCode = null, hideSecondaryButton = false, hidePrimaryButton = false, defaultErrorType = '', value = {}, module='', errorMessage = '', errorInfo = {}, isSendLog = true, errorIcon = null, customErrorCode = null, customCodeMessage = null, userId = null }) => {
    const errorConfiguration = errorConfigurationRef.current || []
    const mwErrorConfiguration = mwErrorConfigurationRef.current || {}
    /* ------------------------------------------------------------------------------------------------ */
    if (!type && !mwErrorCode && !defaultErrorType) return
    /* ------------------------------------------------------------------------------------------------ */
    let config = {};
    
    // if mwErrorCode is available finding if any of the error configuration containing mw error code
    if(mwErrorCode) {
      config = mwErrorConfiguration?.[mwErrorCode]
      // if no configuration available will be showing defaultErrorType
      if(!config)
      config = (errorConfiguration || []).find((config) => {
        return config.type === defaultErrorType
      }) || null
    } else {
      config = (errorConfiguration || []).find((config) => {
        return config.type === type
      }) || null
    }

    //passing show/hide button config to corresponding error components
    if(config) {
      config["hidePrimaryButton"] = hidePrimaryButton;
      config["hideSecondaryButton"] = hideSecondaryButton;
    }
    /* ------------------------------------------------------------------------------------------------ */
    if (!config) {
      const errors = getErrors()
      config = errors.filter((config) => {
        return config.type === type
      })[0]
    }
    /* ------------------------------------------------------------------------------------------------ */
    const {
      type: errorType,
      // title,
      description,
      code,
      uiType,
      loggingLevel
      // primaryActionType,
      // secondaryActionType,
      // primaryActionLabel,
      // secondaryActionLabel,
      // secondaryActionDescription,
      // faqDescription,
      // faqLabel,
      // faqItem,
      // theme
    } = config || {}
  
    /* ------------------------------------------------------------------------------------------------ */
    let errorTypeVariable = ''
    let moduleVariable = ''

    if (code === 'A-5007') {
      // GenericNoSearch
      errorTypeVariable = loggingLevel || 'Debug'
      moduleVariable = 'Search'
    } else if (code === 'A-3001') {
      // LoginSessionExpire
      errorTypeVariable = loggingLevel || 'Error'
      moduleVariable = 'Authentication'
    } else if (code === 'A-1001') {
      // AppNoNetwork
      errorTypeVariable = loggingLevel || 'Debug'
      moduleVariable = 'General'
    } else if (code === 'A-1002' || type === "AppMaintenance") {
      // AppMaintenance
      errorTypeVariable = loggingLevel || 'Debug'
      moduleVariable = 'General'
    } else if (code === 'A-3002') {
      // LoginUnsupportedUserType
      errorTypeVariable = loggingLevel || 'Info'
      moduleVariable = 'Authentication'
    } else if (code === 'A-3005') {
      // UserAccountCreationFailed
      errorTypeVariable = loggingLevel || 'Error'
      moduleVariable = 'Authentication'
    } else if (code === 'A-3006') {
      // UserProfileSavingFailed
      errorTypeVariable = loggingLevel || 'Error'
      moduleVariable = 'Authentication'
    } else if (code === 'A-3008') {
      // LoginUnsupportedSubscriptionType
      errorTypeVariable = loggingLevel || 'Info'
      moduleVariable = 'Authentication'
    } else if (code === 'A-2002') {
      // PlaybackDRM
      errorTypeVariable = loggingLevel || 'Error'
      moduleVariable = 'Player'
    } else if (code === 'A-1006') {
      // TimeDifferenceExceeded
      errorTypeVariable = loggingLevel || 'Error'
      moduleVariable = 'General'
    } else if (code === 'A-3009') {
      // UserLoginFailed
      errorTypeVariable = loggingLevel || 'Error'
      moduleVariable = 'Authentication'
    } else if (code === 'A-3010') {
      // UserDoesnotExist
      errorTypeVariable = loggingLevel || 'Info'
      moduleVariable = 'Authentication'
    }
    else if (code === 'A-5009') {
      // GenericNoFavourite
      errorTypeVariable = loggingLevel || 'Debug'
      moduleVariable = 'Profile'
    } else if (code === 'A-5001') {
      // GenericErrorRemoveFavourite
      errorTypeVariable = loggingLevel || 'Debug'
      moduleVariable = 'Profile'
    } else if (code === 'A-5002') {
      // GenericAddFavourite
      errorTypeVariable = loggingLevel || 'Debug'
      moduleVariable = 'Profile'
    }
    // send log to loggly - if its Error send more info, if its info/debug then send module and debug_message to loggly
    if (isSendLog && (errorTypeVariable || module === "Payment")) { // need to remove this logic after we implement adaptive error handling in throughout the project
      const loggingLevels = config?.loggingLevel ? _utils.capitalizeFirstLetter(config?.loggingLevel) : _utils.capitalizeFirstLetter(errorTypeVariable)
      if(loggingLevels === "Error" || module === "Payment") { // need to remove this logic after we implement adaptive error handling in throughout the project
        log({
          type: loggingLevels || 'Error', //send error to loggly based on the logging level value for the error config
          attr: {
            module: moduleVariable || module,
            error_type: errorType,
            error_code:  config.code,
            // error_message: errorMessage,
            // message: description,
            // errorInfo,
            ...(loggingLevels === "Error" && module !== "Payment" && {error_info: {
              request_header: errorInfo?.request_body && JSON.stringify(errorInfo?.request_body),
              request_url : errorInfo?.request_url,
              response_code : errorInfo?.response_code,
              response_message : errorInfo?.response_body?.message ? JSON.stringify(errorInfo?.response_body?.message) : (errorInfo?.response_body && JSON.stringify(errorInfo?.response_body)),
              error_location: window.location.href
            }}),
            ...(module === "Payment" && {payment_error_info: { //payment error info sending to the loggly
              request_header: errorInfo?.requestBody && JSON.stringify(errorInfo?.requestBody),
              request_url : errorInfo?.apiUrl,
              transaction_error_message : errorInfo?.responseBody?.error?.message,
              transaction_error_code : errorInfo?.responseBody?.error?.errorCode,
              transaction_error_response : errorInfo?.responseBody && JSON.stringify(errorInfo?.responseBody),
              payment_mode: errorInfo?.provider
            }}),
            mw_error_code: errorInfo?.mw_error_code,
            error_shown: true,
            pageUrl:window.location.href,
            retry_count: errorInfo?.retry_count, 
            ...(userId && {user_id: userId}) // added userId parameter in error log
          }
        })
      } else {
        log({
          type: config?.loggingLevel ? _utils.capitalizeFirstLetter(config?.loggingLevel) : _utils.capitalizeFirstLetter(errorTypeVariable),
          attr: {
            module: moduleVariable || module,
            debug_message: description,
            ...(errorType && {error_type: errorType}),
            ...(code && {error_code: code}),
          }
        })
      }
    }
    /* ------------------------------------------------------------------------------------------------ */
    if (!config) return
    /* ------------------------------------------------------------------------------------------------ */
    if (uiType === 'page') dispatch({ type: 'ERROR_PAGE', value: { ...config, ...value, errorIcon, customErrorCode, customCodeMessage } })
    else if (uiType === 'popup') dispatch({ type: 'ERROR_POPUP', value: { ...config, ...value } })
    else if (uiType === 'fullscreen') dispatch({ type: 'ERROR_FULLSCREEN', value: { ...config, ...value } })
    else if (uiType === 'toast') dispatch({ type: 'ERROR_TOAST', value: { ...config, ...value } })
    else if (uiType === 'snackbar') dispatch({ type: 'ERROR_SNACKBAR', value: { ...config, ...value } })
    /* ------------------------------------------------------------------------------------------------ */
  }, [
    dispatch, getErrors, log
  ])
  /* -------------------------------------------------------------------------------------------------- */
  /** This function should be called to hide an error screen */
  const hideError = useCallback(({ type = '', isSendLog=false }) => {
    const errorConfiguration = errorConfigurationRef.current || []
    /* ------------------------------------------------------------------------------------------------ */
    if (!type) return
    /* ------------------------------------------------------------------------------------------------ */
    const config = errorConfiguration.filter((config) => {
      return config.type === type
    })[0]
    /* ------------------------------------------------------------------------------------------------ */
    if (!config) return
    /* ------------------------------------------------------------------------------------------------ */
    const { uiType } = config
    /* ------------------------------------------------------------------------------------------------ */
    if (uiType === 'page') dispatch({ type: 'ERROR_PAGE', value: {} })
    else if (uiType === 'popup') dispatch({ type: 'ERROR_POPUP', value: {} })
    else if (uiType === 'fullscreen') dispatch({ type: 'ERROR_FULLSCREEN', value: {} })
    else if (uiType === 'toast') dispatch({ type: 'ERROR_TOAST', value: {} })
    else if (uiType === 'snackbar') dispatch({ type: 'ERROR_SNACKBAR', value: {} })
    let errorTypeVariable = '';
    let moduleVariable = '';
    if (config?.code === 'A-1001') {
      // AppNoNetwork - to send log AppNoNetwork when offline
      errorTypeVariable = 'Debug'
      moduleVariable = 'General'
    }
    // send log to loggly
    if(isSendLog) {
      log({
        type: config?.loggingLevel ? _utils.capitalizeFirstLetter(config?.loggingLevel) : _utils.capitalizeFirstLetter(errorTypeVariable),
        attr: {
          module: moduleVariable || module,
          debug_message: config?.description,
        }
      })
    }
  }, [dispatch, log])
  /* -------------------------------------------------------------------------------------------------- */
  /** This function should be called to hide all errors */
  const hideAllErrors = useCallback(() => {
    dispatch({ type: 'ERROR_PAGE', value: {} })
    dispatch({ type: 'ERROR_POPUP', value: {} })
    dispatch({ type: 'ERROR_FULLSCREEN', value: {} })
    dispatch({ type: 'ERROR_TOAST', value: {} })
    dispatch({ type: 'ERROR_SNACKBAR', value: {} })
  }, [dispatch])
  /* -------------------------------------------------------------------------------------------------- */
  return { showError, hideError, hideAllErrors }
}
/* ---------------------------------------------------------------------------------------------------- */
export default useErrorUtils
/* ---------------------------------------------------------------------------------------------------- */
