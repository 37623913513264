import {
  PAGE_THEME,
  PAGE_COMPONENT,
  FOOTER_MENU_CONFIG,
  PRIMARY_MENU_CONFIG,
  SPECIAL_MENU_CONFIG,
  SECONDARY_MENU_CONFIG
} from '../constants/actionTypes'
import inialState from './initalState'
/* --------------------------------------------- */
/** Navigation config reducer */
const navigation = (state = inialState, action) => {
  switch (action.type) {
    case FOOTER_MENU_CONFIG:
      return {
        ...state,
        footerMenuConfig: action.value
      }
    case PRIMARY_MENU_CONFIG:
      return {
        ...state,
        primaryMenuConfig: action.value
      }
    case SECONDARY_MENU_CONFIG:
      return {
        ...state,
        secondaryMenuConfig: action.value
      }
    case SPECIAL_MENU_CONFIG:
      return {
        ...state,
        specialMenuConfig: action.value
      }
    case PAGE_THEME:
      return {
        ...state,
        pageTheme: action.value
      }
    case PAGE_COMPONENT:
      return {
        ...state,
        pageComponent: action.value
      }
    default: return state
  }
}
/* --------------------------------------------- */
export default navigation
/* --------------------------------------------- */
