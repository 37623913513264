import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getLabel from './getLabel'

// Helper function to form contentful tree
const getLabelGroup = (contentful) => {
  const filterLocal = getFilter(contentful)
  const labelLocal = getLabel(contentful)
  const labelGroupLocal = contentful.labelGroup
  return labelGroupLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { labels = [], filter = {} } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      labels: getNew(labels, labelLocal),
      filter: getNewObj(filter, [filterLocal])
    }
  })
}

export default getLabelGroup
