import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getAnalyticIntegration from './getAnalyticIntegration'

// Helper function to form contentful tree
const getFeatureAnalytics = (contentful) => {
  const filterLocal = getFilter(contentful)
  const analyticIntegrationLocal = getAnalyticIntegration(contentful)
  const featureAnalyticsLocal = contentful.featureAnalytics
  return featureAnalyticsLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      filter = {}, analyticsIntegrations = []
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      analyticsIntegrations: getNew(analyticsIntegrations, analyticIntegrationLocal)
    }
  })
}

export default getFeatureAnalytics
