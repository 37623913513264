import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const FastForwardIcon = ({ color, ...rest }) => (
  <svg viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} >
    <path 
    fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M0.000976562 1.56C0.000976562 0.641 0.753977 0 1.56298 0C1.86498 0 2.17498 0.09 2.45598 0.288L13.325 7.96C14.204 8.58 14.204 9.884 13.325 10.505L2.45598 18.177C2.17498 18.375 1.86498 18.465 1.56298 18.465C0.753977 18.465 0.000976562 17.824 0.000976562 16.904V1.56ZM11.659 9.232L1.99998 2.414V16.05L11.659 9.232ZM16.0171 1.56C16.0171 0.641 16.7711 0 17.5791 0C17.8811 0 18.1911 0.09 18.4721 0.288L29.3411 7.96C30.2201 8.58 30.2201 9.884 29.3411 10.505L18.4721 18.177C18.1911 18.375 17.8811 18.465 17.5791 18.465C16.7711 18.465 16.0171 17.824 16.0171 16.904V1.56ZM27.6751 9.232L18.0161 2.414V16.05L27.6751 9.232Z" 
    />
  </svg>
)  
/* --------------------------------------------- */
FastForwardIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
FastForwardIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default FastForwardIcon
/* --------------------------------------------- */
