import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 20px;
  input[type="radio"] {
    -webkit-appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1.7907px solid ${({theme}) => theme && theme?.body?.text?.tertiary?.code};;
    outline: none;
    cursor: pointer;
  }

  input[type="radio"]:before {
    content: "";
    display: block;
    width: 60%;
    height: 60%;
    margin: 20% auto;
    border-radius: 50%;
  }

  input[type="radio"]:checked:before {
    background: ${({theme}) => theme && theme?.body?.text?.primary?.code};
  }

  input[type="radio"]:checked {
    border: 1.7907px solid ${({theme}) => theme && theme?.body?.text?.primary?.code};
  }
  
  label {
    color: ${({theme}) => theme && theme?.body?.text?.secondary?.code};
    margin-top: 2px;
    ${({uiDirection}) => {
      if(uiDirection === "rtl"){
        return 'margin-right: 15px; margin-left: unset;'
      }else {
        return 'margin-left: 15px; margin-right: unset;'
      }
    }}
    font-weight: 600;
    font-size: 20px;
  }
`;
