import { ANALYTICS_INTEGRATIONS } from '../constants/actionTypes'
import inialState from './initalState'
/* --------------------------------------------- */
/** Analytics reducer */
const analytics = (state = inialState, action) => {
  switch (action.type) {
    case ANALYTICS_INTEGRATIONS:
      return {
        ...state,
        analyticsIntegrations: action.value
      }
    default: return state
  }
}
/* --------------------------------------------- */
export default analytics
/* --------------------------------------------- */
