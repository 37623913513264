import { CHANNEL_PROGRAM_LIST } from '../constants/actionTypes'
import inialState from './initalState'
/* --------------------------------------------- */
/** Analytics reducer */
const channelProgramList = (state = inialState.channelProgramList, action) => {
  switch (action.type) {
    case CHANNEL_PROGRAM_LIST:
    const date = action.date.toISOString().split('T')[0]
      return {
        ...state,
        channelProgramList: { ...state.channelProgramList, [date]:action.value }
      }
    default: return state
  }
}
/* --------------------------------------------- */
export default channelProgramList
/* --------------------------------------------- */
