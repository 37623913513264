import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getLabel from './getLabel'
import getPlayerControlConfiguration from './getPlayerControlConfiguration'

// Helper function to form contentful tree
const getFeaturePlayer = (contentful) => {
  const filterLocal = getFilter(contentful)
  const playerControlConfigurationLocal = getPlayerControlConfiguration(contentful)
  const labelLocal = getLabel(contentful)
  const featurePlayerLocal = contentful.featurePlayer
  return featurePlayerLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      filter = {}, qualityMapping = [], fullScreenConfig = [], portraitConfig = [], pipConfig = [],
      linearPlayerConfig = [], castConfig = []
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      qualityMapping: getNew(qualityMapping, labelLocal),
      fullScreenConfig: getNew(fullScreenConfig, playerControlConfigurationLocal),
      portraitConfig: getNew(portraitConfig, playerControlConfigurationLocal),
      pipConfig: getNew(pipConfig, playerControlConfigurationLocal),
      linearPlayerConfig: getNew(linearPlayerConfig, playerControlConfigurationLocal),
      castConfig: getNew(castConfig, playerControlConfigurationLocal)
    }
  })
}

export default getFeaturePlayer
