import { useEffect, useCallback, useRef, useContext } from 'react'
/* --------------------------------------------- */
/** Firebase imports */
import firebase from 'firebase/app'
import "firebase/performance"
/* --------------------------------------------- */
import { GlobalContext } from '../context/global.context'
/* --------------------------------------------- */
import { version } from '../config'
import utils from '../utils/utils'
/* --------------------------------------------- */
const staticAttrs = {
    appVersion: version.appVersion
}
/* --------------------------------------------- */
export const firebaseTrace = (name, attr = {}) => {
    name = name || ''
    attr = attr || {}
    let trace = null
    /* --------------------------------------------- */
    const addAttrs = (attr) => {
        if (trace) {
            attr = attr || {}
            Object.keys(attr).map((key) => {
                if (attr[key]) {
                    try {
                        trace.putAttribute(key, attr[key])
                    } catch (e) {
                        // console.error("error", e.message)
                    }
                }
                return null
            })
        }
    }
    /* --------------------------------------------- */
    const stop = () => {
        if (trace){
            try{
                trace.stop()
            }catch(e){
                // console.error(e)
            }
        }
    }
    /* --------------------------------------------- */
    if (!utils.isLocalHost()) {
        attr = { ...staticAttrs, ...attr }
        const firebasePerformance = firebase.performance()
        trace = firebasePerformance.trace(name)
        addAttrs(attr)
        trace.start()
    }
    /* --------------------------------------------- */
    return {
        addAttrs,
        stop
    }
}
/* --------------------------------------------- */
const useFirebaseTrace = () => {
    const { sessionId } = useContext(GlobalContext)
    const sessionIdRef = useRef(sessionId)
    /* --------------------------------------------- */
    useEffect(() => { sessionIdRef.current = sessionId }, [sessionId])
    /* --------------------------------------------- */
    const fbTrace = useCallback((name, attr = {}) => {
        name = name || ''
        attr = attr || {}
        /* --------------------------------------------- */
        const commonAttr = {
            ...staticAttrs,
            sessionId: sessionIdRef.current || ''
        }
        attr = { ...commonAttr, ...attr }
        /* --------------------------------------------- */
        return firebaseTrace(name, attr)
    }, [])
    /* --------------------------------------------- */
    return fbTrace
}

export default useFirebaseTrace