import React from 'react'
import Sidebar from "./Sidebar";

// Wrapper component for Sidebar component
const Wrapper = (props) => {
    return (
        <>
            <Sidebar {...props} />
        </>
    )
}

export default Wrapper


