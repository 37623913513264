import React, { useEffect, useContext, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import uuidv1 from 'uuid/v1'
import { setAuthorizeUrl } from './Login/Login.helper'
import { setStateUUID } from '../../utils/sessionStorageService'
import { GlobalContext } from '../../context/global.context'
import Spinner from '../Spinner'
/* ---------------------------------------------------------------------------------------------------- */
const InitiateLoginRedirect = () => {
    const [authConfig, setAuthConfig] = useState({})
    const uuid = useRef(uuidv1())
    /* ------------------------------------------------------------------------------------------------ */
    const _uuid = uuid.current
    /* ------------------------------------------------------------------------------------------------ */
    const { userManagementConfig = {} } = useSelector(store => store.userManagement)
    const { authConfig: userAuthConfig = [] } = userManagementConfig
    /* ------------------------------------------------------------------------------------------------ */
    const { isAppLoaded } = useContext(GlobalContext)
    /* ------------------------------------------------------------------------------------------------ */
    /**
    * It: Sets authConfig.
    */
    useEffect(() => {
        const authConfig = userAuthConfig.filter((config) => config.identityProvider === 'vdt')
        if (authConfig.length > 0) {
            setAuthConfig(authConfig[0])
        }
    }, [
    userAuthConfig
    ])
    /* ------------------------------------------------------------------------------------------------ */
    /**
     * When: Used during SSO
     * It: Redirects user to login to app
     */
    useEffect(() => {
        if (!isAppLoaded) return
    
        const url = String(window.location.href)
        if(url.includes('/initiateLogin')) {
          setStateUUID(_uuid)
          const _url = setAuthorizeUrl(authConfig, _uuid)
          if (window.opener) window.opener.authWindow = window
          window.location.href = _url
          return
        }
    }, [isAppLoaded, _uuid, authConfig])
    /* ------------------------------------------------------------------------------------------------ */
    return <Spinner size={50} />
}
/* ---------------------------------------------------------------------------------------------------- */
export default InitiateLoginRedirect
