import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const PreviousBtnOutlineIcon = ({ color, ...rest }) => (

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 30" fill="none" style={{ width: 10, height: 10 }} {...rest}>
    <path 
    fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M23.0778 27.0788L8.38253 15L23.0778 2.92126V27.0788ZM23.421 29.7863C23.5916 29.9278 23.8025 30 24.0153 30C24.1513 30 24.2882 29.971 24.4166 29.91C24.7438 29.7553 24.9528 29.4253 24.9528 29.0625V0.937512C24.9528 0.5747 24.7438 0.2447 24.4166 0.0900124C24.0875 -0.0646751 23.7003 -0.0178001 23.421 0.213762L6.31159 14.2763C6.09409 14.4535 5.96847 14.7197 5.96847 15C5.96847 15.2803 6.09409 15.5466 6.31159 15.7238L23.421 29.7863ZM0.34375 29.0625C0.34375 29.5809 0.76375 30 1.28125 30C1.79875 30 2.21875 29.5809 2.21875 29.0625V0.937512C2.21875 0.419075 1.79875 1.24167e-05 1.28125 1.24167e-05C0.76375 1.24167e-05 0.34375 0.419075 0.34375 0.937512V29.0625Z" 
    />
  </svg>    

)
/* --------------------------------------------- */
PreviousBtnOutlineIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
PreviousBtnOutlineIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default PreviousBtnOutlineIcon
/* --------------------------------------------- */
