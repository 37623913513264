import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getTheme from './getTheme'

// Helper function to form contentful tree
const getFeatureProfileManagement = (contentful) => {
  const filterLocal = getFilter(contentful)
  const themeLocal = getTheme(contentful)
  const featureProfileManagementLocal = contentful.featureProfileManagementLocal
  return featureProfileManagementLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const {
      filter = {},
      themeProfileList = [],
      themeProfilePopup = [],
      themeProfileDetails = []
    } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      themeProfileList: getNew(themeProfileList, themeLocal),
      themeProfilePopup: getNew(themeProfilePopup, themeLocal),
      themeProfileDetails: getNew(themeProfileDetails, themeLocal)
    }
  })
}

export default getFeatureProfileManagement
