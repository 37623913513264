import { getNewObj, getNew } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getPage from './getPage'

// Helper function to form contentful tree
const getFeatureRevenue = (contentful) => {
  const filterLocal = getFilter(contentful)
  const pageLocal = getPage(contentful)
  const featureRevenueLocal = contentful.featureRevenue
  return featureRevenueLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { filter = {}, productListingPage = [], productDetailsPage = [], purchaseHistoryPage = [], checkoutPage = [] } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      productListingPage: getNew(productListingPage, pageLocal),
      productDetailsPage: getNew(productDetailsPage, pageLocal),
      purchaseHistoryPage: getNew(purchaseHistoryPage, pageLocal),
      checkoutPage: getNew(checkoutPage, pageLocal)
    }
  })
}

export default getFeatureRevenue
