import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const VolumeIconFilled = ({ color, ...rest }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 160' {...rest}>
    <g id='Layer_2_1_' style={{ display: 'none' }}>
      <rect x='-100' y='-100' style={{ display: 'inline' }} width='360' height='360' />
    </g>
    <path style={{ fill: 'none', stroke: color, strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} d='M139,34.2c23.63,25.59,23.63,65.04,0,90.63' />
    <path style={{ fill: 'none', stroke: color, strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} d='M117.31,58.95c10.74,11.6,10.74,29.52,0,41.13' />
    <line style={{ fill: 'none', stroke: color, strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} x1='43.5' y1='51.5' x2='43.5' y2='108.5' />
    <polyline style={{ fill: 'none', stroke: color, strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} points='3.5,79.5 3.5,108.5 43.15,108.5 89.5,139.6 89.5,71.5 ' />
    <polyline style={{ fill: 'none', stroke: color, strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} points='3.5,80.5 3.5,51.5 43.15,51.5 89.5,20.38 89.5,88.5 ' />
    <path style={{ fill: 'none', stroke: color, strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} d='M139,34.2c23.63,25.59,23.63,65.04,0,90.63' />
    <path style={{ fill: 'none', stroke: color, strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} d='M117.31,58.95c10.74,11.6,10.74,29.52,0,41.13' />
    <line style={{ fill: 'none', stroke: color, strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} x1='43.5' y1='51.5' x2='43.5' y2='108.5' />
    <polyline style={{ fill: 'none', stroke: color, strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} points='3.5,79.5 3.5,108.5 43.15,108.5 89.5,139.6 89.5,71.5 ' />
    <polyline style={{ fill: 'none', stroke: color, strokeWidth: '5', strokeLinecap: 'round', strokeLinejoin: 'round' }} points='3.5,80.5 3.5,51.5 43.15,51.5 89.5,20.38 89.5,88.5 ' />
  </svg>

)
/* --------------------------------------------- */
VolumeIconFilled.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
VolumeIconFilled.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default VolumeIconFilled
/* --------------------------------------------- */
