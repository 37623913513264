import momentTz from 'moment-timezone'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { getUserAuthInfo } from '../utils/localStorageService'
import { getProductsLocal } from '../utils/sessionStorageService'

/** All common utility functions are available here */

function isNull(value) {
  if (value === null || value === undefined || value === '') {
    return true
  }
  for (var i = 1; i < arguments.length; i++) {
    var arg = arguments[i]
    if (arg === null || arg === undefined || arg === '') {
      return true
    }
    if (value[arg] !== null && value[arg] !== undefined && value[arg] !== '') {
      value = value[arg]
    } else {
      return true
    }
  }
  return false
}

function isNotNull() {
  return !isNull.apply(null, arguments)
}

function removePx(value) {
  if (isNotNull(value)) {
    // value = Number(value.toString().split("px")[0]);
    value = parseFloat(value)
    // $(this).css('marginBottom').replace(/[^-\d\.]/g, '');
  }
  return value
}

function getCSSValue(el, styleProp) {
  if (!el) return null
  var camelize = function (str) {
    return str.replace(/-(\w)/g, function (str, letter) {
      return letter.toUpperCase()
    })
  }

  if (el.currentStyle) {
    return el.currentStyle[camelize(styleProp)]
  } else if (document.defaultView && document.defaultView.getComputedStyle) {
    return document.defaultView.getComputedStyle(el, null)
      .getPropertyValue(styleProp)
  } else {
    return el.style[camelize(styleProp)]
  }
}

function isChildElement(obj, parentObj) {
  while (isNotNull(obj, 'tagName') && obj.tagName.toUpperCase() !== 'BODY') {
    if (obj === parentObj) {
      return true
    }
    obj = obj.parentNode
  }
  return false
}

function parseXML(txt) {
  var xmlDoc
  if (window.DOMParser) {
    xmlDoc = new DOMParser().parseFromString(txt, 'text/xml')
  } else { // Internet Explorer
    xmlDoc = new window.ActiveXObject('Microsoft.XMLDOM')
    xmlDoc.async = false
    xmlDoc.loadXML(txt)
  }

  return xmlDoc
}

const getCloserMatchImage = (width, height, imageArray, isCheckAR = true) => {
  if (!imageArray) return
  var minimum = 9999999
  var selectedImage = null
  for (var i = 0, image; i < imageArray.length; i++) {
    image = imageArray[i]
    if (isCheckAR && Math.abs((image.width / image.height) - (width / height)) > 0.001) continue
    var diff = Math.max(Math.abs(image.width - width), Math.abs(image.height - height))
    if (diff < minimum) {
      minimum = diff
      selectedImage = image
    }
  }
  // if (!selectedImage && isCheckAR) {
  //   selectedImage = getCloserMatchImage(width, height, imageArray, false)
  // }
  return selectedImage
}

// expected width and height of image is calculated depending on orientation, size and resolution
const getCloserMatchImage2 = (width, height, imageArray) => { // image fltering
  if (!imageArray) return null
  let arr = [...imageArray]

  // return if exact match exists
  for (let i = 0; i < arr.length; i++) { // image filtering in case of exact image
    const image = arr[i] || {}
    if (image.width === width && image.height === height) return image
  }

  // filter by orientation
  arr = arr.filter((image) => {
    if (!image.width || !image.height) return false
    if (image.width > image.height && width > height) return true // landscape
    if (image.width < image.height && width < height) return true // portrait
    if (image.width === image.height && width === height) return true // square
    return false
  })

  if(arr?.length === 0) {
    const screenOrientation = window.innerWidth >= window.innerHeight ? 'landscape' : 'portrait'
    for(let i = 0; i < imageArray.length; i++) {
      let image = imageArray[i]
      if(image?.supportedOrientations && image?.supportedOrientations.indexOf(screenOrientation) !== -1) {
        return image
      }
    }
  }

  arr.sort((a, b) => parseFloat(a.width) - parseFloat(b.width));

  // find closest match
  for (let i = 0; i < arr.length; i++) {
    const image = arr[i]
    if (image.width >= width && image.height >= height) return image
  }

  var minimum = 9999999

  for (var i = 0, image; i < imageArray.length; i++) { // image filtering to find the closest iamge in different orientation depending on width and height of image and screen
    image = imageArray[i]
    if (Math.abs((image.width / image.height) - (width / height)) > 0.001) continue
    var diff = Math.max(Math.abs(image.width - width), Math.abs(image.height - height))
    if (diff < minimum) {
      minimum = diff
      return image
    }
  }

  return arr[arr.length - 3]
}

const findHeightFromWidthWithAspectRatio = ({width, ratio1, ratio2}) => {
  const ratio  = width / ratio1;
  const height = ratio * ratio2;
  return height;
}

function startsWith(str, toMatch) {
  if (!str || !toMatch) return false
  return str.substring(0, toMatch.length) === toMatch
}

function parse(queryString) {
  if (!queryString) return {}
  var query = {}
  var pairs = ''
  if (queryString.indexOf('?') > -1) pairs = queryString.substring(queryString.indexOf('?') + 1).split('&')
  else pairs = queryString.split('&')
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

function stringify(obj) {
  var str = []
  for (var p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(p + '=' + obj[p])
    }
  }
  return str.join('&')
}

const parseUrl = (function () {
  var a = document.createElement('a')
  return function (url) {
    a.href = url
    let pathname = a?.pathname
    if (pathname[0] !== '/') pathname = `/${pathname}` // IE Fix
    return {
      host: a.host,
      hostname: a.hostname,
      pathname,
      port: a.port,
      protocol: a.protocol,
      search: a.search,
      hash: a.hash
    }
  }
})()

const convertUTCtoRelativeTimezone = (utc) => {
  const tz = momentTz.tz.guess()
  return momentTz.utc(utc).tz(tz)
}

const getLocation = (href) => {
  var l = document.createElement('a')
  l.href = href
  return l
}

const toHHMMSS = function (sec) {
  var secNum = parseInt(sec, 10) // don't forget the second param
  var hours = Math.floor(secNum / 3600)
  var minutes = Math.floor((secNum - (hours * 3600)) / 60)
  var seconds = secNum - (hours * 3600) - (minutes * 60)

  if (hours < 10) { hours = '0' + hours }
  if (minutes < 10) { minutes = '0' + minutes }
  if (seconds < 10) { seconds = '0' + seconds }
  return hours + ':' + minutes + ':' + seconds
}
const toMMSS = function (sec) {
  var secNum = parseInt(sec, 10) // don't forget the second param
  var minutes = Math.floor((secNum) / 60)
  var seconds = secNum - (minutes * 60)

  if (seconds < 10) { seconds = '0' + seconds }
  return  minutes + ':' + seconds
}

const millisToMinutesAndSeconds = (ms, labels = {}) => {
  const {
    hr: hrL = '',
    min: minL = '',
    sec: secL = ''
  } = labels
  const HHMMSS = toHHMMSS(ms / 1000)
  const timeArr = HHMMSS.split(':')
  const hr = timeArr[0]
  const min = timeArr[1]
  const sec = timeArr[2]
  if (hr === '00') {
    if (min === '00') return `${sec} ${secL}`
    else{
      if(sec === '00'){
        return `${Number(min)} ${minL}`
      }else{
        return `${Number(min)} ${minL} ${sec} ${secL}`
      }
    }
  } else {
    if (min === '00') return `${Number(hr)} ${hrL}`
    else return `${Number(hr)} ${hrL} ${min} ${minL}`
  }
}

const millisToMinutesAndSeconds2 = (ms, labels = {}) => {
  const {
    hr: hrL = '',
    min: minL = '',
    sec: secL = ''
  } = labels
  const HHMMSS = toHHMMSS(ms / 1000)
  const timeArr = HHMMSS.split(':')
  const hr = timeArr[0]
  const min = timeArr[1]
  const sec = timeArr[2]
  if (hr === '00') {
    if (min === '00') return `${sec} ${secL}`
    else return `${min} ${minL}`
  } else {
    if (min === '00') return `${hr} ${hrL}`
    else return `${hr} ${hrL} ${min} ${minL}`
  }
}

const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex)

//To check whether a url valid or not, if its valid it returns true
const isValidUrl = urlString=> {
  try { 
    return Boolean(new URL(urlString)); 
  }
  catch(e){ 
    return false; 
  }
}

const getChunksFromString = (st, chunkSize) => st.match(new RegExp(`.{${chunkSize}}`, 'g'))

const convertHexUnitTo256 = (hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16)

const getAlphafloat = (a, alpha) => {
  if (typeof a !== 'undefined') { return a / 256 }
  if (typeof alpha !== 'undefined') {
    if (alpha > 1 && alpha <= 100) { return alpha / 100 }
    if (alpha >= 0 && alpha <= 1) { return alpha }
  }
  return 1
}

const parseQueryString = (query) => {
  let vars = query.split("&");
  let queryString = {};
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    let key = decodeURIComponent(pair[0]);
    let value = decodeURIComponent(pair[1]);
    // If first entry with this name
    if (typeof queryString[key] === "undefined") {
      queryString[key] = decodeURIComponent(value);
      // If second entry with this name
    } else if (typeof queryString[key] === "string") {
      let arr = [queryString[key], decodeURIComponent(value)];
      queryString[key] = arr;
      // If third or later entry with this name
    } else {
      queryString[key].push(decodeURIComponent(value));
    }
  }
  return queryString;
}

const hexToRGBA = (hex, alpha) => {
  if (!hex) return null
  if (!isValidHex(hex)) { throw new Error(`Invalid HEX ${hex}`) }
  const chunkSize = Math.floor((hex.length - 1) / 3)
  const hexArr = getChunksFromString(hex.slice(1), chunkSize)
  const [r, g, b, a] = hexArr.map(convertHexUnitTo256)
  return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`
}

const prependProtocol = (url) => {
  if (startsWith(url, 'http')) {
    return url
  } else {
    return `${window.location.protocol}${url}`
  }
}

const clearMissingValues = (obj) => {
  const myObj = { ...obj }
  Object.keys(myObj).forEach((key) => (!myObj[key]) && delete myObj[key])
  return myObj
}

const trimLeadingZeroesFromHHMMSS = (inputTime) => {
  for (let i = 0; i < 4; i++) {
    if (inputTime[i] !== ':' && inputTime[i] !== '0') {
      return (inputTime.slice(i))
    }
  }
  return (inputTime.slice(4))
}

const isLocalHost = () => (
  window.location.hostname === 'localhost' ||
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === ''
)

function toMilliSeconds(hrs, min, sec) {
  return ((hrs * 60 * 60 + min * 60 + sec) * 1000);
}

// Check if body has right padding. This is necessary during enabling/disabling body scrolling on 
// elements with position set as fixed
const hasMargin = () => window.getComputedStyle(document.body)['paddingRight'] !== "0px"

// Enable body scrolling
const enableScroll = (el) => {
  enableBodyScroll(el)
}

// Disable body scrolling
const disableScroll = (el) => {
  disableBodyScroll(el, { reserveScrollBarGap: true })
}
//parse json
const parseJSON = (jsonStr) => {
  try { 
      return JSON.parse(jsonStr) 
    }catch { 
      return {isValidJSON : false, originalString : jsonStr} 
    }

}

const getUTCOffset = () => {
  return encodeURIComponent(new Date().getTimezoneOffset() * 60 * 1000)
}
const isHomePage = () => {
  const location = decodeURIComponent(window?.location?.pathname);
  const isHomePage = location === '/' || location === '/home' ;
  return isHomePage;

}

const isGettingStartedPage = () => {
  const location = decodeURIComponent(window?.location?.pathname);
  const isGettingStartedPage = location === '/getting-started';
  return isGettingStartedPage;
}

const isCatalogPage = () => {
  const location = decodeURIComponent(window?.location?.pathname);
  const isCatalogPage = location === '/' || location === '/home' || location === '/movies' || location === '/shows' || location === '/kids' ;
  return isCatalogPage;
}

const isPlayerPage = () => {
  const location = decodeURIComponent(window?.location?.pathname);
  const isPlayerPage = location === '/player'  ;
  return isPlayerPage;
}

const isSubscriptionPage = () => {
  const location = decodeURIComponent(window?.location?.pathname);
  const isSubscriptionPage = location === '/subscription'  ;
  return isSubscriptionPage;
}

const isSessionValid = () => {
   const authInfoLocal = getUserAuthInfo() || {}
    const now = Date.now()
    const nextRefreshTime = authInfoLocal.nextRefreshTime || null
    const identityProvider = authInfoLocal.identityProvider || ''
    if (!nextRefreshTime && (!identityProvider || identityProvider !== 'mpx')) return 'INVALID_SESSION'
    if (nextRefreshTime && (now > nextRefreshTime)) return 'SESSION_EXPIRED'
    return 'VALID_SESSION'
}
function tConvert (time, language) {
  // Check correct time format and split into components
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? get_AM_PM_By_lang(language).AM : get_AM_PM_By_lang(language).PM; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join (''); // return adjusted time or original string
}

function msToHMS( ms, language ) {
  // 1- Convert to seconds:
  let seconds = ms / 1000;
  // 2- Extract hours:
  const hours = parseInt( seconds / 3600 ); // 3,600 seconds in 1 hour
  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = parseInt( seconds / 60 ); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;

  if (hours > 0){
    return (hours+ " " + get_AM_PM_By_lang(language)?.hours + minutes+ " " + get_AM_PM_By_lang(language).min)
  } else {
  return (minutes+ " " + get_AM_PM_By_lang(language).min)

  }
}

function getFormattedTime(time) {
  return `${('0' + new Date(time).getHours()).slice(-2)}:${('0' + new Date(time).getMinutes()).slice(-2)}`
}

const checkIfAssetPurchased = (asset_id, categoryId)=>{
  const products = getProductsLocal() || {};
  const purchasedProducts = products?.purchasedProducts || []
  let isPurchased = false;
  for(let i=0; i < purchasedProducts.length ; i++){
    if(purchasedProducts[i]?.productType === "tvod"){
      const availableAssets = purchasedProducts[i]?.availableAssets || []
      if(availableAssets.length > 0){
        isPurchased = availableAssets.indexOf(asset_id)>-1
      } 
    } else{
      const entitlements = purchasedProducts[i]?.entitlements || [] 
      isPurchased = entitlements.some(item => categoryId.includes(item))
    }
    if(isPurchased){
      return isPurchased
    }
  }
  return isPurchased
}
const returnPurchasedProduct = (asset_id, categoryId)=>{
  const products = getProductsLocal() || {};
  const purchasedProducts = products?.purchasedProducts || []
  let isPurchased = false;
  let purchasedProduct = null;
  for(let i=0; i < purchasedProducts.length ; i++){
    if(purchasedProducts[i].entitlementType === 'single'){
      const availableAssets = purchasedProducts[i]?.availableAssets || []
      isPurchased = availableAssets.indexOf(asset_id)>-1
    }else{
      const entitlements = purchasedProducts[i]?.entitlements || [] 
      isPurchased = entitlements.some(item => categoryId.includes(item))
    }
    if(isPurchased){
      purchasedProduct = purchasedProducts[i]
      return purchasedProduct;
    }
  }
  return purchasedProduct
}

// Check for RTL language

const checkRTLLanguage = (lang) => {
  switch (lang) {
    case "ar-AE":
      return true  
    default:
      return false;
  }
}

const get_AM_PM_By_lang = (lang) => {
  switch (lang) {
    case "ar-AE":
      return {AM: "صباحا", PM: "مساءً", hours: "ح", min:"دقيقة"}  
    default:
      return {AM: "AM", PM: "PM", hours: "h", min: "min"};
  }
}

const formatNumbersByLang = (number, lang) => {
  switch (lang) {
    case "ar-AE":  
      let id= ['۰','۱','۲','۳','۴','۵','۶','۷','۸','۹'];
      return number.toString().replace(/[0-9]/g, function(w){
        return id[+w]
       })
    default:  {
      let id= ['0','1','2','3','4','5','6','7','8','9'];
      return number.toString().replace(/[0-9]/g, function(w){
        return id[+w]
       })
    }
  }

 
  ;
}
/* --------------------------------------------- */
const isNotEmptyObject = (obj) => obj && Object.getPrototypeOf(obj) === Object.prototype && Object.keys(obj).length !== 0;
/* --------------------------------------------  */
//To get url extension
const getUrlExtension = ( url ) => {
  if(url && isValidUrl(url)){
    return url.split(/[#?]/)[0].split('.').pop().trim();
  } else {
    return null
  }
}

// to capitalize the first letter of a string
function capitalizeFirstLetter(string) {
  if(string && (typeof string === 'string'))
  return string.charAt(0).toUpperCase() + string.slice(1);
}

  
//seconds to time in hr min and seconds
const secondsToTime = (secs) => {
  const hours = Math.floor(secs / (60 * 60))

  const divisor_for_minutes = secs % (60 * 60)
  const minutes = Math.floor(divisor_for_minutes / 60)

  const divisor_for_seconds = divisor_for_minutes % 60
  const seconds = Math.ceil(divisor_for_seconds)

  const obj = {
    h: hours,
    m: minutes,
    s: seconds
  }
  return obj
}
/* --------------------------------------------  */
var _utils = {
  isNull: isNull,
  isNotNull: isNotNull,
  removePx: removePx,
  getCSSValue: getCSSValue,
  isChildElement: isChildElement,
  parseXML: parseXML,
  getCloserMatchImage,
  getCloserMatchImage2,
  startsWith,
  prependProtocol,
  parse,
  stringify,
  convertUTCtoRelativeTimezone,
  getLocation,
  toHHMMSS,
  toMMSS,
  millisToMinutesAndSeconds,
  millisToMinutesAndSeconds2,
  hexToRGBA,
  parseUrl,
  clearMissingValues,
  trimLeadingZeroesFromHHMMSS,
  isLocalHost,
  toMilliSeconds,
  hasMargin,
  enableScroll,
  disableScroll,
  parseJSON,
  getUTCOffset,
  parseQueryString,
  findHeightFromWidthWithAspectRatio,
  isHomePage,
  isSessionValid,
  tConvert,
  msToHMS,
  getFormattedTime,
  checkIfAssetPurchased,
  isGettingStartedPage,
  isCatalogPage,
  checkRTLLanguage,
  returnPurchasedProduct,
  isPlayerPage,
  isSubscriptionPage,
  formatNumbersByLang,
  isNotEmptyObject,
  isValidUrl,
  getUrlExtension,
  capitalizeFirstLetter,
  secondsToTime,
}

export default _utils
