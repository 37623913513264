import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const ForwardIcon = ({ color, text, ...rest }) => (
  <svg viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g>
      <path 
      fill={color} d="M11.2787 4.2206V1.33129C11.2785 1.09759 11.3399 0.867982 11.4567 0.665564C11.5735 0.463146 11.7416 0.29506 11.944 0.178222C12.1464 0.0613839 12.376 -8.53261e-05 12.6097 8.88953e-08C12.8433 8.55039e-05 13.0729 0.0617225 13.2752 0.178709L19.2932 3.65219C19.4956 3.76914 19.6636 3.93728 19.7804 4.13973C19.8973 4.34218 19.9588 4.57181 19.9588 4.80555C19.9588 5.0393 19.8973 5.26893 19.7804 5.47138C19.6636 5.67383 19.4956 5.84197 19.2932 5.95892L13.2752 9.43397C13.0728 9.55103 12.8431 9.61267 12.6093 9.61268C12.3754 9.6127 12.1457 9.55109 11.9433 9.43407C11.7408 9.31704 11.5728 9.14873 11.4561 8.9461C11.3395 8.74346 11.2782 8.51365 11.2787 8.27982V5.82351C9.09871 6.27301 7.0993 7.35427 5.52975 8.93248C3.96019 10.5107 2.88994 12.516 2.45244 14.6984C2.01493 16.8808 2.22954 19.1437 3.0695 21.2049C3.90946 23.2662 5.3376 25.0346 7.17585 26.2896C9.0141 27.5446 11.1811 28.2308 13.4067 28.2624C15.6323 28.2941 17.818 27.67 19.6912 26.4678C21.5645 25.2656 23.0424 23.5386 23.9407 21.5021C24.839 19.4656 25.1179 17.2098 24.7427 15.0158C24.7252 14.9138 24.7278 14.8094 24.7506 14.7085C24.7734 14.6076 24.8158 14.5122 24.8755 14.4277C24.9351 14.3432 25.0108 14.2712 25.0983 14.216C25.1857 14.1608 25.2832 14.1233 25.3851 14.1057C25.4871 14.0881 25.5915 14.0908 25.6924 14.1136C25.7933 14.1363 25.8887 14.1788 25.9732 14.2384C26.0578 14.2981 26.1297 14.3738 26.1849 14.4612C26.2402 14.5487 26.2777 14.6462 26.2952 14.7481C26.7254 17.2604 26.4024 19.8438 25.3669 22.1727C24.3314 24.5017 22.6297 26.4721 20.4763 27.8357C18.3229 29.1992 15.814 29.8949 13.2659 29.8351C10.7178 29.7752 8.24439 28.9625 6.15736 27.4994C4.07032 26.0363 2.46303 23.9881 1.53799 21.6131C0.612963 19.2381 0.411581 16.6424 0.959223 14.1531C1.50686 11.6638 2.77903 9.39226 4.61542 7.62474C6.45181 5.85721 8.77027 4.67275 11.2787 4.2206ZM12.8532 7.85941L18.1422 4.80634L12.8532 1.75169V7.85941Z" 
      />
      <text x="28%" y="67%" fontSize="10" fill={color}>{text} </text>  
    </g>
  </svg>
)
/* --------------------------------------------- */
ForwardIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Text inside icon. */
  text: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
ForwardIcon.defaultProps = {
  color: 'transparent',
  text: 25,
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default ForwardIcon
/* --------------------------------------------- */
