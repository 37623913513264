import React, { useEffect, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
import CloseIcon from '../icons/CloseIcon'
/* --------------------------------------------- */
import { useGlobalNew } from '../../context/global.new.context'
import { GlobalContext } from '../../context/global.context'
import { useMiscsDispatch } from '../../context/miscs.context'
/* --------------------------------------------- */
import {
    CountryCodeWrapper, PopUpHeader, CountryCodeBodyWrapper, SearchInput, EmptyMatch
  } from './style'

import './index.scss'
import IndividualCountryCode from './IndividualCountryCode'
import utils from '../../utils/utils'
import { getProductsLocal } from '../../utils/sessionStorageService'
/* --------------------------------------------- */

// SettingsPopup component for displaying settings popup and handles events
/* --------------------------------------------- */
const CountryPopup = ({ title = null, isSearchEnabled = false, selectedCountryObj = null }) => {
    const [state, dispatch] = useGlobalNew()
    const miscsDispatch = useMiscsDispatch()
    /* --------------------------------------------- */
    /* --------------------------------------------- */
    const { appMessages } = useSelector(store => store.appConfigReducers)
    const { userManagementConfig = {}, userManagementThemes = {} } = useSelector(store => store.userManagement)
    /* --------------------------------------------- */
    const { additionalConfiguration = "" } = userManagementConfig
    const products = getProductsLocal() || {}; // getting payment providers from storage
    const paymentProviders = (products?.paymentProviders || []).filter(item => item?.isDropdownValue === true); // listing out all the providers isDropdownValue is true
    const { specialMenuConfig } = useSelector(store => store.navigation)
    const { searchTextCountryCode, dispatch: globalDispatch } = useContext(GlobalContext)
    const [theme, updateTheme] = useState(null)
    const [ispopupClosed, updatePopupClosed] = useState(false)
    const [countryCodeList, updateCountryCodeList] = useState([])
    const [countryCodeFullList, updateCountryCodeFUllList] = useState([])
    /* --------------------------------------------- */
    /* --------------------------------------------- */
    /**
     * It: Closes phone no configuration poopup
     */
    const handleCloseClick = () => {
        dispatch({ type: 'CLOSE_COUNTRYCODE_POPUP' })
        if(isSearchEnabled) {
            document.getElementById(
                'myInput').value = null;
            globalDispatch({ type: 'SEARCH_TEXT_COUNTRY_CODE', value: null })
        }    
        dispatch({ type: 'CLOSE_CABLE_OPERATOR_POPUP' })  
        dispatch({ type: 'CLOSE_PARTNER_POPUP' })  
        updatePopupClosed(true);
    }
    /* --------------------------------------------- */
    const searchInputChangeHandler = (e) => {
        if (e.target.value) {
            var value = e ? e.target.value : e
        }
        globalDispatch({ type: 'SEARCH_TEXT_COUNTRY_CODE', value })
    }

    const handleCountrySelect = (selectedCountry) => {
        globalDispatch({ type: 'SELECTED_COUNTRY', value: selectedCountry })
        handleCloseClick();
    }
    const handleOperatorSelect = (selectedOperator) => {
        globalDispatch({ type: 'SELECTED_OPERATOR', value: selectedOperator })
        handleCloseClick();
    }

    // when we select a partner stores in global context
    const handlePartnerSelect = (selectedPartner) => {
        globalDispatch({ type: 'SELECTED_PARTNER', value: selectedPartner })
        handleCloseClick();
    }
    /**
     * It: Disables body scroll on opening settings popup.
     */
    useEffect(() => {
        if(ispopupClosed) miscsDispatch({ type: 'ENABLE_BODY_SCROLL' })
        if (state.isOpenCountryCodePopup || state.isOpenCableOperatorPopup) miscsDispatch({ type: 'DISABLE_BODY_SCROLL' })
        else miscsDispatch({ type: 'ENABLE_BODY_SCROLL' })
        return () => {
            miscsDispatch({ type: 'ENABLE_BODY_SCROLL' })
        }
    }, [state.isOpenCountryCodePopup, miscsDispatch, ispopupClosed, state.isOpenCableOperatorPopup])
    /* --------------------------------------------- */
    useEffect(() => {
        updateTheme(userManagementThemes.registrationTheme || {});
    }, [userManagementThemes.registrationTheme]);

    useEffect(() => {
        const searchBarItem = specialMenuConfig.filter(({ id }) => {
          return (id === 'search_bar')
        })
        if (utils.isNotNull(searchBarItem, 0, 'buttonStyle')) {
        //   setSearchBarTheme(searchBarItem[0].buttonStyle)
        }
      }, [
        specialMenuConfig
      ])

    useEffect(() => {
        if(!additionalConfiguration) return
        const additionalConfigs = JSON.parse(additionalConfiguration);
        const countryCodeList = additionalConfigs?.phoneNumberConfiguration?.countryInfo || [];
        globalDispatch({ type: 'SELECTED_COUNTRY', value: countryCodeList?.length ? countryCodeList[0] : {} })
        updateCountryCodeList(countryCodeList)
        updateCountryCodeFUllList(countryCodeList)
    }, [additionalConfiguration, globalDispatch])

    useEffect(() => {
        var matched_terms = [];
        var searchTextInput  = searchTextCountryCode;
        searchTextInput  = searchTextInput?.toLowerCase();
        countryCodeFullList.forEach(item => {
            if(item?.name?.toLowerCase().startsWith(searchTextInput)){
                matched_terms.push( item ); 
            }
        })
        if(!searchTextCountryCode) updateCountryCodeList(countryCodeFullList);
        else updateCountryCodeList(matched_terms);
        
    }, [searchTextCountryCode, countryCodeFullList])

    return (
            <CountryCodeWrapper {...{ theme }} className='details-page__pop-up-wrapper'>
                <div className='details-page__pop-up-head' >
                    <PopUpHeader {...{ theme }} >{title}</PopUpHeader>
                    <div className='player-audio__close' onClick={handleCloseClick}>
                        <CloseIcon color={theme && theme.compositeStyle && theme.compositeStyle.phonenumberPicker && theme.compositeStyle.phonenumberPicker.accent && theme.compositeStyle.phonenumberPicker.accent.secondary} style={{ width: 15, height: 15 }} />
                    </div>
                </div>
                <CountryCodeBodyWrapper {...{ theme }}>
                    { isSearchEnabled && <SearchInput {...{ theme }} type="text" onChange={searchInputChangeHandler} placeholder={appMessages.label_search_country_desc} id="myInput"/> }
                    <div className="indivudual-country-list-body" >
                        {   state.isOpenCountryCodePopup && <div className="indivudual-country-list" >
                                {countryCodeList?.length ? countryCodeList.map((item, index) => {
                                    return <div className="indivudual-country-list-single" key={index} onClick={() => handleCountrySelect(item)} style={{cursor: "pointer"}}>
                                        <IndividualCountryCode theme = {theme} flag={item?.image} name={item?.name} code={item?.code} isThisCountryCodeSelected={false} />
                                    </div>
                                }): <EmptyMatch {...{ theme }}>{ appMessages.search_no_results }</EmptyMatch>
                                }
                            </div>
                        }
                        {   state.isOpenCableOperatorPopup && <div className="indivudual-country-list" >
                                {selectedCountryObj && selectedCountryObj?.operator?.length ? selectedCountryObj.operator.map((item, index) => {
                                    return <div className="indivudual-country-list-single" key={index} onClick={() => handleOperatorSelect(item)} style={{cursor: "pointer"}}>
                                        <IndividualCountryCode theme = {theme} operator={item} isThisCountryCodeSelected={false} />
                                    </div>
                                }): <EmptyMatch {...{ theme }}>{ appMessages.search_no_results }</EmptyMatch>
                                }
                            </div>
                        }
                        {   state.isOpenPartnerPopup && <div className="indivudual-country-list" > {/* to show partners list in popup to reuse the same popup, getting payment providers list from storage and listing down here */}
                                {paymentProviders && paymentProviders?.length ? paymentProviders.map((item, index) => {
                                    return <div className="indivudual-country-list-single" key={index} onClick={() => handlePartnerSelect(item)} style={{cursor: "pointer"}}>
                                        <IndividualCountryCode theme = {theme} name={item?.displayName} isThisCountryCodeSelected={false} />
                                    </div>
                                }): <EmptyMatch {...{ theme }}>{ appMessages.search_no_results }</EmptyMatch>
                                }
                            </div>
                        }
                    </div>
                </CountryCodeBodyWrapper>
                
            </CountryCodeWrapper>
    )
}
/* --------------------------------------------- */
/* --------------------------------------------- */
export default CountryPopup