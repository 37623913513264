import { getNewObj } from '../utils/contentfulHandler'
import getColor from './getColor'
import getColorGradient from './getColorGradient'

// Helper function to form contentful tree
const getColorPalette = (contentful) => {
  const colorLocal = getColor(contentful)
  const colorGradientLocal = getColorGradient(contentful)
  const colorPaletteLocal = contentful.colorPalette

  return colorPaletteLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { primary = {}, secondary = {}, tertiary = {}, disabled = {}, error = {} } = fields

    return {
      ...fields,
      sysId: id,
      contentTypeId,
      primary: getNewObj(primary, [colorLocal, colorGradientLocal]),
      secondary: getNewObj(secondary, [colorLocal, colorGradientLocal]),
      tertiary: getNewObj(tertiary, [colorLocal, colorGradientLocal]),
      disabled: getNewObj(disabled, [colorLocal, colorGradientLocal]),
      error: getNewObj(error, [colorLocal, colorGradientLocal])
    }
  })
}

export default getColorPalette
