import { INTRO_CONFIG } from '../constants/actionTypes'
// import { mergeThemes, getThemeData } from '../utils/contentfulThemeMerger'
import { filter, hydratePageComponent } from '../utils/contentfulHandler'
import utils from '../utils/utils'

/** IntroConfig store */
export const fetchIntroConfig = (intro = [], options, themeData = {}) => {
  // const { appThemeData, componentThemeData } = themeData || {}
  return (dispatch) => {

    intro = filter(intro, '', options) || []
    // console.log(intro, 'intro')

    let featureIntro = {

    }

    let introContent = []
    let landingPageData = [];
    let theme = []
    let landingPageComponentThemes =[]; 
    let allowSkip = false, allowBackNavigation = false, showIntro = '', showProgressIndicator = '', showStrategy = [];

    // revenue = filter(revenue, '', options) || []

    if (utils.isNotNull(intro, 0)) {
      const introConfig = intro[0]

      if (utils.isNotNull(introConfig, 'introContent')) {
        introContent = introConfig.introContent

        introContent = filter(introContent, '', options) || []
        const introContent1 = [...introContent];
        // filters graphics inside introconent
        for (var i = 0; i < introContent1.length; i++) {
          if (utils.isNotNull(introContent1, i, 'graphics')) {
            introContent1[i].graphics = filter(introContent1[i].graphics, '', options) || [];
          }
        }

        featureIntro.introContent = introContent1;

      }
      if (utils.isNotNull(introConfig, 'landingPage')) {
        landingPageData = introConfig.landingPage

        landingPageData = filter(landingPageData, '', options) || []
        const introContent1 = [...landingPageData];
     
        // filters graphics inside introconent
        for (i = 0; i < introContent1.length; i++) {
          if (utils.isNotNull(introContent1, i, 'graphics')) {
            introContent1[i].graphics = filter(introContent1[i].graphics, '', options) || [];
          }
        }
        let [{components:landinePageComponents} = {}] = introContent1 || [];
        landinePageComponents = landinePageComponents || []
        landingPageComponentThemes = landinePageComponents.map((comp)=>{
          return hydratePageComponent(comp, options)
        })
       
        featureIntro.landingPage = introContent1;
        
      }

      if (utils.isNotNull(introConfig, 'theme')) {
        theme = introConfig.theme;
      }

      if (utils.isNotNull(introConfig, 'allowSkip')) {
        allowSkip = introConfig.allowSkip;
      }

      if (utils.isNotNull(introConfig, 'allowBackNavigation')) {
        allowBackNavigation = introConfig.allowBackNavigation;
      }

      if (utils.isNotNull(introConfig, 'showIntro')) {
        showIntro = introConfig.showIntro;
      }

      if (utils.isNotNull(introConfig, 'showProgressIndicator')) {
        showProgressIndicator = introConfig.showProgressIndicator;
      }

      if (utils.isNotNull(introConfig, 'showStrategy')) {
        showStrategy = introConfig.showStrategy;
      }
    }

    dispatch({
      type: INTRO_CONFIG,
      value: {
        introContent,
        theme,
        showIntroPopUp: true,
        allowSkip,
        allowBackNavigation,
        showIntro,
        showProgressIndicator,
        showStrategy,
        landingPageComponentThemes,
        landingPageData,
      }
    })

    return Promise.resolve()
  }
}
