import { getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'

// Helper function to form contentful tree
const getMaintenanceConfiguration = (contentful) => {
  const filterLocal = getFilter(contentful)
  const maintenanceConfigurationLocal = contentful.maintenanceConfiguration
  return maintenanceConfigurationLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { filter = {} } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal])
    }
  })
}

export default getMaintenanceConfiguration
