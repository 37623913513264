import styled from 'styled-components'
// Styled components used in DetailsSection component.
/* --------------------------------------------- */
export const CountryCodeWrapper = styled.div`
background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.background?.primary};
`

export const PopUpHeader = styled.p`
color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.text?.primary};
`

export const CountryCodeBodyWrapper = styled.div`
background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.background?.primary};

  .indivudual-country-list-body {
    position: relative;
    padding-right: 4px;
    .indivudual-country-list {
      overflow: auto;
      height: 470px;
      /* width */
      ::-webkit-scrollbar {
          height: 120px;
          width: 6px;
      }
        
      /* Track */
      ::-webkit-scrollbar-track {
        border-radius: 10px;
      }
        
      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.accent?.tertiary};
        border-radius: 10px;
      }
        
      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.accent?.tertiary};
      }
    }
  }
`
export const SearchInput = styled.input`
  height: 46px;
  border-radius: 5px;
  outline: none;
  width: calc(100% - 31px);
  border: none;
  margin-bottom: 17.5px;
  margin-right: 31px;
  font-size: 14px;
  padding-left: 20px;
  color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.text?.secondary};
  ::placeholder {
    color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.text?.secondary};
    opacity: 0.9; 
  }
  background: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.background?.disabled};
`
export const EmptyMatch = styled.span`
padding-right: 26px;
  color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.text?.tertiary};
`

export const IndivudualCountryListStyled = styled.div`
    .indivudual-countrylist-wrapper{
        padding: 8.5px 0px;
        display: flex;
        position: relative;
        flex-direction: row;
        margin-left: 9px;
        align-items: center;

        .indivudual-profile-wrapper__avatar{
          margin-right: 12px;
            img{
              width: 36px;
              height: 22px;
            }
        }
            
        .indivudual-country-details{
            display: flex;
            flex-direction: row;
            font-size: 18px;
            font-weight: 400;
            width: 55%;
            .indivudual-country__name{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.text?.tertiary};
                :hover{
                  color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.text?.tertiary};
                }
            }
        }
        .indivudual-country__code{
          font-size: 16px;
          font-weight: 600;
          position: absolute;
          right: 0px;
          padding-right: 21px;
          color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.text?.tertiary};
        }
        .indivudual-country__operator{
          font-size: 16px;
          font-weight: 400;
          color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.phonenumberPicker?.text?.tertiary};
        }
    }
`