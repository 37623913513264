import { PROFILE_MANAGEMENT_CONFIG, PROFILE_MANAGEMENT_THEMES } from '../constants/actionTypes'
import { mergeThemes, getThemeData } from '../utils/contentfulThemeMerger'
import { filter } from '../utils/contentfulHandler'
import utils from '../utils/utils'

/** ProfileManagementConfig store */
export const fetchProfileManagementConfig = (profileManagement = [], options, themeData = {}) => {
  const { appThemeData = {} } = themeData || {}
  return (dispatch) => {
    profileManagement = filter(profileManagement, '', options) || []
    if (utils.isNotNull(profileManagement, 0)) {
      const profileManagementConfig = profileManagement[0]

      dispatch({
        type: PROFILE_MANAGEMENT_CONFIG,
        value: profileManagementConfig
      })

      dispatch({
        type: PROFILE_MANAGEMENT_THEMES,
        value: {
          themeProfileList: getFinalTheme(
            appThemeData || {},
            profileManagementConfig.themeProfileList || [],
            options || {}
          ),
          themeProfilePopup: getFinalTheme(
            appThemeData || {},
            profileManagementConfig.themeProfilePopup || [],
            options || {}
          ),
          themeProfileDetails: getFinalTheme(
            appThemeData || {},
            profileManagementConfig.themeProfileDetails || [],
            options || {}
          )

        }
      })
    }
    return Promise.resolve()
  }
}

export function getFinalTheme (appThemeData = {}, themeArr = [], options = {}) {
  let registrationTheme = appThemeData || {}
  if (utils.isNotNull(themeArr)) {
    const filteredThemeArr = filter(themeArr, '', options) || []
    if (filteredThemeArr[0]) {
      registrationTheme = mergeThemes(
        appThemeData || {},
        getThemeData(filteredThemeArr[0] || {}, options) || {}
      ) || {}
    }
  }
  return registrationTheme
}
