import { PROFILE_MANAGEMENT_CONFIG, PROFILE_MANAGEMENT_THEMES } from '../constants/actionTypes'
import inialState from './initalState'
/* --------------------------------------------- */
/** Profile management config reducer */
const profileManagement = (state = inialState, action) => {
  switch (action.type) {
    case PROFILE_MANAGEMENT_CONFIG:
      return {
        ...state,
        profileManagementConfig: action.value
      }
    case PROFILE_MANAGEMENT_THEMES:
      return {
        ...state,
        profileManagementThemes: action.value
      }
    default: return state
  }
}
/* --------------------------------------------- */
export default profileManagement
/* --------------------------------------------- */
