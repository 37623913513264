import { useEffect, useContext, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  isTablet,
  isMobile
} from 'react-device-detect'
/* ---------------------------------------------------------------------------------------------------- */
import utils from '../utils/utils'
import useErrorUtils from '../utils/useErrorUtils'
import { getUserAuthInfo, splitLanguage, getAutoPlayNextEpisode, setAutoPlayNextEpisode, getUserToken, setUserId } from '../utils/localStorageService'
import {
  getIsShownMaintenance,
  setIsShownMaintenance,
  setRecentlyWatchedLocal
  //  setFavouritesLocal, setRecentSearchesLocal, setProductsLocal
} from '../utils/sessionStorageService'
/* ---------------------------------------------------------------------------------------------------- */
import { GlobalContext } from '../context/global.context'
/* ---------------------------------------------------------------------------------------------------- */
import useGeneralApi from '../apis/useGeneralApi'
import useAuthApi from '../apis/useAuthApi'
import useDetailsApi from '../apis/useDetailsApi'
// import useFavApi from '../apis/useFavApi'
/* ---------------------------------------------------------------------------------------------------- */
import useErrorHandler from '../containers/useErrorHandler'
import useLoggly from '../containers/useLoggly'
import useLogout from '../containers/useLogout'
import { version } from '../config'

/* ---------------------------------------------------------------------------------------------------- */
// Container component
const AppBoundry = (props) => {
  const {
    appConfig,
    maintenanceConfig
  } = useSelector(store => store.appConfigReducers)
  const playerFeature = useSelector(store => store.featuresReducers.playerFeature)
  const { userManagementConfig = {} } = useSelector(store => store.userManagement)
  const isMaintenancePopupShownRef = useRef(false);
  // const { searchConfig } = useSelector(store => store.search)
  /* ------------------------------------------------------------------------------------------------ */
  const { getSessionState } = useGeneralApi()
  const {
    getRecentlyWatched
    // getRecentSearch, , getProducts
  } = useDetailsApi()
  const { refreshTrustedLogin } = useAuthApi()
  // const { getFavourites } = useFavApi()
  const logout = useLogout()

  const {
    appLanguage,
    isAppLoaded,
    isAuthenticated,
    dispatch: globalDispatch,
    isForceLogout,
    selectedProfile,
    refreshCallState,
    isShowSplash,
    allRailsLoaded,
    isGeoBlocked,
    isVpnBlocked,
    userInfo,
  } = useContext(GlobalContext)
  /* ------------------------------------------------------------------------------------------------ */
  const location = useLocation()
  /* ------------------------------------------------------------------------------------------------ */
  const { showError, hideError, hideAllErrors } = useErrorUtils()
  const { log } = useLoggly()
  const { handleError } = useErrorHandler()
  /* ------------------------------------------------------------------------------------------------ */
  const locationSearchRef = useRef(location.search)
  /* ------------------------------------------------------------------------------------------------ */
  // Load scripts after rails api call has been completed for home page
  const loadscript = (url) => {
    const script = document.createElement('script')
    script.src = url
    script.async = true
    document.body.appendChild(script)
  }

  /* ------------------------------------------------------------------------------------------------ */
  useEffect(() => {
    const autoPlay = getAutoPlayNextEpisode()
    if (autoPlay === 'true' || autoPlay === '') {
      globalDispatch({ type: 'AUTOPLAY_TRUE' })
      setAutoPlayNextEpisode(true)
    } else {
      globalDispatch({ type: 'AUTOPLAY_FALSE' })
      setAutoPlayNextEpisode(false)
    }
  }, [globalDispatch])
  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect executes once when user visited the site.
  // And this get executed only if the user already logged in before.
  useEffect(() => {
    if (!isAppLoaded) return
    if (refreshCallState === 'INITIATED') return
    if (refreshCallState === 'ENDED') return
    const sessionState = getSessionState()
    const authInfo = getUserAuthInfo()
    if(authInfo?.userId) { //setting userid in storage
      setUserId(authInfo?.userId)
    }
    // const selectedProfileLocal = JSON.parse(window.store.localStorage.getItem('selected-profile')) || null
    const identityProvider = authInfo.identityProvider
    if (sessionState === 'INVALID_SESSION' || !identityProvider || identityProvider === 'mpx') {
      if (sessionState === 'VALID_SESSION' && identityProvider === 'mpx') {
        globalDispatch({ type: 'USER_INFO', value: authInfo || { profileType: 'adult' } })
        globalDispatch({ type: 'USER_TOKEN', value: authInfo.idToken || '' })
        globalDispatch({ type: 'AUTHENTICATED' })
        // globalDispatch({ type: 'SELECTED_PROFILE', value: selectedProfileLocal })
        const selectedProfilefromLS = JSON.parse(window.store.localStorage.getItem('selected-profile'))
        globalDispatch({ type: 'SELECTED_PROFILE', value: selectedProfilefromLS })
      } else {
        globalDispatch({ type: 'UNAUTHENTICATED' })
      }
      globalDispatch({ type: 'IS_REFRESH_TOKEN_ENDED', value: true })
      globalDispatch({ type: 'REFRESH_CALL_STATE', value: 'ENDED' })
      globalDispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })

      if (window.appLaunchTrace) {
        window.appLaunchTrace.addAttrs({ isLoggedIn: 'false' })
        window.appLaunchTrace.stop()
      }
      return
    }

    if (window.appLaunchTrace) window.appLaunchTrace.addAttrs({ isLoggedIn: 'true' })
    // If session is valid use details from LS
    if (sessionState === 'VALID_SESSION') {
      const tokenFromLS = getUserToken()
      if (tokenFromLS) {
        globalDispatch({ type: 'USER_TOKEN', value: tokenFromLS })
        globalDispatch({ type: 'USER_INFO', value: authInfo })
        globalDispatch({ type: 'AUTHENTICATED' })
        globalDispatch({ type: 'IS_REFRESH_TOKEN_ENDED', value: true })
        globalDispatch({ type: 'REFRESH_CALL_STATE', value: 'ENDED' })
        return
      } else {
        globalDispatch({ type: 'UNAUTHENTICATED' })
      }
    }

    globalDispatch({ type: 'IS_REFRESH_TOKEN_ENDED', value: false })
    globalDispatch({ type: 'REFRESH_CALL_STATE', value: 'INITIATED' })
    refreshTrustedLogin(true, authInfo?.userId) // passing user id
      .then((userInfo) => {
        userInfo = userInfo || {}
        log({ type: 'Debug', attr: { module: 'Authentication', debug_message: 'Refresh trusted login success' }}) // send debug log on refresh token api success
        globalDispatch({ type: 'IS_APP_LOADED', value: false })
        globalDispatch({ type: 'AUTHENTICATED' })
        globalDispatch({ type: 'IS_REFRESH_TOKEN_ENDED', value: true })
        globalDispatch({ type: 'REFRESH_CALL_STATE', value: 'ENDED' })
        // globalDispatch({ type: "USER_NAVIGATION_FROM_LOGIN", value: true })
        if (window.appLaunchTrace) {
          window.appLaunchTrace.addAttrs({
            isLoggedIn: 'true',
            userId: userInfo.userId || ''
          })
          window.appLaunchTrace.stop()
        }
      })
      .catch((error = {}) => {
        if (error.errorCode !== 7001) handleError(error)
        globalDispatch({ type: 'IS_REFRESH_TOKEN_ENDED', value: true })
        globalDispatch({ type: 'REFRESH_CALL_STATE', value: 'ENDED' })
        globalDispatch({ type: 'UNAUTHENTICATED' })
        if (window.appLaunchTrace) window.appLaunchTrace.stop()
      })
  }, [
    handleError,
    isAppLoaded,
    getSessionState,
    globalDispatch,
    log,
    refreshCallState,
    refreshTrustedLogin,
    selectedProfile,
    userInfo
  ])
  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect executes once refreshTrusted logi n gets complete
  // And recentlyWatched data fetched and stored in sessionstorage for later use.
  useEffect(() => {
    if (!isAppLoaded) return
    // if not home page then use showspash for recenelty watch api call
    if (!utils.isHomePage() && !isShowSplash) return
    if (refreshCallState !== 'ENDED') return
    // if home page then use all rails loaded for recently watch api call
    if (utils.isHomePage() && !allRailsLoaded) return
    if (version.project !== 'videotron') {
      globalDispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })
      if (utils.isPlayerPage()) {
        globalDispatch({ type: 'SHOW_SPLASH', value: false })
      }
      return
    }

    let isMounted = true
    if (isAuthenticated) {
      if (!appConfig.baseUrl) return
      let params = {
        page: 1,
        language: splitLanguage(appLanguage),
        size: 20
      }
      params = utils.stringify(params)
      globalDispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: false })
      getRecentlyWatched(appConfig.baseUrl, params)
        .then((res) => {
          if (!isMounted) return null
          const content = (res.content || [])
          setRecentlyWatchedLocal(content)
          globalDispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })
          globalDispatch({ type: 'SHOW_SPLASH', value: false })
        }).catch(() => {
          if (!isMounted) return null
          globalDispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })
          globalDispatch({ type: 'SHOW_SPLASH', value: false })
        })
    } else {
      setRecentlyWatchedLocal([])
      globalDispatch({ type: 'SHOW_SPLASH', value: false })
      globalDispatch({ type: 'IS_RECENTLY_WATCHED_ENDED', value: true })
    }

    return () => {
      isMounted = false
    }
  }, [
    appLanguage,
    isAuthenticated,
    isAppLoaded,
    getRecentlyWatched,
    isShowSplash,
    appConfig.baseUrl,
    globalDispatch,
    refreshCallState,
    allRailsLoaded
  ])
  // /* ------------------------------------------------------------------------------------------------ */
  // // This useEffect executes once recentlyWatched api is complete
  // // And recentSearch data fetched and stored in sessionstorage for later use.
  // useEffect(() => {
  //   if (!isAppLoaded) return
  //   if (!appConfig.baseUrl) return
  //   if (utils.isNull(searchConfig.maxSearchHistoryCount)) return
  //   // if (isShowSplash) return
  //   if (utils.isHomePage() && !allRailsLoaded) return

  //   if (refreshCallState === 'INITIATED') return
  //   if (refreshCallState !== 'ENDED') return
  //   let isMounted = true
  //   if (isAuthenticated) {
  //     const profileId = selectedProfile ? selectedProfile.profileId : ''
  //     getRecentSearch(appConfig.baseUrl, searchConfig.maxSearchHistoryCount, profileId)
  //       .then((res) => {
  //         if (!isMounted) return null
  //         let { searchList } = res
  //         searchList = [...new Set(searchList)]
  //         searchList = searchList.slice(0, searchConfig.maxSearchHistoryCount)
  //         setRecentSearchesLocal(searchList)
  //       }).catch(() => { })
  //   } else {
  //     setRecentSearchesLocal([])
  //   }

  //   return () => {
  //     isMounted = false
  //   }
  // }, [
  //   isAuthenticated,
  //   isAppLoaded,
  //   getRecentSearch,
  //   isShowSplash,
  //   appConfig.baseUrl,
  //   searchConfig.maxSearchHistoryCount,
  //   selectedProfile,
  //   allRailsLoaded,
  //   refreshCallState
  // ])
  // /* ------------------------------------------------------------------------------------------------ */
  // // This useEffect executes once recentlyWatched api is complete
  // // And favourites data fetched and stored in sessionstorage for later use.
  // useEffect(() => {
  //   if (!isAppLoaded) return
  //   if (!appConfig.baseUrl) return
  //   // if (isShowSplash) return
  //   if (refreshCallState === 'INITIATED') return
  //   if (refreshCallState !== 'ENDED') return

  //   // if home page then use all rails loaded for fav api call
  //   if (utils.isHomePage() && !allRailsLoaded) return
  //   if (isAuthenticated) {
  //     const options = {
  //       language: splitLanguage(appLanguage),
  //       size: 50,
  //       page: 1
  //     }
  //     globalDispatch({ type: 'FAVOURITES_CALL_STATE', value: 'IDLE' })
  //     getFavourites(appConfig.baseUrl, options)
  //       .then((res) => {
  //         setFavouritesLocal(res.content || [])
  //         globalDispatch({ type: 'FAVOURITES_CALL_STATE', value: 'ENDED' })
  //       })
  //       .catch(() => {
  //         globalDispatch({ type: 'FAVOURITES_CALL_STATE', value: 'ENDED' })
  //       })
  //   } else {
  //     setFavouritesLocal([])
  //   }
  // }, [
  //   appLanguage,
  //   isAuthenticated,
  //   isAppLoaded,
  //   getFavourites,
  //   isShowSplash,
  //   appConfig.baseUrl,
  //   globalDispatch,
  //   allRailsLoaded,
  //   refreshCallState
  // ])

  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect executes once recentlyWatched api is complete
  // And prodducts data fetched and stored in sessionstorage for later use.
  // useEffect(() => {
  //   if (!isAppLoaded) return
  //   if (!appConfig.baseUrl) return
  //   if (isShowSplash) return

  //   if (refreshCallState === 'INITIATED') return
  //   if (refreshCallState !== 'ENDED') return
  //   // if home page then use all rails loaded for products api call
  //   if (utils.isHomePage() && !allRailsLoaded) return
  //   if(!userInfo?.userId) return
  //     //globalDispatch({ type: 'FAVOURITES_CALL_STATE', value: 'IDLE' })
  //     getProducts(appConfig.baseUrl, {
  //       language: splitLanguage(appLanguage),
  //       userId: userInfo?.userId
  //     }).then((res) => {
  //       // console.log(res, "resssssssss")
  //         setProductsLocal({products:res.products || [], purchasedProducts: res.purchasedProducts || []})
  //         //globalDispatch({ type: 'FAVOURITES_CALL_STATE', value: 'ENDED' })
  //       })
  //       .catch(() => {
  //         setProductsLocal({products: [], purchasedProducts: []})
  //         //globalDispatch({ type: 'FAVOURITES_CALL_STATE', value: 'ENDED' })
  //       })

  // }, [appLanguage, isAuthenticated, isAppLoaded, getProducts, isShowSplash, appConfig.baseUrl, allRailsLoaded, refreshCallState, userInfo.userId])

  /* ------------------------------------------------------------------------------------------------ */
  // This useeffect is used to load third party scripts like ad, shaka, mux after splash screen has been loaded
  // for home page use all rails loaded

  useEffect(() => {
    if (!isShowSplash) {
      if (utils.isHomePage() && allRailsLoaded) {
        loadscript('/static/js/mux.min.js')
        loadscript('/static/js/shaka-player.compiled-3.3.9.js')
        loadscript('/static/js/shakaplayer-mux-5.0.0.js')
        // loadscript('https://imasdk.googleapis.com/js/sdkloader/ima3.js')
      } else {
        loadscript('/static/js/mux.min.js')
        loadscript('/static/js/shaka-player.compiled-3.3.9.js')
        loadscript('/static/js/shakaplayer-mux-5.0.0.js')
        // loadscript('https://imasdk.googleapis.com/js/sdkloader/ima3.js')
      }
    }
  }, [isShowSplash, allRailsLoaded])
  /* ------------------------------------------------------------------------------------------------ */
  // Simplet dispatch to differentiate moble user from non mobile user.
  useEffect(() => {
    if (!isAppLoaded) return
    if ((playerFeature && !playerFeature.allowMobileBrowserPlayback) && (isMobile || isTablet)) {
      globalDispatch({ type: 'MOBILE_USER' })
    } else globalDispatch({ type: 'NONMOBILE_USER' })
  }, [
    isAppLoaded, playerFeature.allowMobileBrowserPlayback, globalDispatch, playerFeature
  ])
  /* ------------------------------------------------------------------------------------------------ */
  // Simplet dispatch to identify shared url.
  useEffect(() => {
    const queryStringObj = utils.parse(locationSearchRef.current)
    const isShared = Boolean(queryStringObj.shared)
    globalDispatch({ type: 'IS_SHARED', value: isShared })
  }, [globalDispatch])
  /* ------------------------------------------------------------------------------------------------ */
  // Simplet dispatch to show login popup to the user.
  useEffect(() => {
    if (!isAppLoaded) return

    if (refreshCallState === 'INITIATED') return
    if (refreshCallState !== 'ENDED') return

    const queryStringObj = utils.parse(locationSearchRef.current)
    const isShared = Boolean(queryStringObj.shared)
    const sessionState = getSessionState()
    if (
      (
        sessionState === 'INVALID_SESSION' ||
        sessionState === 'SESSION_EXPIRED'
      ) &&
      userManagementConfig.showLoginPopup === 'OnSplash' &&
      !isShared
    ) {
      globalDispatch({ type: 'SHOW_LOGIN_POPUP' })
    }
  }, [
    isAppLoaded,
    globalDispatch,
    userManagementConfig.showLoginPopup,
    getSessionState,
    refreshCallState
  ])
  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect exectues code to show App maintenance error based on contentful config.
  useEffect(() => {
    const isShownMaintenance = getIsShownMaintenance(); // get is maintenance popup shown value from session storage
    const showSecondaryButton = maintenanceConfig.showMaintenanceMessage && maintenanceConfig.dismissPopup;
    if((isShownMaintenance && showSecondaryButton) || isMaintenancePopupShownRef.current) return //checking if already dismissed the popup then it will not show the maintenance screen unless the session is closed
    if (maintenanceConfig.showMaintenanceMessage) {
      isMaintenancePopupShownRef.current = true;
      setTimeout(() => {
        // show app maintenance error
        showError({
          type: 'AppMaintenance', isSendLog: true, hidePrimaryButton: showSecondaryButton, hideSecondaryButton: !showSecondaryButton, value: {
            onSecondaryBtnClick: () => {
              hideError({ type: 'AppMaintenance' }) 
              hideAllErrors() //hide error on dismiss button click
              setIsShownMaintenance(true); // set is maintenance popup shown value in session storage
            }
          }
        })
      }, 5)
    }
  }, [showError, hideAllErrors, maintenanceConfig, isShowSplash, location, hideError])

  useEffect(() => {
    // console.log(isGeoBlocked, "isGeoBlocked innn")
    // const os = visitor.os.name
    // const browser = visitor.browser.name
    // // window.alert(JSON.stringify(visitor))
    // if (browser === 'Safari' || os === 'iOS') {
    //   if (!isGeoBlocked && !isVpnBlocked) {
    //     setTimeout(() => {
    //       console.log('visitor', visitor)
    //       showError({ type: 'AppMaintenance' })
    //     }, 5)
    //   }
    // }
  }, [showError, isShowSplash, location, isGeoBlocked, isVpnBlocked])
  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect exectues code to logout a user.
  useEffect(() => {
    if (isForceLogout) {
      logout()
    }
  }, [isForceLogout, logout])
  /* ------------------------------------------------------------------------------------------------ */
  // This useEffect exectues code to show/hide network error based on online/offline status from browser.
  useEffect(() => {
    let timer = null

    const isOnline = () => {
      clearTimeout(timer)
      if (!isAppLoaded) window.location.reload()
      hideError({ type: 'AppNoNetwork', isSendLog: true }) // Send appNoNetwork log
    }

    const isOffline = () => {
      timer = setTimeout(() => {
        showError({ type: 'AppNoNetwork' })
      }, 1000)
    }

    window.addEventListener('online', isOnline)
    window.addEventListener('offline', isOffline)

    return () => {
      window.removeEventListener('online', isOnline)
      window.removeEventListener('offline', isOffline)
      clearTimeout(timer)
    }
  }, [hideError, isAppLoaded, showError])
  /* ------------------------------------------------------------------------------------------------ */
  return null
}
/* ---------------------------------------------------------------------------------------------------- */
export default AppBoundry
