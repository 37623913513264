import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const FullscreenExit = ({ color, ...rest }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
<path fill-rule="evenodd" clip-rule="evenodd" d="M29.0622 11.9193H19.0178C18.5003 11.9193 18.0803 11.5002 18.0803 10.9818V0.937378C18.0803 0.41894 18.5003 -0.00012207 19.0178 -0.00012207C19.5353 -0.00012207 19.9553 0.41894 19.9553 0.937378V10.0443H29.0622C29.5807 10.0443 29.9997 10.4633 29.9997 10.9818C29.9997 11.5002 29.5807 11.9193 29.0622 11.9193ZM19.0182 18.0798H29.0626C29.5801 18.0798 30.0001 18.4989 30.0001 19.0173C30.0001 19.5358 29.5801 19.9548 29.0626 19.9548H19.9557V29.0627C19.9557 29.5811 19.5357 30.0002 19.0182 30.0002C18.4998 30.0002 18.0807 29.5811 18.0807 29.0627V19.0173C18.0807 18.4989 18.4998 18.0798 19.0182 18.0798ZM10.9819 -2.83203e-05C10.4644 -2.83203e-05 10.0444 0.419034 10.0444 0.937472V10.0443H0.9375C0.42 10.0443 0 10.4634 0 10.9818C0 11.5003 0.42 11.9193 0.9375 11.9193H10.9819C11.4994 11.9193 11.9194 11.5003 11.9194 10.9818V0.937472C11.9194 0.419034 11.4994 -2.83203e-05 10.9819 -2.83203e-05ZM0.9375 18.0798H10.9819C11.4994 18.0798 11.9194 18.4989 11.9194 19.0173V29.0627C11.9194 29.5811 11.4994 30.0002 10.9819 30.0002C10.4644 30.0002 10.0444 29.5811 10.0444 29.0627V19.9548H0.9375C0.42 19.9548 0 19.5358 0 19.0173C0 18.4989 0.42 18.0798 0.9375 18.0798Z" fill={color}/>
</svg>
)
/* --------------------------------------------- */
FullscreenExit.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
FullscreenExit.defaultProps = {
  color: 'transparent',
  style: {
    width: 35,
    height: 35
  }
}
/* --------------------------------------------- */
export default FullscreenExit
/* --------------------------------------------- */
