// Action type variables
export const SELECTED_LOCALE = 'selectedLocale'
/* --------------------------------------------- */
export const CONTENTFUL_NAVIGATION_RES = 'CONTENTFUL_NAVIGATION_RES'
export const FOOTER_LIST = 'footerList'
export const SPECIAL_LIST = 'specialList'
export const MAX_VISIBLE_MENUITEM = 'maxVisibleMenuItem'
/* --------------------------------------------- */
export const APP_CONFIG = 'appConfig'
export const CONTENTFUL_APPLICATOIN_RES = 'CONTENTFUL_APPLICATOIN_RES'
export const CONTENTFUL_LOCALES_RES = 'CONTENTFUL_LOCALES_RES'
export const MAINTENANCE_CONFIG = 'maintenanceConfig'
export const ALLOWED_LOCALES = 'allowedLocales'
export const APP_MESSAGES = 'appMessages'
export const APPSTORE_CONFIG = 'appStoreConfig'
/* --------------------------------------------- */
export const PLAYER_FEATURE = 'playerFeature'
export const SUPPORT_INFORMATION = 'supportInformation'
export const CATALOG_FEATURE = 'catalogFeature'
export const REVENUE_FEATURE = 'revenueFeature'
export const REVENUE_CONFIG = 'revenueConfig'
export const INTRO_CONFIG = 'introConfig'
/* --------------------------------------------- */
export const APP_THEME_CONFIG = 'appTheme'
export const CONTENTFUL_DEFAULT_PAGE_THEME_RES = 'CONTENTFUL_DEFAULT_PAGE_THEME_RES'
export const CONTENTFUL_DEFAULT_COMPONENT_THEME_RES = 'CONTENTFUL_DEFAULT_COMPONENT_THEME_RES'
export const CONTENTFUL_DEFAULT_POPUP_THEME_RES = 'CONTENTFUL_DEFAULT_POPUP_THEME_RES'
export const CONTENTFUL_FEATURES_RES = 'CONTENTFUL_FEATURES_RES'
export const COMPONENT_THEME_CONFIG = 'componentTheme'
export const POPUP_THEME_CONFIG = 'popupTheme'
export const SUPPORT_PAGE_THEME_CONFIG = 'supportPageTheme'
export const PLAYER_THEME_CONFIG = 'playerTheme'
export const META_THEME_CONFIG = 'metaTheme'
export const INTRO_THEME_CONFIG = 'introThemeConfig'
export const INTRO_THEME_HEADER_CONFIG = 'introThemeHeaderConfig'
export const INTRO_POP_UP_VISIBILITY = 'introPopUpVisibility'
/* --------------------------------------------- */
export const ANALYTICS_INTEGRATIONS = 'ANALYTICS_INTEGRATIONS'
/* --------------------------------------------- */
export const SEARCH_COMPONENTS = 'SEARCH_COMPONENTS'
export const SEARCH_CONFIG = 'SEARCH_CONFIG'
/* --------------------------------------------- */
export const AUTH_CONFIG = 'authConfig'
export const CATALOG_CONFIG = 'CATALOG_CONFIG'
export const SUPPORT_CONFIG = 'SUPPORT_CONFIG'
/* --------------------------------------------- */
export const USER_MANAGEMENT_CONFIG = 'USER_MANAGEMENT_CONFIG'
export const USER_MANAGEMENT_THEMES = 'USER_MANAGEMENT_THEMES'
/* --------------------------------------------- */

export const PROFILE_MANAGEMENT_CONFIG = 'PROFILE_MANAGEMENT_CONFIG'
export const PROFILE_MANAGEMENT_THEMES = 'PROFILE_MANAGEMENT_THEMES'

/* --------------------------------------------- */
export const PAGE_THEME = 'pageTheme'
export const PAGE_COMPONENT = 'pageComponent'
export const FOOTER_MENU_CONFIG = 'footerMenuConfig'
export const PRIMARY_MENU_CONFIG = 'primaryMenuConfig'
export const SPECIAL_MENU_CONFIG = 'specialMenuConfig'
export const SECONDARY_MENU_CONFIG = 'secondaryMenuConfig'
/* --------------------------------------------- */
export const ADVERTISEMENT_FEATURE = 'featureAdvertisement'
export const ADVERTISEMENT_COMPONENTS = 'ADVERTISEMENT_COMPONENTS'
/* --------------------------------------------- */
export const CHANNEL_PROGRAM_LIST = 'CHANNEL_PROGRAM_LIST'
