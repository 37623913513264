import { USER_MANAGEMENT_CONFIG, USER_MANAGEMENT_THEMES } from '../constants/actionTypes'
import inialState from './initalState'
/* --------------------------------------------- */
/** User management config reducer */
const userManagement = (state = inialState, action) => {
  switch (action.type) {
    case USER_MANAGEMENT_CONFIG:
      return {
        ...state,
        userManagementConfig: action.value
      }
    case USER_MANAGEMENT_THEMES:
      return {
        ...state,
        userManagementThemes: action.value
      }
    default: return state
  }
}
/* --------------------------------------------- */
export default userManagement
/* --------------------------------------------- */
