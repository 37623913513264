import React, { useContext } from "react";
import MetaDataPreview from ".";
import { GlobalContext } from "../../context/global.context";

const MetaDataPreviewContainer = () => {
  const { metaDataPreview } = useContext(GlobalContext);
  if (!metaDataPreview) {
    return null;
  } else {
    return <MetaDataPreview />;
  }
};

export default MetaDataPreviewContainer;
