import React from 'react'
import PlayerPlaceholder from "./PlayerPlaceholder";

// Wrapper component for PlayerPlaceholder component
const Wrapper = (props) => {
    return (
        <>
            <PlayerPlaceholder {...props} />
        </>
    )
}
export default Wrapper