import React from 'react'
import { connect } from 'react-redux'
// import styled from 'styled-components'
import { ButtonWrapper, PopUpContent , HeaderTextPrimaryH2 , HeaderTextSecondaryP } from './style'
/* --------------------------------------------- */
// ConfirmPopUp component for popups.
const ConfirmPopUp = ({ popupTheme: theme, onPositiveBtnClick, title, desc, onNegativeBtnClick, negtiveText, positiveText }) => {
  return (
    <div className='confirm-popup-wrapper confirm-popup-wrapper---inline-action'>
      <PopUpContent {...{ theme }} className='confirm-popup__content'>
        <HeaderTextPrimaryH2 {...{ theme }}>{title}</HeaderTextPrimaryH2>
        <HeaderTextSecondaryP {...{ theme }}>{desc}</HeaderTextSecondaryP>
        <ButtonWrapper {...{ theme }} className='confirm-popup__btn'>
          <div className='popup-btn popup-btn__positive' onClick={onPositiveBtnClick}>
            {negtiveText}
          </div>
          <div className='popup-btn popup-btn__negative' onClick={onNegativeBtnClick}>
            {positiveText}
          </div>
        </ButtonWrapper>
      </PopUpContent>
    </div>
  )
}
/* --------------------------------------------- */
ConfirmPopUp.defaultProps = {}
/* --------------------------------------------- */
const mapStateToProps = state => {
  return {
    popupTheme: state.themeConfigReducers.popupTheme
  }
}
/* --------------------------------------------- */
export default connect(mapStateToProps, null)(ConfirmPopUp)
/* --------------------------------------------- */
