/** Local storage keys */

export const USER_AUTH_INFO = 'user-auth-info'
export const APP_LANGUAGE = 'app-language'
export const SELECTED_PLAYER_QUALITY = 'selected-player-quality'
export const SELECTED_SUBTITLE_TRACK = 'selected-subtitle-track'
export const AUTO_PLAY_NEXT_EPISODE = 'autoplay-next-episode'
export const SELECTED_PROFILE = 'selected-profile'
export const GRAPHICS = 'graphics'
export const REDIRECT_TO_TVLOGIN = "redirect-to-tvlogin"
export const IS_RENDERED_FROM_SIGNUP = "isSignupPageRendered"
export const IS_RENDERED_FROM_RAIL = "isRailItemRendered"
export const IS_APP_FIRST_OPEN = "isAppFirstOpen"
export const USER_TOKEN = "user-token"
export const CONTENTFULL_TREE = "contentfull-tree"
export const IS_METADATA_TRAILER_MUTED="is_metadata_trailer_muted"
export const SIGN_UP_USER_EMAIL = "sign-up_user_email"
export const APP_BASE_URL = "app-base_url"
export const USER_ID = "app-user-id"
