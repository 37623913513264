import React, { useContext, useEffect, useRef } from 'react'
import visitorInfo from 'visitor-info'
import { Context } from "./context";
import { WarningIcon } from './style'
import { routeMap } from '../../../route'
import utils from '../../../utils/utils'
import { getBrowserName } from '../../../utils/contentfulHandler'
import './ErrorPopup.scss'
import { GlobalContext } from '../../../context/global.context'
import {version as appVersion} from '../../../config';
/* ------------------------------------------------------------------------------------------------ */

const visitor = visitorInfo()
const osInfo = visitor.os || {}
const osMap = {
    'Mac OS': 'mac'
}

// export const testObj = { testFunction(){} }
/* ---------------------------------------------------------------------------------------------------- */
// ErrorPopup component which handles rendering and events.
const ErrorPopup = () => {
    const { appMessages = {}, errorPopup = {}, errorDispatch, userInfo = {}, version = {} } = useContext(Context)
    const { dispatch: globalDispatch, sessionId } = useContext(GlobalContext)
    const sessionIdRef = useRef(sessionId)
    /* ------------------------------------------------------------------------------------------------ */
    const {
        title,
        description,
        code,
        primaryActionType,
        secondaryActionType,
        primaryActionLabel,
        secondaryActionLabel,
        secondaryActionDescription,
        onPrimaryBtnClick,
        onSecondaryBtnClick,
        faqDescription,
        faqLabel,
        faqItem = []
    } = errorPopup || {}
    /* ------------------------------------------------------------------------------------------------ */
    const {
        subscriptionType = '',
        customerType = '',
        source = ''
    } = userInfo || {}
    /* ------------------------------------------------------------------------------------------------ */
    const errorPopupRef = useRef(null)
    /* ------------------------------------------------------------------------------------------------ */
    /** It: Handles primary button click based on contentful config. */
    const handelePrimaryBtnClick = () => {
        if (primaryActionType === 'retry') {
            if (onPrimaryBtnClick) {
                onPrimaryBtnClick()
            } else {
                window.location.reload()
            }
        }
        else if (primaryActionType === 'exit') window.close()
        else if (primaryActionType === 'cancel') {
            // testObj.testFunction()
            if (onPrimaryBtnClick) { onPrimaryBtnClick() }
            errorDispatch({ type: 'ERROR_POPUP', value: {} })
        }
        else if (primaryActionType === 'custom') {
            // testObj.testFunction()
            onPrimaryBtnClick()
        }
    }
    /* ------------------------------------------------------------------------------------------------ */
    /** It: Handles secondary button click based on contentful config. */
    const handeleSecondaryBtnClick = () => {
        if (secondaryActionType === 'retry') {
            if (onSecondaryBtnClick) {
                onSecondaryBtnClick()
            } else {
                window.location.reload()
            }
        }
        else if (secondaryActionType === 'exit') window.close()
        else if (secondaryActionType === 'cancel') {
            // testObj.testFunction()
            if (onSecondaryBtnClick) { onSecondaryBtnClick() }
            errorDispatch({ type: 'ERROR_POPUP', value: {} })
        }
        else if (secondaryActionType === 'custom') {
            // testObj.testFunction()
            onSecondaryBtnClick()
        }
    }
    /* ------------------------------------------------------------------------------------------------ */
    /** It: Contstructs faq url on FAQ button click and opens in new tab */
    const handleFAQBtnClick = () => {
        // testObj.testFunction()
        if (faqItem.length === 0) return
        const { id } = faqItem[0]
        if (!id) return
        const browserName = (visitor.browser || {}).name || ''
        const engineName = (visitor.engine || {}).name || ''
        const filters = utils.clearMissingValues({
            platforms: 'web',
            browser: getBrowserName(browserName, engineName),
            displayOrientation: window.innerWidth > window.innerHeight ? 'landscape' : 'portrait',
            buildType: version.buildType || '',
            minimumBuildNumber: version.buildNumber || '',
            operatingSystem: osMap[osInfo.name] || '',
            subscriptionType,
            userType: customerType,
            userSource: source,
            id: id //added id as param to point to linked faq in the faq page
        })
        const query = utils.stringify(filters)
        window.open(`${window.location.origin}${routeMap.faq}?${query}`, '_blank')
    }
    /* ------------------------------------------------------------------------------------------------ */
    /**
    * It: Enable/disable body scroll.
    */
    useEffect(() => {
        let contentEl = errorPopupRef.current
        utils.disableScroll(contentEl, { reserveScrollBarGap: true })
        globalDispatch({ 
        type: 'POPUP_DETAILS', 
        value: {
            inViewport: true,
            useMargin: utils.hasMargin()
        }
        })
        return () => {
            utils.enableScroll(contentEl)
            globalDispatch({ 
                type: 'POPUP_DETAILS', 
                value: {
                    inViewport: false,
                    useMargin: utils.hasMargin()
                }
            })
        }
    }, [globalDispatch])
  /* ------------------------------------------------------------------------------------------------ */
    return (
        <div ref={errorPopupRef} className='error-popup-wrapper'>
            <div className='error-popup__content'>
                <div className="error-popup-header">
                    <WarningIcon />
                    {title && <h2>{title}</h2>}
                </div>
                {description && <p>{description}</p>}
                {code && <p className='error-code'>{appMessages.error_code}: {code}</p>}
                {sessionIdRef?.current && <p className='error-session-id'>{appMessages.label_sid}: {sessionIdRef?.current} {appVersion?.name ? `(${appVersion?.name})`:'' }</p>}
                <div className='error-popup__btn'>
                    {
                        primaryActionLabel &&
                        <div className='popup-btn popup-btn__positive' onClick={handelePrimaryBtnClick}>
                            {primaryActionLabel}
                        </div>
                    }
                    {
                        secondaryActionDescription &&
                        <span className='popup-btn-subtitle'>{secondaryActionDescription}</span>
                    }
                    {
                        secondaryActionLabel &&
                        <div className='popup-btn popup-btn__negative' onClick={handeleSecondaryBtnClick}>
                            {secondaryActionLabel}
                        </div>
                    }
                </div>
                {
                    faqItem.length > 0 &&
                    <div className='error-popup__faq'>
                        {
                            faqDescription &&
                            <span className='popup-btn-subtitle faq-description'>{faqDescription}</span>
                        }
                        {
                            faqLabel &&
                            <div className='popup-btn popup-btn__negative' onClick={handleFAQBtnClick}>
                                {faqLabel}
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
ErrorPopup.propTypes = {}
/* ---------------------------------------------------------------------------------------------------- */
ErrorPopup.defaultProps = {}
/* ---------------------------------------------------------------------------------------------------- */
export default ErrorPopup
/* ---------------------------------------------------------------------------------------------------- */
