import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const ForwardIconFilled = ({ color, text, ...rest }) => (
  <svg viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g>
      <path
      fill={color} d="M0.999018 16.773C0.99892 18.5825 1.37461 20.3723 2.10227 22.0291C2.82994 23.6859 3.89373 25.1734 5.22627 26.3977C6.55882 27.6219 8.13107 28.556 9.84344 29.1409C11.5558 29.7259 13.371 29.9488 15.174 29.7956C16.9771 29.6425 18.7286 29.1165 20.3178 28.2512C21.907 27.3858 23.2992 26.1998 24.4061 24.7684C25.5131 23.337 26.3107 21.6912 26.7485 19.9355C27.1863 18.1797 27.2547 16.3522 26.9493 14.5686C26.9337 14.4419 26.8926 14.3198 26.8284 14.2095C26.7642 14.0991 26.6784 14.003 26.576 13.9267C26.4737 13.8505 26.3569 13.7958 26.2329 13.7659C26.1088 13.7361 25.98 13.7316 25.8541 13.7529C25.7283 13.7742 25.6081 13.8208 25.5007 13.8898C25.3934 13.9588 25.3012 14.0489 25.2296 14.1546C25.158 14.2602 25.1086 14.3793 25.0843 14.5046C25.06 14.6298 25.0613 14.7588 25.0882 14.8835C25.4578 17.0458 25.183 19.269 24.2981 21.2763C23.4132 23.2835 21.9574 24.9861 20.1119 26.1719C18.2664 27.3578 16.1128 27.9744 13.9193 27.9451C11.7259 27.9159 9.58953 27.2419 7.77635 26.0072C5.96317 24.7725 4.55329 23.0317 3.72234 21.0015C2.89138 18.9714 2.67607 16.7416 3.10321 14.5899C3.53035 12.4383 4.58107 10.4598 6.12451 8.90103C7.66794 7.34223 9.63587 6.27194 11.7832 5.82349V8.12235C11.7818 8.35634 11.8426 8.58649 11.9593 8.78928C12.0761 8.99207 12.2446 9.16021 12.4476 9.2765C12.6499 9.39404 12.8797 9.45595 13.1137 9.45595C13.3476 9.45595 13.5774 9.39404 13.7797 9.2765L19.7977 5.80145C20.0001 5.6845 20.1681 5.51636 20.285 5.31391C20.4018 5.11146 20.4633 4.88183 20.4633 4.64808C20.4633 4.41434 20.4018 4.18471 20.285 3.98226C20.1681 3.77981 20.0001 3.61167 19.7977 3.49472L13.7797 0.021238C13.5774 -0.0957482 13.3479 -0.157385 13.1142 -0.157471C12.8805 -0.157556 12.6509 -0.0960868 12.4485 0.0207514C12.2461 0.13759 12.078 0.305675 11.9613 0.508093C11.8445 0.710511 11.783 0.94012 11.7832 1.17382V3.9041C8.76134 4.44284 6.02523 6.02717 4.05371 8.37981C2.0822 10.7324 1.00088 13.7035 0.999018 16.773Z" 
      />
      <text x="28%" y="67%" fontSize="10" fill={color}>{text} </text>  
    </g>
  </svg>
)
/* --------------------------------------------- */
ForwardIconFilled.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Text inside icon. */
  text: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
ForwardIconFilled.defaultProps = {
  color: 'transparent',
  text: 25,
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default ForwardIconFilled
/* --------------------------------------------- */
