import { useCallback, useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import visitorInfo from 'visitor-info'
import currentDevice from 'current-device'
import moment from 'moment-timezone'
/* --------------------------------------------- */
import { version, appName } from '../config'
/* --------------------------------------------- */
import { GlobalContext } from '../context/global.context'
import { getDeviceType } from '../utils/contentfulHandler'
import _utils from '../utils/utils'
/* --------------------------------------------- */
const { device, browser, os, engine } = visitorInfo()
const osMap = {
  'Mac OS': 'mac'
}
let osName = os.name || ''
if (osMap[os.name]) osName = osMap[os.name] || ''
let browserName = (browser || {}).name || ''
const engineName = (engine || {}).name || ''
if (browserName === 'edge' && engineName === 'blink') browserName = 'edgeChromium'
/* --------------------------------------------- */
// Custom hook to handle loggly events.
const useLoggly = () => {
  const { supportConfig } = useSelector(store => store.support)
  const { allowedLocales } = useSelector((store) => store.appConfigReducers);
  /* --------------------------------------------- */
  const { logger, userInfo, selectedProfile } = useContext(GlobalContext)
  /* --------------------------------------------- */
  const logLevelsRef = useRef([])
  const userInfoRef = useRef({})
  const selectedProfileRef = useRef({})
  /* --------------------------------------------- */
  useEffect(() => {
    logLevelsRef.current = supportConfig.logLevel
  }, [supportConfig.logLevel])
  /* --------------------------------------------- */
  useEffect(() => {
    userInfoRef.current = userInfo
  }, [userInfo])
  /* --------------------------------------------- */
  /* --------------------------------------------- */
  useEffect(() => {
    selectedProfileRef.current = selectedProfile
  }, [selectedProfile])

  const filterOutUnwanted = (logAttributes) => {   //TO remove after change password request body password mask
    const array = logAttributes?.pageUrl?.length ? logAttributes?.pageUrl?.split(/[=?&/]/) : [];
    const isChangePassword = array?.includes('change-password');
    const isResetPassword = array?.includes('reset-password'); // if its reset-password or change-password we dont need to attach the request body that includes sesitive info of user
    let modifiedLogAttr = {...logAttributes}
    if(isChangePassword || isResetPassword) {
      const { errorInfo, request_body, ...modifiedRestLogAttr} = modifiedLogAttr;
      modifiedLogAttr = modifiedRestLogAttr;
    }
    return modifiedLogAttr
  }
  /* --------------------------------------------- */
  const log = useCallback(({ type, attr }) => {
    const logLevels = logLevelsRef.current
    const userInfo = userInfoRef.current
    /* --------------------------------------------- */
    const isSendLog = (type) => {
      return !logLevels || logLevels.length === 0 || logLevels.indexOf(type) > -1
    }
    /* --------------------------------------------- */
    const sendLog = (type, attr) => {
      if (isSendLog(type)) {
        const {error_code, ...allAttr} = attr;
        let logAttributes = {
          timestamp: moment().format(`YYYY-DD-MM HH:MM:SS`), //timestamp format changed to make it common along apps
          app_name: appName,
          app_version: version.number,
          app_env: version.env,
          ...(type === "Error" && {os_name: osName}), //send only if its and error log
          os_version: os.version,
          platform: 'Web',
          browser: browserName,
          ...(type === "Error" && {browser_version: browser.version}), //send only if its and error log
          ...(!attr?.user_id && {user_id: userInfo.userId || ''}), // user id as params
          // profile_id: selectedProfile?.profileId || '',
          subscription_type: userInfo.subscriptionType || '',
          // user_type: userInfo.customerType || '',
          device_brand: device?.vendor || '',
          device_model: device?.model || '',
          network: navigator?.connection?.effectiveType || '',
          device_category: _utils.capitalizeFirstLetter(getDeviceType(currentDevice?.type) || currentDevice?.type || device?.type || ''), //to get current device type
          level: type,
          lang: allowedLocales?.[0]?.code || 'en-US', // on language switching implementation we need to change this
          sourcelocation: window.location.href,
          //added config type related code keys
          ...(type === "Info" && {info_code: error_code}),
          ...(type === "Error" && {error_code: error_code}),
          ...(type === "Debug" && {debug_code: error_code}),
          ...(type === "Warning" && {warning_code: error_code}),
          ...allAttr
        }
        logAttributes = filterOutUnwanted(logAttributes) //TO remove after change password request body password mask
        //filter empty or null values
        logAttributes = Object.entries(logAttributes).reduce((obj,[key,value]) => {
            if(key === 'errorInfo' && value?.config?.headers?.Authorization){
              delete value.config.headers.Authorization
              obj[key]=value
              return obj
            }
            if (value && value !=='-' && value !== '_'){
              if(typeof value === 'string' && value.trim().length === 0 ){
                return obj
              }
              obj[key]=value
              return obj
            }else{
              return obj
            }
          }, {})

        
        logger.push(logAttributes)
      }
    }
    /* --------------------------------------------- */
    sendLog(type, attr)
  }, [logger, allowedLocales])
  /* --------------------------------------------- */
  return {
    log
  }
}
/* --------------------------------------------- */
export default useLoggly
