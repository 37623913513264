import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const CloseIcon = ({ color, ...rest }) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.16293 7.5L14.8628 0.800151C15.0461 0.61687 15.0461 0.32062 14.8628 0.137339C14.6795 -0.0459424 14.3832 -0.0459424 14.2 0.137339L7.50012 6.83718L0.800273 0.137339C0.616992 -0.0459424 0.320742 -0.0459424 0.137461 0.137339C-0.0458203 0.32062 -0.0458203 0.61687 0.137461 0.800151L6.8373 7.5L0.137461 14.1998C-0.0458203 14.3831 -0.0458203 14.6794 0.137461 14.8627C0.228867 14.9541 0.348867 15 0.468867 15C0.588867 15 0.708867 14.9541 0.800273 14.8627L7.50012 8.16281L14.2 14.8627C14.2914 14.9541 14.4114 15 14.5314 15C14.6514 15 14.7714 14.9541 14.8628 14.8627C15.0461 14.6794 15.0461 14.3831 14.8628 14.1998L8.16293 7.5Z" fill={color}/>
  </svg>

)
/* --------------------------------------------- */
CloseIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
CloseIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default CloseIcon
/* --------------------------------------------- */
