import React, { useState, useRef, useCallback, forwardRef, useImperativeHandle, useContext, useEffect } from 'react';
import PropTypes from 'prop-types'
import { getUA } from 'react-device-detect'
import { useSelector } from 'react-redux'
import visitorInfo from 'visitor-info'
/* --------------------------------------------- */
import PlayerSwitcher from './PlayerSwitcher'
import PlayerUI from './PlayerUI';
// import AdUI from './AdUI'
import styled from 'styled-components'
/* --------------------------------------------- */
import utils from '../../utils/utils'
import useErrorUtils from '../../utils/useErrorUtils'
import { getSelectedPlayerQuality, getSelectedSubtitleTrack } from '../../utils/localStorageService'
// import { useEffectDebugger } from '../../utils/debugger'
/* --------------------------------------------- */
import { PlayerContext } from '../../context/player.context'
/* --------------------------------------------- */
import useLoggly from '../../containers/useLoggly'
import { useLocation, useHistory } from 'react-router-dom';
import useGeneralApi from '../../apis/useGeneralApi';
import { GlobalContext } from '../../context/global.context';
import { getBrowserName } from '../../utils/contentfulHandler';
import { getProductsLocal } from '../../utils/sessionStorageService';
import VERSION from '../../constants/apiVersionMap'
import usePlayerApi from '../../apis/usePlayerApi';
import _utils from '../../utils/utils';
import useWindowUnloadEffect from '../../hooks/useWindowUnload';
import usePlaybackError from '../../hooks/usePlaybackError';
import { routeMap } from '../../route';
/* --------------------------------------------- */
const { browser = {}, engine = {} } = visitorInfo()
/* --------------------------------------------- */
// Container component to handle player events 
const Player = forwardRef(({
    thisIsChannel, defaultPlayer, smilUrl, title, subtitle, isLivePlayer,
    onErrorOkClick, castConfigPlaybackFormat, isPortraitPlayerEnabled, bingeCountdownDuration,
    onPlay, onPause, onCurrentTimeChange, onComplete, onUnload, onBuffer, onSubtitleChange, baseUrl,
    thumbnailImgs, messages, mediaId, seriesId, type, episodeNum, seasonNumber, seriesTitle,
    onCrash, showPlaceholderPlayBtn, onPlaceholderPlayBtnClick, allowCasting, sendLog, duration, progress, playerStartTime,
    qualityMappingMode, qualityMapping, abrMode, debugModeEnabled, isTrailer, isPlayerLoaded, setPlayerLoaded, showTwoByThreeImage, isMuted, currentProgram, mediaInfo
}, ref) => {
    const [playbackAssetFormat, updatePlaybackAssetFormat] = useState('')
    const [playbackPreviewAssetFormat, updatePlaybackPreviewAssetFormat] = useState('')
    const [theme, updateTheme] = useState({})
    const [isShakaPlayerMount, setShakaPlayerMount] = useState(false)
    const [retry, setRetry] = useState(false);
    const [smilData, updateSmilData] = useState(null)
    const [vidurl, updateUrl] = useState(null)
    const [smilError, setSmilError] = useState(null);
    const { getData } = useGeneralApi()
    // const [isPlayerError, setIsPlayerError] = useState(false);
    /* --------------------------------------------- */
    const { dispatch, allPlayerStats, setReleaseUrl, retrySmil } = useContext(PlayerContext)
    const { appMessages } = useSelector(store => store.appConfigReducers)
    const { sessionId, userInfo, vidSmilUrl, isNavigatedFromRails, dispatch: globalDispatch } = useContext(GlobalContext)
    const playerFeature = useSelector(store => store.featuresReducers.playerFeature)
    const { supportConfig } = useSelector(store => store.support)
    if (!supportConfig.errorConfiguration) supportConfig.errorConfiguration = []
    const pidRef = useRef('')
    const errorConfigurationRef = useRef(supportConfig.errorConfiguration)
    /* --------------------------------------------- */
    const { getParsedSMIL } = usePlayerApi()
    /* --------------------------------------------- */
    const playerSwitcherRef = useRef({})
    const playerUIRef = useRef({})
    const isAutoPlay = useRef(false)
    const allPlayerStatsRef = useRef(false)
    const playerElementRef = useRef(null)
    const isTrailerRef = useRef(isTrailer)
    const portraitPlayerRef = useRef(isPortraitPlayerEnabled)
    const isPlayerLoadedRef = useRef(isPlayerLoaded)
    const videoEleRef = useRef(null)
    const isUnlockApiTriggered = useRef(false);
    const isUpdateTimerRunning = useRef(false);
    const isSMILApiTriggered = useRef(false);
    const location = useLocation()
    const history = useHistory()
    const intervalRef = useRef();
    const _uuid = useRef(sessionId)
    const retryRef = useRef(false);

    /* --------------------------------------------- */
    const { showError } = useErrorUtils()
    const { log } = useLoggly()
    /* --------------------------------------------- */
    useEffect(() => {
        portraitPlayerRef.current = isPortraitPlayerEnabled
    }, [isPortraitPlayerEnabled])
    /* --------------------------------------------- */
    useEffect(() => {
        isPlayerLoadedRef.current = isPlayerLoaded
    }, [isPlayerLoaded])
    /* --------------------------------------------- */
    useEffect(() => {
        if (!isPlayerLoaded && playerUIRef.current) {
            playerUIRef.current.setPlayerError(false)
            playerUIRef.current.setAutoPlay(false)
        }
    }, [isPlayerLoaded])
    /* --------------------------------------------- */
    /**
     * It: Is called when player controls gets shown or hidden
     */
    const onShowControlsChange = useCallback((bShow) => {
        if(videoEleRef){
            videoEleRef.current = playerElementRef.current.getElementsByTagName("video")[0]
        }
        const cues = videoEleRef?.current?.textTracks ? 
                    videoEleRef.current.textTracks.length ? videoEleRef.current.textTracks[0]?.cues : []
                    : [] 
        if(!cues || cues.length === 0) return
        for(let i=0; i < cues.length; i++){
            cues[i].line = bShow ? -7 : -1
        }
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Gets duration.
     */
    const getDuration = useCallback(() => {
        if (playerSwitcherRef && playerSwitcherRef.current && playerSwitcherRef.current.getDuration) return parseInt(playerSwitcherRef.current.getDuration() * 1000)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Sets playing state on play event.
     */
    const _onPlay = useCallback(() => {
        if (playerUIRef.current.updateIsPlaying) playerUIRef.current.updateIsPlaying(true)
        onPlay()
    }, [onPlay])
    /* --------------------------------------------- */
    /**
     * It: Sets paused state on pause event.
     */
    const _onPause = useCallback(() => {
        if (playerUIRef?.current?.updateIsPlaying) playerUIRef.current.updateIsPlaying(false)
        onPause()
    }, [onPause])
    /* --------------------------------------------- */
    /**
     * It: Sets buffering state on buffer event.
     */
    const _onBuffer = useCallback(() => {
        if (playerUIRef?.current?.updateIsPlayerLoading) playerUIRef.current.updateIsPlayerLoading(true)
        onBuffer()
    }, [onBuffer])
    /* --------------------------------------------- */
    // const onSubtitleTextChange = useCallback((currentText) => {
    //     if (playerUIRef.current.updateSubTitleText) playerUIRef.current.updateSubTitleText(currentText)
    // }, [])
    /* --------------------------------------------- */
    /**
     * It: Updates loading state on buffer complete.
     */
    const onBufferComplete = useCallback(() => {
        if (playerUIRef?.current?.updateIsPlayerLoading) playerUIRef.current.updateIsPlayerLoading(false)
    }, [])
    /* --------------------------------------------- */

    const componentCleanup = useCallback((smilRes) => {
        const smilResponse = smilRes || smilData;
        globalDispatch({ type: 'SET_IS_FROM_RAILS', value: false})
        globalDispatch({ type: 'SET_SMIL_URL', value: ''})
        // checking whether unlock api already triggered
        if (smilResponse  && smilResponse?.querySelector && !isUnlockApiTriggered.current) { //to handle smil api triggering on refresh of player page
            /* --------------------------------------------- */
            const concurrencyServiceUrlTag = smilResponse?.querySelector('meta[name=\'concurrencyServiceUrl\']')
            const concurrencyServiceUrl = concurrencyServiceUrlTag && concurrencyServiceUrlTag.getAttribute('content')
            /* --------------------------------------------- */
            if (!concurrencyServiceUrl) return
            /* --------------------------------------------- */
            const uuid = _uuid.current
            /* --------------------------------------------- */
            const lockIdTag = smilResponse?.querySelector('meta[name=\'lockId\']')
            const lockSequenceTokenTag = smilResponse?.querySelector('meta[name=\'lockSequenceToken\']')
            const lockTag = smilResponse?.querySelector('meta[name=\'lock\']')
            let lockId = lockIdTag && lockIdTag.getAttribute('content')
            let lockSequenceToken = lockSequenceTokenTag && lockSequenceTokenTag.getAttribute('content')
            let lock = lockTag && lockTag.getAttribute('content')
            /* --------------------------------------------- */
            let updateRequestUrl = `${concurrencyServiceUrl}/web/Concurrency/unlock?form=json&schema=1.0&`
            if (uuid) updateRequestUrl += `_clientId=${window.encodeURIComponent(uuid)}&`
            if (lockId) updateRequestUrl += `_id=${window.encodeURIComponent(lockId)}&`
            if (lockSequenceToken) updateRequestUrl += `_sequenceToken=${window.encodeURIComponent(lockSequenceToken)}&`
            if (lock) updateRequestUrl += `_encryptedLock=${window.encodeURIComponent(lock)}&`
            /* --------------------------------------------- */
            getData(updateRequestUrl).then(()=> {
                /* --------------------------------------------- */
                log({
                    type: 'Debug', attr: {
                        module: 'Player',
                        debug_message: 'Concurrency unlock success'
                    }
                })
            }).catch((e) => { 
                showError({
                    type: 'PlaybackGeneric',
                    value: {
                        onPrimaryBtnClick: onErrorOkClick,
                        isPortraitPlayer: portraitPlayerRef.current
                    },
                })   
            })
            isUnlockApiTriggered.current = true;
        }
        clearInterval(intervalRef.current) //to clear the interval for the lock api call
    },[smilData, globalDispatch, getData, log, showError, onErrorOkClick])

    /**
     * It: Shows generic error.
     */
    const _onCrash = useCallback(({ errorCode }, errorInfo) => {
        let shakaErrorLog = errorInfo?.shakaErrorLog ? JSON.parse(errorInfo?.shakaErrorLog) : {};
        console.error(errorInfo)
        let errorType = '';
        if (shakaErrorLog?.category === 6 || errorCode === 6001) {
            if (playerSwitcherRef.current) playerSwitcherRef.current.setPlayerError(true)
            if (playerUIRef.current) playerUIRef.current.setPlayerError(4)
            // setIsPlayerError(true);
            errorType = 'PlaybackDRM';
            showError({
                type: 'PlaybackDRM',
                value: {
                    onPrimaryBtnClick: onErrorOkClick,
                    // isPlayerError: true,
                    isPortraitPlayer: portraitPlayerRef.current
                },
                errorInfo
            })
            onCrash(errorInfo, errorType)
            componentCleanup(smilData);
        } else {
            const urlExtension = shakaErrorLog?.data?.[0] ? _utils.getUrlExtension(shakaErrorLog?.data?.[0]): null;
            const video = smilData?.getElementsByTagName('video')?.[1];
            let manifest = video && video.getAttribute('src')
            if(retry || !manifest || (shakaErrorLog?.data?.[0] === manifest)) { // to show error if video manifest fails
                if(retryRef.current) return
                retryRef.current = true;
                if (playerSwitcherRef.current) playerSwitcherRef.current.setPlayerError(true)
                if (playerUIRef.current) playerUIRef.current.setPlayerError(0)
                // setIsPlayerError(true);
                if(shakaErrorLog && (shakaErrorLog?.category === 4 || ['m3u8', 'mpd'].indexOf(urlExtension) > -1)) {
                    errorType = 'ManifestFailed';
                    showError({
                        type: 'ManifestFailed',
                        value: {
                            onPrimaryBtnClick: onErrorOkClick,
                            // isPlayerError: true,
                            isPortraitPlayer: portraitPlayerRef.current
                        },
                        errorInfo
                    })
                } else if(['m4a', 'm4v', 'm4i'].indexOf(urlExtension) > -1) {
                    errorType = 'PlayerSegmentFailed'
                    showError({
                        type: 'PlayerSegmentFailed',
                        value: {
                            onPrimaryBtnClick: onErrorOkClick,
                            // isPlayerError: true,
                            isPortraitPlayer: portraitPlayerRef.current
                        },
                        errorInfo
                    })
                } else {
                    errorType = 'PlaybackGeneric'
                    showError({
                        type: 'PlaybackGeneric',
                        value: {
                            onPrimaryBtnClick: onErrorOkClick,
                            // isPlayerError: true,
                            isPortraitPlayer: portraitPlayerRef.current
                        },
                        errorInfo
                    })
                }
                componentCleanup(smilData);
                onCrash(errorInfo, errorType)
            } else {
                if (manifest) updateUrl(manifest)
                    setRetry(true)
            }
        }
    }, [onCrash, showError, onErrorOkClick, componentCleanup, smilData, retry])

    useEffect(() => {
        if ((location?.state?.selectedSmil && location?.state?._smilUrl && isNavigatedFromRails) || (!isNavigatedFromRails && location?.state?.selectedSmil && !isSMILApiTriggered.current)  ) { //to handle smil api triggering on refresh of player page
            var el = document.createElement( 'html' );
            el.innerHTML = location?.state?.selectedSmil
            updateSmilData(el)
        }
    }, [location, isNavigatedFromRails, dispatch])

    useEffect(()=>{
        if(retrySmil) {
            isSMILApiTriggered.current = false
            updateSmilData(null)
            globalDispatch({ type: 'SET_SMIL_URL', value: ''})
        }
    },[globalDispatch, retrySmil])
    /* --------------------------------------------- */
    /**
     * It: Fetches SMIL.
     */
    useEffect(() => {
        const uuid = _uuid.current
        if (isSMILApiTriggered.current || vidSmilUrl || smilData || !smilUrl || !userInfo?.userId || !playerFeature || !baseUrl ) return //to handle smil api triggering on refresh of player page
        /* --------------------------------------------- */
        isSMILApiTriggered.current = true;
        let _smilUrl = smilUrl.replace(/^http:\/\//i, `${window.location.protocol}//`)

        let products = getProductsLocal();
        const productId = products?.purchasedProducts?.[0]?.id || '';
        /* --------------------------------------------- */
        const parsedURL = utils.parseUrl(_smilUrl);

        const queryParams = utils.parse(parsedURL.search) || {};
        queryParams.format = 'SMIL';
        queryParams.formats = playerFeature?.fullScreenConfig?.playbackAssetFormat;
        queryParams.tracking = true;
        queryParams.clientId = uuid;
        queryParams.productId = productId;
        queryParams.userId = userInfo?.userId || '';
        queryParams.mediaId = mediaId;

        const queryString = utils.stringify(queryParams)
        _smilUrl = `${baseUrl}${VERSION.smilPlayback}/playback/${mediaId}?${queryString}`

        /* --------------------------------------------- */
        setReleaseUrl(_smilUrl)
        /* --------------------------------------------- */
        dispatch({ type: "RELEASE_URL", value: _smilUrl })
        /* --------------------------------------------- */
        getParsedSMIL(_smilUrl).then((res) => {
            updateSmilData(res)
            globalDispatch({ type: 'SET_SMIL_URL', value: _smilUrl})
        }).catch((error = {}) => {
            globalDispatch({ type: 'SET_SMIL_URL', value: ''})
            setSmilError(error)
            const el = document.createElement( 'html' );
            el.innerHTML = error?.responseBody
            /* --------------------------------------------- */
            const exceptionParam = el?.querySelector('param[name=\'exception\']')
            /* --------------------------------------------- */
            const exception = exceptionParam && exceptionParam.getAttribute('value')
            if(error?.errorCode) {
                if(error?.errorCode === "network_error") {
                    showError({ type: "AppNoNetwork", value: {
                    onPrimaryBtnClick: () => history.push('/'),
                    isPlayerError: false,
                    }, });
                }
            } else if(!exception) {
                showError({ type: "UnknownSmilError", value: {
                    onPrimaryBtnClick: () => history.push('/'),
                    isPlayerError: false,
                }, });
                sendLog('UnknownSmilError', 'Player', error)
            }
            let stringifyError = JSON.stringify(error) 
            updateSmilData(stringifyError)
        })
    }, [_onCrash, retrySmil, vidSmilUrl, sendLog, baseUrl, history, showError, globalDispatch, dispatch, getParsedSMIL, location, mediaId, playerFeature, setReleaseUrl, smilData, smilUrl, userInfo])
    /* --------------------------------------------- */
    /**
     * It: Tigger play event in player.
     * It: Tigger play event in chromecast.
     */
    const onPlayClick = useCallback(() => {
        if (!isPlayerLoadedRef.current) setPlayerLoaded(true)
        else if (playerSwitcherRef && playerSwitcherRef.current && playerSwitcherRef.current.play) {
            try{
              playerSwitcherRef.current.play()
            } catch{}
        }
        /* --------------------------------------------- */
        const castPlayer = window.castPlayer
        if (utils.isNotNull(castPlayer, 'remotePlayer', 'isPaused') &&
            utils.isNotNull(castPlayer, 'remotePlayerController', 'playOrPause') &&
            castPlayer.remotePlayer.isPaused) {
            castPlayer.remotePlayerController.playOrPause();
        }
    }, [setPlayerLoaded])
    /* --------------------------------------------- */
    /**
     * It: Tigger pause event in player.
     * It: Tigger pause event in chromecast.
     */
    const onPauseClick = useCallback(() => {
        if (playerSwitcherRef && playerSwitcherRef.current && playerSwitcherRef.current.pause) playerSwitcherRef.current.pause()
        /* --------------------------------------------- */
        const castPlayer = window.castPlayer
        if (utils.isNotNull(castPlayer, 'remotePlayer', 'isPaused') &&
            utils.isNotNull(castPlayer, 'remotePlayerController', 'playOrPause') &&
            !castPlayer.remotePlayer.isPaused) {
            castPlayer.remotePlayerController.playOrPause();
        }
    }, [])

     /* --------------------------------------------- */
    /**
     * It: Pip start.
     */
     const onPipEnableBtnClick = useCallback(() => {
        if (playerSwitcherRef && playerSwitcherRef.current && playerSwitcherRef.current.pipStart) playerSwitcherRef.current.pipStart()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Pip exit.
     */
    const onPipExitBtnClick = useCallback(() => {
        if (playerSwitcherRef && playerSwitcherRef.current && playerSwitcherRef.current.pipExit) playerSwitcherRef.current.pipExit()
    }, [])
    /* --------------------------------------------- */
    useWindowUnloadEffect(() => componentCleanup(), true) // call onBeforeunload custom hook and do component cleanup on before unload of the component

    const onPrimaryBtnClick = useCallback(() => {
        history.push(routeMap.subscription);
      },[history])

    useEffect(() => {
        if (smilData  && smilData?.querySelector && (vidSmilUrl || isNavigatedFromRails)) { //access only if smilData and smilUrl are available and to handle smil api triggering on refresh of player page
        const isExceptionParam = smilData.querySelector('param[name=\'isException\']')
        /* --------------------------------------------- */
        const isException = isExceptionParam && isExceptionParam.getAttribute('value')
        if(isException) return
        /* --------------------------------------------- */
        const concurrencyServiceUrlTag = smilData?.querySelector('meta[name=\'concurrencyServiceUrl\']')
        const concurrencyServiceUrl = concurrencyServiceUrlTag && concurrencyServiceUrlTag.getAttribute('content')
        /* --------------------------------------------- */
        if (!concurrencyServiceUrl) return
        /* --------------------------------------------- */
        if(isUpdateTimerRunning.current) return //Added updateTimer interval running boolean to avoid unwanted rendering and avoid concurrency lock timer to get initialized again
        const uuid = _uuid.current
        /* --------------------------------------------- */
        const updateLockIntervalTag = smilData?.querySelector('meta[name=\'updateLockInterval\']')
        const lockIdTag = smilData?.querySelector('meta[name=\'lockId\']')
        const lockSequenceTokenTag = smilData?.querySelector('meta[name=\'lockSequenceToken\']')
        const lockTag = smilData?.querySelector('meta[name=\'lock\']')
        /* --------------------------------------------- */
        const updateLockInterval = updateLockIntervalTag && updateLockIntervalTag.getAttribute('content')
        let lockId = lockIdTag && lockIdTag.getAttribute('content')
        let lockSequenceToken = lockSequenceTokenTag && lockSequenceTokenTag.getAttribute('content')
        let lock = lockTag && lockTag.getAttribute('content')

        /* --------------------------------------------- */
        const makeUpdateRequest = () => {
            if(isUnlockApiTriggered.current) return //if unlock api is triggered no need to call update api
            // if (this.state.isReplayScreen) return null // video fully played
            //check for Navigation Timing API support
            //checking whether page got reloaded and we have concurrency token in sessionstorage. And we only take token from session on the fisrt load
            let updateRequestUrl = `${concurrencyServiceUrl}/web/Concurrency/update?form=json&schema=1.0&`
            if (uuid) updateRequestUrl += `_clientId=${window.encodeURIComponent(uuid)}&`
            if (lockId) updateRequestUrl += `_id=${window.encodeURIComponent(lockId)}&`
            if (lockSequenceToken) updateRequestUrl += `_sequenceToken=${window.encodeURIComponent(lockSequenceToken)}&`
            if (lock) updateRequestUrl += `_encryptedLock=${window.encodeURIComponent(lock)}&`

            /* --------------------------------------------- */
            getData(updateRequestUrl)
                .then((res = {}) => {
                    if (!res.updateResponse) return
                    /* --------------------------------------------- */
                    const { encryptedLock, id, sequenceToken } = res.updateResponse
                    /* --------------------------------------------- */
                    lock = encryptedLock
                    lockId = id
                    lockSequenceToken = sequenceToken
                    log({
                        type: 'Debug', attr: {
                            debug_message: 'Concurrency update success',
                            module: 'Player'
                        }
                    })
                }).catch((e) => { 
                    componentCleanup(smilData) // for clearing the timer incase if lock update call fails
                    const inStreamGeoBlockErrors = ['CONTENT_GEO_BLOCK'];
                    const error = e?.responseBody?.error;
                    let errorType = ''
                    if (inStreamGeoBlockErrors.includes(error)) {
                        // Triggering in-stream geo block error
                        showError({
                            type: 'InStreamGeoRestricted',
                            value: {
                                onPrimaryBtnClick: onErrorOkClick,
                            }
                        })
                        errorType = 'InStreamGeoRestricted'
                    } else if(error === "ConcurrencyLimitViolation"){ // if concurrency limit reached
                        // Triggering in-stream concurrency block error
                        showError({
                            type: 'PlaybackConcurrency',
                            value: {
                                onPrimaryBtnClick: onErrorOkClick,
                            }
                        })
                        errorType = 'PlaybackConcurrency'
                    } else { // if any network error occures
                        if(e?.errorCode === "network_error") {
                            e["error"] = 'network error while concurrency update'
                        }
                        showError({
                            type: 'PlaybackGeneric',
                            value: {
                                onPrimaryBtnClick: onErrorOkClick,
                                isPortraitPlayer: portraitPlayerRef.current
                            },
                        })   
                        errorType = 'PlaybackConcurrency'
                    }
                    if(e?.errorCode !== "network_error")
                    sendLog(errorType, 'Player', e)
                    if (playerSwitcherRef.current) playerSwitcherRef.current.setPlayerError(true)
                    if (playerUIRef.current) playerUIRef.current.setPlayerError(0)
                    // setIsPlayerError(true);
                 })
            /* --------------------------------------------- */
        }
        /* --------------------------------------------- */
        isUpdateTimerRunning.current = true; //Added updateTimer interval running boolean to avoid unwanted rendering and avoid concurrency lock timer to get initialized again
        const concurrencyLockTimer = setInterval(() => {makeUpdateRequest()}, Number(updateLockInterval) * 1000)
        intervalRef.current = concurrencyLockTimer;
        /* --------------------------------------------- */
        return () => {
            dispatch({ type: "RETRY_SMIL_PLAYBACK", value: false }) // to fix drm token expiry
            // dispatch({ type: "RETRY_PLAYBACK", value: false }) // to fix drm token expiry
            dispatch({ type: "PLAYBACK_DRM_TOKEN", value: null }) // to fix drm token expiry
            globalDispatch({ type: 'SET_IS_FROM_RAILS', value: false})
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getData, smilData, vidSmilUrl, isNavigatedFromRails, log, componentCleanup, sendLog, showError, onErrorOkClick])
    /* --------------------------------------------- */

    const resetError = () => {
        setSmilError(null)
    }

    usePlaybackError({ currentTime: progress, resetError, durationTime: duration, type, pidRef, detailsSectionData: mediaInfo, releaseUrlRef: smilUrl, onPrimaryBtnClick, errorObj: smilError, smilUrl, smilData, errorConfigurationRef, playerStartTime, contentTitle: title, sendLog: (errorType, module) => sendLog( errorType, module, smilError) })
    
    /* --------------------------------------------- */
    /**
     * It: Mutes playback.
     */
    const onMuteClick = useCallback(() => {
        if (playerSwitcherRef && playerSwitcherRef.current && playerSwitcherRef.current.mute) playerSwitcherRef.current.mute()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: UnMutes playback.
     */
    const onUnmuteClick = useCallback(() => {
        if (playerSwitcherRef && playerSwitcherRef.current && playerSwitcherRef.current.unmute) playerSwitcherRef.current.unmute()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Sets autoPlay state.
     */
    const autoPlay = useCallback((autoPlay) => {
        if (autoPlay) onPlayClick()
        if (playerUIRef.current.setAutoPlay) playerUIRef.current.setAutoPlay(autoPlay)
        isAutoPlay.current = autoPlay
    }, [onPlayClick])
    /* --------------------------------------------- */
    /**
     * It: Gets current time.
     */
    const getCurrentTime = useCallback(() => {
        if (playerSwitcherRef && playerSwitcherRef.current && playerSwitcherRef.current.getCurrentTime) return parseInt(playerSwitcherRef.current.getCurrentTime() * 1000)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Sets current time.
     */
    const setCurrentTime = useCallback((currentTime) => {
        if (!currentTime || isNaN(currentTime) || currentTime < 0) currentTime = 0
        if (utils.isNotNull(playerSwitcherRef, "current", "setCurrentTime")) playerSwitcherRef.current.setCurrentTime(currentTime / 1000)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Get all qualities.
     */
    const getAllQualities = useCallback(() => {
        if (playerSwitcherRef.current) return playerSwitcherRef.current.getAllQualities()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Get current quality.
     */
    const getCurrentQuality = useCallback(() => {
        if (playerSwitcherRef.current) return playerSwitcherRef.current.getCurrentQuality()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Sets current quality.
     */
    const setCurrentQuality = useCallback((quality, options) => {
        if (playerSwitcherRef.current) return playerSwitcherRef.current.setCurrentQuality(quality, options)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Get all subtitles.
     */
    const getAllSubtitles = useCallback(() => {
        if (playerSwitcherRef.current) return playerSwitcherRef.current.getAllSubtitles()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Get current subtitle.
     */
    const getCurrentSubtitle = useCallback(() => {
        if (playerSwitcherRef.current) return playerSwitcherRef.current.getCurrentSubtitle()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Set current subtitles.
     */
    const setCurrentSubtitle = useCallback((subtitle) => {
        if (playerSwitcherRef && playerSwitcherRef.current && playerSwitcherRef.current.setCurrentSubtitle) playerSwitcherRef.current.setCurrentSubtitle(subtitle)
        onSubtitleChange(subtitle)
    }, [onSubtitleChange])
    /* --------------------------------------------- */
    /**
     * It: Get all audios.
     */
    const getAllAudios = useCallback(() => {
        if (playerSwitcherRef.current) return playerSwitcherRef.current.getAllAudios()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Get current audio.
     */
    const getCurrentAudio = useCallback(() => {
        if (playerSwitcherRef.current) return playerSwitcherRef.current.getCurrentAudio()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Sets current audio.
     */
    const setCurrentAudio = useCallback((audio) => {
        if (playerSwitcherRef.current) return playerSwitcherRef.current.setCurrentAudio(audio)
    }, [])
    /* --------------------------------------------- */
    /**
     * When: Current time change.
     * When: Duration time change.
     */
    const _onCurrentTimeChange = useCallback(() => {
        const currentTime = getCurrentTime()
        const duration = getDuration()
        if (playerUIRef.current && playerUIRef.current.onCurrentTimeChange && currentTime) playerUIRef.current.onCurrentTimeChange(currentTime)
        if (playerUIRef.current && playerUIRef.current.updateDuration) playerUIRef.current.updateDuration(duration)
        onCurrentTimeChange(currentTime, duration)
    }, [getCurrentTime, getDuration, onCurrentTimeChange])
    /* --------------------------------------------- */
    /**
     * It: Show/hide back button.
     */
    const showBackBtn = useCallback((isShowBackBtn) => {
        if (playerUIRef.current.showBackBtn) return playerUIRef.current.showBackBtn(isShowBackBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide binge.
     */
    const showBingeWatching = useCallback((isShowBingeWatching) => {
        if (playerUIRef.current.showBingeWatching) return playerUIRef.current.showBingeWatching(isShowBingeWatching)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide breadcrumb.
     */
    const showBreadCrumbView = useCallback((isShowBreadCrumbView) => {
        if (playerUIRef.current.showBreadCrumbView) return playerUIRef.current.showBreadCrumbView(isShowBreadCrumbView)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide elapsed time.
     */
    const showElapsedTime = useCallback((isShowElapsedTime) => {
        if (playerUIRef.current.showElapsedTime) return playerUIRef.current.showElapsedTime(isShowElapsedTime)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide pip button.
     */
     const showPipButton = useCallback((isShowPipButton) => {
        if (playerUIRef.current.showPipButton) return playerUIRef.current.showPipButton(isShowPipButton)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide forward button.
     */
    const showForwardBtn = useCallback((isShowForwardBtn) => {
        if (playerUIRef.current.showForwardBtn) return playerUIRef.current.showForwardBtn(isShowForwardBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide forward duration.
     */
    const setForwardDuration = useCallback((duration) => {
        if (playerUIRef.current.setForwardDuration) return playerUIRef.current.setForwardDuration(duration)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Set playback asset format.
     */
    const setPlaybackAssetFormat = useCallback((playbackAssetFormat) => {
        updatePlaybackAssetFormat(playbackAssetFormat)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Set playback preview asset format.
     */
    const setPlaybackPreviewAssetFormat = useCallback((previewAssetFormat) => {
        updatePlaybackPreviewAssetFormat(previewAssetFormat)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide mute/unmute button
     */
    const showMuteUnmuteBtn = useCallback((isShowMuteUnmuteBtn) => {
        if (playerUIRef.current.showMuteUnmuteBtn) return playerUIRef.current.showMuteUnmuteBtn(isShowMuteUnmuteBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide play/pause button
     */
    const showPlayPauseBtn = useCallback((isShowPlayPauseBtn) => {
        if (playerUIRef.current.showPlayPauseBtn) return playerUIRef.current.showPlayPauseBtn(isShowPlayPauseBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide qulaity selector button
     */
    const showQualitySelectorBtn = useCallback((isShowQualitySelectorBtn) => {
        if (playerUIRef.current.showQualitySelectorBtn) return playerUIRef.current.showQualitySelectorBtn(isShowQualitySelectorBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide audio selector button
     */
     const showAudioSelectorBtn = useCallback((isShowAudioSelectorBtn) => {
        if (playerUIRef.current.showAudioSelectorBtn) return playerUIRef.current.showAudioSelectorBtn(isShowAudioSelectorBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide remaining time
     */
    const showRemainingTime = useCallback((isShowRemainingTime) => {
        if (playerUIRef.current.showRemainingTime) return playerUIRef.current.showRemainingTime(isShowRemainingTime)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide rewind button
     */
    const showRewindBtn = useCallback((isShowRewindBtn) => {
        if (playerUIRef.current.showRewindBtn) return playerUIRef.current.showRewindBtn(isShowRewindBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide next button
     */
     const showNextBtn = useCallback((isShowNextBtn) => {
        if (playerUIRef.current.showNextBtn) return playerUIRef.current.showNextBtn(isShowNextBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide previous 
     */
     const showPreviousBtn = useCallback((isShowPreviousBtn) => {
        if (playerUIRef.current.showPreviousBtn) return playerUIRef.current.showPreviousBtn(isShowPreviousBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide fast rewind button
     */
     const showFastRewindBtn = useCallback((isShowFastRewindBtn) => {
        if (playerUIRef.current.showFastRewindBtn) return playerUIRef.current.showFastRewindBtn(isShowFastRewindBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide fast forward button
     */
     const showFastForwardBtn = useCallback((isShowFastForwardBtn) => {
        if (playerUIRef.current.showFastForwardBtn) return playerUIRef.current.showFastForwardBtn(isShowFastForwardBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide rewind duration
     */
     const setRewindDuration = useCallback((duration) => {
        if (playerUIRef.current.setRewindDuration) return playerUIRef.current.setRewindDuration(duration)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide seek bar
     */
    const showSeekBar = useCallback((isShowSeekBar) => {
        if (playerUIRef.current.showSeekBar) return playerUIRef.current.showSeekBar(isShowSeekBar)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide seek preview
     */
    const showSeekPreview = useCallback((isShowSeekPreview) => {
        if (playerUIRef.current.showSeekPreview) return playerUIRef.current.showSeekPreview(isShowSeekPreview)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide title
     */
    const showTitle = useCallback((isShowTitle) => {
        if (playerUIRef.current.showTitle) return playerUIRef.current.showTitle(isShowTitle)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide total duration
     */
    const showTotalDuration = useCallback((isShowTotalDuration) => {
        if (playerUIRef.current.showTotalDuration) return playerUIRef.current.showTotalDuration(isShowTotalDuration)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide subtitle selector button
     */
    const showSubTitleSelectorBtn = useCallback((isShowSubtitleSelectorBtn) => {
        if (playerUIRef.current.showSubTitleSelectorBtn) return playerUIRef.current.showSubTitleSelectorBtn(isShowSubtitleSelectorBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Show/hide fullscreen button
     */
    const showFullscreenBtn = useCallback((isShowFullscreenBtn) => {
        if (playerUIRef.current.showFullscreenBtn) return playerUIRef.current.showFullscreenBtn(isShowFullscreenBtn)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Sets player theme
     */
    const setPlayerTheme = useCallback((theme) => {
        updateTheme(theme)
        if (playerUIRef.current.setPlayerTheme) return playerUIRef.current.setPlayerTheme(theme)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Shows generic error.
     */
    const setPlayerError = useCallback((isPlayerError, errorInfo) => {
        if (playerSwitcherRef && playerSwitcherRef.current && playerSwitcherRef.current.setPlayerError) playerSwitcherRef.current.setPlayerError(isPlayerError)
        if (playerUIRef.current.setPlayerError) return playerUIRef.current.setPlayerError(0)
        showError({
            type: 'PlaybackGeneric',
            value: {
                onPrimaryBtnClick: onErrorOkClick,
                isPlayerError: true,
                isPortraitPlayer: portraitPlayerRef.current
            },
            errorInfo
        })
        componentCleanup(smilData);
    }, [showError, onErrorOkClick, componentCleanup, smilData])
    /* --------------------------------------------- */
    /**
     * It: Sets player loading state.
     */
    const setPlayerLoading = useCallback((isPlayerLoading) => {
        if (playerUIRef?.current?.updateIsPlayerLoading) return playerUIRef.current.updateIsPlayerLoading(isPlayerLoading)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: sets state: isShakaPlayerMount on player component mount
     */
     const handlePlayerMount = () => {
       setShakaPlayerMount(true)
     }
     /* --------------------------------------------- */
    /**
     * It: Sets player ready state.
     */
    const _onLoad = useCallback(() => {
        const gostHeader = document.getElementsByClassName("gostHeader")[0];
        const header = document.getElementsByClassName("header")[0];
        if(gostHeader) {gostHeader.style.display = "none"}
        if(header) {header.style.display = "none"}
        const media = {
            duration: getDuration && getDuration()
        }
        if(playerSwitcherRef.current.getVideoEle)
            videoEleRef.current = playerSwitcherRef.current.getVideoEle();
        /* --------------------------------------------- */
        let playerSelectedQuality = getCurrentQuality()
        /* --------------------------------------------- */
        let localSelectedSubTitle = getSelectedSubtitleTrack()
        if (playerSwitcherRef && playerSwitcherRef.current && playerSwitcherRef.current.setCurrentSubtitle) playerSwitcherRef.current.setCurrentSubtitle(localSelectedSubTitle.language)
        /* --------------------------------------------- */
        if (playerUIRef.current) {
            if (playerUIRef.current.updateIsPlayerReady) playerUIRef.current.updateIsPlayerReady(true)
            if (playerUIRef.current.updateDuration) playerUIRef.current.updateDuration(media.duration)
            if (playerUIRef.current.updateIsReplayScreen) playerUIRef.current.updateIsReplayScreen(false)
            if (playerUIRef.current.updateDisplayCurrentQuality) playerUIRef.current.updateDisplayCurrentQuality(playerSelectedQuality)
        }
        handlePlayerMount()
        // playerFeature.portraitPlayerEnabled
        if (isAutoPlay.current || isPortraitPlayerEnabled) onPlayClick()
        else onPauseClick()
    }, [getDuration, onPauseClick, onPlayClick, getCurrentQuality, isPortraitPlayerEnabled])
    /* --------------------------------------------- */
    /**
     * It: Sends stats to logly.
     */

    const _onUnload = useCallback(() => {
        const gostHeader = document.getElementsByClassName("gostHeader")[0];
        const header = document.getElementsByClassName("header")[0];
        if(gostHeader) {gostHeader.style.display = "block"}
            if(header) {header.style.display = "block"}
        if (debugModeEnabled) {
            const playerStats = allPlayerStatsRef.current
            const stats4Loggly = {}
            let type4Stats = type || ''
            if (isTrailerRef.current) type4Stats = 'trailer'
            else if (type === 'series') type4Stats = 'episode'
            playerStats.map((stats) => {
                stats.label = ((stats.label || '').toLowerCase() || '').replace(/ /g, '_') || ''
                if (stats.label === 'user_token') return null
                stats4Loggly[stats.label] = stats.value
                return null
            })
            stats4Loggly['content_type'] = type4Stats
            stats4Loggly['url'] = stats4Loggly['release_url'] || ''
            log({
                type: 'Debug',
                attr: {
                    module: 'Player',
                    debug_message: 'Player unloaded'
                }
            })
        }
        onUnload()
    }, [debugModeEnabled, onUnload, type, log])
    /* --------------------------------------------- */
    /**
     * It: Sets replay screen state.
     */
    const _onComplete = useCallback(() => {
        if (utils.isNotNull(playerUIRef, 'current', 'updateIsReplayScreen')) playerUIRef.current.updateIsReplayScreen(true)
        onComplete()
    }, [onComplete])
    /* --------------------------------------------- */
    useEffect(() => {
        allPlayerStatsRef.current = allPlayerStats
    }, [allPlayerStats])
    /* --------------------------------------------- */
    /**
     * It: Dispatches player stats events.
     */
    useEffect(() => {
        dispatch({ type: "USER_AGENT", value: getUA })
        dispatch({ type: "START_TIME", value: new Date() })
        if (window.shaka) {
            dispatch({ type: 'PLAYER_VERSION', value: window.shaka.Player.version })
        }

        const isSafari = getBrowserName((browser || {}).name, (engine || {}).name) === 'safari'
        if (!isSafari) {
            let selectedPlayerQuality = getSelectedPlayerQuality() || ''
            if (!selectedPlayerQuality) selectedPlayerQuality = appMessages.label_player_auto
            dispatch({ type: "STARTING_QUALITY", value: selectedPlayerQuality })
        }
    }, [dispatch, appMessages.label_player_auto])
    /* --------------------------------------------- */
    useImperativeHandle(ref, () => {
        return {
            onPlayClick, onPauseClick, onMuteClick, onUnmuteClick,
            autoPlay, getCurrentTime, setCurrentTime, getDuration,
            getAllQualities, getCurrentQuality, setCurrentQuality,
            getAllSubtitles, getCurrentSubtitle, setCurrentSubtitle, onPipExitBtnClick, onPipEnableBtnClick,
            getAllAudios, getCurrentAudio, setCurrentAudio, setPlaybackAssetFormat, setPlaybackPreviewAssetFormat,
            showBackBtn, showBingeWatching, showBreadCrumbView, showElapsedTime, showForwardBtn, showPipButton,
            setForwardDuration, showMuteUnmuteBtn, showPlayPauseBtn, showQualitySelectorBtn,
            showSubTitleSelectorBtn, showFullscreenBtn, showAudioSelectorBtn, componentCleanup,
            showRemainingTime, showRewindBtn, setRewindDuration, showSeekBar, showSeekPreview, showTitle, showTotalDuration,
            setPlayerTheme, setPlayerLoaded, setPlayerError, setPlayerLoading, showNextBtn, showPreviousBtn, showFastForwardBtn, showFastRewindBtn
        }
    });
    /* --------------------------------------------- */
    return (
        <PlayerWrapper ref={playerElementRef} {...{ theme }} className='player' id='player'>
            {isPlayerLoaded && vidSmilUrl &&
                <PlayerSwitcher ref={playerSwitcherRef} {...{
                    defaultPlayer, smilUrl, baseUrl, mediaId, isLivePlayer,
                    onLoad: _onLoad, onBuffer: _onBuffer, onBufferComplete, onPlay: _onPlay,
                    onPause: _onPause, onCurrentTimeChange: _onCurrentTimeChange, onComplete: _onComplete,
                    onCrash: _onCrash, playbackAssetFormat, playbackPreviewAssetFormat, seasonNumber,
                    castConfigPlaybackFormat, title, onUnload: _onUnload,
                    qualityMappingMode, qualityMapping, abrMode, type, debugModeEnabled, isShakaPlayerMount,
                    isMuted, vidurl, retry, currentProgram, thumbnailImgs, smilContent: smilData
                }} />
            }

            <PlayerUI ref={playerUIRef} {...{
                thisIsChannel,
                isPlayerLoaded,
                title, subtitle, thumbnailImgs,
                onPlayClick, onPauseClick, onMuteClick, onUnmuteClick, onPipEnableBtnClick,
                onPipExitBtnClick,
                getAllQualities,
                isLivePlayer,
                getCurrentQuality, qualityMapping,
                setCurrentQuality,
                episodeNum, seasonNumber, seriesTitle,
                getAllSubtitles,
                getCurrentSubtitle,
                setCurrentSubtitle,
                setCurrentTime,
                onErrorOkClick,
                messages,
                mediaId, seriesId, type,
                showPlaceholderPlayBtn,
                onPlaceholderPlayBtnClick,
                allowCasting,
                debugModeEnabled,
                playerElementRef,
                bingeCountdownDuration,
                onShowControlsChange,
                isShakaPlayerMount,
                showTwoByThreeImage,
            }} />
            {/* <AdUI videoEle={videoEleRef.current} type={type}/>  // ad ui commented need to be included in future*/}
        </PlayerWrapper>
    );
})

const PlayerWrapper = styled.div`
    video::-webkit-media-text-track-display {
        color:${({ theme: { body } }) => body && body.text.primary};
        font-size: 35px;
        font-family: "Open Sans", sans-serif, Helvetica, Arial;
}

  video::cue {
    opacity: .7;
    background-color: black;
  }

`
/* --------------------------------------------- */
Player.propTypes = {
    /* Default player for playback. */
    defaultPlayer: PropTypes.string,
    /* smilUrl for playback. */
    smilUrl: PropTypes.string,
    /* Title to be displayed in the player  */
    title: PropTypes.string,
    /* Subtitle to be displayed in the player  */
    subtitle: PropTypes.string,
    /* Array of thumbnail images to be displayed over the player  */
    thumbnailImgs: PropTypes.array,
    /* This callback gets triggered when player buffers.  */
    onBuffer: PropTypes.func,
    /* This callback gets triggered when player completes buffering.  */
    onBufferComplete: PropTypes.func,
    /* This callback gets triggered when playback plays.  */
    onPlay: PropTypes.func,
    /* This callback gets triggered when playback pauses.  */
    onPause: PropTypes.func,
    /* This callback gets triggered on every seek.  */
    onCurrentTimeChange: PropTypes.func,
    /* This callback gets triggered when playback completes.  */
    onComplete: PropTypes.func,
    /* This callback gets triggered when player crashes.  */
    onCrash: PropTypes.func,
    /* This callback gets triggered when recieved concurrency limit error.  */
    onConcurrencyLimitViolation: PropTypes.func,
    /* This callback gets triggered when recieved geolocation blocked error.  */
    onGeoLocationBlocked: PropTypes.func,
    /* This callback gets triggered when recieved LicenseNotGranted error.  */
    onLicenseNotGranted: PropTypes.func,
    /* This callback gets triggered when recieved unknow format error.  */
    onUnknowFormat: PropTypes.func,
    /* This callback gets triggered when recieved access denied error.  */
    onAccessDenied: PropTypes.func,
    /* This callback gets triggered when fullscreen state changes. */
    onFullscreenChange: PropTypes.func,
    /* On error popup ok click.  */
    onErrorOkClick: PropTypes.func,
    /* This callback gets triggered when subtitle text changes.  */
    // onSubtitleTextChange: PropTypes.func,
    /* This callback gets triggered when player is destroyed.  */
    onUnload: PropTypes.func,
    /* This callback gets triggered when subtitle is changed.  */
    onSubtitleChange: PropTypes.func,
    /* Object contains all messages related to player.  */
    messages: PropTypes.object
}
/* --------------------------------------------- */
Player.defaultProps = {
    defaultPlayer: 'Shaka',
    smilUrl: '',
    title: '',
    subtitle: '',
    thumbnailImgs: [],
    showPlaceholderPlayBtn: false,
    onBuffer: () => { },
    onBufferComplete: () => { },
    onPlay: () => { },
    onPause: () => { },
    onCurrentTimeChange: () => { },
    onComplete: () => { },
    onCrash: () => { },
    onConcurrencyLimitViolation: () => { },
    onGeoLocationBlocked: () => { },
    onLicenseNotGranted: () => { },
    onUnknowFormat: () => { },
    onAccessDenied: () => { },
    onFullscreenChange: () => { },
    onErrorOkClick: () => { },
    // onSubtitleTextChange: () => { },
    onUnload: () => { },
    onSubtitleChange: () => { },
    messages: {}
}
/* --------------------------------------------- */
export default Player;
/* --------------------------------------------- */