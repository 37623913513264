import { getNewObj } from '../utils/contentfulHandler'
import getColorPalette from './getColorPalette'

// Helper function to form contentful tree
const getThemeSection = (contentful) => {
  const colorPaletteLocal = getColorPalette(contentful)
  const themeSectionLocal = contentful.themeSection
  return themeSectionLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { accent = {}, background = {}, text = {} } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      accent: getNewObj(accent, [colorPaletteLocal]),
      background: getNewObj(background, [colorPaletteLocal]),
      text: getNewObj(text, [colorPaletteLocal])
    }
  })
}

export default getThemeSection
