import { useEffect, useContext, useRef } from 'react'
import Bugsnag from '@bugsnag/js'
import { GlobalContext } from '../../context/global.context'
import { bugsnagConfig } from '../../config'

// A helper component to attach userInfo to bugsnag errors.
const BugsnagLoader = (props) => {
    const { userInfo } = useContext(GlobalContext)
    const userInfoRef = useRef(userInfo)

    useEffect(() => {
        userInfoRef.current = userInfo
    }, [userInfo])

    /**
     * It: Sets userId to bugsnag when available.
     */
    useEffect(() => {
        if (!bugsnagConfig.apiKey) return
        const onError = (event) => {
            const userInfo = userInfoRef.current || {}
            const userId = userInfo.userId || ''
            if (userId) {
                event.setUser(userId)
            }
        }
        Bugsnag.addOnError(onError)
        return () => {
            Bugsnag.removeOnError(onError)
        }
    }, [])

    return null
}

export default BugsnagLoader