import { RECENTLY_WATCHED, FAVOURITES, RECENT_SEARCHES, STATE_UUID, PRODUCTS, LANDING_PAGE_RENDERED, EPISODES, SUCCESS_DATA, IS_SHOWN_MAINTENANCE } from './keys'
import ss from 'localstorage-slim';

/** Utility functions to read & write in session storage */

const sessionStorage = window.store.sessionStorage

export const getSessionStorage = (key = '') => {
  if (!key) return {}
  return JSON.parse(sessionStorage.getItem(key)) || null
}

export const setSessionStorage = (key = '', value = {}) => {
  if (!key) return false
  value = JSON.stringify(value || {})
  sessionStorage.setItem(key, value)
  return true
}

export const removeSessionStorage = (key = '') => {
  sessionStorage.removeItem(key)
  return true
}

export const getRecentlyWatchedLocal = () => getSessionStorage(RECENTLY_WATCHED)

export const setRecentlyWatchedLocal = (recentlyWatched = []) => setSessionStorage(RECENTLY_WATCHED, recentlyWatched)

export const removeRecentlyWatchedLocal = () => removeSessionStorage(RECENTLY_WATCHED)

export const getFavouritesLocal = () => getSessionStorage(FAVOURITES)

export const setFavouritesLocal = (favourites = []) => setSessionStorage(FAVOURITES, favourites)

// get is maintenance popup shown value from session storage
export const getIsShownMaintenance = () => getSessionStorage(IS_SHOWN_MAINTENANCE)

// set is maintenance popup shown value in session storage
export const setIsShownMaintenance = (isShownMaintenance = []) => setSessionStorage(IS_SHOWN_MAINTENANCE, isShownMaintenance)

export const removeFavouritesLocal = () => removeSessionStorage(FAVOURITES)

export const getRecentSearchesLocal = () => getSessionStorage(RECENT_SEARCHES)

export const setRecentSearchesLocal = (recentSearches = []) => setSessionStorage(RECENT_SEARCHES, recentSearches)

export const removeRecentSearchesLocal = () => removeSessionStorage(RECENT_SEARCHES)

export const setLandingPageRendered = (landingPageRendered = false) => setSessionStorage(LANDING_PAGE_RENDERED, landingPageRendered)

export const getLandingPageRendered = () => getSessionStorage(LANDING_PAGE_RENDERED)

export const removeLandingPageRendered = () => removeSessionStorage(LANDING_PAGE_RENDERED)

export const setEpisodesList = ({data, mediaId, seasonNo}) => {
  const listL = getSessionStorage(EPISODES) || {}
  if(!listL[mediaId]){
    listL[mediaId]={}
  }
  listL[mediaId][seasonNo] = data;
  setSessionStorage(EPISODES, listL)
}
export const removeEpisode = (mediaId, seasonNo)=>{
  const listL =  getSessionStorage(EPISODES) || {}
  if(listL && listL[mediaId]){
    delete listL[mediaId][seasonNo];
    setSessionStorage(EPISODES, listL)

  }
}

export const getEpisodesList = (mediaId, seasonNo) => {
  const listL =  getSessionStorage(EPISODES) || {}
  if(listL[mediaId] && listL[mediaId][seasonNo]){
    return listL[mediaId] && listL[mediaId][seasonNo]
  }else{
    return null
  }
}

export const removeSessionStorageData = () => {
  removeRecentlyWatchedLocal()
  removeFavouritesLocal()
  removeRecentSearchesLocal()
  removeProductsLocal()
  removeLandingPageRendered()
}

export const setStateUUID = (uuid) => setSessionStorage(STATE_UUID, uuid)

export const getStateUUID = () => getSessionStorage(STATE_UUID)

export const removeStateUUID = () => removeSessionStorage(STATE_UUID)

export const getProductsLocal = () => getSessionStorage(PRODUCTS)

export const setProductsLocal = (products = []) => setSessionStorage(PRODUCTS, products)

export const removeProductsLocal = () => removeSessionStorage(PRODUCTS)

//To get the encrypted payment session data
export const getSuccessData = () => {
  try {
    return ss.get(SUCCESS_DATA, { decrypt: true }) || {};
  } catch (err){
    return {};
  }
}

//to set the payment session data(the data will be encrypted)
export const setSuccessData = (data = null) => {
  try {
    ss.set(SUCCESS_DATA, data || {}, { encrypt: true });
  } catch(err) {}
  return true
}




