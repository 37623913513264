import React, { useContext } from 'react'
import styled from "styled-components";
import utils from "../../../utils/utils";
import { Context } from "./context";
import WarningIconOrginal from '../../icons/WarningIcon'
/* ---------------------------------------------------------------------------------------------------- */
export const Wrapper = (props) => {
  const { errorPopup } = useContext(Context)
  const { theme } = errorPopup
  /* -------------------------------------------------------------------------------------------------- */
  return <StyledWrapper theme={theme}>{props.children}</StyledWrapper>
};
/* ---------------------------------------------------------------------------------------------------- */
export const WarningIcon = (props) => {
  const { errorPopup } = useContext(Context)
  const { theme } = errorPopup
  /* -------------------------------------------------------------------------------------------------- */
  let color = "transparent"
  /* -------------------------------------------------------------------------------------------------- */
  if (utils.isNotNull(theme, "body", "accent", "primary")) color = theme.body.accent.primary;
  /* -------------------------------------------------------------------------------------------------- */
  return <WarningIconOrginal color={color} style={{ width: 45, height: 45, marginBottom: 15 }} {...props} />
};
/* ---------------------------------------------------------------------------------------------------- */
export const StyledWrapper = styled.div`
  .error-popup__content {
    background: ${({ theme }) => {
    if (utils.isNotNull(theme, "body", "background", "primary")) {
      return theme.body.background.primary;
    } else {
      return "transparent";
    }
  }};
  }

  .--hide {
    display: none
  }

  h2 {
    color: ${({ theme }) => {
    if (utils.isNotNull(theme, "header", "text", "primary")) {
      return theme.header.text.primary;
    } else {
      return "transparent";
    }
  }};
  }

  p, span.popup-btn-subtitle {
    color: ${({ theme }) => {
    if (utils.isNotNull(theme, "body", "text", "primary")) {
      return theme.body.text.primary;
    } else {
      return "transparent";
    }
  }};
  }

  .error-code {
    color: ${({ theme }) => {
    if (utils.isNotNull(theme, "body", "text", "secondary")) {
      return theme.body.text.secondary;
    } else {
      return "transparent";
    }
  }};
  }

  .error-session-id {
    color: ${({ theme }) => {
      if (utils.isNotNull(theme, "body", "text", "secondary")) {
        return theme.body.text.secondary;
      } else {
        return "white"; //return white incase if no theme values available
      }
    }};
    font-size: 12px;
  }

  .popup-btn__positive {
      background-color: ${({ theme }) => {
      if (utils.isNotNull(theme, "compositeStyle", "primaryButton", "normal", "background")) {
        return theme.compositeStyle.primaryButton.normal.background;
      } else {
        return "transparent";
      }
      }};
        color: ${({ theme }) => {
      if (utils.isNotNull(theme, "compositeStyle", "primaryButton", "normal", "text")) {
        return theme.compositeStyle.primaryButton.normal.text;
      } else {
        return "transparent";
      }
      }};
      border: ${({ theme }) => {
        if (utils.isNotNull(theme, "compositeStyle", "primaryButton", "normal", "stroke")) {
          return `1px solid ${theme.compositeStyle.primaryButton.normal.stroke}`;
        } else {
          return "transparent";
        }
      }};
      &:hover {
        background-color: ${({ theme }) => {
          if (utils.isNotNull(theme, "compositeStyle", "primaryButton", "focussed", "background")) {
            return theme.compositeStyle.primaryButton.focussed.background;
          } else {
            return "transparent";
          }
        }};
            color: ${({ theme }) => {
          if (utils.isNotNull(theme, "compositeStyle", "primaryButton", "focussed", "text")) {
            return theme.compositeStyle.primaryButton.focussed.text;
          } else {
            return "transparent";
          }
        }};
        border: ${({ theme }) => {
          if (utils.isNotNull(theme, "compositeStyle", "primaryButton", "focussed", "stroke")) {
            return `1px solid ${theme.compositeStyle.primaryButton.focussed.stroke}`;
          } else {
            return "transparent";
          }
        }};
      }
  }

  .popup-btn__negative {
      background-color: ${({ theme }) => {
      if (utils.isNotNull(theme, "compositeStyle", "secondaryButton", "normal", "background")) {
        return theme.compositeStyle.secondaryButton.normal.background;
      } else {
        return "transparent";
      }
    }};
    color: ${({ theme }) => {
      if (utils.isNotNull(theme, "compositeStyle", "secondaryButton", "normal", "text")) {
        return theme.compositeStyle.secondaryButton.normal.text;
      } else {
        return "transparent";
      }
    }};
    border: ${({ theme }) => {
      if (utils.isNotNull(theme, "compositeStyle", "secondaryButton", "normal", "stroke")) {
        return `1px solid ${theme.compositeStyle.secondaryButton.normal.stroke}`;
      } else {
        return "transparent";
      }
    }};
    &:hover {
      background-color: ${({ theme }) => {
        if (utils.isNotNull(theme, "compositeStyle", "secondaryButton", "focussed", "background")) {
          return theme.compositeStyle.secondaryButton.focussed.background;
        } else {
          return "transparent";
        }
      }};
      color: ${({ theme }) => {
        if (utils.isNotNull(theme, "compositeStyle", "secondaryButton", "focussed", "text")) {
          return theme.compositeStyle.secondaryButton.focussed.text;
        } else {
          return "transparent";
        }
      }};
      border: ${({ theme }) => {
        if (utils.isNotNull(theme, "compositeStyle", "secondaryButton", "focussed", "stroke")) {
          return `1px solid ${theme.compositeStyle.secondaryButton.focussed.stroke}`;
        } else {
          return "transparent";
        }
      }};
    }
  }


  .error-fullscreen__btn{
    .popup-btn-subtitle{
      color: ${({ theme }) => {
        if (utils.isNotNull(theme, "body", "text", "secondary")) {
          return theme.body.text.secondary;
        } else {
          return "transparent";
        }
      }};
    }
  }

  .error-fullscreen__faq{

  .popup-btn-subtitle.faq-description{
    color: ${({ theme }) => {
      if (utils.isNotNull(theme, "body", "text", "tertiary")) {
        return theme.body.text.tertiary;
      } else {
        return "transparent";
      }
    }};
  }

  .popup-btn.popup-btn__negative{

    background-color: ${({ theme }) => {
      if (utils.isNotNull(theme, "compositeStyle", "tertiaryButton", "normal", "background")) {
        return theme.compositeStyle.tertiaryButton.normal.background;
      } else {
        return "transparent";
      }
    }};
    color: ${({ theme }) => {
      if (utils.isNotNull(theme, "compositeStyle", "tertiaryButton", "normal", "text")) {
        return theme.compositeStyle.tertiaryButton.normal.text;
      } else {
        return "transparent";
      }
    }};
    border: ${({ theme }) => {
      if (utils.isNotNull(theme, "compositeStyle", "tertiaryButton", "normal", "stroke")) {
        return `1px solid ${theme.compositeStyle.tertiaryButton.normal.stroke}`;
      } else {
        return "transparent";
      }
    }};
  }
}
`;
/* ---------------------------------------------------------------------------------------------------- */
