import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getTheme from './getTheme'
import getLabel from './getLabel'

// Helper function to form contentful tree
const getFeatureAd = (contentful) => {
  const filterLocal = getFilter(contentful)
  const themeLocal = getTheme(contentful)
  const labelLocal = getLabel(contentful)

  const featureAdvertisementLocal = contentful.featureAdvertisement
  return featureAdvertisementLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { filter = {}, linearAdOverrideUrls = [], adPlayerTheme =[] } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      linearAdOverrideUrls: getNew(linearAdOverrideUrls, labelLocal),
      adPlayerTheme: getNew(adPlayerTheme, themeLocal)

    }
  })
}

export default getFeatureAd
