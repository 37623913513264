import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useGlobalNew } from "../../context/global.new.context";
import { GlobalContext } from "../../context/global.context";
import { useMiscsDispatch } from "../../context/miscs.context";
import { setAppLanguage } from '../../utils/localStorageService'
import Button from '../form/Button'

import CloseIcon from "../icons/CloseIcon";

import RadioButton from "../RadioButton";

import utils from '../../utils/utils'

import './index.scss'

const LanguagePopup = () => {
  const [state, dispatch] = useGlobalNew();
  const [languageSelectBox, updateLanguageSelectBox] = useState({})
  const miscsDispatch = useMiscsDispatch();
  const [language, setLanguage] = useState('')

  /* --------------------------------------------- */
  const { dispatch: globalDispatch, appLanguage, uiDirection } = useContext(GlobalContext);
  /* --------------------------------------------- */

  const { appMessages, allowedLocales } = useSelector((store) => store.appConfigReducers);
  const { popupTheme, specialList } = useSelector((store) => store.themeConfigReducers);
  const { secondaryMenuConfig = {} } = useSelector((store) => store.navigation);
  const [ispopupClosed, updatePopupClosed] = useState(false);

    let languagePageTheme;
    let languagePageConfig
    const languagePage = secondaryMenuConfig?.menuItems?.filter(menuItem => menuItem?.id?.trim() === "menu_language")
    if ( languagePage && languagePage.length > 0) {
        languagePageTheme = languagePage[0].theme[0]
        languagePageConfig = languagePage[0].page[0]
    }

  useEffect(() => {
    updateLanguageSelectBox(prevState => ({
      ...prevState,
      items: allowedLocales.map((locale) => {
        return {
          key: locale.code,
          value: locale.name
        }
      })
    }))
  }, [allowedLocales])


  useEffect(() => {
    updateLanguageSelectBox(prevState => ({
      ...prevState,
      defaultOption: appLanguage
    }))
    setLanguage(appLanguage)
  }, [appLanguage])


  useEffect(() => {
    specialList.map(({ title, id, theme: selectboxTheme }) => {
      if (id === 'selector_language') {
        updateLanguageSelectBox(prevState => ({
          ...prevState, title
        }))
      }
      return null
    })

  }, [specialList])


  const handleCloseClick = () => {
    dispatch({ type: "CLOSE_LANGUAGE_POPUP" });
    setLanguage(languageSelectBox?.defaultOption)
    updatePopupClosed(true);
  };

  useEffect(() => {
    if (ispopupClosed) miscsDispatch({ type: "ENABLE_BODY_SCROLL" });
    if (state.isOpenLanguagePopup)
      miscsDispatch({ type: "DISABLE_BODY_SCROLL" });
    else miscsDispatch({ type: "ENABLE_BODY_SCROLL" });
    return () => {
      miscsDispatch({ type: "ENABLE_BODY_SCROLL" });
    };
  }, [state.isOpenLanguagePopup, miscsDispatch, ispopupClosed]);


  const onChangeHandler = (e) => {
    setLanguage(e.target.value)
  }

  const onClickHandler = () => {
    globalDispatch({ type: 'SET_APP_LANGUAGE', value: language })
    if(utils.checkRTLLanguage(language)){
      document.getElementsByTagName('body')[0].setAttribute("dir", "rtl")
      globalDispatch({ type: 'SET_UI_DIRECTION', value: 'rtl'})
    }else {
      globalDispatch({ type: 'SET_UI_DIRECTION', value: 'ltr'})
      document.getElementsByTagName('body')[0].setAttribute("dir", "ltr")
    }
    setAppLanguage(language)
    handleCloseClick()
  }

  return (
    <div
      className={`details-page__pop-up settings-popUp ${
        state.isOpenLanguagePopup ? "details-page__pop-up---open" : ""
      }`}
    >
      <LanguageWrapper
        {...{ theme: popupTheme }}
        className="details-page__pop-up-wrapper"
      >
        <div className="details-page__pop-up-head">
          <PopUpHeader>
            {languagePageConfig?.title || ""}
          </PopUpHeader>
          <div className="player-audio__close" onClick={handleCloseClick} style={{marginTop: 'unset'}}>
            <CloseIcon
              color={
                popupTheme &&
                popupTheme.header &&
                popupTheme.header.accent &&
                popupTheme.header.accent.primary
              }
              style={{ width: 15, height: 15 }}
            />
          </div>
        </div>
        <div>
          <div className="language-change">
              {languageSelectBox && languageSelectBox?.items?.map(ele => {
               return <RadioButton theme={{...languagePageTheme }} uiDirection={uiDirection} onChange={(data) => onChangeHandler(data)} checked={language === ele?.key} id={ele?.key} value={ele?.key} title={ele?.value}/>
              })}
          </div>
          <ButtonWrapper theme={{...languagePageTheme}}>
              <Button {...{ title: appMessages.label_profiles_save_changes }}  onClick={onClickHandler}/>
          </ButtonWrapper>
        </div>
      </LanguageWrapper>
    </div>
  );
};


export const LanguageWrapper = styled.div`
  background: ${({ theme: { body } }) => body && body.background.primary};
`
export const PopUpHeader = styled.p`
  color: ${({ theme: { header } }) => header && header?.text?.primary};
`

const ButtonWrapper = styled.div`
display: flex;
justify-content: center;
button {
  background: ${({ theme: { compositeStyle } }) =>  compositeStyle && compositeStyle?.primaryButton?.normal?.background?.code};
  color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle?.primaryButton?.normal?.text?.code};
  box-shadow: 0 6px 8px 2px rgb(0 0 0 / 48%);
  transition: all 0.3s ease-in-out;
  height: 45px;
  display: block;
  font-weight: 700;
  max-width: 187px;
  margin-top: 3rem;
}
`
export default LanguagePopup