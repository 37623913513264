import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../context/global.context'
import Spinner from '../Spinner'

// Loader component to show spinner during app load.
const Loader = () => {
    const { isAppLoaded } = useContext(GlobalContext)
    const [isShowSpinner, setShowSpinner] = useState(isAppLoaded)

    useEffect(() => {
        if (isAppLoaded) setShowSpinner(false)
    }, [isAppLoaded])

    if (isShowSpinner) return <Spinner size={50} />
    return null
}

export default Loader