import { getNew, getNewObj } from '../utils/contentfulHandler'
import getLanguage from './getLanguage'
import getLabelGroup from './getLabelGroup'
import getFilter from './getFilter'

// Helper function to form contentful tree
const getLocalization = (contentful) => {
  const languageLocal = getLanguage(contentful)
  const labelGroupLocal = getLabelGroup(contentful)
  const filterLocal = getFilter(contentful)
  const localizationLocal = contentful.localization
  return localizationLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { languages = [], labelGroups = [], filter = {} } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      languages: getNew(languages, languageLocal),
      labelGroups: getNew(labelGroups, labelGroupLocal),
      filter: getNewObj(filter, [filterLocal])
    }
  })
}

export default getLocalization
