import React, { useState, useContext, useEffect } from 'react'
import { Context } from "./context";
import './ErrorSnackbar.scss'
/* ---------------------------------------------------------------------------------------------------- */
// ErrorSnackbar component which handles rendering and events.
const ErrorSnackbar = () => {
    const [isActive, setActive] = useState(true)
    /* ------------------------------------------------------------------------------------------------ */
    const { errorSnackbar, dispatch } = useContext(Context)
    const {
        title,
        primaryActionType,
        primaryActionLabel,
        onPrimaryBtnClick = () => { }, 
        loginTheme,
    } = errorSnackbar
    /* ------------------------------------------------------------------------------------------------ */
    /** It: Handles primary button click based on contentful config. */
    const borderTheme =  loginTheme?.compositeStyle?.inputBox?.accent?.error;
    const handelePrimaryBtnClick = () => {
        if (primaryActionType === 'retry') window.location.reload()
        else if (primaryActionType === 'exit') window.close()
        else if (primaryActionType === 'cancel') {
            setActive(false)
            dispatch({ type: 'ERROR_SNACKBAR', value: {} })
        }
        else if (primaryActionType === 'custom') onPrimaryBtnClick()
    }

     /** It: removes toast after 5 seconds. */
     useEffect(() => {
        const timer = setTimeout(() => {
            setActive(false)
            dispatch({ type: 'ERROR_SNACKBAR', value: {} })
        }, 5000)
        return () => {
            clearTimeout(timer)
        }
    }, [dispatch, title])

    /* ------------------------------------------------------------------------------------------------ */
    return (
        <div className={`notification ${isActive ? 'active' : ''}`} style={{border: borderTheme, borderWidth: "1px", borderStyle: "solid"}}>
            <div className='text'>
                <p className="desc">{title}</p>
                <div className='snackbar___action ripple rect' onClick={handelePrimaryBtnClick}>{primaryActionLabel}</div>
            </div>
        </div>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
ErrorSnackbar.propTypes = {}
/* ---------------------------------------------------------------------------------------------------- */
ErrorSnackbar.defaultProps = {}
/* ---------------------------------------------------------------------------------------------------- */
export default ErrorSnackbar
/* ---------------------------------------------------------------------------------------------------- */
