import {
  PAGE_THEME,
  PAGE_COMPONENT,
  FOOTER_MENU_CONFIG,
  PRIMARY_MENU_CONFIG,
  SPECIAL_MENU_CONFIG,
  SECONDARY_MENU_CONFIG
} from '../constants/actionTypes'
import { getMenuButtonStyle, mergeThemes, getThemeData } from '../utils/contentfulThemeMerger'
import { filter, hydratePageComponent } from '../utils/contentfulHandler'
import utils from '../utils/utils'
import { routeMap, routeVarMap } from '../route'

/** NaviagationConfig store */
export const fetchNaviagationConfig = (navigation = [], { appThemeData, componentThemeData }, options) => {
  navigation = navigation || []
  return (dispatch) => {
    navigation = filter(navigation, '', options)
    const pageTheme = []
    const pageComponentsMap = {}
    const pageConfig = {}
    if (utils.isNotNull(navigation, 0)) {
      const primaryMenu = navigation.filter(item => {
        return item.navigationCategory === 'primary'
      })

      let primaryMenuItems = []
      const primaryMenuOverflowItem = {}
      let primaryMenuTheme = {}
      const primaryMenuConfig = primaryMenu[0] || {}
      const pagesNavigation = {}
      if (utils.isNotNull(primaryMenuConfig, 'menuStyle')) {
        options.menuStyle = primaryMenuConfig.menuStyle
      }

      if (utils.isNotNull(primaryMenuConfig, 'menuItems')) {
        let menuItemsConfig = primaryMenuConfig.menuItems
        menuItemsConfig = filter(menuItemsConfig, '', options)

        menuItemsConfig = menuItemsConfig.map((config) => {
          if (utils.isNotNull(config)) {
            return config
          }
          return null
        }).filter(config => config)

        primaryMenuItems = menuItemsConfig.filter((config) => {
          if (utils.isNotNull(config, 'itemStyle')) {
            return config.itemStyle === 'primary'
          }
          return false
        })

        primaryMenuItems = primaryMenuItems.map((config, i) => {
          return { ...config, isHome: i === 0 }
        })

        primaryMenuItems = primaryMenuItems.map((config) => {
          const isHome = !!config.isHome
          if (utils.isNotNull(config, 'page')) {
            const page = filter(config.page, '', options)
            if (utils.isNotNull(page, 0)) {
              let { identifierExt = '', components = [], navigation = [], type } = page[0]

              /** back button navigation */
              navigation = filter(navigation, '', options)
              const selectedNav = navigation[0] || {}
              if (!pagesNavigation[type]) pagesNavigation[type] = {}
              pagesNavigation[type][identifierExt] = { ...selectedNav, isHome }

              pageComponentsMap[identifierExt] = []
              components = filter(components, '', options)
              components.map((comp) => {
                let pConfig = {}
                let pComponentStyle = []
                let pTheme = {}
                let oTheme = {}
                if (utils.isNotNull(comp)) {
                  const component = comp
                  const {
                    config, componentStyles, componentTheme, overflowTheme
                  } = hydratePageComponent(component, options, {
                    defaultPageTheme: appThemeData,
                    defaultComponentTheme: componentThemeData
                  })
                  pConfig = config
                  pComponentStyle = componentStyles
                  pTheme = componentTheme
                  oTheme = overflowTheme

                  // if (utils.isNotNull(component, 'title')) {
                  //   pConfig.title = component.title
                  // }
                  // if (utils.isNotNull(component, 'identifierExt')) {
                  //   pConfig.identifierExt = component.identifierExt
                  // }
                  // if (utils.isNotNull(component, 'contents')) {
                  //   const configs = filter(component.contents, '', options)
                  //   if (utils.isNotNull(configs, 0)) {
                  //     pConfig = { ...pConfig, ...configs[0] }
                  //   }
                  // }
                  // if (utils.isNotNull(component, 'componentStyle')) {
                  //   pComponentStyle = filterComponentStyle(component.componentStyle, options)
                  // }
                  // if (utils.isNotNull(component, 'theme')) {
                  //   const theme = filter(component.theme, '', options)
                  //   if (utils.isNotNull(theme, 0)) {
                  //     pTheme = getThemeData(theme[0], options)
                  //     pTheme = mergeThemes(componentThemeData, pTheme)
                  //   } else if (componentThemeData) pTheme = componentThemeData
                  // } else if (componentThemeData) pTheme = componentThemeData
                  // if (utils.isNotNull(component, 'maxItems')) {
                  //   pConfig.maxItems = component.maxItems
                  // }
                  // if (utils.isNotNull(component, 'overflowListingTheme')) {
                  //   const theme = filter(component.overflowListingTheme, '', options)
                  //   if (utils.isNotNull(theme, 0)) {
                  //     oTheme = getThemeData(theme[0], options)
                  //     oTheme = mergeThemes(appThemeData, oTheme)
                  //   } else if (appThemeData) oTheme = appThemeData
                  // } else if (appThemeData) oTheme = appThemeData
                }
                let sysId = ''
                if (utils.isNotNull(comp, 'sysId')) {
                  sysId = comp.sysId
                }
                pageComponentsMap[identifierExt].push(sysId)
                pageConfig[sysId] = {
                  config: pConfig,
                  componentStyle: pComponentStyle,
                  theme: pTheme,
                  overflowListingTheme: oTheme
                }
                return null
              })
            }
          }
          return config
        })
        primaryMenuItems = primaryMenuItems.map((config) => {
          if (utils.isNotNull(config, 'page')) {
            const page = filter(config.page, '', options)
            if (utils.isNotNull(page, 0)) {
              config = { ...page[0], ...config }
            }
          }
          return config
        })
        primaryMenuItems = primaryMenuItems.map((config) => {
          const { id, type, identifierExt } = config
          let { components = [] } = config
          components = filter(components, '', options) || []
          let { buttonStyle } = config
          if (buttonStyle) buttonStyle = getMenuButtonStyle(buttonStyle, options)
          if (utils.isNotNull(appThemeData, 'compositeStyle', 'primaryButton')) {
            if (buttonStyle) buttonStyle = mergeThemes(appThemeData.compositeStyle.primaryButton, buttonStyle)
            else buttonStyle = appThemeData.compositeStyle.primaryButton
          }
          const [firstComp = {}] = components || []
          let { contents = [] } = firstComp || {}
          contents = filter(contents, '', options) || []

          let url = ''
          if (id === 'myaccount') {
            url = routeMap.myAccount
          } else if (components.length > 0) {
            switch (type) {
              case 'listing': {
                if (utils.isNotNull(contents, 0, 'params')) {
                  let params = contents[0].params
                  params = params && params.replace(/,/g, '&')
                  url = `${routeMap.listing}?${params}&sysId=${components[0].sysId}&identifierExt=${identifierExt}`
                }
                break
              }
              case 'details': {
                if (utils.isNotNull(contents, 0, 'data')) {
                  url = `${routeMap.details.replace(':pageName', identifierExt).replace(':type', routeVarMap.type.channel).replace(':mediaId', contents[0].data)}?identifierExt=${identifierExt}`
                }
                break
              }
              case 'player': {
                if (utils.isNotNull(contents, 0, 'data')) {
                  url = `${routeMap.player}?channelId=${contents[0].data}`
                }
                break
              }
              case 'page': {
                if (identifierExt === "favorites") {
                  url = routeMap.favourites
                } else if (identifierExt) {
                  url = routeMap.catalog.replace(':pageName', identifierExt)
                }
                break
              }
              case 'external':
              case 'webview': {
                if (utils.isNotNull(contents, 0, 'data')) {
                  url = contents[0].data
                }
                break
              }
              default: break
            }
          }
          return { ...config, url, buttonStyle }
        })
        primaryMenuItems = primaryMenuItems.map((config) => {
          let { identifierExt = '', theme = [] } = config
          if (theme.length === 0) {
            theme = [appThemeData]
          }
          if (utils.isNotNull(theme, 0)) {
            const pageThemeData = getThemeData(theme[0], options)
            pageTheme[identifierExt] = mergeThemes(appThemeData, pageThemeData)
          }
          return config
        })

        dispatch({
          type: PAGE_COMPONENT,
          value: {
            pageComponentsMap,
            pageComponents: pageConfig,
            pagesNavigation
          }
        })

        if (utils.isNotNull(primaryMenuConfig, 'overflowItem')) {
          const overflowItem = primaryMenuConfig.overflowItem
          let { buttonStyle } = overflowItem
          if (buttonStyle) buttonStyle = getMenuButtonStyle(buttonStyle)
          if (utils.isNotNull(appThemeData, 'compositeStyle', 'primaryButton')) {
            if (buttonStyle) buttonStyle = mergeThemes(appThemeData.compositeStyle.primaryButton, buttonStyle)
            else buttonStyle = appThemeData.compositeStyle.primaryButton
          }
          primaryMenuOverflowItem.buttonStyle = buttonStyle
        }

        let footerMenuItems = menuItemsConfig.filter((config) => {
          if (utils.isNotNull(config, 'itemStyle')) {
            return config.itemStyle === 'footer'
          }
          return false
        })
        footerMenuItems = footerMenuItems.map((config) => {
          if (utils.isNotNull(config, 'page')) {
            const page = filter(config.page, '', options)
            if (utils.isNotNull(page, 0)) {
              config = { ...page[0], ...config }
            }
          }
          return config
        })
        footerMenuItems = footerMenuItems.map((config) => {
          let { buttonStyle } = config
          if (buttonStyle) buttonStyle = getMenuButtonStyle(buttonStyle)
          if (utils.isNotNull(appThemeData, 'compositeStyle', 'primaryButton')) {
            if (buttonStyle) buttonStyle = mergeThemes(appThemeData.compositeStyle.primaryButton, buttonStyle)
            else buttonStyle = appThemeData.compositeStyle.primaryButton
          }
          return { ...config, buttonStyle }
        })
        footerMenuItems = footerMenuItems.map((config) => {
          let { identifierExt, theme = [] } = config
          if (theme.length === 0) {
            theme = [appThemeData]
          }
          if (utils.isNotNull(theme, 0)) {
            const pageThemeData = getThemeData(theme[0], options)
            pageTheme[identifierExt] = mergeThemes(appThemeData, pageThemeData)
          }
          return config
        })

        dispatch({
          type: FOOTER_MENU_CONFIG,
          value: footerMenuItems
        })

        let specialMenuItems = menuItemsConfig.filter((config) => {
          if (utils.isNotNull(config, 'itemStyle')) {
            return config.itemStyle === 'special'
          }
          return false
        })
        specialMenuItems = specialMenuItems.map((config) => {
          if (utils.isNotNull(config, 'page')) {
            const page = filter(config.page, '', options)
            if (utils.isNotNull(page, 0)) {
              config = { ...page[0], ...config }
            }
          }
          return config
        })
        specialMenuItems = specialMenuItems.map((config) => {
          let { buttonStyle } = config
          if (buttonStyle) buttonStyle = getMenuButtonStyle(buttonStyle)
          if (utils.isNotNull(appThemeData, 'compositeStyle', 'primaryButton')) {
            if (buttonStyle) buttonStyle = mergeThemes(appThemeData.compositeStyle.primaryButton, buttonStyle)
            else buttonStyle = appThemeData.compositeStyle.primaryButton
          }
          return { ...config, buttonStyle }
        })
        specialMenuItems = specialMenuItems.map((config) => {
          let { identifierExt, theme = [] } = config
          if (theme.length === 0) {
            theme = [appThemeData]
          }
          if (utils.isNotNull(theme, 0)) {
            const pageThemeData = getThemeData(theme[0], options)
            pageTheme[identifierExt] = mergeThemes(appThemeData, pageThemeData)
          }
          return config
        })

        dispatch({
          type: SPECIAL_MENU_CONFIG,
          value: specialMenuItems
        })
      }

      if (utils.isNotNull(primaryMenuConfig, 'theme')) {
        const theme = filter(primaryMenuConfig.theme, '', options)
        if (utils.isNotNull(theme, 0)) {
          primaryMenuTheme = getThemeData(theme[0], options)
          primaryMenuTheme = mergeThemes(appThemeData, primaryMenuTheme)
        } else if (appThemeData) primaryMenuTheme = appThemeData
      } else if (appThemeData) primaryMenuTheme = appThemeData

      dispatch({
        type: PRIMARY_MENU_CONFIG,
        value: {
          overflowItem: primaryMenuOverflowItem,
          menuItems: primaryMenuItems,
          theme: primaryMenuTheme,
          maxVisibleItems: primaryMenuConfig.maxVisibleItems || 1
        }
      })

      const profileMenu = navigation.filter(item => {
        return item.navigationCategory === 'profile'
      })
      let profileMenuTheme = {}
      let profileMenuOverflowTheme = {}
      let profileMenuItems = []
      const profileMenuOverflowItem = {}
      const profileMenuConfig = profileMenu[0] || {}
      if (utils.isNotNull(profileMenuConfig, 'menuItems')) {
        let menuItemsConfig = profileMenuConfig.menuItems
        menuItemsConfig = filter(menuItemsConfig, '', options)
        menuItemsConfig = menuItemsConfig.map((config) => {
          if (utils.isNotNull(config)) {
            return config
          }
          return null
        }).filter(config => config)
        profileMenuItems = menuItemsConfig.filter((config) => {
          if (utils.isNotNull(config, 'itemStyle')) {
            return config.itemStyle === 'primary'
          }
          return false
        })
        profileMenuItems = profileMenuItems.map((config) => {
          if (utils.isNotNull(config, 'page')) {
            const page = filter(config.page, '', options)
            if (utils.isNotNull(page, 0)) {
              config = { ...page[0], ...config }
              if (config.id === 'favorites'){
                let { identifierExt = '', components = [] } = page[0]
                pageComponentsMap[identifierExt] = []
                components = filter(components, '', options)
                components.map((comp) => {
                  let pConfig = {}
                  let pComponentStyle = []
                  let pTheme = {}
                  let oTheme = {}
                  if (utils.isNotNull(comp)) {
                    const component = comp
                    const {
                      config, componentStyles, componentTheme, overflowTheme
                    } = hydratePageComponent(component, options, {
                      defaultPageTheme: appThemeData,
                      defaultComponentTheme: componentThemeData
                    })
                    pConfig = config
                    pComponentStyle = componentStyles
                    pTheme = componentTheme
                    oTheme = overflowTheme

                  }
                  let sysId = ''
                  if (utils.isNotNull(comp, 'sysId')) {
                    sysId = comp.sysId
                  }
                  pageComponentsMap[identifierExt].push(sysId)
                  pageConfig[sysId] = {
                    config: pConfig,
                    componentStyle: pComponentStyle,
                    theme: pTheme,
                    overflowListingTheme: oTheme
                  }
                  return null
                })
                }
            }
          }
          return config
        })

        profileMenuItems = profileMenuItems.map((config) => {
          let { buttonStyle } = config
          if (buttonStyle) buttonStyle = getMenuButtonStyle(buttonStyle)
          if (utils.isNotNull(appThemeData, 'compositeStyle', 'primaryButton')) {
            if (buttonStyle) buttonStyle = mergeThemes(appThemeData.compositeStyle.primaryButton, buttonStyle)
            else buttonStyle = appThemeData.compositeStyle.primaryButton
          }
          let url = ''
          if (['external', 'webview'].indexOf(config.type) > -1) {
            if (utils.isNotNull(config, 'components')) {
              const components = filter(config.components, '', options) || []
              if (utils.isNotNull(components, 0, 'contents')) {
                const contents = filter(components[0].contents, '', options) || []
                url = (contents[0] || {}).data
              }
            }
          }
          return { ...config, url, buttonStyle }
        })

        profileMenuItems = profileMenuItems.map((config) => {
          let { identifierExt, theme = [] } = config
          if (theme.length === 0) {
            theme = [appThemeData]
          }
          if (utils.isNotNull(theme, 0)) {
            const pageThemeData = getThemeData(theme[0])
            pageTheme[identifierExt] = mergeThemes(appThemeData, pageThemeData)
          }
          return config
        })
      }
      if (utils.isNotNull(profileMenuConfig, 'theme')) {
        const theme = filter(profileMenuConfig.theme, '', options)
        if (utils.isNotNull(theme, 0)) {
          profileMenuTheme = getThemeData(theme[0], options)
          profileMenuTheme = mergeThemes(appThemeData, profileMenuTheme)
        } else if (appThemeData) profileMenuTheme = appThemeData
      } else if (appThemeData) profileMenuTheme = appThemeData

      if (utils.isNotNull(profileMenuConfig, 'overflowTheme')) {
        const theme = filter(profileMenuConfig.overflowTheme, '', options)
        if (utils.isNotNull(theme, 0)) {
          profileMenuOverflowTheme = getThemeData(theme[0], options)
          profileMenuOverflowTheme = mergeThemes(appThemeData, profileMenuOverflowTheme)
        } else if (appThemeData) profileMenuOverflowTheme = appThemeData
      } else if (appThemeData) profileMenuOverflowTheme = appThemeData

      if (utils.isNotNull(profileMenuConfig, 'overflowItem')) {
        const overflowItem = profileMenuConfig.overflowItem
        let { buttonStyle } = overflowItem
        if (buttonStyle) buttonStyle = getMenuButtonStyle(buttonStyle)
        if (utils.isNotNull(appThemeData, 'compositeStyle', 'primaryButton')) {
          if (buttonStyle) buttonStyle = mergeThemes(appThemeData.compositeStyle.primaryButton, buttonStyle)
          else buttonStyle = appThemeData.compositeStyle.primaryButton
        }
        profileMenuOverflowItem.buttonStyle = buttonStyle
      }
      dispatch({
          type: PAGE_COMPONENT,
          value: {
            pageComponentsMap,
            pageComponents: pageConfig,
            pagesNavigation
          }
        })
      dispatch({
        type: SECONDARY_MENU_CONFIG,
        value: {
          overflowItem: profileMenuOverflowItem,
          overflowTheme: profileMenuOverflowTheme,
          menuItems: profileMenuItems,
          theme: profileMenuTheme,
          maxVisibleItems: profileMenuConfig.maxVisibleItems || 1
        }
      })
    }

    dispatch({
      type: PAGE_THEME,
      value: pageTheme
    })

    return Promise.resolve()
  }
}
