import { useCallback, useContext, useRef, useEffect } from 'react'
import useGeneralApi from './useGeneralApi'
import useAuthApi from './useAuthApi'
import utils from '../utils/utils'
import { GlobalContext } from '../context/global.context'
import { getAppLanguage, setAppLanguage } from '../utils/localStorageService'
import { checkStaticPage, getBrowserName } from '../utils/contentfulHandler'
import visitorInfo from 'visitor-info'
import { version } from '../config'
import device from 'current-device'
import { detectIncognito } from 'detect-incognito'

const isStatic = checkStaticPage()
const isCallbackRoute = utils.startsWith(decodeURIComponent(window?.location?.pathname), '/callback')
const query = utils.parse(window.location.search) || {}
const visitor = visitorInfo()
const osInfo = visitor.os || {}
const osMap = {
  'Mac OS': 'mac'
}

let filterParams = { filtering: true }
if (isStatic && !isCallbackRoute) {
  filterParams.platform = query.platforms || 'web'
  filterParams.browser = getBrowserName(query.browser) || ''
  filterParams.buildType = query.buildType || ''
  filterParams.minimumBuildNumber = query.minimumBuildNumber || ''
  filterParams.deviceCategory = query.deviceCategory || ''
  filterParams.operatingSystem = query.operatingSystem || ''
} else {
  const browserName = (visitor.browser || {}).name || ''
  const engineName = (visitor.engine || {}).name || ''
  filterParams.platform = 'web'
  filterParams.browser = getBrowserName(browserName, engineName) || ''
  filterParams.buildType = version.buildType || ''
  filterParams.minimumBuildNumber = version.buildNumber || ''
  filterParams.deviceCategory = device.type || ''
  if (osMap[osInfo.name]) filterParams.operatingSystem = osMap[osInfo.name] || ''
  else filterParams.operatingSystem = osInfo.name || ''
}

/* --------------------------------------------- */
const usePlayerApi = () => {
  const { getData } = useGeneralApi()
  const { verifySession } = useAuthApi()
  const { userToken, isContentfulPreview } = useContext(GlobalContext)
  const userTokenRef = useRef(userToken)
  /* --------------------------------------------- */
  useEffect(() => {
    userTokenRef.current = userToken
  }, [userToken])
  /* --------------------------------------------- */
  const getParsedSMIL = useCallback((url) => {
    const locale = getAppLanguage() || window.navigator.userLanguage || window.navigator.language || 'en-US'
    setAppLanguage(locale)

    if (locale) filterParams.locale = locale
    filterParams = utils.clearMissingValues(filterParams)
    // filterParams.applicationType = window?.location?.host?.includes("sports") ? 'sports' : 'sports'
    if(isContentfulPreview){
      filterParams.preview = isContentfulPreview //contentfull preview 
    }
    const neededQueryParam =  {...filterParams};
    delete neededQueryParam.platform
    const params = utils.stringify(neededQueryParam)
    return verifySession('', async() => {
      const Authorization = `Bearer ${userTokenRef.current}`
      let isIncognito = false; //initially isIncognito value would be false
      //We were using detect incognito package to identify the browser whether incognito mode or not, we are awaiting for the response from detectIncognito and calling smil api now, error case also handled.
      try {
        isIncognito = await detectIncognito().then((result) => { return result?.isPrivate}).catch(() => {return false}); //returns whether the browser mode is incognito or not
      } catch {}
      return getData(`${url}&${params}&isIncognito=${isIncognito}`, {}, { Authorization}, {}, { isDoNotSetSessionId: true }).then(response => utils.parseXML(response)) // passing is incognito param to playback api
    }, { url })
  }, [getData, isContentfulPreview, verifySession])
  /* --------------------------------------------- */
  return { getParsedSMIL }
}
/* --------------------------------------------- */
export default usePlayerApi
