import React from 'react'
import RadioButton from './RadioButton'
import { Wrapper } from './style'
/* ---------------------------------------------------------------------------------------------------- */
// Wrapper component for RadioButton component.
const RadioButtonWrapper = (props) => {
    return (
        <Wrapper theme={props.theme} uiDirection={props.uiDirection}>
            <RadioButton {...props} />
        </Wrapper>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
export default RadioButtonWrapper
/* ---------------------------------------------------------------------------------------------------- */
