// useWindowUnload custom hook is used to call callback function passed to this hook (handler) when user clicks on reload or back button of the browser
import { useEffect, useRef } from 'react';

const useWindowUnloadEffect = (handler, callOnCleanup) => {
  const cb = useRef() // callback 
  
  cb.current = handler // assigns handler in useRef variable
  
  // on onbeforeunload event callback gets initiated
  useEffect(() => {
    const handler = () => cb.current()
  
    window.addEventListener('beforeunload', handler)
    
    return () => {
      if(callOnCleanup) handler()
    
      window.removeEventListener('beforeunload', handler)
    }
  }, [callOnCleanup])
}

export default useWindowUnloadEffect;