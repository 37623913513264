import React, { useEffect, useState, useContext, useRef } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import SplashDesktop from '../../imgs/splash_web.png'
import SplashMobile from '../../imgs/splash_mobile.png'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
/* --------------------------------------------- */
import { device } from '../../constants/breakpoints'
import utils from '../../utils/utils'
import { getGraphics, getIsFirstOpenEventTriggered, setIsFirstOpenEventTriggered} from '../../utils/localStorageService'
import { version } from '../../config'
/* --------------------------------------------- */
import '../../styles/pages/SplashPage.scss'
import Spinner from '.././Spinner'
import useAnalytics from '../../containers/useAnalytics'
var VERSION = require('../../constants/hardcoded.json')

/* --------------------------------------------- */
// SplashPage component having logic for displaying splash page
const SplashPage = () => {
  const { isShowSplash, dispatch, isShowSpinner, isInitialAppLoad, userInfo } = useContext(GlobalContext)
  const [graphics, setGraphics] = useState(getGraphics())
  const { appTheme: theme = {}} = useSelector(store => store.themeConfigReducers)
  const { appMessages = {} } = useSelector(store => store.appConfigReducers)
  const { sendAnalytics } = useAnalytics()
  const sendAnalyticsRef = useRef(false)
  /* --------------------------------------------- */
  /**
   * It: Sets image in a state.
   */

   useEffect(() => {
    window.FreshworksWidget('hide', 'launcher');
  }, [])
  useEffect(() => {
    if (theme.graphics) setGraphics(theme.graphics)
  }, [theme.graphics])
  /* --------------------------------------------- */
  /**
   * It: Enable/disable body scroll.
   * It: Show/hide spinner.
   */
  useEffect(() => {
    if (isShowSplash) {
      disableBodyScroll(document.body, { reserveScrollBarGap: true })
      dispatch({ type: "SHOW_SPINNER", value: false })
    }
    return () => {
      enableBodyScroll(document.body)
    }
  }, [isShowSplash, graphics, dispatch])

  if(!isShowSplash && !sendAnalyticsRef.current) {
    sendAnalyticsRef.current = true
    const isFirstOpenEventTriggered = getIsFirstOpenEventTriggered()
    if (!isFirstOpenEventTriggered) {
      sendAnalytics({
        type: 'first_open',
      })
      setIsFirstOpenEventTriggered(true)
    }
    if(userInfo && userInfo?.emailHash) {
      sendAnalytics({ type: 'app_launch', attr: {
        user_id: userInfo.userId,
        user_email_hash: userInfo.emailHash,
        user_type: userInfo.customerType,
        user_source: userInfo.source
      }})
    } else {
      sendAnalytics({ type: 'app_launch'})
    }
  }
  /* --------------------------------------------- */
  const configLoaded = utils.isNotEmptyObject(appMessages);
  if (!isShowSplash && configLoaded && !isInitialAppLoad) return null

  return (
    <div className='splash-wrapper'>
      <SplashBG className="splash-screen splash-open" graphics={graphics} />
      <div className='app-ver-code'>
        <Spinner size={45} IsLoading={!isShowSpinner} />
        {appMessages.label_settings_version ? appMessages?.label_settings_version : VERSION.VERSION_TEXT}{version.name}
      </div>
    </div>
  )
}
/* --------------------------------------------- */
const SplashBG = styled.div`
  ${({ graphics = [] }) => {
    graphics = graphics || []
    return Object.keys(device).map((resolution) => {
      let splashLogoBg = '';
      if (graphics.length === 0) {
        if (resolution.indexOf('mobile') !== -1) {
          splashLogoBg = SplashMobile
        } else {
          splashLogoBg = SplashDesktop
        }
      }
      (graphics || []).map(({ id, images }) => {
        if (id === 'app_splash') {
          if (resolution.indexOf('mobile') !== -1) {
            splashLogoBg = images.map(({ url, orientation }) => {
              if (orientation === 'portrait') {
                return utils.prependProtocol(url)
              }
              return utils.prependProtocol(url)
            }).filter(Boolean)[0] || ''
          } else {
            splashLogoBg = images.map(({ url, orientation }) => {
              if (orientation === 'landscape') {
                return utils.prependProtocol(url)
              }
              return utils.prependProtocol(url)
            }).filter(Boolean)[0] || ''
          }
        }
        return null
      })
      return `
        @media ${device[resolution]} {
          background-image: url('${splashLogoBg}');
          background-size: contain;
          background-repeat: no-repeat;
        }
      `
    })
  }}
`
/* --------------------------------------------- */
SplashPage.propTypes = {
  /** show/hide splash. */
  isShowSplash: PropTypes.bool,
}
/* --------------------------------------------- */
SplashPage.defaultProps = {
  isShowSplash: false
}
/* --------------------------------------------- */
export default SplashPage
/* --------------------------------------------- */
