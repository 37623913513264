import React, { useContext } from 'react'
import styled from "styled-components";
import utils from "../../../utils/utils";
import { Context } from "./context";
/* ---------------------------------------------------------------------------------------------------- */
export const Wrapper = (props) => {
  const { errorSnackbar } = useContext(Context)
  const { theme } = errorSnackbar
  /* -------------------------------------------------------------------------------------------------- */
  return <StyledWrapper theme={theme}>{props.children}</StyledWrapper>
};
/* ---------------------------------------------------------------------------------------------------- */
export const StyledWrapper = styled.div`
.notification {
    border-color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "toastTheme", "text", "secondary")) {
      return `${theme.compositeStyle.toastTheme.text.secondary}`;
    } else {
      return "transparent";
    }
  }};
    border-radius: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "toastTheme", "curvedEdges")) {
      if (theme.compositeStyle.toastTheme.curvedEdges) return "2px"
      else return "0px"
    } else {
      return "0px";
    }
  }};
  background-color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "toastTheme", "background", "primary")) {
      return theme.compositeStyle.toastTheme.background.primary;
    } else {
      return "transparent";
    }
  }};
  }

  .desc {
    color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "toastTheme", "text", "primary")) {
      return theme.compositeStyle.toastTheme.text.primary;
    } else {
      return "transparent";
    }
  }};
  }

  .snackbar___action {
    color: ${({ theme }) => {
    if (utils.isNotNull(theme, "compositeStyle", "toastTheme", "text", "secondary")) {
      return theme.compositeStyle.toastTheme.text.secondary;
    } else {
      return "transparent";
    }
  }};
  }
`;
/* ---------------------------------------------------------------------------------------------------- */
