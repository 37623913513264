//Ref - https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html

export const getDescriptionOfPlayerError = (error, appMessages) => {
    const shakaErrorLog = error ? JSON.stringify(error): ''
    const shakaErrorMoreInfo = appMessages.label_mux_error_more_info || 'Find more info from the shaka player error logs'
    switch(error?.code) {
        //Network related errors
        case 1000: return `${appMessages.label_mux_error_1000 || "A network request was made using an unsupported URI scheme."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 1001: return `${appMessages.label_mux_error_1001 || "A network request failure occured."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 1002: return `${appMessages.label_mux_error_1002 || "A network request failure occured."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 1003: return `${appMessages.label_mux_error_1003 || "A network request timed out."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 1004: return `${appMessages.label_mux_error_1004 || "A network request was made with a malformed data URI."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 1006: return `${appMessages.label_mux_error_1006 || "A request filter threw an error."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 1007: return `${appMessages.label_mux_error_1007 || "A response filter threw an error."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 1008: return `${appMessages.label_mux_error_1008 || "A network request was made with a malformed data URI."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 1009: return `${appMessages.label_mux_error_1009 || "A network request failure occured."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 1010: return `${appMessages.label_mux_error_1010 || "The number of retry attempts have run out"} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 1011: return `${appMessages.label_mux_error_1011 || "The segment is missing"}. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;

        //Text parsing errors
        case 2000: return `${appMessages.label_mux_error_2000 || "The text parser failed to parse a text stream due to an invalid header."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2001: return `${appMessages.label_mux_error_2001 || "The text parser failed to parse a text stream."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2003: return `${appMessages.label_mux_error_2003 || "Was unable to detect the encoding of the response text. Suggest adding byte-order-markings to the response data."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2004: return `${appMessages.label_mux_error_2004 || "The response data contains invalid Unicode character encoding."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2005: return `${appMessages.label_mux_error_2005 || "The XML parser failed to parse an xml stream"} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2007: return `${appMessages.label_mux_error_2007 || "MP4 segment does not contain TTML."}. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2008: return `${appMessages.label_mux_error_2008 || "MP4 segment does not contain VTT."}. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2009: return `${appMessages.label_mux_error_2009 || "An exception occured while parsing the media"} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2010: return `${appMessages.label_mux_error_2010 || "Error parsing the segment"} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2011: return `${appMessages.label_mux_error_2011 || "Unable to guess mime type of the text."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2012: return `${appMessages.label_mux_error_2012 || "External text tracks cannot be added in src because native platform doesn't support it."}. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2013: return `${appMessages.label_mux_error_2013 || "Only WebVTT is supported when using src"} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2014: return `${appMessages.label_mux_error_2014 || "The compilation does not contain a required text plugin for this operation"} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2015: return `${appMessages.label_mux_error_2015 || "The chapters track failed to load"}. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2016: return `${appMessages.label_mux_error_2016 || "External thumbnails tracks cannot be added in src= because native platform doesn't support it."}. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 2017: return `${appMessages.label_mux_error_2017 || "Only external urls of WebVTT type are supported."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;

        //Media errors
        case 3000: return `${appMessages.label_mux_error_3000 || "Some component tried to read past the end of a buffer. The segment index, init segment, or PSSH may be malformed."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3001: return `${appMessages.label_mux_error_3001 || "Some component tried to parse an integer that was too large to fit in a JavaScript number without rounding error."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3002: return `${appMessages.label_mux_error_3002 || "The EBML parser used to parse the WebM container encountered an integer, ID, or other field larger than the maximum supported by the parser."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3003: return `${appMessages.label_mux_error_3003 || "The EBML parser used to parse the WebM container encountered a floating- point field of a size not supported by the parser."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3004: return `${appMessages.label_mux_error_3004 || "Either the segment index range is incorrect or the data is corrupt."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3005: return `${appMessages.label_mux_error_3005 || "The segment index data may be corrupt."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3006: return `${appMessages.label_mux_error_3006 || "The MP4 SIDX parser encountered a type of SIDX that is not supported."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3007: return `${appMessages.label_mux_error_3007 || "The segment index data may be corrupt."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3008: return `${appMessages.label_mux_error_3008 || "The init segment data may be corrupt."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3009: return `${appMessages.label_mux_error_3009 || "The init segment data may be corrupt."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3010: return `${appMessages.label_mux_error_3010 || "The init segment data may be corrupt."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3011: return `${appMessages.label_mux_error_3011 || "The init segment data may be corrupt or may have been incorrectly encoded"} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3012: return `${appMessages.label_mux_error_3012 || "The segment index data may be corrupt."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3013: return `${appMessages.label_mux_error_3013 || "The segment index data may be corrupt."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3014: return `${appMessages.label_mux_error_3014 || "A MediaSource operation failed."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3015: return `${appMessages.label_mux_error_3015 || "A MediaSource operation threw an exception."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3016: return `${appMessages.label_mux_error_3016 || "The video element reported an error."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3017: return `${appMessages.label_mux_error_3017 || "A MediaSource operation threw QuotaExceededError and recovery failed."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3018: return `${appMessages.label_mux_error_3018 || "Mux.js did not invoke the callback signifying successful transmuxing."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 3019: return `${appMessages.label_mux_error_3019 || "Content transformations required by the platform could not be performed for some reason (unsupported container, etc.)"} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;

        //Manifest errors
        case 4000: return `${appMessages.label_mux_error_4000 || "The Player was unable to guess the manifest type based on file extension or MIME type"} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4001: return `${appMessages.label_mux_error_4001 || "The DASH Manifest contained invalid XML markup."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4002: return `${appMessages.label_mux_error_4002 || "The DASH Manifest contained a Representation with insufficient segment information."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4003: return `${appMessages.label_mux_error_4003 || "The DASH Manifest contained an AdaptationSet with no Representations."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4004: return `${appMessages.label_mux_error_4004 || "The DASH Manifest contained an Period with no AdaptationSets."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4005: return `${appMessages.label_mux_error_4005 || "The DASH Manifest does not specify an init segment with a WebM container."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4006: return `${appMessages.label_mux_error_4006 || "The DASH Manifest contained an unsupported container format."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4007: return `${appMessages.label_mux_error_4007 || "The embedded PSSH data has invalid encoding."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4008: return `${appMessages.label_mux_error_4008 || "There is an AdaptationSet whose Representations do not have any common key-systems."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4009: return `${appMessages.label_mux_error_4009 || "Having multiple key IDs per Representation is not supported."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4010: return `${appMessages.label_mux_error_4010 || "The DASH Manifest specifies conflicting key IDs."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4012: return `${appMessages.label_mux_error_4012 || "There exist some streams that could be decoded, but restrictions imposed by the application or the key system prevent us from playing."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4015: return `${appMessages.label_mux_error_4015 || "HLS playlist doesn't start with a mandory #EXTM3U tag."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4016: return `${appMessages.label_mux_error_4016 || "HLS tag has an invalid name that doesn't start with '#EXT'"} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4017: return `${appMessages.label_mux_error_4017 || "HLS playlist has both Master and Media/Segment tags."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4018: return `${appMessages.label_mux_error_4018 || "A Representation has an id that is the same as another Representation in the same Period. This makes manifest updates impossible since we cannot map the updated Representation to the old one."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4020: return `${appMessages.label_mux_error_4020 || "HLS manifest has several #EXT-X-MAP tags. We can only support one at the moment."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4023: return `${appMessages.label_mux_error_4023 || "One of the required attributes was not provided, so the HLS manifest is invalid."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4024: return `${appMessages.label_mux_error_4024 || "One of the required tags was not provided, so the HLS manifest is invalid."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4025: return `${appMessages.label_mux_error_4025 || "The HLS parser was unable to guess codecs of a stream."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4026: return `${appMessages.label_mux_error_4026 || "The HLS parser has encountered encrypted content with unsupported KEYFORMAT attributes."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4027: return `${appMessages.label_mux_error_4027 || "The manifest parser only supports xlink links with xlink:actuate='onLoad'."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4028: return `${appMessages.label_mux_error_4028 || "The manifest parser has hit its depth limit on xlink link chains."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4032: return `${appMessages.label_mux_error_4032 || "The content container or codecs are not supported by this browser."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4033: return `${appMessages.label_mux_error_4033 || "External text tracks cannot be added to live streams."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4036: return `${appMessages.label_mux_error_4036 || "The Manifest contained no Variants."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4037: return `${appMessages.label_mux_error_4037 || "We failed to find matching streams across DASH Periods, and the period-flattening aglorithm introduced in v3.0 has failed."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4038: return `${appMessages.label_mux_error_4038 || "We failed to find matching streams across DASH Periods due to inconsistent DRM systems across periods."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4039: return `${appMessages.label_mux_error_4039 || "The HLS manifest refers to an undeclared variables."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4040: return `${appMessages.label_mux_error_4040 || "We do not support playing encrypted mp2t with MSE"} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4041: return `${appMessages.label_mux_error_4041 || "We do not support playing encrypted content (different than mp2t) with MSE and legacy Apple MediaKeys API."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4042: return `${appMessages.label_mux_error_4042 || "Web Crypto API is not available (to decrypt AES-128 streams). Web Crypto only exists in secure origins like https."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4043: return `${appMessages.label_mux_error_4043 || "AES-128 iv length should be 16 bytes."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4044: return `${appMessages.label_mux_error_4044 || "AES-128 encryption key length should be 16 bytes."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 4045: return `${appMessages.label_mux_error_4045 || "External thumbnails tracks cannot be added to live streams."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;

        //streaming error
        case 5006: return `${appMessages.label_mux_error_5006 || "This would only happen if StreamingEngine were not started correctly, and should not be seen in production."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;

        //DRM related error
        case 6000: return `${appMessages.label_mux_error_6000 || "The manifest indicated protected content, but the manifest parser was unable to determine what key systems should be used."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6001: return `${appMessages.label_mux_error_6001 || "None of the requested key system configurations are available."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6002: return `${appMessages.label_mux_error_6002 || "The browser found one of the requested key systems, but it failed to create an instance of the CDM for some unknown reason."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6003: return `${appMessages.label_mux_error_6003 || "The browser found one of the requested key systems and created an instance of the CDM, but it failed to attach the CDM to the video for some unknown reason."} ${error?.data?.[0] && '- ' + error?.data?.[0] }. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6004: return `${appMessages.label_mux_error_6004 || "The CDM rejected the server certificate supplied by the application. The certificate may be malformed or in an unsupported format."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6005: return `${appMessages.label_mux_error_6005 || "The CDM refused to create a session for some unknown reason."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6006: return `${appMessages.label_mux_error_6006 || "The CDM was unable to generate a license request for the init data it was given. The init data may be malformed or in an unsupported format."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6007: return `${appMessages.label_mux_error_6007 || "The license request failed. This could be a timeout, a network failure, or a rejection by the server."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6008: return `${appMessages.label_mux_error_6008 || "The license response was rejected by the CDM. The server's response may be invalid or malformed for this CDM."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6010: return `${appMessages.label_mux_error_6010 || "The manifest does not specify any DRM info, but the content is encrypted. Either the manifest or the manifest parser are broken."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6012: return `${appMessages.label_mux_error_6012 || "No license server was given for the key system signaled by the manifest. A license server URI is required for every key system."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6013: return `${appMessages.label_mux_error_6013 || "A required offline session was removed. The content is not playable."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6014: return `${appMessages.label_mux_error_6014 || "The license has expired. This is triggered when all keys in the key status map have a status of 'expired'."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6015: return `${appMessages.label_mux_error_6015 || "A server certificate wasn't given when it is required. FairPlay requires setting an explicit server certificate in the configuration."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6016: return `${appMessages.label_mux_error_6016 || "An error was thrown while executing the init data transformation."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 6017: return `${appMessages.label_mux_error_6017 || "The server certificate request failed."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;

        //Player related errors
        case 7000: return `${appMessages.label_mux_error_7000 || "The call to Player.load() was interrupted by a call to Player.unload() or another call to Player.load()."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 7001: return `${appMessages.label_mux_error_7001 || "An internal error which indicates that an operation was aborted."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 7002: return `${appMessages.label_mux_error_7002 || "The call to Player.load() failed because the Player does not have a video element."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 7003: return `${appMessages.label_mux_error_7003 || "The operation failed because the object has been destroyed."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 7004: return `${appMessages.label_mux_error_7004 || "The content has not been loaded in the Player."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
       
        //Cast related errors
        case 8000: return `${appMessages.label_mux_error_8000 || "The Cast API is unavailable."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 8001: return `${appMessages.label_mux_error_8001 || "No cast receivers are available at this time."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 8002: return `${appMessages.label_mux_error_8002 || "The library is already casting."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 8003: return `${appMessages.label_mux_error_8003 || "A Cast SDK error that we did not explicitly plan for has occurred."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 8004: return `${appMessages.label_mux_error_8004 || "The cast operation was canceled by the user."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 8005: return `${appMessages.label_mux_error_8005 || "The cast connection timed out."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 8006: return `${appMessages.label_mux_error_8006 || "The requested receiver app ID does not exist or is unavailable. Check the requested app ID for typos."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;

        //Storage related error
        case 9000: return `${appMessages.label_mux_error_9000 || "Offline storage is not supported on this browser; it is required for offline support."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9001: return `${appMessages.label_mux_error_9001 || "An unknown error occurred in the IndexedDB."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9002: return `${appMessages.label_mux_error_9002 || "The storage operation was aborted. Deprecated in favor of more general OPERATION_ABORTED."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9003: return `${appMessages.label_mux_error_9003 || "The specified item was not found in the IndexedDB."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9004: return `${appMessages.label_mux_error_9004 || "A network request was made with a malformed offline URI."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9005: return `${appMessages.label_mux_error_9005 || "The specified content is live or in-progress. Live and in-progress streams cannot be stored offline."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9007: return `${appMessages.label_mux_error_9007 || "There was no init data available for offline storage. This happens when there is no init data in the manifest nor could we find any in the segments. We currently only support searching MP4 init segments for init data."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9008: return `${appMessages.label_mux_error_9008 || "shaka.offline.Storage was constructed with a Player proxy instead of a local player instance. To fix this, use Player directly with Storage instead of the results of CastProxy.prototype.getPlayer()."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9011: return `${appMessages.label_mux_error_9011 || "The storage cell does not allow new operations that require new keys."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9012: return `${appMessages.label_mux_error_9012 || "A key was not found in a storage cell."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9013: return `${appMessages.label_mux_error_9013 || "A storage cell was not found."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9014: return `${appMessages.label_mux_error_9014 || "The storage limit defined in downloadSizeCallback has been reached."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9015: return `${appMessages.label_mux_error_9015 || "downloadSizeCallback has produced an unexpected error."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9016: return `${appMessages.label_mux_error_9016 || "The storage cell does not allow new operations that significantly change existing data."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 9017: return `${appMessages.label_mux_error_9017 || "When attempting to open an indexedDB instance, nothing happened for long enough for us to time out. This keeps the storage mechanism from hanging indefinitely, if neither the success nor error callbacks are called."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;

        //ADS related error
        case 10000: return `${appMessages.label_mux_error_10000 || "CS IMA SDK, required for ad insertion, has not been included on the page."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 10001: return `${appMessages.label_mux_error_10001 || "Client Side Ad Manager needs to be initialized to enable Client Side Ad Insertion. Call adManager.initClientSide() to do it."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 10002: return `${appMessages.label_mux_error_10002 || "SS IMA SDK, required for ad insertion, has not been included on the page."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 10003: return `${appMessages.label_mux_error_10003 || "Server Side Ad Manager needs to be initialized to enable Server Side Ad Insertion."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
        case 10004: return `${appMessages.label_mux_error_10004 || "A new DAI steam was requested before the previous request had been resolved. Only one stream request at a time is supported."} ${shakaErrorMoreInfo} - ${shakaErrorLog}`;

        default: return `${appMessages.label_mux_error_unknown || "An unknown error occured"}. ${shakaErrorMoreInfo} - ${shakaErrorLog}`;
    }
}

export const getErrorTitle = (error, appMessages) => {
    const code = error?.code;
    switch(error?.category) {
        case 1: return `${code} - ${appMessages.label_mux_error_network || "Network error"}`;
        case 2: return `${code} - ${appMessages.label_mux_error_text|| "Text stream parsing Error"}`;
        case 3: return `${code} - ${appMessages.label_mux_error_video|| "Error parsing audio/video streams"}`;
        case 4: return `${code} - ${appMessages.label_mux_error_manifest || "Error parsing manifest"}`;
        case 5: return `${code} - ${appMessages.label_mux_error_streaming || "Error related to streaming"}`;
        case 6: return `${code} - ${appMessages.label_mux_error_drm || "Error related to DRM"}`;
        case 7: return `${code} - ${appMessages.label_mux_error_player || "Miscellaneous errors from the player"}`;
        case 8: return `${code} - ${appMessages.label_mux_error_cast || "Errors related to cast"}`;
        case 9: return `${code} - ${appMessages.label_mux_error_storage || "Errors in the database storage (offline)"}`;
        case 10: return `${code} - ${appMessages.label_mux_error_ads || "Errors related to ad insertion"}`;
        default: return code ? `${code} - ${appMessages.label_mux_error_unknown || "Unknown Error"}` : (appMessages.label_mux_error_unknown || "Unknown Error");
    }
}