import utils from '../../../utils/utils'

// Helper functions to create ppup for login
export const createPopup = (url, w, h) => {
    const left = (window.screen.width / 2) - (w / 2)
    const top = (window.screen.height / 2) - (h / 2)
    const newWindow = window.open('', '_blank', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)
    if (newWindow) {
        newWindow.location.href = url
        newWindow.focus()
    }
    return newWindow
}

// Helper functions to create popup for login
export function openAndListenNewWin(url, {
    maxTime = null,
    onProgress = () => { },
    onBlock = () => { },
    onFailure = () => { },
    onSuccess = () => { },
}, options = {}) {
    const { width = 500, height = 500 } = options
    onProgress()
    let newWindow = createPopup(url, width, height)
    let isSuccess = false
    let time = 0
    const intervalTime = 5
    const interval = setInterval(() => {
        time = time + intervalTime
        if (!newWindow) {
            if (window.authWindow) {
                newWindow = window.authWindow
                onProgress()
            } else {
                onBlock()
                return
            }
        }
        if (maxTime !== null && time > maxTime) { // on timeout close the window.
            // console.log('TIMEOUT: Login popup closed.')
            if (newWindow) newWindow.close()
            clearInterval(interval)
            if (!isSuccess) onFailure()
            return null
        }
        if (newWindow.closed) { // if window closed by the user manually before authentication.
            clearInterval(interval)
            if (!isSuccess) onFailure()
            return null
        }
        try {
            let currentURL = newWindow.location.href
            if (currentURL[currentURL.length - 1] === '?') currentURL = currentURL.slice(0, currentURL.length - 1)
            if (url !== currentURL && utils.getLocation(currentURL).hostname === window.location.hostname) {
                newWindow.close()
                isSuccess = true
                onSuccess(currentURL)
            }
        } catch (error) { }
    }, intervalTime)
    return { newWindow, interval }
}