import {
  APP_THEME_CONFIG,
  CONTENTFUL_DEFAULT_PAGE_THEME_RES,
  CONTENTFUL_DEFAULT_COMPONENT_THEME_RES,
  CONTENTFUL_DEFAULT_POPUP_THEME_RES,
  CONTENTFUL_FEATURES_RES, CONTENTFUL_NAVIGATION_RES,
  MAX_VISIBLE_MENUITEM,
  COMPONENT_THEME_CONFIG, POPUP_THEME_CONFIG,
  META_THEME_CONFIG, FOOTER_LIST, SPECIAL_LIST,
  SUPPORT_PAGE_THEME_CONFIG, PLAYER_THEME_CONFIG, INTRO_THEME_CONFIG, INTRO_THEME_HEADER_CONFIG
} from '../constants/actionTypes'
import inialState from './initalState'
/* --------------------------------------------- */
/** Theme config reducer */
const themeConfigReducers = (state = inialState, action) => {
  switch (action.type) {
    case APP_THEME_CONFIG:
      return {
        ...state,
        appTheme: action.value
      }
    /* --------------------------------------------- */
    case CONTENTFUL_DEFAULT_PAGE_THEME_RES:
      return {
        ...state,
        contentfulDefaultPageThemeRes: action.value,
        isAppTheme: true
      }
    case CONTENTFUL_DEFAULT_COMPONENT_THEME_RES:
      return {
        ...state,
        contentfulDefaultComponentThemeRes: action.value
      }
    case CONTENTFUL_DEFAULT_POPUP_THEME_RES:
      return {
        ...state,
        contentfulDefaultPopupThemeRes: action.value
      }
    case CONTENTFUL_FEATURES_RES:
      return {
        ...state,
        contentfulFeaturesRes: action.value
      }
    /* --------------------------------------------- */
    case COMPONENT_THEME_CONFIG:
      return {
        ...state,
        componentTheme: action.value
      }
    case POPUP_THEME_CONFIG:
      return {
        ...state,
        popupTheme: action.value
      }
    case SUPPORT_PAGE_THEME_CONFIG:
      return {
        ...state,
        supportPageTheme: action.value
      }
    case PLAYER_THEME_CONFIG:
      return {
        ...state,
        playerTheme: action.value
      }
    case META_THEME_CONFIG:
      return {
        ...state,
        metaTheme: action.value
      }
    case INTRO_THEME_CONFIG:
      return {
        ...state,
        [INTRO_THEME_CONFIG]: action.value
      }
      case INTRO_THEME_HEADER_CONFIG:
      return {
        ...state,
        [INTRO_THEME_HEADER_CONFIG]: action.value
      }  
    case CONTENTFUL_NAVIGATION_RES:
      return {
        ...state,
        isMenuList: true,
        contentfulNavigationRes: action.value
      }
    /* --------------------------------------------- */
    case MAX_VISIBLE_MENUITEM:
      return {
        ...state,
        maxVisisbleMenuItems: action.value
      }
    case FOOTER_LIST:
      return {
        ...state,
        footerList: action.value
      }
    case SPECIAL_LIST:
      return {
        ...state,
        specialList: action.value
      }
    default: return state
  }
}
/* --------------------------------------------- */
export default themeConfigReducers
/* --------------------------------------------- */
