import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const EditCircleIcon = ({ pencilColor, backgroundColor, ...rest }) => (
<svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12.5" cy="12.5" r="12.5" fill={backgroundColor}/>
    <path fill={pencilColor} fillRule="evenodd" clipRule="evenodd" d="M12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25ZM17.612 10.5562C17.494 10.5562 17.3814 10.5092 17.2984 10.4263L14.5747 7.7026C14.4013 7.52918 14.4013 7.24887 14.5747 7.07545L15.9035 5.74621C16.3471 5.30313 17.1192 5.31067 17.5548 5.74577L19.2544 7.4458C19.7099 7.90085 19.7099 8.64154 19.2544 9.09704L17.9256 10.4263C17.8422 10.5092 17.7295 10.5562 17.612 10.5562ZM8.57122 19.5833H5.84754C5.60271 19.5833 5.40402 19.3851 5.40402 19.1398V16.4161C5.40402 16.2986 5.45059 16.1859 5.53397 16.1026L13.5666 8.07032C13.7396 7.8969 14.0204 7.8969 14.1938 8.07032L16.917 10.794C17.0004 10.8769 17.047 10.9896 17.047 11.1076C17.047 11.2251 17.0004 11.3378 16.917 11.4212L8.8848 19.4534C8.80141 19.5368 8.68876 19.5833 8.57122 19.5833Z"/>
</svg>
)
/* --------------------------------------------- */
EditCircleIcon.propTypes = {
    /** Color of icon. */
    color: PropTypes.string,
    /** Icon css styles. */
    style: PropTypes.object
}
/* --------------------------------------------- */
EditCircleIcon.defaultProps = {
    color: 'transparent',

}
/* --------------------------------------------- */
export default EditCircleIcon
/* --------------------------------------------- */
