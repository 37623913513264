import React,{ useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
import utils from '../../utils/utils'
import '../../styles/pages/ContactUS.scss'
import { version } from '../../config'
import { getDecodedBase64ForContactDetails } from '../../utils/APIUtilFunctions'
/* --------------------------------------------- */
// ContactUS component having logic for showing contact us page.
const ContactUsFeebackForm = () => {
  const { supportPageTheme } = useSelector(store => store.themeConfigReducers)
  const [fullName, updateFullName] = useState('')
  const [email, updateEmail] = useState('')
  const [userId, updateUserId] = useState('')
  const [session_Id, updateSessionId] = useState('')
  /* --------------------------------------------- */
  const theme = supportPageTheme || {}
  const searchString = `${window.location.search}`;
  const str = searchString.split("?")
  const queryString = str[1];
  const { graphics } = theme
  let imageURL = ''
  graphics && graphics.map(({ id, images }) => {
    if (id === 'app_popup_logo') {
      imageURL = utils.prependProtocol(images[0].url)
      return null
    }
    return null
  })
  /* --------------------------------------------- */
  const onCloseWindow = () => {
    // Listen from the outermost div
		const targetNode = document.getElementById('freshworks-container');
		// Options for the observer (which mutations to observe)
		const config = { attributes: false, childList: true, subtree: true };

		// Callback function to execute when a change in the div tree is observed
		const callback = function(mutationList, observer) {
			//Loop through all mutations
			for(const mutation of mutationList) {
				//A change in the childList has happened
				if (mutation.type === 'childList') {
					if(document.getElementById("freshworks-frame-wrapper") == null)
					{
							//Close window here
              window.close()
					}
				}
			}
		};
    try {
		// Creating the observer instance linked to the callback function
		const observer = new MutationObserver(callback);
		// Start observing the target node for configured mutations
		observer.observe(targetNode, config);
    } catch {}
  }
  /* --------------------------------------------- */
  useEffect(() => {
      if(queryString) {
        const contactDetails = JSON.parse(getDecodedBase64ForContactDetails(queryString))
        updateFullName(contactDetails.userName)
        updateEmail(contactDetails.email)
        updateUserId(contactDetails.userID)
        updateSessionId(contactDetails.sessionID);
      }    
    /* --------------------------------------------- */
  }, [updateFullName, updateEmail, updateUserId, queryString])
  
  /* --------------------------------------------- */

  useEffect(() => {
    window.FreshworksWidget('identify', 'ticketForm', {
      name: fullName ? fullName : "",
      email: email ? email : "",
      custom_fields: {
        cf_sessionid: session_Id,
        cf_appversion:  version.number,
        cf_userid:  userId ? userId : "",
      },
    });
    window.FreshworksWidget('hide', 'launcher');
    window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_sessionid']);
    window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_appversion']);
    window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_userid']);
    window.FreshworksWidget('open');
    setInterval(() => {
      onCloseWindow();
    }, 1000);
    
  },[email, fullName, session_Id, userId])
  /* --------------------------------------------- */
  return (
    <div className='feedback-form'>
        {imageURL? <img alt='title__logo' className='title__logo' src={imageURL} /> : ""}
    </div>
  )
}
/* --------------------------------------------- */

export default ContactUsFeebackForm
/* --------------------------------------------- */
