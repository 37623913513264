export const DETAILS_LAYOUT = {
    FULL_WIDTH: 'full-width',
    LANSCAPE_BOXED: 'landscape-boxed',
    PORTRAIT_BOXED: 'portrait-boxed',
    ALT_ORIGINAL: 'alt-original'
}

export const ASPECT_RATIO = {
    TWO_BY_THREE: 0.667,
    SIXTEEN_BY_NINE: 1.778,
}