import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const RewindIconFilled = ({ color, text, ...rest }) => (
  <svg viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g>
      <path 
      fill={color} d="M26.1377 16.9305C26.1378 18.74 25.7621 20.5298 25.0344 22.1866C24.3068 23.8433 23.243 25.3309 21.9104 26.5551C20.5779 27.7793 19.0057 28.7135 17.2933 29.2984C15.5809 29.8833 13.7657 30.1063 11.9627 29.9531C10.1597 29.7999 8.40808 29.274 6.8189 28.4086C5.22972 27.5433 3.83757 26.3573 2.73061 24.9259C1.62366 23.4944 0.826028 21.8487 0.388242 20.093C-0.0495433 18.3372 -0.117944 16.5096 0.187373 14.7261C0.203019 14.5994 0.244165 14.4772 0.308327 14.3669C0.372488 14.2566 0.458336 14.1604 0.560688 14.0842C0.663041 14.008 0.779777 13.9533 0.903851 13.9234C1.02792 13.8935 1.15676 13.8891 1.2826 13.9104C1.40843 13.9317 1.52864 13.9783 1.63598 14.0473C1.74332 14.1163 1.83556 14.2064 1.90714 14.312C1.97871 14.4177 2.02814 14.5367 2.05244 14.662C2.07673 14.7873 2.0754 14.9162 2.0485 15.041C1.67897 17.2033 1.95374 19.4265 2.83861 21.4338C3.72348 23.441 5.17933 25.1436 7.02484 26.3294C8.87034 27.5152 11.0239 28.1319 13.2174 28.1026C15.4108 28.0733 17.5472 27.3994 19.3604 26.1647C21.1736 24.93 22.5834 23.1892 23.4144 21.159C24.2453 19.1288 24.4607 16.8991 24.0335 14.7474C23.6064 12.5958 22.5556 10.6173 21.0122 9.05851C19.4688 7.4997 17.5009 6.42941 15.3535 5.98096V8.27982C15.3549 8.51381 15.2941 8.74397 15.1774 8.94675C15.0606 9.14954 14.8921 9.31768 14.6891 9.43397C14.4868 9.55151 14.257 9.61342 14.023 9.61342C13.7891 9.61342 13.5593 9.55151 13.357 9.43397L7.33903 5.95892C7.13664 5.84197 6.96859 5.67383 6.85176 5.47138C6.73492 5.26893 6.67342 5.0393 6.67342 4.80555C6.67342 4.57181 6.73492 4.34218 6.85176 4.13973C6.96859 3.93728 7.13664 3.76914 7.33903 3.65219L13.357 0.178709C13.5593 0.0617225 13.7889 8.55039e-05 14.0225 8.88953e-08C14.2562 -8.53261e-05 14.4858 0.0613839 14.6882 0.178222C14.8906 0.29506 15.0587 0.463146 15.1755 0.665564C15.2923 0.867982 15.3537 1.09759 15.3535 1.33129V4.06157C18.3754 4.60031 21.1115 6.18464 23.083 8.53728C25.0545 10.8899 26.1358 13.861 26.1377 16.9305Z" 
      />
      <text x="28%" y="67%" fontSize="10" fill={color}>{text} </text>  
    </g>
  </svg>
)
/* --------------------------------------------- */
RewindIconFilled.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Text inside icon. */
  text: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
RewindIconFilled.defaultProps = {
  color: 'transparent',
  text: 25,
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default RewindIconFilled
/* --------------------------------------------- */
