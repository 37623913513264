import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const NextBtnOutlineIcon = ({ color, next, ...rest }) => (
  <svg viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path 
    fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M16.9141 15.0003L2.21875 27.079V2.92153L16.9141 15.0003ZM0.880938 29.9103C1.00844 29.9712 1.14531 30.0003 1.28125 30.0003C1.49406 30.0003 1.70594 29.9281 1.87656 29.7865L18.9859 15.724C19.2025 15.5468 19.3281 15.2806 19.3281 15.0003C19.3281 14.72 19.2025 14.4537 18.9859 14.2756L1.87656 0.213097C1.59625 -0.0175276 1.20812 -0.0653401 0.880938 0.0893474C0.552813 0.244972 0.34375 0.574972 0.34375 0.937785V29.0628C0.34375 29.4256 0.552813 29.7556 0.880938 29.9103ZM23.0781 29.0626C23.0781 29.581 23.4981 30.0001 24.0156 30.0001C24.5331 30.0001 24.9531 29.581 24.9531 29.0626V0.937597C24.9531 0.41916 24.5331 9.73865e-05 24.0156 9.73865e-05C23.4981 9.73865e-05 23.0781 0.41916 23.0781 0.937597V29.0626Z" 
    />
  </svg>
)
/* --------------------------------------------- */
NextBtnOutlineIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
NextBtnOutlineIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default NextBtnOutlineIcon
/* --------------------------------------------- */
