import React from 'react'
import PropTypes from 'prop-types'
/* ---------------------------------------------------------------------------------------------------- */
// Simple button component
const Button = ({ onClick, text, size }) => {
    return (
        <div className={`button-wrapper ${size === 'small' ? 'button-sm' : size === 'medium' ? 'buttom-md' : ''}`}>
            <button className='button-wrapper__btn' onClick={onClick}>{text}</button>
        </div>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
Button.propTypes = {
    /** Handler function for onclick event. */
    onClick: PropTypes.func,
    /** Button Text */
    text: PropTypes.string,
    /** Button Size */
    size: PropTypes.string
}
/* ---------------------------------------------------------------------------------------------------- */
Button.defaultProps = {
    onClick: () => { },
    text: '',
    size: ''
}
/* ---------------------------------------------------------------------------------------------------- */
export default Button
/* ---------------------------------------------------------------------------------------------------- */
