import utils from '../../../utils/utils'

export const setAuthorizeUrl = (authConfig, uuid) => {
    const authorizeUrl = authConfig.authorizeUrl
    const authClientId = authConfig.authClientId
    let redirectUri = authConfig.redirectUri
    /* --------------------------------------------- */
    if (!authorizeUrl || !authClientId || !redirectUri) return
    /* --------------------------------------------- */
    const _uuid = uuid || (utils.parse(utils.parseUrl(window.location).search) || {}).state
    const parsedURL = utils.parseUrl(authorizeUrl)
    let queryParams = utils.parse(authorizeUrl) || {}
    queryParams.state = _uuid
    queryParams = utils.stringify(queryParams)
    const _authorizeUrl = `${parsedURL.protocol}//${parsedURL?.hostname}${parsedURL?.pathname}?${queryParams}`
    if (window.location.hostname === 'localhost') redirectUri = `${window.location.origin}/callback`
    if (window.opener) window.opener.authWindow = window
    return `${_authorizeUrl}&client_id=${authClientId}&redirect_uri=${redirectUri}` // redirecting to external login page
}


