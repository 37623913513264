import React, { useEffect, useState, useRef, useContext } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
/* --------------------------------------------- */
import { routeMap } from '../../route'
/* --------------------------------------------- */
import Spinner from '../Spinner'
import TextBox from '../form/TextBox'
import Button from '../form/Button'
import SocialLogin from '../SocialLogin'
import useErrorUtils from '../../utils/useErrorUtils'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
/* --------------------------------------------- */
import useAuthApi from '../../apis/useAuthApi'
import utils from '../../utils/utils'
import { setTVLoginRedirect, getTVLoginRedirect } from '../../utils/localStorageService'
import {
  isTablet,
  isMobile,
} from 'react-device-detect'

/* --------------------------------------------- */
// LoginPage component having logic for login page.
const LoginPage = ({ history }) => {
  const [theme, updateTheme] = useState()
  const [isLoading, updateIsLoading] = useState(true)
  const [emailText, updateEmailText] = useState('')
  const [passwordText, updatePasswordText] = useState('')
  const [isSubmitBtn, updateIsSubmitBtn] = useState(false)
  const [emailPlaceholder, updateEmailPlaceholder] = useState('')
  const [passwordPlaceholder, updatePasswordPlaceholder] = useState('')
  const [inValidFormItem, updateInValidFormItem] = useState(false)
  const [baseUrl, updateBaseUrl] = useState(null)
  const [authConfig, setAuthConfig] = useState({})
  const [inputTheme, updateInputTheme] = useState({})
  /* --------------------------------------------- */
  const [isShowLoginForm, showLoginForm] = useState(false)
  const [isShowSocialLogin, showSocialLogin] = useState(false)
  /* --------------------------------------------- */
  const { userManagementConfig = {}, userManagementThemes = {} } = useSelector(store => store.userManagement)
  const { profileManagementConfig = {} } = useSelector(store => store.profileManagement)
  const { appMessages } = useSelector(store => store.appConfigReducers)
  const { appConfig } = useSelector(store => store.appConfigReducers)
  const { secondaryMenuConfig = {} } = useSelector(store => store.navigation)
  const { menuItems = [] } = secondaryMenuConfig
  /* --------------------------------------------- */
  const { loginMethods = [], authConfig: userAuthConfig = [] } = userManagementConfig
  /* --------------------------------------------- */
  const { isAuthenticated, dispatch, previousURL } = useContext(GlobalContext)
  const { login } = useAuthApi()
  /* --------------------------------------------- */
  const emailInput = useRef(null)
  const passwordInput = useRef(null)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  /* --------------------------------------------- */
  const { showError } = useErrorUtils()
  /* --------------------------------------------- */
  /**
   * It: Sets page title.
   * It: Show header back button.
   */
  // checking myaccount indentifier for all the inner pages, also login and sign up pages
  useEffect(() => {
    const loginPage = menuItems?.length ? menuItems?.filter((value) => {
      return value.identifierExt === 'myaccount'
    }) : []
    if (loginPage && loginPage[0]?.navigation && (loginPage[0]?.navigation.length > 0)) {
      dispatch({ type: 'SHOW_HEADER_BACK_BTN' })
      dispatch({ type: 'PAGE_TITLE', value: 'Login' })
    } else {
      dispatch({ type: 'HIDE_HEADER_BACK_BTN' })
      dispatch({ type: 'PAGE_TITLE', value: '' })
    }
  }, [dispatch, menuItems])
  /* --------------------------------------------- */
  /**
   * It: Shows spinner.
   */
  useEffect(() => {
    let isMounted = true
    const timer = setTimeout(() => {
      if (!isMounted) return
      updateIsLoading(false)
      dispatch({ type: 'SHOW_SPLASH', value: false })
    }, 500)

    // clearing the timer and making sure that state will not changed when component is unmounting
    return () => {
      isMounted = false
      clearTimeout(timer)
    }
  }, [dispatch])
  /* --------------------------------------------- */
  /**
   * It: Sets authConfig.
   */
  useEffect(() => {
    const authConfig = userAuthConfig.filter((config) => config.identityProvider === 'cognito' || config.identityProvider === 'mpx')
    setAuthConfig(authConfig[0] || {})
    dispatch({ type: 'USER_NAVIGATION_FROM_LOGIN', value: true })
  }, [
    userAuthConfig,
    dispatch
  ])
  /* --------------------------------------------- */
  /**
   * It: Redirects to home page when unauthenticated.
   */
  useEffect(() => {
    if (isAuthenticated) {
      if (getTVLoginRedirect()) {
        history.push(routeMap.tvLogin)
        setTimeout(() => {
          setTVLoginRedirect(false)
        }, 1000)
      } else if (previousURL) {
        history.push(previousURL)
        dispatch({ type: 'SET_PREVIOUS_URL', value: null })
      } else {
        history.push('/')
      }
    }
  }, [
    history,
    isAuthenticated,
    previousURL,
    dispatch
  ])
  /* --------------------------------------------- */
  useEffect(() => {
    if (isShowLoginForm) {
      emailInput.current.classList.add('validation-Error')
      passwordInput.current.classList.add('validation-Error')
    }
  }, [isShowLoginForm])
  /* --------------------------------------------- */
  /**
   * It: Updates state to show/hide login buttons.
   */
  useEffect(() => {
    if (loginMethods.indexOf('cognito') > -1) showLoginForm(loginMethods.indexOf('cognito') > -1)
    if (loginMethods.indexOf('mpx') > -1) showLoginForm(loginMethods.indexOf('mpx') > -1)
    showSocialLogin(
      loginMethods.indexOf('facebook') > -1 ||
      loginMethods.indexOf('google') > -1 ||
      loginMethods.indexOf('apple') > -1
    )
  }, [loginMethods])
  /* --------------------------------------------- */
  /**
   * It: Sets baseUrl in a state.
   */
  useEffect(() => {
    if (!appConfig.baseUrl) return
    updateBaseUrl(appConfig.baseUrl)
  }, [appConfig])
  /* --------------------------------------------- */
  /**
   * It: Sets placeholder value in a state
   */
  useEffect(() => {
    updateEmailPlaceholder(appMessages.label_sign_up_email)
    updatePasswordPlaceholder(appMessages.label_sign_up_password)
  }, [appMessages])
  /* --------------------------------------------- */
  /**
   * It: Updates theme.
   */
  useEffect(() => {
    updateTheme(userManagementThemes.loginTheme)
  }, [userManagementThemes.loginTheme])
  /* --------------------------------------------- */
  /**
   * It: Updates input theme.
   */
  useEffect(() => {
    if (utils.isNotNull(theme, 'compositeStyle', 'inputBox')) {
      updateInputTheme(theme.compositeStyle.inputBox || {})
    }
  }, [theme])
  /* --------------------------------------------- */
  /**
   * When: Should be called on email change.
   * It: Enable/disable submit button.
   */
  const emailHandler = (data) => {
    updateEmailText(data)
    if (data) updateEmailPlaceholder(appMessages.label_sign_up_email)
    if (passwordText) updatePasswordPlaceholder(appMessages.label_sign_up_password)
    updateInValidFormItem(false)
    emailInput.current.classList.remove('validation-Error')
    if (data && passwordText) {
      updateIsSubmitBtn(true)
    } else {
      updateIsSubmitBtn(false)
    }
  }
  /* --------------------------------------------- */
  /**
   * When: Should be called on password change.
   * It: Enable/disable submit button.
   */
  const passwordHandler = (data) => {
    updatePasswordText(data)
    updateInValidFormItem(false)
    passwordInput.current.classList.remove('validation-Error')
    if (emailText) updateEmailPlaceholder(appMessages.label_sign_up_email)
    if (data) updatePasswordPlaceholder(appMessages.label_sign_up_password)
    if (emailText && data) {
      updateIsSubmitBtn(true)
    } else {
      updateIsSubmitBtn(false)
    }
  }
  /* --------------------------------------------- */
  /**
   * When: Should be called on Enter button click.
   * It: Calls login function.
   */
  const onEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (emailText && passwordText !== '') { handleLoginClick() } else if (emailText && passwordText === '') {
        if (passwordRef?.current?.getInput()) { return passwordRef?.current?.getInputFocus() }
      }
    }
  }
  /* --------------------------------------------- */
  /**
   * When: Should be called on login click.
   * It: Calls login api.
   */
  const handleLoginClick = () => {
    if(isSubmitBtn) {
    const isMultiProfileEnabled = Object.keys(profileManagementConfig).length > 0
    const showSelectionOnStart = profileManagementConfig.showSelectionOnStart
    /* --------------------------------------------- */
    emailInput.current.classList.remove('validation-Error')
    passwordInput.current.classList.remove('validation-Error')
    /* --------------------------------------------- */
    if (baseUrl) {
      updateIsLoading(true)
      const setProfile = isMobile || isTablet
      login(baseUrl, {
        loginMethods,
        userName: emailText,
        password: passwordText,
        identityProvider: authConfig.identityProvider,
        logoutExtendPeriod: authConfig.logoutExtendPeriod,
        accessTokenRefreshInterval: authConfig.accessTokenRefreshInterval
      }, setProfile)
        .then(() => {
          dispatch({ type: 'IS_APP_LOADED', value: false })
          dispatch({ type: 'AUTHENTICATED' })
          updateIsLoading(false)
          if (getTVLoginRedirect()) {
            history.push(routeMap.tvLogin)
          } else if (previousURL) {
            history.push(previousURL)
            dispatch({ type: 'SET_PREVIOUS_URL', value: null })
          } else {
            history.push('/')
          }
          if (isMultiProfileEnabled) {
            dispatch({ type: 'SET_SHOW_MULTI_PROFILE_LIST', value: showSelectionOnStart })
          }
        })
        .catch((error) => {
          dispatch({ type: 'UNAUTHENTICATED' })
          updateIsLoading(false)
          const { errorCode = -1 } = error
          if (errorCode === 1304) {
            showError({ type: 'LoginFailedWrongCredentials', isSendLog: false, errorInfo: error })
          } else if (errorCode === 1319) {
            showError({ type: 'UserDoesnotExist', isSendLog: false, errorInfo: error })
          } else {
            showError({ type: "UserLoginFailed", isSendLog: false, errorInfo: error })
          }
          updateInValidFormItem(true)
        })
    }
  }
  }

  return (
    <>
      <BodyWrapper {...{ theme }} className='catalogContainer login-page-wrapper'>
        {isLoading && <Spinner size={60} IsLoading />}
        <div className='form-page login-wrapper'>
          <div className='form-top-signup'>
            <HeaderTextPrimaryH2 {...{ theme }}>{appMessages.label_login_title_description}</HeaderTextPrimaryH2>
            <HeaderTextSecondaryP {...{ theme }}>{appMessages.label_login_subtitle_description}</HeaderTextSecondaryP>
          </div>
          <div className='form-top'>
            {
              isShowLoginForm &&
                <div className='form'>
                  <div ref={emailInput}>
                    <TextBox {...{ ref: emailRef, placeholder: emailPlaceholder, inValidFormItem, validationText: appMessages.label_sign_up_please_enter_email, theme: inputTheme, type: 'email', name: 'email', handler: emailHandler, onKeyPress: onEnterKeyPress }} />
                  </div>
                  <div ref={passwordInput}>
                    <TextBox {...{ ref: passwordRef, placeholder: passwordPlaceholder, inValidFormItem, isShowEye: true, validationText: appMessages.label_sign_up_please_enter_password, theme: inputTheme, type: 'password', name: 'password', handler: passwordHandler, onKeyPress: onEnterKeyPress }} />
                  </div>
                  <ForgotPassword {...{ theme }}><NavLink to={routeMap.forgotPassword}>{appMessages.label_log_in_forgot_password}</NavLink></ForgotPassword>
                  <ButtonWrapper {...{ theme }}>
                    <div className={`form-btn-wrapper ${isSubmitBtn ? 'sumbit-bnt-active' : ''}`}>
                      <Button {...{ title: appMessages.label_forgot_password_log_in }} onClick={handleLoginClick} />
                    </div>
                  </ButtonWrapper>
                  <div className='form__alternative'>
                    <TextPrimaryP {...{ theme }}>{appMessages.label_log_in_account} </TextPrimaryP>
                    <TextPrimaryNavlink {...{ theme }}> <NavLink to={routeMap.signup}>{appMessages.label_log_in_sign_up}</NavLink></TextPrimaryNavlink>
                  </div>
                </div>
            }
            {
              isShowLoginForm && isShowSocialLogin &&
                <TextSecondary {...{ theme }} className='or-seprator'><p>{appMessages.label_sign_up_or}</p></TextSecondary>
            }
            <SocialLogin method='signin' />
          </div>
        </div>
      </BodyWrapper>
    </>
  )
}
/* --------------------------------------------- */
const BodyWrapper = styled.div`
  background: ${({ theme: { body } }) => body && body.background.primary};
  color: ${({ theme: { body } }) => body && body.text.primary};

  .validation-Error .floting-label {
    color: ${({ theme: { body } }) => body && body.text.secondary};
  }

  .validation-Error .form__item {
    border: 1px solid transparent;
  }

  .form__item {
    border: 1px solid ${({ theme: { body } }) => body && body.accent.primary};
  }

  .form__item---invalid {
    border: 1px solid ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.inputBox && compositeStyle.inputBox.accent && compositeStyle.inputBox.accent.error} !important;
  }

  .form__item---valid {
    border: 1px solid transparent!important;
  }

  .form__alternative {
    padding-bottom: 20px;
  }
`

/* --------------------------------------------- */
const ButtonWrapper = styled.div`
button {
  cursor: not-allowed;
  background: ${({ theme: { compositeStyle } }) =>
    compositeStyle &&
    compositeStyle.primaryButton &&
    compositeStyle.primaryButton.disabled &&
    compositeStyle.primaryButton.disabled.background};
  color: ${({ theme: { compositeStyle } }) =>
    compositeStyle &&
    compositeStyle.primaryButton &&
    compositeStyle.primaryButton.disabled &&
    compositeStyle.primaryButton.disabled.text};
  border:${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.disabled.stroke};  
  width: 25%;
}

.sumbit-bnt-active button {
  cursor: pointer;
  background: ${({ theme: { compositeStyle } }) =>
    compositeStyle &&
    compositeStyle.primaryButton &&
    compositeStyle.primaryButton.normal &&
    compositeStyle.primaryButton.normal.background};
  color: ${({ theme: { compositeStyle } }) =>
    compositeStyle &&
    compositeStyle.primaryButton &&
    compositeStyle.primaryButton.normal &&
    compositeStyle.primaryButton.normal.text};
  border:${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.normal.stroke};

    &:hover {
      background-color:${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.focussed.background};
      color:${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.focussed.text};
      border:${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.primaryButton.focussed.stroke};
    }    
}
`
/* --------------------------------------------- */
const TextSecondary = styled.div`
  p {
    background: ${({ theme: { body } }) => body && body.background.primary};
    color: ${({ theme: { header } }) => header && header.text.disabled};
  }

  &:after {
    background: ${({ theme: { header } }) => header && header.background.disabled};
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    top: 10px;
  }
`
const HeaderTextPrimaryH2 = styled.h2`
  color: ${({ theme: { header } }) => header && header.text.primary};
  margin-bottom: 5px;
`

const HeaderTextSecondaryP = styled.p`
    color: ${({ theme: { body } }) => body && body.text.secondary};
    margin-top: 5px;
`

/* --------------------------------------------- */
const TextPrimaryP = styled.p`
    color: ${({ theme: { body } }) => body && body.text.primary};
    display: inline;
`
/* --------------------------------------------- */

const TextPrimaryNavlink = styled.div`
  a {
    color: ${({ theme: { body } }) => body && body.text.tertiary};
    &:hover {
      color: ${({ theme: { body } }) => body && body.text.secondary};
    }
  }
  display: inline;
`
/* --------------------------------------------- */

const ForgotPassword = styled.h2`
a {
  color: ${({ theme: { body } }) => body && body.text.tertiary};
  &:hover {
    color: ${({ theme: { body } }) => body && body.text.secondary};
  }
}
`
/* --------------------------------------------- */
LoginPage.defaultProps = {}
/* --------------------------------------------- */
export default (withRouter(LoginPage))
/* --------------------------------------------- */
