import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getCuratedDataEntry from './getCuratedDataEntry'

// Helper function to form contentful tree
const getComponentData = (contentful, isCurated) => {
  const filterLocal = getFilter(contentful)
  let curatedDataLocal = [];
  if(!isCurated) { // for curatedData when page filter is called, getPage and getCuratedDataEntry files are called in loop, this flag is used to resolve it
    curatedDataLocal = getCuratedDataEntry(contentful)
  }
  const componentDataLocal = contentful.componentData
  return componentDataLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { filter = {}, curatedData = [] } = fields
      return {
        ...fields,
        sysId: id,
        contentTypeId,
        filter: getNewObj(filter, [filterLocal]),
        ...(!isCurated && { curatedData: getNew(curatedData, curatedDataLocal)})
      }
  })
}

export default getComponentData
