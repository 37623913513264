import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import EditCircleIconHover from './EditCircleIconHover'
import EditCircleIcon from './EditCircleIcon'
import SelectedTickIcon from './SelectedTickIcon'
import { useSelector } from 'react-redux'
import { GlobalContext } from '../../context/global.context'




const Avatar = ({ height, image, status = null, showBorder = false , editFunction = null }) => {
    const { uiDirection } = useContext(GlobalContext)
    // Props: height:<overall height and width of the avatar,eg:60px>,
    //  image:<Avatar image, SVG>, 
    //  status:<'selected' or 'edit'>
    // shoeBorder = true/false

    const { userManagementThemes = {} } = useSelector(store => store.userManagement)
    const { profileManagementConfig = {}} = useSelector(store => store.profileManagement)
    const [fallbackAvatar, setFallbackAvatar] = useState("")

    const [theme, setTheme] = useState(null)
    const [isFocussedOnEdit, setIsFocussedOnEdit] = useState(false)

    // --Setting Profile List Theme---
    useEffect(() => {
        if (userManagementThemes && userManagementThemes.themeProfileList) setTheme(userManagementThemes.themeProfileList)
    }, [userManagementThemes])

    useEffect(() => {
        if (profileManagementConfig && profileManagementConfig.avatars) {
            let parsedData = JSON.parse(profileManagementConfig.avatars)
            if(parsedData.avatars && parsedData.avatars[0]){
                setFallbackAvatar(parsedData.avatars[0])
            }
        }
    }, [profileManagementConfig])
    return (
        <AvatarStyled height={height} showBorder={showBorder} theme={theme}>
            <div className="avatar-wrapper">
                <img className="avatar-wrapper-image" src={image || fallbackAvatar} alt="imgalt"/>
                {status && <div className="avatar-wrapper-icon" onClick={() => editFunction()} onMouseEnter={() => setIsFocussedOnEdit(true)} onMouseLeave={() => setIsFocussedOnEdit(false)} dir={uiDirection}>
                    {status === 'selected' ?
                        <SelectedTickIcon tickColor={theme && theme.compositeStyle && theme.compositeStyle.tertiaryButton && theme.compositeStyle.tertiaryButton.normal && theme.compositeStyle.tertiaryButton.normal.background}
                            backgroundColor={theme && theme.compositeStyle && theme.compositeStyle.tertiaryButton && theme.compositeStyle.tertiaryButton.normal && theme.compositeStyle.tertiaryButton.normal.text} />
                        :
                        status === 'edit' ?
                            isFocussedOnEdit ?
                                <EditCircleIconHover pencilColor={theme && theme.compositeStyle && theme.compositeStyle.tertiaryButton && theme.compositeStyle.tertiaryButton.normal && theme.compositeStyle.tertiaryButton.normal.text}
                                backgroundColor={theme && theme.compositeStyle && theme.compositeStyle.tertiaryButton && theme.compositeStyle.tertiaryButton.normal && theme.compositeStyle.tertiaryButton.normal.background} />
                                :
                                <EditCircleIcon pencilColor={theme && theme.compositeStyle && theme.compositeStyle.tertiaryButton && theme.compositeStyle.tertiaryButton.normal && theme.compositeStyle.tertiaryButton.normal.background}
                                backgroundColor={theme && theme.compositeStyle && theme.compositeStyle.tertiaryButton && theme.compositeStyle.tertiaryButton.normal && theme.compositeStyle.tertiaryButton.normal.text} />
                            : ''}
                </div>}
            </div>

        </AvatarStyled>
    )
}


const AvatarStyled = styled.div`

    // We're getting the param 'height'. Since this is a circle and hence the width and height
    // are treated as the same value

    .avatar-wrapper{
        width: ${({ height }) => height};
        height: ${({ height }) => height};
        position: relative;

        .avatar-wrapper-image{
                vertical-align: middle;
                height: ${({ height }) => height};
                border-radius: 50%;
                border: ${({ showBorder, theme: { compositeStyle } }) => showBorder  ? `3px solid ${compositeStyle && compositeStyle.selectorStyle && compositeStyle.selectorStyle.selected && compositeStyle.selectorStyle.selected.stroke} ` : ''} 
            
        }//avatar-wrapper-image
        .avatar-wrapper-icon{
            svg{
                height: 25%;
                position: absolute;
                top: 3px;
                right: 3px;
                cursor: pointer;
            }

        }//avatar-wrapper-icon

        // RTL
        .avatar-wrapper-icon[dir="rtl"]{
            svg{
                left: 3px;
                right: unset
            }

        }//avatar-wrapper-icon
    }//avatar-wrapper

`

export default Avatar
