import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const RewindIcon = ({ color, text, ...rest }) => (
  <svg viewBox="0 0 27 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g>
      <path 
      fill={color} d="M15.2018 4.2206V1.33129C15.2019 1.09759 15.1405 0.867982 15.0237 0.665564C14.9069 0.463146 14.7389 0.29506 14.5365 0.178222C14.3341 0.0613839 14.1045 -8.53261e-05 13.8708 8.88953e-08C13.6371 8.55039e-05 13.4076 0.0617225 13.2053 0.178709L7.18729 3.65219C6.98491 3.76914 6.81686 3.93728 6.70003 4.13973C6.58319 4.34218 6.52168 4.57181 6.52168 4.80555C6.52168 5.0393 6.58319 5.26893 6.70003 5.47138C6.81686 5.67383 6.98491 5.84197 7.18729 5.95892L13.2053 9.43397C13.4077 9.55103 13.6374 9.61267 13.8712 9.61268C14.105 9.6127 14.3347 9.55109 14.5372 9.43407C14.7396 9.31704 14.9076 9.14873 15.0243 8.9461C15.141 8.74346 15.2022 8.51365 15.2018 8.27982V5.82351C17.3818 6.27301 19.3812 7.35427 20.9507 8.93248C22.5203 10.5107 23.5905 12.516 24.028 14.6984C24.4655 16.8808 24.2509 19.1437 23.411 21.2049C22.571 23.2662 21.1429 25.0346 19.3046 26.2896C17.4664 27.5446 15.2993 28.2308 13.0738 28.2624C10.8482 28.2941 8.66249 27.67 6.78925 26.4678C4.91601 25.2656 3.4381 23.5386 2.5398 21.5021C1.6415 19.4656 1.36255 17.2098 1.73774 15.0158C1.75532 14.9139 1.75264 14.8094 1.72987 14.7085C1.7071 14.6076 1.66467 14.5122 1.60502 14.4277C1.54536 14.3432 1.46965 14.2712 1.38219 14.216C1.29474 14.1608 1.19726 14.1233 1.09532 14.1057C0.993381 14.0881 0.888978 14.0908 0.788072 14.1136C0.687166 14.1363 0.591733 14.1788 0.507223 14.2384C0.422713 14.2981 0.35078 14.3738 0.295531 14.4612C0.240283 14.5487 0.202801 14.6462 0.185225 14.7481C-0.24495 17.2604 0.0780681 19.8438 1.11358 22.1727C2.14909 24.5017 3.85076 26.4721 6.00419 27.8357C8.15761 29.1992 10.6664 29.8949 13.2146 29.8351C15.7627 29.7752 18.2361 28.9625 20.3231 27.4994C22.4101 26.0363 24.0174 23.9881 24.9425 21.6131C25.8675 19.2381 26.0689 16.6424 25.5212 14.1531C24.9736 11.6638 23.7014 9.39226 21.865 7.62474C20.0287 5.85721 17.7102 4.67275 15.2018 4.2206ZM13.6272 7.85941L8.3383 4.80634L13.6272 1.75169V7.85941Z" 
      />
      <text x="28%" y="67%" fontSize="10" fill={color}>{text} </text>  
    </g>
  </svg>

)
/* --------------------------------------------- */
RewindIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Text inside icon. */
  text: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
RewindIcon.defaultProps = {
  color: 'transparent',
  text: 25,
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default RewindIcon
/* --------------------------------------------- */
