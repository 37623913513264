import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const FullscreenEnter = ({ color, ...rest }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.0444 0H0.9375C0.419062 0 0 0.419062 0 0.9375V10.0444C0 10.5628 0.419062 10.9819 0.9375 10.9819C1.455 10.9819 1.875 10.5628 1.875 10.0444V1.875H10.0444C10.5619 1.875 10.9819 1.45594 10.9819 0.9375C10.9819 0.419062 10.5619 0 10.0444 0ZM19.9552 0H29.0621C29.5796 0 29.9996 0.419062 29.9996 0.9375V10.0444C29.9996 10.5628 29.5796 10.9819 29.0621 10.9819C28.5446 10.9819 28.1246 10.5628 28.1246 10.0444V1.875H19.9552C19.4368 1.875 19.0177 1.45594 19.0177 0.9375C19.0177 0.419062 19.4368 0 19.9552 0ZM28.1246 19.9549C28.1246 19.4364 28.5446 19.0174 29.0621 19.0174C29.5796 19.0174 29.9996 19.4364 29.9996 19.9549V29.0627C29.9996 29.5811 29.5796 30.0002 29.0621 30.0002H19.9552C19.4368 30.0002 19.0177 29.5811 19.0177 29.0627C19.0177 28.5443 19.4368 28.1252 19.9552 28.1252H28.1246V19.9549ZM1.875 28.125H10.0444C10.5619 28.125 10.9819 28.5441 10.9819 29.0625C10.9819 29.5809 10.5619 30 10.0444 30H0.9375C0.419062 30 0 29.5809 0 29.0625V19.9547C0 19.4362 0.419062 19.0172 0.9375 19.0172C1.455 19.0172 1.875 19.4362 1.875 19.9547V28.125Z" fill={color}/>
</svg>
)
/* --------------------------------------------- */
FullscreenEnter.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
FullscreenEnter.defaultProps = {
  color: 'transparent',
  style: {
    width: 35,
    height: 35
  }
}
/* --------------------------------------------- */
export default FullscreenEnter
/* --------------------------------------------- */
