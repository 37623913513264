import React, { useEffect, useContext } from 'react'
import { Route, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
import { routeMap } from '../route'
/* --------------------------------------------- */
import LoginPopup from '../components/pages/LoginPopup'
import { isShowError } from '../components/errors'
/* --------------------------------------------- */
import utils from '../utils/utils'
import { getUserAuthInfo, setTVLoginRedirect } from '../utils/localStorageService'
/* --------------------------------------------- */
import { GlobalContext } from '../context/global.context'
import { ErrorContext } from '../context/error.context'
/* --------------------------------------------- */
// ProtectedRoute component to redirect unauthenticated users to home pages.
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, refreshCallState, isAppLoaded, isShowSplash } = useContext(GlobalContext) // userInfo
  const { errorPage } = useContext(ErrorContext)
  const history = useHistory()
  const location = useLocation()
  /* --------------------------------------------- */
  const { revenueFeature } = useSelector(store => store.featuresReducers)
  /* --------------------------------------------- */
  // useEffect(() => {
  //   /** Redirect user to home page from player on force logout or when unauthenticated. */
  //   if (utils.startsWith(decodeURIComponent(location?.pathname), routeMap.player) && !isAuthenticated) {
  //     history.push('/')
  //   }
  // }, [history, location?.pathname, isAuthenticated])
  /* --------------------------------------------- */
  /**
   * It: Redirects to home when revenue mode is anonymous.
   */
  useEffect(() => {
    if (utils.startsWith(decodeURIComponent(location?.pathname), routeMap.paymentHistory)) {
      if (revenueFeature.revenueMode && revenueFeature.revenueMode[0] === 'anonymous') {
        history.push('/')
      }
    }
  }, [revenueFeature.revenueMode, history, location])
  /* --------------------------------------------- */
  /**
   * It: Redirects to home from change password page when unauthenticated.
   */
  useEffect(() => {
    if (utils.startsWith(decodeURIComponent(location?.pathname).toLowerCase(), routeMap.changePassword)) {
      const userAuthInfo = getUserAuthInfo()
      if (
        ['cognito', 'mpx'].indexOf(userAuthInfo.identityProvider) === -1 || (!isAuthenticated && isAppLoaded && !isShowSplash)
      ) {
        history.push('/')
      }
    }
  }, [isAuthenticated, isAppLoaded, history, location, isShowSplash])
   /* --------------------------------------------- */
  /**
   * It: Redirects to home from tvLogin page when unauthenticated.
   */
  useEffect(() => {
    if (utils.startsWith(decodeURIComponent(location?.pathname).toLowerCase(), routeMap.tvLogin.toLowerCase())) {
      if (
        !isAuthenticated && isAppLoaded && !isShowSplash 
      ) {
        setTVLoginRedirect(true)
        history.push(routeMap.login)
      }
    }
  }, [isAuthenticated, isAppLoaded, history, location, isShowSplash])
  /* --------------------------------------------- */
   /**
   * It: Redirects to home from my account page when unauthenticated.
   */
  useEffect(() => {
    if (utils.startsWith(decodeURIComponent(location?.pathname), routeMap.myAccount)) {
      if (
        !isAuthenticated && isAppLoaded && !isShowSplash 
      ) {
        history.push('/')
      }
    }
  }, [isAuthenticated, isAppLoaded, history, location, isShowSplash])
  /* --------------------------------------------- */
  /**
   * It: Redirects to home from payement page when unauthenticated.
   */
  useEffect(() => {
    if (utils.startsWith(decodeURIComponent(location?.pathname), routeMap.payment.replace(':storeProductId', ''))) {
      if (!isAuthenticated && isAppLoaded && !isShowSplash ) { //added the same check we did for other non static pages isauthenticated value will not get in the initial useEffect load itself
        history.push('/')
      }
    }
  }, [isAuthenticated, history, location, isAppLoaded, isShowSplash])
  /* --------------------------------------------- */
  /**
   * It: Redirects to home from favourites page and other account related pages if unauthenticated.
   */
   useEffect(() => {
    if (utils.startsWith(decodeURIComponent(location?.pathname), routeMap.favourites) ||
      utils.startsWith(decodeURIComponent(location?.pathname), routeMap.editProfile) ||
      utils.startsWith(decodeURIComponent(location?.pathname), routeMap.accountInfo) ||
      utils.startsWith(decodeURIComponent(location?.pathname), routeMap.myAccount)) {
        if (isAppLoaded && refreshCallState === 'ENDED' && !isAuthenticated) {
          history.push('/')
        }
    }
  }, [isAuthenticated, isAppLoaded, refreshCallState, history, location])  
  /* --------------------------------------------- */
  /**
   * It: Authenticates and show component.
   */
  const AuthenticatedComponent = () => {
    if (isShowError(errorPage)) return null
    return (
      <>
        <LoginPopup />
        <Component {...rest} />
      </>
    )
  }
  // const userAuthInfo = getUserAuthInfo();

  // waiting for the trusted login to get called and set value in userInfo.customerType
  // if (Object.keys(userAuthInfo).length !== 0 && !userInfo.customerType) {
  //   return <></>
  // }

  return <Route render={AuthenticatedComponent} />
}
/* --------------------------------------------- */
export default ProtectedRoute
