import React, { useContext, useRef } from 'react'
import visitorInfo from 'visitor-info'
import { Context } from "./context";
import { WarningIcon } from './style'
import { routeMap } from '../../../route'
import utils from '../../../utils/utils'
import { getBrowserName } from '../../..//utils/contentfulHandler'
import './ErrorFullscreen.scss'
import { GlobalContext } from '../../../context/global.context';
import {version as appVersion} from '../../../config';

const visitor = visitorInfo()
const osInfo = visitor.os || {}
const osMap = {
    'Mac OS': 'mac'
}

// export const testFunctionObj = { testFunction() { } }
/* ---------------------------------------------------------------------------------------------------- */
// ErrorFullscreen component which handles rendering and events.
const ErrorFullscreen = () => {
    const { errorFullscreen = {}, errorDispatch, appMessages = {}, userInfo = {}, version = {} } = useContext(Context)
    const { sessionId } = useContext(GlobalContext)
    const sessionIdRef = useRef(sessionId)
    /* ------------------------------------------------------------------------------------------------ */
    const {
        title,
        description,
        code,
        primaryActionType,
        secondaryActionType,
        primaryActionLabel,
        secondaryActionLabel,
        secondaryActionDescription,
        onPrimaryBtnClick = () => { },
        onSecondaryBtnClick = () => { },
        faqDescription,
        faqLabel,
        hidePrimaryButton,
        hideSecondaryButton,
        faqItem = []
    } = errorFullscreen || {}
    /* ------------------------------------------------------------------------------------------------ */
    const {
        subscriptionType = '',
        customerType = '',
        source = ''
    } = userInfo || {}
    /* ------------------------------------------------------------------------------------------------ */
    /** It: Handles primary button click based on contentful config. */
    const handelePrimaryBtnClick = () => {
        if (primaryActionType === 'retry') window.location.reload()
        else if (primaryActionType === 'exit') window.close()
        else if (primaryActionType === 'cancel') {
            onPrimaryBtnClick()
            errorDispatch({ type: 'ERROR_FULLSCREEN', value: {} })
        }
        else if (primaryActionType === 'custom') onPrimaryBtnClick()
    }
    /* ------------------------------------------------------------------------------------------------ */
    /** It: Handles secondary button click based on contentful config. */
    const handeleSecondaryBtnClick = () => {
        if (secondaryActionType === 'retry') window.location.reload()
        else if (secondaryActionType === 'exit') window.close()
        else if (secondaryActionType === 'cancel') {
            onSecondaryBtnClick()
            errorDispatch({ type: 'ERROR_FULLSCREEN', value: {} })
        }
        else if (secondaryActionType === 'custom') onSecondaryBtnClick()
    }
    /* ------------------------------------------------------------------------------------------------ */
    /** It: Contstructs faq url on FAQ button click and opens in new tab */
    const handleFAQBtnClick = () => {
        // testFunctionObj.testFunction() //This function is created & used only for testing.
        if (faqItem.length === 0) return
        const { id } = faqItem[0]
        if (!id) return
        const browserName = (visitor.browser || {}).name || ''
        const engineName = (visitor.engine || {}).name || ''
        const filters = utils.clearMissingValues({
            platforms: 'web',
            browser: getBrowserName(browserName, engineName),
            displayOrientation: window.innerWidth > window.innerHeight ? 'landscape' : 'portrait',
            buildType: version.buildType || '',
            minimumBuildNumber: version.buildNumber || '',
            operatingSystem: osMap[osInfo.name] || '',
            subscriptionType,
            userType: customerType,
            userSource: source,
            id: id //added id as param to point to linked faq in the faq page
        })
        const query = utils.stringify(filters)
        window.open(`${window.location.origin}${routeMap.faq}?${query}`, '_blank')
    }
    /* ------------------------------------------------------------------------------------------------ */
    return (
        <div className='error-fullscreen-wrapper'>
            <div className='error-fullscreen__content'>
                <div className="error-fullscreen-header">
                    <WarningIcon />
                    {title && <h2>{title}</h2>}
                </div>
                {description && <p>{description}</p>}
                {code && <p className='error-code'>{appMessages.error_code || "CODE D'ERREUR"}: {code}</p>}
                {sessionIdRef?.current && <p className='error-session-id'>{appMessages.label_sid}: {sessionIdRef?.current} {appVersion?.name ? `(${appVersion?.name})`:'' }</p>}
                <div className='error-fullscreen__btn'>
                    {
                        primaryActionLabel && !hidePrimaryButton &&
                        <div className='popup-btn popup-btn__positive' onClick={handelePrimaryBtnClick}>
                            {primaryActionLabel}
                        </div>
                    }
                    {
                        secondaryActionDescription &&
                        <span className='popup-btn-subtitle'>{secondaryActionDescription}</span>
                    }
                    {
                        secondaryActionLabel && !hideSecondaryButton &&
                        <div className='popup-btn popup-btn__negative' onClick={handeleSecondaryBtnClick}>
                            {secondaryActionLabel}
                        </div>
                    }
                </div>
                {
                    faqItem.length > 0 &&
                    <div className='error-fullscreen__faq'>
                        {
                            faqDescription &&
                            <span className='popup-btn-subtitle faq-description'>{faqDescription}</span>
                        }
                        {
                            faqLabel &&
                            <div className='popup-btn popup-btn__negative' onClick={handleFAQBtnClick}>
                                {faqLabel}
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
ErrorFullscreen.propTypes = {}
/* ---------------------------------------------------------------------------------------------------- */
ErrorFullscreen.defaultProps = {}
/* ---------------------------------------------------------------------------------------------------- */
export default ErrorFullscreen
/* ---------------------------------------------------------------------------------------------------- */
