import styled from 'styled-components'
import _utils from '../../utils/utils'
/* --------------------------------------------- */
// Styled components for SelectBox component.
export const SelectBoxInput = styled.div`
  background: ${({ theme: { footer } }) => footer && footer.background.secondary};
  color: ${({ theme: { footer } }) => footer && footer.text.secondary};
  border: 0.5px solid ${({ theme: { footer } }) => footer && footer.accent.primary};
`

export const DropdownIcon = styled.div`
  border-color: ${({ theme: { footer } }) => footer && footer.accent.secondary} transparent;
`

export const DropdownItem = styled.div`
  background: ${({ theme: { compositeStyle } }) => _utils.isNotNull(compositeStyle, 'dropdownStyle', 'normal', 'background') && compositeStyle.dropdownStyle.normal.background};
  color: ${({ theme: { compositeStyle } }) => _utils.isNotNull(compositeStyle, 'dropdownStyle', 'normal', 'text') && compositeStyle.dropdownStyle.normal.text};
  &:hover{
    background: ${({ theme: { compositeStyle } }) => _utils.isNotNull(compositeStyle, 'dropdownStyle', 'focussed', 'background') && compositeStyle.dropdownStyle.focussed.background};
    color: ${({ theme: { compositeStyle } }) => _utils.isNotNull(compositeStyle, 'dropdownStyle', 'focussed', 'text') && compositeStyle.dropdownStyle.focussed.text};
  }
  & [active]{
    background: ${({ theme: { compositeStyle } }) => _utils.isNotNull(compositeStyle, 'dropdownStyle', 'selected', 'background') && compositeStyle.dropdownStyle.selected.background};
    color: ${({ theme: { compositeStyle } }) => _utils.isNotNull(compositeStyle, 'dropdownStyle', 'selected', 'text') && compositeStyle.dropdownStyle.selected.text};
  }
`