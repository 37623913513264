import React from 'react'
import { useSelector } from 'react-redux'
import ErrorPage from './ErrorPage'
import Provider from './context'
import { Wrapper } from './style'
/* ---------------------------------------------------------------------------------------------------- */
// Wrapper component for ErrorPage component.
const ErrorPageWrapper = () => {
    const { appMessages } = useSelector(store => store.appConfigReducers)

    return (
        <Provider  value = {{ appMessages }}>
            <Wrapper>
                <ErrorPage/>
            </Wrapper>
        </Provider>
    )
}

/* ---------------------------------------------------------------------------------------------------- */
export default ErrorPageWrapper
/* ---------------------------------------------------------------------------------------------------- */
