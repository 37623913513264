import styled from 'styled-components'

export const AppWrapper = styled.div`
  background: ${({ theme: { body } }) => body && body.background.primary};
  color: ${({ theme: { body } }) => body && body.text.primary};
  min-height: ${({ isNoHeaderPage, isTvGuidePage }) => isNoHeaderPage || isTvGuidePage ? 'unset' : 'calc(100vh - 65px)'};

  /*  unset min-height for contact-us/faq/tos/pp popups */
  .cms-page__content-data & { 
      min-height: unset;
  }
`
