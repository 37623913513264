import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getPage from './getPage'

// Helper function to form contentful tree
const getFeatureSearch = (contentful) => {
  const filterLocal = getFilter(contentful)
  const pageLocal = getPage(contentful)
  const featureSearchLocal = contentful.featureSearch
  return featureSearchLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { filter = {}, searchPage = [] } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      searchPage: getNew(searchPage, pageLocal)
    }
  })
}

export default getFeatureSearch
