import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const VolumeIcon = ({ color, ...rest }) => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.2114 28.9116C17.3098 29.3466 17.6961 29.641 18.1245 29.641C18.1929 29.641 18.2633 29.6335 18.3326 29.6185C25.2027 28.0594 30 22.048 30 15.0007C30 7.95344 25.2027 1.9421 18.3336 0.382075C17.8273 0.268636 17.3257 0.584578 17.2114 1.08896C17.0961 1.59428 17.4129 2.09585 17.9173 2.21117C23.9277 3.57525 28.125 8.83564 28.125 15.0007C28.125 21.1668 23.9277 26.4253 17.9182 27.7884C17.4129 27.9038 17.0961 28.4063 17.2114 28.9116ZM1.87503 18.7506H7.18792C7.43637 18.7506 7.6745 18.8491 7.85075 19.0253L14.0627 25.2373V4.76383L7.85075 10.9758C7.6745 11.1521 7.43637 11.2505 7.18792 11.2505H1.87503V18.7506ZM15.0002 28.438C14.7565 28.438 14.5165 28.3423 14.3374 28.1633L6.79886 20.6256H0.937515C0.420007 20.6256 0 20.2066 0 19.6881V10.313C0 9.79453 0.420007 9.37547 0.937515 9.37547H6.79886L14.3374 1.83785C14.6055 1.56972 15.0077 1.48815 15.3593 1.63441C15.709 1.77878 15.9377 2.12191 15.9377 2.50067V27.5004C15.9377 27.8792 15.709 28.2223 15.3593 28.3667C15.2431 28.4155 15.1212 28.438 15.0002 28.438ZM18.1264 22.2196C17.7804 22.2196 17.4476 22.0284 17.2845 21.6965C17.0557 21.2324 17.247 20.6708 17.7111 20.4421C19.798 19.4117 21.0945 17.3267 21.0945 15.0007C21.0945 12.6785 19.7961 10.5869 17.7064 9.54253C17.2432 9.31003 17.0548 8.74752 17.2864 8.28439C17.5179 7.82125 18.0814 7.63375 18.5445 7.86438C21.2736 9.23034 22.9696 11.9641 22.9696 15.0007C22.9696 18.0458 21.2717 20.7749 18.5398 22.1221C18.4067 22.1887 18.2651 22.2196 18.1264 22.2196Z" fill={color}/>
</svg>

)
/* --------------------------------------------- */
VolumeIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
VolumeIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default VolumeIcon
/* --------------------------------------------- */
