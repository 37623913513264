import {
  SEARCH_COMPONENTS, SEARCH_CONFIG
} from '../constants/actionTypes'
import { filter, hydratePageComponent } from '../utils/contentfulHandler'
import utils from '../utils/utils'

/** SearchConfig store */
export const fetchSearchConfig = (features = [], themeData, options) => {
  return (dispatch) => {
    const {
      appThemeData,
      componentThemeData
    } = themeData
    features = filter(features, '', options)

    let featureSearch = features.map(item => {
      if (item.contentTypeId === 'featureSearch') return item
      return null
    }).filter(feature => feature !== null)

    featureSearch = filter(featureSearch, '', options)

    if (utils.isNotNull(featureSearch, 0)) {
      const searchConfig = featureSearch[0]
      dispatch({
        type: SEARCH_CONFIG,
        value: searchConfig
      })

      if (utils.isNotNull(searchConfig, 'searchPage')) {
        const searchPage = filter(searchConfig.searchPage, '', options)
        if (utils.isNotNull(searchPage, 0, 'components')) {
          const pageConfig = {}
          let searchComponents = searchPage[0].components
          searchComponents = filter(searchComponents, '', options)
          searchComponents.map((comp) => {
            let pConfig = {}
            let pComponentStyle = []
            let pTheme = {}
            let oTheme = {}
            const component = comp
            const {
              config, componentStyles, componentTheme, overflowTheme
            } = hydratePageComponent(component, options, {
              defaultPageTheme: appThemeData,
              defaultComponentTheme: componentThemeData
            })
            pConfig = config
            pComponentStyle = componentStyles
            pTheme = componentTheme
            oTheme = overflowTheme

            let identifierExt = ''
            if (utils.isNotNull(comp, 'identifierExt')) {
              identifierExt = comp.identifierExt
            }
            pageConfig[identifierExt] = {
              config: pConfig,
              componentStyle: pComponentStyle,
              theme: pTheme,
              overflowListingTheme: oTheme
            }
            return null
          })
          dispatch({
            type: SEARCH_COMPONENTS,
            value: pageConfig
          })
        }
      }
    }
    return Promise.resolve()
  }
}
