import React from 'react'
import ToggleSwitch from './ToggleSwitch'
import { Wrapper } from './style'
/* ---------------------------------------------------------------------------------------------------- */
// Wrapper component for ToggleSwitch component.
const ToggleSwitchWrapper = (props) => {
    return (
        <Wrapper theme={props.theme}>
            <ToggleSwitch checked={props.checked} onChange={props.onChange} />
        </Wrapper>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
export default ToggleSwitchWrapper
/* ---------------------------------------------------------------------------------------------------- */
