import React, { useState, useEffect, useRef, useCallback, forwardRef, useImperativeHandle, useContext } from 'react';
import visitorInfo from 'visitor-info'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
// import uuidv1 from 'uuid/v1'
/* --------------------------------------------- */
import ShakaPlayer from './ShakaPlayer'
/* --------------------------------------------- */
import useLoggly from '../../containers/useLoggly'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
/* --------------------------------------------- */
// import useGeneralApi from '../../apis/useGeneralApi'
// import usePlayerApi from '../../apis/usePlayerApi'
/* --------------------------------------------- */
import utils from '../../utils/utils'
import { getAppLanguageWithoutRegion } from '../../utils/localStorageService'
// import { useEffectDebugger } from '../../utils/debugger'
import { PlayerContext } from '../../context/player.context'
/* --------------------------------------------- */
import withRouterInnerRef from '../../components/hoc/withRouterInnerRef'
// import { getProductsLocal } from '../../utils/sessionStorageService';
// import VERSION from '../../constants/apiVersionMap'

/* --------------------------------------------- */
const { browser } = visitorInfo()
/* --------------------------------------------- */
// PlayerSwitcher component to handle player switching and concurrency
const PlayerSwitcher = forwardRef(({
    history, smilUrl, baseUrl, castConfigPlaybackFormat, isLivePlayer, title, mediaId,
    onLoad, onBuffer, onBufferComplete, onPlay, onPause, onCurrentTimeChange, onComplete, playbackAssetFormat, playbackPreviewAssetFormat,
    onCrash, onConcurrencyLimitViolation, onLicenseNotGranted, onUnknowFormat, onGeoLocationBlocked, onPlayerVpnBlocked,
    onAccessDenied, onUnload, qualityMapping, qualityMappingMode, abrMode, type, debugModeEnabled, seasonNumber, isShakaPlayerMount, isMuted, vidurl, retry, currentProgram, smilContent
}, ref) => {
    /* --------------------------------------------- */
    const location = useLocation()
    const { trailer } = utils.parse(location.search)
    /* --------------------------------------------- */
    const [isPlayerError, setPlayerError] = useState(false)
    const [fairplayCertificate, setFairplayCertificate] = useState(null)
    const [isEncrypted, setIsEncrypted] = useState(false)
    /* --------------------------------------------- */
    const [url, updateUrl] = useState(null)
    const [pid, updatePid] = useState(null)
    const [currentPlaybackFormat, updateCurrentPlaybackFormat] = useState('')
    /* --------------------------------------------- */
    const { dispatch, onPIDChange } = useContext(PlayerContext)
    const { isCastConnectedStatus, dispatch: globalDispatch, isAutoplay: globalAutoplay, userToken, userInfo, sessionId } = useContext(GlobalContext)
    /* --------------------------------------------- */
    // const { getData } = useGeneralApi()
    // const { getParsedSMIL } = usePlayerApi()
    /* --------------------------------------------- */
    const { log } = useLoggly()
    /* --------------------------------------------- */
    const _uuid = useRef(sessionId)
    const playerRef = useRef(null)
    const userTokenRef = useRef('')
    const userInfoRef = useRef(userInfo)
    /* --------------------------------------------- */
    useEffect(() => {
        userTokenRef.current = userToken
    }, [userToken])
    /* --------------------------------------------- */
    useEffect(() => {
        userInfoRef.current = userInfo
    }, [userInfo])

    /* --------------------------------------------- */
    /**
     * It: Sets playback format in state.
     */
    useEffect(() => {
        let playbackformat = playbackAssetFormat
        if (isCastConnectedStatus) playbackformat = castConfigPlaybackFormat
        if (trailer === 'true' || type === 'preview') playbackformat = playbackPreviewAssetFormat
        updateCurrentPlaybackFormat(playbackformat)
    }, [type,
        playbackAssetFormat,
        playbackPreviewAssetFormat,
        trailer,
        isCastConnectedStatus,
        castConfigPlaybackFormat
    ])
    /* --------------------------------------------- */
    /**
     * It: Tiggers play event in player.
     */
    const play = useCallback(() => {
        try{
          if (playerRef.current && playerRef.current.play) playerRef.current.play()
        } catch{}
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Tiggers pause event in player.
     */
    const pause = useCallback(() => {
        try{    
           if (playerRef && playerRef?.current?.pause) playerRef.current.pause()
        } catch {}
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Tiggers mute event in player.
     */
    const mute = useCallback(() => {
        if (playerRef?.current?.mute) playerRef.current.mute()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Tiggers unmute event in player.
     */
    const unmute = useCallback(() => {
        if (playerRef.current.unmute) playerRef.current.unmute()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Tiggers pip start event in player.
     */
     const pipStart = useCallback(() => {
        if (playerRef?.current?.mute) playerRef.current.pipStart()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Tiggers pip exit event in player.
     */
    const pipExit = useCallback(() => {
        if (playerRef.current.unmute) playerRef.current.pipExit()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Gets current time from player.
     */
    const getCurrentTime = useCallback(() => {
        if (utils.isNotNull(playerRef, 'current', 'getCurrentTime')) return playerRef.current.getCurrentTime()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Sets current time in player.
     */
    const setCurrentTime = useCallback((currentTime) => {
        if (playerRef && playerRef?.current?.setCurrentTime) playerRef.current.setCurrentTime(currentTime)
        /* --------------------------------------------- */
        const castPlayer = window.castPlayer
        if (utils.isNotNull(castPlayer, 'remotePlayer', 'currentTime') &&
            utils.isNotNull(castPlayer, 'remotePlayerController', 'seek')) {
            castPlayer.remotePlayer.currentTime = currentTime;
            castPlayer.remotePlayerController.seek();
        }
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Gets duration from player.
     */
    const getDuration = useCallback(() => {
        if (playerRef.current && playerRef.current.getDuration) return playerRef.current.getDuration()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Gets all qualities from player.
     */
    const getAllQualities = useCallback(() => {
        if (playerRef && playerRef.current.getAllQualities) return playerRef.current.getAllQualities()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Gets current quality from player.
     */
    const getCurrentQuality = useCallback(() => {
        if (playerRef && playerRef.current.getCurrentQuality) return playerRef.current.getCurrentQuality()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Sets current quality from player.
     */
    const setCurrentQuality = useCallback((quality, options) => {
        if (playerRef && playerRef.current.setCurrentQuality) return playerRef.current.setCurrentQuality(quality, options)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Gets all subtitles from player.
     */
    const getAllSubtitles = useCallback(() => {
        if (playerRef && playerRef.current && playerRef.current.getAllSubtitles) return playerRef.current.getAllSubtitles()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Gets current subtitle from player.
     */
    const getCurrentSubtitle = useCallback(() => {
        if (playerRef && playerRef.current.getCurrentSubtitle) return playerRef.current.getCurrentSubtitle()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Sets current subtitle from player.
     */
    const setCurrentSubtitle = useCallback((subtitle) => {
        if (playerRef && playerRef.current.setCurrentSubtitle) return playerRef.current.setCurrentSubtitle(subtitle)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Gets all audios from player.
     */
    const getAllAudios = useCallback(() => {
        if (playerRef && playerRef.current.getAllAudios) return playerRef.current.getAllAudios()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Get current audio from player.
     */
    const getCurrentAudio = useCallback(() => {
        if (playerRef && playerRef.current.getCurrentAudio) return playerRef.current.getCurrentAudio()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Sets current audio from player.
     */
    const setCurrentAudio = useCallback((audio) => {
        if (playerRef && playerRef.current.setCurrentAudio) return playerRef.current.setCurrentAudio(audio)
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Resets current time to beginning.
     * It: Calls onComplete callback.
     */
    const _onComplete = useCallback(() => {
        onComplete()
        setCurrentTime(0)
    }, [setCurrentTime, onComplete])
    /* --------------------------------------------- */
    /**
     * It: returns the video element
     */
    const getVideoEle = useCallback(() => {
        return playerRef.current.getVideoEle && playerRef.current.getVideoEle()
    }, [])
    /* --------------------------------------------- */
    /**
     * It: Calls when playhead enters a large gap
     */
    const onLargeGap = useCallback((currentTime, gapSize) => {
      log({
        type: 'Debug',
        attr: {
          module: 'Player',
          debug_message: `${title} - GapSize: ${gapSize} and CurrentTime ${currentTime}`
        }
      })
    }, [log, title])
    /* --------------------------------------------- */
    useImperativeHandle(ref, () => {
        return {
            play, pause, mute, unmute, getCurrentTime, setCurrentTime, getDuration,
            getAllQualities, getCurrentQuality, setCurrentQuality,
            getAllSubtitles, getCurrentSubtitle, setCurrentSubtitle,
            getAllAudios, getCurrentAudio, setCurrentAudio, setPlayerError, getVideoEle, pipStart, pipExit
        }
    });
    /* --------------------------------------------- */
    /**
     * It: Calls onCrash callback.
     */
    const _onCrash = useCallback((res = {}, errorInfo) => {
        onCrash(res, errorInfo)
    }, [onCrash])
    /* --------------------------------------------- */
    /**
     * It: Fetches SMIL.
     */
    // useEffect(() => {
    //     let isMounted = true
    //     const uuid = _uuid.current
    //     if (!smilUrl || !currentPlaybackFormat) return
    //     if (refreshCallState === 'INITIATED') return
    //     if (refreshCallState !== 'ENDED') return
    //     /* --------------------------------------------- */
    //     let _smilUrl = smilUrl.replace(/^http:\/\//i, `${window.location.protocol}//`)
    //     let products = getProductsLocal();
    //     const productId = products?.purchasedProducts?.[0]?.id || '';
    //     /* --------------------------------------------- */
    //     const parsedURL = utils.parseUrl(_smilUrl);

    //     const queryParams = utils.parse(parsedURL.search) || {};
    //     queryParams.format = 'SMIL';
    //     queryParams.formats = currentPlaybackFormat;
    //     queryParams.tracking = true;
    //     queryParams.clientId = uuid;
    //     queryParams.productId = productId;
    //     queryParams.userId = userInfo?.userId || '';
    //     queryParams.mediaId = mediaId;

    //     const queryString = utils.stringify(queryParams)
    //     _smilUrl = `${baseUrl}${VERSION.smilPlayback}/playback/${mediaId}?${queryString}`

    //     /* --------------------------------------------- */
    //     setReleaseUrl(_smilUrl)
    //     /* --------------------------------------------- */
    //     dispatch({ type: "RELEASE_URL", value: _smilUrl })
    //     /* --------------------------------------------- */
    //     getParsedSMIL(_smilUrl).then((res) => {
    //         if (!isMounted) return
    //         if (!res) return _onCrash({}, { error: res })
    //         if (res.getElementsByTagName('parsererror').length > 0) return _onCrash({}, { error: res })
    //         /* --------------------------------------------- */
    //         updatesmilContent(res)
    //     }).catch((error = {}) => {
    //         if (!isMounted) return
    //         error = error || {}
    //         const { data = {} } = error.response || {}
    //         let { message = '' } = data || {}
    //         if (!message) message = error.message || ''
    //         _onCrash({}, { error, message })
    //     })

    //     return () => {
    //         isMounted = false
    //     }
    // }, [smilUrl, dispatch, _onCrash, getParsedSMIL, setReleaseUrl, currentPlaybackFormat, refreshCallState, userInfo.userId, mediaId, baseUrl])
    /* --------------------------------------------- */
    /**
     * It: Sets encrypted state.
     */
    useEffect(() => {
        if (smilContent  && smilContent?.querySelector) {
            /* --------------------------------------------- */
            const securityTag = smilContent?.querySelector('ref[security]')
            const isEncrypted = securityTag && securityTag.getAttribute('security')
            setIsEncrypted(isEncrypted)
        }
    }, [smilContent])
    /* --------------------------------------------- */
    /**
     * It: Show/hide thumbnail.
     */
    useEffect(() => {
        if (!smilContent) return
        /* --------------------------------------------- */
        // const isExceptionParam = smilContent.querySelector('param[name=\'isException\']')
        // const exceptionParam = smilContent.querySelector('param[name=\'exception\']')
        // const responseCodeParam = smilContent.querySelector('param[name=\'responseCode\']')
        const thumbnailUrlTag = smilContent.querySelector('imagestream')
        const trackingDataParam = smilContent.querySelector('param[name=\'trackingData\']')
        /* --------------------------------------------- */
        // const isException = isExceptionParam && isExceptionParam.getAttribute('value')
        // const exception = exceptionParam && exceptionParam.getAttribute('value')
        // const responseCode = responseCodeParam && responseCodeParam.getAttribute('value')
        const thumbnailUrl = thumbnailUrlTag && thumbnailUrlTag.getAttribute('src')
        let trackingDataValue = trackingDataParam && trackingDataParam.getAttribute('value')
        trackingDataValue = trackingDataValue && trackingDataValue.split('|')
        let pid = null
        if (trackingDataValue) {
            for (let i = 0; i < trackingDataValue.length; i++) {
                const t = trackingDataValue[i].split('=')
                if (t[0] === 'pid') {
                    pid = t[1]; break;
                }
            }
        }
        updatePid(pid)
        if (onPIDChange) onPIDChange(pid)
        if (thumbnailUrl) {
            dispatch({ type: 'THUMBNAIL_URL', data: thumbnailUrl })
            dispatch({ type: 'SHOW_THUMBNAIL', value: true })
        } else {
            dispatch({ type: 'SHOW_THUMBNAIL', value: false })
        }
        /* --------------------------------------------- */
        // let xmlStr = null
        // try {
        //     const serializer = new window.XMLSerializer();
        //     xmlStr = serializer.serializeToString(smilContent);
        // } catch (e) { }

        /* --------------------------------------------- */
        // if (isException === 'true') {
        //     if (exception === 'ConcurrencyLimitViolation') onConcurrencyLimitViolation({ smilContent: xmlStr })
        //     else if (exception === 'GeoLocationBlocked') onGeoLocationBlocked({ smilContent: xmlStr })
        //     else if (exception === 'AnonymousProxyBlocked') onPlayerVpnBlocked({ smilContent: xmlStr })
        //     else if (exception === 'LicenseNotGranted' || responseCode === '401') onLicenseNotGranted({ smilContent: xmlStr })
        //     else if (exception === 'UnknownFormat') onUnknowFormat({ smilContent: xmlStr })
        //     else if (exception === 'NoAssetTypeFormatMatches') onUnknowFormat({ smilContent: xmlStr })
        //     else if (responseCode === '403') onAccessDenied({ smilContent: xmlStr })
        //     else _onCrash({}, { smilContent: xmlStr })
        //     return
        // }
        /* --------------------------------------------- */
        const video = smilContent.getElementsByTagName('video')[0]
        // const param = smilContent.getElementsByTagName('param')[1]
        const param = smilContent.querySelector('param[name=\'token\']')
        let m3u8 = video && video.getAttribute('src')
        let token = param && param.getAttribute('value') 
        dispatch({ type: "PLAYBACK_DRM_TOKEN", value: token })
        /* --------------------------------------------- */
        // m3u8 = 'https://cdn.theoplayer.com/video/elephants-dream/playlist-single-audio.m3u8' // HLS subtitle
        // m3u8 = 'https://demo.unified-streaming.com/video/tears-of-steel/tears-of-steel-multiple-subtitles.ism/.mpd' // SHAKA subtitle
        // m3u8 = 'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8' // subtitle
        // m3u8 = 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8' // Shaka/Hls subtitle
        /* --------------------------------------------- */
        // if(!retry) {
            if (m3u8) updateUrl(m3u8)
        // }
    }, [smilContent, onConcurrencyLimitViolation, onGeoLocationBlocked, onPlayerVpnBlocked, onLicenseNotGranted, onUnknowFormat, onAccessDenied, _onCrash, dispatch, onPIDChange])
    
    
    useEffect(()=>{
        if(retry) {
            if(vidurl) updateUrl(vidurl)
        }
    },[retry, vidurl])

    /* --------------------------------------------- */
    /**
     * It: Sets cast data.
     */
    useEffect(() => {
        if (!isCastConnectedStatus) return

        if (smilContent  && smilContent?.querySelector) {

        /* --------------------------------------------- */
        const lang = getAppLanguageWithoutRegion()
        const userInfo = userInfoRef.current
        const userId = userInfo.userId
        const progress = getCurrentTime() || 0
        globalDispatch({
            type: 'CAST_DATA',
            data: {
                baseUrl,
                mediaId,
                userId,
                token: userTokenRef.current || '',
                lang,
                autoplay: globalAutoplay,
                castConfigPlaybackFormat: castConfigPlaybackFormat,
                isLinear: isLivePlayer,
                senderPlatform: "web",
                progress
            }
        })
        /* --------------------------------------------- */
        //sending info log on cast initiated  
        log({
            type: 'Info', attr: {
                module: 'Cast',
                debug_message: 'Cast initiated'
            }
        })
        /* --------------------------------------------- */
        history.goBack()
        history.push('/')
    }
    }, [
        history,
        trailer,
        baseUrl,
        mediaId,
        smilContent,
        smilUrl,
        isLivePlayer,
        title,
        log,
        globalDispatch,
        isCastConnectedStatus,
        currentPlaybackFormat,
        castConfigPlaybackFormat,
        globalAutoplay,
        seasonNumber,
        getCurrentTime
    ])

    // const componentCleanup = useCallback((concurrencyLockTimer, concurrencyServiceUrl, uuid, lockId, lockSequenceToken, lock, releaseUrl) => {
    //     clearInterval(concurrencyLockTimer)
    //     /* --------------------------------------------- */
    //     let updateRequestUrl = `${concurrencyServiceUrl}/web/Concurrency/unlock?form=json&schema=1.0&`
    //     if (uuid) updateRequestUrl += `_clientId=${window.encodeURIComponent(uuid)}&`
    //     if (lockId) updateRequestUrl += `_id=${window.encodeURIComponent(lockId)}&`
    //     if (lockSequenceToken) updateRequestUrl += `_sequenceToken=${window.encodeURIComponent(lockSequenceToken)}&`
    //     if (lock) updateRequestUrl += `_encryptedLock=${window.encodeURIComponent(lock)}&`
    //     /* --------------------------------------------- */
    //     getData(updateRequestUrl).catch(() => { })
    //     /* --------------------------------------------- */
    //     log({
    //         type: 'Info', attr: {
    //             module: 'Concurrency',
    //             playback: {
    //                 playback_action: 'concurrency_unlock',
    //                 source_url: releaseUrl,
    //                 concurrency_url: updateRequestUrl,
    //                 content_title: title,
    //                 content_id: mediaId,
    //             }
    //         }
    //     })
    // },[getData, log, mediaId, title])
    /* --------------------------------------------- */
    /**
     * It: Calls concurrency lock api.
     * It: Calls concurrency unlock api.
     */
    // useEffect(() => {
    //     if (smilContent  && smilContent?.querySelector) {
    //     /* --------------------------------------------- */
    //     const concurrencyServiceUrlTag = smilContent?.querySelector('meta[name=\'concurrencyServiceUrl\']')
    //     const concurrencyServiceUrl = concurrencyServiceUrlTag && concurrencyServiceUrlTag.getAttribute('content')
    //     /* --------------------------------------------- */
    //     if (!concurrencyServiceUrl) return
    //     /* --------------------------------------------- */
    //     const uuid = _uuid.current
    //     /* --------------------------------------------- */
    //     const releaseUrl = getReleaseUrl()
    //     /* --------------------------------------------- */
    //     const updateLockIntervalTag = smilContent?.querySelector('meta[name=\'updateLockInterval\']')
    //     const lockIdTag = smilContent?.querySelector('meta[name=\'lockId\']')
    //     const lockSequenceTokenTag = smilContent?.querySelector('meta[name=\'lockSequenceToken\']')
    //     const lockTag = smilContent?.querySelector('meta[name=\'lock\']')
    //     /* --------------------------------------------- */
    //     const updateLockInterval = updateLockIntervalTag && updateLockIntervalTag.getAttribute('content')
    //     let lockId = lockIdTag && lockIdTag.getAttribute('content')
    //     let lockSequenceToken = lockSequenceTokenTag && lockSequenceTokenTag.getAttribute('content')
    //     let lock = lockTag && lockTag.getAttribute('content')
    //     window.addEventListener('beforeunload', () => componentCleanup(concurrencyLockTimer, concurrencyServiceUrl, uuid, lockId, lockSequenceToken, lock, releaseUrl));

    //     /* --------------------------------------------- */
    //     const makeUpdateRequest = () => {
    //         // if (this.state.isReplayScreen) return null // video fully played
    //         let updateRequestUrl = `${concurrencyServiceUrl}/web/Concurrency/update?form=json&schema=1.0&`
    //         if (uuid) updateRequestUrl += `_clientId=${window.encodeURIComponent(uuid)}&`
    //         if (lockId) updateRequestUrl += `_id=${window.encodeURIComponent(lockId)}&`
    //         if (lockSequenceToken) updateRequestUrl += `_sequenceToken=${window.encodeURIComponent(lockSequenceToken)}&`
    //         if (lock) updateRequestUrl += `_encryptedLock=${window.encodeURIComponent(lock)}&`
    //         /* --------------------------------------------- */
    //         getData(updateRequestUrl)
    //             .then((res = {}) => {
    //                 if (!res.updateResponse) return
    //                 /* --------------------------------------------- */
    //                 const { encryptedLock, id, sequenceToken } = res.updateResponse
    //                 /* --------------------------------------------- */
    //                 lock = encryptedLock
    //                 lockId = id
    //                 lockSequenceToken = sequenceToken
    //             }).catch(() => { })
    //         /* --------------------------------------------- */
    //         log({
    //             type: 'Info', attr: {
    //                 event: 'playback',
    //                 module: 'Concurrency',
    //                 playback: {
    //                     playback_action: 'concurrency_update',
    //                     source_url: releaseUrl,
    //                     concurrency_url: updateRequestUrl,
    //                     content_title: title,
    //                     content_id: mediaId,
    //                 }
    //             }
    //         })
    //     }
    //     /* --------------------------------------------- */
    //     const concurrencyLockTimer = setInterval(() => makeUpdateRequest(), Number(updateLockInterval) * 1000)
    //     /* --------------------------------------------- */
    //     return () => {
    //         window.removeEventListener('beforeunload', componentCleanup(concurrencyLockTimer, concurrencyServiceUrl, uuid, lockId, lockSequenceToken, lock, releaseUrl)); // remove the event handler for normal unmounting
    //         clearInterval(concurrencyLockTimer)
    //         /* --------------------------------------------- */
    //         // let updateRequestUrl = `${concurrencyServiceUrl}/web/Concurrency/unlock?form=json&schema=1.0&`
    //         // if (uuid) updateRequestUrl += `_clientId=${window.encodeURIComponent(uuid)}&`
    //         // if (lockId) updateRequestUrl += `_id=${window.encodeURIComponent(lockId)}&`
    //         // if (lockSequenceToken) updateRequestUrl += `_sequenceToken=${window.encodeURIComponent(lockSequenceToken)}&`
    //         // if (lock) updateRequestUrl += `_encryptedLock=${window.encodeURIComponent(lock)}&`
    //         // /* --------------------------------------------- */
    //         // getData(updateRequestUrl).catch(() => { })
    //         // /* --------------------------------------------- */
    //         // log({
    //         //     type: 'Info', attr: {
    //         //         module: 'Concurrency',
    //         //         playback: {
    //         //             playback_action: 'concurrency_unlock',
    //         //             source_url: releaseUrl,
    //         //             concurrency_url: updateRequestUrl,
    //         //             content_title: title,
    //         //             content_id: mediaId,
    //         //         }
    //         //     }
    //         // })
    //     }
    // }
    // }, [getData, smilContent, smilUrl, title, log, mediaId, getReleaseUrl, componentCleanup])
    /* --------------------------------------------- */
    /**
     * It: Fetches fairplay certificate.
     */
    useEffect(() => {
        let isMounted = true
        if (!isEncrypted) return
        if (browser.name !== 'Safari') return
        /* --------------------------------------------- */
                if (!isMounted) return
                setFairplayCertificate(null)
        return () => {
            isMounted = false
        }
    }, [isEncrypted, _onCrash])

    /* --------------------------------------------- */
    return (
        <>
            {!isPlayerError && url && <ShakaPlayer ref={playerRef} {...{
                url, onLoad, onBuffer, onBufferComplete, onPlay, onPause, onLargeGap,
                uuid: _uuid.current,
                onCurrentTimeChange, title, mediaId, playbackAssetFormat,
                onComplete: _onComplete, onCrash: _onCrash, onUnload, pid, fairplayCertificate,
                qualityMapping, qualityMappingMode, abrMode, isEncrypted, debugModeEnabled, isShakaPlayerMount,
                isMuted, currentProgram
            }} />}
        </>
    );
})
/* --------------------------------------------- */
PlayerSwitcher.propTypes = {
    /* smilUrl for playback. */
    smilUrl: PropTypes.string,
    /* This callback gets triggered when player is ready for playback.  */
    onLoad: PropTypes.func,
    /* This callback gets triggered when player buffers.  */
    onBuffer: PropTypes.func,
    /* This callback gets triggered when player completes buffering.  */
    onBufferComplete: PropTypes.func,
    /* This callback gets triggered when playback plays.  */
    onPlay: PropTypes.func,
    /* This callback gets triggered when playback pauses.  */
    onPause: PropTypes.func,
    /* This callback gets triggered on every seek.  */
    onCurrentTimeChange: PropTypes.func,
    /* This callback gets triggered when playback completes.  */
    onComplete: PropTypes.func,
    /* This callback gets triggered when player crashes.  */
    onCrash: PropTypes.func,
    /* This callback gets triggered when recieved concurrency limit error.  */
    onConcurrencyLimitViolation: PropTypes.func,
    /* This callback gets triggered when recieved license not granted error.  */
    onLicenseNotGranted: PropTypes.func,
    /* This callback gets triggered when recieved unknow format error.  */
    onUnknowFormat: PropTypes.func,
    /* This callback gets triggered when recieved geolocation blocked error.  */
    onGeoLocationBlocked: PropTypes.func,
    /* This callback gets triggered when recieved vpn proxy blocked error.  */
    onPlayerVpnBlocked: PropTypes.func,
    /* This callback gets triggered when recieved access denied error.  */
    onAccessDenied: PropTypes.func,
    /* This callback gets triggered when subtitle text changes.  */
    // onSubtitleTextChange: PropTypes.func,
    /* This callback gets triggered when player is destroyed.  */
    onUnload: PropTypes.func
}
/* --------------------------------------------- */
PlayerSwitcher.defaultProps = {
    smilUrl: '',
    onLoad: () => { },
    onBuffer: () => { },
    onBufferComplete: () => { },
    onPlay: () => { },
    onPause: () => { },
    onCurrentTimeChange: () => { },
    onComplete: () => { },
    onCrash: () => { },
    onConcurrencyLimitViolation: () => { },
    onLicenseNotGranted: () => { },
    onUnknowFormat: () => { },
    onGeoLocationBlocked: () => { },
    onPlayerVpnBlocked: () => { },
    onAccessDenied: () => { },
    // onSubtitleTextChange: () => { },
    onUnload: () => { }
}
/* --------------------------------------------- */
export default withRouterInnerRef(PlayerSwitcher);
/* --------------------------------------------- */
