/** React & Redux imports */
import React, { Suspense, lazy, useEffect, useContext } from 'react'
import { Route, BrowserRouter, Switch, useLocation, useHistory, Redirect } from 'react-router-dom'
/* --------------------------------------------- */
/** Bugsnag imports */
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
/* --------------------------------------------- */
/** Firebase imports */
import firebase from 'firebase/app'
import "firebase/performance"
/* --------------------------------------------- */
/** Loggly imports */
import { _LTracker } from 'loggly-jslogger';
/* --------------------------------------------- */
/** Route imports */
import { routeMap } from './route'
/* --------------------------------------------- */
/** Context imports */
import GlobalProvider, { GlobalContext } from './context/global.context'
import GlobalNewProvider from './context/global.new.context'
import ErrorProvider from './context/error.context'
import NavigationProvider from './context/navigation.context'
import MiscsProvider from './context/miscs.context'
/* --------------------------------------------- */
/** Hooks imports */
import useEngage from './hooks/useEngage'
import { firebaseTrace } from './hooks/useFirebaseTrace'
/* --------------------------------------------- */
/** Utils imports */
import utils from './utils/utils'
import { checkStaticPage } from './utils/contentfulHandler'
// import { getStateUUID, removeStateUUID } from './utils/sessionStorageService'
/* --------------------------------------------- */
/** Other imports */
import ScrollMemory from './components/ScrollMemory';
import uuidv1 from 'uuid/v1'
/* --------------------------------------------- */
/** Config imports */
import {
  bugsnagConfig, firebaseConfig, logglyConfig,
  isLocalHost, version, appName
} from './config'
/* --------------------------------------------- */
/** CSS imports */
import './styles/components/App.scss'
import './styles/main.scss'
/* --------------------------------------------- */
/** Components imports */
import StaticPagePerformanceAnalyser from './components/Helper/StaticPagePerformanceAnalyser'
import Loader from './components/Loader'
import ContentfulLoader from './components/ContentfulLoader'
import BodyScroll from './components/BodyScroll'
import BugsnagLoader from './components/BugsnagLoader'
import Header from './components/Header'
import AppBG from './components/AppBG'
import ErrorContainer from './components/errors'
import GetAppPopup from './components/GetAppPopup'
import SettingsPopup from './components/SettingsPopup'
import GetAppMobilePopup from './components/GetAppMobilePopup'
import CountrycodePopup from './components/CountrycodePopup'
import Spinner from './components/Spinner'
import SplashPage from './components/pages/SplashPage'
import ProtectedRoute from './components/ProtectedRoute'

import Footer from './components/Footer'
// import ScrollToTop from './components/ScrollToTop'
import InitiateLogin from './components/pages/InitiateLogin'
import LoginPage from './components/pages/LoginPage'
import InitiateLoginRedirect from './components/pages/InitiateLoginRedirect'
import ContactUsFeebackForm from './components/pages/ContactUsFeebackForm'

/* --------------------------------------------- */
/** Container imports */
import AppBoundry from './containers/AppBoundry'
import IntroPopup from './components/pages/IntroPopup/IntroPopup'
import TimeModificationHandler from './components/TimeModificationHandler'
import MetaDataPreviewContainer from './components/MetaDataPreview/MetaDataPreviewContainer'
import LanguagePopup from './components/LanguagePopup'
import VerifyAccount from './components/pages/VerifyAccount/verifyAccount'

/* --------------------------------------------- */
/** Lazy Load Components */
const ConditionalInitChromecast = lazy(() => retry(() => import('./components/chromeCast/ConditionalInitChromecast')));
const ChromeCastMiniController = lazy(() => retry(() => import('./components/chromeCast/ChromeCastMiniController')));

/* --------------------------------------------- */
/** Static Components imports */
const Faq = lazy(() => retry(() => import('./components/pages/Faq')));
const ContactUs = lazy(() => retry(() => import('./components/pages/ContactUs')));
const TermsOfUse = lazy(() => retry(() => import('./components/pages/TermsOfUse')));
const PrivacyPolicy = lazy(() => retry(() => import('./components/pages/PrivacyPolicy')));
const AuthContainer = lazy(() => retry(() => import('./containers/AuthContainer')));
/* --------------------------------------------- */
/** Non Static Components imports */
const MyAccount = lazy(() => retry(() => import('./components/pages/MyAccount')));
const PlayerPage = lazy(() => retry(() => import(/* webpackPrefetch: true */'./components/pages/PlayerPage')));
const SignUpPage = lazy(() => retry(() => import('./components/pages/SignUpPage')));
const AccountInfo = lazy(() => retry(() => import('./components/pages/AccountInfo')));
const ListingPage = lazy(() => retry(() => import('./components/pages/ListingPage')));
const CatalogPage = lazy(() => retry(() => import(/* webpackPrefetch: true */'./components/pages/CatalogPage')));
const ProfileEdit = lazy(() => retry(() => import('./components/pages/ProfileEdit')));
const ChannelGuide = lazy(() => retry(() => import('./components/pages/ChannelGuide')));
const ResetPassword = lazy(() => retry(() => import('./components/pages/ResetPassword')));
const ForgotPassword = lazy(() => retry(() => import('./components/pages/ForgotPassword')));
const PaymentHistory = lazy(() => retry(() => import('./components/pages/PaymentHistory')));
const Subscription = lazy(() => retry(() => import('./components/pages/Subscription')));
const ChangePassword = lazy(() => retry(() => import('./components/pages/ChangePassword')));
const FavouritesPage = lazy(() => retry(() => import('./components/pages/FavouritesPage')));
const PurchaseDetailsPage = lazy(() => retry(() => import('./components/InAppPayment/PurchaseDetailsPage')));
const OrderProcessing = lazy(() => retry(() => import('./components/InAppPayment/OrderProccessing')));
const DetailsPageContainer = lazy(() => retry(() => import(/* webpackPrefetch: true */'./containers/DetailsPageContainer')));
const MultiProfile = lazy(() => retry(() => import('./components/MultiProfileManagement/MultiProfile')));
const TVLogin = lazy(() => retry(() => import('./components/pages/TVLogin')));
const LandingPage = lazy(() => retry(() => import('./components/pages/LandingPage')));

/* --------------------------------------------- */
/** ErrorBoundary component */
let ErrorBoundary = (props) => <>{props.children}</>
/* --------------------------------------------- */
/** Generate sessionId */
const sessionId = uuidv1()
/* --------------------------------------------- */
/** Bugsnag initialization */
if (bugsnagConfig.apiKey) {
  Bugsnag.start({
    ...bugsnagConfig,
    plugins: [new BugsnagPluginReact()],
    metadata: {
      bugsnagSession: {
        Id: sessionId
      }
    }
  })
  /* --------------------------------------------- */
  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
}
/* --------------------------------------------- */
/**
 * Bugsnag issues of firebase
 * https://diagnal.atlassian.net/browse/DC-4475
 * https://github.com/firebase/firebase-js-sdk/issues/2710
 * https://github.com/firebase/firebase-js-sdk/issues/1926
 * Upgraded to v8.1.2 to resolve the issue.
 */
/** Firebase initialization */
if (firebaseConfig.apiKey) {
  firebase.initializeApp(firebaseConfig)
  window.appLaunchTrace = firebaseTrace('app_launch', {
    isLoggedIn: 'false',
    sessionId: sessionId
  })
}
/* --------------------------------------------- */
/** Loggly initialization */
let logger = null
if (logglyConfig.id && _LTracker) {
  logger = _LTracker || [];
  logger.setSession(sessionId)
  logger.push({
    'logglyKey': logglyConfig.id,
    'tag': `${appName.replace(/ /g, "_") || ''}-web-${version.env || ''}`,
    'useUtfEncoding': true
  });
}
/* --------------------------------------------- */
/** LogoutCallback component */
const LogoutCallback = () => {
  const history = useHistory()

  useEffect(() => {
    history.push('/')
  }, [history])

  return <></>
}
/* --------------------------------------------- */
/** Callback component */
const Callback = () => {
  const { dispatch: globalDispatch, initiateLogin } = useContext(GlobalContext)
  /* --------------------------------------------- */
  const history = useHistory()
  const location = useLocation()



  useEffect(() => {
    const query = utils.parse(location.search)
    if (query.action === 'logout') {
      globalDispatch({ type: 'RENDERED_LANDING', value: true })
      history.push('/')
    }
    else {
      // SSO callback
      globalDispatch({ type: 'RENDERED_LANDING', value: true })
      globalDispatch({ type: 'INITIATE_LOGIN', value: true })
    }
  }, [location.search, history, globalDispatch])

  if (initiateLogin) {
    return <InitiateLogin />
  }
  return <></>
}
/* --------------------------------------------- */
/**
 * Hide console.log statements in non-dev builds.
 */
if (!isLocalHost && version.buildType !== 'dev') {
  console.log = () => { };
}
/* --------------------------------------------- */
const query = utils.parse(window.location.search) || {}
const platform = query.platforms || 'web'
/* --------------------------------------------- */
/** SuspenseFallback component */
const SuspenseFallback = () => {
  const { isShowSplash, isInitialAppLoad, dispatch } = useContext(GlobalContext)
  const changeInitalAppLoadState = () => {
    return () => {
      if(isInitialAppLoad) {
        dispatch({ type: "INITIAL_APP_LOAD", value: false })
      }
    }
  }
  useEffect(changeInitalAppLoadState,[])
  let showFallback = true;
  if (isShowSplash) showFallback = false;
  if (platform === 'web' && showFallback && !isInitialAppLoad) return <Spinner size={50} />
  return null;
}
/* --------------------------------------------- */
const retry = (fn, retriesLeft = 3, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
/* --------------------------------------------- */
/** App component */
const App = () => {
  /** Initialize engage */
  useEngage()
  /* --------------------------------------------- */
  /** Check if static page */
  const isStaticPage = checkStaticPage()
  /* --------------------------------------------- */
  return (
    <ErrorBoundary>
      <GlobalProvider {...{ logger, sessionId }}>
        <GlobalNewProvider>
          <MiscsProvider>
            <BodyScroll />
            <BugsnagLoader />
            <Loader />
            <ErrorProvider>
              <ContentfulLoader />
              <TimeModificationHandler />
              {isStaticPage && (
                <>
                  <ErrorContainer />
                  <Suspense fallback={<SuspenseFallback />}>
                    <BrowserRouter>
                      <Switch>
                        <Route exact path={routeMap.contactUs} render={props => <StaticPagePerformanceAnalyser key={props.location.pathname + props.location.search} {...props}><ContactUs /></StaticPagePerformanceAnalyser>} />
                        <Route exact path={routeMap.faq} render={props => <StaticPagePerformanceAnalyser key={props.location.pathname + props.location.search} {...props}><Faq /></StaticPagePerformanceAnalyser>} />
                        <Route exact path={routeMap.tos} render={props => <StaticPagePerformanceAnalyser key={props.location.pathname + props.location.search} {...props}><TermsOfUse /></StaticPagePerformanceAnalyser>} />
                        <Route exact path={routeMap.pp} render={props => <StaticPagePerformanceAnalyser key={props.location.pathname + props.location.search} {...props}><PrivacyPolicy /></StaticPagePerformanceAnalyser>} />
                        <Route exact path='/auth/:type/:mode' render={() => <AuthContainer />} />
                        <Route exact path='/callback' render={() => <Callback />} />
                        <Route exact path='/logout' render={() => <LogoutCallback />} />
                        <Route exact path='/initiateLogin' render={() => <InitiateLoginRedirect />} />
                        <Route path='/feedback' render={() => <ContactUsFeebackForm />} />
                      </Switch>
                    </BrowserRouter>
                  </Suspense>
                </>
              )}
              {!isStaticPage && (
                <BrowserRouter>
                    <NavigationProvider>
                      <Header {...{ isForceShowLogo: true, isShowTitle: true }} />
                      <AppBG>
                        <SettingsPopup />
                        <CountrycodePopup />
                        <LanguagePopup />
                        <ErrorContainer />
                        <GetAppPopup />
                        <GetAppMobilePopup />
                        <Spinner size={50} IsLoading isUseGlobalState />
                        <IntroPopup />
                        <SplashPage />
                        <Suspense fallback={<SuspenseFallback />}>
                          {/* <LandingPage /> */}
                          <MultiProfile />
                          <ScrollMemory />
                          <AppBoundry />
                          <MetaDataPreviewContainer/>
                          <Switch>
                            {/** Account Routes */}
                            <Route exact path='/getting-started' render={props => <LandingPage />} />
                            <Route exact path={routeMap.login} render={props => <ProtectedRoute component={LoginPage} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.signup} render={props => <ProtectedRoute component={SignUpPage} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.verifyAccount} render={props => <ProtectedRoute component={VerifyAccount} key={props.location.pathname + props.location.search} {...props} />} />

                            <Route exact path={routeMap.forgotPassword} render={props => <ProtectedRoute component={ForgotPassword} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.resetPassword} render={props => <ProtectedRoute component={ResetPassword} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.changePassword} render={props => <ProtectedRoute component={ChangePassword} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.editProfile} render={props => <ProtectedRoute component={ProfileEdit} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.accountInfo} render={props => <ProtectedRoute component={AccountInfo} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.myAccount} render={props => <ProtectedRoute component={MyAccount} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.tvLogin} render={props => <ProtectedRoute component={TVLogin} key={props.location.pathname + props.location.search} {...props} />} />

                            {/** Payment Routes */}
                            <Route exact path={routeMap.paymentHistory} render={props => <ProtectedRoute component={PaymentHistory} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.subscription} render={props => <ProtectedRoute component={Subscription} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.payment} render={props => <ProtectedRoute component={PurchaseDetailsPage} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.orderStatus} render={props => <ProtectedRoute component={OrderProcessing} key={props.location.pathname + props.location.search} {...props} />} />

                            {/** Media Routes */}
                            <Route exact path={routeMap.details} render={props => <ProtectedRoute component={DetailsPageContainer} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.listing} render={props => <ProtectedRoute component={ListingPage} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.favourites} render={props => <ProtectedRoute component={FavouritesPage} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.player} render={props => <ProtectedRoute component={PlayerPage} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={routeMap.tvGuide} render={props => <ProtectedRoute component={ChannelGuide} key={props.location.pathname + props.location.search} {...props} />} />
                            <Route exact path={['/', routeMap.catalog]} render={props => <ProtectedRoute component={CatalogPage} key={props.location.pathname + props.location.search} {...props} />} />
                            {/* Other Routes */}
                            <Route exact path='*' render={() => <Redirect to='/' />} />
                          </Switch>
                          <ConditionalInitChromecast>
                            <ChromeCastMiniController />
                          </ConditionalInitChromecast>
                        </Suspense>
                      </AppBG>
                      <Footer />
                    </NavigationProvider>
                </BrowserRouter>
              )}
            </ErrorProvider>
          </MiscsProvider>
        </GlobalNewProvider>
      </GlobalProvider>
    </ErrorBoundary>
  )
}
/* --------------------------------------------- */
export default App
