import React from 'react'
import PropTypes from 'prop-types'
/* --------------------------------------------- */
const PreviousBtnFilledIcon = ({ color, next, ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 30" fill="none" style={{ width: 30, height: 30 }} {...rest} >
    <path
    fill={color} fill-rule="evenodd" clip-rule="evenodd" d="M23.421 29.7864C23.5916 29.9279 23.8025 30.0001 24.0153 30.0001C24.1513 30.0001 24.2882 29.9711 24.4166 29.9101C24.7438 29.7554 24.9528 29.4254 24.9528 29.0626V0.937634C24.9528 0.574822 24.7438 0.244822 24.4166 0.0901345C24.0875 -0.064553 23.7003 -0.017678 23.421 0.213884L6.31159 14.2764C6.09409 14.4536 5.96847 14.7198 5.96847 15.0001C5.96847 15.2804 6.09409 15.5467 6.31159 15.7239L23.421 29.7864ZM0.34375 29.0626C0.34375 29.5811 0.76375 30.0001 1.28125 30.0001C1.79875 30.0001 2.21875 29.5811 2.21875 29.0626V0.937634C2.21875 0.419197 1.79875 0.000134488 1.28125 0.000134488C0.76375 0.000134488 0.34375 0.419197 0.34375 0.937634V29.0626Z"
    />
  </svg>

)
/* --------------------------------------------- */
PreviousBtnFilledIcon.propTypes = {
  /** Color of icon. */
  color: PropTypes.string,
  /** Icon css styles. */
  style: PropTypes.object
}
/* --------------------------------------------- */
PreviousBtnFilledIcon.defaultProps = {
  color: 'transparent',
  style: {
    width: 25,
    height: 25
  }
}
/* --------------------------------------------- */
export default PreviousBtnFilledIcon
/* --------------------------------------------- */
