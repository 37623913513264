import React from 'react'
import ErrorSnackbar from './ErrorSnackbar'
import Provider from './context'
import { Wrapper } from './style'
/* ---------------------------------------------------------------------------------------------------- */
// Wrapper component for ErrorSnackbar component.
const ErrorPageWrapper = () => {
    return (
        <Provider>
            <Wrapper>
                <ErrorSnackbar />
            </Wrapper>
        </Provider>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
export default ErrorPageWrapper
/* ---------------------------------------------------------------------------------------------------- */
