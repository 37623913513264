import React, { useContext, useCallback, useEffect, useState, useRef } from 'react';
import SliderRTL from 'react-slick-pnth'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import Slider from "react-slick";
import styled from 'styled-components';
import { useHistory } from "react-router-dom";
import { version } from '../../../config';
import { GlobalContext } from '../../../context/global.context';
import { getGraphics } from '../../../utils/contentfulThemeMerger';
import utils from '../../../utils/utils';
import RightArrowIconForIntro from '../../icons/RightArrowIconForIntro';
/* --------------------------------------------- */
import { routeMap } from '../../../route'
import { splitLanguage } from '../../../utils/localStorageService';
import useDetailsApi from '../../../apis/useDetailsApi';
import { setProductsLocal } from '../../../utils/sessionStorageService';
/* --------------------------------------------- */

let setTimeoutIntroNext = false;

const IntroPopup = props => {
    let setTimeOutTracker = false
    const introConfig = useSelector(store => store.featuresReducers.introConfig)
    const { introContent = [], allowSkip = true, showIntro = '', showStrategy = [], showProgressIndicator = true, allowBackNavigation = true } = introConfig;
    const { introThemeConfig = [] } = useSelector(store => store.themeConfigReducers)
    const { appMessages } = useSelector(store => store.appConfigReducers)
    const [showIntroPopUp, setShowIntroPopUp] = useState(false)
    const [isIntroPopUpShownOnce, setIntroPopUpShownOnce] = useState(false)
    const [activeItem, setActiveItem] = useState(0)
    const { hasUserNavigatedFromLoginPage, isUserLoginRefresh, dispatch: globalDispatch, isAuthenticated, uiDirection, appLanguage, userInfo } = useContext(GlobalContext); //isAuthenticated
    const sliderRef = useRef(null)
    const isFirstLoad = useRef(null)
    const introPopupRef = useRef(null)
    const location = useLocation()
    const history = useHistory();
    const [filteredContent, setFilteredContent] = useState([])
     const { getProducts } = useDetailsApi();
     const { appConfig } = useSelector(store => store.appConfigReducers)

    // const history = useHistory()

    const sliderConf = {
        speed: 1000,
        initialSlide: 0,
        slidesToScroll: 1,
        rtl: (uiDirection === "rtl") ? true : false,
        // is used to move the slides in sync with the animation
        beforeChange: (prevI, i) => {
            if (setTimeOutTracker) return
            setTimeOutTracker = setTimeout(() => {
                setActiveItem(i);
                setTimeOutTracker = false;
            }, 1000)

        },
    }

    // Show intro popup ad update version
    const show = () => {
        const location = decodeURIComponent(window?.location?.pathname);
        if(location === '/' || location === '/home' || location === '/getting-started') {
            setShowIntroPopUp(true)
        }
        localStorage.version = version.appVersion
    }

    const showPopup = () => {
        const location = decodeURIComponent(window?.location?.pathname);
        if(location === '/login' || location === '/verify-account') {
            setShowIntroPopUp(true)
        }
        localStorage.version = version.appVersion
    }

    // shows login page and hide intro pop up
    // const handleLoginClick = () => {
    //     history.push(routeMap.login)
    //     hidePopUp('GET_STARTED')
    // }

    // returns `true` if showStrategy applies on the app load.
    const checkShowStrategy = useCallback(() => {

        // if showStrategy is 'Always' then show the pop-up
        if (showStrategy.find(el => el === 'Always')) {
            return true
        }

        // if isFirstLoad is true (which is set to false forever on close), then show the pop-up 
        if (isFirstLoad.current) {
            return true
        }

        // if there is no version set in localStorage, then the app is loading for the first time.
        if (showStrategy.find(el => el === 'OnFirstLaunch') && !localStorage.version) {
            isFirstLoad.current = true; // runs only once on page load
            return true
        }

        if (showStrategy.find(el => el === 'OnAppUpdate')) {
            if (!localStorage.version) {
                isFirstLoad.current = true; // runs only once on page load
                return true
            }
            // if there is a change in version the show pop up
            if (localStorage.version !== version.appVersion) {
                return true
            }
        }
        return false
    }, [showStrategy])

    useEffect(() => {
        // case tv login page 
        if (utils.startsWith(decodeURIComponent(location?.pathname), routeMap.tvLogin)) {
            return
        }


        if (!checkShowStrategy()) return 

        // case: refresh when user is already logged in
        if (isUserLoginRefresh) {
            show()
            return
        }
        // case: user logged in from login menu
        if (hasUserNavigatedFromLoginPage && !isIntroPopUpShownOnce && isAuthenticated) {
            setIntroPopUpShownOnce(true)
            showIntro === 'OnLogin' && showPopup()
        }
        else if (!isIntroPopUpShownOnce && showIntro === 'OnSplash') {
            setIntroPopUpShownOnce(true)
            showIntro === 'OnSplash' && show()
        }
    }, [hasUserNavigatedFromLoginPage, isUserLoginRefresh, showIntro, checkShowStrategy, showStrategy, location, isIntroPopUpShownOnce, isAuthenticated])

    const changeSlide = () => {
        if (setTimeoutIntroNext) return
        setTimeoutIntroNext = setTimeout(() => {
            setTimeoutIntroNext = false
        }, 1000)
        sliderRef.current.slickNext()
        next()
    }

    const next = () => {
        setActiveItem(activeItem + 1 < filteredContent.length ? activeItem + 1 : activeItem);
    }

    const hidePopUp = (action) => {
        setShowIntroPopUp(false)
        isFirstLoad.current = null
    }

    const showDots = () => {
        if ((activeItem === filteredContent.length - 1 && !allowBackNavigation) || !showProgressIndicator) return false
        return true
    }

    /**
     * It: Enables / Disables background scrolling when popup is open
     */
     useEffect(() => {
        let contentEl = introPopupRef
        if (showIntroPopUp && filteredContent && filteredContent.length) {
            /* Disabling scroll only if popup is present. */
            utils.disableScroll(contentEl, { reserveScrollBarGap: true })
            globalDispatch({ 
                type: 'POPUP_DETAILS', 
                value: {
                    inViewport: true,
                    useMargin: utils.hasMargin()
                }
            })
        }
        else {
            utils.enableScroll(contentEl)
            globalDispatch({ 
                type: 'POPUP_DETAILS', 
                value: {
                    inViewport: false,
                    useMargin: utils.hasMargin()
                }
            })
        }
    }, [showIntroPopUp, globalDispatch, filteredContent])


    const getFilteredContent = (subscribed = true) => {
        let popupsSubsbscribedUsers = []
        let popupUnSubscribedUsers = []
        for ( let i = 0 ; i < introContent.length; i++) {
            const popUp = introContent[i];
            const subTypes =  (popUp.filter || {}).subscriptionType || [];
            if (subTypes.includes('subscribed')) {
                popupsSubsbscribedUsers = [...popupsSubsbscribedUsers, popUp];
            } else if (!subTypes.includes('anonymous')) {
                popupUnSubscribedUsers = [...popupUnSubscribedUsers, popUp]
            }
        }
        if (subscribed) {
            return popupsSubsbscribedUsers;
        }
        return popupUnSubscribedUsers;
    }


    const updateContentBasedOnSubscription = () => {
            getProducts(appConfig.baseUrl, {
                filterBy:'type',filter:'svod',
                language: splitLanguage(appLanguage),
                userId: userInfo?.userId
            }).then((res = {}) => {
                const purchasedProducts = res.purchasedProducts || []
                setProductsLocal(res); // we are no longer using setProductlist already storing plans on local storage
                if (purchasedProducts.length) {
                    setFilteredContent(getFilteredContent(true))
                    // update with -- includes subscription
                } else {
                    setFilteredContent(getFilteredContent(false))

                        // update with -- not-includes subscription
                }
            }).catch(() => {
                // setFilterCheckInProgress(false)
                setFilteredContent(getFilteredContent(false))
                     // update with -- not-includes subscription
            })
    }


    const filterContent = () => {
        if (isAuthenticated === false) {
            setFilteredContent(introContent)
        } else if (userInfo?.userId) {
            updateContentBasedOnSubscription()
        }
    }
    useEffect(filterContent, [introContent, isAuthenticated])



    if (!filteredContent.length || !showIntroPopUp || isAuthenticated === null) return <></>


    const lastPopupPage = (filteredContent.length - 1 === activeItem)
    const activePopupPage = filteredContent[activeItem] || {}
    const startButtonText = activePopupPage.ctaData || appMessages.button_intro_get_started
    const startButtonActionType = activePopupPage.ctaType;
    let startButtonAction = () => hidePopUp('GET_STARTED')
    if (startButtonActionType === 'custom') { // not-a-good idea
        startButtonAction = () => {
            hidePopUp('GET_STARTED')
            history.push(routeMap.subscription)
        };
    }

    return showIntroPopUp && filteredContent.length && <IntroPopupMask>
        <IntroWrapper className='intro-popup-wrapper' {...introThemeConfig}>
            <div className='pop-up' ref={introPopupRef}>
            {uiDirection === "rtl" ? (
               <SliderRTL dots={showDots()} ref={sliderRef} {...{ ...sliderConf, swipe: !(!allowBackNavigation && activeItem === filteredContent.length - 1) }}>
                   {filteredContent.map(({ title, description, graphics, sysId }, index) => <div key={sysId}>
                        <div className='intro-img-container'>
                            {/* <IntroImage alt={title} src={graphics[0]?.images[2].imageUrl} /> */}
                            <IntroImage alt={title} graphics={graphics} />
                        </div>
                        <div className='intro-desc-contents'>
                            <IntroTitle {...introThemeConfig} className='intro-title'>{title}</IntroTitle>
                            <IntroDescription {...introThemeConfig} className='intro-desc'>{description}</IntroDescription>
                        </div>

                    </div>)}
               </SliderRTL>
            ): 
                <Slider dots={showDots()} ref={sliderRef} {...{ ...sliderConf, swipe: !(!allowBackNavigation && activeItem === filteredContent.length - 1) }}>
                    {filteredContent.map(({ title, description, graphics, sysId }, index) => <div key={sysId}>
                        <div className='intro-img-container'>
                            {/* <IntroImage alt={title} src={graphics[0]?.images[2].imageUrl} /> */}
                            <IntroImage alt={title} graphics={graphics} />
                        </div>
                        <div className='intro-desc-contents'>
                            <IntroTitle {...introThemeConfig} className='intro-title'>{title}</IntroTitle>
                            <IntroDescription {...introThemeConfig} className='intro-desc'>{description}</IntroDescription>
                        </div>

                    </div>)}
                </Slider> 
            }
            </div>
            <div className='bottom-nav'>
                {/* <div className='intro-nav'>{filteredContent.map((el, i) => <span onClick={() => goto(i)} className={`intro-nav-ele ${activeItem === i ? ' active' : ''}`} key={i}></span>)}</div> */}
                <div className='intro-control-btns'>
                    {lastPopupPage ? 
                        (<div>
                            <div className='get-started-btn-container' onClick={startButtonAction}>
                                <GetStartedBtn {...introThemeConfig} className='get-started-btn'>{startButtonText}</GetStartedBtn>
                            </div>
                            {!isAuthenticated && <AccountLabel {...introThemeConfig} className='account-label'>{appMessages.label_intro_got_an_account}</AccountLabel>}
                            {/* {!isAuthenticated && <div className='login-details-container' onClick={handleLoginClick}>
                                <LoginButton {...introThemeConfig} className='login-btn' >{appMessages.label_forgot_password_log_in}</LoginButton>
                            </div>} */}
                            {isAuthenticated && startButtonActionType === 'custom' && appMessages.label_may_be_later && <TertiaryButton className='maybe-btn' {...introThemeConfig} uiDirection={uiDirection}>
                                <span className='maybe-later' onClick={() => { setShowIntroPopUp(false); isFirstLoad.current = null }} >{appMessages.label_may_be_later}</span>
                            </TertiaryButton>}
                        </div>
                        ):
                        <>
                            <SecondaryButton className='next-btn'  {...introThemeConfig } uiDirection={uiDirection} >
                                <span onClick={changeSlide} className='next-label'>{appMessages.label_intro_next}</span>
                                <span onClick={changeSlide} className='right-arrow-icon'>
                                    <RightArrowIconForIntro color={utils.isNotNull(introThemeConfig, 'compositeStyle', 'secondaryButton', 'normal', 'text') ? introThemeConfig.compositeStyle.secondaryButton.normal.text : ''} />
                                </span>
                            </SecondaryButton>
                            {allowSkip && <TertiaryButton className='skip-btn' {...introThemeConfig} uiDirection={uiDirection}>
                                <span className='skip-label' onClick={() => { setShowIntroPopUp(false); isFirstLoad.current = null }} >{appMessages.label_intro_skip}</span>
                            </TertiaryButton>}
                        </>
                    }
                </div>
            </div>

        </IntroWrapper>
    </IntroPopupMask>
}

const IntroPopupMask = styled.div`
position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  padding: 15px;
`

const SecondaryButton = styled.div`
float: ${({uiDirection}) => (uiDirection === "ltr") ? 'right' : 'left'}
display: flex;
font-size: 14px;
font-weight: 700;
background: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'normal', 'background') ? compositeStyle.secondaryButton.normal.background : ''};
color: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'normal', 'text') ? compositeStyle.secondaryButton.normal.text : ''};
border: 1px solid ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'normal', 'stroke') ? compositeStyle.secondaryButton.normal.stroke : ''};
 :hover {
    background: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'focussed', 'background') ? compositeStyle.secondaryButton.focussed.background : ''};
    border: 1px solid ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'focussed', 'stroke') ? compositeStyle.secondaryButton.focussed.stroke : ''};
${({uiDirection}) => {
    if (uiDirection === "rtl") {
        return 'padding-left: 39px;'
    }else {
        return 'padding-right: 39px;'
    }
}}

@media screen and (max-width: 450px), screen and (max-height: 525px) {
    font-size: 18px;
    font-size: 14px;
    font-weight: 700
    ${({uiDirection}) => {
        if (uiDirection === "rtl") {
            return 'padding-left: 25px; padding: 1px 0px 15px 30px;'
        }else {
            return 'padding-right: 25px; padding: 1px 30px 15px 0px;'
        }
    }}
}
`

const TertiaryButton = styled.div`
color: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'normal', 'text') ? compositeStyle.secondaryButton.normal.text : ''};
padding: 4px 32px;
font-size: 14px;
font-weight: 700;
font-family: 'Montserrat';
:hover {
    color: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'secondaryButton', 'focussed', 'text') ? compositeStyle.secondaryButton.focussed.text : ''};
}
@media screen and (max-width: 450px), screen and (max-height: 525px) {
    padding: 1px 0px 15px 30px;
    font-size: 14px;
    font-weight: 700;
    ${({uiDirection}) => {
        if (uiDirection === "rtl") {
            return 'padding: 1px 30px 15px 0px;'
        }else {
            return 'padding: 1px 0px 15px 30px;'
        }
    }}
}
`

const IntroTitle = styled.h3`
    color: ${({ body }) => utils.isNotNull(body, 'text', 'primary') ? body.text.primary : '#ffd200'};
`

const IntroDescription = styled.p`
    color: ${({ body }) => utils.isNotNull(body, 'text', 'secondary') ? body.text.secondary : '#ffffff'};
`

const IntroWrapper = styled.div`
  background-color:${({ body }) => {
        return utils.isNotNull(body, 'background', 'primary') ? body.background.primary : '#2b2b2b';
    }};

    .pop-up .slick-dots li.slick-active button:before {
        color: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'sliderIndicator', 'selected', 'background') ? compositeStyle.sliderIndicator.selected.background : '#ffd200'};
        
    }
    .slick-dots button:before {
        opacity: 0.75;
    }

    .pop-up .slick-dots li button:before {
        color: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'sliderIndicator', 'normal', 'background') ? compositeStyle.sliderIndicator.normal.background : '#ffd200'};
    }
`

const GetStartedBtn = styled.button`
background: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'background') ? compositeStyle.primaryButton.normal.background : ''};
color: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'text') ? compositeStyle.primaryButton.normal.text : ''};
border: 1px solid ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'stroke') ? compositeStyle.primaryButton.normal.stroke : ''};
 :hover {
    background: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'primaryButton', 'focussed', 'background') ? compositeStyle.primaryButton.focussed.background : ''};
    border: 1px solid ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'primaryButton', 'focussed', 'stroke') ? compositeStyle.primaryButton.focussed.stroke : ''};
}
`

// const LoginButton = styled.button`
//     background: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'background') ? compositeStyle.secondaryButton.normal.background : ''};
//     border: 1px solid ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'stroke') ? compositeStyle.secondaryButton.normal.stroke : ''};
//     color: ${({ compositeStyle }) => utils.isNotNull(compositeStyle, 'primaryButton', 'normal', 'text') ? compositeStyle.secondaryButton.normal.text : ''};
//     font-weight: 600;
//     font-size: 12px;
//     font-family: "Montserrat";
//     border-radius: 5px;
//     width: auto;
//     padding: 12px 22px;
//     height: 32px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// `
const AccountLabel = styled.p`
    color: ${({ body }) => utils.isNotNull(body, 'text', 'secondary') ? body.text.secondary : '#ffffff'};
    display: flex;
    justify-content: center;
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 12px;
    
`

const IntroImage = styled.img`
${({ graphics = [] }) => {
        // gets the images in contentFul configuration
        const filteredGraphics = getGraphics({ graphics });
        const { images } = (filteredGraphics[0] || {});
        const { url } = (images[0] || {})
        return `content: url('${url}');`
    }}
position: absolute;
object-fit: cover;
width: 100%;
height: 100%;
`

export default IntroPopup
