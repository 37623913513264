import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef, useContext, useCallback } from 'react';
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Hotkeys from "react-hot-keys";
import visitorInfo from 'visitor-info'
import { useFullScreen } from 'react-browser-hooks'
/* --------------------------------------------- */
import { device, size } from '../../constants/breakpoints'
import utils from '../../utils/utils'
import { routeMap } from '../../route'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
import { PlayerContext } from '../../context/player.context'
import { useSelector } from 'react-redux'
/* --------------------------------------------- */
import RewindIcon from '../icons/RewindIcon'
import RewindIconFilled from '../icons/RewindIconFilled'
import ForwardIcon from '../icons/ForwardIcon'
import ForwardIconFilled from '../icons/ForwardIconFilled'
import FullscreenEnter from '../icons/FullscreenEnter'
import VolumeIcon from '../icons/VolumeIcon'
import VolumeIconFilled from '../icons/VolumeIconFilled'
import FullscreenExit from '../icons/FullscreenExit'
import GoBackArrowIcon from '../icons/GoBackArrowIcon'
import PauseIcon from '../icons/PauseIcon'
import PauseIconFilled from '../icons/PauseIconFilled'
import VolMuteIcon from '../icons/VolMuteIcon'
import CloseIcon from '../icons/CloseIcon'
import PlayIcon from '../icons/PlayIcon';
import PlayIconFilled from '../icons/PlayIconFilled';
import SubtitleIcon from '../icons/SubtitleIcon';
import SubtitleIconFilled from '../icons/SubtitleIconFilled';
import SettingsIcon from '../icons/SettingsIcon';
import SettingsIconFilled from '../icons/SettingsIconFilled';
import NextBtnOutlineIcon from '../icons/NextBtnOutlineIcon';
import NextBtnFilledIcon from '../icons/NextBtnFilledIcon';
import PreviousBtnOutlineIcon from '../icons/PreviousBtnOutlineIcon';
import PreviousBtnFilledIcon from '../icons/PreviousBtnFilledIcon';
import FastRewindIcon from '../icons/FastRewindIcon';
import FastRewindIconFilled from '../icons/FastRewindIconFilled';
import FastForwardIconFilled from '../icons/FastForwardIconFilled';
import FastForwardIcon from '../icons/FastForwardIcon';
/* --------------------------------------------- */
import PlayerSeek from './PlayerSeek';
import BinchWatch from './BinchWatch';
import Spinner from '../Spinner';
import PlayerPlaceholder from '../PlayerPlaceholder'
import PlayerStats from './PlayerStats'
/* --------------------------------------------- */
import withRouterInnerRef from '../../components/hoc/withRouterInnerRef'
import { getAppLanguageWithoutRegion, setSelectedPlayerQuality, getSelectedSubtitleTrack, setSelectedSubtitleTrack } from '../../utils/localStorageService'
/* --------------------------------------------- */
import useDetailsApi from '../../apis/useDetailsApi'
/* --------------------------------------------- */
import { splitLanguage } from '../../utils/localStorageService'
/* --------------------------------------------- */
import './Player.scss'
import { getBrowserName } from '../../utils/contentfulHandler';
import PipEnable from '../icons/PipEnable';
import PipExit from '../icons/PipExit';
/* --------------------------------------------- */
const { browser = {}, engine = {} } = visitorInfo()
/* --------------------------------------------- */
const timeoutInMiliseconds = 1000
/* --------------------------------------------- */
const PlayerUI = forwardRef(({
    thisIsChannel,
    isPlayerLoaded,
    title,
    subtitle,
    thumbnailImgs,
    onPauseClick,
    onPlayClick,
    onMuteClick,
    onPipEnableBtnClick,
    onPipExitBtnClick,
    onUnmuteClick,
    messages,
    type,
    history,
    setCurrentTime,
    getAllQualities,
    getAllSubtitles,
    getCurrentQuality,
    setCurrentQuality,
    getCurrentSubtitle,
    setCurrentSubtitle,
    episodeNum, seasonNumber, seriesTitle,
    mediaId, seriesId,
    showPlaceholderPlayBtn,
    onPlaceholderPlayBtnClick,
    allowCasting,
    isLivePlayer,
    debugModeEnabled,
    bingeCountdownDuration,
    onShowControlsChange,
    isShakaPlayerMount,
    showTwoByThreeImage,
}, ref) => {
    /* --------------------------------------------- */
    const {
        dispatch: playerDispatch, isShowStats, onUserPlayClick,
        onUserPauseClick, onSeek, isShowThumbnail,
        setNextEpisode, startPos = 0, startDuration = 0, bufferingInfo } = useContext(PlayerContext)
    /* --------------------------------------------- */
    const location = useLocation()
    const { toggle: toggleFullscreen, fullScreen: isFullscreen, close: closeFullscreen } = useFullScreen()
    const isChrome = browser.name === 'Chrome'
    const isSafari = getBrowserName((browser || {}).name, (engine || {}).name) === 'safari'
    const isMobileSafari = browser.name === 'Mobile Safari'
    const locationPath = useLocation()?.pathname
    const { trailer, season } = utils.parse(location.search)
    /* --------------------------------------------- */
    const [seekTime, setSeekTime] = useState(0)
    const [isPlayerLoading, updateIsPlayerLoading] = useState(true)
    const [isShowControls, updateIsShowControls] = useState(true)
    const [isMute, updateIsMute] = useState(false)
    const [isPipEnabled, updateIsPipEnabled] = useState(false)
    const [isPlaying, updateIsPlaying] = useState(false)
    const [isPlayerMenuOpen, updateIsPlayerMenuOpen] = useState(false)
    const [isReplayScreen, updateIsReplayScreen] = useState(false)
    const [currentTime, updateCurrentTime] = useState((startPos || 0) * 1000)
    const [duration, updateDuration] = useState((startDuration || 0) * 1000)
    const [buffered, updateBuffered] = useState(0)
    const [currentTimeFormatted, updateCurrentTimeFormatted] = useState('0:00')
    const [durationFormatted, updateDurationFormatted] = useState('0:00')
    const [remainingTimeFormatted, updateRemainingTimeFormatted] = useState('0:00')
    const [allQualities, updateAllQualities] = useState([])
    const [allSubTitle, updateAllSubTitle] = useState([])
    const [currentQuality, updateCurrentQuality] = useState()
    const [displayCurrentQuality, updateDisplayCurrentQuality] = useState()
    const [currentSubTitle, updateCurrentSubTitle] = useState()
    // const [subTitleText, updateSubTitleText] = useState('')
    const [isShowSubTitleSelector, showIsSubTitleSelector] = useState(false)
    const [isPlayerReady, updateIsPlayerReady] = useState(false)
    /* --------------------------------------------- */
    const [isShowBackBtn, showBackBtn] = useState(false)
    const [isShowBingeWatching, showBingeWatching] = useState(false)
    const [isShowBreadCrumbView, showBreadCrumbView] = useState(false)
    const [isShowElapsedTime, showElapsedTime] = useState(false)
    const [isShowForwardBtn, showForwardBtn] = useState(false)
    const [forwardDuration, setForwardDuration] = useState(0)
    const [isShowMuteUnmuteBtn, showMuteUnmuteBtn] = useState(false)
    const [isShowPipBtn, showPipButton] = useState(false)
    const [isShowPlayPauseBtn, showPlayPauseBtn] = useState(false)
    const [isShowQualitySelectorBtn, showQualitySelectorBtn] = useState(false)
    const [isShowSubTitleSelectorBtn, showSubTitleSelectorBtn] = useState(false)
    const [isShowFullscreenBtn, showFullscreenBtn] = useState(false)
    const [isShowRemainingTime, showRemainingTime] = useState(false)
    const [isShowRewindBtn, showRewindBtn] = useState(false)
    const [rewindDuration, setRewindDuration] = useState(false)
    const [isShowSeekbar, showSeekBar] = useState(false)
    const [isShowSeekPreview, showSeekPreview] = useState(false)
    const [isShowTitle, showTitle] = useState(false)
    const [isShowTotalDuration, showTotalDuration] = useState(false)
    const [isShowFastForwardBtn, showFastForwardBtn] = useState(false)
    const [isShowFastRewindBtn, showFastRewindBtn] = useState(false)
    const [isShowPreviousBtn, showPreviousBtn] = useState(false)
    const [isShowNextBtn, showNextBtn] = useState(false)
    const [isAutoPlay, setAutoPlay] = useState(false)
    const [nextEpisode, UpdateNextEpisode] = useState(null)
    /* --------------------------------------------- */
    const [theme, setPlayerTheme] = useState({})
    /* --------------------------------------------- */
    const [playerError, setPlayerError] = useState(false)
    /* --------------------------------------------- */
    const binchWatchRef = useRef(null)
    const prevPlayingState = useRef(false)
    const isScrubbingRef = useRef(false)
    /* --------------------------------------------- */
    const { isAutoplay, isAuthenticated, appLanguage } = useContext(GlobalContext)
    const { appConfig, appMessages = {} } = useSelector(store => store.appConfigReducers)
    /* --------------------------------------------- */
    const { getNextEpisode } = useDetailsApi()
    /* --------------------------------------------- */
    const showPlayerStats = () => {
        playerDispatch({ type: 'SHOW_STATS', value: !isShowStats })
    }
    /* --------------------------------------------- */
    const hotKeysPlayPauseHandler = () => {
        if (!isPlayerReady) return
        if (isShowSubTitleSelector || isPlayerMenuOpen) return
        if (isPlaying) {
            onPauseClick()
            onUserPauseClick()
        }
        else {
            onPlayClick()
            onUserPlayClick()
        }
    }
    /* --------------------------------------------- */
    const handleShowControls = useCallback((bShow) => {
        // For hiding the cursor when player controls hides
        if(document?.getElementById("player")?.style) {
            if(bShow) {
                document.getElementById('player').style.setProperty('cursor', 'default', 'important');
            } else {
                document.getElementById('player').style.setProperty('cursor', 'none', 'important')
            }
        }
    
        updateIsShowControls(bShow) 
        if(isShowControls !== bShow){
            onShowControlsChange && onShowControlsChange(bShow)
        }
    },[onShowControlsChange, isShowControls])
    /* --------------------------------------------- */
    const handleRewindBtn = () => {
        if (!isPlayerReady) return
        let rewindTime = currentTime - rewindDuration * 1000
        if (rewindTime < 0) rewindTime = 0
        setCurrentTime(rewindTime)
        updateCurrentTime(rewindTime)
        handleShowControls(true)
        if (onSeek) onSeek(rewindTime)
    }
    /* --------------------------------------------- */
    const handleForwardBtn = () => {
        if (!isPlayerReady) return
        let forwardTime = currentTime + forwardDuration * 1000
        if (forwardTime > duration) forwardTime = duration
        setCurrentTime(forwardTime)
        updateCurrentTime(forwardTime)
        handleShowControls(true)
        if (onSeek) onSeek(forwardTime)
    }
    /* --------------------------------------------- */
    const handlePlayerBackBtnClick = () => {
        //if pip is enabled on back button click pip will close . This logic will be changed now Pip button would be hidden in player
        if(document.pictureInPictureElement) {
            onPipExitBtnClick()
        } else {
            history.goBack()
        }
    }
    const lang = getAppLanguageWithoutRegion()
    /* --------------------------------------------- */
    const onCurrentTimeChange = (time) => {
        if (!isScrubbingRef.current) {
            updateCurrentTime(time)
        }
    }
    /* --------------------------------------------- */
    // const escapeKeyhandler = () => {
    //     else handlePlayerBackBtnClick()
    //     handleShowControls(true)
    // }
    /* --------------------------------------------- */
    useImperativeHandle(ref, () => {
        return {
            showBackBtn, showBingeWatching, showBreadCrumbView, showElapsedTime, showForwardBtn, showSubTitleSelectorBtn,
            showFullscreenBtn, setForwardDuration, showMuteUnmuteBtn, showPlayPauseBtn, showQualitySelectorBtn,
            showRemainingTime, showRewindBtn, setRewindDuration, showSeekBar, showSeekPreview, showTitle, showTotalDuration,
            showNextBtn, showPreviousBtn, showFastForwardBtn, showFastRewindBtn, showPipButton,
            setPlayerTheme, setAutoPlay,
            setPlayerError,
            updateIsPlayerLoading,
            updateIsPlaying,
            onCurrentTimeChange,
            updateDuration,
            updateIsReplayScreen,
            // updateSubTitleText,
            updateIsPlayerReady,
            updateDisplayCurrentQuality
        }
    });
    /* --------------------------------------------- */
    useEffect(() => {
        let isMounted = true
        if (isAuthenticated && seriesId && !trailer) {
            getNextEpisode(appConfig.baseUrl, {
                episodeId: mediaId,
                seriesId: seriesId,
                language: splitLanguage(appLanguage)
            }).then((res = {}) => {
                if (!isMounted) return
                if (res.nextEpisode) {
                    UpdateNextEpisode(res.nextEpisode)
                }
            }).catch(() => { })
        }
        /* --------------------------------------------- */
        return () => {
            isMounted = false
        }
    }, [mediaId, appConfig.baseUrl, seriesId, isAuthenticated, getNextEpisode, setNextEpisode, appLanguage, trailer])
    /* --------------------------------------------- */
    useEffect(() => {
        if (!setNextEpisode || !nextEpisode) return
        nextEpisode.seriesUid = seriesId
        nextEpisode.seriesTitle = seriesTitle
        setNextEpisode(nextEpisode)
    }, [nextEpisode, setNextEpisode, seriesId, seriesTitle])
    /* --------------------------------------------- */
    useEffect(() => {
        updateDisplayCurrentQuality('Auto')
    }, [])
    /* --------------------------------------------- */
    useEffect(() => {
        setSeekTime(currentTime)
    }, [currentTime])
    /* --------------------------------------------- */
    useEffect(() => {
        const bufferedTime = bufferingInfo.total && bufferingInfo.total.length && bufferingInfo.total[bufferingInfo.total.length - 1].end ?
            bufferingInfo.total[bufferingInfo.total.length - 1].end : 0
        const percent = bufferedTime > 0 ? (bufferedTime * 100) / (duration / 1000) : 0
        updateBuffered(percent)
    }, [bufferingInfo, duration])
    /* --------------------------------------------- */
    useEffect(() => {
        if (isShowSeekbar) {
            if (utils.isNotNull(seekTime) && !isNaN(seekTime)) {
                updateCurrentTimeFormatted(
                    utils.toHHMMSS(seekTime / 1000)
                )
            }
            if (utils.isNotNull(seekTime) && !isNaN(seekTime)) {
                let remainingTime = duration - seekTime;
                if(remainingTime > 0) {
                    updateRemainingTimeFormatted(
                        utils.toHHMMSS(remainingTime /1000)
                    )
                }
            }
            if (utils.isNotNull(duration) && !isNaN(duration)) {
                updateDurationFormatted(
                    utils.toHHMMSS(duration / 1000)
                )
            }
        }
    }, [seekTime, duration, isShowSeekbar])
    /* --------------------------------------------- */
    useEffect(() => {
        let timer = null
        if (isPlayerReady) {
            timer = setInterval(() => handleShowControls(false), 10000)
        }
        return () => {
            clearInterval(timer)
        }
    }, [isPlayerReady, handleShowControls])
    /* --------------------------------------------- */
    useEffect(() => {
        let timeoutId = ''
        const playerUI = document.getElementById('player')
        
        /* --------------------------------------------- */
        const doInactive = () => {
            if (isPlaying) handleShowControls(false)
        }
        /* --------------------------------------------- */
        const resetTimer = () => {
            window.clearTimeout(timeoutId)
            timeoutId = window.setTimeout(doInactive, timeoutInMiliseconds)
            if (!isShowControls) {
                handleShowControls(true)
            }
        }
        /* --------------------------------------------- */
        if (playerUI && playerUI.addEventListener) {
            playerUI.addEventListener('mousemove', resetTimer)
            playerUI.addEventListener('mousedown', resetTimer)
            playerUI.addEventListener('keypress', resetTimer)
            playerUI.addEventListener('touchmove', resetTimer)
        }
        /* --------------------------------------------- */
        return () => {
            clearTimeout(timeoutId)
            playerUI.removeEventListener('mousemove', resetTimer)
            playerUI.removeEventListener('mousedown', resetTimer)
            playerUI.removeEventListener('keypress', resetTimer)
            playerUI.removeEventListener('touchmove', resetTimer)
        }
        /* --------------------------------------------- */
    }, [isShowControls, isPlaying, handleShowControls])
    /* --------------------------------------------- */
    useEffect(() => {
        if (isShowBingeWatching && binchWatchRef.current) {
            if (binchWatchRef.current.updateCurrentTime) binchWatchRef.current.updateCurrentTime(currentTime)
            if (binchWatchRef.current.updateDuration) binchWatchRef.current.updateDuration(duration)
        }
    }, [isShowBingeWatching, currentTime, duration])
    /* --------------------------------------------- */
    const setStateAllSubTitle = useCallback(() => {
      setTimeout(() => {
        const subtitleData = getAllSubtitles() || []
        updateAllSubTitle(subtitleData)
        const selectedSubTitleTrack = getSelectedSubtitleTrack()
        // Code to disable subtitle by default in safari
        if(selectedSubTitleTrack.language === 'none' && isSafari && subtitleData.length>=1) {
          const defaultTitle = subtitleData.filter((data) => (data.language === 'none' || data.language === ''))
          if(defaultTitle.length>=1) setSelectedSubtitleTrack(defaultTitle[0]) 
          setCurrentSubtitle("default")
        }
      }, 0)
    }, [getAllSubtitles, isSafari, setCurrentSubtitle])

    useEffect(() => {
        // Code to display subtitle option on player
        if (isPlayerReady && getAllSubtitles && isShakaPlayerMount) {
          setStateAllSubTitle()
        }
    }, [isPlayerReady, getAllSubtitles, setStateAllSubTitle, isShakaPlayerMount])

    /* --------------------------------------------- */
    const nextBtnHandle = useCallback(() => {
        const { uid, displayDuration: duration = 0 } = nextEpisode || {}
        nextEpisode.seriesId = seriesId
        nextEpisode.seriesTitle = seriesTitle
        history.replace(`${routeMap.player}?mediaId=${uid}&progress=0&duration=${duration || 0}`, { details: nextEpisode })
    }, [history, nextEpisode, seriesId, seriesTitle])
    /* --------------------------------------------- */
    const handlePauseBtnHandler = () => {
        onPauseClick()
        onUserPauseClick()
    }
    /* --------------------------------------------- */
    const handlePlayBtn = () => {
        updateIsReplayScreen(false)
        onPlayClick()
        onUserPlayClick()
    }
    /* --------------------------------------------- */
    const playbackQualityHandler = (index) => {
        setSelectedPlayerQuality(allQualities[index] || '')
        setCurrentQuality(allQualities[index])
        updateDisplayCurrentQuality(allQualities[index])
        updateIsPlayerMenuOpen(false)
        if (prevPlayingState.current) onPlayClick()
    }
    /* --------------------------------------------- */
    const playbackSubTitleHandler = (index) => {
        setSelectedSubtitleTrack(allSubTitle[index])
        setCurrentSubtitle(allSubTitle[index].language)
        updateCurrentSubTitle(index)
        showIsSubTitleSelector(false)
        if (prevPlayingState.current) onPlayClick()
    }
    /* --------------------------------------------- */
    const handleMuteBtn = () => {
        if (!isMute) { onMuteClick() }
        else { onUnmuteClick() }
        updateIsMute(!isMute)
    }
    /* --------------------------------------------- */
    // pip button enable/exit handling
    const handlePipBtn = () => {
        if (!isPipEnabled) { onPipEnableBtnClick() }
        else { onPipExitBtnClick() }
        updateIsPipEnabled(!isPipEnabled)
    }
    /* --------------------------------------------- */
    const handlePlayerSubTitleSettings = () => {
        if (!isShowSubTitleSelector) {
            prevPlayingState.current = isPlaying
            showIsSubTitleSelector(true)
            onPauseClick()
            let selectedSubTitle = getCurrentSubtitle()
            let localSelectedSubTitle = getSelectedSubtitleTrack()
            let subtitleIndex = null
            if (localSelectedSubTitle) {
                for (let i = 0; i < allSubTitle.length; i++) {
                    if (allSubTitle[i].language === localSelectedSubTitle.language) {
                        subtitleIndex = i
                        break;
                    } else if (localSelectedSubTitle.language !== "none" && allSubTitle[i].language !== "none") {
                      subtitleIndex = i
                    }
                    // return null
                }
            }
            if (subtitleIndex === null) {
                for (let i = 0; i < allSubTitle.length; i++) {
                    if (allSubTitle[i].language === selectedSubTitle.language) {
                        subtitleIndex = i
                        break;
                    } else if (selectedSubTitle.language !== "none" && allSubTitle[i].language !== "none") {
                      subtitleIndex = i
                    }
                    // return null
                }
            }
            updateCurrentSubTitle(subtitleIndex)
        }
    }
    /* --------------------------------------------- */
    const handlePlayerSettingsClose = () => {
        if (isPlayerMenuOpen) {
            updateIsPlayerMenuOpen(false)
            if (prevPlayingState.current) onPlayClick()
        }
    }
    /* --------------------------------------------- */
    const handlePlayerSubTitleClose = () => {
        if (isShowSubTitleSelector) {
            showIsSubTitleSelector(false)
            if (prevPlayingState.current) onPlayClick()
        }
    }
    /* --------------------------------------------- */
    const handlePlayerSettings = () => {
        if (!isPlayerMenuOpen) {
            prevPlayingState.current = isPlaying
            updateIsPlayerMenuOpen(true)
            onPauseClick()
            let allQualities = getAllQualities()
            updateAllQualities(allQualities)
            let selectedQuality = getCurrentQuality()
            let index = allQualities.indexOf(selectedQuality)
            if (index > -1) updateCurrentQuality(index)
            else updateCurrentQuality(0)
        }
    }
    /* --------------------------------------------- */
    const hotKeyhandlers = {
        'control+shift+option+d': showPlayerStats,
        'control+shift+alt+d': showPlayerStats,
        'space': hotKeysPlayPauseHandler,
        'right': handleForwardBtn,
        'left': handleRewindBtn,
        // 'escape': escapeKeyhandler
    };
    /* --------------------------------------------- */
    const handleHotKeyUp = (e) => {
        if (isShowSubTitleSelector || isPlayerMenuOpen) return
        const event = hotKeyhandlers[e]
        if (event) event()
    }
    /* --------------------------------------------- */
    const handleSlideStart = useCallback(() => {
        isScrubbingRef.current = true
        prevPlayingState.current = isPlaying
        onPauseClick()
    }, [isPlaying, onPauseClick])
    /* --------------------------------------------- */
    const handleSlideEnd = seekTime => {
       // checks for video total duration with seek Time.
        const totalDuration = utils.trimLeadingZeroesFromHHMMSS(durationFormatted);
        const splitTime = totalDuration.split(':').map(Number);
        const durationInMilliSec = splitTime.length > 2 ? utils.toMilliSeconds(...splitTime) : utils.toMilliSeconds(0, ...splitTime);
        if (durationInMilliSec < seekTime) seekTime = durationInMilliSec
        // -----------------------------------------------------------
        
        
        setCurrentTime(seekTime)
        updateCurrentTime(seekTime)
        if (onSeek) onSeek(seekTime)
        if (prevPlayingState.current) onPlayClick()
        isScrubbingRef.current = false
    }
    /* --------------------------------------------- */
    const handleSlideUpdate = useCallback(([seekTime]) => {
        setSeekTime(seekTime)
    }, [])
    /* --------------------------------------------- */
    const handleSlideStartForLive = useCallback(() => {
        isScrubbingRef.current = true
        prevPlayingState.current = isPlaying
    }, [isPlaying])
    /* --------------------------------------------- */
    return (
        <PlayerWrapper theme={theme} className={locationPath === routeMap.player ? 'player-fullscreen' : 'player-portrait'}>
            <Hotkeys
                keyName={Object.keys(hotKeyhandlers).join(',')}
                onKeyUp={handleHotKeyUp}
            >
                {!playerError && !isAutoPlay && !isPlayerLoaded && locationPath !== routeMap.player &&
                    <PlayerPlaceholder {...{
                        thisIsChannel,
                        isShowPlayBtn: showPlaceholderPlayBtn,
                        playBtnClickHandler: onPlaceholderPlayBtnClick,
                        images: thumbnailImgs,
                        showTwoByThreeImage,
                    }} />
                }
                {!playerError && isPlayerLoaded && <Spinner size={40} IsLoading={isPlayerLoading} spinnerTempColor={theme && theme.body && theme.body.accent.tertiary} />}
                {!playerError && isPlayerLoaded && <>
                    <div className="player__overlay"></div>
                    <div className={`player__top-controls ${isShowControls ? 'show-controls' : ''}`} >
                        <div className='player__top-titile-wrapper'   >
                            {
                                isShowBackBtn && (
                                    !isFullscreen ?
                                        (
                                            <div className={`back-btn ${episodeNum ? 'back__icon-bottom-margin' : ''}`}
                                                // style={{marginBottom: episodeNum ? '.6%' : '0%'}}
                                                onClick={handlePlayerBackBtnClick}>
                                                <GoBackArrowIcon color={theme && theme.header && theme.header.accent.secondary} style={{ width: 20, height: 20 }} />
                                            </div>
                                        ) :
                                        (
                                            <div className='back-btn' onClick={closeFullscreen}><GoBackArrowIcon color={theme && theme.header && theme.header.accent.secondary} style={{ width: 20, height: 20 }} /></div>
                                        )
                                )
                            }
                            <div className='title'>
                                {(isShowBreadCrumbView || isLivePlayer || trailer) && <SubTitle theme={theme}>{trailer ? <span> {seriesTitle && <span>{seriesTitle}</span>} {seasonNumber && <span>{messages.label_details_season} {season}</span>}</span> : subtitle}</SubTitle>}
                                {episodeNum && !trailer && <p> {seriesTitle && <span>{seriesTitle}</span>} {seasonNumber && <span>{messages.label_details_season} {seasonNumber}</span>} {episodeNum && <span>{messages.label_details_episode} {episodeNum}</span>}</p>}
                                {(trailer && type !== 'episode' && type !== 'series') && <SubTitle theme={theme}>{title}</SubTitle>}
                                {(isShowTitle || isLivePlayer || trailer) && <HeaderTitle theme={theme}>{(trailer) ? messages.label_details_trailer : title}</HeaderTitle>}
                            </div>
                        </div>
                        <div className={`controls ${!isPlayerReady ? 'visibility-hidden' : ''}`}>
                            {
                                isChrome && allowCasting && (
                                    <div className='player__settings' >
                                        <div>
                                            <google-cast-launcher style={{ display: 'block', width: 28, height: 28, cursor: 'pointer' }}></google-cast-launcher>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                isShowSubTitleSelectorBtn && allSubTitle.length > 0 && (
                                    <div className='player__audio' >
                                        <div onClick={handlePlayerSubTitleSettings}>
                                            <p>{messages.label_player_subtitles}</p>
                                            <div className='control-icons-wrapper'>
                                                <SubtitleIcon color={theme && theme.header && theme.header.accent.primary} style={{ width: 27, height: 27 }} className="control-icons" />
                                                <SubtitleIconFilled color={theme && theme.header && theme.header.accent.primary} style={{ width: 27, height: 27 }} className="control-icons-filled" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                ((isShowPipBtn) && document.pictureInPictureEnabled) && (
                                    document.pictureInPictureElement ?
                                        (
                                            <div onClick={handlePipBtn} className='volume-btn-wrapper' >
                                                <div className='control-icons-wrapper'>
                                                    <PipExit color={theme && theme.header && theme.header.accent.primary} style={{ width: 27, height: 27 }} />
                                                </div>
                                            </div>
                                        ) :
                                        (
                                            <div onClick={handlePipBtn} className='volume-btn-wrapper' >
                                                <div className='control-icons-wrapper'>
                                                    <PipEnable color={theme && theme.header && theme.header.accent.primary} style={{ width: 27, height: 27 }} />
                                                </div>
                                            </div>
                                        )
                                )
                            }
                            {
                                isShowQualitySelectorBtn && (
                                    <div className='player__settings' >
                                        <div onClick={handlePlayerSettings}>
                                            <p>{displayCurrentQuality}</p>
                                            <div className='control-icons-wrapper'>
                                                <SettingsIconFilled color={theme && theme.header && theme.header.accent.primary} style={{ width: 27, height: 27 }} className="control-icons-filled" />
                                                <SettingsIcon color={theme && theme.header && theme.header.accent.primary} style={{ width: 27, height: 27 }} className="control-icons" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                isShowMuteUnmuteBtn && (
                                    isMute ?
                                        (
                                            <div onClick={handleMuteBtn} className='volume-btn-wrapper' >
                                                <p>{appMessages.player_label_unmute}</p>
                                                <div className='control-icons-wrapper'>
                                                    <VolMuteIcon color={theme && theme.header && theme.header.accent.primary} style={{ width: 27, height: 27 }} />
                                                </div>
                                            </div>
                                        ) :
                                        (
                                            <div onClick={handleMuteBtn} className='volume-btn-wrapper' >
                                                <p>{appMessages.player_label_mute}</p>
                                                <div className='control-icons-wrapper'>
                                                    <VolumeIcon className="control-icons-filled" color={theme && theme.header && theme.header.accent.primary} style={{ width: 27, height: 27 }} />
                                                    <VolumeIconFilled className="control-icons" color={theme && theme.header && theme.header.accent.primary} style={{ width: 27, height: 27 }} />
                                                </div>
                                            </div>
                                        )
                                )
                            }
                            {
                                isShowFullscreenBtn && (
                                    <div onClick={toggleFullscreen} id='fullscreenBtn' >
                                        {isFullscreen ?
                                            <div className='control-icons-wrapper'>
                                                <FullscreenExit color={theme && theme.header && theme.header.accent.primary} style={{ width: 25, height: 25 }} />
                                            </div>
                                            :
                                            <div className='control-icons-wrapper'> <FullscreenEnter color={theme && theme.header && theme.header.accent.primary} style={{ width: 25, height: 25 }} />
                                            </div>
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {/* <div className='subTitle'>
                        <SubTitleText className='subTitle__track' theme={theme}>{subTitleText}</SubTitleText>
                    </div> */}
                    <PlayerStats
                        mediaId={mediaId}
                        debugModeEnabled={debugModeEnabled}
                        showPlayerStats={showPlayerStats}
                        theme={theme}
                    />
                    <div className={`player-audio__list ${isShowSubTitleSelector ? 'player-audio__list---open' : 'player-audio__list---close'}`}>
                        <PlayerPopUpContent theme={theme} className='player-audio__content-wrapper'>
                            <div className='player-audio__head'>
                                <PlayerSettingHeader theme={theme}>{messages.label_player_language_options}</PlayerSettingHeader>
                                <div className='player-audio__close' onClick={handlePlayerSubTitleClose}>
                                    <CloseIcon color={theme && theme.body && theme.body.accent.primary} style={{ width: 18, height: 18 }} />
                                </div>
                            </div>
                            <div className='player-audio__content-list-wrapper'>
                                <h3>{messages.label_player_subtitles}</h3>
                                <div className='player-audio__content'>
                                    <PlayerSettingListWrapper theme={theme}>{
                                        allSubTitle.map(({ language }, i) => {
                                            if (!language) return null
                                            const subTitleLanguage = `subtitle_${language}`
                                            const msg = (language === 'none') ? messages.subtitle_none : (messages[subTitleLanguage] || language)
                                            if (!msg) return null
                                            return <li key={`subtitle_${lang}${i}`} onClick={() => playbackSubTitleHandler(i)} className={`${currentSubTitle === i ? 'active' : ''}`}>{msg}</li>
                                        })
                                    }
                                    </PlayerSettingListWrapper>
                                </div>
                            </div>
                        </PlayerPopUpContent>
                    </div>
                    <div className={`player-settings__list ${isPlayerMenuOpen ? 'player-settings__list---open' : 'player-settings__list---close'}`}>
                        <PlayerPopUpContent theme={theme} className='player-settings__content-wrapper'>
                            <div className='player-settings__head'>
                                <PlayerSettingHeader theme={theme}>{messages.player_quality_selector}</PlayerSettingHeader>
                                <div className='player-settings__close' onClick={handlePlayerSettingsClose}>
                                    <CloseIcon color={theme && theme.body && theme.body.accent.primary} style={{ width: 18, height: 18 }} />
                                </div>
                            </div>
                            <div className='player-settings__content'>
                                <PlayerSettingListWrapper theme={theme}>{
                                    allQualities.map((quality, i) => {
                                        if (!quality) return null
                                        return <li key={i} onClick={() => playbackQualityHandler(i)} className={`${currentQuality === i ? 'active' : ''}`}>{quality}</li>
                                    })
                                }
                                </PlayerSettingListWrapper>
                            </div>
                        </PlayerPopUpContent>
                    </div>
                    {isShowBingeWatching && isAutoplay && !trailer &&
                        <BinchWatch
                            nextEpisode={nextEpisode}
                            messages={messages}
                            ref={binchWatchRef}
                            nextBtnHandle={nextBtnHandle}
                            bingeCountdownDuration={bingeCountdownDuration}
                        />
                    }
                    {isReplayScreen && showPlaceholderPlayBtn ? <ImgContainer {...{ images: thumbnailImgs }} className='replay__img-holder' /> : null}
                    {isPlayerReady && <div className={`player__bottom-controls ${isMobileSafari ? 'show-controls-mobile-safari' : isShowControls ? 'show-controls' : ''}`} dir="ltr">
                        <div className='controls'>
                            {
                                isShowPreviousBtn && (
                                    <div className='previous-icon player-icon-container' onClick={() => { }}>
                                        <PreviousBtnOutlineIcon color={theme && theme.body && theme.body.accent.primary} style={{ width: 21, height: 30 }} className="player-icon" />
                                        <PreviousBtnFilledIcon color={theme && theme.body && theme.body.accent.primary} style={{ width: 21, height: 30 }} className="playerfill-icon" />
                                    </div>
                                )
                            }
                            {
                                isShowFastRewindBtn && (
                                    <div className='fast-rewind-icon player-icon-container' onClick={() => { }}>
                                        <FastRewindIcon color={theme && theme.body && theme.body.accent.primary} style={{ width: 35, height: 30 }} className="player-icon" />
                                        <FastRewindIconFilled color={theme && theme.body && theme.body.accent.primary} style={{ width: 35, height: 30 }} className="playerfill-icon" />
                                    </div>
                                )
                            }
                           {
                                isShowRewindBtn && (
                                    <div className='rewind-icon player-icon-container' onClick={handleRewindBtn}>
                                        <RewindIcon color={theme && theme.body && theme.body.accent.primary} text={`${isLivePlayer? `` : rewindDuration}`} style={{ width: 26, height: 30 }} className="player-icon" />
                                        <RewindIconFilled color={theme && theme.body && theme.body.accent.primary} text={`${isLivePlayer? `` : rewindDuration}`} style={{ width: 26, height: 30 }} className="playerfill-icon" />
                                    </div>
                                )
                            }
                            {
                                isShowPlayPauseBtn && (
                                    isPlaying ?
                                        (
                                            <div onClick={handlePauseBtnHandler} className="player-icon-container">
                                                <PauseIcon color={theme && theme.body && theme.body.accent.primary} style={{ width: 21, height: 30 }} className="player-icon" />
                                                <PauseIconFilled color={theme && theme.body && theme.body.accent.primary} style={{ width: 21, height: 30 }} className="playerfill-icon" />
                                            </div>
                                        ) :
                                        (
                                            <div onClick={handlePlayBtn} className="player-icon-container">
                                                <PlayIcon color={theme && theme.body && theme.body.accent.primary} style={{ width: 21, height: 30 }} className="player-icon" />
                                                <PlayIconFilled color={theme && theme.body && theme.body.accent.primary} style={{ width: 21, height: 30 }} className="playerfill-icon" />
                                            </div>
                                        )
                                )
                            }
                            {
                                isShowForwardBtn && (
                                    <div className='forward-icon player-icon-container' onClick={handleForwardBtn}>
                                        <ForwardIcon color={theme && theme.body && theme.body.accent.primary} text={`${isLivePlayer? `` : forwardDuration}`} style={{ width: 26, height: 30 }} className="player-icon" />
                                        <ForwardIconFilled color={theme && theme.body && theme.body.accent.primary} text={`${isLivePlayer? `` : forwardDuration}`} style={{ width: 26, height: 30 }} className="playerfill-icon" />
                                    </div>
                                )
                            }
                            {
                                isShowFastForwardBtn && (
                                    <div className='fast-forward-icon player-icon-container' onClick={() => { }}>
                                        <FastForwardIcon color={theme && theme.body && theme.body.accent.primary} style={{ width: 35, height: 30 }} className="player-icon" />
                                        <FastForwardIconFilled color={theme && theme.body && theme.body.accent.primary} style={{ width: 35, height: 30 }} className="playerfill-icon" />
                                    </div>
                                )
                            }
                            {
                                (nextEpisode || isShowNextBtn) && (
                                    <div className='next-button player-icon-container' onClick={nextEpisode ? nextBtnHandle : () => { }}>
                                        <NextBtnOutlineIcon color={theme && theme.body && theme.body.accent.primary} next={`${nextEpisode ? `${appMessages.player_control_next}` : ``}`} style={{ width: 21, height: 30 }} className="player-icon" />
                                        <NextBtnFilledIcon color={theme && theme.body && theme.body.accent.primary} next={`${nextEpisode ? `${appMessages.player_control_next}` : ``}`} style={{ width: 21, height: 30 }} className="playerfill-icon" />
                                    </div>
                                )
                            }

                            {/* {
                                nextEpisode &&
                                <div className='next-button player-icon-container' onClick={nextBtnHandle}>
                                    <NextBtnOutlineIcon color={theme && theme.body && theme.body.accent.primary} next={appMessages.player_control_next} style={{ width: 21, height: 30 }} className="player-icon" />
                                    <NextBtnFilledIcon color={theme && theme.body && theme.body.accent.primary} next={appMessages.player_control_next} style={{ width: 21, height: 30 }} className="playerfill-icon" />
                                </div>
                            } */}
                        </div>
                        {
                            isShowSeekbar && (
                                isLivePlayer ? 
                                    <div className='seek-bar'>
                                        <SeekTrack className='seek__track' isLivePlayer={isLivePlayer}>
                                            <PlayerSeek
                                                trackColor={theme && theme.body && theme.body.accent.secondary}
                                                seekBarColor={theme && theme.body && theme.body.background}
                                                isShowThumbnail={isShowSeekPreview && isShowThumbnail}
                                                onSlideStart={handleSlideStartForLive}
                                                onSlideEnd={handleSlideEnd}
                                                onUpdate={handleSlideUpdate}
                                                currentPos={(duration || 0)}
                                                duration={(duration || 0)}
                                                buffered={(duration || 0)}
                                                seekDisabled={true} // disabling seek for live player. Might need to change in future
                                                isSafari={isSafari}
                                                isLivePlayer={isLivePlayer}
                                            />
                                        </SeekTrack>
                                        <div className='seek-time'>
                                            {isShowRemainingTime ? <p id='currentTimePlayer'>{utils.trimLeadingZeroesFromHHMMSS(remainingTimeFormatted)}</p> : 
                                            isShowElapsedTime && <p id='currentTimePlayer'>{utils.trimLeadingZeroesFromHHMMSS(currentTimeFormatted)}</p> }
                                            {(isShowElapsedTime || isShowRemainingTime) && isShowTotalDuration && <p>&nbsp;/&nbsp;</p>}
                                            {isShowTotalDuration && <p id='durationTime'>{utils.trimLeadingZeroesFromHHMMSS(durationFormatted)}</p>}
                                        </div>
                                    </div>
                                :   <div className='seek-bar'>
                                        <div className='seek__track'>
                                            <PlayerSeek
                                                trackColor={theme && theme.body && theme.body.accent.secondary}
                                                seekBarColor={theme && theme.body && theme.body.background}
                                                isShowThumbnail={isShowSeekPreview && isShowThumbnail}
                                                onSlideStart={handleSlideStart}
                                                onSlideEnd={handleSlideEnd}
                                                onUpdate={handleSlideUpdate}
                                                currentPos={(currentTime || 0)}
                                                duration={(duration || 0)}
                                                buffered={(buffered || 0)}
                                                seekDisabled={false}
                                                isSafari={false}
                                                isLivePlayer={false}
                                            />
                                        </div>
                                        <div className='seek-time'>
                                            {isShowRemainingTime ? <p id='currentTimePlayer'>{utils.trimLeadingZeroesFromHHMMSS(remainingTimeFormatted)}</p> : 
                                            isShowElapsedTime && <p id='currentTimePlayer'>{utils.trimLeadingZeroesFromHHMMSS(currentTimeFormatted)}</p> }                                            {(isShowElapsedTime || isShowRemainingTime) && isShowTotalDuration && <p>&nbsp;/&nbsp;</p>}
                                            {isShowTotalDuration && <p id='durationTime'>{utils.trimLeadingZeroesFromHHMMSS(durationFormatted)}</p>}
                                        </div>
                                    </div>    
                            )
                        }
                        {
                            isLivePlayer &&
                            <div className="player__live_label">
                                <span className="player__live-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="8" cy="8" r="8" fill="red" />
                                    </svg>
                                </span>
                                {appMessages.label_live}
                            </div>
                        }
                    </div>}
                </>
                }
            </Hotkeys>
        </PlayerWrapper>
    );
})

/* --------------------------------------------- */ //if its a live player block seek bar interaction
const SeekTrack = styled.div`
 #rail-track {
    cursor: ${({ isLivePlayer }) => isLivePlayer ? 'default !important': 'pointer' };  
 }
`
/* --------------------------------------------- */
const PlayerSettingHeader = styled.p`
  color: ${({ theme: { header } }) => header && header.text.secondary};
`
/* --------------------------------------------- */
const PlayerWrapper = styled.div`
--connected-color:  ${({ theme: { header } }) => header && header.accent.primary};
--disconnected-color: ${({ theme: { header } }) => header && header.accent.primary};
position: absolute;
width:100%;
height: 100%;
top: 0;
left: 0;

.seek-time p {
    color:  ${({ theme: { body } }) => body && body.text.secondary};
}

.title p {
    color:  ${({ theme: { header } }) => header && header.text.secondary};
}

.title p span:after {
    background:  ${({ theme: { header } }) => header && header.text.secondary};
}
`
/* --------------------------------------------- */
const PlayerPopUpContent = styled.div`
    background-color: ${({ theme: { body } }) => body && body.background.primary};
`
/* --------------------------------------------- */
const PlayerSettingListWrapper = styled.ul`
  & li {
    background-color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.normal.background};
    color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.normal.text};
    border: solid 1px ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.normal.stroke};

    &:hover{
      background-color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.focussed.background};
      color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.focussed.text};
      border: solid 1px ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.focussed.stroke};
    }
    &.active{
      background-color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.selected.background};
      color: ${({ theme: { compositeStyle } }) => compositeStyle && compositeStyle.selectorStyle.selected.text};
    }
  }
  `
/* --------------------------------------------- */
const ImgContainer = styled.div`
  ${({ images }) => {
        return Object.keys(device).map((resolution) => {
            let width = 50
            if (resolution.indexOf('mobile') > -1 || resolution === 'tablet') width = 100
                const widthInPX = (width / 100) * size[resolution]
                const height = widthInPX * (1 / 1.778)
            let img = utils.getCloserMatchImage(widthInPX, height, images)
            img = img ? img.url : ''
            img = window.escape(img)
            img = img.replace(/https%3A/g, 'https:')
            img = img.replace(/http%3A/g, 'http:')
            return `
      @media ${device[resolution]} {
          background-image: url("${img}");
      }
      `
        })
    }}
`
/* --------------------------------------------- */
const HeaderTitle = styled.h2`
    color:${({ theme: { header } }) => header && header.text.primary};
`
/* --------------------------------------------- */
const SubTitle = styled.p`
    color:${({ theme: { header } }) => header && header.text.secondary};
`
/* --------------------------------------------- */
// const SubTitleText = styled.p`
//     color:${({ theme: { body } }) => body && body.text.primary};
// `
/* --------------------------------------------- */
PlayerUI.propTypes = {
    /* Title to be displayed in the player  */
    title: PropTypes.string,
    /* Subtitle to be displayed in the player  */
    subtitle: PropTypes.string,
    /* Array of thumbnail images to be displayed over the player  */
    thumbnailImgs: PropTypes.array,
    /* This callback gets triggered when pause button clicked.  */
    onPauseClick: PropTypes.func,
    /* This callback gets triggered when play button clicked.  */
    onPlayClick: PropTypes.func,
    /* This callback gets triggered when mute button clicked.  */
    onMuteClick: PropTypes.func,
    /* This callback gets triggered when unmute button clicked.  */
    onUnmuteClick: PropTypes.func,
    /* Object contains all messages related to player.  */
    messages: PropTypes.object
}
/* --------------------------------------------- */
PlayerUI.defaultProps = {
    title: '',
    subtitle: '',
    thumbnailImgs: [],
    showPlaceholderPlayBtn: false,
    onPauseClick: () => { },
    onPlayClick: () => { },
    onMuteClick: () => { },
    onUnmuteClick: () => { },
    messages: {}
}
/* --------------------------------------------- */
export default withRouterInnerRef(PlayerUI)
/* --------------------------------------------- */
