import React from 'react'
import ErrorPopup from './ErrorPopup'
import { useSelector } from 'react-redux'
import Provider from './context'
import { Wrapper } from './style'
/* ---------------------------------------------------------------------------------------------------- */
// Wrapper component for ErrorPopup component.
const ErrorPageWrapper = () => {
    const { appMessages } = useSelector(store => store.appConfigReducers)
    return (
        <Provider value = {{ appMessages }}>
            <Wrapper>
                <ErrorPopup />
            </Wrapper>
        </Provider>
    )
}
/* ---------------------------------------------------------------------------------------------------- */
export default ErrorPageWrapper
/* ---------------------------------------------------------------------------------------------------- */
