import React, { useCallback, useRef, useContext, useEffect } from 'react'
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider'
import { SliderRail, Handle, Track } from './PlayerSeekerComponents'
import Thumbnails from './Thumbnails'
import PropTypes from 'prop-types'
import { PlayerContext } from '../../context/player.context'

// Container component to handle player seek control
const PlayerSeek = ({ onSlideStart, onSlideEnd, onUpdate, resetBingePopOnClick, buffered, seekDisabled, ...props }) => {
  const adMarkerContainer = useRef(null);
  const { adCuePoints } = useContext(PlayerContext);
  
  const _onSlideStart = useCallback((props) => {
    if (onSlideStart) onSlideStart(props)
  }, [onSlideStart])

  const _onSlideEnd = useCallback((props) => {
    if (onSlideEnd) onSlideEnd(props)
  }, [onSlideEnd])

  const _onUpdate = useCallback((props) => {
    if (onUpdate) onUpdate(props)
  }, [onUpdate])
  const markAdBreaks = useCallback(() => {
    if (!adMarkerContainer.current) {
      return;
    }
    if (!adCuePoints?.length) {
      adMarkerContainer.current.style.background = "transparent";
      return;
    }

    const seekRange = window.shakaInstance.seekRange();
    const seekRangeSize = seekRange.end - seekRange.start;
    const gradient = ["to right"];
    const pointsAsFractions = [];
    const adBreakColor = "#FFE300";
    let postRollAd = false;
    for (const point of adCuePoints) {
      // Post-roll ads are marked as starting at -1 in CS IMA ads.
      if (point.start === -1 && !point.end) {
        postRollAd = true;
      }
      // Filter point within the seek range. For points with no endpoint
      // (client side ads) check that the start point is within range.
      if (point.start >= seekRange.start && point.start < seekRange.end) {
        if (point.end && point.end > seekRange.end) {
          continue;
        }

        const startDist = point.start - seekRange.start;
        const startFrac = startDist / seekRangeSize || 0;
        // For points with no endpoint assume a 1% length: not too much,
        // but enough to be visible on the timeline.
        let endFrac = startFrac + 0.01;
        if (point.end) {
          const endDist = point.end - seekRange.start;
          endFrac = endDist / seekRangeSize || 0;
        }

        pointsAsFractions.push({
          start: startFrac,
          end: endFrac,
        });
      }
    }

    for (const point of pointsAsFractions) {
      gradient.push(makeColor("transparent", point.start));
      gradient.push(makeColor(adBreakColor, point.start));
      gradient.push(makeColor(adBreakColor, point.end));
      gradient.push(makeColor("transparent", point.end));
    }

    if (postRollAd) {
      gradient.push(makeColor("transparent", 0.99));
      gradient.push(makeColor(adBreakColor, 0.99));
    }
    adMarkerContainer.current.style.background =
      "linear-gradient(" + gradient.join(",") + ")";
  }, [adCuePoints]);
  useEffect(() => {
    markAdBreaks();
  }, [adCuePoints, markAdBreaks]);
  const makeColor = (color, fract) => {
    return color + " " + fract * 100 + "%";
  };
  return (
    <>
      <Slider
        mode={1}
        step={1}
        domain={[0, (props.duration || 1)]}
        values={[props.currentPos || 0]}
        onSlideEnd={_onSlideEnd}
        onUpdate={_onUpdate}
        disabled = {seekDisabled}
        >
        <Rail>
          {({ getRailProps }) => <SliderRail resetBingePopOnClick={resetBingePopOnClick} getRailProps={getRailProps}
            onSlideStart={_onSlideStart}
            bufferedPercentage={buffered}
            isFromCast={props.isFromCast || false}
            seekBarColor={props.seekBarColor}
          />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className='slider-handles'>
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={[0, props.duration || 1]}
                  color={props.trackColor}
                  getHandleProps={getHandleProps}
                  isSafari={props.isSafari}
                  isLivePlayer={props.isLivePlayer}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className='slider-tracks'>
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                  color={props.trackColor}
                  isSafari={props.isSafari}
                  isLivePlayer={props.isLivePlayer}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
      {<div className="shaka-ad-markers" ref={adMarkerContainer}></div>}
      {props.isShowThumbnail && <Thumbnails duration={props.duration || 0} />}
    </>
  )
}
/* --------------------------------------------- */
PlayerSeek.propTypes = {
  isShowThumbnail: PropTypes.bool
}
/* --------------------------------------------- */
PlayerSeek.defaultProps = {
  isShowThumbnail: true
}
/* --------------------------------------------- */
export default PlayerSeek
