import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { RemoveScrollBar, fullWidthClassName } from 'react-remove-scroll-bar'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
/* --------------------------------------------- */
import { routeMap } from '../../route'
/* --------------------------------------------- */
import { device } from '../../constants/breakpoints'
/* --------------------------------------------- */
// import GoBackArrowIcon from '.././icons/GoBackArrowIcon'
import ConfirmPopUp from '.././ConfirmPopUp'
/* --------------------------------------------- */
import Menu from '.././Sidebar'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
import { NavigationDispatchContext, NavigationStateContext } from '../../context/navigation.context'
import useLogout from '../../containers/useLogout'
/* --------------------------------------------- */
import SearchBar from '.././SearchBar'//
/* --------------------------------------------- */
/* --------------------------------------------- */
import { setUserAuthInfo } from '../../utils/localStorageService'
import {
  isTablet,
  isMobile,
} from 'react-device-detect'
// import Avatar from './Avatar'
/* --------------------------------------------- */
import styled from 'styled-components'
import utils from '../../utils/utils'
import Avatar from '../../components/MultiProfileManagement/Avatar'
/* --------------------------------------------- */
import {
  HeaderLandingWrapper, HeaderWrapper, LeftWrapper, HeaderMenuLink, HeaderMenuLinkExternal, RightWrapper, MenuItem, GoBackArrowIconWithTheme,
  MenuImg, IconImg, AvatarImg, MenuItemLink, PlayBtnAction, LandingHeader
} from './style'
import { setLandingPageRendered } from '../../utils/sessionStorageService'
import { useGlobalNewDispatch } from '../../context/global.new.context'
import getFilter from '../../contentful/getFilter'
import { getNewObj } from '../../utils/contentfulHandler'
/* --------------------------------------------- */
// Header component for displaying header with menu handling.
const Header = ({ isShowLogo, isShowSearchIcon }) => {
  const [isMenuOpen, updateIsMenuOpen] = useState(false)
  const [isShowSidebar, updateIsShowSidebar] = useState(true)
  const [isShowGoBackArrowIcon, updateIsShowGoBackArrowIcon] = useState(false)
  const [isShowMenuIcon, updateIsShowMenuIcon] = useState(true)
  const [isConfirmPopup, updateIsConfirmPopup] = useState(false)
  const [maxVisisbleMenuItems, udpateMaxVisisbleMenuItems] = useState(1)
  const [theme, updateTheme] = useState(null)
  const [headerTheme, setHeaderTheme] = useState(null)
  const [title, updateTitle] = useState(null)
  const [isShowSecondaryNavDropDown, updateIsShowSecondaryNavDropDown] = useState(false)
  const [logoPosition, updateLogoPosition] = useState('')

  // const [margin, setMargin] = useState(0)
  // const [isShowHeader, ] = useState(false)
  /* --------------------------------------------- */
  const headerProfileList = useRef(null)
  const bIsTvLoginPage = useRef(false)

  /* --------------------------------------------- */
  const logout = useLogout()
  /* --------------------------------------------- */
  const history = useHistory()
  const location = useLocation()
  /* --------------------------------------------- */
  const { appTheme, introThemeHeaderConfig } = useSelector(store => store.themeConfigReducers)
  const { appMessages } = useSelector(store => store.appConfigReducers)
  const { userManagementConfig = {} } = useSelector(store => {return store.userManagement})
  const { primaryMenuConfig = {}, secondaryMenuConfig = {} } = useSelector(store => store.navigation)
  const { searchConfig } = useSelector(store => store.search)
  /* --------------------------------------------- */
  const { dispatch, contentfulData, isShared, showHeaderBackBtn, pageTitle, isAuthenticated, userInfo, selectedProfile, popupDetails, isPopupInViewport,isRenderedLandingPage, uiDirection } = useContext(GlobalContext)
  const navigationDispatch = useContext(NavigationDispatchContext)
  const { isSideMenuOpen } = useContext(NavigationStateContext)
  const globalNewDispatch = useGlobalNewDispatch()
  /* --------------------------------------------- */
  const { loginMethods = [] } = userManagementConfig
  /* --------------------------------------------- */
  /**
   * When: On outside click.
   * It: Hides profile menu.
   */
  const handleClickOutside = useCallback((event) => {
    if (!(headerProfileList.current && headerProfileList.current.contains(event.target))) {
      updateIsShowSecondaryNavDropDown(false)
    }
  }, [])

  /* --------------------------------------------- */
  /**
   * It: Updates user auth info.
   */
  useEffect(() => {
    if (!userInfo || !userInfo.profileType) {
      dispatch({ type: 'USER_INFO', value: { ...userInfo, profileType: 'adult' } })
      setUserAuthInfo({ profileType: 'adult' }, { merge: true })
    }
  }, [userInfo, dispatch])

  useEffect(() => {
    if(!selectedProfile && isAuthenticated && !( isTablet || isMobile)){
      dispatch({ type: 'SET_SHOW_MULTI_PROFILE_LIST', value: true })

    }
  }, [dispatch, isAuthenticated, selectedProfile])

  useEffect(() => {
    // console.log('userInfo-Header', isAuthenticated)
  }, [isAuthenticated])
  /* --------------------------------------------- */
  /**
   * When: On login click.
   * It: Shows login popup.
   * It: Redirects to login page.
   */
  const handleLoginClick = (loginMethods) => {
    updateIsShowSecondaryNavDropDown(false)
    if ((loginMethods || []).indexOf('vdt') > -1) {
      dispatch({ type: 'IS_POPUP_IN_VIEWPORT', value: true })
      disableBodyScroll(document.body, { reserveScrollBarGap: true })
      dispatch({ type: 'SHOW_LOGIN_POPUP' })
      if (isShared) {
        dispatch({
          type: 'LOGIN_POPUP_DETAILS',
          value: {
            isShowCloseIcon: true,
            onCloseClick: () => {
              dispatch({ type: 'HIDE_LOGIN_POPUP' })
              dispatch({ type: 'IS_POPUP_IN_VIEWPORT', value: false })
              enableBodyScroll(document.body)
              }
        }})
      } else {
        dispatch({ type: 'LOGIN_POPUP_DETAILS', value: {} })
      }
    }
    else {
      if (!isAuthenticated && !isRenderedLandingPage) {
          dispatch({ type: 'RENDERED_LANDING', value: true})
      }
      updateIsShowSecondaryNavDropDown(false)
      // dispatch({ type: 'SET_PREVIOUS_URL', value: window.location.pathname})
      history.push('/login')
    }

  }
  /* --------------------------------------------- */
  /**
   * When: On logout click.
   * It: Hides logout popup.
   * It: Hides profile menu.
   * It: Updates authenticated & user info state globally.
   */
  const handleLogoutClick = useCallback(() => {
    updateIsConfirmPopup(false)
    updateIsShowSecondaryNavDropDown(false)
    dispatch({ type: 'SELECTED_PROFILE', value: null })
    dispatch({ type: 'SET_SHOW_PROFILE_DETAILS', value: false })
    dispatch({ type: 'SET_SHOW_MULTI_PROFILE_LIST', value: false })
    dispatch({ type: 'UNAUTHENTICATED' })
    logout()
  }, [dispatch, logout])
  /* --------------------------------------------- */
  /**
   * It: Show/Hide menu icon.
   * It: Show/Hide back button icon.
   * It: Updates title.
   */
  useEffect(() => {
    if (showHeaderBackBtn) {
      updateIsShowMenuIcon(false)
      updateIsShowGoBackArrowIcon(true)
      updateTitle(pageTitle)
      // updateLogoPosition("center") handle when back is enabled
    } else {
      updateIsShowMenuIcon(true)
      updateIsShowGoBackArrowIcon(false)
      updateTitle(null)
    }
  }, [showHeaderBackBtn, pageTitle])

  /* --------------Toggle sidebar menu------------------------------- */
  useEffect(() => {
    updateIsMenuOpen(isSideMenuOpen)
  }, [isSideMenuOpen])

  /*------------------------------------------------- */
  /**
   * It: Sets moused down event listener.
   */
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])
  /* --------------------------------------------- */
  /**
   * It: Updates theme.
   */
  useEffect(() => {
    updateTheme(appTheme)
  }, [appTheme])

  useEffect(() => {
    setHeaderTheme(introThemeHeaderConfig)
  }, [introThemeHeaderConfig])
  /* --------------------------------------------- */
  /**
   * It: Updates max visible menu items state.
   */
  useEffect(() => {
    udpateMaxVisisbleMenuItems(primaryMenuConfig.maxVisibleItems || 1)
  }, [primaryMenuConfig.maxVisibleItems])
  /* --------------------------------------------- */
  /**
   * It: Show/Hide sidebar.
   */
  useEffect(() => {
    if (maxVisisbleMenuItems === 1) {
      updateIsShowSidebar(true)
    } else if (maxVisisbleMenuItems >= primaryMenuConfig.menuItems.length) {
      updateIsShowSidebar(false)
    } else updateIsShowSidebar(true)
  }, [primaryMenuConfig.menuItems, maxVisisbleMenuItems])
  /* --------------------------------------------- */
  /**
   * It: Sets setting popup title.
   */
  useEffect(() => {
      bIsTvLoginPage.current=utils.startsWith(decodeURIComponent(location?.pathname).toLowerCase(), routeMap.tvLogin.toLowerCase())
    },
    [location]
  )
  /* --------------------------------------------- */

  const { graphics } = appTheme
  let imageURL = ''
  let logoPositionPotrait = ''
  let logoPositionLandscape = ''
  let filters = ''
  graphics && graphics.map(({ id, images, anchor, filter }) => {
    if(contentfulData && filter) {
      const filterLocal = getFilter(contentfulData)
      filters = getNewObj(filter, [filterLocal])
    }
    if (id !== 'app_header_logo') return null
    if(filters) {
      if(filters?.displayOrientation?.[0] === "portrait") {
        if (utils.isNotNull(images, '0', 'url')) {
          imageURL = `${images[0].url}`
        }
        logoPositionPotrait = anchor
      } else {
        if (utils.isNotNull(images, '0', 'url')) {
          imageURL = `${images[0].url}`
        }
        logoPositionLandscape = anchor
      }
    }
    
    return null
  })
    // const logoPos = window.innerWidth > window.innerHeight ? logoPositionPotrait : logoPositionLandscape
    // updateLogoPosition(logoPos)

  const handleResize = useCallback(() => {
    
      if (window.innerWidth > 1024) {
        updateLogoPosition(logoPositionLandscape)
      } else {
        updateLogoPosition(logoPositionPotrait)
      }
      // if(showHeaderBackBtn) { // handle when back is enabled
      //   updateLogoPosition("center")
      // }
  }, [logoPositionPotrait, logoPositionLandscape
    // showHeaderBackBtn
  ])

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const graphicsHeader  = headerTheme?.graphics
  let headerImageURL = ''
  graphicsHeader && graphicsHeader.map(({ id, images, anchor }) => {
    if (id !== 'landing_header_logo') return null
    if (utils.isNotNull(images, '0', 'url')) {
      headerImageURL = `${images[0].url}`
    }
    return null
  })
  /* --------------------------------------------- */
  /**
   * When: On back arrow click.
   * It: Redriects to previous page.
   */
  const goBackArrowOnClick = () => {
    if (window?.location?.pathname === '/account-info') history.push('/')
    else {
      // dispatch({ type: 'RENDERED_LANDING', value: false })
      history.goBack()
    }
  }
  /* --------------------------------------------- */
  /**
   * When: On menu icon click.
   * It: Opens side menu.
   */
  const onClickHandler = () => {
    navigationDispatch({ type: "SHOW_MENU_SIDEBAR" })
    updateIsMenuOpen(true)
  }
  /* --------------------------------------------- */
  /**
   * When: On outside menu click.
   * It: Closes side menu.
   */
  const clickOutsideHandler = () => {
    navigationDispatch({ type: "HIDE_MENU_SIDEBAR" })
    updateIsMenuOpen(false)
  }
  /* --------------------------------------------- */
  /**
   * When: On profile icon click.
   * It: Toggles profile menu.
   */
  const profileOnclickHandler = () => {
    if (isShowSecondaryNavDropDown) updateIsShowSecondaryNavDropDown(false)
    else updateIsShowSecondaryNavDropDown(true)
  }
  /* --------------------------------------------- */
  /**
   * When: On popup positive button click.
   * It: Closes popup.
   */
  const handlePositiveBtn = () => {
    updateIsConfirmPopup(false)
  }
  /* --------------------------------------------- */
  /* --------------------------------------------- */
  /**
   * When: On settings click.
   * It: Opens settings popup.
   * It: Addes firebase performance trace.
   * It: Closes profile menu.
   */
  const handleSettingsClick = () => { 
      globalNewDispatch({ type: 'OPEN_SETTINGS_POPUP' })
    updateIsShowSecondaryNavDropDown(false)
  }

  const handleLanguageClick = () => { 
    globalNewDispatch({ type: 'OPEN_LANGUAGE_POPUP' })
  updateIsShowSecondaryNavDropDown(false)
}

  /* --------------------------------------------- */
  /**
   * When: On logout click.
   * It: Opens logout popup.
   * It: Closes profile menu.
   */
  const handleLogoutMenuItemClick = () => {
    updateIsConfirmPopup(true)
    updateIsShowSecondaryNavDropDown(false)
  }
  /* --------------------------------------------- */
  const handleExternalOpen = (url) => {
    window.open(url, '_blank');
  }
  /* -------------------------------------------- */
  /**
   * When: On signup click.
   * It: Closes profile menu.
   * It: Redirects to signup page.
   */
  const handleSignupMenuItemClick = () => {
    updateIsShowSecondaryNavDropDown(false)
    dispatch({ type: 'SET_PREVIOUS_URL', value: window?.location?.pathname})
    history.push(routeMap.signup)
  }
  /* -------------------------------------------- */
  /**
   * When: On profile click.
   * It: Opens profile selection popup.
   * It: Closes profile menu.
   */
  const handleProfileClick = () => {
    dispatch({ type: 'SET_SHOW_MULTI_PROFILE_LIST', value: true });
    updateIsShowSecondaryNavDropDown(false)
  }
  /* -------------------------------------------- */
    useEffect(() => {
    //setMargin(isPopupInViewport ? window.getComputedStyle(document.body)['paddingRight'] : 0)
  }, [isPopupInViewport])
  /* -------------------------------------------- */
  // if (!isShowHeader) return null
  /* -------------------------------------------- */
  if(bIsTvLoginPage?.current){
    return null
  }

  if (location?.pathname?.startsWith('/getting-started')) {
    return (
        <HeaderLandingWrapper theme= {{...headerTheme}}>
          <LandingHeader theme = {{...headerTheme }}>
            <LeftWrapper>
            <img className='headerLogo' src={headerImageURL} alt='' style={{marginLeft: `${uiDirection === "ltr" ? "30px": "15px"}`, marginRight: `${uiDirection === "rtl" ? "30px": "15px"}`}}/>
            </LeftWrapper>
            <div className='header-section-btn__action'>
              <PlayBtnAction theme={{...headerTheme }} className='action__single' onClick={() => handleLoginClick(loginMethods)}>
                {<p>{appMessages.label_forgot_password_log_in}</p>}
              </PlayBtnAction>
            </div>
            </LandingHeader>
        </HeaderLandingWrapper>
    )
  }

  return (
    <>
      {isMenuOpen && <RemoveScrollBar />}
      <div className='gostHeader' />
      <HeaderWrapper {...{ theme }} className={`header ${fullWidthClassName} ${popupDetails.inViewport && popupDetails.useMargin ? 'no-scroll-reserve-gap' : 'no-scroll-no-reserve-gap'}`}>
        <div className='main-container' >
          <LeftWrapper>
            {isShowGoBackArrowIcon && <GoBackArrowIconWithTheme {...{ theme }} className='header__goBackArrowIcon'  onClick={goBackArrowOnClick} style={{ width: 20, height: 20 }} />}
            {isShowMenuIcon && isShowSidebar && <MenuImg theme={primaryMenuConfig.overflowItem} className='header__menuIcon header__menuIcon---desktop'  onClick={onClickHandler} style={{ width: 20, height: 20 }} />}
            {!showHeaderBackBtn && <MenuImg theme={primaryMenuConfig.overflowItem} className='header__menuIcon header__menuIcon---responsive'  onClick={onClickHandler} style={{ width: 20, height: 20 }} />}
            {title && <div className='header__title' >{title}</div>}
            <Menu isOpenMenu={isMenuOpen} maxVisisbleMenuItems={maxVisisbleMenuItems} clickOutsideHandler={clickOutsideHandler} />
            {isShowLogo && ['left', 'top-left'].indexOf(logoPosition) > -1 && <NavLink to='/'><img className='headerLogo' src={imageURL} alt='' /></NavLink>}

            {maxVisisbleMenuItems !== 1 &&
              <div className='header___primary-nav' >
                <ul>
                {primaryMenuConfig?.menuItems?.length && primaryMenuConfig.menuItems.filter(item => item?.identifierExt !== 'home').map(({ title, type, identifierExt, url, buttonStyle }, index) => {                    
                    if (!isAuthenticated && url === routeMap.myAccount) return null
                    if(identifierExt !== 'favorites' && location?.pathname !== "/") {
                      setLandingPageRendered(true)
                    }
                    
                    if (identifierExt === 'favorites') {
                      if (isAuthenticated) url = routeMap.favourites
                      else return null
                    }
                    if (identifierExt === 'tv-guide') url = routeMap.tvGuide
                    let isActive = false
                    if (
                      location.pathname === url ||
                      (location.pathname === '/' && identifierExt === 'home')
                    ) {
                      isActive = true
                    }
                    
                    if(identifierExt==='home'){
                      url='/'
                    }
                    let _return = null
                    if ((maxVisisbleMenuItems > index) && !showHeaderBackBtn) {
                      if (['webview', 'external'].indexOf(type) > -1) {
                        _return = (
                          <li key={index}>
                            <HeaderMenuLinkExternal
                              buttonstyle={buttonStyle}
                              href={url}
                              target='_blank'>
                              {title}
                            </HeaderMenuLinkExternal>
                          </li>
                        )
                      } else {
                        _return = (
                          <li key={index}>
                            <HeaderMenuLink
                              buttonstyle={buttonStyle}
                              className={(isActive) ? 'active' : ''}
                              to={url}
                            > {title}
                            </HeaderMenuLink>
                          </li>
                        )
                      }
                      return _return
                    } else return null
                  })}
                </ul>
              </div>}
          </LeftWrapper>

          <CenterWrapper {...{ showHeaderBackBtn }}>
            {isShowLogo && ['center', 'center-left'].indexOf(logoPosition) > -1 && <NavLink to='/'><img className='headerLogo' src={imageURL} alt='' /></NavLink>}
          </CenterWrapper>
          <RightWrapper theme={secondaryMenuConfig.overflowTheme}>
            {isShowLogo && logoPosition === 'right' && <img className='headerLogo' src={imageURL} alt='' />}
            {searchConfig && Object.keys(searchConfig)?.length ? <SearchBar {...{ theme }} />: null}
            {secondaryMenuConfig.menuItems && secondaryMenuConfig.menuItems.length > 0 ?
              <div className='header__profile-wrapper' ref={headerProfileList}>

                <div className='header__profile-btn'  onClick={profileOnclickHandler}>
                  
                    <AvatarImg theme={secondaryMenuConfig.overflowItem} className={`${isShowSecondaryNavDropDown ? 'active' : ''}`} />
                  
                </div>
                <div className={`header__profile-list ${isShowSecondaryNavDropDown ? 'header__profile-list---open' : ''}`} >
                  <ul>
                    {
                      secondaryMenuConfig.menuItems.map((config, i) => {
                        const { id, title, buttonStyle, identifierExt, url, type } = config
                        // console.log('Item - Getting', config )
                        if (url && type === 'external') {
                          return (
                            <MenuItemLink href={url} target='_blank' key={i} onClick={() => handleProfileClick()} buttonStyle={buttonStyle}>
                              <IconImg theme={{ buttonStyle }} />
                              <h3>{title}</h3>
                            </MenuItemLink>
                          )
                        } else if (url && type === 'webview') {
                          return (
                            <MenuItemLink href={url} key={i} onClick={() => handleProfileClick()} buttonStyle={buttonStyle}>
                              <IconImg className='menu-icon' theme={{ buttonStyle }} />
                              <h3>{title}</h3>
                            </MenuItemLink>
                          )
                        } else {
                        if (id === 'login') {
                          // if (loginMethods.indexOf('vdt') === -1 && loginMethods.indexOf('mpx') === -1) {
                          //   return null
                          // } else {
                            return !isAuthenticated && (
                              <MenuItem key={i} onClick={() => handleLoginClick(loginMethods)} buttonStyle={buttonStyle}>
                                <IconImg theme={{ buttonStyle }} />
                                <h3>{title}</h3>
                              </MenuItem>
                            )
                          // }
                        } else if (id === 'logout') {
                          // if (loginMethods.indexOf('vdt') === -1 && loginMethods.indexOf('mpx') === -1) {
                          //   console.log("inside if")

                          //   return null
                          // } else {
                            // console.log("inside else")

                            return isAuthenticated && (
                              <MenuItem key={i} onClick={() => handleLogoutMenuItemClick()} buttonStyle={buttonStyle}>
                                <IconImg theme={{ buttonStyle }} />
                                <h3>{title}</h3>
                              </MenuItem>
                            )
                          // }
                        }

                        else if (id === 'menu_sign_up') {
                          // if (loginMethods.indexOf('mpx') === -1) {
                          //   return null
                          // } else {
                            return !isAuthenticated && (
                              <MenuItem key={i} onClick={() => handleSignupMenuItemClick()} buttonStyle={buttonStyle}>
                                <IconImg theme={{ buttonStyle }} />
                                <h3>{title}</h3>
                              </MenuItem>
                            )
                          // }
                        }

                        else if (id === 'menu_subscription') {
                          return (
                            <MenuItem key={i} onClick={() => handleExternalOpen(url)} buttonStyle={buttonStyle}>
                              <IconImg theme={{ buttonStyle }} />
                              <h3>{title}</h3>
                            </MenuItem>
                          )
                        }
                        else if(identifierExt === 'myaccount'){ 
                          return (<> {isAuthenticated &&
                              <MenuItem key={i}
                                onClick={() => {
                                    updateIsShowSecondaryNavDropDown(false)
                                    history.push(identifierExt === 'myaccount' ? '/my-account' : url)}
                                  }
                                buttonStyle={buttonStyle}
                                >
                                <IconImg theme={{ buttonStyle }} />
                                <h3>{title}</h3>
                              </MenuItem>}
                              </>)
                        }
                        else if(identifierExt && identifierExt.trim() === 'applanguage'){
                          return (
                            <MenuItem key={i} onClick={() => handleLanguageClick()} buttonStyle={buttonStyle}>
                              <IconImg theme={{ buttonStyle }} />
                              <h3>{title}</h3>
                            </MenuItem>
                          )
                        }

                        else {
                          if (identifierExt === 'appsetting') {
                            return (
                              <MenuItem key={i} onClick={() => handleSettingsClick()} buttonStyle={buttonStyle}>
                                <IconImg theme={{ buttonStyle }} />
                                <h3>{title}</h3>
                              </MenuItem>
                            )
                          } else {
                            return (
                              <MenuItem key={i} 
                                onClick={() => {
                                  updateIsShowSecondaryNavDropDown(false)
                                  history.push(identifierExt === 'favorites' ? '/favourites' : identifierExt === 'tv-guide' ? '/tv-guide': url)}
                                } 
                                buttonStyle={buttonStyle}
                                >
                                <IconImg theme={{ buttonStyle }} />
                                <h3>{title}</h3>
                              </MenuItem>
                            )
                          }
                        }
                        }
                      })
                    }
                  </ul>
                </div>
              </div> : <div className="header__profile-wrapper">
                <div className="header__profile-btn" onClick={() => dispatch({ type: 'SET_SHOW_MULTI_PROFILE_LIST', value: true })}>
                  {isAuthenticated && selectedProfile && selectedProfile.avatar && <Avatar height="40px" image={selectedProfile.avatar} showBorder={true} />}
                </div>
              </div>}
          </RightWrapper>
        </div>

      </HeaderWrapper>
      {isConfirmPopup && (
        <ConfirmPopUp
          onPositiveBtnClick={handlePositiveBtn}
          {...{
            title: appMessages.logout_popup_title,
            desc: appMessages.logout_popup_description,
            negtiveText: appMessages.label_logout_cancel,
            positiveText: appMessages.logout_popup_ok
          }}
          onNegativeBtnClick={handleLogoutClick}
        />)}
    </>
  )
}

export const CenterWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
${({ showHeaderBackBtn }) => {
    // if (!showHeaderBackBtn) return
    const mobile = ['mobileS', 'mobileM', 'mobileL']
    return mobile.map((resolution) => {
      return `
      @media ${device[resolution]} {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `
    })
  }}
`

/* --------------------------------------------- */
Header.propTypes = {
  /** Show/Hide Menu icon. */
  isShowMenuIcon: PropTypes.bool,
  /** Show/Hide Logo. */
  isShowLogo: PropTypes.bool,
  /** Force Show Logo. */
  isForceShowLogo: PropTypes.bool,
  /** Show/Hide Title. */
  isShowTitle: PropTypes.bool,
  /** history object sent as props from redux */
  history: PropTypes.object,
  /** Show/Hide Back arrow icon. */
  isShowGoBackArrowIcon: PropTypes.bool,
  /** Show/Hide Search icon. */
  isShowSearchIcon: PropTypes.bool,
  /** Header title */
  title: PropTypes.string,
  /** Header theme */
  theme: PropTypes.object
}
/* --------------------------------------------- */
Header.defaultProps = {
  isShowMenuIcon: true,
  isShowLogo: true,
  isForceShowLogo: false,
  isShowTitle: false,
  history: {},
  isShowGoBackArrowIcon: false,
  isShowSearchIcon: true,
  title: '',
  theme: {}
}
/* --------------------------------------------- */
export default Header
  /* --------------------------------------------- */
