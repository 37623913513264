export const routeMap = {
    /** Static Routes */
    contactUs: '/contact-us',
    faq: '/faq',
    tos: '/tos',
    pp: '/privacy-policy',
    /** Media Routes */
    catalog: '/:pageName',
    details: '/:pageName/:type/:mediaId?',
    listing: '/listing',
    favourites: '/favourites',
    player: '/player',
    tvGuide: '/tv-guide',
    /** Account Routes */
    login: '/login',
    signup: '/signup',
    verifyAccount: '/verify-account',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    changePassword: '/change-password',
    editProfile: '/edit-profile',
    accountInfo: '/account-info',
    myAccount: '/my-account',
    /** Payment Routes */
    paymentHistory: '/payment-history',
    subscription:'/subscription',
    payment: '/payment/:storeProductId',
    orderStatus: '/payment/order/:orderId',
    tvLogin: '/tvLogin',
    feedback: '/feedback'
}

export const routeVarMap = {
    type: {
        movie: 'movie',
        series: 'series',
        preview: 'preview',
        channel: 'channel',
        match: 'match',
        sports: 'Sports',
        liveevent: 'liveevent',
        liveEvent: 'liveEvent'
    }
}