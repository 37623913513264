import React, { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import visitorInfo from 'visitor-info'
import { isTablet, isMobile } from 'react-device-detect'
/* --------------------------------------------- */
import { GlobalContext } from '../../context/global.context'
import { ErrorContext } from '../../context/error.context'
/* --------------------------------------------- */
import { GetAppPopupContainer } from './style'
/* --------------------------------------------- */
const visitor = visitorInfo()
/* --------------------------------------------- */
// GetAppMobilePopup component for showing get app mobile popup.
const GetAppMobilePopup = () => {
  const [isShow, updateIsupdate] = useState(true)
  /* --------------------------------------------- */
  const theme = useSelector(store => store.themeConfigReducers.popupTheme)
  const appStoreConfig = useSelector(store => store.appConfigReducers.appStoreConfig)
  const appMessages = useSelector(store => store.appConfigReducers.appMessages)
  /* --------------------------------------------- */
  const { isShowInstallBanner } = useContext(GlobalContext)
  /* --------------------------------------------- */
  const {
    errorPage,
    errorPopup,
    errorFullscreen,
    errorToast,
    errorSnackbar
  } = useContext(ErrorContext)
  /* --------------------------------------------- */
  /**
   * When: To handle cancel button click.
   * It: Hides popup.
   */
  const negativeHandler = () => {
    updateIsupdate(false)
  }
  /* --------------------------------------------- */
  /**
   * When: To handle positive button click.
   * It: Opens store link.
   */
  const getAppHandler = () => {
    const os = visitor.os.name
    let storeLink = appStoreConfig.androidAppStoreUrl
    if (os === 'Mac OS' || os === 'iOS' || os === 'iPadOS') storeLink = appStoreConfig.iosAppStoreUrl
    window.open(storeLink, '_blank')
  }
  /* --------------------------------------------- */
  if (
    (!isMobile && !isTablet) ||
    !appStoreConfig.showAppstoreBanner ||
    !isShowInstallBanner ||
    Object.keys(errorPage).length !== 0 ||
    Object.keys(errorPopup).length !== 0 ||
    Object.keys(errorFullscreen).length !== 0 ||
    Object.keys(errorToast).length !== 0 ||
    Object.keys(errorSnackbar).length !== 0
  ) return null
  /* --------------------------------------------- */
  return (
    <>
      {isShow && (
        <GetAppPopupContainer {...{ theme }} className='get-app-toast'>
          <div className='text-box'>
            <h2>{appMessages.label_web_app_download_banner_title}</h2>
          </div>
          <div className='text-box'>
            <p>{appMessages.label_web_app_download_banner_description}</p>

          </div>
          <div className='action'>
            <p onClick={negativeHandler} className='dismiss-Btn'>{appMessages.button_web_app_dismiss}</p>
            <p onClick={getAppHandler} className='dowload-Btn'>{appMessages.button_web_app_download_banner}</p>
          </div>
        </GetAppPopupContainer>
      )}
    </>
  )
}



/* --------------------------------------------- */
GetAppMobilePopup.defaultProps = {}
/* --------------------------------------------- */
export default GetAppMobilePopup
/* --------------------------------------------- */
