import { SUPPORT_CONFIG } from '../constants/actionTypes'
import inialState from './initalState'
/* --------------------------------------------- */
/** Support config reducer */
const support = (state = inialState, action) => {
  switch (action.type) {
    case SUPPORT_CONFIG:
      return {
        ...state,
        supportConfig: action.value
      }
    default: return state
  }
}
/* --------------------------------------------- */
export default support
/* --------------------------------------------- */
