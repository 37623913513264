import { getNew, getNewObj } from '../utils/contentfulHandler'
import getFilter from './getFilter'
import getAnalyticEvent from './getAnalyticEvent'

// Helper function to form contentful tree
const getAnalyticIntegration = (contentful) => {
  const filterLocal = getFilter(contentful)
  const analyticEventLocal = getAnalyticEvent(contentful)
  const analyticIntegrationLocal = contentful.analyticIntegration
  return analyticIntegrationLocal.map(({ sys = {}, fields = {} }) => {
    const { id = '', contentType = {} } = sys
    const { sys: contentTypeSys = {} } = contentType
    const { id: contentTypeId = '' } = contentTypeSys
    const { filter = {}, analyticEvents = [] } = fields
    return {
      ...fields,
      sysId: id,
      contentTypeId,
      filter: getNewObj(filter, [filterLocal]),
      analyticEvents: getNew(analyticEvents, analyticEventLocal)
    }
  })
}

export default getAnalyticIntegration
